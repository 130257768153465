import { createDamageStore } from '@declaration/store/createDamageStore';
import { Step } from '@shared/claim/steps';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
import { electricalDamageConstraints } from '@shared/validator/constraints/electricalDamageConstraints';
export const name = 'electricalDamage';
export const namespaced = true;
function newElectricalDamage() {
    return {
        id: null,
        clientIdentifier: newClientIdentifier(),
        type: null,
        repairable: true,
        repairAmount: null,
        repairQuote: emptyUploadedFileCollection(),
        irreparabilityCertificate: emptyUploadedFileCollection(),
        purchaseInfo: true,
        purchaseCertification: false,
        purchaseAmount: null,
        purchaseInvoice: emptyUploadedFileCollection(),
        compensationMode: null,
        compensationModeHasBeenForced: null,
    };
}
const { createDamageState, damageGetters, damageActions, damageMutations } = createDamageStore(newElectricalDamage, electricalDamageConstraints, Step.ElectricalDamagesDetails, 'electricalDamages', { step: null, categoryKey: 'type' });
export const state = createDamageState;
export const getters = {
    ...damageGetters,
    getElectricalDamagePositionSuffix: (state) => (slug, index) => {
        if (!slug) {
            return null;
        }
        let positionWithSameCategory = 1;
        let isTheOnlyOneWithCategory = true;
        state.damages.forEach((damage, otherIndex) => {
            if (damage.type === slug && otherIndex !== index) {
                isTheOnlyOneWithCategory = false;
                if (otherIndex < index) {
                    positionWithSameCategory += 1;
                }
            }
        });
        return isTheOnlyOneWithCategory ? null : positionWithSameCategory.toString();
    },
};
export const actions = {
    ...damageActions,
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    updateType({ commit, rootState }, { type, changeKey }) {
        const data = { type };
        commit('UPDATE_DAMAGE', { changeKey, data, typeTrigram: rootState.qualification.typeTrigram });
    },
    async validateElectricalDamagesStep({ dispatch, rootState, commit }) {
        if (!rootState.claim.claim.stepStack.includes(Step.ElectricalDamagesDetails)) {
            try {
                await dispatch('claim/saveStepWithoutStoreUpdate', Step.ElectricalDamagesDetails, {
                    root: true,
                });
            }
            catch (_a) {
                dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
                return;
            }
        }
        await dispatch('claim/updateAndSaveClaimStep', Step.ElectricalDamages, { root: true });
        commit('RESET_CHANGES');
    },
};
export const mutations = {
    ...damageMutations,
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
};
