import { ApiResourceName } from '@shared/types/api/api';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { optionalResourceIri, uuidFromIri } from '@shared/utils/iri';
import { iriFromId } from '@shared/utils/IriFromId';
import { isNotNull } from '@shared/utils/isNotNull';
// Window / URL Creator do not exists in node environment.
const urlCreator = typeof window !== 'undefined' ? window.URL : { createObjectURL: () => '' };
const FILE_CACHE = new Map();
export function emptyUploadedFileCollection() {
    return {
        '@type': ApiEntityType.UploadedAppFileCollection,
        id: null,
        uploadedAppFiles: [],
    };
}
export function removeFilesFromFileCollection(fileCollection) {
    var _a;
    if (typeof fileCollection === 'string') {
        return {
            '@type': ApiEntityType.UploadedAppFileCollection,
            id: fileCollection,
            uploadedAppFiles: [],
        };
    }
    return {
        '@type': ApiEntityType.UploadedAppFileCollection,
        id: (_a = fileCollection === null || fileCollection === void 0 ? void 0 : fileCollection.id) !== null && _a !== void 0 ? _a : null,
        uploadedAppFiles: [],
    };
}
/**
 * Creates an UploadedFile instance from backend stored file iri
 */
export function uploadedFileFromIri(axios, iri) {
    const cached = FILE_CACHE.get(iri);
    if (cached) {
        return cached;
    }
    const fetch = axios
        // File content is in Files resource. Entity is in UploadedFiles resource.
        .$get(iriFromId(ApiResourceName.Files, uuidFromIri(iri)), {
        responseType: 'blob',
    })
        .then((response) => ({
        id: iri,
        url: urlCreator.createObjectURL(response),
        type: response.type,
        isUploading: false,
        originalName: response.originalName,
    }));
    FILE_CACHE.set(iri, fetch);
    return fetch;
}
export function uploadedFileFromResponse(axios, file) {
    return uploadedFileFromIri(axios, file.id);
}
export function uploadedFilesFromIris(axios, fileIris) {
    return Promise.all(fileIris.map((iri) => uploadedFileFromIri(axios, iri)));
}
export function uploadedFilesFromResponses(axios, files) {
    return Promise.all(files.map((response) => uploadedFileFromResponse(axios, response)));
}
export function uploadedWithoutUrlFromFileReponse(file) {
    return {
        id: file.id,
        url: null,
        type: file.mimeType,
        originalName: file.originalName,
    };
}
export function fileCollectionFilesToIri(collection) {
    const uploadedAppFiles = collection.uploadedAppFiles;
    if (!Array.isArray(uploadedAppFiles)) {
        return collection;
    }
    const iris = uploadedAppFiles
        .map(optionalResourceIri)
        .filter(isNotNull);
    return {
        ...collection,
        uploadedAppFiles: iris,
    };
}
export function fileCollectionFilesCount(collection) {
    if (collection === null || collection === undefined || typeof collection === 'string') {
        return 0;
    }
    const uploadedAppFiles = collection.uploadedAppFiles;
    if (!Array.isArray(uploadedAppFiles)) {
        return 0;
    }
    const iris = uploadedAppFiles
        .map(optionalResourceIri)
        .filter(isNotNull);
    return iris.length;
}
