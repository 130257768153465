import { parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { objectToTranslatableChoices, sortChoices, translatableObjectToChoices, translateChoices, } from '@shared/choice';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { BDG_INITIAL_PERSONAL_PROPERTY_CATEGORIES, BDG_PERSONAL_PROPERTY_REPARATION_BY_TRAD_KEY, INITIAL_PERSONAL_PROPERTY_CATEGORIES, PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY, PERSONAL_PROPERTY_RECEIPT_TYPES_TRAD_KEY, } from '@shared/constants/personalPropertyConstants';
import { PROOF_TYPES_LABELS } from '@shared/constants/refundConstants';
import { emptyUploadedFileCollection, fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { BDGPersonalPropertyInitialCategorySlug, BDGPersonalPropertyReparationBy, PersonalPropertyInitialCategorySlug, PersonalPropertyReceiptType, PersonalPropertyType, REPAIRABLE_STATUS_LABELS, RepairableStatus, } from '@shared/types/api/personalProperty';
import { ProofType } from '@shared/types/api/refundDetail';
import { ClaimFileType } from '@shared/types/file';
import { formatOptionalAge } from '@shared/utils/formatAge';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { optionalCategoryIri, optionalResourceIri } from '@shared/utils/iri';
import { EMPTY } from '@shared/utils/strings';
import { booleanTransform, optionalBooleanTransform, optionalString, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
import personalPropertyConstraints from '@shared/validator/constraints/personalPropertyConstraints';
export function personalPropertyTitle(model, categories = {}, defaultValue = 'Bien Mobilier') {
    var _a, _b, _c;
    const categoryIri = optionalResourceIri(model.category);
    if (categoryIri) {
        if (categoryIri === optionalCategoryIri('especes', categories)) {
            return 'Espèces';
        }
        else if (categoryIri === optionalCategoryIri(['document-identite', 'papiers-d-identite'], categories)) {
            return "Papiers d'identité";
        }
        else if (categoryIri === optionalCategoryIri('denrees-alimentaires', categories)) {
            return 'Denrées alimentaires';
        }
    }
    return (_c = (_b = (_a = model.thirdPartyProductInfo) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : model.designation) !== null && _c !== void 0 ? _c : defaultValue;
}
export function personalPropertyBrand(model, defaultValue = EMPTY) {
    var _a;
    return ((_a = model.thirdPartyProductInfo) === null || _a === void 0 ? void 0 : _a.brand) || model.description || defaultValue;
}
let personalPropertiesCategoriesChoices = null;
function getPersonalPropertiesCategoriesChoices(categories) {
    if (personalPropertiesCategoriesChoices) {
        return personalPropertiesCategoriesChoices;
    }
    personalPropertiesCategoriesChoices = sortChoices(Object.entries(categories)
        .filter(([_, category]) => !category.isHidden)
        .map(([iri, category]) => ({ label: category.label, value: iri })));
    return personalPropertiesCategoriesChoices;
}
export const personalPropertyFields = (model, { translate, categories, edition, projection, type }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    const title = personalPropertyTitle(model, categories);
    const fields = [];
    const categoryIri = optionalResourceIri(model.category);
    const category = categoryIri ? (_a = categories[categoryIri]) !== null && _a !== void 0 ? _a : null : null;
    const identityDocumentCategoryIri = optionalCategoryIri(['document-identite', 'papiers-d-identite'], categories);
    const cashCategoryIri = optionalCategoryIri('especes', categories);
    const foodCategoryIri = optionalCategoryIri('denrees-alimentaires', categories);
    const otherIri = optionalCategoryIri('autres', categories);
    const multimediaIri = optionalCategoryIri('multimedia', categories);
    const bigElectricalIri = optionalCategoryIri('gros-electromenager', categories);
    const smallElectricalIri = optionalCategoryIri('petit-electromenager-outillage-electronique', categories);
    const defaultBackofficeFormDisplay = projection === ModelFieldsProjection.Backoffice &&
        categoryIri !== identityDocumentCategoryIri &&
        categoryIri !== cashCategoryIri &&
        categoryIri !== foodCategoryIri;
    const otherDescriptionOnly = model.type === PersonalPropertyType.GlassBreakage &&
        model.initialCategorySlug === PersonalPropertyInitialCategorySlug.Autres;
    const replaceableGlass = !!model.initialCategorySlug &&
        [
            BDGPersonalPropertyInitialCategorySlug.InsertDeCheminee,
            BDGPersonalPropertyInitialCategorySlug.Meuble,
            BDGPersonalPropertyInitialCategorySlug.Miroir,
        ].includes(model.initialCategorySlug);
    const isThirdParty = !!model.thirdPartyProductId;
    if (projection === ModelFieldsProjection.Declaration) {
        if (category && category.isAmountOnly) {
            return {
                title,
                fields: [
                    {
                        label: translate('model.personal_property.amount_only'),
                        key: 'amount',
                        value: model.amount,
                        displayedValue: formatOptionalCentsAmount(model.amount),
                    },
                ],
                model,
            };
        }
    }
    if (projection === ModelFieldsProjection.Backoffice) {
        if (isGlassBreakagePersonalProperty(model)) {
            const bdgCategories = {
                ...Object.fromEntries(Object.entries(BDG_INITIAL_PERSONAL_PROPERTY_CATEGORIES).map(([key, value]) => [
                    key,
                    value.labelTradKey,
                ])),
                [BDGPersonalPropertyInitialCategorySlug.Autres]: INITIAL_PERSONAL_PROPERTY_CATEGORIES[PersonalPropertyInitialCategorySlug.Autres]
                    .labelTradKey,
            };
            fields.push({
                label: 'Type de bien',
                value: model.initialCategorySlug,
                displayedValue: optionalTranslatableEnumTransform(model.initialCategorySlug, bdgCategories, translate, '-'),
                editable: {
                    input: {
                        type: ModelFieldInputType.Select,
                        choices: translateChoices(objectToTranslatableChoices(bdgCategories), translate),
                    },
                    modelKey: 'initialCategorySlug',
                },
            });
            if (edition) {
                if (replaceableGlass || otherDescriptionOnly) {
                    model.category = otherIri;
                }
                else if (![bigElectricalIri, smallElectricalIri, multimediaIri].includes(categoryIri)) {
                    model.category = null;
                }
            }
            fields.push({
                label: translate('model.personal_property.category'),
                value: model.category,
                displayedValue: categoryIri ? categories[categoryIri].label : EMPTY,
                editable: {
                    input: {
                        type: ModelFieldInputType.Select,
                        choices: getPersonalPropertiesCategoriesChoices(categories).filter((val) => {
                            if (replaceableGlass || otherDescriptionOnly)
                                return val.value === otherIri;
                            return [bigElectricalIri, smallElectricalIri, multimediaIri].includes(val.value);
                        }),
                    },
                    disabled: !model.initialCategorySlug || replaceableGlass || otherDescriptionOnly,
                    modelKey: 'category',
                },
            });
        }
        else {
            fields.push({
                label: translate('model.personal_property.category'),
                value: categoryIri,
                displayedValue: category ? category.label : EMPTY,
                editable: {
                    input: {
                        type: ModelFieldInputType.Select,
                        choices: getPersonalPropertiesCategoriesChoices(categories),
                    },
                    disabled: isThirdParty,
                    modelKey: 'category',
                },
            });
        }
    }
    if (categoryIri === identityDocumentCategoryIri) {
        fields.push({
            label: translate('model.personal_property.document-type'),
            value: model.documentType,
            displayedValue: optionalTranslatableEnumTransform(model.documentType, PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY, translate, translate('model.document.none')),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY, translate),
                },
                modelKey: 'documentType',
            },
        });
    }
    else {
        if ((projection === ModelFieldsProjection.Declaration && !otherDescriptionOnly) ||
            projection === ModelFieldsProjection.Valuation ||
            defaultBackofficeFormDisplay) {
            fields.push({
                label: translate('model.personal_property.designation'),
                value: model.designation,
                displayedValue: model.designation || ((_b = model.thirdPartyProductInfo) === null || _b === void 0 ? void 0 : _b.name) || EMPTY,
                editable: {
                    input: {
                        type: ModelFieldInputType.Text,
                    },
                    disabled: isThirdParty,
                    modelKey: 'designation',
                },
            });
        }
        if ((model.description && categoryIri !== cashCategoryIri) || defaultBackofficeFormDisplay) {
            fields.push({
                label: translate(model.thirdPartyProductInfo
                    ? 'model.personal_property.description.brand'
                    : otherDescriptionOnly
                        ? 'model.personal_property.description.other'
                        : 'model.personal_property.description'),
                value: model.thirdPartyProductInfo ? model.thirdPartyProductInfo.brand : model.description,
                displayedValue: personalPropertyBrand(model),
                editable: {
                    input: {
                        type: ModelFieldInputType.Text,
                    },
                    disabled: isThirdParty,
                    modelKey: 'description',
                },
            });
        }
        if (!otherDescriptionOnly) {
            if (model.age || defaultBackofficeFormDisplay) {
                fields.push({
                    label: translate('model.personal_property.age'),
                    value: model.age,
                    displayedValue: formatOptionalAge(model.age, translate),
                    editable: {
                        input: {
                            type: ModelFieldInputType.Age,
                        },
                        modelKey: 'age',
                    },
                });
            }
            if ((!((_c = model.thirdPartyProductInfo) === null || _c === void 0 ? void 0 : _c.isAvailable) && model.amount) ||
                projection === ModelFieldsProjection.Backoffice) {
                // Display in backoffice, discount on personal property amount.
                // Discount is applied to personal property amount
                // not coming from third party provider like UPPTEC.
                const discountedAmount = projection === ModelFieldsProjection.Backoffice &&
                    ((_e = (_d = model.refund) === null || _d === void 0 ? void 0 : _d.valuation) === null || _e === void 0 ? void 0 : _e.replacement) &&
                    model.amount &&
                    ((_g = (_f = model.refund) === null || _f === void 0 ? void 0 : _f.valuation) === null || _g === void 0 ? void 0 : _g.replacement) < model.amount
                    ? formatOptionalCentsAmount((_j = (_h = model.refund) === null || _h === void 0 ? void 0 : _h.valuation) === null || _j === void 0 ? void 0 : _j.replacement)
                    : null;
                const discountedAmountDisplayedValue = discountedAmount ? ' (' + discountedAmount + ')' : '';
                fields.push({
                    label: category && category.isAmountOnly
                        ? translate('model.personal_property.amount_only')
                        : translate('model.personal_property.amount'),
                    value: (_k = model.amount) !== null && _k !== void 0 ? _k : 0,
                    displayedValue: model.amount
                        ? formatOptionalCentsAmount(model.amount) + discountedAmountDisplayedValue
                        : '-',
                    editable: {
                        input: {
                            type: ModelFieldInputType.Amount,
                        },
                        unit: '€',
                        modelKey: 'amount',
                    },
                });
            }
        }
        if (model.type === PersonalPropertyType.GlassBreakage && replaceableGlass) {
            const { windowCount, widestWindowWidth, widestWindowHeight } = model;
            if (projection === ModelFieldsProjection.Backoffice || typeof windowCount === 'number') {
                fields.push({
                    label: translate('model.personal_property.bdg.window_count'),
                    value: windowCount,
                    displayedValue: (windowCount === null || windowCount === void 0 ? void 0 : windowCount.toString()) || '-',
                    editable: {
                        input: {
                            type: ModelFieldInputType.Number,
                        },
                        modelKey: 'windowCount',
                    },
                });
            }
            if (projection === ModelFieldsProjection.Declaration) {
                if (windowCount &&
                    (typeof widestWindowWidth === 'number' || typeof widestWindowHeight === 'number')) {
                    fields.push({
                        label: translate('model.personal_property.bdg.widest_window_dimensions'),
                        key: 'widestWindowDimensions',
                        displayedValue: `${parseOptionalDimension(widestWindowWidth, 'n/a')} x ${parseOptionalDimension(widestWindowHeight, 'n/a')}`,
                    });
                }
            }
            if (projection === ModelFieldsProjection.Backoffice && windowCount && windowCount > 0) {
                fields.push({
                    label: translate('model.personal_property.bdg.widest_window_width'),
                    value: widestWindowWidth,
                    displayedValue: parseOptionalDimension(widestWindowWidth, 'n/a'),
                    editable: {
                        input: {
                            type: ModelFieldInputType.Number,
                        },
                        unit: 'mm',
                        modelKey: 'widestWindowWidth',
                    },
                });
                fields.push({
                    label: translate('model.personal_property.bdg.widest_window_height'),
                    value: widestWindowHeight,
                    displayedValue: parseOptionalDimension(widestWindowHeight, 'n/a'),
                    editable: {
                        input: {
                            type: ModelFieldInputType.Number,
                        },
                        unit: 'mm',
                        modelKey: 'widestWindowHeight',
                    },
                });
            }
        }
        if (!otherDescriptionOnly) {
            fields.push({
                label: projection === ModelFieldsProjection.Declaration
                    ? translate('model.personal_property.receipt.document')
                    : translate('model.personal_property.receipt.type'),
                value: (_l = model.receipt) === null || _l === void 0 ? void 0 : _l.type,
                displayedValue: ((_m = model.receipt) === null || _m === void 0 ? void 0 : _m.type) === PersonalPropertyReceiptType.Pictures &&
                    fileCollectionFilesCount(model.pictureCollection) > 1
                    ? `${fileCollectionFilesCount(model.pictureCollection)} ${optionalTranslatableEnumTransform((_o = model.receipt) === null || _o === void 0 ? void 0 : _o.type, PERSONAL_PROPERTY_RECEIPT_TYPES_TRAD_KEY, (key) => translate(key, fileCollectionFilesCount(model.pictureCollection)), translate('model.document.none'))}`
                    : `${optionalTranslatableEnumTransform((_p = model.receipt) === null || _p === void 0 ? void 0 : _p.type, PERSONAL_PROPERTY_RECEIPT_TYPES_TRAD_KEY, translate, translate('model.document.none'))}`,
                layout: {
                    column: 1,
                },
                editable: {
                    input: {
                        type: ModelFieldInputType.Select,
                        choices: translatableObjectToChoices(PERSONAL_PROPERTY_RECEIPT_TYPES_TRAD_KEY, translate),
                    },
                    modelKey: 'receipt.type',
                },
            });
        }
    }
    const receiptType = (_q = model.receipt) === null || _q === void 0 ? void 0 : _q.type;
    if (projection !== ModelFieldsProjection.Declaration &&
        projection !== ModelFieldsProjection.Pdf &&
        !!receiptType &&
        receiptType !== PersonalPropertyReceiptType.None &&
        receiptType !== PersonalPropertyReceiptType.Pictures) {
        fields.push({
            label: translate('model.personal_property.receipt.document'),
            value: (_r = model.receipt) === null || _r === void 0 ? void 0 : _r.fileCollection,
            displayedValue: {
                type: 'fileCollection',
                fileType: ClaimFileType.PersonalPropertyReceipt,
                uploadLabel: translate('model.document.upload'),
                addFileLabel: translate('model.document.upload.add_page'),
                emptyLabel: translate('model.document.empty'),
                fileCollection: (_s = model.receipt) === null || _s === void 0 ? void 0 : _s.fileCollection,
            },
            editable: {
                input: {
                    type: ModelFieldInputType.FileCollection,
                },
                modelKey: 'receipt.fileCollection',
            },
        });
    }
    if (projection !== ModelFieldsProjection.Declaration &&
        !replaceableGlass &&
        !otherDescriptionOnly) {
        const isThirdParty = !!model.thirdPartyProductId;
        fields.push({
            label: translate('model.personal_property.is_third_party'),
            displayedValue: booleanTransform(isThirdParty, translate),
            upptec: isThirdParty,
        });
        if (isThirdParty && !edition) {
            const product = (_u = (_t = model.thirdPartyProductInfo) === null || _t === void 0 ? void 0 : _t.bestAlternative) !== null && _u !== void 0 ? _u : null;
            fields.push({
                label: translate('model.personal_property.best_alternative'),
                key: 'bestAlternative',
                value: product,
                displayedValue: product
                    ? {
                        type: 'thirdPartyProduct',
                        candidate: product,
                    }
                    : 'Aucun',
            });
        }
    }
    if (claimTypeHasFeature(type, ClaimTypeFeatureFlag.PersonalPropertyWarranty)) {
        fields.push({
            label: translate('model.personal_property.warranty'),
            value: model.isUnderWarranty,
            displayedValue: optionalBooleanTransform(model.isUnderWarranty, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Checkbox,
                },
                modelKey: 'isUnderWarranty',
            },
            layout: {
                column: 1,
            },
        });
    }
    if (claimTypeHasFeature(type, ClaimTypeFeatureFlag.PersonalPropertyRepair)) {
        if (projection !== ModelFieldsProjection.Declaration) {
            fields.push({
                label: translate('model.personal_property.repairable_status'),
                value: model.repairableStatus,
                displayedValue: optionalTranslatableEnumTransform(model.repairableStatus, REPAIRABLE_STATUS_LABELS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translatableObjectToChoices(REPAIRABLE_STATUS_LABELS, translate),
                        vertical: true,
                    },
                    modelKey: 'repairableStatus',
                    toPayload(repairableStatus) {
                        if (repairableStatus === RepairableStatus.Excluded) {
                            return {
                                repairableStatus,
                                repairQuoteInfo: null,
                                repairFileCollection: emptyUploadedFileCollection(),
                            };
                        }
                        if (repairableStatus === RepairableStatus.Irreparable) {
                            return {
                                repairableStatus,
                                repairQuoteInfo: null,
                            };
                        }
                        return { repairableStatus };
                    },
                },
                layout: {
                    column: 1,
                },
            });
        }
        if (model.repairableStatus &&
            model.repairableStatus !== RepairableStatus.Excluded &&
            projection !== ModelFieldsProjection.Pdf) {
            if (projection !== ModelFieldsProjection.Declaration) {
                fields.push({
                    label: model.repairableStatus === RepairableStatus.Repairable
                        ? translate('model.personal_property.repair_quote.document')
                        : translate('model.personal_property.irreparability_certificate.document'),
                    value: model.repairFileCollection,
                    displayedValue: {
                        type: 'fileCollection',
                        fileType: ClaimFileType.PersonalPropertyRepairQuote,
                        uploadLabel: translate('model.document.upload'),
                        addFileLabel: translate('model.document.upload.add_page'),
                        emptyLabel: translate('model.document.empty'),
                        fileCollection: model.repairFileCollection,
                    },
                    editable: {
                        input: {
                            type: ModelFieldInputType.FileCollection,
                        },
                        modelKey: 'repairFileCollection',
                    },
                });
            }
            else {
                fields.push({
                    label: translate('model.personal_property.repairable'),
                    key: 'repairable',
                    value: model.repairableStatus === RepairableStatus.Repairable,
                    displayedValue: optionalBooleanTransform(model.repairableStatus === RepairableStatus.Repairable, translate),
                });
            }
        }
        const shouldHaveRepairQuoteInfo = model.repairableStatus === RepairableStatus.Repairable &&
            fileCollectionFilesCount(model.repairFileCollection) > 0;
        if (shouldHaveRepairQuoteInfo) {
            fields.push({
                label: translate('model.personal_property.repair_quote.amount'),
                value: (_v = model.repairQuoteInfo) === null || _v === void 0 ? void 0 : _v.amount,
                displayedValue: formatOptionalCentsAmount((_w = model.repairQuoteInfo) === null || _w === void 0 ? void 0 : _w.amount),
                editable: {
                    input: {
                        type: ModelFieldInputType.Amount,
                    },
                    modelKey: 'repairQuoteInfo.amount',
                },
                layout: {
                    column: 1,
                },
            });
        }
        if (claimTypeHasFeature(type, ClaimTypeFeatureFlag.PersonalPropertyRepairerInfo)) {
            fields.push({
                label: translate('model.personal_property.repair_quote.company_name'),
                value: (_x = model.repairQuoteInfo) === null || _x === void 0 ? void 0 : _x.companyName,
                displayedValue: optionalString((_y = model.repairQuoteInfo) === null || _y === void 0 ? void 0 : _y.companyName),
                editable: {
                    input: {
                        type: ModelFieldInputType.Text,
                    },
                    modelKey: 'repairQuoteInfo.companyName',
                },
                layout: {
                    column: 1,
                },
            });
            fields.push({
                label: translate('model.personal_property.repair_quote.company_phone_number'),
                value: (_z = model.repairQuoteInfo) === null || _z === void 0 ? void 0 : _z.companyPhoneNumber,
                displayedValue: optionalString((_0 = model.repairQuoteInfo) === null || _0 === void 0 ? void 0 : _0.companyPhoneNumber),
                editable: {
                    input: {
                        type: ModelFieldInputType.Text,
                    },
                    modelKey: 'repairQuoteInfo.companyPhoneNumber',
                },
                layout: {
                    column: 1,
                },
            });
        }
    }
    const picturesField = {
        label: translate('model.pictures'),
        value: model.pictureCollection,
        displayedValue: {
            type: 'fileCollection',
            fileType: ClaimFileType.PersonalPropertyPicture,
            uploadLabel: translate('model.picture.upload'),
            emptyLabel: translate('model.pictures.empty'),
            fileCollection: model.pictureCollection,
            displayAsCount: true,
        },
        editable: {
            input: {
                type: ModelFieldInputType.FileCollection,
            },
            modelKey: 'pictureCollection',
        },
    };
    // Robbery : Only show pictures in backoffice for Pictures receipt type
    if (type === ClaimTypeTrigram.Robbery) {
        if (projection !== ModelFieldsProjection.Declaration &&
            projection !== ModelFieldsProjection.Pdf &&
            receiptType === PersonalPropertyReceiptType.Pictures) {
            fields.push(picturesField);
        }
    }
    else if (!otherDescriptionOnly) {
        fields.push(picturesField);
    }
    if (edition) {
        model.userKnowPrice = true;
        if (categoryIri === cashCategoryIri) {
            model.designation = 'montant en espèces';
            model.description = 'Espèces';
        }
    }
    const proofType = (_2 = (_1 = model.refund) === null || _1 === void 0 ? void 0 : _1.parameters) === null || _2 === void 0 ? void 0 : _2.proofType;
    if (projection === ModelFieldsProjection.Backoffice &&
        !edition &&
        proofType &&
        proofType !== ProofType.NotApplicable) {
        fields.push({
            label: 'Justification',
            key: 'proofType',
            value: proofType,
            displayedValue: PROOF_TYPES_LABELS[proofType],
            layout: { column: 1 },
        });
    }
    if (isGlassBreakagePersonalProperty(model)) {
        if (replaceableGlass) {
            if (projection === ModelFieldsProjection.Declaration) {
                fields.push({
                    label: 'Réparation',
                    key: 'reparations',
                    displayedValue: model.windowReparation
                        ? optionalTranslatableEnumTransform(model.windowReparationBy, BDG_PERSONAL_PROPERTY_REPARATION_BY_TRAD_KEY, translate, '-')
                        : 'Non',
                });
                if (model.windowReparationBy &&
                    [
                        BDGPersonalPropertyReparationBy.Myself,
                        BDGPersonalPropertyReparationBy.Professional,
                    ].includes(model.windowReparationBy) &&
                    fileCollectionFilesCount(model.windowReparationInvoice) > 0) {
                    fields.push({
                        label: 'Facture réparation',
                        key: 'reparationsInvoice',
                        displayedValue: formatOptionalCentsAmount(model.windowReparationInvoiceAmount, '-'),
                    });
                }
            }
            else {
                if (edition && model.windowReparation === undefined) {
                    model.windowReparation = null;
                }
                fields.push({
                    label: 'Vitres remplacées',
                    value: model.windowReparation,
                    displayedValue: optionalBooleanTransform(model.windowReparation, translate),
                    layout: { column: 1 },
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'windowReparation',
                    },
                });
                if (model.windowReparation) {
                    fields.push({
                        label: 'Auteur des réparations',
                        value: model.windowReparationBy,
                        displayedValue: optionalTranslatableEnumTransform(model.windowReparationBy, BDG_PERSONAL_PROPERTY_REPARATION_BY_TRAD_KEY, translate, '-'),
                        layout: { column: 1 },
                        editable: {
                            input: {
                                type: ModelFieldInputType.Radio,
                                choices: translateChoices(objectToTranslatableChoices(BDG_PERSONAL_PROPERTY_REPARATION_BY_TRAD_KEY), translate),
                                vertical: true,
                            },
                            modelKey: 'windowReparationBy',
                        },
                    });
                    if (model.windowReparationBy &&
                        [
                            BDGPersonalPropertyReparationBy.Myself,
                            BDGPersonalPropertyReparationBy.Professional,
                        ].includes(model.windowReparationBy) &&
                        projection !== ModelFieldsProjection.Pdf) {
                        fields.push({
                            label: 'Facture des réparations',
                            value: model.windowReparationInvoice,
                            displayedValue: {
                                type: 'fileCollection',
                                fileType: ClaimFileType.CraftsmanInvoice,
                                uploadLabel: translate('model.document.upload'),
                                addFileLabel: translate('model.document.upload.add_page'),
                                emptyLabel: translate('model.document.empty'),
                                fileCollection: model.windowReparationInvoice,
                            },
                            layout: { column: 1 },
                            editable: {
                                input: {
                                    type: ModelFieldInputType.FileCollection,
                                },
                                modelKey: 'windowReparationInvoice',
                            },
                        });
                        if (fileCollectionFilesCount(model.windowReparationInvoice) > 0) {
                            fields.push({
                                label: 'Montant de la facture',
                                value: model.windowReparationInvoiceAmount,
                                displayedValue: formatOptionalCentsAmount(model.windowReparationInvoiceAmount),
                                layout: { column: 1 },
                                editable: {
                                    input: {
                                        type: ModelFieldInputType.Amount,
                                    },
                                    unit: '€',
                                    modelKey: 'windowReparationInvoiceAmount',
                                },
                            });
                        }
                    }
                }
            }
        }
    }
    return { title, fields, editable: { constraints: personalPropertyConstraints }, model };
};
export const foodDamagesFields = (model, { categories, translate, projection }) => {
    const title = personalPropertyTitle(model, categories);
    const fields = [];
    const categoryIri = optionalCategoryIri('denrees-alimentaires', categories);
    if (categoryIri && projection !== ModelFieldsProjection.Backoffice) {
        fields.push({
            label: translate('model.personal_property.category'),
            value: categoryIri,
            displayedValue: categories[categoryIri].label,
            key: 'category',
        });
    }
    fields.push({
        label: translate('model.personal_property.amount_only'),
        value: model.amount,
        displayedValue: formatOptionalCentsAmount(model.amount),
        editable: {
            input: {
                type: ModelFieldInputType.Amount,
            },
            unit: '€',
            modelKey: 'amount',
        },
    });
    return { title, fields, editable: { constraints: personalPropertyConstraints }, model };
};
export function isBasePersonalProperty(pp) {
    return pp.type === PersonalPropertyType.Default;
}
export function isGlassBreakagePersonalProperty(pp) {
    return pp.type === PersonalPropertyType.GlassBreakage;
}
