import { LittleGateSize } from '@shared/types/api/externalDamage';
export const LITTLE_GATE_SIZE_IMAGES_AND_TRAD_KEYS = {
    [LittleGateSize.Small]: {
        tradKey: 'claim.declaration.external_damages.form.little_gate.size.small',
        descriptionTradKey: 'claim.declaration.external_damages.form.little_gate.size.small.description',
        image: require('@declaration/assets/images/externalDamage/littleGate/bas.svg'),
    },
    [LittleGateSize.Medium]: {
        tradKey: 'claim.declaration.external_damages.form.little_gate.size.medium',
        descriptionTradKey: 'claim.declaration.external_damages.form.little_gate.size.medium.description',
        image: require('@declaration/assets/images/externalDamage/littleGate/moyen.svg'),
    },
    [LittleGateSize.High]: {
        tradKey: 'claim.declaration.external_damages.form.little_gate.size.high',
        descriptionTradKey: 'claim.declaration.external_damages.form.little_gate.size.high.description',
        image: require('@declaration/assets/images/externalDamage/littleGate/haut.svg'),
    },
    [LittleGateSize.VeryHigh]: {
        tradKey: 'claim.declaration.external_damages.form.little_gate.size.very_high',
        descriptionTradKey: 'claim.declaration.external_damages.form.little_gate.size.very_high.description',
        image: require('@declaration/assets/images/externalDamage/littleGate/treshaut.svg'),
    },
};
