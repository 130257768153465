import { objectToTranslatableImageChoices } from '@shared/choice';
import { SHUTTER_TYPE_IMAGES_AND_TRAD_KEYS } from '@shared/constants/externalDamages';
export const SHUTTER_TITLE = 'claim.declaration.external_damages.form.shutters.title';
export const SHUTTER_SUBTITLE = 'claim.declaration.external_damages.form.shutters.subtitle';
export const SHUTTER_DETAILS_TITLE = 'claim.declaration.external_damages.form.shutters.details.title';
export const SHUTTER_COUNT_TITLE = 'claim.declaration.external_damages.form.shutters.count.title';
export const SHUTTER_MATERIAL_TITLE = 'claim.declaration.external_damages.form.shutters.material.title';
export const SHUTTER_MATERIAL_PLACEHOLDER = 'claim.declaration.external_damages.form.shutters.material.placeholder';
export const SHUTTER_WIDTH_TITLE = 'claim.declaration.external_damages.form.shutters.width.title';
export const SHUTTER_OPENING_TITLE = 'claim.declaration.external_damages.form.shutters.opening.title';
export const SHUTTER_TYPE_CHOICES = objectToTranslatableImageChoices(SHUTTER_TYPE_IMAGES_AND_TRAD_KEYS);
export const SHUTTERS_WIDTH_CHOICES = [
    {
        value: false,
        tradKey: 'claim.declaration.external_damages.form.shutters.width.lower',
    },
    {
        value: true,
        tradKey: 'claim.declaration.external_damages.form.shutters.width.greater',
    },
];
