import { CONFIG } from '@shared/environment/environment';
export default () => {
    if (CONFIG.crispWebsiteId === '') {
        return;
    }
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CONFIG.crispWebsiteId;
    (function () {
        const d = document;
        const s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = true;
        d.getElementsByTagName('head')[0].appendChild(s);
    })();
};
export function crispInstance() {
    if ('$crisp' in window) {
        return window.$crisp;
    }
    return null;
}
