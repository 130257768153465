import { EXTERNAL_DAMAGE_LOCALIZATIONS, EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { ExternalDamageLocalizationSlug, ExternalDamageOrigin, } from '@shared/types/api/externalDamage';
export const OTHER_EXTERNAL_DAMAGE_LOCALIZATIONS = {
    [ExternalDamageOrigin.Storm]: [
        ExternalDamageLocalizationSlug.Outbuilding,
        ExternalDamageLocalizationSlug.Other,
    ],
    [ExternalDamageOrigin.Hail]: [],
    [ExternalDamageOrigin.Snow]: [
        ExternalDamageLocalizationSlug.Outbuilding,
        ExternalDamageLocalizationSlug.Other,
    ],
    [ExternalDamageOrigin.Fire]: [
        ExternalDamageLocalizationSlug.Electricity,
        ExternalDamageLocalizationSlug.Plumbing,
        ExternalDamageLocalizationSlug.Other,
    ],
};
export function getOtherExternalDamageLocalizationChoices(origin) {
    const localizations = OTHER_EXTERNAL_DAMAGE_LOCALIZATIONS[origin];
    return localizations.map((value) => {
        const tradKey = EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS[origin][value];
        return { value, tradKey };
    });
}
export const isLocalisationEnabled = (localizationSlug, qualification) => {
    const key = 'causeType' in qualification ? qualification.causeType : ClaimTypeTrigram.Fire;
    return (!!key &&
        [...EXTERNAL_DAMAGE_LOCALIZATIONS[key], ...OTHER_EXTERNAL_DAMAGE_LOCALIZATIONS[key]].includes(localizationSlug));
};
export const ExternalDamageLocalizationSlugAppartmentExcluded = [
    ExternalDamageLocalizationSlug.Roof,
    ExternalDamageLocalizationSlug.Chimney,
    ExternalDamageLocalizationSlug.Veranda,
    ExternalDamageLocalizationSlug.GarageDoor,
    ExternalDamageLocalizationSlug.Outbuilding,
];
