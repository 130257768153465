const dayLabel = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const monthLabel = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];
function labelToSlug(label) {
    return label.toLowerCase().replace(': ', '').replace(' ', '-');
}
function getLabelFromDate(date) {
    return `${dayLabel[date.getDay()]} ${date.getDate()} ${monthLabel[date.getMonth()]}`;
}
export function generateAvailableTimeSlotsSchedule(availableTimeSlotsSchedule, startDate, endDate) {
    let offset = 0;
    return availableTimeSlotsSchedule
        .map((timeSlotsSchedule, index) => {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + index + offset);
        // Do not include saturdays and sundays
        while (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
            offset++;
            currentDate.setDate(startDate.getDate() + index + offset);
        }
        timeSlotsSchedule.date = currentDate;
        timeSlotsSchedule.label = getLabelFromDate(currentDate);
        timeSlotsSchedule.availableTimeSlots = timeSlotsSchedule.availableTimeSlots.map((timeSlot) => {
            timeSlot.slug = labelToSlug(`${getLabelFromDate(currentDate)} ${timeSlot.label}`);
            return timeSlot;
        });
        return timeSlotsSchedule;
    })
        .filter((timeSlotsSchedule) => {
        return timeSlotsSchedule.date.getTime() <= endDate.getTime();
    });
}
