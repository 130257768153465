import { translateChoices } from '@shared/choice';
import { LIGHT_SOURCE_TYPE_CHOICES, LIGHT_SOURCE_TYPE_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export const lightSourceFields = (model, { translate, stormSnowHailDamageCauseType }) => {
    const origin = stormSnowHailDamageCauseType !== null && stormSnowHailDamageCauseType !== void 0 ? stormSnowHailDamageCauseType : ClaimTypeTrigram.Fire;
    return [
        {
            label: translate('model.external_damage.light_source.type'),
            value: model.lightType,
            displayedValue: optionalTranslatableEnumTransform(model.lightType, LIGHT_SOURCE_TYPE_TRAD_KEYS[origin], translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(LIGHT_SOURCE_TYPE_CHOICES[origin], translate),
                },
                modelKey: 'lightType',
            },
        },
    ];
};
