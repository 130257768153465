import { parseDimension, parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { ModelFieldInputType } from '@shared/modelField/modelField';
export function exposedElectricalSheathDetails(model) {
    if (!model.type || !model.length)
        return null;
    return parseDimension(model.length);
}
export function exposedElectricalSheathFields(model, context) {
    var _a;
    const { translate, index } = context;
    return [
        {
            label: translate('model.good_damage.exposed_electrical_sheath.length'),
            value: ((_a = model.length) !== null && _a !== void 0 ? _a : 0) / 1000,
            displayedValue: parseOptionalDimension(model.length),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'm',
                modelKey: `goodDamages.${index}.length`,
                toPayload: (length) => ({ length: length * 1000 }),
            },
        },
    ];
}
