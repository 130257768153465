import { ImpactsNumber, Material, Measurement } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
import { GateFillStyle, GateType } from '@shared/types/api/externalDamage';
export const GATE_TYPE_TRAD_KEYS_AND_IMAGES = {
    [GateType.Sliding]: {
        tradKey: 'claim.declaration.break_in_damages.form.gate.type.sliding',
        sumupTradKey: 'claim.declaration.break_in_damages.form.gate.type.sliding.sumup',
        image: require('@declaration/assets/images/breakInDamage/gate/type/sliding.svg'),
    },
    [GateType.Flappy]: {
        tradKey: 'claim.declaration.break_in_damages.form.gate.type.flappy',
        sumupTradKey: 'claim.declaration.break_in_damages.form.gate.type.flappy.sumup',
        image: require('@declaration/assets/images/breakInDamage/gate/type/flappy.svg'),
    },
};
export const GATE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES = {
    [GateFillStyle.Plein]: {
        tradKey: 'claim.declaration.break_in_damages.form.gate.doorType.plein',
        sumupTradKey: 'claim.declaration.break_in_damages.form.gate.doorType.plein.sumup',
        image: {
            [GateType.Flappy]: require('@declaration/assets/images/breakInDamage/gate/doorType/flappy/plein.svg'),
            [GateType.Sliding]: require('@declaration/assets/images/breakInDamage/gate/doorType/sliding/plein.svg'),
        },
    },
    [GateFillStyle.Ajoure]: {
        tradKey: 'claim.declaration.break_in_damages.form.gate.doorType.ajoure',
        sumupTradKey: 'claim.declaration.break_in_damages.form.gate.doorType.ajoure.sumup',
        image: {
            [GateType.Flappy]: require('@declaration/assets/images/breakInDamage/gate/doorType/flappy/ajoure.svg'),
            [GateType.Sliding]: require('@declaration/assets/images/breakInDamage/gate/doorType/sliding/ajoure.svg'),
        },
    },
    [GateFillStyle.SemiAjoure]: {
        tradKey: 'claim.declaration.break_in_damages.form.gate.doorType.semi-ajoure',
        sumupTradKey: 'claim.declaration.break_in_damages.form.gate.doorType.semi-ajoure.sumup',
        image: {
            [GateType.Flappy]: require('@declaration/assets/images/breakInDamage/gate/doorType/flappy/semi-ajoure.svg'),
            [GateType.Sliding]: require('@declaration/assets/images/breakInDamage/gate/doorType/sliding/semi-ajoure.svg'),
        },
    },
};
export const GATE_MATERIAL_CHOICES = [
    {
        value: Material.Wood,
        tradKey: 'claim.declaration.break_in_damages.form.material.wood',
    },
    {
        value: Material.PVC,
        tradKey: 'claim.declaration.break_in_damages.form.material.pvc',
    },
    {
        value: Material.Steel,
        tradKey: 'claim.declaration.break_in_damages.form.material.steel',
    },
    {
        value: Material.Aluminium,
        tradKey: 'claim.declaration.break_in_damages.form.material.aluminium',
    },
];
export const WOODEN_GATE_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.AtLeastOne,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
    },
];
export const METAL_GATE_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.MoreThanThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    },
];
export const PVC_GATE_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.FourToSix,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.four-to-six',
    },
    {
        value: ImpactsNumber.MoreThanSeven,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-seven',
    },
];
export const GATE_HEIGHT_TRAD_KEYS = {
    [Measurement.LessThan150cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-150cm',
    [Measurement.Between150And170cm]: 'claim.declaration.break_in_damages.form.measurement.between-150-and-170cm',
    [Measurement.Between170And180cm]: 'claim.declaration.break_in_damages.form.measurement.between-170-and-180cm',
    [Measurement.MoreThan180cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-180cm',
};
export const GATE_WIDTH_TRAD_KEYS = {
    [Measurement.LessThan300cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-300cm',
    [Measurement.Between300And400cm]: 'claim.declaration.break_in_damages.form.measurement.between-300-and-400cm',
    [Measurement.MoreThan400cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-400cm',
};
export const GATE_IS_MOTORIZED_CHOICES = [
    {
        value: false,
        tradKey: 'claim.declaration.break_in_damages.form.gate.motorization.manual',
    },
    {
        value: true,
        tradKey: 'claim.declaration.break_in_damages.form.gate.motorization.motorized',
    },
];
export const REPAIR_STATUS_CHOICES_GATE = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.gate',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.gate',
    },
];
