import get from 'lodash/get';
import validateJs from 'validate.js';
export function presenceIfValue(value, { attribute, triggeringValue, message }, _key, attributes) {
    if (!validateJs.isEmpty(value)) {
        return;
    }
    const trigeringValues = Array.isArray(triggeringValue) ? triggeringValue : [triggeringValue];
    if (attributes) {
        if (validateJs.contains(trigeringValues, get(attributes, attribute))) {
            return message || 'this value is required';
        }
    }
}
