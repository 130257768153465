function formatCents(cents) {
    if (!cents) {
        return '00';
    }
    return cents.padEnd(2, '0');
}
export function formatAmountWithoutCurrency(amount, alwaysIncludeCents = false, alwaysIncludeSymbol = false) {
    let result = '0';
    if (amount > 0) {
        const asString = amount.toString(10);
        const [units, cents] = asString.split('.');
        const spaced = units.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '\xA0');
        if (cents || alwaysIncludeCents)
            result = `${spaced},${formatCents(cents)}`;
        else
            result = spaced;
    }
    else if (amount < 0) {
        return `-\xA0${formatAmountWithoutCurrency(-amount, alwaysIncludeCents)}`;
    }
    if (alwaysIncludeSymbol)
        return `+\xA0${result}`;
    return result;
}
export default function formatAmount(amount, alwaysIncludeCents = false, alwaysIncludeSymbol = false) {
    return `${formatAmountWithoutCurrency(amount, alwaysIncludeCents, alwaysIncludeSymbol)}\xA0€`;
}
