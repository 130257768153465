import { imageObjectToChoices, translatableObjectToChoices } from '@shared/choice';
import { GATE_MATERIAL_TRAD_KEYS, LITTLE_GATE_SIZE_IMAGES_AND_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { MATERIALS_TRAD_KEYS } from '@shared/types/api/breakInDamage';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export const littleGateFields = (model, { translate }) => {
    return [
        {
            label: translate('model.external_damage.material'),
            value: model.material,
            displayedValue: optionalTranslatableEnumTransform(model.material, MATERIALS_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(GATE_MATERIAL_TRAD_KEYS, translate),
                },
                modelKey: 'material',
            },
        },
        {
            label: translate('model.external_damage.height'),
            value: model.size,
            displayedValue: optionalTranslatableEnumTransform(model.size, LITTLE_GATE_SIZE_IMAGES_AND_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: imageObjectToChoices(LITTLE_GATE_SIZE_IMAGES_AND_TRAD_KEYS, translate),
                },
                modelKey: 'size',
            },
        },
    ];
};
