import { ElectricalHeaterType, ElectricMeterType, GoodDamageType, LightSourceType, LockerDoorType, PipeType, SlidingBayGlassType, SlidingBayGlazingType, SlidingBayLeafWidth, WcType, TowelDryerType, WaterRadiatorDamageType, WaterRadiatorMaterial, GarageDoorType, GarageDoorMaterial, GarageDoorDamageType, GarageDoorWidth, GarageDoorOpeningMode, DoorType, DoorMaterial, DoorDamageType, DoorGlazingType, DoorGlassType, DoorLockType, WindowMaterial, WindowDamageType, WindowGlazingType, FrenchWindowDamageType, FrenchWindowGlassType, FrenchWindowGlazingType, FrenchWindowLeafHeight, FrenchWindowLeafWidth, FrenchWindowMaterial, WindowGlassType, WindowLeafHeight, WindowLeafWidth, GoodDamageCategory, } from '@shared/types/api/roomDamage';
const goodDamageCategoryConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.category.validator.presence',
    },
};
const goodDamageTypeConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.category`,
        triggeringValue: [
            GoodDamageCategory.Electricity,
            GoodDamageCategory.Furniture,
            GoodDamageCategory.Heating,
            GoodDamageCategory.Opening,
            GoodDamageCategory.Plumbing,
        ],
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.category.validator.presence',
    },
});
const workplanWidthConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.workplan.width.validator.presence',
    },
    numericality: {
        greaterThan: 0,
        lessThan: 100000,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.workplan.width.validator.numericality',
    },
};
const workplanHeightConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.workplan.height.validator.presence',
    },
    numericality: {
        greaterThan: 0,
        lessThan: 100000,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.workplan.height.validator.numericality',
    },
};
const lockerDoorTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.door_type.validator.presence',
    },
    inclusion: {
        within: [LockerDoorType.SLIDING, LockerDoorType.SWING],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.door_type.validator.presence',
    },
};
const lockerDoorCountConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.door_count.validator.presence',
    },
    numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: 3,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.door_count.validator.numericality',
    },
};
const lockerIsLayoutDamagedConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.is_layout_damaged.validator.presence',
    },
};
const electricMeterTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.electric_meter.meter_type.validator.presence',
    },
    inclusion: {
        within: [ElectricMeterType.LINKY, ElectricMeterType.REGULAR],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.electric_meter.meter_type.validator.presence',
    },
};
const exposedElectricalSheathLengthConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.exposed_electrical_sheath.length.validator.presence',
    },
    numericality: {
        greaterThan: 0,
        lessThan: 100000,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.exposed_electrical_sheath.length.validator.numericality',
    },
};
const lightSourceTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.light_source.light_type.validator.presence',
    },
    inclusion: {
        within: [LightSourceType.DCL, LightSourceType.SPOT_LIGHT, LightSourceType.WALL_LIGHT],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.light_source.light_type.validator.presence',
    },
};
const visiblePipeTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes.pipe_type.validator.presence',
    },
    inclusion: {
        within: [PipeType.COPPER, PipeType.FLEXIBLE, PipeType.PEX, PipeType.UNKNOWN],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes.pipe_type.validator.presence',
    },
};
const visiblePipeLengthConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.pipeType`,
        triggeringValue: [PipeType.COPPER, PipeType.PEX, PipeType.UNKNOWN],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes.length.validator.presence',
    },
    numericality: {
        greaterThan: 0,
        lessThan: 100000,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes.length.validator.numericality',
    },
});
const wcTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.wc.wc_type.validator.presence',
    },
    inclusion: {
        within: [WcType.BLOCK, WcType.HUNG, WcType.MACERATOR],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.wc.wc_type.validator.presence',
    },
};
const electricalHeaterTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.electrical_heater.heater_type.validator.presence',
    },
    inclusion: {
        within: [
            ElectricalHeaterType.CONVECTION_HEATER,
            ElectricalHeaterType.INERTIA_RADIATOR,
            ElectricalHeaterType.RADIANT_RADIATOR,
            ElectricalHeaterType.UNKNOWN,
        ],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.electrical_heater.heater_type.validator.presence',
    },
};
const towelDryerTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.towel_dryer.dryer_type.validator.presence',
    },
    inclusion: {
        within: [TowelDryerType.ELECTRICAL_DRYER, TowelDryerType.WATER_DRYER, TowelDryerType.UNKNOWN],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.towel_dryer.dryer_type.validator.presence',
    },
};
const waterRadiatorDamageTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.damage_type.validator.presence',
    },
    inclusion: {
        within: [WaterRadiatorDamageType.AESTHETIC, WaterRadiatorDamageType.DAMAGED],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.damage_type.validator.presence',
    },
};
const waterRadiatorMaterialConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.damageType`,
        triggeringValue: WaterRadiatorDamageType.DAMAGED,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.material.validator.presence',
    },
    inclusion: {
        within: [
            WaterRadiatorMaterial.ALUMINUM_OR_STEEL,
            WaterRadiatorMaterial.CAST_IRON,
            WaterRadiatorMaterial.UNKNOWN,
        ],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.material.validator.presence',
    },
});
const slidingBayLeafCountConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_count.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 3,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_count.validator.numericality',
    },
};
const slidingBayIsGlassBrokenConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.is_glass_broken.validator.presence',
    },
};
const slidingBayGlazingTypeConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlassBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glazing_type.validator.presence',
    },
    inclusion: {
        within: [
            SlidingBayGlazingType.SIMPLE,
            SlidingBayGlazingType.DOUBLE,
            SlidingBayGlazingType.TRIPLE,
            SlidingBayGlazingType.UNKNOWN,
        ],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glazing_type.validator.presence',
    },
});
const slidingBayGlassTypeConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlassBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glass_type.validator.presence',
    },
    inclusion: {
        within: [
            SlidingBayGlassType.LAMINATED,
            SlidingBayGlassType.NON_LAMINATED,
            SlidingBayGlassType.UNKNOWN,
        ],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glass_type.validator.presence',
    },
});
const slidingBayLeafWidthConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlassBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_width.validator.presence',
    },
    inclusion: {
        within: [SlidingBayLeafWidth.SMALL, SlidingBayLeafWidth.MEDIUM, SlidingBayLeafWidth.LARGE],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_width.validator.presence',
    },
});
const garageDoorTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type.validator.presence',
    },
    inclusion: {
        within: Object.values(GarageDoorType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type.validator.presence',
    },
};
const garageDoorMaterialConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.material.validator.presence',
    },
    inclusion: {
        within: Object.values(GarageDoorMaterial),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.material.validator.presence',
    },
};
const garageDoorDamageTypeConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.material`,
        triggeringValue: GarageDoorMaterial.WOOD,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.damage_type.validator.presence',
    },
    inclusion: {
        within: Object.values(GarageDoorDamageType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.damage_type.validator.presence',
    },
});
const garageDoorWidthConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.material === GarageDoorMaterial.WOOD
            ? `goodDamages.${index}.damageType`
            : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.material === GarageDoorMaterial.WOOD
            ? GarageDoorDamageType.DAMAGED
            : [GarageDoorMaterial.PVC, GarageDoorMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_width.validator.presence',
    },
    inclusion: {
        within: Object.values(GarageDoorWidth),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_width.validator.presence',
    },
});
const garageDoorOpeningModeConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.material === GarageDoorMaterial.PVC ||
            goodDamage.material === GarageDoorMaterial.METAL ||
            goodDamage.damageType === GarageDoorDamageType.DAMAGED
            ? `goodDamages.${index}.doorWidth`
            : `goodDamages.${index}.damageType`,
        triggeringValue: goodDamage.material === GarageDoorMaterial.PVC ||
            goodDamage.material === GarageDoorMaterial.METAL ||
            goodDamage.damageType === GarageDoorDamageType.DAMAGED
            ? GarageDoorWidth.STANDARD
            : GarageDoorDamageType.AESTHETIC,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.opening_mode.validator.presence',
    },
    inclusion: {
        within: Object.values(GarageDoorOpeningMode),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.opening_mode.validator.presence',
    },
});
const garageDoorIsMotorWorkingConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.openingMode`,
        triggeringValue: GarageDoorOpeningMode.MOTORIZED,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.is_motor_working.validator.presence',
    },
});
const doorTypeConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.door_type.validator.presence',
    },
    inclusion: {
        within: Object.values(DoorType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.door_type.validator.presence',
    },
};
const doorLeafCountConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.doorType`,
        triggeringValue: [DoorType.MAIN_ENTRANCE, DoorType.SERVICE],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.leaf_count.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 2,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.leaf_count.validator.numericality',
    },
});
const doorMaterialConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.doorType`,
        triggeringValue: [DoorType.MAIN_ENTRANCE, DoorType.SERVICE],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.material.validator.presence',
    },
    inclusion: {
        within: Object.values(DoorMaterial),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.material.validator.presence',
    },
});
const doorDamageTypeConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.material`,
        triggeringValue: DoorMaterial.WOOD,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.damage_type.validator.presence',
    },
    inclusion: {
        within: Object.values(DoorDamageType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.damage_type.validator.presence',
    },
});
const doorHasGlazingConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.doorType`,
        triggeringValue: [DoorType.MAIN_ENTRANCE, DoorType.SERVICE],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.has_glazing.validator.presence',
    },
});
const doorIsGlazingBrokenConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.hasGlazing`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.is_glazing_broken.validator.presence',
    },
});
const doorTileCountConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.tile_count.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThan: 99,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.tile_count.validator.numericality',
    },
});
const doorBiggestTileWidthConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.biggest_tile_width.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 999999,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.biggest_tile_width.validator.numericality',
    },
});
const doorBiggestTileHeightConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.biggest_tile_height.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 999999,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.biggest_tile_height.validator.numericality',
    },
});
const doorGlazingTypeConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.damageType === DoorDamageType.AESTHETIC
            ? `goodDamages.${index}.isGlazingBroken`
            : `goodDamages.${index}.hasGlazing`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glazing_type.validator.presence',
    },
    inclusion: {
        within: Object.values(DoorGlazingType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glazing_type.validator.presence',
    },
});
const doorGlassTypeConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.damageType === DoorDamageType.AESTHETIC
            ? `goodDamages.${index}.isGlazingBroken`
            : `goodDamages.${index}.hasGlazing`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glass_type.validator.presence',
    },
    inclusion: {
        within: Object.values(DoorGlassType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glass_type.validator.presence',
    },
});
const doorLockTypeConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.material === DoorMaterial.WOOD
            ? `goodDamages.${index}.damageType`
            : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.material === DoorMaterial.WOOD
            ? DoorDamageType.DAMAGED
            : [DoorMaterial.PVC, DoorMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.validator.presence',
    },
    inclusion: {
        within: Object.values(DoorLockType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.validator.presence',
    },
});
const windowLeafCountConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.leaf_count.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 3,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.leaf_count.validator.numericality',
    },
};
const windowMaterialConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.material.validator.presence',
    },
    inclusion: {
        within: Object.values(WindowMaterial),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.material.validator.presence',
    },
};
const windowDamageTypeConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.material`,
        triggeringValue: WindowMaterial.WOOD,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.damage_type.validator.presence',
    },
    inclusion: {
        within: Object.values(WindowDamageType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.damage_type.validator.presence',
    },
});
const windowIsGlazingBrokenConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.damageType`,
        triggeringValue: WindowDamageType.AESTHETIC,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.is_glazing_broken.validator.presence',
    },
});
const windowTileCountConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.tile_count.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThan: 99,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.tile_count.validator.numericality',
    },
});
const windowBiggestTileWidthConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.biggest_tile_width.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 999999,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.biggest_tile_width.validator.numericality',
    },
});
const windowBiggestTileHeightConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.biggest_tile_height.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 999999,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.biggest_tile_height.validator.numericality',
    },
});
const windowGlazingTypeConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.damageType === WindowDamageType.AESTHETIC
            ? `goodDamages.${index}.isGlazingBroken`
            : goodDamage.material === WindowMaterial.WOOD
                ? `goodDamages.${index}.damageType`
                : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.damageType === WindowDamageType.AESTHETIC
            ? true
            : goodDamage.material === WindowMaterial.WOOD
                ? WindowDamageType.DAMAGED
                : [WindowMaterial.PVC, WindowMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glazing_type.validator.presence',
    },
    inclusion: {
        within: Object.values(WindowGlazingType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glazing_type.validator.presence',
    },
});
const windowGlassTypeConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.damageType === WindowDamageType.AESTHETIC
            ? `goodDamages.${index}.isGlazingBroken`
            : goodDamage.material === WindowMaterial.WOOD
                ? `goodDamages.${index}.damageType`
                : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.damageType === WindowDamageType.AESTHETIC
            ? true
            : goodDamage.material === WindowMaterial.WOOD
                ? WindowDamageType.DAMAGED
                : [WindowMaterial.PVC, WindowMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glass_type.validator.presence',
    },
    inclusion: {
        within: Object.values(WindowGlassType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glass_type.validator.presence',
    },
});
const windowLeafWidthConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.material === WindowMaterial.WOOD
            ? `goodDamages.${index}.damageType`
            : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.material === WindowMaterial.WOOD
            ? WindowDamageType.DAMAGED
            : [WindowMaterial.PVC, WindowMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.leaf_width.validator.presence',
    },
    inclusion: {
        within: Object.values(WindowLeafWidth),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.leaf_width.validator.presence',
    },
});
const windowLeafHeightConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.material === WindowMaterial.WOOD
            ? `goodDamages.${index}.damageType`
            : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.material === WindowMaterial.WOOD
            ? WindowDamageType.DAMAGED
            : [WindowMaterial.PVC, WindowMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.leaf_height.validator.presence',
    },
    inclusion: {
        within: Object.values(WindowLeafHeight),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.leaf_height.validator.presence',
    },
});
const frenchWindowLeafCountConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_count.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 3,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_count.validator.numericality',
    },
};
const frenchWindowMaterialConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.material.validator.presence',
    },
    inclusion: {
        within: Object.values(FrenchWindowMaterial),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.material.validator.presence',
    },
};
const frenchWindowDamageTypeConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.material`,
        triggeringValue: FrenchWindowMaterial.WOOD,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.damage_type.validator.presence',
    },
    inclusion: {
        within: Object.values(FrenchWindowDamageType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.damage_type.validator.presence',
    },
});
const frenchWindowIsGlazingBrokenConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.damageType`,
        triggeringValue: FrenchWindowDamageType.AESTHETIC,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.is_glazing_broken.validator.presence',
    },
});
const frenchWindowTileCountConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.tile_count.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThan: 99,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.tile_count.validator.numericality',
    },
});
const frenchWindowBiggestTileWidthConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.biggest_tile_width.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 999999,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.biggest_tile_width.validator.numericality',
    },
});
const frenchWindowBiggestTileHeightConstraints = (index) => ({
    presenceIfValue: {
        attribute: `goodDamages.${index}.isGlazingBroken`,
        triggeringValue: true,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.biggest_tile_height.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 999999,
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.biggest_tile_height.validator.numericality',
    },
});
const frenchWindowGlazingTypeConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.damageType === FrenchWindowDamageType.AESTHETIC
            ? `goodDamages.${index}.isGlazingBroken`
            : goodDamage.material === FrenchWindowMaterial.WOOD
                ? `goodDamages.${index}.damageType`
                : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.damageType === FrenchWindowDamageType.AESTHETIC
            ? true
            : goodDamage.material === FrenchWindowMaterial.WOOD
                ? FrenchWindowDamageType.DAMAGED
                : [FrenchWindowMaterial.PVC, FrenchWindowMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glazing_type.validator.presence',
    },
    inclusion: {
        within: Object.values(FrenchWindowGlazingType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glazing_type.validator.presence',
    },
});
const frenchWindowGlassTypeConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.damageType === FrenchWindowDamageType.AESTHETIC
            ? `goodDamages.${index}.isGlazingBroken`
            : goodDamage.material === FrenchWindowMaterial.WOOD
                ? `goodDamages.${index}.damageType`
                : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.damageType === FrenchWindowDamageType.AESTHETIC
            ? true
            : goodDamage.material === FrenchWindowMaterial.WOOD
                ? FrenchWindowDamageType.DAMAGED
                : [FrenchWindowMaterial.PVC, FrenchWindowMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glass_type.validator.presence',
    },
    inclusion: {
        within: Object.values(FrenchWindowGlassType),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glass_type.validator.presence',
    },
});
const frenchWindowLeafWidthConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.material === FrenchWindowMaterial.WOOD
            ? `goodDamages.${index}.damageType`
            : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.material === FrenchWindowMaterial.WOOD
            ? FrenchWindowDamageType.DAMAGED
            : [FrenchWindowMaterial.PVC, FrenchWindowMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_width.validator.presence',
    },
    inclusion: {
        within: Object.values(FrenchWindowLeafWidth),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_width.validator.presence',
    },
});
const frenchWindowLeafHeightConstraints = (index, goodDamage) => ({
    presenceIfValue: {
        attribute: goodDamage.material === FrenchWindowMaterial.WOOD
            ? `goodDamages.${index}.damageType`
            : `goodDamages.${index}.material`,
        triggeringValue: goodDamage.material === FrenchWindowMaterial.WOOD
            ? FrenchWindowDamageType.DAMAGED
            : [FrenchWindowMaterial.PVC, FrenchWindowMaterial.METAL],
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_height.validator.presence',
    },
    inclusion: {
        within: Object.values(FrenchWindowLeafHeight),
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_height.validator.presence',
    },
});
function createWorkplanConstraints(constraints, index) {
    constraints[`goodDamages.${index}.width`] = workplanWidthConstraints;
    constraints[`goodDamages.${index}.height`] = workplanHeightConstraints;
}
function createLockerConstraints(constraints, index) {
    constraints[`goodDamages.${index}.doorType`] = lockerDoorTypeConstraints;
    constraints[`goodDamages.${index}.doorCount`] = lockerDoorCountConstraints;
    constraints[`goodDamages.${index}.isLayoutDamaged`] = lockerIsLayoutDamagedConstraints;
}
function createElectricMeterConstraints(constraints, index) {
    constraints[`goodDamages.${index}.meterType`] = electricMeterTypeConstraints;
}
function createExposedElectricalSheathConstraints(constraints, index) {
    constraints[`goodDamages.${index}.length`] = exposedElectricalSheathLengthConstraints;
}
function createLightSourceConstraints(constraints, index) {
    constraints[`goodDamages.${index}.lightType`] = lightSourceTypeConstraints;
}
function createVisiblePipeConstraints(constraints, index) {
    constraints[`goodDamages.${index}.pipeType`] = visiblePipeTypeConstraints;
    constraints[`goodDamages.${index}.length`] = visiblePipeLengthConstraints(index);
}
function createWcConstraints(constraints, index) {
    constraints[`goodDamages.${index}.wcType`] = wcTypeConstraints;
}
function createElectricalHeaterConstraints(constraints, index) {
    constraints[`goodDamages.${index}.heaterType`] = electricalHeaterTypeConstraints;
}
function createTowelDryerConstraints(constraints, index) {
    constraints[`goodDamages.${index}.dryerType`] = towelDryerTypeConstraints;
}
function createWaterRadiatorConstraints(constraints, index) {
    constraints[`goodDamages.${index}.damageType`] = waterRadiatorDamageTypeConstraints;
    constraints[`goodDamages.${index}.material`] = waterRadiatorMaterialConstraints(index);
}
function createSlidingBayConstraints(constraints, index) {
    constraints[`goodDamages.${index}.leafCount`] = slidingBayLeafCountConstraints;
    constraints[`goodDamages.${index}.isGlassBroken`] = slidingBayIsGlassBrokenConstraints;
    constraints[`goodDamages.${index}.glazingType`] = slidingBayGlazingTypeConstraints(index);
    constraints[`goodDamages.${index}.glassType`] = slidingBayGlassTypeConstraints(index);
    constraints[`goodDamages.${index}.leafWidth`] = slidingBayLeafWidthConstraints(index);
}
function createGarageDoorConstraints(constraints, index, goodDamage) {
    constraints[`goodDamages.${index}.doorType`] = garageDoorTypeConstraints;
    constraints[`goodDamages.${index}.material`] = garageDoorMaterialConstraints;
    constraints[`goodDamages.${index}.damageType`] = garageDoorDamageTypeConstraints(index);
    constraints[`goodDamages.${index}.doorWidth`] = garageDoorWidthConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.openingMode`] = garageDoorOpeningModeConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.isMotorWorking`] = garageDoorIsMotorWorkingConstraints(index);
}
function createDoorConstraints(constraints, index, goodDamage) {
    constraints[`goodDamages.${index}.doorType`] = doorTypeConstraints;
    constraints[`goodDamages.${index}.leafCount`] = doorLeafCountConstraints(index);
    constraints[`goodDamages.${index}.material`] = doorMaterialConstraints(index);
    constraints[`goodDamages.${index}.damageType`] = doorDamageTypeConstraints(index);
    constraints[`goodDamages.${index}.hasGlazing`] = doorHasGlazingConstraints(index);
    if (goodDamage.damageType === DoorDamageType.AESTHETIC) {
        constraints[`goodDamages.${index}.isGlazingBroken`] = doorIsGlazingBrokenConstraints(index);
        constraints[`goodDamages.${index}.tileCount`] = doorTileCountConstraints(index);
        constraints[`goodDamages.${index}.biggestTileWidth`] = doorBiggestTileWidthConstraints(index);
        constraints[`goodDamages.${index}.biggestTileHeight`] = doorBiggestTileHeightConstraints(index);
    }
    constraints[`goodDamages.${index}.glazingType`] = doorGlazingTypeConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.glassType`] = doorGlassTypeConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.lockType`] = doorLockTypeConstraints(index, goodDamage);
}
function createWindowConstraints(constraints, index, goodDamage) {
    constraints[`goodDamages.${index}.leafCount`] = windowLeafCountConstraints;
    constraints[`goodDamages.${index}.material`] = windowMaterialConstraints;
    constraints[`goodDamages.${index}.damageType`] = windowDamageTypeConstraints(index);
    constraints[`goodDamages.${index}.isGlazingBroken`] = windowIsGlazingBrokenConstraints(index);
    constraints[`goodDamages.${index}.tileCount`] = windowTileCountConstraints(index);
    constraints[`goodDamages.${index}.biggestTileWidth`] = windowBiggestTileWidthConstraints(index);
    constraints[`goodDamages.${index}.biggestTileHeight`] = windowBiggestTileHeightConstraints(index);
    constraints[`goodDamages.${index}.glazingType`] = windowGlazingTypeConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.glassType`] = windowGlassTypeConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.leafWidth`] = windowLeafWidthConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.leafHeight`] = windowLeafHeightConstraints(index, goodDamage);
}
function createFrenchWindowConstraints(constraints, index, goodDamage) {
    constraints[`goodDamages.${index}.leafCount`] = frenchWindowLeafCountConstraints;
    constraints[`goodDamages.${index}.material`] = frenchWindowMaterialConstraints;
    constraints[`goodDamages.${index}.damageType`] = frenchWindowDamageTypeConstraints(index);
    constraints[`goodDamages.${index}.isGlazingBroken`] =
        frenchWindowIsGlazingBrokenConstraints(index);
    constraints[`goodDamages.${index}.tileCount`] = frenchWindowTileCountConstraints(index);
    constraints[`goodDamages.${index}.biggestTileWidth`] =
        frenchWindowBiggestTileWidthConstraints(index);
    constraints[`goodDamages.${index}.biggestTileHeight`] =
        frenchWindowBiggestTileHeightConstraints(index);
    constraints[`goodDamages.${index}.glazingType`] = frenchWindowGlazingTypeConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.glassType`] = frenchWindowGlassTypeConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.leafWidth`] = frenchWindowLeafWidthConstraints(index, goodDamage);
    constraints[`goodDamages.${index}.leafHeight`] = frenchWindowLeafHeightConstraints(index, goodDamage);
}
export function createGoodDamagesConstraints(goodDamages) {
    if (!goodDamages)
        return {};
    const constraints = {};
    Array.from(goodDamages).forEach((goodDamage, index) => {
        constraints[`goodDamages.${index}.category`] = goodDamageCategoryConstraints;
        constraints[`goodDamages.${index}.type`] = goodDamageTypeConstraints(index);
        switch (goodDamage.type) {
            case GoodDamageType.Workplan:
                createWorkplanConstraints(constraints, index);
                break;
            case GoodDamageType.Locker:
                createLockerConstraints(constraints, index);
                break;
            case GoodDamageType.ElectricMeter:
                createElectricMeterConstraints(constraints, index);
                break;
            case GoodDamageType.ExposedElectricalSheath:
                createExposedElectricalSheathConstraints(constraints, index);
                break;
            case GoodDamageType.LightSource:
                createLightSourceConstraints(constraints, index);
                break;
            case GoodDamageType.VisiblePipes:
                createVisiblePipeConstraints(constraints, index);
                break;
            case GoodDamageType.WC:
                createWcConstraints(constraints, index);
                break;
            case GoodDamageType.ElectricHeater:
                createElectricalHeaterConstraints(constraints, index);
                break;
            case GoodDamageType.TowelDryer:
                createTowelDryerConstraints(constraints, index);
                break;
            case GoodDamageType.WaterRadiator:
                createWaterRadiatorConstraints(constraints, index);
                break;
            case GoodDamageType.SlidingBay:
                createSlidingBayConstraints(constraints, index);
                break;
            case GoodDamageType.GarageDoor:
                createGarageDoorConstraints(constraints, index, goodDamage);
                break;
            case GoodDamageType.Door:
                createDoorConstraints(constraints, index, goodDamage);
                break;
            case GoodDamageType.Window:
                createWindowConstraints(constraints, index, goodDamage);
                break;
            case GoodDamageType.FrenchWindow:
                createFrenchWindowConstraints(constraints, index, goodDamage);
                break;
        }
    });
    return constraints;
}
