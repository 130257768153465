import { isMobile } from '@declaration/utils/layout';
export function updateLayoutOnScreenSizeChange(vue) {
    const dispatchOnSizeChange = () => {
        const current = isMobile();
        if (current !== vue.$store.state.layout.isMobile) {
            vue.$store.dispatch('layout/setMobile', current);
        }
    };
    window.addEventListener('resize', dispatchOnSizeChange);
    return () => {
        window.removeEventListener('resize', dispatchOnSizeChange);
    };
}
