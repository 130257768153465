import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { damageSeverityField, dimensionsFields, editionMaterialField, impactsNumberField, otherDamagesFields, } from '@shared/breakInDamage/breakInDamageFields';
import { translatableObjectToChoices } from '@shared/choice';
import { LITTLE_GATE_HEIGHT_TRAD_KEYS_AND_IMAGES } from '@shared/constants/breakInDamages/LittleGateConstants';
import { DamageSeverity, ImpactsNumber, Material } from '@shared/types/api/breakInDamage';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <LittleGate>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function littleGateBreakInDamageEditionFields(model, translate, projection, edition = false) {
    const fields = [];
    if (edition) {
        fields.push(editionMaterialField(model, translate));
    }
    fields.push(damageSeverityField(model, translate));
    if (model.damageSeverity === DamageSeverity.Critical) {
        if (edition) {
            fields.push(littleGateBreakInDamageCriticaleSeverityField(model, translate));
        }
    }
    else if (model.damageSeverity === DamageSeverity.Notable) {
        fields.push(impactsNumberField(model, translate));
        if (edition) {
            if ((model.material === Material.PVC &&
                model.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
                (model.material === Material.Metal && model.numberOfImpacts === ImpactsNumber.MoreThanThree)) {
                fields.push(littleGateBreakInDamageCriticaleSeverityField(model, translate));
            }
            else {
                fields.push({
                    label: translate('model.break_in_damage.lock_damage'),
                    key: 'littleGateLockIsDamaged',
                    value: model.littleGateLockIsDamaged,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'littleGateLockIsDamaged',
                    },
                });
            }
        }
        if (projection !== ModelFieldsProjection.Declaration &&
            !(model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven) &&
            !(model.material === Material.Metal && model.numberOfImpacts === ImpactsNumber.MoreThanThree)) {
            fields.push(...otherDamagesFields(model, translate));
        }
    }
    // We check the conditions for critical severity again but this time also checking if we're in
    // readonly mode because in this mode we want the dimensions fields to appear at the end
    if (!edition &&
        (model.damageSeverity === DamageSeverity.Critical ||
            (model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
            (model.material === Material.Metal && model.numberOfImpacts === ImpactsNumber.MoreThanThree))) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function littleGateBreakInDamageCriticaleSeverityField(model, translate) {
    return {
        label: translate('model.break_in_damage.little_gate.height'),
        key: 'littleGateHeight',
        value: model.littleGateHeight,
        editable: {
            input: {
                type: ModelFieldInputType.Select,
                choices: translatableObjectToChoices(LITTLE_GATE_HEIGHT_TRAD_KEYS_AND_IMAGES, translate),
            },
            modelKey: 'littleGateHeight',
        },
    };
}
