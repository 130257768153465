export var ClaimFileType;
(function (ClaimFileType) {
    ClaimFileType["PersonalPropertyPicture"] = "personal-property-picture";
    ClaimFileType["CraftsmanInvoice"] = "artisan-invoice";
    ClaimFileType["PersonalPropertyReceipt"] = "personal-property-receipt";
    ClaimFileType["PersonalPropertyRepairQuote"] = "personal-property-repair-quote";
    ClaimFileType["PersonalPropertyIrreparabilityCertificate"] = "personal-property-irreparability-certificate";
    ClaimFileType["RoomDamage"] = "room-damage";
    ClaimFileType["Receipt"] = "receipt";
    ClaimFileType["PublicWorksDocument"] = "public-works-document";
    ClaimFileType["ProfessionalIntervention"] = "professional-intervention";
    ClaimFileType["RealEstateQuote"] = "real-estate-quote";
    ClaimFileType["RealEstateIrreparabilityCertificate"] = "real-estate-irreparability-certificate";
    ClaimFileType["ComplaintReceipt"] = "complaint-receipt";
    ClaimFileType["ExternalDamagePicture"] = "external-damage-picture";
    ClaimFileType["BreakInDamagePicture"] = "break-in-damage-picture";
    ClaimFileType["BreakInDamageRepairReceipt"] = "break-in-damage-repair-receipt";
    ClaimFileType["GlassDamagePicture"] = "glass-damage-picture";
    ClaimFileType["GlassDamageRepairReceipt"] = "glass-damage-repair-receipt";
    ClaimFileType["EquipmentReceipt"] = "equipment-receipt";
    ClaimFileType["EquipmentPicture"] = "equipment-picture";
    ClaimFileType["LocksReplacementQuote"] = "locks-replacement-quote";
    ClaimFileType["RENCounterOfferFile"] = "ren-counter-offer-file";
    ClaimFileType["GEDFile"] = "ged-file";
    ClaimFileType["TenantLease"] = "tenant-lease";
})(ClaimFileType || (ClaimFileType = {}));
export const CLAIM_FILE_TYPE_TRAD_KEYS = {
    [ClaimFileType.PersonalPropertyPicture]: 'model.file.type.personal_property_picture',
    [ClaimFileType.CraftsmanInvoice]: 'model.file.type.artisan_invoice',
    [ClaimFileType.PersonalPropertyReceipt]: 'model.file.type.personal_property_receipt',
    [ClaimFileType.PersonalPropertyRepairQuote]: 'model.file.type.personal_property_repair_quote',
    [ClaimFileType.PersonalPropertyIrreparabilityCertificate]: 'model.file.type.personal_property_irreparability_certificate',
    [ClaimFileType.RoomDamage]: 'model.file.type.room_damage',
    [ClaimFileType.Receipt]: 'model.file.type.receipt',
    [ClaimFileType.PublicWorksDocument]: 'model.file.type.public_works_document',
    [ClaimFileType.ProfessionalIntervention]: 'model.file.type.professional_intervention',
    [ClaimFileType.RealEstateQuote]: 'model.file.type.real_estate_quote',
    [ClaimFileType.RealEstateIrreparabilityCertificate]: 'model.file.type.real_estate_irreparability_certificate',
    [ClaimFileType.ComplaintReceipt]: 'model.file.type.complaint_receipt',
    [ClaimFileType.ExternalDamagePicture]: 'model.file.type.external_damage_picture',
    [ClaimFileType.BreakInDamagePicture]: 'model.file.type.break_in_damage_picture',
    [ClaimFileType.BreakInDamageRepairReceipt]: 'model.file.type.break_in_damage_repair_receipt',
    [ClaimFileType.GlassDamagePicture]: 'model.file.type.glass_damage_picture',
    [ClaimFileType.GlassDamageRepairReceipt]: 'model.file.type.glass_damage_picture',
    [ClaimFileType.EquipmentReceipt]: 'model.file.type.equipment_receipt',
    [ClaimFileType.EquipmentPicture]: 'model.file.type.equipment_picture',
    [ClaimFileType.LocksReplacementQuote]: 'model.file.type.locks_replacement_quote',
    [ClaimFileType.RENCounterOfferFile]: 'model.file.type.ren_counter_offer_file',
    [ClaimFileType.GEDFile]: 'model.file.type.ged_file',
    [ClaimFileType.TenantLease]: 'model.file.type.tenant_lease',
};
