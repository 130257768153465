export var NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST;
(function (NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST) {
    NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST["unknown"] = "unknown";
    NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST["notInList"] = "not_in_list";
})(NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST || (NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST = {}));
export const NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST_CHOICES = [
    {
        value: NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST.unknown,
        tradKey: 'claim.third_party.form.insurance_contact.question.name.unknown',
    },
    {
        value: NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST.notInList,
        tradKey: 'claim.third_party.form.insurance_contact.question.name.not_in_list',
    },
];
