import Vue from 'vue';
import { generateAvailableTimeSlotsSchedule } from '@declaration/constants/availableTimeSlotsSchedule';
import appointmentConstraints from '@shared/validator/constraints/appointmentConstraints';
import { ValidationContext } from '@shared/validator/validationContext';
import { newValidator, revealViolations, touch, validate } from '@shared/validator/validator';
export const name = 'artisanAppointment';
export const namespaced = true;
const newUserContact = () => ({
    email: null,
    phoneNumber: null,
    addressDetails: null,
});
export const state = () => {
    const userContact = newUserContact();
    const validator = newValidator(userContact);
    validate(validator, userContact, appointmentConstraints);
    return {
        artisanName: 'Dynaren',
        artisanExtranetLink: 'https://extranet.dynaren.fr',
        availableTimeSlotsSchedule: [],
        selectedTimeSlotSlug: null,
        userContact,
        validator: newValidator(userContact),
    };
};
export const getters = {
    validation(state) {
        return new ValidationContext(state.validator);
    },
    displayedArtisanAvailableTimeSlotsScheduleByDays(state) {
        const currentDate = new Date();
        return state.availableTimeSlotsSchedule
            .filter((daySchedule) => daySchedule.date.getTime() >= currentDate.getTime())
            .filter((daySchedule) => daySchedule.availableTimeSlots.length)
            .map((daySchedule) => {
            const displayedTimeSlots = daySchedule.availableTimeSlots.map((timeSlot) => {
                return {
                    slug: timeSlot.slug,
                    label: timeSlot.label,
                };
            });
            return {
                title: {
                    label: daySchedule.label,
                },
                availableTimeSlots: displayedTimeSlots,
            };
        });
    },
    selectedTimeSlotDay(state) {
        return state.availableTimeSlotsSchedule.find((daySchedule) => {
            return !!daySchedule.availableTimeSlots.find((timeSlot) => timeSlot.slug === state.selectedTimeSlotSlug);
        });
    },
    selectedTimeSlot(state, getters) {
        if (!getters.selectedTimeSlotDay) {
            return null;
        }
        return getters.selectedTimeSlotDay.availableTimeSlots.find((timeSlot) => timeSlot.slug === state.selectedTimeSlotSlug);
    },
};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    updateContact({ commit }, payload) {
        commit('UPDATE_APPOINTMENT_CONTACT', payload);
    },
    validateContact({ commit }) {
        commit('VALIDATE_AND_REVEAL_CONTACT');
    },
    // eslint-disable-next-line require-await
    async fetchArtisanAppointments({ commit }) {
        commit('RESET_STATE_WITHOUT_CONTACT');
        const availableTimeSlotsScheduleStructure = [
            {
                date: new Date(2019, 10, 2),
                label: 'Samedi 2 Novembre',
                availableTimeSlots: [
                    {
                        slug: 'samedi-2-novembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'samedi-2-novembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                ],
            },
            {
                date: new Date(2019, 10, 30),
                label: 'Samedi 30 Novembre',
                availableTimeSlots: [
                    {
                        slug: 'samedi-30-novembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'samedi-30-novembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 1),
                label: 'Dimanche 1 Décembre',
                availableTimeSlots: [],
            },
            {
                date: new Date(2019, 11, 2),
                label: 'Lundi 2 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'lundi-2-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'lundi-2-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                    {
                        slug: 'lundi-2-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 3),
                label: 'Mardi 3 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'mardi-3-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'mardi-3-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 4),
                label: 'Mercredi 4 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'mercredi-4-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'mercredi-4-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 5),
                label: 'Jeudi 5 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'jeudi-5-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'jeudi-5-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                    {
                        slug: 'jeudi-5-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 6),
                label: 'Vendredi 6 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'vendredi-6-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                    {
                        slug: 'vendredi-6-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 7),
                label: 'Samedi 7 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'samedi-7-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'samedi-7-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 8),
                label: 'Dimanche 8 Décembre',
                availableTimeSlots: [],
            },
            {
                date: new Date(2019, 11, 9),
                label: 'Lundi 9 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'lundi-9-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'lundi-9-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 10),
                label: 'Mardi 10 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'mardi-10-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 11),
                label: 'Mercredi 11 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'mercredi-11-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'mercredi-11-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                    {
                        slug: 'mercredi-11-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 12),
                label: 'Jeudi 12 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'jeudi-12-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'jeudi-12-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                    {
                        slug: 'jeudi-12-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 13),
                label: 'Vendredi 13 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'vendredi-13-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                    {
                        slug: 'vendredi-13-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 14),
                label: 'Samedi 14 Décembre',
                availableTimeSlots: [],
            },
            {
                date: new Date(2019, 11, 15),
                label: 'Dimanche 15 Décembre',
                availableTimeSlots: [],
            },
            {
                date: new Date(2019, 11, 16),
                label: 'Lundi 16 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'lundi-16-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                    {
                        slug: 'lundi-16-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 17),
                label: 'Mardi 17 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'mardi-17-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'mardi-17-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 18),
                label: 'Mercredi 18 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'mercredi-18-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'mercredi-18-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                    {
                        slug: 'mercredi-18-decembre-apres-midi-14h-18h30',
                        label: 'Après-midi : 14h - 18h30',
                        startHour: '14h',
                        endHour: '18h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 19),
                label: 'Jeudi 19 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'jeudi-19-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'jeudi-19-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 20),
                label: 'Vendredi 20 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'vendredi-20-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 21),
                label: 'Samedi 21 Décembre',
                availableTimeSlots: [
                    {
                        slug: 'samedi-21-decembre-matin-9h30-12h',
                        label: 'Matin : 9h30 - 12h',
                        startHour: '9h30',
                        endHour: '12h',
                    },
                    {
                        slug: 'samedi-21-decembre-midi-12h-14h30',
                        label: 'Midi : 12h - 14h30',
                        startHour: '12h',
                        endHour: '14h30',
                    },
                ],
            },
            {
                date: new Date(2019, 11, 22),
                label: 'Dimanche 22 Décembre',
                availableTimeSlots: [],
            },
        ];
        const startDate = new Date();
        startDate.setDate(startDate.getDate() + 1);
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 15);
        commit('SET_AVAILABLE_TIME_SLOTS_SCHEDULE', generateAvailableTimeSlotsSchedule(availableTimeSlotsScheduleStructure, startDate, endDate));
    },
    setSelectedTimeSlotSlug({ commit }, selectedTimeSlotSlug) {
        commit('SET_SELECTED_TIME_SLOT_SLUG', selectedTimeSlotSlug);
    },
    async confirmAppointment({ dispatch, rootState }, { companyName, extranetLink, label, startDateTime, endDateTime, userContact, }) {
        if (!rootState.claim.claim.id) {
            return;
        }
        const payload = {
            claim: rootState.claim.claim.id,
            artisanCompanyName: companyName,
            artisanExtranetLink: extranetLink,
            artisanAppointmentLabel: label,
            startDateTime,
            endDateTime,
            userContact,
        };
        try {
            await this.$axios.post('/artisan_appointments', payload);
        }
        catch (_a) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
        }
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    RESET_STATE_WITHOUT_CONTACT(stateObject) {
        const userContact = stateObject.userContact;
        Object.assign(stateObject, state());
        Object.assign(stateObject.userContact, userContact);
    },
    SET_AVAILABLE_TIME_SLOTS_SCHEDULE(state, availableTimeSlotsSchedule) {
        Vue.set(state, 'availableTimeSlotsSchedule', availableTimeSlotsSchedule);
    },
    SET_SELECTED_TIME_SLOT_SLUG(state, selectedTimeSlotSlug) {
        Vue.set(state, 'selectedTimeSlotSlug', selectedTimeSlotSlug);
    },
    UPDATE_APPOINTMENT_CONTACT(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            Vue.set(state.userContact, key, value);
            touch(state.validator, key);
            validate(state.validator, state.userContact, appointmentConstraints);
        });
    },
    VALIDATE_AND_REVEAL_CONTACT(state) {
        validate(state.validator, state.userContact, appointmentConstraints);
        revealViolations(state.validator);
    },
};
