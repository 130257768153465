import { ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { damageSeverityField, dimensionsFields, editionMaterialField, impactsNumberField, otherDamagesFields, } from '@shared/breakInDamage/breakInDamageFields';
import { translatableObjectToChoices, translateChoices } from '@shared/choice';
import { FLAPPY_SHUTTER_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, SHUTTER_BAR_TYPE_TRAD_KEYS_AND_IMAGES, SHUTTER_TYPE_SUMUP_TRAD_KEYS, SHUTTER_WINDOW_WIDTH_CHOICES, } from '@shared/constants/breakInDamages/ShutterConstants';
import { BreakInDamageShutterType, DamageSeverity, ImpactsNumber, Material, ShutterBarTypes, } from '@shared/types/api/breakInDamage';
import { SHUTTERS_MOTORIZATION_CHOICES } from '@shared/constants/externalDamages/ShutterConstants';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <Shutter>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function shutterBreakInDamageEditionFields(model, translate, projection, edition = false) {
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.break_in_damage.shutter.type'),
            key: 'shutterType',
            value: model.shutterType,
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(SHUTTER_TYPE_SUMUP_TRAD_KEYS, translate),
                },
                modelKey: 'shutterType',
            },
        });
        if (model.shutterType === BreakInDamageShutterType.Flappy) {
            fields.push({
                label: translate('model.break_in_damage.leaves_type'),
                key: 'flappyShutterLeaves',
                value: model.flappyShutterLeaves,
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translatableObjectToChoices(FLAPPY_SHUTTER_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                    },
                    modelKey: 'flappyShutterLeaves',
                },
            });
        }
        else if (model.shutterType === BreakInDamageShutterType.Bar) {
            fields.push({
                label: translate('model.break_in_damage.shutter.bar_type'),
                key: 'shutterBarTypes',
                value: model.shutterBarTypes,
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translatableObjectToChoices(SHUTTER_BAR_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                    },
                    modelKey: 'shutterBarTypes',
                    toPayload(shutterBarTypes) {
                        if (shutterBarTypes === ShutterBarTypes.Grid) {
                            return { shutterBarTypes, mustBeReplaced: true };
                        }
                        return { shutterBarTypes, mustBeReplaced: null };
                    },
                },
            });
        }
        else if (model.shutterType === BreakInDamageShutterType.Rolling) {
            fields.push({
                label: translate('model.break_in_damage.shutter.rolling.opening'),
                key: 'rollingShutterIsMotorized',
                value: model.rollingShutterIsMotorized,
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translateChoices(SHUTTERS_MOTORIZATION_CHOICES, translate),
                    },
                    modelKey: 'rollingShutterIsMotorized',
                },
            });
        }
        if (model.shutterType === BreakInDamageShutterType.Bar) {
            if (model.shutterBarTypes === ShutterBarTypes.IndividualBars) {
                fields.push({
                    label: translate('claim.declaration.break_in_damages.form.shutter.individual-bars'),
                    key: 'shutterIndividualBarsNumber',
                    value: model.shutterIndividualBarsNumber,
                    editable: {
                        input: {
                            type: ModelFieldInputType.Number,
                        },
                        modelKey: 'shutterIndividualBarsNumber',
                        toPayload(shutterIndividualBarsNumber) {
                            return { shutterIndividualBarsNumber, mustBeReplaced: true };
                        },
                    },
                });
            }
        }
        if (model.shutterType !== BreakInDamageShutterType.Bar) {
            fields.push(editionMaterialField(model, translate));
        }
    }
    if (model.shutterType !== BreakInDamageShutterType.Bar) {
        fields.push(damageSeverityField(model, translate));
        if (model.damageSeverity === DamageSeverity.Critical) {
            if (edition) {
                fields.push(shutterBreakInDamageCriticalSeverityField(model, translate));
            }
        }
        else if (model.damageSeverity === DamageSeverity.Notable) {
            fields.push(impactsNumberField(model, translate));
            if (edition) {
                if ((model.material === Material.Metal &&
                    model.numberOfImpacts === ImpactsNumber.MoreThanThree) ||
                    (model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven)) {
                    fields.push(shutterBreakInDamageCriticalSeverityField(model, translate));
                }
            }
            if (projection !== ModelFieldsProjection.Declaration &&
                !(model.material === Material.Metal && model.numberOfImpacts === ImpactsNumber.MoreThanThree) &&
                !(model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven)) {
                fields.push(...otherDamagesFields(model, translate));
            }
        }
    }
    // We check the conditions for critical severity again but this time also checking if we're in
    // readonly mode because in this mode we want the dimensions fields to appear at the end
    if (!edition &&
        (model.damageSeverity === DamageSeverity.Critical ||
            (model.material === Material.Metal &&
                model.numberOfImpacts === ImpactsNumber.MoreThanThree) ||
            (model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven))) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function shutterBreakInDamageCriticalSeverityField(model, translate) {
    return {
        label: translate('claim.declaration.break_in_damages.form.shutter.window-width'),
        key: 'shutterWindowWidth',
        value: model.shutterWindowWidth,
        editable: {
            input: {
                type: ModelFieldInputType.Radio,
                choices: translateChoices(SHUTTER_WINDOW_WIDTH_CHOICES, translate),
            },
            modelKey: 'shutterWindowWidth',
        },
    };
}
