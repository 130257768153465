// Liste à conserver À JOUR avec les noms des assureurs tels que définis par la convention interne
// (ex: backend/node/upptec/cli/commands/dumpCategoriesMapping.ts -> enum Insurer)
export var InsurerName;
(function (InsurerName) {
    InsurerName["Claims"] = "claims";
    InsurerName["Generali"] = "generali";
    InsurerName["LaMedicale"] = "lamedicale";
    InsurerName["AGPM"] = "agpm";
    InsurerName["IMH"] = "imh";
    InsurerName["MACIF"] = "macif";
    InsurerName["MAPA"] = "mapa";
    InsurerName["BPCE"] = "bpce";
    InsurerName["AdenesItalia"] = "aeaitalia";
    InsurerName["Polytel"] = "polytel";
})(InsurerName || (InsurerName = {}));
