import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { contractFromResponse } from '@declaration/helpers/serializers/contractSerializer';
import contractContactsAreDifferent from '@declaration/services/contractDiff';
import { CONTRACT_HOUSING_TYPE_WITH_SURFACE, HOUSING_TYPE_WITH_SURFACE, ROOM_MEAN_SURFACE, } from '@shared/constants/roomDamageConstants';
import { ApiResourceName } from '@shared/types/api/api';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { ContractHousingCapacityCode, } from '@shared/types/api/contract';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { uuidFromIri } from '@shared/utils/iri';
import contractContactConstraints from '@shared/validator/constraints/contractContactConstraints';
import { ValidationContext } from '@shared/validator/validationContext';
import { isValid, newValidator, revealViolations, touch, validate, } from '@shared/validator/validator';
export const name = 'contract';
export const namespaced = true;
export const state = () => ({
    contracts: [],
    selectedContract: null,
    selectedContractIndex: null,
    applicableContractDetail: null,
    protectionMeans: null,
    protectionMeasures: null,
    coverageOptions: null,
    contractInEdition: null,
    contactValidator: null,
    contractInEditionHasDiff: false,
});
function computeContractsDiff({ selectedContract, contractInEdition }) {
    if (contractInEdition === null || selectedContract === null) {
        return false;
    }
    return contractContactsAreDifferent(contractInEdition.contact, selectedContract.contact);
}
export const getters = {
    validation(state) {
        return new ValidationContext(state.contactValidator);
    },
    housingType(state) {
        var _a;
        return (_a = state.selectedContract) === null || _a === void 0 ? void 0 : _a.housingType.slug;
    },
    isTenant(state) {
        var _a, _b;
        const code = (_b = (_a = state.selectedContract) === null || _a === void 0 ? void 0 : _a.housingCapacity) === null || _b === void 0 ? void 0 : _b.code;
        if (!code)
            return false;
        return [
            ContractHousingCapacityCode.Tenant,
            ContractHousingCapacityCode.SubTenant,
            ContractHousingCapacityCode.UniqueTenant,
            ContractHousingCapacityCode.TenantWithInsuranceForOwnerNonOccupier,
        ].includes(code);
    },
    isNonOccupier(state) {
        var _a, _b;
        const code = (_b = (_a = state.selectedContract) === null || _a === void 0 ? void 0 : _a.housingCapacity) === null || _b === void 0 ? void 0 : _b.code;
        if (!code)
            return false;
        return [
            ContractHousingCapacityCode.OwnerNonOccupier,
            ContractHousingCapacityCode.CoOwnerNonOccupier,
        ].includes(code);
    },
    roomMeanSurface: (state) => (roomNameSlug) => {
        var _a;
        if (!roomNameSlug) {
            return 1;
        }
        const contractHousingType = (_a = state.selectedContract) === null || _a === void 0 ? void 0 : _a.housingType.slug;
        const houseTypeWithSurface = contractHousingType
            ? CONTRACT_HOUSING_TYPE_WITH_SURFACE[contractHousingType]
            : HOUSING_TYPE_WITH_SURFACE.house;
        return ROOM_MEAN_SURFACE[roomNameSlug][houseTypeWithSurface] || 20;
    },
    contractNumber(state) {
        var _a, _b;
        return (_b = (_a = state.selectedContract) === null || _a === void 0 ? void 0 : _a.number) !== null && _b !== void 0 ? _b : null;
    },
};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    async fetchContracts({ commit }) {
        const response = await this.$axios
            .$get(apiResourcePath(ApiResourceName.Contracts))
            .then((response) => response['hydra:member']);
        const contracts = response.map(contractFromResponse);
        commit('SET_CONTRACTS', contracts);
    },
    async fetchSelectedContract({ commit, rootState, dispatch }) {
        const claim = rootState.claim.claim;
        if (claim === null) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
            return;
        }
        if (!claim.contractIri) {
            return commit('SET_SELECTED_CONTRACT', null);
        }
        const contractResponse = await this.$axios.$get(claim.contractIri);
        commit('SET_SELECTED_CONTRACT', contractFromResponse(contractResponse));
        dispatch('fetchContractProtectionMeans');
        dispatch('fetchContractProtectionMeasures');
        dispatch('fetchContractCoverageOptions');
    },
    async fetchApplicableContractDetail({ commit, rootState, dispatch }) {
        const claim = rootState.claim.claim;
        if (claim === null) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
            return;
        }
        if (!claim.id) {
            return commit('SET_APPLICABLE_CONTRACT_DETAIL', null);
        }
        const applicableContractDetailResponse = await this.$axios.$get(`/get_applicable_contract_details/${uuidFromIri(claim.id)}`);
        // check that we have an applicableContractDetail, if not the call will return an empty array
        if (Object.keys(applicableContractDetailResponse).length === 0 &&
            applicableContractDetailResponse.constructor === Object) {
            return commit('SET_APPLICABLE_CONTRACT_DETAIL', null);
        }
        commit('SET_APPLICABLE_CONTRACT_DETAIL', applicableContractDetailResponse);
    },
    async fetchContractProtectionMeans({ commit, rootState, dispatch }) {
        const claim = rootState.claim.claim;
        if (claim === null) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
            return;
        }
        if (!claim.id) {
            return commit('SET_PROTECTION_MEANS', null);
        }
        const protectionMeans = await this.$axios.$get(`/contract-data/${uuidFromIri(claim.id)}/protection-means`);
        return commit('SET_PROTECTION_MEANS', protectionMeans);
    },
    async fetchContractProtectionMeasures({ commit, rootState, dispatch }) {
        const claim = rootState.claim.claim;
        if (claim === null) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
            return;
        }
        if (!claim.id) {
            return commit('SET_PROTECTION_MEASURES', null);
        }
        const protectionMeasures = await this.$axios.$get(`/contract-data/${uuidFromIri(claim.id)}/protection-measures`);
        return commit('SET_PROTECTION_MEASURES', protectionMeasures);
    },
    async fetchContractCoverageOptions({ commit, rootState, dispatch }) {
        const claim = rootState.claim.claim;
        if (claim === null) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
            return;
        }
        if (!claim.id) {
            return commit('SET_COVERAGE_OPTIONS', null);
        }
        const coverageOptions = await this.$axios.$get(`/contract-data/${uuidFromIri(claim.id)}/covers`);
        return commit('SET_COVERAGE_OPTIONS', coverageOptions);
    },
    async selectAndSaveContract({ commit, rootState, dispatch, state: { contracts } }, { selectedContractIndex, step }) {
        const claim = rootState.claim.claim;
        if (claim === null) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
            return;
        }
        commit('SELECT_CONTRACT', selectedContractIndex);
        const contractIri = contracts[selectedContractIndex].id;
        if (rootState.claim.claim.contractIri === contractIri) {
            // No-op if same contract selected
            await dispatch('claim/saveClaimWithPayload', { step }, { root: true });
            return;
        }
        const payload = {
            contract: contractIri,
            step,
        };
        if (rootState.qualification.typeTrigram !== ClaimTypeTrigram.Empty) {
            payload.type = null; // reset claimType if it had been set
        }
        await dispatch('claim/saveClaimWithPayload', payload, { root: true });
        dispatch('fetchContractProtectionMeans');
        dispatch('fetchContractProtectionMeasures');
        dispatch('fetchContractCoverageOptions');
    },
    async saveWithNoContract({ commit, dispatch }, step) {
        commit('SELECT_CONTRACT', null);
        const payload = {
            contract: null,
            step,
        };
        await dispatch('claim/saveClaimWithPayload', payload, { root: true });
    },
    validateContractContact({ commit, state }) {
        if (!state.contactValidator) {
            throw new Error('No validator');
        }
        commit('VALIDATE_AND_REVEAL_CONTACT');
    },
    editContract({ commit }) {
        commit('EDIT_CONTRACT');
    },
    cancelContractEdition({ commit }) {
        commit('CANCEL_CONTRACT_EDITION');
    },
    async saveContractInEdition({ commit, state: { contractInEdition, contactValidator } }) {
        if (!contractInEdition || !contactValidator) {
            return;
        }
        commit('VALIDATE_AND_REVEAL_CONTACT');
        if (!isValid(contactValidator)) {
            return;
        }
        await this.$axios.$put(contractInEdition.id, {
            contact: contractInEdition.contact,
        });
        commit('SAVE_CONTRACT_IN_EDITION');
    },
    updateContractContact({ commit }, payload) {
        commit('UPDATE_CONTRACT_CONTACT', payload);
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    SET_CONTRACTS(state, contracts) {
        state.contracts = contracts;
        state.contractInEditionHasDiff = computeContractsDiff(state);
    },
    SET_SELECTED_CONTRACT(state, contract) {
        state.selectedContract = contract;
    },
    SET_APPLICABLE_CONTRACT_DETAIL(state, applicableContractDetail) {
        state.applicableContractDetail = applicableContractDetail;
    },
    SET_PROTECTION_MEANS(state, protectionMeans) {
        state.protectionMeans = protectionMeans;
    },
    SET_PROTECTION_MEASURES(state, protectionMeasures) {
        state.protectionMeasures = protectionMeasures;
    },
    SET_COVERAGE_OPTIONS(state, coverageOptions) {
        state.coverageOptions = coverageOptions;
    },
    SELECT_CONTRACT(state, index) {
        state.selectedContractIndex = index;
        if (index === null) {
            // New personal Property
            state.selectedContract = null;
            return;
        }
        state.selectedContract = cloneDeep(state.contracts[index]);
        state.contactValidator = newValidator(state.selectedContract.contact);
        validate(state.contactValidator, state.selectedContract.contact, contractContactConstraints);
        state.contractInEditionHasDiff = computeContractsDiff(state);
    },
    EDIT_CONTRACT(state) {
        if (!state.selectedContract) {
            return;
        }
        state.contractInEdition = cloneDeep(state.selectedContract);
        state.contactValidator = newValidator(state.contractInEdition.contact);
        validate(state.contactValidator, state.contractInEdition.contact, contractContactConstraints);
        state.contractInEditionHasDiff = computeContractsDiff(state);
    },
    CANCEL_CONTRACT_EDITION(state) {
        state.contractInEdition = null;
        state.contactValidator = null;
        state.contractInEditionHasDiff = false;
    },
    VALIDATE_AND_REVEAL_CONTACT(state) {
        if (!state.contractInEdition) {
            return;
        }
        if (state.contactValidator === null) {
            state.contactValidator = newValidator(state.contractInEdition.contact);
        }
        validate(state.contactValidator, state.contractInEdition.contact, contractContactConstraints);
        revealViolations(state.contactValidator);
    },
    SAVE_CONTRACT_IN_EDITION(state) {
        if (state.selectedContractIndex === null || state.contractInEdition === null) {
            return;
        }
        state.selectedContract = state.contractInEdition;
        Vue.set(state.contracts, state.selectedContractIndex, state.selectedContract);
    },
    UPDATE_CONTRACT_CONTACT(state, contractContactPayload) {
        const contractInEdition = state.contractInEdition;
        if (!contractInEdition) {
            return;
        }
        Object.entries(contractContactPayload).forEach(([key, value]) => {
            Vue.set(contractInEdition.contact, key, value);
            if (state.contactValidator === null) {
                state.contactValidator = newValidator(contractInEdition.contact);
            }
            touch(state.contactValidator, key);
            validate(state.contactValidator, contractInEdition.contact, contractContactConstraints);
            state.contractInEditionHasDiff = computeContractsDiff(state);
        });
    },
};
