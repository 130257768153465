import jwtDecode from 'jwt-decode';
export const AUTH_JWT_REFRESH_URL = '/auth/jwt/refresh';
let refreshAndSaveAccessTokenPromise = null;
const refreshAndSaveAccessToken = (axios, store) => {
    if (refreshAndSaveAccessTokenPromise) {
        return refreshAndSaveAccessTokenPromise;
    }
    refreshAndSaveAccessTokenPromise = new Promise((resolve) => {
        axios
            .post(AUTH_JWT_REFRESH_URL, null, { withCredentials: true })
            .then((response) => {
            store.commit('authentication/SET_AUTH', response.data.access);
            resolve(response.data.access);
        })
            .catch(() => {
            store.dispatch('authentication/logout', null);
            resolve(null);
        })
            .finally(() => {
            refreshAndSaveAccessTokenPromise = null;
        });
    });
    return refreshAndSaveAccessTokenPromise;
};
const tokenHasExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        if (!decodedToken.exp) {
            return true;
        }
        const now = new Date().getTime() / 1000;
        return decodedToken.exp - now < 10;
    }
    catch (err) {
        return false;
    }
};
export const getValidAccessToken = (axios, store) => {
    const accessToken = store.state.authentication.accessToken;
    if (accessToken) {
        if (tokenHasExpired(accessToken)) {
            return refreshAndSaveAccessToken(axios, store);
        }
        return Promise.resolve(accessToken);
    }
    return Promise.resolve(null);
};
