import { ExternalDamageLocalizationSlug, ExternalDamageOrigin, } from '@shared/types/api/externalDamage';
export const DEFAULT_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS = {
    [ExternalDamageLocalizationSlug.Chimney]: 'claim.declaration.external_damages.form.localization.chimney',
    [ExternalDamageLocalizationSlug.Electricity]: 'claim.declaration.external_damages.form.localization.electricity',
    [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: 'claim.declaration.external_damages.form.localization.external_personal_property',
    [ExternalDamageLocalizationSlug.FenceAndGate]: 'claim.declaration.external_damages.form.localization.fence_and_gate',
    [ExternalDamageLocalizationSlug.FrontWall]: 'claim.declaration.external_damages.form.localization.front_wall',
    [ExternalDamageLocalizationSlug.GarageDoor]: 'claim.declaration.external_damages.form.localization.garage_door',
    [ExternalDamageLocalizationSlug.GardenTerrace]: 'claim.declaration.external_damages.form.localization.garden_terrace',
    [ExternalDamageLocalizationSlug.Other]: 'claim.declaration.external_damages.form.localization.other',
    [ExternalDamageLocalizationSlug.Outbuilding]: 'claim.declaration.external_damages.form.localization.outbuilding',
    [ExternalDamageLocalizationSlug.Plumbing]: 'claim.declaration.external_damages.form.localization.plumbing',
    [ExternalDamageLocalizationSlug.Roof]: 'claim.declaration.external_damages.form.localization.roof',
    [ExternalDamageLocalizationSlug.Shutters]: 'claim.declaration.external_damages.form.localization.shutters',
    [ExternalDamageLocalizationSlug.Veranda]: 'claim.declaration.external_damages.form.localization.veranda',
    [ExternalDamageLocalizationSlug.Window]: 'claim.declaration.external_damages.form.localization.window',
    [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: 'claim.declaration.external_damages.form.localization.photovoltaic_panel',
    [ExternalDamageLocalizationSlug.Door]: 'claim.declaration.external_damages.form.localization.door',
};
const STORM_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS = {};
const SNOW_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS = {};
const HAIL_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS = {
    [ExternalDamageLocalizationSlug.Roof]: 'claim.declaration.external_damages.form.localization.roof.hail',
    [ExternalDamageLocalizationSlug.Window]: 'claim.declaration.external_damages.form.localization.window.hail',
};
const FIRE_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS = {
    [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: 'claim.declaration.external_damages.form.localization.external_personal_property.fire',
    [ExternalDamageLocalizationSlug.FenceAndGate]: 'claim.declaration.external_damages.form.localization.fence_and_gate.fire',
};
export const EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS = {
    [ExternalDamageOrigin.Storm]: {
        ...DEFAULT_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS,
        ...STORM_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Snow]: {
        ...DEFAULT_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS,
        ...SNOW_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Hail]: {
        ...DEFAULT_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS,
        ...HAIL_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Fire]: {
        ...DEFAULT_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS,
        ...FIRE_EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS,
    },
};
export const EXTERNAL_DAMAGE_LOCALIZATIONS = {
    [ExternalDamageOrigin.Storm]: [
        ExternalDamageLocalizationSlug.GardenTerrace,
        ExternalDamageLocalizationSlug.Roof,
        ExternalDamageLocalizationSlug.Chimney,
        ExternalDamageLocalizationSlug.FenceAndGate,
        ExternalDamageLocalizationSlug.Shutters,
        ExternalDamageLocalizationSlug.GarageDoor,
        ExternalDamageLocalizationSlug.Veranda,
        ExternalDamageLocalizationSlug.ExternalPersonalProperty,
    ],
    [ExternalDamageOrigin.Hail]: [
        ExternalDamageLocalizationSlug.GardenTerrace,
        ExternalDamageLocalizationSlug.Roof,
        ExternalDamageLocalizationSlug.Window,
        ExternalDamageLocalizationSlug.Shutters,
        ExternalDamageLocalizationSlug.GarageDoor,
        ExternalDamageLocalizationSlug.Door,
        ExternalDamageLocalizationSlug.Veranda,
        ExternalDamageLocalizationSlug.FenceAndGate,
        ExternalDamageLocalizationSlug.PhotovoltaicPanel,
        ExternalDamageLocalizationSlug.Outbuilding,
        ExternalDamageLocalizationSlug.Other,
    ],
    [ExternalDamageOrigin.Snow]: [
        ExternalDamageLocalizationSlug.GardenTerrace,
        ExternalDamageLocalizationSlug.Roof,
        ExternalDamageLocalizationSlug.Veranda,
    ],
    [ExternalDamageOrigin.Fire]: [
        ExternalDamageLocalizationSlug.FenceAndGate,
        ExternalDamageLocalizationSlug.ExternalPersonalProperty,
        ExternalDamageLocalizationSlug.Roof,
    ],
};
export function getExternalDamageLocalizationChoices(origin) {
    const localizations = EXTERNAL_DAMAGE_LOCALIZATIONS[origin];
    return localizations.map((value) => {
        const tradKey = EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS[origin][value];
        return { value, tradKey };
    });
}
