export function displayToastOnNewError(vue) {
    return vue.$store.watch((state) => {
        return state.error.errors;
    }, (errors) => {
        if (errors.length === 0) {
            return;
        }
        for (const error of errors) {
            vue.$buefy.toast.open({
                message: error.message,
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        vue.$store.dispatch('error/remove');
    });
}
