import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
import { DoorLockDamages, DoorLockType, DoorType, ImpactsNumber, LeavesType, Material, } from '@shared/types/api/breakInDamage';
export const DOOR_TYPE_TRAD_KEYS_AND_IMAGES = {
    [DoorType.MainEntrance]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.type.main-entrance',
        sumupTradKey: 'claim.declaration.break_in_damages.form.door.type.main-entrance.sumup',
        image: require('@declaration/assets/images/breakInDamage/door/doorType/porte-entree.svg'),
    },
    [DoorType.Service]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.type.service',
        sumupTradKey: 'claim.declaration.break_in_damages.form.door.type.service.sumup',
        image: require('@declaration/assets/images/breakInDamage/door/doorType/porte-service.svg'),
    },
    [DoorType.Indoor]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.type.indoor',
        sumupTradKey: 'claim.declaration.break_in_damages.form.door.type.indoor.sumup',
        image: require('@declaration/assets/images/breakInDamage/door/doorType/porte-interieure.svg'),
    },
};
export const DOOR_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES = {
    [LeavesType.OneLeaf]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.one-leaf',
        image: require('@declaration/assets/images/breakInDamage/door/leavesType/un-battant.svg'),
    },
    [LeavesType.TwoLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.two-leaves',
        image: require('@declaration/assets/images/breakInDamage/door/leavesType/deux-battants.svg'),
    },
};
export const DOOR_MATERIAL_CHOICES = [
    {
        value: Material.Wood,
        tradKey: 'claim.declaration.break_in_damages.form.material.wood',
    },
    {
        value: Material.PVC,
        tradKey: 'claim.declaration.break_in_damages.form.material.pvc',
    },
    {
        value: Material.Metal,
        tradKey: 'claim.declaration.break_in_damages.form.material.metal',
    },
];
export const DOOR_LOCK_DAMAGES = [
    {
        value: DoorLockDamages.Nonfunctional,
        tradKey: 'claim.declaration.break_in_damages.form.door.lock-damages.nonfunctional',
    },
    {
        value: DoorLockDamages.Destroyed,
        tradKey: 'claim.declaration.break_in_damages.form.door.lock-damages.destroyed',
    },
];
export const DOOR_LOCK_TYPE_TRAD_KEYS_AND_IMAGES = {
    [DoorLockType.OneLock]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.lock-type.one-lock',
        image: require('@declaration/assets/images/breakInDamage/door/lockType/one-lock.svg'),
    },
    [DoorLockType.TwoLocks]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.lock-type.two-locks',
        image: require('@declaration/assets/images/breakInDamage/door/lockType/two-locks.svg'),
    },
    [DoorLockType.ThreeLocks]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.lock-type.three-locks',
        image: require('@declaration/assets/images/breakInDamage/door/lockType/three-locks.svg'),
    },
    [DoorLockType.FixatedMultipointLock]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.lock-type.fixated-multipoint-lock',
        image: require('@declaration/assets/images/breakInDamage/door/lockType/fixated-multipoint-lock.svg'),
    },
    [DoorLockType.FairedMultipointLock]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.lock-type.faired-multipoint-lock',
        image: require('@declaration/assets/images/breakInDamage/door/lockType/faired-multipoint-lock.svg'),
    },
    [DoorLockType.IntegratedMultipointLock]: {
        tradKey: 'claim.declaration.break_in_damages.form.door.lock-type.integrated-multipoint-lock',
        image: require('@declaration/assets/images/breakInDamage/door/lockType/integrated-multipoint-lock.svg'),
    },
};
export const WOODEN_DOOR_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.AtLeastOne,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
    },
];
export const METAL_DOOR_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.MoreThanThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    },
];
export const PVC_DOOR_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.FourToSix,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.four-to-six',
    },
    {
        value: ImpactsNumber.MoreThanSeven,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-seven',
    },
];
export const REPAIR_STATUS_CHOICES_DOOR = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.door',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.door',
    },
];
