import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import { uuidFromIri } from '@shared/utils/iri';
export var EntityChangeAction;
(function (EntityChangeAction) {
    EntityChangeAction["Create"] = "create";
    EntityChangeAction["Update"] = "update";
    EntityChangeAction["Delete"] = "delete";
})(EntityChangeAction || (EntityChangeAction = {}));
export function isUpdateChangeKey(key) {
    return typeof key === 'string';
}
export function isCreateChangeKey(key) {
    return !isUpdateChangeKey(key);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createEntityChanges() {
    return { create: [], delete: [], update: {} };
}
export function areEntityChangesEmpty(changes) {
    return (changes.delete.length === 0 &&
        changes.create.length === 0 &&
        Object.keys(changes.update).length === 0);
}
export function changesToArray(changes) {
    return [...changes.create, ...Object.values(changes.update)];
}
export function getEntityChange(changes, changeKey) {
    var _a;
    return ((_a = (isUpdateChangeKey(changeKey) ? changes.update[changeKey] : changes.create[changeKey !== null && changeKey !== void 0 ? changeKey : 0])) !== null && _a !== void 0 ? _a : null);
}
export function getOrCreateEntityChange(changes, changeKey, create) {
    const existing = getEntityChange(changes, changeKey);
    if (existing) {
        return existing;
    }
    // Create change model for update
    if (isUpdateChangeKey(changeKey)) {
        const change = create(changeKey, changes);
        Vue.set(changes.update, changeKey, change);
        return change;
    }
    // Create  change model for creation
    const change = create(changeKey, changes);
    Vue.set(changes.create, changeKey !== null && changeKey !== void 0 ? changeKey : 0, change);
    return change;
}
export function damageChangesToEntityChanges(changes) {
    const create = changes.create.map((create) => create.data).filter((data) => !isEmpty(data));
    const update = {};
    for (const iri in changes.update) {
        const data = changes.update[iri].data;
        if (isEmpty(data)) {
            continue;
        }
        update[iri] = data;
    }
    return {
        create,
        update,
        delete: changes.delete,
    };
}
/**
 * Transform create/update/delete for entity changes into payload array
 */
export function entityChangesToPayload(existingEntities, changes, useIri = false) {
    const keyFromId = useIri ? (id) => id : uuidFromIri;
    const payloads = existingEntities
        // Do not add to payload existing entities without iri (legacy frontend placeholder data)
        .filter((entity) => entity.id !== null)
        // Remove deleted entities from payload
        .filter(({ id }) => !changes.delete.includes(keyFromId(id)))
        // Replace updated entities with payload from change data
        .map((entity) => keyFromId(entity.id) in changes.update
        ? { ...changes.update[keyFromId(entity.id)], id: entity.id }
        : entity.id);
    // Add created entities to payload without draft ids
    payloads.push(...changes.create.map(({ id: _id, ...payload }) => payload));
    return payloads;
}
