function dispatchIfDifferentCallback(callback, store) {
    if (store.state.layout.goBackCallback === callback) {
        return;
    }
    store.dispatch('layout/setGoBackCallback', callback);
}
export function setGoBackCallback(goBack, { store, routerBack }) {
    if (goBack === undefined) {
        // Default behavior is to trigger a router back
        dispatchIfDifferentCallback(routerBack, store);
        return;
    }
    if (goBack === null) {
        // Do not enable to go back with app backbutton
        dispatchIfDifferentCallback(null, store);
        return;
    }
    dispatchIfDifferentCallback(goBack, store);
}
