import { DEFAULT_LAYOUT_COLOR } from '@declaration/layout';
import { isMobile } from '@declaration/utils/layout';
export const name = 'layout';
export const namespaced = true;
export const state = () => ({
    title: null,
    color: DEFAULT_LAYOUT_COLOR,
    closeCallback: null,
    goBackCallback: null,
    isMobile: isMobile(),
});
export const getters = {
    isDesktop(state) {
        return !state.isMobile;
    },
};
export const actions = {
    setColor({ commit, state }, color) {
        if (state.color === color) {
            return;
        }
        commit('SET_COLOR', color);
    },
    setTitle({ commit, state }, title) {
        if (state.title === title) {
            return;
        }
        commit('SET_TITLE', title);
    },
    setCloseCallback({ commit, state }, closeCallback) {
        if (state.closeCallback === closeCallback) {
            return;
        }
        commit('SET_CLOSE_CALLBACK', closeCallback);
    },
    setGoBackCallback({ commit, state }, goBackCallback) {
        if (state.goBackCallback === goBackCallback) {
            return;
        }
        commit('SET_GO_BACK_CALLBACK', goBackCallback);
    },
    setMobile({ commit, state }, isMobile) {
        if (state.isMobile === isMobile) {
            return;
        }
        commit('SET_MOBILE', isMobile);
    },
};
export const mutations = {
    SET_COLOR(state, color) {
        state.color = color;
    },
    SET_TITLE(state, title) {
        state.title = title;
    },
    SET_CLOSE_CALLBACK(state, closeCallback) {
        state.closeCallback = closeCallback;
    },
    SET_GO_BACK_CALLBACK(state, goBackCallback) {
        state.goBackCallback = goBackCallback;
    },
    SET_MOBILE(state, isMobile) {
        state.isMobile = isMobile;
    },
};
