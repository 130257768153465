import { applyLayoutColor } from '@declaration/services/applyLayoutColor';
import { changePageTitle } from '@declaration/services/changePageTitle';
import { setCloseCallback } from '@declaration/services/setCloseCallback';
import { setGoBackCallback } from '@declaration/services/setGoBackCallback';
function setLayoutCloseCallback({ store, route }) {
    const closeMeta = route.meta.find((meta) => meta.hasOwnProperty('close'));
    setCloseCallback(closeMeta === null || closeMeta === void 0 ? void 0 : closeMeta.close, { store });
}
function setLayoutGoBackCallback({ store, route, app: { router } }) {
    if (!router) {
        return;
    }
    const goBackMeta = route.meta.find((meta) => meta.hasOwnProperty('goBack'));
    setGoBackCallback(goBackMeta === null || goBackMeta === void 0 ? void 0 : goBackMeta.goBack, { store, routerBack: router.back.bind(router) });
}
/**
 * Sets title from metadata
 *
 * Usage in meta :
 *
 * title: "tranlation.key.of.title"
 */
function setLayoutTitle({ store, route, app }) {
    const titleMeta = route.meta.find((meta) => meta.hasOwnProperty('title'));
    changePageTitle(titleMeta === null || titleMeta === void 0 ? void 0 : titleMeta.title, { store, translate: app.i18n.tc });
}
function setLayoutColor({ store, route }) {
    const colorMeta = route.meta.find((meta) => meta.hasOwnProperty('color'));
    applyLayoutColor(colorMeta === null || colorMeta === void 0 ? void 0 : colorMeta.color, { store });
}
const middleware = (context) => {
    setLayoutCloseCallback(context);
    setLayoutGoBackCallback(context);
    setLayoutTitle(context);
    setLayoutColor(context);
};
export default middleware;
