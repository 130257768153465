import * as Sentry from '@sentry/browser';
import { createMandatoryDocumentPayload, parseMandatoryDocuments } from '@shared/documents/document';
import { MandatoryDocumentStatus, } from '@shared/types/documents';
import { uuidFromIri } from '@shared/utils/iri';
export const name = 'documents';
export const state = () => ({
    mandatoryDocuments: [],
    isUploading: false,
    pollingTimeout: null,
});
export const getters = {
    documentByStatus(state) {
        return (...status) => state.mandatoryDocuments.filter((doc) => status.includes(doc.status));
    },
    isAnalysisEnabled(_state, _getters, rootState) {
        var _a;
        const recitalEnabled = (_a = rootState.settings.appSettings) === null || _a === void 0 ? void 0 : _a.filter((setting) => setting.name === 'recitalEnabled')[0].value;
        return recitalEnabled !== null && recitalEnabled !== void 0 ? recitalEnabled : false;
    },
    hasPendingAnalysis(state, getters) {
        return (getters.isAnalysisEnabled &&
            state.mandatoryDocuments.some(({ status }) => status === MandatoryDocumentStatus.Pending));
    },
};
export const actions = {
    async fetchDocuments({ getters, dispatch, commit }) {
        try {
            const claim = await dispatch('claim/fetchClaim', { skipContract: true, shouldUpdatePage: false }, { root: true });
            const url = '/mandatory_docs/' + uuidFromIri(claim.id);
            const response = await this.$axios.$get(url);
            const mandatoryDocuments = await parseMandatoryDocuments(response, claim, getters.isAnalysisEnabled, this.$axios);
            commit('SET_DOCUMENTS', mandatoryDocuments);
            if (getters.hasPendingAnalysis)
                dispatch('startPolling');
            else
                dispatch('stopPolling');
        }
        catch (err) {
            Sentry.captureException(err);
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
        }
    },
    async uploadDocument({ dispatch, commit, rootState }, { document, uploadedAppFileCollection, parameters }) {
        const claim = rootState.claim.rawClaim;
        const payload = claim
            ? createMandatoryDocumentPayload(claim, document, uploadedAppFileCollection, parameters)
            : null;
        if (payload) {
            try {
                commit('SET_IS_UPLOADING', true);
                await dispatch('claim/saveClaimWithPayload', payload, { root: true });
                await dispatch('fetchDocuments');
            }
            catch (err) {
                Sentry.captureException(err);
                dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
            }
            finally {
                commit('SET_IS_UPLOADING', false);
            }
        }
    },
    startPolling({ state, commit, dispatch }) {
        if (state.pollingTimeout !== null)
            clearTimeout(state.pollingTimeout);
        const pollingTimeout = setTimeout(async () => {
            await dispatch('fetchDocuments');
        }, 5000);
        commit('SET_POLLING_TIMEOUT', pollingTimeout);
    },
    stopPolling({ state, commit }) {
        if (state.pollingTimeout !== null)
            clearTimeout(state.pollingTimeout);
        commit('SET_POLLING_TIMEOUT', null);
    },
};
export const mutations = {
    SET_DOCUMENTS(state, documents) {
        state.mandatoryDocuments = [...documents]
            .sort((a, b) => a.fileType.localeCompare(b.fileType))
            .sort((a, b) => a.parent.id.localeCompare(b.parent.id));
    },
    SET_IS_UPLOADING(state, isUploading) {
        state.isUploading = isUploading;
    },
    RESET_STATE(state) {
        state.mandatoryDocuments = [];
    },
    SET_POLLING_TIMEOUT(state, pollingTimeout) {
        state.pollingTimeout = pollingTimeout;
    },
};
