import validateJs from 'validate.js';
import { presenceIfEmpty, presenceIfPresent } from '@shared/validator/extensions/presenceIfEmpty';
import { presenceIfValue } from '@shared/validator/extensions/presenceIfValue';
import { requiredFileCollection } from '@shared/validator/extensions/requiredFileCollection';
import { checked } from '@shared/validator/extensions/checked';
import { notifyVueObserver } from '@shared/vue/notifyObserver';
validateJs.validators.presenceIfEmpty = presenceIfEmpty;
validateJs.validators.presenceIfPresent = presenceIfPresent;
validateJs.validators.presenceIfValue = presenceIfValue;
validateJs.validators.requiredFileCollection = requiredFileCollection;
validateJs.validators.checked = checked;
function fieldValidation() {
    return { touched: false, violations: [], errors: [] };
}
/**
 * Object is used to type the validator and ensure that
 * validate is called with the right values
 */
export function newValidator(_object) {
    return {
        fields: {},
        revealViolations: false,
    };
}
export function hasErrors(validator) {
    return (Object.values(validator.fields).find(({ violations }) => violations.length > 0) !== undefined);
}
export function isValid(validator) {
    return !hasErrors(validator);
}
export function isTouched(validator) {
    return Object.values(validator.fields).find(({ touched }) => touched) !== undefined;
}
export function touch(validator, fieldName) {
    let field = validator.fields[fieldName];
    if (!field) {
        validator.fields[fieldName] = fieldValidation();
        field = validator.fields[fieldName];
    }
    field.touched = true;
    field.errors = field.violations;
    notifyVueObserver(validator);
}
export function untouch(validator, fieldName) {
    let field = validator.fields[fieldName];
    if (!field) {
        validator.fields[fieldName] = fieldValidation();
        field = validator.fields[fieldName];
    }
    field.touched = false;
    field.errors = [];
    notifyVueObserver(validator);
}
export function validate(validator, object, constraints) {
    const fields = validator.fields;
    const constraintsViolations = validateJs(object, constraints, { fullMessages: false, format: 'detailed' }) || [];
    const fieldsToRemove = new Set();
    // Reset validator violations and errors
    for (const fieldName in fields) {
        fields[fieldName].violations = [];
        fields[fieldName].errors = [];
        fieldsToRemove.add(fieldName);
    }
    // Set violations in validator fields
    for (const constraintKey in constraintsViolations) {
        const { error, attribute, value, options } = constraintsViolations[constraintKey];
        let field = fields[attribute];
        if (!field) {
            field = fieldValidation();
            fields[attribute] = field;
        }
        field.violations.push({ message: error, options, value });
        if (field.touched || validator.revealViolations) {
            field.errors = field.violations;
        }
    }
    // Handling dynamic constraints that can be different between 2 validate() calls
    for (const fieldName in constraints) {
        // Field still present in constraints will not be removed
        fieldsToRemove.delete(fieldName);
        // Add validation fields that were not present before
        if (!fields[fieldName]) {
            fields[fieldName] = fieldValidation();
        }
    }
    // Remove validation fields that are no more in constraints (used for dynamic constraints)
    fieldsToRemove.forEach((fieldName) => {
        delete validator.fields[fieldName];
    });
    notifyVueObserver(validator);
}
export function revealViolations(validator) {
    validator.revealViolations = true;
    for (const fieldName in validator.fields) {
        validator.fields[fieldName].errors = validator.fields[fieldName].violations;
    }
    notifyVueObserver(validator);
}
