export const chargeConstraints = {
    typology: {
        presence: {
            allowEmpty: false,
            message: 'claim.charge.typology.validator.presence',
        },
    },
    label: {
        presence: {
            allowEmpty: true,
            message: 'claim.charge.label.validator.presence',
        },
    },
    amount: {
        presence: {
            allowEmpty: false,
            message: 'claim.charge.amount.validator.presence',
        },
        numericality: {
            greaterThan: 0,
            message: 'claim.charge.amount.validator.numericality',
        },
    },
};
export const chargeConstraintsFactory = () => chargeConstraints;
