import { WindowType } from '@shared/types/api/externalDamage';
export const WINDOW_TYPE_TITLE = 'claim.declaration.external_damages.form.window.title';
export const WINDOW_TYPE_SUBTITLE = 'claim.declaration.external_damages.form.window.subtitle';
export const WINDOW_COUNT_TITLE = 'claim.declaration.external_damages.form.window.count.title';
export const WINDOW_STRUCTURE_DAMAGED_TITLE = {
    [WindowType.Window]: 'claim.declaration.external_damages.form.window.structure_damaged.title.window',
    [WindowType.RoofWindow]: 'claim.declaration.external_damages.form.window.structure_damaged.title.roof_window',
    [WindowType.SlidingBay]: 'claim.declaration.external_damages.form.window.structure_damaged.title.sliding_bay',
    [WindowType.FrenchWindow]: 'claim.declaration.external_damages.form.window.structure_damaged.title.french_window',
};
export const WINDOW_STRUCTURE_DAMAGED_SUBTITLE = 'claim.declaration.external_damages.form.window.structure_damaged.subtitle';
export const WINDOW_PANE_COUNT_TITLE = 'claim.declaration.external_damages.form.window.pane_count.title';
export const WINDOW_PANE_DIMENSIONS_TITLE = {
    [WindowType.Window]: 'claim.declaration.external_damages.form.window.pane_dimensions.title.window',
    [WindowType.RoofWindow]: 'claim.declaration.external_damages.form.window.pane_dimensions.title.roof_window',
    [WindowType.SlidingBay]: 'claim.declaration.external_damages.form.window.pane_dimensions.title.sliding_bay',
    [WindowType.FrenchWindow]: 'claim.declaration.external_damages.form.window.pane_dimensions.title.french_window',
};
export const WINDOW_PANE_GLASS_TYPE_TITLE = 'claim.declaration.external_damages.form.window.pane_glass_type.title';
export const WINDOW_PANE_GLAZING_TYPE_TITLE = 'claim.declaration.external_damages.form.window.pane_glazing_type.title';
