import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { PersonalPropertyReceiptType, PersonalPropertyType, RepairableStatus, BDGPersonalPropertyInitialCategorySlug, BDGPersonalPropertyReparationBy, } from '@shared/types/api/personalProperty';
import { optionalResourceIri } from '@shared/utils/iri';
import { isGlassBreakagePersonalProperty } from '@shared/personalProperty/personalPropertyFields';
const amount = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.personal_property.form.question.amount.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 0,
        lessThan: 500000000,
        message: 'claim.damage.personal_property.form.question.amount.validator.numericality',
    },
};
const age = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.personal_property.form.question.age.validator.presence',
    },
    numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThan: 6000,
        // Change the traduction key if you modify the lessThan property
        message: 'claim.damage.personal_property.form.question.age.validator.numericality',
    },
};
const purchaseCertification = {
    checked: {
        message: 'claim.damage.personal_property.form.question.user_purchase_certification.validator.presence',
    },
};
// Category validation can be from iri key or id key
const categoryIri = {
    presenceIfEmpty: {
        attribute: 'category',
        message: 'claim.damage.personal_property.form.question.category.validator.presence',
    },
};
const category = {
    presenceIfEmpty: {
        attribute: 'categoryIri',
        message: 'claim.damage.personal_property.form.question.category.validator.presence',
    },
};
const initialCategorySlug = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.glass_breakage.question.personal_property.form.initial-category-slug.validator.presence',
    },
};
const documentType = {
    presenceIfEmpty: {
        attribute: 'document-type',
        message: 'claim.damage.personal_property.form.question.document-type.validator.presence',
    },
};
const thirdPartyProductId = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.personal_property.form.question.item.validator.presence',
    },
};
const userKnowPrice = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.personal_property.form.question.user-know-price.validator.presence',
    },
};
const designation = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.personal_property.form.question.designation.validator.presence',
    },
};
const description = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.personal_property.form.question.description.validator.presence',
    },
};
const windowCount = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.glass_breakage.question.personal_property.form.window_count.validator.presence',
    },
    numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 1,
        message: 'claim.damage.glass_breakage.question.personal_property.form.window_count.validator.numericality',
    },
};
const widestWindow = {
    width: {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.glass_breakage.question.personal_property.form.widest_window.width.validator.presence',
        },
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 1,
            message: 'claim.damage.glass_breakage.question.personal_property.form.widest_window.width.validator.numericality',
        },
    },
    height: {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.glass_breakage.question.personal_property.form.widest_window.height.validator.presence',
        },
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 1,
            message: 'claim.damage.glass_breakage.question.personal_property.form.widest_window.height.validator.numericality',
        },
    },
};
export const windowReparation = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.glass_breakage.question.personal_property.form.window_reparation.validator.presence',
    },
};
const windowReparationBy = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.glass_breakage.question.personal_property.form.window_reparation_by.validator.presence',
    },
};
const windowReparationInvoice = {
    requiredFileCollection: {
        message: 'claim.damage.glass_breakage.question.personal_property.form.window_reparation_invoice.validator.required_file_collection',
        trigram: ClaimTypeTrigram.Glass,
    },
};
const windowReparationInvoiceAmount = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.glass_breakage.question.personal_property.form.window_reparation_invoice_amount.validator.presence',
    },
    numericality: {
        greaterThanOrEqualTo: 1,
        lessThan: 500000000,
        message: 'claim.damage.glass_breakage.question.personal_property.form.window_reparation_invoice_amount.validator.numericality',
    },
};
function getConstraintsOptions(personalProperty, typeTrigram) {
    const repairFileCollection = personalProperty.repairFileCollection;
    const hasRepairFile = !!repairFileCollection &&
        typeof repairFileCollection !== 'string' &&
        Array.isArray(repairFileCollection.uploadedAppFiles) &&
        repairFileCollection.uploadedAppFiles.length > 0;
    return {
        repairQuoteInfo: !!typeTrigram &&
            claimTypeHasFeature(typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyWarranty) &&
            hasRepairFile,
    };
}
function personalPropertyConstraints(data, { personalPropertyCategories, typeTrigram }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { repairQuoteInfo } = getConstraintsOptions(data, typeTrigram);
    let constraints;
    const identityDocumentCategoryIri = (_b = (_a = personalPropertyCategories.find((c) => c.data.slug === 'papiers-d-identite')) === null || _a === void 0 ? void 0 : _a.data.id) !== null && _b !== void 0 ? _b : null;
    const electronicCategoryIri = (_d = (_c = personalPropertyCategories.find((c) => c.data.slug === 'electronique')) === null || _c === void 0 ? void 0 : _c.data.id) !== null && _d !== void 0 ? _d : null;
    const jewelryCategoryIri = (_f = (_e = personalPropertyCategories.find((c) => c.data.slug === 'bijoux-objets-de-valeur')) === null || _e === void 0 ? void 0 : _e.data.id) !== null && _f !== void 0 ? _f : null;
    const amountCategoryIri = (_h = (_g = personalPropertyCategories.find((c) => c.data.slug === 'especes')) === null || _g === void 0 ? void 0 : _g.data.id) !== null && _h !== void 0 ? _h : null;
    const foodCategoryIri = (_k = (_j = personalPropertyCategories.find((c) => c.data.slug === 'denrees-alimentaires')) === null || _j === void 0 ? void 0 : _j.data.id) !== null && _k !== void 0 ? _k : null;
    const isElectronic = optionalResourceIri(data.category) === electronicCategoryIri;
    const isJewelry = optionalResourceIri(data.category) === jewelryCategoryIri;
    const isIdentityDocument = optionalResourceIri(data.category) === identityDocumentCategoryIri;
    const isAmountOnly = optionalResourceIri(data.category) === amountCategoryIri;
    const isFood = optionalResourceIri(data.category) === foodCategoryIri;
    const isElectricDamages = () => typeTrigram === ClaimTypeTrigram.Electric;
    const isRepairable = isGlassBreakagePersonalProperty(data) &&
        data.initialCategorySlug &&
        [
            BDGPersonalPropertyInitialCategorySlug.InsertDeCheminee,
            BDGPersonalPropertyInitialCategorySlug.Meuble,
            BDGPersonalPropertyInitialCategorySlug.Miroir,
        ].includes(data.initialCategorySlug);
    const isDescriptionOnly = data.type === PersonalPropertyType.GlassBreakage &&
        data.initialCategorySlug === BDGPersonalPropertyInitialCategorySlug.Autres;
    const isThirdPartyAmount = ((_l = data.thirdPartyProductInfo) === null || _l === void 0 ? void 0 : _l.amount) &&
        (data.thirdPartyProductInfo.amount > 0 || data.thirdPartyProductInfo.amount !== null);
    if (data.thirdPartyProductId) {
        if ((_m = data.thirdPartyProductInfo) === null || _m === void 0 ? void 0 : _m.isAvailable) {
            constraints =
                // Available thirdparty product
                {
                    thirdPartyProductId,
                    age,
                };
        }
        else {
            constraints =
                // Unavailable thirdparty product
                {
                    categoryIri,
                    category,
                    thirdPartyProductId,
                    age,
                    amount,
                    designation,
                    description,
                };
        }
    }
    else if (isElectronic) {
        constraints =
            // Available thirdparty product
            {
                categoryIri,
                category,
                age,
                designation,
                description,
            };
    }
    else if (isIdentityDocument) {
        constraints = {
            category,
            documentType,
        };
    }
    else if (isJewelry) {
        constraints = {
            category,
            userKnowPrice,
            designation,
        };
    }
    else if (isAmountOnly || isFood) {
        constraints = {
            category,
            amount,
        };
    }
    else if (isDescriptionOnly) {
        constraints =
            // Not found thirdparty product
            {
                categoryIri,
                category,
                designation,
                description,
            };
    }
    else {
        constraints =
            // Not found thirdparty product
            {
                categoryIri,
                category,
                userKnowPrice,
                age,
                designation,
            };
    }
    if (isFood)
        return constraints;
    if (isElectricDamages()) {
        if (data.isRepairQuoteMandatory || data.repairableStatus === RepairableStatus.Repairable) {
            constraints.repairFileCollection = {
                requiredFileCollection: {
                    message: 'validator.requiredFileCollection',
                },
            };
        }
        if (data.repairableStatus === RepairableStatus.Repairable) {
            constraints['repairQuoteInfo.amount'] = {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.presence',
                },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThan: 500000000,
                    message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.numericality',
                },
            };
        }
        if (data.userKnowPrice) {
            constraints.age = age;
            if (!isThirdPartyAmount && !((_o = data.thirdPartyProductInfo) === null || _o === void 0 ? void 0 : _o.isAvailable)) {
                constraints.amount = amount;
            }
        }
        else {
            delete constraints.age;
            if (!isAmountOnly)
                delete constraints.amount;
            if (!isIdentityDocument && !isAmountOnly) {
                constraints.purchaseCertification = purchaseCertification;
            }
        }
    }
    if (isGlassBreakagePersonalProperty(data)) {
        constraints.initialCategorySlug = initialCategorySlug;
        if (isRepairable) {
            constraints.windowCount = windowCount;
            constraints.widestWindowWidth = widestWindow.width;
            constraints.widestWindowHeight = widestWindow.height;
            if (data.windowReparation !== undefined) {
                constraints.windowReparation = windowReparation;
            }
            if (data.windowReparation) {
                constraints.windowReparationBy = windowReparationBy;
            }
            if (data.windowReparationBy === BDGPersonalPropertyReparationBy.Professional) {
                constraints.windowReparationInvoice = windowReparationInvoice;
            }
            if (fileCollectionFilesCount(data.windowReparationInvoice) > 0) {
                constraints.windowReparationInvoiceAmount = windowReparationInvoiceAmount;
            }
        }
    }
    if (data.userKnowPrice && isJewelry) {
        constraints.amount = amount;
    }
    if (repairQuoteInfo && data.repairableStatus === RepairableStatus.Repairable) {
        if (!isElectricDamages()) {
            constraints['repairQuoteInfo.companyName'] = {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.personal_property.form.question.repair_quote_company_name.validator.presence',
                },
            };
        }
        constraints['repairQuoteInfo.amount'] = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.presence',
            },
            numericality: {
                greaterThanOrEqualTo: 0,
                lessThan: 500000000,
                message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.numericality',
            },
        };
    }
    const receiptType = (_p = data.receipt) === null || _p === void 0 ? void 0 : _p.type;
    // Receipt document is mandatory if can chose receipt type and a type is chosen
    if (claimTypeHasFeature(typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyReceiptTypes) &&
        !isIdentityDocument &&
        data.userKnowPrice) {
        constraints['receipt.type'] = {
            presence: {
                allowEmpty: false,
                message: 'validator.requiredReceiptType',
            },
        };
        if (!receiptType) {
            if (!isIdentityDocument && data.userKnowPrice) {
                constraints['receipt.fileCollection'] = {
                    requiredFileCollection: {
                        message: 'validator.requiredReceiptType',
                        trigram: typeTrigram,
                    },
                };
            }
            else if (data.thirdPartyProductId) {
                constraints['receipt.fileCollection'] = {
                    requiredFileCollection: {
                        message: 'validator.requiredReceiptType',
                        trigram: typeTrigram,
                    },
                };
            }
        }
        else if (receiptType === PersonalPropertyReceiptType.Pictures) {
            constraints.pictureCollection = {
                requiredFileCollection: {
                    message: 'validator.requiredFileCollection',
                    trigram: typeTrigram,
                },
            };
        }
        else if (receiptType !== PersonalPropertyReceiptType.None &&
            receiptType !== PersonalPropertyReceiptType.CashWithdrawal) {
            constraints['receipt.fileCollection'] = {
                requiredFileCollection: {
                    message: 'validator.requiredFileCollection',
                    trigram: typeTrigram,
                },
            };
        }
    }
    return constraints;
}
export default personalPropertyConstraints;
