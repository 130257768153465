import { LOCKER_DOOR_TYPE_MODEL_LABELS, LOCKER_DOOR_TYPE_MODEL_CHOICES, GOOD_DAMAGE_TYPE_LABELS, LOCKER_DOOR_TYPE_LABELS, } from '../goodDamage';
import { translateChoices, numbersToChoices } from '@shared/choice';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalTranslatableEnumTransform, optionalString, optionalBooleanTransform, } from '@shared/utils/transform';
export function lockerDetails(model, translate) {
    if (!model.type || !model.doorType || !model.doorCount)
        return null;
    const key = `${GOOD_DAMAGE_TYPE_LABELS[model.type]}.description`;
    return translate(key, model.doorCount, {
        type: translate(LOCKER_DOOR_TYPE_LABELS[model.doorType]).toLowerCase(),
        count: model.doorCount,
    });
}
export function lockerFields(model, context) {
    const { translate, index } = context;
    return [
        {
            label: translate('model.good_damage.locker.door_type'),
            value: model.doorType,
            displayedValue: optionalTranslatableEnumTransform(model.doorType, LOCKER_DOOR_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    nullable: true,
                    choices: translateChoices(LOCKER_DOOR_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.doorType`,
                toPayload: (doorType) => ({ doorType }),
            },
        },
        {
            label: translate('model.good_damage.locker.door_count'),
            value: model.doorCount,
            displayedValue: optionalString(model.doorCount),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    nullable: true,
                    choices: numbersToChoices(1, 2, 3),
                },
                modelKey: `goodDamages.${index}.doorCount`,
                toPayload: (doorCount) => ({ doorCount }),
            },
        },
        {
            label: translate('model.good_damage.locker.is_layout_damaged'),
            value: model.isLayoutDamaged,
            displayedValue: optionalBooleanTransform(model.isLayoutDamaged, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    nullable: true,
                    choices: translateChoices(YES_OR_NO_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.isLayoutDamaged`,
                toPayload: (isLayoutDamaged) => ({ isLayoutDamaged }),
            },
        },
    ];
}
