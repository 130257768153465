import { objectToTranslatableChoices } from '@shared/choice';
import { ElectricalDamageCauseOrigin } from '@shared/types/api/claim';
import { ElectricalDamageType, ElectricalDamageRepairableStatus, } from '@shared/types/api/electricalDamage';
export const CAUSE_ORIGIN_TRAD_KEYS = {
    [ElectricalDamageCauseOrigin.AtHome]: 'claim.qualification.electrical.cause_origin.home',
    [ElectricalDamageCauseOrigin.Network]: 'claim.qualification.electrical.cause_origin.network',
    [ElectricalDamageCauseOrigin.Thunder]: 'claim.qualification.electrical.cause_origin.thunder',
    [ElectricalDamageCauseOrigin.Unknown]: 'claim.qualification.electrical.cause_origin.unknown',
};
export const CAUSE_ORIGIN_CHOICES = objectToTranslatableChoices(CAUSE_ORIGIN_TRAD_KEYS);
export const ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS = {
    [ElectricalDamageType.GarageDoorMotorization]: 'claim.damage.electrical_damage.form.type.garage_door_motorization',
    [ElectricalDamageType.GateMotorization]: 'claim.damage.electrical_damage.form.type.gate_motorization',
    [ElectricalDamageType.HeatPump]: 'claim.damage.electrical_damage.form.type.heat_pump',
    [ElectricalDamageType.RollingShuttersMotorization]: 'claim.damage.electrical_damage.form.type.rolling_shutters_motorization',
    [ElectricalDamageType.Intercom]: 'claim.damage.electrical_damage.form.type.intercom',
    [ElectricalDamageType.Alarm]: 'claim.damage.electrical_damage.form.type.alarm',
    [ElectricalDamageType.PhotovoltaicPanels]: 'claim.damage.electrical_damage.form.type.photovoltaic_panels',
    [ElectricalDamageType.Swimpool]: 'claim.damage.electrical_damage.form.type.swimpool',
    [ElectricalDamageType.Other]: 'claim.damage.electrical_damage.form.type.other',
};
export const ELECTRICAL_DAMAGE_REPAIRABLE_STATUSES_TRAD_KEYS = {
    [ElectricalDamageRepairableStatus.Repairable]: 'model.electrical_damage.repairable',
    [ElectricalDamageRepairableStatus.Irreparable]: 'model.electrical_damage.irreparable',
};
export const ELECTRICAL_DAMAGE_TYPES_CHOICES = objectToTranslatableChoices(ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS);
export const ELECTRICAL_DAMAGE_TYPES_BASE = [
    ElectricalDamageType.RollingShuttersMotorization,
    ElectricalDamageType.GateMotorization,
    ElectricalDamageType.GarageDoorMotorization,
    ElectricalDamageType.Intercom,
    ElectricalDamageType.HeatPump,
    ElectricalDamageType.Alarm,
    ElectricalDamageType.PhotovoltaicPanels,
    ElectricalDamageType.Swimpool,
];
export const ELECTRICAL_DAMAGE_TYPES_OTHER = Object.values(ElectricalDamageType).filter((e) => !ELECTRICAL_DAMAGE_TYPES_BASE.includes(e));
export const ELECTRICAL_DAMAGE_TYPES_BASE_CHOICES = ELECTRICAL_DAMAGE_TYPES_CHOICES.filter((e) => {
    return ELECTRICAL_DAMAGE_TYPES_BASE.includes(e.value);
});
export const ELECTRICAL_DAMAGE_TYPES_OTHER_CHOICES = ELECTRICAL_DAMAGE_TYPES_CHOICES.filter((e) => {
    return ELECTRICAL_DAMAGE_TYPES_OTHER.includes(e.value);
});
