import { Step } from '@shared/claim/steps';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { MandatoryDocumentStatus, } from '@shared/types/documents';
import { DocumentAnalyseStatus } from '@shared/types/api/file';
import { CLAIM_FILE_TYPE_TRAD_KEYS } from '@shared/types/file';
import { personalPropertyTitle } from '@shared/personalProperty/personalPropertyFields';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import { serialize } from '@shared/serialization/apiResourceSerializer';
import { fetchFile } from '@shared/files/fetchFile';
/**
 * Transforms the collection of documents from the API into a collection of usable object to use in the application
 * @param docs List of mandatory documents from the API
 * @param claim The plain Claim payload
 * @returns The hydrated collection of mandatory documents
 */
export async function parseMandatoryDocuments(docs, claim, analysis, axios) {
    return await Promise.all(docs.map((doc) => parseMandatoryDocument(doc, claim, analysis, axios))).then((docs) => docs.filter((doc) => !!doc));
}
/**
 * Transforms the document from the API into an usable object to use in the application
 * @param doc The mandatory document response from the API
 * @param claim The plain Claim payload
 * @returns The hydrated mandatory document
 */
export async function parseMandatoryDocument(doc, claim, analysis, axios) {
    var _a, _b;
    let parent = claim;
    let parentParam = null;
    // * Example : simple path
    // claim.robberyDamageQualification.complaintReceiptFileCollection
    // parts = [ 'claim', 'robberyDamageQualification', 'complaintReceiptFileCollection']
    // * Example : path with collection
    // claim.counting.breakInDamages/ec97fffe-adee-11ed-bc1f-0242ac120006.repairFileCollection
    // parts = [ 'claim', 'counting', 'breakInDamages/ec97fffe-adee-11ed-bc1f-0242ac120006', 'repairFileCollection' ]
    const uploadedAppFileCollection = doc.linkedTo
        .split('.')
        .reduce((acc, part, i) => {
        var _a, _b;
        parent = acc;
        parentParam = part;
        if (i === 0)
            return acc;
        if (acc === null)
            return null;
        if (part.includes('/')) {
            const [collectionKey, id] = part.split('/');
            const collection = acc[collectionKey];
            if (Array.isArray(collection)) {
                const item = (_a = collection.find((i) => { var _a; return (_a = i.id) === null || _a === void 0 ? void 0 : _a.includes(id); })) !== null && _a !== void 0 ? _a : null;
                return item;
            }
            return null;
        }
        else {
            // * Edge case : We need data from the PersonalProperty when deadling with a PersonalPropertyReceipt to get the document title
            if (((_b = acc[part]) === null || _b === void 0 ? void 0 : _b['@type']) === ApiEntityType.PersonalPropertyReceipt) {
                return { ...acc[part], personalProperty: acc };
            }
            return acc[part];
        }
    }, claim);
    const parameters = (_b = (_a = doc.mandatoryParameters) === null || _a === void 0 ? void 0 : _a.map(parseMandatoryParameter.bind(null, claim)).filter((p) => !!p)) !== null && _b !== void 0 ? _b : [];
    if (!uploadedAppFileCollection || !parent || !parentParam)
        return null;
    const status = await parseDocumentStatus(doc, uploadedAppFileCollection, analysis, axios);
    return { ...doc, uploadedAppFileCollection, parent, parentParam, status, parameters };
}
export function parseMandatoryParameter(claim, path) {
    let entity = claim;
    let entityParam = null;
    const value = path.split('.').reduce((acc, part, i) => {
        var _a;
        entity = acc;
        entityParam = part;
        if (i === 0)
            return acc;
        if (acc === null)
            return null;
        if (part.includes('/')) {
            const [collectionKey, id] = part.split('/');
            const collection = acc[collectionKey];
            return Array.isArray(collection) ? (_a = collection.find((i) => { var _a; return (_a = i.id) === null || _a === void 0 ? void 0 : _a.includes(id); })) !== null && _a !== void 0 ? _a : null : null;
        }
        else
            return acc[part];
    }, claim);
    if (entity === null || entityParam === null)
        return null;
    return { path, parameter: entityParam, entity, value };
}
/**
 * Creates a new payload to send to the API when editing the mandatory document
 * @param document The hydrated document to create the payload from
 * @param payload The new files collection to merge into the payload, and optionnaly the personalPropertyReceipt type
 * @param claim The plain Claim payload
 * @returns A payload to use in Claim updates
 */
export function createMandatoryDocumentPayload(claim, document, uploadedAppFileCollection, parameters) {
    var _a, _b;
    if (claim === null)
        return null;
    const parts = document.linkedTo.split('.');
    const payloadResult = {
        id: claim.id,
        step: Step.DocumentsSynthesisEdition,
    };
    let parentPayload = payloadResult;
    let parentResouce = claim;
    for (const [i, part] of parts.entries()) {
        if (i === 0)
            continue;
        if (parentResouce === null)
            return null;
        if (part.includes('/')) {
            const [collectionKey, id] = part.split('/');
            const collection = parentResouce[collectionKey];
            if (Array.isArray(collection)) {
                const entity = (_a = collection.find((o) => o.id.includes(id))) !== null && _a !== void 0 ? _a : {};
                const entityPayload = {
                    '@id': entity['@id'],
                    '@type': entity['@type'],
                    id: entity.id,
                    type: entity.type,
                };
                const collectionPayload = collection.map((o) => {
                    var _a;
                    if ((_a = o.id) === null || _a === void 0 ? void 0 : _a.includes(id))
                        return entityPayload;
                    return o.id;
                });
                parentPayload[collectionKey] = collectionPayload;
                parentPayload = entityPayload;
                parentResouce = entity;
                continue;
            }
            return null;
        }
        else {
            const entity = (_b = parentResouce[part]) !== null && _b !== void 0 ? _b : {};
            const entityPayload = {
                '@id': entity['@id'],
                '@type': entity['@type'],
                id: entity.id,
                type: entity.type,
            };
            parentPayload[part] = entityPayload;
            parentPayload = entityPayload;
            parentResouce = entity;
        }
    }
    const collectionPayload = parentPayload;
    collectionPayload.uploadedAppFiles = uploadedAppFileCollection.uploadedAppFiles;
    parameters === null || parameters === void 0 ? void 0 : parameters.forEach((param) => {
        var _a, _b, _c, _d;
        const paramParts = param.path.split('.');
        let parentParamPayload = payloadResult;
        let parentParamResource = { ...claim, ...payloadResult };
        for (const [i, part] of paramParts.entries()) {
            if (i === 0)
                continue;
            if (parentParamResource === null || i === paramParts.length - 1)
                break;
            if (part.includes('/')) {
                const [collectionKey, id] = part.split('/');
                const collection = parentParamResource[collectionKey];
                const payloadCollection = parentParamPayload[collectionKey];
                if (Array.isArray(collection)) {
                    const paramEntity = (_a = collection.find((o) => (typeof o === 'string' ? o : o.id).includes(id))) !== null && _a !== void 0 ? _a : {};
                    const paramEntityPayload = (_b = payloadCollection === null || payloadCollection === void 0 ? void 0 : payloadCollection.find((o) => (typeof o === 'string' ? o : o.id).includes(id))) !== null && _b !== void 0 ? _b : {
                        '@id': paramEntity['@id'],
                        '@type': paramEntity['@type'],
                        id: paramEntity.id,
                        type: paramEntity.type,
                    };
                    const collectionPayload = (payloadCollection !== null && payloadCollection !== void 0 ? payloadCollection : collection).map((o) => {
                        const oid = typeof o === 'string' ? o : o.id;
                        if (oid.includes(id))
                            return paramEntityPayload;
                        return oid;
                    });
                    parentParamPayload[collectionKey] = collectionPayload;
                    parentParamPayload = paramEntityPayload;
                    parentParamResource = paramEntity;
                }
                else {
                    parentParamResource = null;
                }
            }
            else {
                const paramEntity = (_c = parentParamResource[part]) !== null && _c !== void 0 ? _c : {};
                const paramEntityPayload = (_d = parentParamPayload[part]) !== null && _d !== void 0 ? _d : {
                    '@id': paramEntity['@id'],
                    '@type': paramEntity['@type'],
                    id: paramEntity.id,
                    type: paramEntity.type,
                };
                parentParamPayload[part] = paramEntityPayload;
                parentParamPayload = paramEntityPayload;
                parentParamResource = paramEntity;
            }
        }
        parentParamPayload[param.parameter] = param.value;
    });
    return serialize(payloadResult);
}
/**
 * Generates a display title for the provided mandatory document
 * @param doc The document to generate the title from
 * @param categories The list of personalProperyCategories (required for translation)
 * @param translate The translate function (usualy, this.$tc or i18n.tc)
 * @returns The translated document title
 */
export function getMandatoryDocumentTitle(doc, categories, translate) {
    const title = translate(CLAIM_FILE_TYPE_TRAD_KEYS[doc.fileType]);
    const parentTitle = getParentTitle(doc.parent, categories, translate);
    if (parentTitle)
        return `${title} - ${parentTitle}`;
    return title;
}
// TODO Complete this function as we add more mandatory document rules
/**
 * Generates a display title for the provided mandatory document parent entity
 * @param entity API entity from which the title is extracted
 * @param categories The list of personalProperyCategories (required for translation)
 * @param translate The translate function (usualy, this.$tc or i18n.tc)
 * @returns The translated entity title
 */
function getParentTitle(entity, categories, translate) {
    switch (entity['@type']) {
        case ApiEntityType.FenceBreakInDamage:
        case ApiEntityType.GateBreakInDamage:
        case ApiEntityType.LittleGateBreakInDamage:
        case ApiEntityType.SlidingBayWindowBreakInDamage:
        case ApiEntityType.GarageDoorBreakInDamage:
        case ApiEntityType.WindowBreakInDamage:
        case ApiEntityType.ShutterBreakInDamage:
        case ApiEntityType.DoorBreakInDamage:
        case ApiEntityType.OtherBreakInDamage:
        case ApiEntityType.FrenchWindowBreakInDamage:
        case ApiEntityType.BreakInDamage: {
            const e = entity;
            return breakInDamageTitle(e, translate);
        }
        case ApiEntityType.PersonalProperty: {
            const e = entity;
            return personalPropertyTitle(e, categories);
        }
        case ApiEntityType.PersonalPropertyReceipt: {
            const e = entity;
            return personalPropertyTitle(e.personalProperty, categories);
        }
        default:
            return null;
    }
}
async function parseDocumentStatus(doc, fileCollection, analysis, axios) {
    var _a;
    if (!doc.isUploaded)
        return MandatoryDocumentStatus.Missing;
    if (!analysis || !doc.needAnalysis)
        return MandatoryDocumentStatus.Uploaded;
    const fileValue = (_a = fileCollection.mergedFile) !== null && _a !== void 0 ? _a : fileCollection.uploadedAppFiles[0];
    const file = typeof fileValue === 'string' ? await fetchFile(fileValue, axios) : fileValue;
    if (!(file === null || file === void 0 ? void 0 : file.documentAnalyse))
        return MandatoryDocumentStatus.Pending;
    return {
        [DocumentAnalyseStatus.Uploaded]: MandatoryDocumentStatus.Pending,
        [DocumentAnalyseStatus.ToRetryUpload]: MandatoryDocumentStatus.Pending,
        [DocumentAnalyseStatus.FailedUpload]: MandatoryDocumentStatus.NotVerified,
        [DocumentAnalyseStatus.Ingested]: MandatoryDocumentStatus.Pending,
        [DocumentAnalyseStatus.ToVerify]: MandatoryDocumentStatus.Pending,
        [DocumentAnalyseStatus.ToRetryCheck]: MandatoryDocumentStatus.Pending,
        [DocumentAnalyseStatus.Error]: MandatoryDocumentStatus.Invalid,
        [DocumentAnalyseStatus.Rejected]: MandatoryDocumentStatus.Invalid,
        [DocumentAnalyseStatus.Verified]: MandatoryDocumentStatus.Valid,
    }[file.documentAnalyse.status];
}
