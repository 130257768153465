import { ImpactsNumber, LeavesType, Material, Measurement } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
export const FRENCH_WINDOW_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES = {
    [LeavesType.OneLeaf]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.one-leaf',
        image: require('@declaration/assets/images/breakInDamage/frenchwindow/type/un-battant.svg'),
    },
    [LeavesType.TwoLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.two-leaves',
        image: require('@declaration/assets/images/breakInDamage/frenchwindow/type/deux-battants.svg'),
    },
    [LeavesType.ThreeLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.three-leaves',
        image: require('@declaration/assets/images/breakInDamage/frenchwindow/type/trois-battants.svg'),
    },
};
export const FRENCH_WINDOW_MATERIAL_CHOICES = [
    {
        value: Material.Wood,
        tradKey: 'claim.declaration.break_in_damages.form.material.wood',
    },
    {
        value: Material.PVC,
        tradKey: 'claim.declaration.break_in_damages.form.material.pvc',
    },
    {
        value: Material.Metal,
        tradKey: 'claim.declaration.break_in_damages.form.material.metal',
    },
];
export const WOOD_FRENCH_WINDOW_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.AtLeastOne,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
    },
];
export const METAL_FRENCH_WINDOW_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.MoreThanThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    },
];
export const PVC_FRENCH_WINDOW_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.FourToSix,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.four-to-six',
    },
    {
        value: ImpactsNumber.MoreThanSeven,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-seven',
    },
];
export const FRENCH_WINDOW_HEIGHT_CHOICES = [
    {
        value: Measurement.LessThan220cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-220cm',
    },
    {
        value: Measurement.MoreThan220cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-220cm',
    },
];
export const FRENCH_WINDOW_ONE_LEAF_WIDTH_CHOICES = [
    {
        value: Measurement.LessThan95cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-95cm',
    },
    {
        value: Measurement.MoreThan95cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-95cm',
    },
];
export const FRENCH_WINDOW_TWO_LEAVES_WIDTH_CHOICES = [
    {
        value: Measurement.LessThan145cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-145cm',
    },
    {
        value: Measurement.MoreThan145cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-145cm',
    },
];
export const REPAIR_STATUS_CHOICES_FRENCH_WINDOW = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.french-window',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.french-window',
    },
];
