import { objectToTranslatableChoices } from '@shared/choice';
import { GlassDamageType, GlassType, GlazingType } from '@shared/types/api/glassDamage';
export const GLASS_DAMAGE_TYPE_TRAD_KEYS = {
    [GlassDamageType.Awning]: 'claim.damage.glass_damage.form.type.awning',
    [GlassDamageType.FixedBayWindow]: 'claim.damage.glass_damage.form.type.fiwed_bay_window',
    [GlassDamageType.FrenchWindow]: 'claim.damage.glass_damage.form.type.french_window',
    [GlassDamageType.GlassWall]: 'claim.damage.glass_damage.form.type.glass_wall',
    [GlassDamageType.Greenhouse]: 'claim.damage.glass_damage.form.type.greenhouse',
    [GlassDamageType.Pergola]: 'claim.damage.glass_damage.form.type.pergola',
    [GlassDamageType.SlidingBayWindow]: 'claim.damage.glass_damage.form.type.sliding_bay_window',
    [GlassDamageType.SwimpoolCover]: 'claim.damage.glass_damage.form.type.swimpool_cover',
    [GlassDamageType.Transom]: 'claim.damage.glass_damage.form.type.transom',
    [GlassDamageType.Velux]: 'claim.damage.glass_damage.form.type.velux',
    [GlassDamageType.Veranda]: 'claim.damage.glass_damage.form.type.veranda',
    [GlassDamageType.Window]: 'claim.damage.glass_damage.form.type.window',
    [GlassDamageType.Other]: 'claim.damage.glass_damage.form.type.other',
};
export const GLASS_DAMAGE_GLAZING_TYPE_TRAD_KEYS = {
    [GlazingType.Simple]: 'claim.damage.glass_damage.form.glazing_type.simple',
    [GlazingType.Double]: 'claim.damage.glass_damage.form.glazing_type.double',
    [GlazingType.Triple]: 'claim.damage.glass_damage.form.glazing_type.triple',
    [GlazingType.Unknown]: 'claim.damage.glass_damage.form.glazing_type.unknown',
};
export const GLASS_DAMAGE_GLASS_TYPE_TRAD_KEYS = {
    [GlassType.Laminated]: 'claim.damage.glass_damage.form.glass_type.laminated',
    [GlassType.NonLaminated]: 'claim.damage.glass_damage.form.glass_type.non_laminated',
    [GlassType.Unknown]: 'claim.damage.glass_damage.form.glass_type.unknown',
};
export const GLASS_DAMAGE_TYPE_CHOICES = objectToTranslatableChoices(GLASS_DAMAGE_TYPE_TRAD_KEYS);
export const GLASS_DAMAGE_GLAZING_TYPE_CHOICES = objectToTranslatableChoices(GLASS_DAMAGE_GLAZING_TYPE_TRAD_KEYS);
export const GLASS_DAMAGE_GLASS_TYPE_CHOICES = objectToTranslatableChoices(GLASS_DAMAGE_GLASS_TYPE_TRAD_KEYS);
