import { NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST_CHOICES } from '@shared/constants/thirdParty';
import { ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { booleanTransform, optionalString } from '@shared/utils/transform';
import thirdPartyConstraints from '@shared/validator/constraints/thirdPartyConstraints';
export function thirdPartyTitle(model) {
    var _a, _b, _c;
    let title = '';
    if ((_a = model.contact) === null || _a === void 0 ? void 0 : _a.firstName) {
        title += model.contact.firstName + ' ';
    }
    if ((_b = model.contact) === null || _b === void 0 ? void 0 : _b.lastName) {
        title += model.contact.lastName + ' ';
    }
    if ((_c = model.contact) === null || _c === void 0 ? void 0 : _c.companyName) {
        title += model.contact.companyName;
    }
    return title.trim() || 'Tiers';
}
const thirdPartyConstraintsFactory = () => thirdPartyConstraints;
export const thirdPartyFields = (model, { translate, projection, edition, vehicle }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const title = projection === ModelFieldsProjection.Declaration || edition ? thirdPartyTitle(model) : '';
    const insuranceCompany = typeof model.insuranceCompany === 'string' ? null : (_a = model.insuranceCompany) !== null && _a !== void 0 ? _a : null;
    const isCompany = !!((_b = model.contact) === null || _b === void 0 ? void 0 : _b.isCompany);
    const fields = [];
    if (projection === ModelFieldsProjection.Declaration || edition) {
        fields.push({
            label: translate('model.third_party.contact.is_company'),
            value: isCompany,
            displayedValue: booleanTransform(isCompany, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Checkbox,
                },
                modelKey: 'contact.isCompany',
            },
        });
    }
    if (projection !== ModelFieldsProjection.Declaration) {
        fields.push({
            separator: isCompany ? 'Société' : 'Individu',
        });
    }
    if (isCompany) {
        fields.push({
            label: translate('model.third_party.contact.company_name'),
            value: (_c = model.contact) === null || _c === void 0 ? void 0 : _c.companyName,
            displayedValue: optionalString((_d = model.contact) === null || _d === void 0 ? void 0 : _d.companyName),
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'contact.companyName',
            },
        });
    }
    else {
        fields.push({
            label: translate('model.third_party.contact.first_name'),
            value: (_e = model.contact) === null || _e === void 0 ? void 0 : _e.firstName,
            displayedValue: optionalString((_f = model.contact) === null || _f === void 0 ? void 0 : _f.firstName),
            editable: {
                input: {
                    type: ModelFieldInputType.Text, // Wierd ts inference bug
                },
                modelKey: 'contact.firstName',
            },
        }, {
            label: translate('model.third_party.contact.last_name'),
            value: (_g = model.contact) === null || _g === void 0 ? void 0 : _g.lastName,
            displayedValue: optionalString((_h = model.contact) === null || _h === void 0 ? void 0 : _h.lastName),
            editable: {
                input: {
                    type: ModelFieldInputType.Text, // Wierd ts inference bug
                },
                modelKey: 'contact.lastName',
            },
        });
    }
    fields.push({
        label: translate('model.third_party.contact.address'),
        value: (_j = model.contact) === null || _j === void 0 ? void 0 : _j.address,
        displayedValue: optionalString((_k = model.contact) === null || _k === void 0 ? void 0 : _k.address),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'contact.address',
        },
    }, {
        label: translate('model.third_party.contact.address_affix'),
        value: (_l = model.contact) === null || _l === void 0 ? void 0 : _l.addressAffix,
        displayedValue: optionalString((_m = model.contact) === null || _m === void 0 ? void 0 : _m.addressAffix),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'contact.addressAffix',
        },
    }, {
        label: translate('model.third_party.contact.zipcode'),
        value: (_o = model.contact) === null || _o === void 0 ? void 0 : _o.zipcode,
        displayedValue: optionalString((_p = model.contact) === null || _p === void 0 ? void 0 : _p.zipcode),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'contact.zipcode',
        },
    }, {
        label: translate('model.third_party.contact.city'),
        value: (_q = model.contact) === null || _q === void 0 ? void 0 : _q.city,
        displayedValue: optionalString((_r = model.contact) === null || _r === void 0 ? void 0 : _r.city),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'contact.city',
        },
    }, {
        label: translate('model.third_party.contact.phone'),
        value: (_s = model.contact) === null || _s === void 0 ? void 0 : _s.phone,
        displayedValue: optionalString((_t = model.contact) === null || _t === void 0 ? void 0 : _t.phone),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'contact.phone',
        },
    }, {
        label: translate('model.third_party.contact.email'),
        value: (_u = model.contact) === null || _u === void 0 ? void 0 : _u.email,
        displayedValue: optionalString((_v = model.contact) === null || _v === void 0 ? void 0 : _v.email),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'contact.email',
        },
    });
    if (vehicle) {
        if (projection !== ModelFieldsProjection.Declaration) {
            fields.push({
                separator: 'Véhicule',
                layout: { column: 1 },
            });
        }
        fields.push({
            label: translate('model.third_party.vehicle.registration_number'),
            value: model.vehicleRegistrationNumber,
            displayedValue: optionalString(model.vehicleRegistrationNumber),
            layout: { column: 1 },
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'vehicleRegistrationNumber',
            },
        });
    }
    if (projection !== ModelFieldsProjection.Declaration) {
        fields.push({ separator: 'Assurance', layout: { column: 1 } });
    }
    function getTradKeyForCompanyName(companyName) {
        const choice = NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST_CHOICES.find((choice) => choice.value === companyName);
        return choice ? choice.tradKey : undefined;
    }
    const tradKey = getTradKeyForCompanyName(insuranceCompany === null || insuranceCompany === void 0 ? void 0 : insuranceCompany.name);
    fields.push({
        label: translate('model.third_party.insurance.name'),
        value: insuranceCompany === null || insuranceCompany === void 0 ? void 0 : insuranceCompany.name,
        displayedValue: tradKey
            ? optionalString(translate(tradKey))
            : optionalString(insuranceCompany === null || insuranceCompany === void 0 ? void 0 : insuranceCompany.name),
        layout: {
            column: 1,
        },
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'insuranceCompany.name',
        },
    }, {
        label: translate('model.third_party.insurance.phone'),
        value: insuranceCompany === null || insuranceCompany === void 0 ? void 0 : insuranceCompany.phone,
        displayedValue: optionalString(insuranceCompany === null || insuranceCompany === void 0 ? void 0 : insuranceCompany.phone),
        layout: {
            column: 1,
        },
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'insuranceCompany.phone',
        },
    }, {
        label: translate('model.third_party.insurance.email'),
        value: insuranceCompany === null || insuranceCompany === void 0 ? void 0 : insuranceCompany.email,
        displayedValue: optionalString(insuranceCompany === null || insuranceCompany === void 0 ? void 0 : insuranceCompany.email),
        layout: {
            column: 1,
        },
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'insuranceCompany.email',
        },
    }, {
        label: translate('model.third_party.contract_number'),
        value: model.contractNumber,
        displayedValue: optionalString(model.contractNumber),
        layout: {
            column: 1,
        },
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'contractNumber',
        },
    });
    return { title, fields, model, editable: { constraints: thirdPartyConstraintsFactory } };
};
