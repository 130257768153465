import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { ApiResourceName } from '@shared/types/api/api';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { thirdPartyFormValuesToPostData, thirdPartyGetDataToFormValues, } from '@declaration/helpers/serializers/thirdPartySerializer';
import { stringContainsCaseInsensitive } from '@declaration/utils/string';
import { Step } from '@shared/claim/steps';
import { isValid, newValidator, revealViolations, touch, validate, } from '@shared/validator/validator';
import thirdPartyConstraints from '@shared/validator/constraints/thirdPartyConstraints';
import { ValidationContext } from '@shared/validator/validationContext';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
export const name = 'thirdParty';
export const namespaced = true;
const newThirdParty = () => {
    return {
        iri: null,
        clientIdentifier: newClientIdentifier(),
        contact: {
            isCompany: false,
            companyName: null,
            lastName: null,
            firstName: null,
            address: null,
            addressAffix: null,
            zipcode: null,
            city: null,
            phone: null,
            email: null,
        },
        insuranceCompany: { id: null, name: null, email: null, phone: null, handWrittenName: null },
        vehicleRegistrationNumber: null,
        contractNumber: null,
    };
};
export const state = () => {
    const thirdPartyInEdition = newThirdParty();
    const validator = newValidator(thirdPartyInEdition);
    validate(validator, thirdPartyInEdition, thirdPartyConstraints);
    return {
        thirdParties: [],
        thirdPartyInEditionIndex: null,
        thirdPartyInEdition,
        thirdPartyValidator: validator,
        insuranceCompanyChoices: [],
    };
};
export const getters = {
    validation(state) {
        return new ValidationContext(state.thirdPartyValidator);
    },
    rawInsuranceCompanyNames(state) {
        return state.insuranceCompanyChoices
            .map((insuranceCompany) => insuranceCompany.name)
            .sort();
    },
    filteredInsuranceCompanyNames(state) {
        return state.insuranceCompanyChoices
            .filter((insuranceCompany) => state.thirdPartyInEdition.insuranceCompany.name
            ? stringContainsCaseInsensitive(state.thirdPartyInEdition.insuranceCompany.name, insuranceCompany.name)
            : true)
            .map((insuranceCompany) => insuranceCompany.name)
            .sort();
    },
    hasSelectedInsuranceCompany(state, getters) {
        return (state.thirdPartyInEdition.insuranceCompany.name !== null &&
            getters.filteredInsuranceCompanyNames.length === 1 &&
            getters.filteredInsuranceCompanyNames[0].toLowerCase() ===
                state.thirdPartyInEdition.insuranceCompany.name.toLowerCase());
    },
};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    addNewThirdPartyAndEditIt({ commit, state, rootState }, shouldPrefillWithInsureeAddress) {
        const newThirdPartyIndex = state.thirdParties.length;
        const newThirdPartyWithCompletedAddress = newThirdParty();
        if (shouldPrefillWithInsureeAddress && rootState.contract.selectedContract) {
            newThirdPartyWithCompletedAddress.contact.address =
                rootState.contract.selectedContract.contact.address;
            newThirdPartyWithCompletedAddress.contact.addressAffix =
                rootState.contract.selectedContract.contact.addressAffix;
            newThirdPartyWithCompletedAddress.contact.zipcode =
                rootState.contract.selectedContract.contact.zipcode;
            newThirdPartyWithCompletedAddress.contact.city =
                rootState.contract.selectedContract.contact.city;
        }
        commit('ADD_NEW_THIRD_PARTY_AND_EDIT_IT', {
            newThirdPartyIndex,
            newThirdPartyWithCompletedAddress,
        });
    },
    async fetchInsuranceCompanyChoicesIfNotSet({ state, commit }) {
        if (state.insuranceCompanyChoices.length) {
            return;
        }
        try {
            const { data } = await this.$axios.get(`${apiResourcePath(ApiResourceName.InsuranceCompanies)}?isFromReferentialList=true&isIARD=true`);
            const insuranceCompanyChoices = data['hydra:member'];
            commit('SET_INSURANCE_COMPANY_CHOICES', insuranceCompanyChoices);
        }
        catch (_a) { }
    },
    updateStoreWithClaimResponse({ commit }, claimResponse) {
        commit('SET_THIRD_PARTIES', claimResponse.thirdParties.map(thirdPartyGetDataToFormValues));
    },
    setThirdPartyInEditionIndex({ commit }, thirdPartyInEditionIndex) {
        commit('SET_THIRD_PARTIES_IN_EDITION_AND_INDEX', thirdPartyInEditionIndex);
    },
    validateThirdPartyInEdition({ commit }) {
        commit('VALIDATE_AND_REVEAL_THIRD_PARTY');
    },
    async saveThirdPartyInEdition({ state, commit, dispatch }, payload) {
        if (!state.thirdPartyValidator) {
            return;
        }
        commit('VALIDATE_AND_REVEAL_THIRD_PARTY');
        if (!isValid(state.thirdPartyValidator)) {
            return;
        }
        const thirPartyPayload = [];
        state.thirdParties.forEach((thirdParty, index) => {
            if (index === state.thirdPartyInEditionIndex) {
                thirPartyPayload.push(thirdPartyFormValuesToPostData(state.thirdPartyInEdition));
                return;
            }
            if (thirdParty.iri) {
                thirPartyPayload.push({
                    id: thirdParty.iri,
                });
            }
        });
        await dispatch('claim/saveClaimWithPayload', { thirdParties: thirPartyPayload, step: Step.ThirdPartiesDetails, ...payload }, {
            root: true,
        });
    },
    async deleteThirdPartyByIndex({ commit, state, dispatch }, thirdPartyIndex) {
        if (thirdPartyIndex >= state.thirdParties.length) {
            return;
        }
        const thirdPartyToDelete = state.thirdParties[thirdPartyIndex];
        if (thirdPartyToDelete.iri) {
            try {
                await this.$axios.$delete(thirdPartyToDelete.iri);
            }
            catch (_a) {
                return dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
            }
        }
        commit('DELETE_THIRD_PARTY_BY_INDEX', thirdPartyIndex);
    },
    async deleteThirdPartyInEdition({ commit, state, dispatch }) {
        if (!state.thirdPartyInEdition) {
            return;
        }
        if (!state.thirdPartyInEdition.iri) {
            commit('DELETE_THIRD_PARTY_IN_EDITION');
            return;
        }
        try {
            await this.$axios.$delete(state.thirdPartyInEdition.iri);
            commit('DELETE_THIRD_PARTY_IN_EDITION');
        }
        catch (_a) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
        }
    },
    updateThirdPartyInEdition({ commit }, payload) {
        commit('UPDATE_THIRD_PARTY_IN_EDITION', payload);
    },
    updateContactOfThirdPartyInEdition({ commit }, payload) {
        commit('UPDATE_CONTACT_OF_THIRD_PARTY_IN_EDITION', payload);
    },
    updateInsuranceCompanyOfThirdPartyInEdition({ commit }, payload) {
        commit('UPDATE_INSURANCE_COMPANY_OF_THIRD_PARTY_IN_EDITION', payload);
    },
    async validateThirdPartyStep({ dispatch, rootState }, step) {
        // ensure Step.ThirdPartiesDetails is in StepStack
        if (!rootState.claim.claim.stepStack.includes(Step.ThirdPartiesDetails)) {
            try {
                await dispatch('claim/saveStepWithoutStoreUpdate', Step.ThirdPartiesDetails, { root: true });
            }
            catch (_a) {
                dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
                return;
            }
        }
        await dispatch('claim/updateAndSaveClaim', {
            step,
            claimPayload: {
                thirdPartyIncidentDescription: rootState.claim.claim.thirdPartyIncidentDescription,
            },
        }, { root: true });
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    ADD_NEW_THIRD_PARTY_AND_EDIT_IT(state, { newThirdPartyIndex, newThirdPartyWithCompletedAddress, }) {
        state.thirdParties.push(newThirdPartyWithCompletedAddress);
        state.thirdPartyInEditionIndex = newThirdPartyIndex;
        state.thirdPartyInEdition = cloneDeep(newThirdPartyWithCompletedAddress);
        state.thirdPartyValidator = newValidator(state.thirdPartyInEdition);
        validate(state.thirdPartyValidator, state.thirdPartyInEdition, thirdPartyConstraints);
    },
    SET_THIRD_PARTIES(state, thirdParties) {
        state.thirdParties = thirdParties;
    },
    SET_INSURANCE_COMPANY_CHOICES(state, insuranceCompanyChoices) {
        state.insuranceCompanyChoices = insuranceCompanyChoices;
    },
    SET_THIRD_PARTIES_IN_EDITION_AND_INDEX(state, thirdPartyInEditionIndex) {
        state.thirdPartyInEditionIndex = thirdPartyInEditionIndex;
        state.thirdPartyInEdition =
            thirdPartyInEditionIndex === null
                ? newThirdParty()
                : cloneDeep(state.thirdParties[thirdPartyInEditionIndex]);
        state.thirdPartyValidator = newValidator(state.thirdPartyInEdition);
        validate(state.thirdPartyValidator, state.thirdPartyInEdition, thirdPartyConstraints);
    },
    SAVE_THIRD_PARTY_IN_EDITION(state) {
        if (state.thirdPartyInEditionIndex === null) {
            state.thirdParties = [...state.thirdParties, state.thirdPartyInEdition];
            return;
        }
        Vue.set(state.thirdParties, state.thirdPartyInEditionIndex, state.thirdPartyInEdition);
    },
    DELETE_THIRD_PARTY_BY_INDEX(state, thirdPartyIndex) {
        state.thirdParties = state.thirdParties.filter((_, index) => index !== thirdPartyIndex);
    },
    DELETE_THIRD_PARTY_IN_EDITION(state) {
        state.thirdParties = state.thirdParties.filter((_, index) => index !== state.thirdPartyInEditionIndex);
        state.thirdPartyInEditionIndex = null;
        state.thirdPartyInEdition = newThirdParty();
        state.thirdPartyValidator = newValidator(state.thirdPartyInEdition);
    },
    VALIDATE_AND_REVEAL_THIRD_PARTY(state) {
        validate(state.thirdPartyValidator, state.thirdPartyInEdition, thirdPartyConstraints);
        revealViolations(state.thirdPartyValidator);
    },
    UPDATE_THIRD_PARTY_IN_EDITION(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            Vue.set(state.thirdPartyInEdition, key, value);
            touch(state.thirdPartyValidator, key);
            validate(state.thirdPartyValidator, state.thirdPartyInEdition, thirdPartyConstraints);
        });
    },
    UPDATE_CONTACT_OF_THIRD_PARTY_IN_EDITION(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            Vue.set(state.thirdPartyInEdition.contact, key, value);
            touch(state.thirdPartyValidator, `contact.${key}`);
            validate(state.thirdPartyValidator, state.thirdPartyInEdition, thirdPartyConstraints);
        });
    },
    UPDATE_INSURANCE_COMPANY_OF_THIRD_PARTY_IN_EDITION(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            Vue.set(state.thirdPartyInEdition.insuranceCompany, key, value);
            touch(state.thirdPartyValidator, `insuranceCompany.${key}`);
            validate(state.thirdPartyValidator, state.thirdPartyInEdition, thirdPartyConstraints);
        });
    },
};
