import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { damageSeverityField, dimensionsFields, impactsNumberField, otherDamagesFields, } from '@shared/breakInDamage/breakInDamageFields';
import { translatableObjectToChoices, translateChoices } from '@shared/choice';
import { DamageSeverity, ImpactsNumber } from '@shared/types/api/breakInDamage';
import { SLIDING_BAY_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, SLIDING_BAY_WIDTH_CHOICES, } from '@shared/constants/breakInDamages/SlidingBayConstants';
import { GLASS_TYPE_TRAD_KEYS_AND_IMAGES, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/breakInDamageConstants';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <SlidingBayWindow>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function slidingBayWindowBreakInDamageEditionFields(model, translate, projection, edition = false) {
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.break_in_damage.leaves_type'),
            key: 'slidingBayLeavesType',
            value: model.slidingBayLeavesType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(SLIDING_BAY_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'slidingBayLeavesType',
            },
        });
    }
    fields.push(damageSeverityField(model, translate));
    if (model.damageSeverity === DamageSeverity.Critical) {
        if (edition) {
            fields.push(...slidingBayWindowBreakInDamageCriticalSeverityFields(model, translate));
        }
    }
    else if (model.damageSeverity === DamageSeverity.Notable) {
        fields.push(impactsNumberField(model, translate));
        if (edition) {
            if (model.numberOfImpacts === ImpactsNumber.MoreThanThree) {
                fields.push(...slidingBayWindowBreakInDamageCriticalSeverityFields(model, translate));
            }
            else {
                fields.push({
                    label: translate('model.break_in_damage.broken_window'),
                    key: 'slidingBayHasBrokenTiles',
                    value: model.slidingBayHasBrokenTiles,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'slidingBayHasBrokenTiles',
                    },
                });
                if (model.slidingBayHasBrokenTiles) {
                    fields.push({
                        label: translate('model.break_in_damage.broken_tiles_number'),
                        key: 'slidingBayNumberOfBrokenTiles',
                        value: model.slidingBayNumberOfBrokenTiles,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'slidingBayNumberOfBrokenTiles',
                        },
                    }, {
                        label: translate('model.break_in_damage.broken_window.height'),
                        key: 'slidingBayBiggestBrokenTileHeight',
                        value: model.slidingBayBiggestBrokenTileHeight,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'slidingBayBiggestBrokenTileHeight',
                        },
                    }, {
                        label: translate('model.break_in_damage.broken_window.width'),
                        key: 'slidingBayBiggestBrokenTileWidth',
                        value: model.slidingBayBiggestBrokenTileWidth,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'slidingBayBiggestBrokenTileWidth',
                        },
                    }, {
                        label: translate('model.break_in_damage.glazing_type'),
                        key: 'slidingBayGlazingType',
                        value: model.slidingBayGlazingType,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Radio,
                                choices: translatableObjectToChoices(GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                                vertical: true,
                            },
                            modelKey: 'slidingBayGlazingType',
                        },
                    }, {
                        label: translate('model.break_in_damage.glass_type'),
                        key: 'slidingBayGlassType',
                        value: model.slidingBayGlassType,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Radio,
                                choices: translatableObjectToChoices(GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                            },
                            modelKey: 'slidingBayGlassType',
                        },
                    });
                }
                fields.push({
                    label: translate('model.break_in_damage.lock_damage'),
                    key: 'slidingBayLockIsDamaged',
                    value: model.slidingBayLockIsDamaged,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'slidingBayLockIsDamaged',
                    },
                });
            }
        }
        else if ((projection === ModelFieldsProjection.Backoffice ||
            projection !== ModelFieldsProjection.Pdf) &&
            model.slidingBayHasBrokenTiles) {
            fields.push({
                label: translate('model.break_in_damage.broken_tiles_number'),
                key: 'slidingBayNumberOfBrokenTiles',
                value: model.slidingBayNumberOfBrokenTiles,
                displayedValue: '' + model.slidingBayNumberOfBrokenTiles,
            }, {
                label: translate('model.break_in_damage.broken_window.height'),
                key: 'slidingBayBiggestBrokenTileHeight',
                value: model.slidingBayBiggestBrokenTileHeight,
                displayedValue: '' + model.slidingBayBiggestBrokenTileHeight + 'cm',
            }, {
                label: translate('model.break_in_damage.broken_window.width'),
                key: 'slidingBayBiggestBrokenTileWidth',
                value: model.slidingBayBiggestBrokenTileWidth,
                displayedValue: '' + model.slidingBayBiggestBrokenTileWidth + 'cm',
            }, {
                label: translate('model.break_in_damage.glazing_type'),
                key: 'slidingBayGlazingType',
                value: model.slidingBayGlazingType,
                displayedValue: optionalTranslatableEnumTransform(model.slidingBayGlazingType, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            }, {
                label: translate('model.break_in_damage.glass_type'),
                key: 'slidingBayGlassType',
                value: model.slidingBayGlassType,
                displayedValue: optionalTranslatableEnumTransform(model.slidingBayGlassType, GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            });
        }
        if (projection !== ModelFieldsProjection.Declaration &&
            model.numberOfImpacts !== ImpactsNumber.MoreThanThree) {
            fields.push(...otherDamagesFields(model, translate));
        }
    }
    // We check the conditions for critical severity again but this time also checking if we're in
    // readonly mode because in this mode we want the dimensions fields to appear at the end
    if (!edition &&
        (model.damageSeverity === DamageSeverity.Critical ||
            model.numberOfImpacts === ImpactsNumber.MoreThanThree)) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function slidingBayWindowBreakInDamageCriticalSeverityFields(model, translate) {
    return [
        {
            label: translate('claim.declaration.break_in_damages.form.sliding-bay.width'),
            key: 'slidingBayWidth',
            value: model.slidingBayWidth,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(SLIDING_BAY_WIDTH_CHOICES, translate),
                    vertical: true,
                },
                modelKey: 'slidingBayWidth',
            },
        },
        {
            label: translate('model.break_in_damage.glazing_type'),
            key: 'slidingBayGlazingType',
            value: model.slidingBayGlazingType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                    vertical: true,
                },
                modelKey: 'slidingBayGlazingType',
            },
        },
        {
            label: translate('model.break_in_damage.glass_type'),
            key: 'slidingBayGlassType',
            value: model.slidingBayGlassType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'slidingBayGlassType',
            },
        },
    ];
}
