export var Step;
(function (Step) {
    Step["Index"] = "index";
    Step["Unavailable"] = "indisponible";
    Step["ContractChoice"] = "contrat/choix-du-contrat";
    Step["ContractInformation"] = "contrat/information-du-contrat";
    Step["ContractModification"] = "contrat/modification";
    Step["ClaimType"] = "type-du-sinistre";
    Step["ClaimDate"] = "qualification/date-du-sinistre";
    Step["DeclarationDeadlineOver"] = "sortie/delai-declaration-depasse";
    Step["NoAdministrativeFollowup"] = "sortie/sans-suite-administrative";
    Step["NoFollowUp"] = "sortie/sans-suite";
    Step["ClaimCompleted"] = "sortie/declaration-completee";
    Step["ClaimCompletedArtisan"] = "sortie/declaration-completee-artisan";
    Step["NoSuitableExpertiseSchedule"] = "sortie/pas-de-creneau-expert";
    Step["TransferToPae"] = "sortie/transfert-vers-pae";
    Step["DeclarationDocumentPending"] = "sortie/document-en-attente";
    Step["PremiseFurnished"] = "qualification/local-meuble";
    Step["PremiseVacant"] = "qualification/local-vacant";
    Step["LeaveBeforeSinister"] = "qualification/conge-avant-sinistre";
    Step["LeakageCauseLocation"] = "qualification/degat-des-eaux/cause-de-la-fuite";
    Step["LeakageCauseLocationHouse"] = "qualification/degat-des-eaux/cause-de-la-fuite-maison";
    Step["OwnerHasDamagesOnTenantProperties"] = "dommages/votre-locataire-a-t-il-des-dommages-mobiliers";
    Step["WaterLeakageCauseFixed"] = "qualification/degat-des-eaux/reparation";
    Step["WaterLeakageCauseLocalized"] = "qualification/degat-des-eaux/fuite-localisee";
    Step["WaterLeakageCauseLocalizedPlumberIntervened"] = "qualification/degat-des-eaux/fuite-localisee/plombier-intervenu";
    Step["WaterLeakageCauseLocalizedPlumberIntervenedInvoice"] = "qualification/degat-des-eaux/fuite-localisee/plombier-intervenu/facture";
    Step["WaterLeakageCauseAdvisorContact"] = "contacter-un-conseiller-dde";
    Step["IsRehousingNeeded"] = "qualification/besoin-de-relogement";
    Step["NeedHelpToFindLeakage"] = "qualification/recherche-de-fuite";
    Step["YourDamages"] = "qualification/pr\u00E9ciser-vos-dommages-uniquement";
    Step["BeCalledQualificationQuestion"] = "qualification/etre-appele";
    Step["BeCalledConfirmation"] = "prise-de-rendez-vous/etre-appele/confirmation";
    Step["BeCalledConfirmationTNG"] = "prise-de-rendez-vous/etre-appele/appeler-un-conseiller-TNG";
    Step["NeedRehousing"] = "prise-de-rendez-vous/besoin-de-relogement";
    Step["ShouldRepairLeakage"] = "veuillez-reparer-la-fuite";
    Step["ShouldLocalizeLeakage"] = "qualification/attente-localisation-fuite";
    Step["Smuggling"] = "introduction-clandestine-vol";
    Step["WaterLeakageCauseNotLocalized"] = "fuite-non-localisee";
    Step["ShouldContactOwnerLeakage"] = "veuillez-contacter-votre-proprietaire-fuite";
    Step["NumberOfRoomDamages"] = "dommages/nombre-de-pieces";
    Step["NumberOfPersonalPropertyDamages"] = "dommages/nombre-de-dommages-mobiliers";
    Step["HasDamages"] = "dommages/avez-vous-des-dommages";
    Step["HasPersonalPropertyDamages"] = "dommages/avez-vous-des-dommages-mobiliers";
    Step["HasRoomDamages"] = "dommages/avez-vous-des-dommages-immobiliers";
    Step["LeakageCauseRoom"] = "qualification/degat-des-eaux/piece-en-cause";
    Step["LeakageCauseEquipment"] = "qualification/degat-des-eaux/localisation-du-probleme";
    Step["LeakageCauseEquipmentDetail"] = "qualification/degat-des-eaux/localisation-du-probleme-detail";
    Step["LeakageCauseCommonArea"] = "qualification/degat-des-eaux/localisation-du-probleme-dans-les-parties-communes";
    Step["LeakageCauseOutsideHouse"] = "qualification/degat-des-eaux/localisation-du-probleme-exterieur-maison";
    Step["EquipmentAge"] = "qualification/degat-des-eaux/age-de-l-equipement";
    Step["OutsiderIntervention"] = "qualification/intervention-d-un-tiers";
    Step["NoDamage"] = "dommages/aucun-degats";
    Step["TenantInsurer"] = "prise-en-charge-assurance-locataire";
    Step["OwnerInsurer"] = "prise-en-charge-assurance-proprietaire";
    Step["ValuationOwnerInsurer"] = "chiffrage-prise-en-charge-assurance-proprietaire";
    Step["RoomDamagesOwnerInsurer"] = "dommages-immobiliers-assurance-proprietaire";
    Step["TenantNotCovered"] = "locataire/non-couvert";
    Step["ElectricalCauseOrigin"] = "qualification/degat-electrique/origine-probleme";
    Step["ElectricalDeclarationInfos"] = "qualification/degat-electrique/infos-declaration-dommages";
    Step["ElectricalDamageType"] = "qualification/degat-electrique/type-de-dommages";
    Step["ElectricalDamageFoodAmount"] = "qualification/degat-electrique/montant-denrees-alimentaires";
    Step["ElectricalNumberOfDamagedEquipments"] = "qualification/degat-electrique/nombre-equipements-endommages";
    Step["IsAwareOfPublicWorks"] = "qualification/degat-electrique/intervention-reseau-public";
    Step["HasProfessionalIntervened"] = "qualification/intervention-professionnelle";
    Step["ProfessionalInterventionInfo"] = "qualification/intervention-professionnelle/information";
    Step["ProfessionalInterventionInvoice"] = "qualification/intervention-professionnelle/justificatif";
    Step["PublicWorksInfo"] = "qualification/degat-electrique/intervention-reseau-public/info";
    Step["ElectricalCauseEquipment"] = "qualification/degat-electrique/equipement-en-cause";
    Step["StormDate"] = "qualification/degat-electrique/date-de-l-orage";
    Step["ElectricalDamages"] = "dommages/degat-electrique/immobilier";
    Step["ElectricalDamagesDetails"] = "dommages/degat-electrique/immobilier/details";
    Step["StormCauseType"] = "qualification/tempete-neige-grele/type-de-cause";
    Step["IsProtectionMeasureDone"] = "qualification/mesures-conservatoires/entrepris";
    Step["IsHelpForProtectionMeasureNeeded"] = "qualification/mesures-conservatoires/besoin-assistance";
    Step["IsThirdPartyInvolved"] = "qualification/tiers-implique";
    Step["RobberyPNOExit"] = "declaration/cambriolage/sortie-pno";
    Step["RobberyEventType"] = "qualification/cambriolage/type-evenement";
    Step["RobberyLostKeysCircumstances"] = "qualification/cambriolage/perte-cle/circonstances";
    Step["RobberyLostKeysLockSelection"] = "qualification/cambriolage/perte-cle/selection-serrures";
    Step["RobberyDuringAbsence"] = "qualification/cambriolage/absence";
    Step["RobberyAbsenceDates"] = "qualification/cambriolage/dates-absence";
    Step["RobberyLeaveDate"] = "qualification/cambriolage/date-depart";
    Step["RobberyType"] = "qualification/cambriolage/type";
    Step["RobberyLocation"] = "qualification/cambriolage/lieu";
    Step["RobberyProtectionMeans"] = "qualification/cambriolage/moyens-de-protection";
    Step["RobberyProtectionMeasures"] = "qualification/cambriolage/mesures-de-protection";
    Step["RobberyComplaintFiledIntro"] = "qualification/cambriolage/depot-de-plainte-intro";
    Step["RobberyComplaintNotFiled"] = "qualification/cambriolage/depot-de-plainte";
    Step["RobberyPersonalPropertyCount"] = "qualification/cambriolage/nombre-de-biens-voles";
    Step["RobberyMethod"] = "qualification/cambriolage/methode";
    Step["RobberyHouseSecured"] = "qualification/cambriolage/protection";
    Step["RobberyHouseSecuredYesNo"] = "qualification/cambriolage/protection/oui-non";
    Step["RobberyHouseIsEmergencyServiceNeeded"] = "qualification/cambriolage/besoin-service-urgence";
    Step["BreakInDamages"] = "dommages/cambriolage/deteriorations";
    Step["BreakInDamagesDetails"] = "dommages/cambriolage/deteriorations/details";
    Step["BreakInDamagesMissingFile"] = "dommages/justificatif-manquant";
    Step["RobberyVandalismAuthorKnown"] = "qualification/cambriolage/auteur-vandalisme-connu";
    Step["RobberyVandalismAuthor"] = "qualification/cambriolage/auteur-vandalisme";
    Step["PersonalRobberyEventType"] = "qualification/cambriolage/vol-a-la-personne/type-evenement";
    Step["PersonalRobberyConcernedPerson"] = "qualification/cambriolage/vol-a-la-personne/personne-concernee";
    Step["RobberyDamagesDeclarationInfos"] = "qualification/cambriolage/infos-declaration-dommages";
    Step["DamagesDeclarationSynthesis"] = "qualification/synthese-declaration-dommages";
    Step["DroughtDeclaration"] = "qualification/secheresse/declaration";
    Step["DroughtAppointment"] = "qualification/secheresse/prise-de-rendez-vous";
    Step["QualificationCertification"] = "qualification/certification";
    Step["RoomDamages"] = "dommages/immobilier";
    Step["RoomDamagesDetails"] = "dommages/immobilier/details";
    Step["PersonalProperties"] = "dommages/mobilier";
    Step["PersonalPropertiesDetails"] = "dommages/mobilier/details";
    Step["ExternalDamages"] = "dommages/exterieurs";
    Step["ExternalDamagesDetails"] = "dommages/exterieurs/details";
    Step["Equipments"] = "dommages/equipements";
    Step["EquipmentsDetails"] = "dommages/equipements/details";
    Step["ThirdParties"] = "declaration-des-tiers";
    Step["ThirdPartiesDetails"] = "declaration-des-tiers/details";
    Step["Sumup"] = "dommages/recapitulatif";
    Step["CompensationOptions"] = "indemnisation/options";
    Step["CompensationRemainingWorks"] = "indemnisation/travaux-restants";
    Step["CompensationRemainingWorksConfirmation"] = "indemnisation/travaux-restants-confirmation";
    Step["CompensationPartnerOption"] = "indemnisation/artisan-partenaire/proposition";
    Step["CompensationProposition"] = "indemnisation/proposition";
    Step["CompensationLostLeys"] = "indemnisation/perte-de-cles";
    Step["CompensationConfirmation"] = "indemnisation/confirmation";
    Step["CompensationOtherArtisanSendInvoice"] = "indemnisation/autre-artisan/envoi-facture";
    Step["CompensationLowDeductible"] = "indemnisation/inferieur-a-franchise";
    Step["CompensationNoDamages"] = "indemnisation/aucun-dommage";
    Step["CompensationNotCoveredGuarantee"] = "indemnisation/garantie-non-couvert";
    Step["CompensationLostSharedKeys"] = "indemnisation/perte-de-cles-communes";
    Step["CompensationDamagesSumUp"] = "indemnisation/recapitulatif-dommages";
    Step["CompensationSsDiNonOccConvDi"] = "indemnisation/ss-digit-non-occ-conv-di";
    Step["ExpertAppointmentByPhoneIndex"] = "prise-de-rendez-vous/expert/par-telephone";
    Step["ExpertAppointmentByPhoneChoice"] = "prise-de-rendez-vous/expert/par-telephone/choix-de-la-date";
    Step["ExpertAppointmentByPhoneConfirmation"] = "prise-de-rendez-vous/expert/par-telephone/confirmation";
    Step["ExpertAppointmentByVisioIndex"] = "prise-de-rendez-vous/expert/par-visio-conference";
    Step["ExpertAppointmentByVisioVerification"] = "prise-de-rendez-vous/expert/par-visio-conference/verification";
    Step["ExpertAppointmentByVisioChoice"] = "prise-de-rendez-vous/expert/par-visio-conference/choix-de-la-date";
    Step["ExpertAppointmentByVisioConfirmation"] = "prise-de-rendez-vous/expert/par-visio-conference/confirmation";
    Step["ExpertAppointmentOnSiteIndex"] = "prise-de-rendez-vous/expert/sur-site";
    Step["ExpertAppointmentOnSiteChoice"] = "prise-de-rendez-vous/expert/sur-site/choix-de-la-date";
    Step["ExpertAppointmentOnSiteConfirmation"] = "prise-de-rendez-vous/expert/sur-site/confirmation";
    Step["ExpertRecontactForAppointment"] = "prise-de-rendez-vous/expert/recontact";
    Step["ArtisanLeakageCauseSearchAppointmentIndex"] = "prise-de-rendez-vous/artisan/recherche-de-fuite";
    Step["ArtisanLeakageCauseSearchAppointmentChoice"] = "prise-de-rendez-vous/artisan/recherche-de-fuite/choix-de-la-date";
    Step["ArtisanLeakageCauseSearchAppointmentConfirmation"] = "prise-de-rendez-vous/artisan/recherche-de-fuite/confirmation";
    Step["ArtisanSecuringAppointmentIndex"] = "prise-de-rendez-vous/artisan/protection";
    Step["ArtisanSecuringAppointmentChoice"] = "prise-de-rendez-vous/artisan/protection/choix-de-la-date";
    Step["ArtisanSecuringAppointmentConfirmation"] = "prise-de-rendez-vous/artisan/protection/confirmation";
    Step["ArtisanRepairAppointmentChoice"] = "prise-de-rendez-vous/artisan/travaux/choix-de-la-date";
    Step["ImmediatePayoutImpossible"] = "reglement-immediat-impossible";
    Step["ImmediatePayoutIndex"] = "paiement/index";
    Step["ImmediatePayoutPending"] = "paiement/en-attente";
    Step["ImmediatePayoutSuccess"] = "paiement/succes";
    Step["ImmediatePayoutFailure"] = "paiement/echec";
    Step["DelayedPayoutIndex"] = "paiement-differe/index";
    Step["DelayedPayoutPending"] = "paiement-differe/en-attente";
    Step["DelayedPayoutSuccess"] = "paiement-differe/succes";
    Step["DelayedPayoutFailure"] = "paiement-differe/echec";
    Step["UserComment"] = "commentaire-utilisateur";
    Step["Summary"] = "resume";
    Step["SummaryValidation"] = "resume/validation";
    Step["ClaimRefused"] = "refus-total";
    Step["InManagement"] = "en-gestion";
    Step["NeedManagement"] = "reprise-en-gestion-necessaire";
    Step["Maintenance"] = "maintenance";
    Step["GlassBreakagePersonalPropertiesNonIncluded"] = "qualification/bris-de-glace/degats-mobiliers-non-inclus";
    Step["GlassBreakageDamageType"] = "qualification/bris-de-glace/type-de-dommages";
    Step["GlassBreakageSecurity"] = "qualification/bris-de-glace/securite";
    Step["GlassBreakageSecurityAssistanceNeeded"] = "qualification/bris-de-glace/securite/service-urgence";
    Step["GlassBreakageRoomDamagesCircumstances"] = "qualification/bris-de-glace/circonstances-degats-immobiliers";
    Step["GlassBreakageRoomDamagesOrigin"] = "qualification/bris-de-glace/circonstances-degats-immobiliers/origine";
    Step["GlassBreakageRoomDamagesInstallationDate"] = "qualification/bris-de-glace/circonstances-degats-immobiliers/date-de-pose";
    Step["GlassBreakageRoomDamagesThirdPartyContact"] = "qualification/bris-de-glace/circonstances-degats-immobiliers/coordonnees-du-tiers";
    Step["GlassBreakageRoomDamageDescription"] = "qualification/bris-de-glace/description-degats-immobiliers";
    Step["GlassBreakagePersonalPropertyCircumstances"] = "qualification/bris-de-glace/circonstances-degats-mobiliers";
    Step["GlassBreakagePersonalPropertyCircumstancesThirdPartyContact"] = "qualification/bris-de-glace/circonstances-degats-mobiliers/coordonnees-du-tiers";
    Step["GlassBreakagePersonalPropertyDescription"] = "qualification/bris-de-glace/description-degats-mobiliers";
    Step["GlassDamages"] = "dommages/bris-de-glace/immobilier";
    Step["GlassDamagesDetails"] = "dommages/bris-de-glace/immobilier/details";
    Step["GlassDamagesReparations"] = "dommages/bris-de-glace/immobilier/reparations";
    Step["FireBeginQuestion"] = "qualification/incendie/depart-du-feu";
    Step["FireCauseLocationHouseQuestion"] = "qualification/incendie/cause-incendie-maison";
    Step["FireCauseLocalOrigin"] = "qualification/incendie/local-origine-feu";
    Step["FireCauseExternOrigin"] = "qualification/incendie/externe-origine-feu";
    Step["FireHasRealEstateDamages"] = "qualification/incendie/dommages-immobiliers";
    Step["FireElectricalCause"] = "qualification/incendie/cause-electrique";
    Step["FireElectricalBoardAge"] = "qualification/incendie/age-compteur-electrique";
    Step["FireElectricalNetworkAge"] = "qualification/incendie/age-reseau-electrique";
    Step["FireHasProIntervention"] = "qualification/incendie/intervention-pro-dernier-mois";
    Step["FireThirdParty"] = "declaration/tier-info-incendie";
    Step["FireComplaintFiledQuestion"] = "declaration/feu/depot-de-plainte";
    Step["FireHasThirdPartyInfo"] = "declaration/coordonnees-du-tier";
    Step["FireFireplaceCause"] = "qualification/incendie/cause-cheminee";
    Step["FireFireplaceAge"] = "qualification/incendie/age-cheminee";
    Step["FireFireplaceWhoMakesWorks"] = "qualification/incendie/qui-a-fait-travaux";
    Step["FireWhoMakesWorks"] = "declaration/incendie/realisation-travaux";
    Step["FireCauseCommonPartsOrigin"] = "qualification/incendie/origine-parties-communes";
    Step["FireElectricalCauseCommonParts"] = "qualification/incendie/appareil-electrique-pc";
    Step["FireCauseNeighboorOrigin"] = "qualification/incendie/origine-voisins";
    Step["FirePersonalPropertyDescription"] = "qualification/incendie/description-degats-mobiliers";
    Step["FireOriginVehicle"] = "qualification/incendie/origine-vehicule";
    Step["FireNumberOfExternalDamages"] = "qualification/incendie/nombre-de-DI";
    Step["FireWhoIsResponsibleBarbecue"] = "declaration/incendie/responsable-barbecue";
    Step["DocumentsSynthesis"] = "synthese-documents";
    Step["DocumentsSynthesisEdition"] = "synthese-documents-edition";
    Step["DeductibleApplicationInfo"] = "qualification/information-application-franchise";
})(Step || (Step = {}));
// To be used in backoffice and helpdesk
export const StepLabels = {
    [Step.Index]: 'Accueil',
    [Step.Unavailable]: 'Garantie non disponible',
    [Step.ContractChoice]: 'Choix du contrat',
    [Step.ContractInformation]: 'Informations du contrat',
    [Step.ContractModification]: 'Modification des information du contrat',
    [Step.ClaimType]: 'Choix de la garantie',
    [Step.ClaimDate]: 'Date du sinistre',
    [Step.DeclarationDeadlineOver]: 'Fin - Déclaration hors délai',
    [Step.NoAdministrativeFollowup]: 'Fin - Sans suite administrative',
    [Step.NoFollowUp]: 'Fin - Sans suite',
    [Step.ClaimCompleted]: 'Fin - Déclaration complétée',
    [Step.NoSuitableExpertiseSchedule]: 'Fin - Pas de rendez-vous convenable',
    [Step.TransferToPae]: 'Fin - Transfert gestionnaire',
    [Step.PremiseFurnished]: 'Le local est-il meublé ?',
    [Step.PremiseVacant]: 'Le local est-il vacant ?',
    [Step.LeaveBeforeSinister]: 'Congé donné avant le sinistre ?',
    [Step.OwnerHasDamagesOnTenantProperties]: 'Avez vous connaissance de dommages mobiliers appartenant au locataire ?',
    [Step.LeakageCauseLocation]: 'Cause de la fuite ?',
    [Step.LeakageCauseLocationHouse]: 'Cause de la fuite dans la maison ?',
    [Step.WaterLeakageCauseFixed]: 'Fuite réparée ?',
    [Step.WaterLeakageCauseLocalized]: 'Fuite localisée ?',
    [Step.HasDamages]: 'Avez-vous des dommages à déclarer ?',
    [Step.IsRehousingNeeded]: 'Besoin de relogement ?',
    [Step.NeedHelpToFindLeakage]: "Besoin d'aide pour recherche de fuite ?",
    [Step.YourDamages]: 'Message : déclaration de mes dommages uniquement',
    [Step.BeCalledQualificationQuestion]: 'Etre appelé',
    [Step.BeCalledConfirmation]: 'Etre appelé confirmation',
    [Step.BeCalledConfirmationTNG]: 'Appel conseiller - Confirmation',
    [Step.NeedRehousing]: 'Besoin de relogement - Confirmation',
    [Step.ShouldRepairLeakage]: 'Pause - Réparation de la fuite',
    [Step.WaterLeakageCauseNotLocalized]: 'Pause - Fuite non localisée',
    [Step.ShouldContactOwnerLeakage]: 'Pause - Contact propriétaire localisation fuite',
    [Step.NumberOfRoomDamages]: 'Nombre de pièce endommagé ?',
    [Step.NumberOfPersonalPropertyDamages]: 'Nombre de biens mobiliers endommagés ?',
    [Step.HasPersonalPropertyDamages]: 'Présence de biens mobiliers endommagés ?',
    [Step.HasRoomDamages]: 'Présence de pièces endommagées ?',
    [Step.LeakageCauseRoom]: 'Pièce en cause de la fuite ?',
    [Step.LeakageCauseEquipment]: 'Equipement en cause de la fuite ?',
    [Step.LeakageCauseEquipmentDetail]: 'Détail équipement en cause de la fuite ?',
    [Step.LeakageCauseCommonArea]: 'Localisation de la fuite dans les parties communes ?',
    [Step.LeakageCauseOutsideHouse]: 'Localisation de la fuite extérieur de la maison ?',
    [Step.EquipmentAge]: "Age de l'équipement en cause ?",
    [Step.OutsiderIntervention]: "Intervention d'un tiers ?",
    [Step.NoDamage]: 'Fin - Aucun dégâts',
    [Step.RobberyPNOExit]: "PNO doit contacter l'assureur pour une déclaration de vol",
    [Step.RobberyEventType]: "Type d'événement en vol",
    [Step.RobberyType]: 'Type de cambriolage ?',
    [Step.RobberyComplaintFiledIntro]: 'Information sur dépôt de plainte',
    [Step.RobberyVandalismAuthorKnown]: 'Auteur du vandalisme connu ?',
    [Step.ThirdPartiesDetails]: 'Saisie du tiers',
    [Step.RobberyVandalismAuthor]: 'Auteur du vandalisme',
    [Step.RobberyLocation]: 'Lieu du sinistre',
    [Step.RobberyProtectionMeans]: 'Moyens de protection',
    [Step.RobberyProtectionMeasures]: 'Mise en oeuvre des moyens de protection',
    [Step.RobberyDuringAbsence]: 'Evénement pendant absence ?',
    [Step.RobberyLeaveDate]: "Date de début d'absence ?",
    [Step.QualificationCertification]: 'Certification de la déclaration',
    [Step.RobberyHouseIsEmergencyServiceNeeded]: "Besoin du service d'urgence ?",
    [Step.RobberyLostKeysCircumstances]: 'Circonstances de la perte de clés',
    [Step.RobberyLostKeysLockSelection]: 'Sélection des serrures',
    [Step.PersonalRobberyEventType]: 'Type de vol sur la personne',
    [Step.PersonalRobberyConcernedPerson]: 'Personne concernée par le vol sur la personne',
    [Step.RobberyPersonalPropertyCount]: 'Nombre de biens volés ?',
    [Step.RobberyComplaintNotFiled]: 'Dépôt de plainte (fin de parcours)',
    [Step.BreakInDamagesDetails]: "Saisie d'une effraction",
    [Step.BreakInDamages]: 'Liste des effractions',
    [Step.PersonalPropertiesDetails]: "Saisie d'un dommage mobilier",
    [Step.PersonalProperties]: 'Liste des dommages mobiliers',
    [Step.Sumup]: "Récapitulatif / Certification de l'état de perte",
    [Step.UserComment]: "Commentaire à l'attention de l'assureur",
    [Step.ExpertAppointmentByVisioIndex]: 'Expert visio - Page information',
    [Step.ExpertAppointmentByVisioVerification]: 'Expert visio - Vérification des moyens',
    [Step.ExpertAppointmentByVisioChoice]: 'Expert visio - Choix du créneau horaire',
    [Step.ExpertAppointmentByVisioConfirmation]: 'Expert visio - Rendez-vous confirmé',
    [Step.ExpertAppointmentByPhoneIndex]: 'Expert tel - Page information',
    [Step.ExpertAppointmentByPhoneChoice]: 'Expert tel - Choix du créneau horaire',
    [Step.ExpertAppointmentByPhoneConfirmation]: 'Expert tel - Rendez-vous confirmé',
    [Step.ExpertAppointmentOnSiteIndex]: 'Expert sur site - Page information',
    [Step.ExpertAppointmentOnSiteChoice]: 'Expert sur site - Choix du créneau horaire',
    [Step.ExpertAppointmentOnSiteConfirmation]: 'Expert sur site - Rendez-vous confirmé',
    [Step.ArtisanSecuringAppointmentIndex]: 'Artisan protection - Page information',
    [Step.ArtisanSecuringAppointmentChoice]: 'Artisan protection - Choix du créneau horaire',
    [Step.ArtisanSecuringAppointmentConfirmation]: 'Artisan protection - Rendez-vous confirmé',
    [Step.CompensationRemainingWorks]: 'Indemnisation - Choix indemnisation travaux restants',
    [Step.CompensationRemainingWorksConfirmation]: 'Indemnisation - Confirmation indemnisation travaux restants',
    [Step.CompensationLostLeys]: 'Indemnisation - Récapitulatif perte de clés',
    [Step.CompensationDamagesSumUp]: 'Indemnisation - Récapitulatif des dommages',
    [Step.DeductibleApplicationInfo]: 'Qualification - Information application de la franchise (tiers non connu)',
};
