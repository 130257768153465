export var ChoiceUsage;
(function (ChoiceUsage) {
    ChoiceUsage["Info"] = "info";
    ChoiceUsage["Warning"] = "warning";
    ChoiceUsage["Alert"] = "alert";
})(ChoiceUsage || (ChoiceUsage = {}));
function useCache(baseFunction, cache) {
    const newFunction = (input, ...otherArgs) => {
        const cached = cache.get(input);
        if (cached)
            return cached;
        const result = baseFunction(input, ...otherArgs);
        cache.set(input, result);
        return result;
    };
    return newFunction;
}
function getEntries(object) {
    return Object.entries(object);
}
function transformObjectToChoices(object) {
    return getEntries(object).map(([value, data]) => {
        if (typeof data === 'string')
            return { value, label: data };
        return { value, label: data.label };
    });
}
function transformObjectToTranslatableChoices(object) {
    return getEntries(object).map(([value, data]) => {
        if (typeof data === 'string')
            return { value, tradKey: data };
        return { value, ...data };
    });
}
function transformObjectToImageChoices(object) {
    return getEntries(object).map(([value, data]) => {
        if (typeof data === 'string')
            return { value, image: data };
        return { value, ...data };
    });
}
function transformObjectToTranslatableImageChoices(object) {
    return getEntries(object).map(([value, data]) => {
        return { value, ...data };
    });
}
function transformTranslatableObjectToChoices(object, translate) {
    return getEntries(object).map(([value, data]) => {
        return { value, label: translate(typeof data === 'string' ? data : data.tradKey) };
    });
}
function transformTranslatableImageObjectToChoices(object, translate) {
    return getEntries(object).map(([value, data]) => {
        return { value, label: translate(data.tradKey) };
    });
}
function doTranslateChoices(choices, translate) {
    return choices.map(({ value, tradKey, ...rest }) => Object.assign(rest, { value, label: translate(tradKey) }));
}
function doSortChoices(choices) {
    return choices.sort((a, b) => a.label.localeCompare(b.label));
}
const objectToChoicesCache = new WeakMap();
const objectToTranslatableChoicesCache = new WeakMap();
const objectToImageChoicesCache = new WeakMap();
const objectToTranslatableImageChoicesCache = new WeakMap();
const translatableObjectToChoicesCache = new WeakMap();
const imageObjectToChoicesCache = new WeakMap();
const translateChoicesCache = new WeakMap();
const sortChoicesCache = new WeakMap();
export const objectToChoices = useCache(transformObjectToChoices, objectToChoicesCache);
export const objectToTranslatableChoices = useCache(transformObjectToTranslatableChoices, objectToTranslatableChoicesCache);
export const objectToImageChoices = useCache(transformObjectToImageChoices, objectToImageChoicesCache);
export const objectToTranslatableImageChoices = useCache(transformObjectToTranslatableImageChoices, objectToTranslatableImageChoicesCache);
export const translatableObjectToChoices = useCache(transformTranslatableObjectToChoices, translatableObjectToChoicesCache);
export const imageObjectToChoices = useCache(transformTranslatableImageObjectToChoices, imageObjectToChoicesCache);
export function objectToBooleanChoices(obj) {
    return [
        {
            value: true,
            label: typeof obj.true === 'string' ? obj.true : obj.true.label,
        },
        {
            value: false,
            label: typeof obj.false === 'string' ? obj.false : obj.false.label,
        },
    ];
}
export function objectToBooleanTranslatableChoices(obj) {
    return [
        {
            value: true,
            tradKey: typeof obj.true === 'string' ? obj.true : obj.true.tradKey,
            descriptionTradKey: typeof obj.true === 'string' ? undefined : obj.true.descriptionTradKey,
            sumupTradKey: typeof obj.true === 'string' ? undefined : obj.true.sumupTradKey,
        },
        {
            value: false,
            tradKey: typeof obj.false === 'string' ? obj.false : obj.false.tradKey,
            descriptionTradKey: typeof obj.false === 'string' ? obj.false : obj.false.descriptionTradKey,
            sumupTradKey: typeof obj.false === 'string' ? obj.false : obj.false.sumupTradKey,
        },
    ];
}
export function objectToBooleanImageChoices(obj) {
    return [
        {
            value: true,
            image: typeof obj.true === 'string' ? obj.true : obj.true.image,
        },
        {
            value: false,
            image: typeof obj.false === 'string' ? obj.false : obj.false.image,
        },
    ];
}
export function objectToBooleanTranslatableImageChoices(obj) {
    return [
        {
            value: true,
            ...obj.true,
        },
        {
            value: false,
            ...obj.false,
        },
    ];
}
export const translateChoices = useCache(doTranslateChoices, translateChoicesCache);
export const sortChoices = useCache(doSortChoices, sortChoicesCache);
/**
 * @deprecated Please use YES_NO_CHOICES TranslatableChoices
 */
export const BOOLEAN_CHOICES = [
    { label: 'Oui', value: true },
    { label: 'Non', value: false },
];
export function enumToChoices(en) {
    return Object.values(en).map((val) => ({ value: val, label: val }));
}
export function numbersToChoices(...nums) {
    return nums.map((val) => ({ value: val, label: val.toString(10) }));
}
