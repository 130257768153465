import { BreakInDamageShutterType, ImpactsNumber, Material, Measurement, ShutterBarTypes, LeavesType, } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
export const SHUTTER_MATERIAL_CHOICES = [
    {
        value: Material.Wood,
        tradKey: 'claim.declaration.break_in_damages.form.material.wood',
    },
    {
        value: Material.PVC,
        tradKey: 'claim.declaration.break_in_damages.form.material.pvc',
    },
    {
        value: Material.Metal,
        tradKey: 'claim.declaration.break_in_damages.form.material.metal',
    },
];
export const SHUTTER_WINDOW_WIDTH_CHOICES = [
    {
        value: Measurement.LessThan120cm,
        tradKey: 'claim.declaration.break_in_damages.form.shutter.window-width.less-than-120cm',
    },
    {
        value: Measurement.MoreThan120cm,
        tradKey: 'claim.declaration.break_in_damages.form.shutter.window-width.more-than-120cm',
    },
];
export const FLAPPY_SHUTTER_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES = {
    [LeavesType.OneLeaf]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.one-leaf',
        sumupTradKey: 'claim.declaration.break_in_damages.form.shutter.flappy.one-leaf.sumup',
        image: require('@declaration/assets/images/breakInDamage/shutter/flappy/un-battant.svg'),
    },
    [LeavesType.TwoLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.two-leaves',
        sumupTradKey: 'claim.declaration.break_in_damages.form.shutter.flappy.two-leaves.sumup',
        image: require('@declaration/assets/images/breakInDamage/shutter/flappy/deux-battants.svg'),
    },
};
export const SHUTTER_BAR_TYPE_TRAD_KEYS_AND_IMAGES = {
    [ShutterBarTypes.IndividualBars]: {
        tradKey: 'claim.declaration.break_in_damages.form.shutter.type.bar.individual-bars',
        sumupTradKey: 'claim.declaration.break_in_damages.form.window.type.bar.individual-bars.sumup',
        image: require('@declaration/assets/images/breakInDamage/shutter/bar/individual-bars.svg'),
    },
    [ShutterBarTypes.Grid]: {
        tradKey: 'claim.declaration.break_in_damages.form.shutter.type.bar.grid',
        sumupTradKey: 'claim.declaration.break_in_damages.form.shutter.type.bar.grid.sumup',
        image: require('@declaration/assets/images/breakInDamage/shutter/bar/grid.svg'),
    },
};
export const WOODEN_SHUTTER_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.AtLeastOne,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
    },
];
export const METAL_SHUTTER_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.MoreThanThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    },
];
export const PVC_SHUTTER_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.FourToSix,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.four-to-six',
    },
    {
        value: ImpactsNumber.MoreThanSeven,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-seven',
    },
];
export const REPAIR_STATUS_CHOICES_SHUTTER = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.shutter',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.shutter',
    },
];
export const SHUTTER_TYPE_SUMUP_TRAD_KEYS = {
    [BreakInDamageShutterType.Bar]: 'claim.declaration.break_in_damages.form.shutter.bar.sumup',
    [BreakInDamageShutterType.Rolling]: 'claim.declaration.break_in_damages.form.shutter.rolling.sumup',
    [BreakInDamageShutterType.Folding]: 'claim.declaration.break_in_damages.form.shutter.folding.sumup',
    [BreakInDamageShutterType.Flappy]: 'claim.declaration.break_in_damages.form.shutter.flappy.sumup',
};
export const BAR_TYPE_SUMUP_TRAD_KEYS = {
    [ShutterBarTypes.IndividualBars]: 'claim.declaration.break_in_damages.form.shutter.type.bar.individual-bars.sumup',
    [ShutterBarTypes.Grid]: 'claim.declaration.break_in_damages.form.shutter.type.bar.grid.sumup',
};
