import Vue from 'vue';
Vue.directive('click-outside', {
    bind(htmlElement, binding, vnode) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const element = htmlElement; // Here for typescript
        element.clickOutsideEvent = function (event) {
            if (!vnode.context || !binding.expression) {
                return;
            }
            // Check that click was outside the el and his children
            if (element === event.target || element.contains(event.target)) {
                return;
            }
            // And if it did, call method provided in attribute value
            // binding.value() run the arg
            vnode.context[binding.expression](event);
        };
        document.body.addEventListener('click', element.clickOutsideEvent);
    },
    unbind(element) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        document.body.removeEventListener('click', element.clickOutsideEvent);
    },
});
