import { ELECTRICAL_HEATER_TYPE_MODEL_LABELS, ELECTRICAL_HEATER_TYPE_MODEL_CHOICES, ELECTRICAL_HEATER_TYPE_LABELS, } from '../goodDamage';
import { translateChoices } from '@shared/choice';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { ElectricalHeaterType, } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export function electricalHeaterTitle(model, translate) {
    if (!model.type || !model.heaterType || model.heaterType === ElectricalHeaterType.UNKNOWN)
        return null;
    return translate('claim.damage.real_estate.room_damage.form.question.good_damage.heating.electrical_heater.title', undefined, { type: translate(ELECTRICAL_HEATER_TYPE_LABELS[model.heaterType]).toLowerCase() });
}
export function electricalHeaterFields(model, context) {
    const { translate, index } = context;
    return [
        {
            label: translate('model.good_damage.electrical_heater.heater_type'),
            value: model.heaterType,
            displayedValue: optionalTranslatableEnumTransform(model.heaterType, ELECTRICAL_HEATER_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(ELECTRICAL_HEATER_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.heaterType`,
                toPayload: (heaterType) => ({ heaterType }),
            },
        },
    ];
}
