import { objectToTranslatableChoices, objectToTranslatableImageChoices, } from '@shared/choice';
import { VerandaGutterMaterial, VerandaPanelGlazingType, VerandaPanelMaterial, } from '@shared/types/api/externalDamage';
export const VERANDA_PANELS_MATERIAL_TRAD_KEYS_AND_IMAGES = {
    [VerandaPanelMaterial.Glass]: {
        tradKey: 'claim.declaration.external_damages.form.veranda.panels.material.glass',
        image: require('@declaration/assets/images/externalDamage/veranda/glass-panel.svg'),
    },
    [VerandaPanelMaterial.Polycarbonate]: {
        tradKey: 'claim.declaration.external_damages.form.veranda.panels.material.polycarbonate',
        image: require('@declaration/assets/images/externalDamage/veranda/polycarbonate-panel.svg'),
    },
    [VerandaPanelMaterial.SandwichPanel]: {
        tradKey: 'claim.declaration.external_damages.form.veranda.panels.material.sandwich_panel',
        image: require('@declaration/assets/images/externalDamage/veranda/sandwich-panel.svg'),
    },
    [VerandaPanelMaterial.Other]: {
        tradKey: 'claim.declaration.external_damages.form.veranda.panels.material.other',
        image: require('@declaration/assets/images/externalDamage/veranda/other-panel.svg'),
    },
};
export const VERANDA_PANELS_MATERIAL_CHOICES = objectToTranslatableImageChoices(VERANDA_PANELS_MATERIAL_TRAD_KEYS_AND_IMAGES);
export const VERANDA_GLASS_PANEL_GLAZING_TRAD_KEYS_AND_IMAGES = {
    [VerandaPanelGlazingType.Simple]: {
        tradKey: 'claim.declaration.external_damages.form.veranda.panels.glass.glazing.simple',
        image: require('@declaration/assets/images/externalDamage/veranda/glazing-simple.svg'),
    },
    [VerandaPanelGlazingType.Double]: {
        tradKey: 'claim.declaration.external_damages.form.veranda.panels.glass.glazing.double',
        image: require('@declaration/assets/images/externalDamage/veranda/glazing-double.svg'),
    },
    [VerandaPanelGlazingType.Unknown]: {
        tradKey: 'claim.declaration.external_damages.form.veranda.panels.glass.glazing.unknown',
        image: require('@declaration/assets/images/externalDamage/veranda/glazing-unknown.svg'),
    },
};
export const VERANDA_GLASS_PANEL_GLAZING_CHOICES = objectToTranslatableImageChoices(VERANDA_GLASS_PANEL_GLAZING_TRAD_KEYS_AND_IMAGES);
export const VERANDA_GUTTER_MATERIAL_TRAD_KEYS = {
    [VerandaGutterMaterial.Zinc]: 'claim.declaration.external_damages.form.veranda.gutter.material.zinc',
    [VerandaGutterMaterial.Pvc]: 'claim.declaration.external_damages.form.veranda.gutter.material.pvc',
    [VerandaGutterMaterial.Aluminum]: 'claim.declaration.external_damages.form.veranda.gutter.material.aluminum',
};
export const VERANDA_GUTTER_MATERIAL_CHOICES = objectToTranslatableChoices(VERANDA_GUTTER_MATERIAL_TRAD_KEYS);
