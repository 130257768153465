import { forcableAmount } from '@shared/utils/forcedAmount';
export function createEmptyValuation() {
    return {
        obsolescence: null,
        obsolescenceDeducted: null,
        obsolescenceRatio: null,
        replacement: null,
    };
}
export function createEmptyParameters() {
    return {
        ceiling: {
            type: null,
            value: null,
        },
        deductibleAmount: forcableAmount(),
        documentsAmount: null,
        proofType: null,
        exclusionReason: null,
        isVanApplicable: null,
        recoverableObsolescenceRatio: null,
    };
}
export function createEmptyCompensation() {
    return {
        total: forcableAmount(),
        delayed: forcableAmount(),
        obsolescenceDeducted: null,
        immediate: forcableAmount(),
        documentAmount: null,
        recoverableObsolescence: null,
        recoverableObsolescenceRatio: null,
        certifiedArtisanAmount: null,
    };
}
export function createEmptyAppliedAmounts() {
    return {
        total: forcableAmount(),
        delayed: forcableAmount(),
        obsolescenceDeducted: null,
        immediate: forcableAmount(),
        documentAmount: null,
        recoverableObsolescence: null,
        recoverableObsolescenceRatio: null,
        certifiedArtisanAmount: null,
    };
}
export const createEmptyRefund = () => ({
    valuation: createEmptyValuation(),
    parameters: createEmptyParameters(),
    compensation: createEmptyCompensation(),
    appliedAmounts: createEmptyAppliedAmounts(),
});
