import { ClaimTypeTrigram } from '@shared/types/api/claim';
export var ClaimTypeFeatureFlag;
(function (ClaimTypeFeatureFlag) {
    ClaimTypeFeatureFlag["PersonalPropertyWarranty"] = "personalPropertyWarranty";
    ClaimTypeFeatureFlag["PersonalPropertyRepair"] = "personalPropertyRepair";
    ClaimTypeFeatureFlag["PersonalPropertyMonthAge"] = "personalPropertyMonthAge";
    ClaimTypeFeatureFlag["PersonalPropertyPicturesOnlyForReceipt"] = "personalPropertyPicturesOnlyForReceipt";
    ClaimTypeFeatureFlag["PersonalPropertyReceiptTypes"] = "personalPropertyReceiptTypes";
    ClaimTypeFeatureFlag["PersonalPropertyOtherAsExtraButton"] = "personalPropertyOtherAsExtraButton";
    ClaimTypeFeatureFlag["PersonalPropertyDescribeOther"] = "personalPropertyDescribeOther";
    ClaimTypeFeatureFlag["PersonalPropertyPurchaseCertification"] = "PersonalPropertyPurchaseCertification";
    ClaimTypeFeatureFlag["PersonalPropertyUserKnowPriceAsCheckboxes"] = "PersonalPropertyUserKnowPriceCheckboxes";
    ClaimTypeFeatureFlag["PersonalPropertyDescriptionRequired"] = "PersonalPropertyDescriptionRequired";
    ClaimTypeFeatureFlag["PersonalPropertyAgeInputHiddenFromCreationFields"] = "PersonalPropertyAgeInputHiddenFromCreationFields";
    ClaimTypeFeatureFlag["PersonalPropertyRepairerInfo"] = "PersonalPropertyRepairerInfo";
    ClaimTypeFeatureFlag["ThirdPartyWithIncidentDescription"] = "thirdPartyWithIncidentDescription";
    ClaimTypeFeatureFlag["ClaimDateHoursAndMinutes"] = "claimDateHoursAndMinutes";
    ClaimTypeFeatureFlag["WellVisiblePecuniraryCompensationOption"] = "wellVisiblePecuniraryCompensationOption";
    ClaimTypeFeatureFlag["InitialPersonalPropertyCategoryChoice"] = "initialPersonalPropertyCategoryChoice";
    ClaimTypeFeatureFlag["PersonalPropertyReparationForm"] = "personalPropertyReparationForm";
    ClaimTypeFeatureFlag["DisplayListOfItemsToRepair"] = "displayListOfItemsToRepair";
    ClaimTypeFeatureFlag["DisplayUploadInvoiceForConfirmationStep"] = "displayUploadInvoiceForConfirmationStep";
    ClaimTypeFeatureFlag["ShowPecuniraryChoiceConfirmation"] = "showPecuniraryChoiceConfirmation";
    ClaimTypeFeatureFlag["ImmediateAmountBlock"] = "immediateAmountBlock";
    ClaimTypeFeatureFlag["RealEstateDetails"] = "RealEstateDetails";
    ClaimTypeFeatureFlag["PreFillThirdPartyAddress"] = "preFillThirdPartyAddress";
    ClaimTypeFeatureFlag["MandatoryRoomDamagedSurfaces"] = "mandatoryRoomDamagedSurfaces";
    ClaimTypeFeatureFlag["DescribeRoomDamagedGoods"] = "describeRoomDamagedGoods";
    ClaimTypeFeatureFlag["PersonalPropertiesIrreparableStatusDefault"] = "PersonalPropertiesReparableStatusDefault";
    // Representation logic used per example for backoffice/pdf display of a Claim
    ClaimTypeFeatureFlag["Receipts"] = "receipts";
    ClaimTypeFeatureFlag["RoomDamages"] = "roomDamages";
    ClaimTypeFeatureFlag["Equipments"] = "equipments";
    ClaimTypeFeatureFlag["BreakInDamages"] = "breakInDamages";
    ClaimTypeFeatureFlag["PersonalProperties"] = "personalProperties";
    ClaimTypeFeatureFlag["FoodDamages"] = "foodDamages";
    ClaimTypeFeatureFlag["ExternalDamages"] = "externalDamages";
    ClaimTypeFeatureFlag["GlassDamages"] = "glassDamages";
    ClaimTypeFeatureFlag["ElectricalDamages"] = "electricalDamages";
})(ClaimTypeFeatureFlag || (ClaimTypeFeatureFlag = {}));
const FEATURE_FLAG_BY_TYPE = {
    [ClaimTypeFeatureFlag.PersonalPropertyWarranty]: new Set([]),
    [ClaimTypeFeatureFlag.PersonalPropertyRepair]: new Set([ClaimTypeTrigram.Electric]),
    [ClaimTypeFeatureFlag.PersonalPropertyAgeInputHiddenFromCreationFields]: new Set([
        ClaimTypeTrigram.Electric,
    ]),
    [ClaimTypeFeatureFlag.PersonalPropertyMonthAge]: new Set([
        ClaimTypeTrigram.Robbery,
        ClaimTypeTrigram.Glass,
        ClaimTypeTrigram.Electric,
    ]),
    [ClaimTypeFeatureFlag.PersonalPropertyRepairerInfo]: new Set([
        ClaimTypeTrigram.Empty,
        ClaimTypeTrigram.WaterDamage,
        ClaimTypeTrigram.Robbery,
        ClaimTypeTrigram.Disaster,
        ClaimTypeTrigram.Drought,
        ClaimTypeTrigram.StormSnowHail,
        ClaimTypeTrigram.Glass,
        ClaimTypeTrigram.Fire,
        ClaimTypeTrigram.Vehicle,
        ClaimTypeTrigram.Other,
    ]),
    [ClaimTypeFeatureFlag.PersonalPropertyDescriptionRequired]: new Set([
        ClaimTypeTrigram.Empty,
        ClaimTypeTrigram.WaterDamage,
        ClaimTypeTrigram.Robbery,
        ClaimTypeTrigram.Disaster,
        ClaimTypeTrigram.Drought,
        ClaimTypeTrigram.StormSnowHail,
        ClaimTypeTrigram.Glass,
        ClaimTypeTrigram.Fire,
        ClaimTypeTrigram.Vehicle,
        ClaimTypeTrigram.Other,
    ]),
    [ClaimTypeFeatureFlag.PersonalPropertyUserKnowPriceAsCheckboxes]: new Set([
        ClaimTypeTrigram.Empty,
        ClaimTypeTrigram.WaterDamage,
        ClaimTypeTrigram.Robbery,
        ClaimTypeTrigram.Disaster,
        ClaimTypeTrigram.Drought,
        ClaimTypeTrigram.StormSnowHail,
        ClaimTypeTrigram.Glass,
        ClaimTypeTrigram.Fire,
        ClaimTypeTrigram.Vehicle,
        ClaimTypeTrigram.Other,
    ]),
    [ClaimTypeFeatureFlag.PersonalPropertyPurchaseCertification]: new Set([
        ClaimTypeTrigram.Electric,
    ]),
    [ClaimTypeFeatureFlag.PersonalPropertyPicturesOnlyForReceipt]: new Set([
        ClaimTypeTrigram.Robbery,
    ]),
    [ClaimTypeFeatureFlag.PersonalPropertyReceiptTypes]: new Set([ClaimTypeTrigram.Robbery]),
    [ClaimTypeFeatureFlag.ThirdPartyWithIncidentDescription]: new Set([
        ClaimTypeTrigram.StormSnowHail,
    ]),
    [ClaimTypeFeatureFlag.ClaimDateHoursAndMinutes]: new Set([ClaimTypeTrigram.Robbery]),
    [ClaimTypeFeatureFlag.WellVisiblePecuniraryCompensationOption]: new Set([
        ClaimTypeTrigram.StormSnowHail,
    ]),
    [ClaimTypeFeatureFlag.InitialPersonalPropertyCategoryChoice]: new Set([
        ClaimTypeTrigram.Robbery,
        ClaimTypeTrigram.Glass,
        ClaimTypeTrigram.Fire,
    ]),
    [ClaimTypeFeatureFlag.PersonalPropertyReparationForm]: new Set([ClaimTypeTrigram.Glass]),
    [ClaimTypeFeatureFlag.PersonalPropertyOtherAsExtraButton]: new Set([ClaimTypeTrigram.Glass]),
    [ClaimTypeFeatureFlag.PersonalPropertyDescribeOther]: new Set([ClaimTypeTrigram.Glass]),
    [ClaimTypeFeatureFlag.DisplayListOfItemsToRepair]: new Set([ClaimTypeTrigram.Robbery]),
    [ClaimTypeFeatureFlag.DisplayUploadInvoiceForConfirmationStep]: new Set([
        ClaimTypeTrigram.Electric,
    ]),
    [ClaimTypeFeatureFlag.ImmediateAmountBlock]: new Set([ClaimTypeTrigram.Robbery]),
    [ClaimTypeFeatureFlag.ShowPecuniraryChoiceConfirmation]: new Set([ClaimTypeTrigram.Robbery]),
    [ClaimTypeFeatureFlag.PreFillThirdPartyAddress]: new Set([ClaimTypeTrigram.WaterDamage]),
    [ClaimTypeFeatureFlag.Receipts]: new Set([
        ClaimTypeTrigram.WaterDamage,
        ClaimTypeTrigram.StormSnowHail,
    ]),
    [ClaimTypeFeatureFlag.RoomDamages]: new Set([
        ClaimTypeTrigram.WaterDamage,
        ClaimTypeTrigram.Disaster,
        ClaimTypeTrigram.Fire,
        ClaimTypeTrigram.StormSnowHail,
        ClaimTypeTrigram.Vehicle,
        ClaimTypeTrigram.Other,
    ]),
    [ClaimTypeFeatureFlag.Equipments]: new Set([ClaimTypeTrigram.StormSnowHail]),
    [ClaimTypeFeatureFlag.BreakInDamages]: new Set([ClaimTypeTrigram.Robbery]),
    [ClaimTypeFeatureFlag.GlassDamages]: new Set([ClaimTypeTrigram.Glass]),
    [ClaimTypeFeatureFlag.PersonalProperties]: new Set([
        ClaimTypeTrigram.Electric,
        ClaimTypeTrigram.Robbery,
        ClaimTypeTrigram.StormSnowHail,
        ClaimTypeTrigram.Vehicle,
        ClaimTypeTrigram.Glass,
        ClaimTypeTrigram.Fire,
        ClaimTypeTrigram.Disaster,
        ClaimTypeTrigram.WaterDamage,
    ]),
    [ClaimTypeFeatureFlag.ExternalDamages]: new Set([
        ClaimTypeTrigram.StormSnowHail,
        ClaimTypeTrigram.Fire,
    ]),
    [ClaimTypeFeatureFlag.ElectricalDamages]: new Set([ClaimTypeTrigram.Electric]),
    [ClaimTypeFeatureFlag.FoodDamages]: new Set([ClaimTypeTrigram.Electric]),
    [ClaimTypeFeatureFlag.DescribeRoomDamagedGoods]: new Set([ClaimTypeTrigram.Fire]),
    [ClaimTypeFeatureFlag.MandatoryRoomDamagedSurfaces]: new Set([ClaimTypeTrigram.WaterDamage]),
    [ClaimTypeFeatureFlag.PersonalPropertiesIrreparableStatusDefault]: new Set([
        ClaimTypeTrigram.Electric,
    ]),
    [ClaimTypeFeatureFlag.RealEstateDetails]: new Set([ClaimTypeTrigram.Electric]),
};
export function claimTypeHasFeature(type, feature) {
    return FEATURE_FLAG_BY_TYPE[feature].has(type);
}
