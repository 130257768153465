import { ClaimStatus } from '@shared/types/api/claim';
export const closingStatus = [
    ClaimStatus.DamagesValidated,
    ClaimStatus.NotCovered,
    ClaimStatus.ExpertAppointmentConfirmed,
    ClaimStatus.NoSuitableExpertiseSchedule,
    ClaimStatus.Completed,
    ClaimStatus.NoAdministrativeFollowUp,
    ClaimStatus.TransferToPae,
    ClaimStatus.Closed,
    ClaimStatus.ClosureConfirmed,
];
