import { TOWEL_DRYER_TYPE_MODEL_LABELS, TOWEL_DRYER_TYPE_MODEL_CHOICES, TOWEL_DRYER_TYPE_LABELS, GOOD_DAMAGE_TYPE_LABELS, } from '../goodDamage';
import { translateChoices } from '@shared/choice';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { TowelDryerType } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export function towelDryerTitle(model, translate) {
    if (!model.type || !model.dryerType || model.dryerType === TowelDryerType.UNKNOWN)
        return null;
    return `${translate(GOOD_DAMAGE_TYPE_LABELS[model.type])} ${translate(TOWEL_DRYER_TYPE_LABELS[model.dryerType]).toLowerCase()}`;
}
export function towelDryerFields(model, context) {
    const { translate, index } = context;
    return [
        {
            label: translate('model.good_damage.towel_dryer.dryer_type'),
            value: model.dryerType,
            displayedValue: optionalTranslatableEnumTransform(model.dryerType, TOWEL_DRYER_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(TOWEL_DRYER_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.dryerType`,
                toPayload: (dryerType) => ({ dryerType }),
            },
        },
    ];
}
