import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { damageSeverityField, dimensionsFields, editionMaterialField, impactsNumberField, otherDamagesFields, } from '@shared/breakInDamage/breakInDamageFields';
import { translatableObjectToChoices, translateChoices } from '@shared/choice';
import { DamageSeverity, ImpactsNumber, LeavesType, Material, } from '@shared/types/api/breakInDamage';
import { ONE_LEAF_WINDOW_HEIGHT_CHOICES, ONE_LEAF_WINDOW_WIDTH_CHOICES, TWO_LEAVES_WINDOW_HEIGHT_CHOICES, TWO_LEAVES_WINDOW_WIDTH_CHOICES, WINDOW_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/breakInDamages/WindowConstants';
import { GLASS_TYPE_TRAD_KEYS_AND_IMAGES, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/breakInDamageConstants';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <Window>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function windowBreakInDamageEditionFields(model, translate, projection, edition = false) {
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.break_in_damage.leaves_type'),
            key: 'windowType',
            value: model.windowType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(WINDOW_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'windowType',
            },
        });
        fields.push(editionMaterialField(model, translate));
    }
    fields.push(damageSeverityField(model, translate));
    if (model.damageSeverity === DamageSeverity.Critical) {
        if (edition) {
            fields.push(...windowBreakInDamageCriticalSeverityFields(model, translate));
        }
    }
    else if (model.damageSeverity === DamageSeverity.Notable) {
        fields.push(impactsNumberField(model, translate));
        if (edition) {
            if (model.material === Material.PVC &&
                model.numberOfImpacts === ImpactsNumber.MoreThanSeven) {
                fields.push(...windowBreakInDamageCriticalSeverityFields(model, translate));
            }
            else {
                fields.push({
                    label: translate('model.break_in_damage.broken_window'),
                    key: 'windowHasBrokenTiles',
                    value: model.windowHasBrokenTiles,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'windowHasBrokenTiles',
                    },
                });
                if (model.windowHasBrokenTiles) {
                    fields.push({
                        label: translate('model.break_in_damage.broken_tiles_number'),
                        key: 'windowNumberOfBrokenTiles',
                        value: model.windowNumberOfBrokenTiles,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'windowNumberOfBrokenTiles',
                        },
                    }, {
                        label: translate('model.break_in_damage.broken_window.height'),
                        key: 'windowBiggestBrokenTileHeight',
                        value: model.windowBiggestBrokenTileHeight,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'windowBiggestBrokenTileHeight',
                        },
                    }, {
                        label: translate('model.break_in_damage.broken_window.width'),
                        key: 'windowBiggestBrokenTileWidth',
                        value: model.windowBiggestBrokenTileWidth,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'windowBiggestBrokenTileWidth',
                        },
                    }, {
                        label: translate('model.break_in_damage.glazing_type'),
                        key: 'windowGlazingType',
                        value: model.windowGlazingType,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Radio,
                                choices: translatableObjectToChoices(GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                                vertical: true,
                            },
                            modelKey: 'windowGlazingType',
                        },
                    }, {
                        label: translate('model.break_in_damage.glass_type'),
                        key: 'windowLaminatedGlazingType',
                        value: model.windowLaminatedGlazingType,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Radio,
                                choices: translatableObjectToChoices(GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                                vertical: true,
                            },
                            modelKey: 'windowLaminatedGlazingType',
                        },
                    });
                }
            }
        }
        else if ((projection === ModelFieldsProjection.Backoffice ||
            projection !== ModelFieldsProjection.Pdf) &&
            model.windowHasBrokenTiles) {
            fields.push({
                label: translate('model.break_in_damage.broken_tiles_number'),
                key: 'windowNumberOfBrokenTiles',
                value: model.windowNumberOfBrokenTiles,
                displayedValue: '' + model.windowNumberOfBrokenTiles,
            }, {
                label: translate('model.break_in_damage.broken_window.height'),
                key: 'windowBiggestBrokenTileHeight',
                value: model.windowBiggestBrokenTileHeight,
                displayedValue: '' + model.windowBiggestBrokenTileHeight,
            }, {
                label: translate('model.break_in_damage.broken_window.width'),
                key: 'windowBiggestBrokenTileWidth',
                value: model.windowBiggestBrokenTileWidth,
                displayedValue: '' + model.windowBiggestBrokenTileWidth,
            }, {
                label: translate('model.break_in_damage.glazing_type'),
                key: 'windowGlazingType',
                value: model.windowGlazingType,
                displayedValue: optionalTranslatableEnumTransform(model.windowGlazingType, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            }, {
                label: translate('model.break_in_damage.glass_type'),
                key: 'windowLaminatedGlazingType',
                value: model.windowLaminatedGlazingType,
                displayedValue: optionalTranslatableEnumTransform(model.windowLaminatedGlazingType, GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            });
        }
        if (projection !== ModelFieldsProjection.Declaration &&
            !(model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven)) {
            fields.push(...otherDamagesFields(model, translate));
        }
    }
    // We check the conditions for critical severity again but this time also checking if we're in
    // readonly mode because in this mode we want the dimensions fields to appear at the end
    if (!edition &&
        (model.damageSeverity === DamageSeverity.Critical ||
            (model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven))) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function windowBreakInDamageCriticalSeverityFields(model, translate) {
    return [
        {
            label: translate('claim.declaration.break_in_damages.form.window.height'),
            key: 'windowHeight',
            value: model.windowHeight,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(model.windowType === LeavesType.OneLeaf
                        ? ONE_LEAF_WINDOW_HEIGHT_CHOICES
                        : TWO_LEAVES_WINDOW_HEIGHT_CHOICES, translate),
                    vertical: true,
                },
                modelKey: 'windowHeight',
            },
        },
        {
            label: translate('claim.declaration.break_in_damages.form.window.width'),
            key: 'windowWidth',
            value: model.windowWidth,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(model.windowType === LeavesType.OneLeaf
                        ? ONE_LEAF_WINDOW_WIDTH_CHOICES
                        : TWO_LEAVES_WINDOW_WIDTH_CHOICES, translate),
                    vertical: true,
                },
                modelKey: 'windowWidth',
            },
        },
        {
            label: translate('model.break_in_damage.glazing_type'),
            key: 'windowGlazingType',
            value: model.windowGlazingType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                    vertical: true,
                },
                modelKey: 'windowGlazingType',
            },
        },
        {
            label: translate('model.break_in_damage.glass_type'),
            key: 'windowLaminatedGlazingType',
            value: model.windowLaminatedGlazingType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                    vertical: true,
                },
                modelKey: 'windowLaminatedGlazingType',
            },
        },
    ];
}
