import { glassDamageFields } from '@shared/glassDamage/glassDamageFields';
import { EntityChangeType } from '@backoffice/claim/edition/entitiesChanges';
import { breakInDamageFields } from '@shared/breakInDamage/breakInDamageFields';
import { translateChoices } from '@shared/choice';
import { equipmentFields } from '@shared/equipment/equipmentFields';
import { externalDamageFields } from '@shared/externalDamage/externalDamageFields';
import { personalPropertyFields } from '@shared/personalProperty/personalPropertyFields';
import { receiptFields } from '@shared/receipt/receiptFields';
import { roomDamageFields } from '@shared/roomDamage/roomDamageFields';
import { thirdPartyFields } from '@shared/thirdParty/thirdPartyFields';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { chargeFields } from '@shared/charges/chargeFields';
import { electricalDamageFields } from '@shared/electricalDamage/electricalDamageFields';
export var ModelFieldsProjection;
(function (ModelFieldsProjection) {
    ModelFieldsProjection["Declaration"] = "declaration";
    ModelFieldsProjection["Backoffice"] = "backoffice";
    ModelFieldsProjection["Valuation"] = "valuation";
    ModelFieldsProjection["Pdf"] = "pdf";
})(ModelFieldsProjection || (ModelFieldsProjection = {}));
export var ModelFieldInputType;
(function (ModelFieldInputType) {
    ModelFieldInputType["Text"] = "text";
    ModelFieldInputType["Amount"] = "amount";
    ModelFieldInputType["Date"] = "date";
    ModelFieldInputType["DateTime"] = "datetime";
    ModelFieldInputType["Time"] = "time";
    ModelFieldInputType["Number"] = "number";
    ModelFieldInputType["Select"] = "select";
    ModelFieldInputType["MultipleChoice"] = "multipleChoice";
    ModelFieldInputType["Checkbox"] = "checkbox";
    ModelFieldInputType["FileCollection"] = "fileCollection";
    ModelFieldInputType["Radio"] = "radio";
    ModelFieldInputType["Age"] = "age";
})(ModelFieldInputType || (ModelFieldInputType = {}));
export function modelFieldKey(field) {
    if ('separator' in field) {
        return 'separator';
    }
    if ('group' in field) {
        return 'group';
    }
    if ('columns' in field) {
        return 'columns';
    }
    if ('key' in field && field.key) {
        return field.key;
    }
    if ('editable' in field && field.editable) {
        return field.editable.modelKey;
    }
    throw new Error('Could not compute model field key');
}
export function indexModelFields(modelFields) {
    const indexed = {};
    for (const field of modelFields.fields) {
        const { ...fieldCloneWithoutKey } = field;
        if ('key' in fieldCloneWithoutKey) {
            delete fieldCloneWithoutKey.key;
        }
        indexed[modelFieldKey(field)] = fieldCloneWithoutKey;
        if ('modelFields' in field) {
            // Recursively set fields for sub collections fields
            for (const subFields of field.modelFields) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ;
                subFields.fields = indexModelFields(subFields);
            }
        }
    }
    return indexed;
}
// Typescript inference doesn't work for UnknownModelToField function type here
export const MODEL_TO_FIELDS_FOR_TYPE = {
    [EntityChangeType.RoomDamage]: roomDamageFields,
    [EntityChangeType.ExternalDamage]: externalDamageFields,
    [EntityChangeType.BreakInDamage]: breakInDamageFields,
    [EntityChangeType.GlassDamage]: glassDamageFields,
    [EntityChangeType.ElectricalDamage]: electricalDamageFields,
    [EntityChangeType.Equipment]: equipmentFields,
    [EntityChangeType.PersonalProperty]: personalPropertyFields,
    [EntityChangeType.ThirdParty]: thirdPartyFields,
    [EntityChangeType.Receipt]: receiptFields,
    [EntityChangeType.Charge]: chargeFields,
};
export function isFileModelField(field) {
    return (!!field.displayedValue &&
        typeof field.displayedValue !== 'string' &&
        !Array.isArray(field.displayedValue) &&
        field.displayedValue.type === 'file');
}
export function isFileCollectionModelField(field) {
    return (!!field.displayedValue &&
        typeof field.displayedValue !== 'string' &&
        !Array.isArray(field.displayedValue) &&
        field.displayedValue.type === 'fileCollection');
}
export function isProductModelField(field) {
    return (!!field.displayedValue &&
        typeof field.displayedValue !== 'string' &&
        !Array.isArray(field.displayedValue) &&
        field.displayedValue.type === 'thirdPartyProduct');
}
export const ModelFieldInputPreset = {
    boolean: (translate) => ({
        type: ModelFieldInputType.Radio,
        choices: translateChoices(YES_OR_NO_CHOICES, translate),
    }),
    verticalBoolean: (translate) => ({
        type: ModelFieldInputType.Radio,
        choices: translateChoices(YES_OR_NO_CHOICES, translate),
        vertical: true,
    }),
    nullableBoolean: (translate) => ({
        type: ModelFieldInputType.Radio,
        choices: translateChoices(YES_OR_NO_CHOICES, translate),
        nullable: true,
    }),
    verticalNullableBoolean: (translate) => ({
        type: ModelFieldInputType.Radio,
        choices: translateChoices(YES_OR_NO_CHOICES, translate),
        nullable: true,
        vertical: true,
    }),
    autoSelect: (choices, nullable, forcedDirection) => {
        const type = choices.length <= 3 ? ModelFieldInputType.Radio : ModelFieldInputType.Select;
        return {
            type,
            choices,
            nullable: type === ModelFieldInputType.Radio ? nullable : undefined,
            vertical: type === ModelFieldInputType.Radio
                ? shouldDisplayVertical(choices, nullable, forcedDirection)
                : undefined,
        };
    },
};
function shouldDisplayVertical(choices, nullable, forcedDirection) {
    if (forcedDirection === 'vertical')
        return true;
    if (forcedDirection === 'horizontal')
        return false;
    if (choices.length + (nullable ? 1 : 0) > 3)
        return true;
    if (choices.some((choice) => choice.label.includes(' ')))
        return true;
    return false;
}
