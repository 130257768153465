import { lightSourceFields, lightSourceTitle, electricMeterFields, electricMeterTitle, doorFields, electricalHeaterFields, exposedElectricalSheathFields, exposedElectricalSheathDetails, frenchWindowFields, garageDoorFields, lockerFields, lockerDetails, slidingBayFields, towelDryerFields, visiblePipeFields, visiblePipeTitle, visiblePipeDetails, waterRadiatorFields, wcFields, windowFields, workplanFields, workplanDetails, doorTitle, doorDetails, electricalHeaterTitle, frenchWindowDetails, windowDetails, garageDoorTitle, garageDoorDetails, slidingBayDetails, towelDryerTitle, waterRadiatorDetails, } from './goodDamages';
import { getGoodDamageCategoryChoices, getGoodDamageTypeChoices, GOOD_DAMAGE_CATEGORY_LABELS, GOOD_DAMAGE_TYPE_LABELS, } from '@shared/roomDamage/goodDamage';
import { ModelFieldInputType, } from '@shared/modelField/modelField';
import { GoodDamageType, } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
import { translateChoices } from '@shared/choice';
import { isNotNull } from '@shared/utils/isNotNull';
export const goodDamageFields = (model, context) => {
    const { translate, index } = context;
    const title = optionalTranslatableEnumTransform(model.type, GOOD_DAMAGE_TYPE_LABELS, translate, optionalTranslatableEnumTransform(model.category, GOOD_DAMAGE_CATEGORY_LABELS, translate));
    const fields = [];
    if (model.room) {
        const choices = getGoodDamageCategoryChoices(model.room);
        if (choices) {
            fields.push({
                label: translate('model.good_damage.category'),
                value: model.category,
                displayedValue: optionalTranslatableEnumTransform(model.category, GOOD_DAMAGE_CATEGORY_LABELS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Select,
                        choices: translateChoices(choices, translate),
                    },
                    modelKey: `goodDamages.${index}.category`,
                    toPayload: (category) => ({ category }),
                },
            });
            if (model.category) {
                const choices = getGoodDamageTypeChoices(model.room, model.category);
                if (choices) {
                    fields.push({
                        label: translate('model.good_damage.type'),
                        value: model.type,
                        displayedValue: optionalTranslatableEnumTransform(model.type, GOOD_DAMAGE_TYPE_LABELS, translate),
                        editable: {
                            input: {
                                type: ModelFieldInputType.Select,
                                choices: translateChoices(choices, translate),
                            },
                            modelKey: `goodDamages.${index}.type`,
                            toPayload: (type) => ({ type }),
                        },
                    });
                    if (model.type) {
                        switch (model.type) {
                            case GoodDamageType.Locker:
                                fields.push(...lockerFields(model, context));
                                break;
                            case GoodDamageType.Workplan:
                                fields.push(...workplanFields(model, context));
                                break;
                            case GoodDamageType.ElectricMeter:
                                fields.push(...electricMeterFields(model, context));
                                break;
                            case GoodDamageType.LightSource:
                                fields.push(...lightSourceFields(model, context));
                                break;
                            case GoodDamageType.ExposedElectricalSheath:
                                fields.push(...exposedElectricalSheathFields(model, context));
                                break;
                            case GoodDamageType.VisiblePipes:
                                fields.push(...visiblePipeFields(model, context));
                                break;
                            case GoodDamageType.WC:
                                fields.push(...wcFields(model, context));
                                break;
                            case GoodDamageType.ElectricHeater:
                                fields.push(...electricalHeaterFields(model, context));
                                break;
                            case GoodDamageType.TowelDryer:
                                fields.push(...towelDryerFields(model, context));
                                break;
                            case GoodDamageType.WaterRadiator:
                                fields.push(...waterRadiatorFields(model, context));
                                break;
                            case GoodDamageType.SlidingBay:
                                fields.push(...slidingBayFields(model, context));
                                break;
                            case GoodDamageType.GarageDoor:
                                fields.push(...garageDoorFields(model, context));
                                break;
                            case GoodDamageType.Door:
                                fields.push(...doorFields(model, context));
                                break;
                            case GoodDamageType.Window:
                                fields.push(...windowFields(model, context));
                                break;
                            case GoodDamageType.FrenchWindow:
                                fields.push(...frenchWindowFields(model, context));
                                break;
                        }
                    }
                }
            }
        }
    }
    const toPayload = (value, model) => {
        var _a;
        const goodDamages = [
            ...((_a = model.goodDamages) !== null && _a !== void 0 ? _a : []),
        ].filter(isNotNull);
        const current = goodDamages[index];
        if (typeof current === 'string') {
            goodDamages[index] = value;
        }
        else {
            goodDamages[index] = { ...current, ...value };
        }
        return {
            goodDamages,
        };
    };
    return { title, fields, model, editable: { toPayload } };
};
export function getGoodDamageTitle(model, translate) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!model.type)
        return '-';
    const defaultTitle = translate(GOOD_DAMAGE_TYPE_LABELS[model.type]);
    switch (model.type) {
        case GoodDamageType.Door:
            return (_a = doorTitle(model, translate)) !== null && _a !== void 0 ? _a : defaultTitle;
        case GoodDamageType.ElectricHeater:
            return (_b = electricalHeaterTitle(model, translate)) !== null && _b !== void 0 ? _b : defaultTitle;
        case GoodDamageType.ElectricMeter:
            return (_c = electricMeterTitle(model, translate)) !== null && _c !== void 0 ? _c : defaultTitle;
        case GoodDamageType.GarageDoor:
            return (_d = garageDoorTitle(model, translate)) !== null && _d !== void 0 ? _d : defaultTitle;
        case GoodDamageType.LightSource:
            return (_e = lightSourceTitle(model, translate)) !== null && _e !== void 0 ? _e : defaultTitle;
        case GoodDamageType.VisiblePipes:
            return (_f = visiblePipeTitle(model, translate)) !== null && _f !== void 0 ? _f : defaultTitle;
        case GoodDamageType.TowelDryer:
            return (_g = towelDryerTitle(model, translate)) !== null && _g !== void 0 ? _g : defaultTitle;
    }
    return defaultTitle;
}
export function getGoodDamageDetails(model, translate) {
    if (model.type) {
        switch (model.type) {
            case GoodDamageType.Door:
                return doorDetails(model, translate);
            case GoodDamageType.FrenchWindow:
                return frenchWindowDetails(model, translate);
            case GoodDamageType.Locker:
                return lockerDetails(model, translate);
            case GoodDamageType.Window:
                return windowDetails(model, translate);
            case GoodDamageType.GarageDoor:
                return garageDoorDetails(model, translate);
            case GoodDamageType.Workplan:
                return workplanDetails(model);
            case GoodDamageType.ExposedElectricalSheath:
                return exposedElectricalSheathDetails(model);
            case GoodDamageType.VisiblePipes:
                return visiblePipeDetails(model);
            case GoodDamageType.SlidingBay:
                return slidingBayDetails(model, translate);
            case GoodDamageType.WaterRadiator:
                return waterRadiatorDetails(model, translate);
        }
    }
    return null;
}
