import { PergolaMaterial, PergolaDamageType } from '@shared/types/api/externalDamage';
import { objectToTranslatableChoices } from '@shared/choice';
export const PERGOLA_SPECIFICATIONS_MATERIAL_KEYS = {
    [PergolaMaterial.Wood]: 'claim.declaration.external_damages.form.pergola.specifications.material.wood',
    [PergolaMaterial.PVC]: 'claim.declaration.external_damages.form.pergola.specifications.material.pvc',
    [PergolaMaterial.Aluminium]: 'claim.declaration.external_damages.form.pergola.specifications.material.aluminium',
    [PergolaMaterial.Steel]: 'claim.declaration.external_damages.form.pergola.specifications.material.steel',
};
export const PERGOLA_DAMAGE_TYPES_KEYS = {
    [PergolaDamageType.Minor]: 'claim.declaration.external_damages.form.pergola.damage.choice.minor',
    [PergolaDamageType.Destroyed]: 'claim.declaration.external_damages.form.pergola.damage.choice.destroyed',
};
export const PERGOLA_SPECIFICATIONS_MATERIAL_CHOICES = objectToTranslatableChoices(PERGOLA_SPECIFICATIONS_MATERIAL_KEYS);
export const PERGOLA_DAMAGE_TYPES_CHOICES = objectToTranslatableChoices(PERGOLA_DAMAGE_TYPES_KEYS);
