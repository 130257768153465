import { parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { translateChoices } from '@shared/choice';
import { PIPE_TYPE_CHOICES, PIPE_TYPE_TRAD_KEYS, } from '@shared/constants/externalDamages/VisiblePipeConstants';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { PipeType } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export const visiblePipeFields = (model, { translate, stormSnowHailDamageCauseType }) => {
    const origin = stormSnowHailDamageCauseType !== null && stormSnowHailDamageCauseType !== void 0 ? stormSnowHailDamageCauseType : ClaimTypeTrigram.Fire;
    const fields = [
        {
            label: translate('model.external_damage.visible_pipe.type'),
            value: model.pipeType,
            displayedValue: optionalTranslatableEnumTransform(model.pipeType, PIPE_TYPE_TRAD_KEYS[origin], translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(PIPE_TYPE_CHOICES[origin], translate),
                },
                modelKey: 'pipeType',
                toPayload: (pipeType) => ({
                    pipeType,
                    pipeLength: [PipeType.COPPER, PipeType.PEX, PipeType.UNKNOWN].includes(pipeType)
                        ? model.pipeLength
                        : null,
                }),
            },
        },
    ];
    if ([PipeType.COPPER, PipeType.PEX, PipeType.UNKNOWN].includes(model.pipeType)) {
        fields.push({
            label: translate('model.external_damage.visible_pipe.length'),
            value: model.pipeLength,
            displayedValue: parseOptionalDimension(model.pipeLength),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'm',
                modelKey: 'pipeLength',
            },
        });
    }
    return fields;
};
