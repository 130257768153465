import { createDamageStore } from '@declaration/store/createDamageStore';
import { Step } from '@shared/claim/steps';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { glassDamageConstraints } from '@shared/validator/constraints/glassDamageConstraints';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
export const name = 'glassDamage';
export const namespaced = true;
function newGlassDamage() {
    return {
        id: null,
        clientIdentifier: newClientIdentifier(),
        type: null,
        numberOfPanes: null,
        widestPaneWidth: null,
        widestPaneHeight: null,
        glazingType: null,
        glassType: null,
        pictureCollection: emptyUploadedFileCollection(),
    };
}
const { createDamageState, damageGetters, damageActions, damageMutations } = createDamageStore(newGlassDamage, glassDamageConstraints, Step.GlassDamagesDetails, 'glassDamages');
export const state = createDamageState;
export const getters = {
    ...damageGetters,
    getGlassDamagePositionSuffix: (state) => (slug, index) => {
        if (!slug) {
            return null;
        }
        let positionWithSameCategory = 1;
        let isTheOnlyOneWithCategory = true;
        state.damages.forEach((damage, otherIndex) => {
            if (damage.type === slug && otherIndex !== index) {
                isTheOnlyOneWithCategory = false;
                if (otherIndex < index) {
                    positionWithSameCategory += 1;
                }
            }
        });
        return isTheOnlyOneWithCategory ? null : positionWithSameCategory.toString();
    },
};
export const actions = {
    ...damageActions,
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    updateType({ commit, rootState }, { type, changeKey }) {
        const data = { type };
        commit('UPDATE_DAMAGE', { changeKey, data, typeTrigram: rootState.qualification.typeTrigram });
    },
    async validateGlassDamagesStep({ dispatch, rootState, commit }) {
        if (!rootState.claim.claim.stepStack.includes(Step.GlassDamagesDetails)) {
            try {
                await dispatch('claim/saveStepWithoutStoreUpdate', Step.GlassDamagesDetails, {
                    root: true,
                });
            }
            catch (_a) {
                dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
                return;
            }
        }
        await dispatch('claim/updateAndSaveClaimStep', Step.GlassDamages, { root: true });
        commit('RESET_CHANGES');
    },
};
export const mutations = {
    ...damageMutations,
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
};
