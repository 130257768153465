import { fileCollectionFilesCount, removeFilesFromFileCollection, } from '@shared/files/uploadedFiles';
import { BreakInDamageRepairationType, RobberyBreakableElement, } from '@shared/types/api/claim';
import { BreakInDamageDocumentsType } from '@shared/constants/breakInDamageConstants';
import { ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS } from '@shared/constants/robberyConstants';
import { EMPTY } from '@shared/utils/strings';
import { DOOR_TYPE_TRAD_KEYS_AND_IMAGES } from '@shared/constants/breakInDamages/DoorConstants';
import { DamageSeverity, ImpactsNumber, OtherDamageType } from '@shared/types/api/breakInDamage';
import { OTHER_DAMAGE_TYPE_TRAD_KEYS_AND_IMAGES } from '@shared/constants/breakInDamages/OtherConstants';
export function emptyBreakInDamageDocumentFields(breakInDamage) {
    return {
        repairFileCollection: removeFilesFromFileCollection(breakInDamage.repairFileCollection),
        repairQuoteInfo: null,
        pendingRepairFileCollection: removeFilesFromFileCollection(breakInDamage.pendingRepairFileCollection),
        pendingRepairQuoteInfo: null,
    };
}
export function shouldDisplayMustBeReplacedField(breakInDamage) {
    return breakInDamage.isRepaired === false;
}
export function shouldDisplayReceiptUpload(breakInDamage) {
    return (shouldDisplayInitialReceiptUpload(breakInDamage) ||
        shouldDisplayLateReceiptUpload(breakInDamage));
}
export function shouldDisplayInitialReceiptUpload(breakInDamage) {
    return (breakInDamage.isRepaired &&
        (breakInDamage.repairationType === BreakInDamageRepairationType.Temporary ||
            breakInDamage.repairationType === BreakInDamageRepairationType.Definitive));
}
export function shouldDisplayLateReceiptUpload(breakInDamage) {
    return ((!breakInDamage.isRepaired && breakInDamage.mustBeReplaced) || isRepairTemporary(breakInDamage));
}
export function isRepairTemporary(breakInDamage) {
    return (breakInDamage.isRepaired &&
        (breakInDamage.repairationType === BreakInDamageRepairationType.Temporary ||
            breakInDamage.repairationType === BreakInDamageRepairationType.AssistanceTemporary));
}
export function shouldDisplayBothUpload(breakInDamage) {
    return (shouldDisplayLateReceiptUpload(breakInDamage) &&
        shouldDisplayInitialReceiptUpload(breakInDamage));
}
export function initializeBreakInDamage(type) {
    switch (type) {
        case RobberyBreakableElement.Door:
            return {
                type,
                doorType: null,
                doorLeavesType: null,
                material: null,
                damageSeverity: null,
                doorHasLockDamage: null,
                doorLockDamage: null,
                doorLockType: null,
                numberOfImpacts: null,
                doorHasBrokenWindowTiles: null,
                doorNumberOfBrokenWindowTiles: 1,
                doorBiggestBrokenWindowTileHeight: null,
                doorBiggestBrokenWindowTileWidth: null,
                hasOtherDamages: null,
                otherDamages: null,
            };
        case RobberyBreakableElement.Window:
            return {
                type,
                windowType: null,
                material: null,
                damageSeverity: null,
                numberOfImpacts: null,
                windowHasBrokenTiles: null,
                windowNumberOfBrokenTiles: 1,
                windowBiggestBrokenTileHeight: null,
                windowBiggestBrokenTileWidth: null,
                windowGlazingType: null,
                windowLaminatedGlazingType: null,
                hasOtherDamages: null,
                otherDamages: null,
                windowHeight: null,
                windowWidth: null,
            };
        case RobberyBreakableElement.FrenchWindow:
            return {
                type,
                frenchWindowType: null,
                material: null,
                damageSeverity: null,
                numberOfImpacts: null,
                frenchWindowHasBrokenTiles: null,
                frenchWindowNumberOfBrokenTiles: 1,
                frenchWindowBiggestBrokenTileHeight: null,
                frenchWindowBiggestBrokenTileWidth: null,
                frenchWindowLockIsDamaged: null,
                frenchWindowGlazingType: null,
                frenchWindowLaminatedGlazingType: null,
                hasOtherDamages: null,
                otherDamages: null,
                frenchWindowHeight: null,
                frenchWindowWidth: null,
            };
        case RobberyBreakableElement.SlidingBayWindow:
            return {
                type,
                slidingBayLeavesType: null,
                damageSeverity: null,
                numberOfImpacts: null,
                slidingBayHasBrokenTiles: null,
                slidingBayNumberOfBrokenTiles: 1,
                slidingBayBiggestBrokenTileHeight: null,
                slidingBayBiggestBrokenTileWidth: null,
                slidingBayGlazingType: null,
                slidingBayGlassType: null,
                slidingBayLockIsDamaged: null,
                hasOtherDamages: null,
                otherDamages: null,
                slidingBayWidth: null,
            };
        default:
            return {
                type,
            };
    }
}
export function getBreakInDamageTitle(breakInDamage, translate, defaultTitle = EMPTY) {
    if (breakInDamage && breakInDamage.type) {
        if (breakInDamage.type === RobberyBreakableElement.Door && breakInDamage.doorType) {
            return translate(DOOR_TYPE_TRAD_KEYS_AND_IMAGES[breakInDamage.doorType].tradKey);
        }
        if (breakInDamage.type === RobberyBreakableElement.Other && breakInDamage.otherDamageType) {
            if (breakInDamage.otherDamageType === OtherDamageType.Other &&
                breakInDamage.otherDamageName) {
                return breakInDamage.otherDamageName;
            }
            return translate(OTHER_DAMAGE_TYPE_TRAD_KEYS_AND_IMAGES[breakInDamage.otherDamageType].tradKey);
        }
        return translate(ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS[breakInDamage.type]);
    }
    return defaultTitle;
}
export function getBreakInDamageDocumentsType(breakInDamage) {
    const hasInvoice = fileCollectionFilesCount(breakInDamage === null || breakInDamage === void 0 ? void 0 : breakInDamage.repairFileCollection) > 0;
    const hasQuote = fileCollectionFilesCount(breakInDamage === null || breakInDamage === void 0 ? void 0 : breakInDamage.pendingRepairFileCollection) > 0;
    if (hasInvoice && hasQuote) {
        return BreakInDamageDocumentsType.InvoiceAndQuote;
    }
    else if (hasInvoice) {
        return BreakInDamageDocumentsType.InvoiceOnly;
    }
    else if (hasQuote) {
        return BreakInDamageDocumentsType.QuoteOnly;
    }
    return BreakInDamageDocumentsType.None;
}
// Function to check if the BreakInDamage has no "real" damage declared (impacts, lock/glass damages, other damages)
export function breakInDamageHasNoDamageDeclared(breakInDamage) {
    return (breakInDamage.damageSeverity === DamageSeverity.Notable &&
        breakInDamage.numberOfImpacts === ImpactsNumber.NoImpact &&
        !breakInDamage.hasOtherDamages &&
        ((breakInDamage.type === RobberyBreakableElement.Door &&
            !breakInDamage.doorHasLockDamage &&
            !breakInDamage.doorHasBrokenWindowTiles) ||
            (breakInDamage.type === RobberyBreakableElement.Window &&
                !breakInDamage.windowHasBrokenTiles) ||
            (breakInDamage.type === RobberyBreakableElement.Shutter && breakInDamage.shutterIsOpening) ||
            (breakInDamage.type === RobberyBreakableElement.GarageDoor &&
                breakInDamage.garageDoorIsFunctional &&
                !breakInDamage.garageDoorIsBent) ||
            (breakInDamage.type === RobberyBreakableElement.FrenchWindow &&
                !breakInDamage.frenchWindowHasBrokenTiles &&
                !breakInDamage.frenchWindowLockIsDamaged) ||
            (breakInDamage.type === RobberyBreakableElement.SlidingBayWindow &&
                !breakInDamage.slidingBayHasBrokenTiles &&
                !breakInDamage.slidingBayLockIsDamaged) ||
            (breakInDamage.type === RobberyBreakableElement.Gate &&
                !breakInDamage.gateLockIsDamaged &&
                breakInDamage.gateIsFunctional &&
                !breakInDamage.gateIsBent) ||
            (breakInDamage.type === RobberyBreakableElement.LittleGate &&
                !breakInDamage.littleGateLockIsDamaged)));
}
