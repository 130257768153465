import moment from 'moment';
import { EMPTY } from '@shared/utils/strings';
export var dateFormat;
(function (dateFormat) {
    dateFormat["Month"] = "month";
    dateFormat["Day"] = "day";
})(dateFormat || (dateFormat = {}));
export function formatDuration(duration, translate, dateScale) {
    return dateScale === dateFormat.Month
        ? `${duration} ${translate('age.month', duration)}`
        : `${duration} ${translate('age.day', duration)}`;
}
export function formatOptionalAgeInMonths(months, translate, defaultString = EMPTY) {
    return typeof months !== 'number'
        ? defaultString
        : formatDuration(months, translate, dateFormat.Month);
}
export function formatAgeInYears(years, translate) {
    return `${years} ${translate('age.year', years)}`;
}
export function formatOptionalAgeInYears(years, translate, defaultString = EMPTY) {
    return years === undefined || years === null ? defaultString : formatAgeInYears(years, translate);
}
export function formatAge(age, translate, isYear = false) {
    const ageInMonths = Math.floor(isYear ? age * 12 : age);
    const years = Math.floor(ageInMonths / 12);
    const months = ageInMonths % 12;
    const result = [];
    if (years > 0)
        result.push(formatAgeInYears(years, translate));
    if (months > 0)
        result.push(formatDuration(months, translate, dateFormat.Month));
    return result.join(` ${translate('and')} `);
}
export function formatOptionalAge(age, translate, isYear = false, defaultString = EMPTY) {
    return age === undefined || age === null || age === 0
        ? defaultString
        : formatAge(age, translate, isYear);
}
export function formatYearRanges(purchaseDate, damageDate, translate) {
    if (purchaseDate.toString() === damageDate.toString()) {
        return `${translate('model.electrical_damage.age.less_than_a_year')}`;
    }
    else {
        const startMoment = moment(purchaseDate);
        const endMoment = moment(damageDate);
        const diff = endMoment.diff(startMoment, 'months', true); // true pour inclure des fractions de mois
        const years = Math.floor(diff / 12);
        const months = Math.floor(diff % 12);
        const days = endMoment.diff(startMoment.add(years, 'years').add(months, 'months'), 'days');
        if (years < 1 || (years === 1 && months === 0 && days === 0)) {
            return translate('model.electrical_damage.age.less_than_a_year');
        }
        else if (years > 20 || (years === 20 && (months !== 0 || days !== 0))) {
            return `${translate('model.electrical_damage.age.more_than_twenty_years')}`;
        }
        else if (days === 0 && months === 0) {
            return `${translate('between')} ${years - 1} ${translate('and')} ${years} ${translate('age.year', 2)} (${translate('included')})`;
        }
        else {
            return `${translate('between')} ${years} ${translate('and')} ${years + 1} ${translate('age.year', 2)} (${translate('included')})`;
        }
    }
}
export function formatOptionalYearRanges(purchaseDate, damageDate, translate, defaultString = EMPTY) {
    return purchaseDate === undefined ||
        purchaseDate === null ||
        damageDate === undefined ||
        damageDate === null
        ? defaultString
        : formatYearRanges(purchaseDate, damageDate, translate);
}
