const surfaceDamageCoveringConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.surface_damage.covering.validator.presence',
    },
};
const surfaceDamageAgeConstraint = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.surface_damage.state.validator.presence',
    },
};
/**
 * Creates age and coveringCategory foreach surface damage grouped by surface type
 * or indexed globally
 *
 *  'surfaceDamage.wall.1.age'
 *  and
 *  'surfaceDamage.1.age'
 */
export function createSurfaceDamageConstraints(surfaceDamages) {
    if (!surfaceDamages)
        return {};
    const constraints = {};
    Array.from(surfaceDamages).forEach((_, index) => {
        constraints[`surfaceDamages.${index}.coveringCategory`] = surfaceDamageCoveringConstraints;
        constraints[`surfaceDamages.${index}.age`] = surfaceDamageAgeConstraint;
    });
    return constraints;
}
