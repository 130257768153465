// Used for loading data in UI so layout does not collapse on empty string/content
export const LOADING = ' ';
// Used to reflect an empty/null value in UI
export const EMPTY = '-';
const afterLastSlashRegex = /[^/]+$/;
export function stringAfterLastSlash(input) {
    return input.match(afterLastSlashRegex)[0];
}
export function capitalize(value) {
    if (typeof value !== 'string') {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}
export function minimalize(value) {
    if (typeof value !== 'string') {
        return value;
    }
    return value.charAt(0).toLowerCase() + value.slice(1);
}
