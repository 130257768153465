import { TileDisplacementCount } from '@shared/types/api/externalDamage';
export const TILE_DISPLACEMENT_COUNT_TRAD_KEYS = {
    [TileDisplacementCount.Ten]: {
        tradKey: 'claim.declaration.external_damages.form.tile_displacement.count.ten',
        sumupTradKey: 'claim.declaration.external_damages.form.tile_displacement.count.ten.sumup',
    },
    [TileDisplacementCount.FiftyInRoofPart]: {
        tradKey: 'claim.declaration.external_damages.form.tile_displacement.count.fifty_part',
        sumupTradKey: 'claim.declaration.external_damages.form.tile_displacement.count.fifty_part.sumup',
    },
    [TileDisplacementCount.FiftyInWholeRoof]: {
        tradKey: 'claim.declaration.external_damages.form.tile_displacement.count.fifty_whole',
        sumupTradKey: 'claim.declaration.external_damages.form.tile_displacement.count.fifty_whole.sumup',
    },
};
