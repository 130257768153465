import { hasErrors, isValid, isTouched, validate, revealViolations, touch, untouch, } from '@shared/validator/validator';
export class ValidationContext {
    constructor(validator) {
        this.validator = validator;
    }
    get isTouched() {
        if (this.validator === null) {
            return false;
        }
        return isTouched(this.validator);
    }
    get isValid() {
        if (this.validator === null) {
            return true;
        }
        return isValid(this.validator);
    }
    get hasErrors() {
        if (this.validator === null) {
            return false;
        }
        return hasErrors(this.validator);
    }
    fieldHasViolations(fieldName) {
        if (this.validator === null) {
            return false;
        }
        const field = this.validator.fields[fieldName];
        if (!field) {
            return false;
        }
        return field.violations.length > 0;
    }
    fieldIsValid(fieldName) {
        if (this.validator === null) {
            return true;
        }
        const field = this.validator.fields[fieldName];
        if (!field) {
            return true;
        }
        return field.errors.length === 0;
    }
    fieldErrors(fieldName, vue) {
        if (this.validator === null) {
            return [];
        }
        const field = this.validator.fields[fieldName];
        if (!field) {
            return [];
        }
        return field.errors.map(({ message, value, options }) => {
            return vue.$tc(message, 0, { ...options, value });
        });
    }
    touch(fieldName) {
        if (!this.validator) {
            return;
        }
        touch(this.validator, fieldName);
    }
    untouch(fieldName) {
        if (!this.validator) {
            return;
        }
        untouch(this.validator, fieldName);
    }
    validate(object, constraints) {
        if (!this.validator) {
            return;
        }
        validate(this.validator, object, constraints);
    }
    revealViolations() {
        if (!this.validator) {
            return;
        }
        revealViolations(this.validator);
    }
}
