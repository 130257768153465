import { UserRole } from '@shared/types/api/user';
const USER_ROLE_HIERARCHY = {
    [UserRole.ADMIN]: UserRole.VALUATION_OPTIONS,
    [UserRole.VALUATION_OPTIONS]: UserRole.MANAGER,
    [UserRole.MANAGER]: [
        UserRole.MANAGER_READONLY,
        UserRole.BACKOFFICE_DAMAGES_VALUATION,
        UserRole.BACKOFFICE_HOTLINE,
        UserRole.BACKOFFICE_TOOL_PRODUCTS,
    ],
    [UserRole.MANAGER_READONLY]: UserRole.BACKOFFICE,
    [UserRole.BACKOFFICE_DAMAGES_VALUATION]: UserRole.BACKOFFICE,
    [UserRole.BACKOFFICE_HOTLINE]: UserRole.BACKOFFICE,
    [UserRole.BACKOFFICE_TOOL_PRODUCTS]: UserRole.BACKOFFICE,
};
export function validateRole(roles, role) {
    return roles.some((r) => {
        var _a;
        if (r === role)
            return true;
        const children = (_a = USER_ROLE_HIERARCHY[r]) !== null && _a !== void 0 ? _a : false;
        return children && validateRole([children].flat(), role);
    });
}
export const DEFAULT_PAGES_SECURITY = {
    utilisateurs: UserRole.ADMIN,
    'utilisateurs-nouveau': UserRole.ADMIN,
    'utilisateurs-id': UserRole.ADMIN,
    'utilisateurs-id-nouveau-contrat': UserRole.ADMIN,
    'services-produits': UserRole.BACKOFFICE_TOOL_PRODUCTS,
    'rendez-vous': UserRole.MANAGER,
    document: UserRole.MANAGER,
    declarations: UserRole.MANAGER_READONLY,
    'declarations-nouvelle': UserRole.MANAGER,
    'declarations-id': UserRole.MANAGER_READONLY,
    'declarations-id-appel': UserRole.MANAGER,
    'declarations-id-frais-de-gestion': UserRole.MANAGER,
    'declarations-id-contrat': UserRole.MANAGER_READONLY,
    'declarations-id-declaration': UserRole.MANAGER_READONLY,
    'declarations-id-dommages': UserRole.MANAGER_READONLY,
    'declarations-id-ged': UserRole.MANAGER,
    'declarations-id-indemnisation': UserRole.MANAGER_READONLY,
    'declarations-id-provision': UserRole.MANAGER,
    'declarations-id-ren': UserRole.MANAGER,
    'declarations-id-synthese': UserRole.MANAGER_READONLY,
    'declarations-id-priming': UserRole.MANAGER_READONLY,
    contrats: UserRole.MANAGER,
    'configuration-menu': UserRole.ADMIN,
    'configuration-templates': UserRole.ADMIN,
    'configuration-templates-id': UserRole.ADMIN,
    chiffrages: UserRole.BACKOFFICE_DAMAGES_VALUATION,
    'chiffrages-valuation': UserRole.BACKOFFICE_DAMAGES_VALUATION,
    'chiffrages-valuation-damage-key': UserRole.BACKOFFICE_DAMAGES_VALUATION,
};
