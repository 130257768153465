import { SLIDING_BAY_GLAZING_TYPE_MODEL_LABELS, SLIDING_BAY_GLAZING_TYPE_MODEL_CHOICES, SLIDING_BAY_GLASS_TYPE_MODEL_LABELS, SLIDING_BAY_GLASS_TYPE_MODEL_CHOICES, SLIDING_BAY_LEAF_WIDTH_MODEL_LABELS, SLIDING_BAY_LEAF_WIDTH_MODEL_CHOICES, GOOD_DAMAGE_TYPE_LABELS, } from '../goodDamage';
import { numbersToChoices, translateChoices } from '@shared/choice';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalString, optionalBooleanTransform, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
export function slidingBayDetails(model, translate) {
    if (!model.type || !model.leafCount)
        return null;
    const key = `${GOOD_DAMAGE_TYPE_LABELS[model.type]}.description`;
    return translate(key, model.leafCount, { count: model.leafCount });
}
export function slidingBayFields(model, context) {
    const { translate, index } = context;
    const fields = [
        {
            label: translate('model.good_damage.sliding_bay.leaf_count'),
            value: model.leafCount,
            displayedValue: optionalString(model.leafCount),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    nullable: true,
                    choices: numbersToChoices(2, 3),
                },
                modelKey: `goodDamages.${index}.leafCount`,
                toPayload: (leafCount) => ({ leafCount }),
            },
        },
        {
            label: translate('model.good_damage.sliding_bay.is_glass_broken'),
            value: model.isGlassBroken,
            displayedValue: optionalBooleanTransform(model.isGlassBroken, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    nullable: true,
                    choices: translateChoices(YES_OR_NO_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.isGlassBroken`,
                toPayload: (isGlassBroken) => ({
                    isGlassBroken,
                    glazingType: isGlassBroken ? model.glazingType : null,
                    glassType: isGlassBroken ? model.glassType : null,
                    leafWidth: isGlassBroken ? model.leafWidth : null,
                }),
            },
        },
    ];
    if (model.isGlassBroken) {
        fields.push({
            label: translate('model.good_damage.sliding_bay.glazing_type'),
            value: model.glazingType,
            displayedValue: optionalTranslatableEnumTransform(model.glazingType, SLIDING_BAY_GLAZING_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(SLIDING_BAY_GLAZING_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.glazingType`,
                toPayload: (glazingType) => ({ glazingType }),
            },
        }, {
            label: translate('model.good_damage.sliding_bay.glass_type'),
            value: model.glassType,
            displayedValue: optionalTranslatableEnumTransform(model.glassType, SLIDING_BAY_GLASS_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(SLIDING_BAY_GLASS_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.glassType`,
                toPayload: (glassType) => ({ glassType }),
            },
        }, {
            label: translate('model.good_damage.sliding_bay.leaf_width'),
            value: model.leafWidth,
            displayedValue: optionalTranslatableEnumTransform(model.leafWidth, SLIDING_BAY_LEAF_WIDTH_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(SLIDING_BAY_LEAF_WIDTH_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.leafWidth`,
                toPayload: (leafWidth) => ({ leafWidth }),
            },
        });
    }
    return fields;
}
