import { objectToTranslatableChoices } from '@shared/choice';
import { QualificationRoomType } from '@shared/types/api/claim';
import { HousingTypeSlug } from '@shared/types/api/contract';
export var HOUSING_TYPE_WITH_SURFACE;
(function (HOUSING_TYPE_WITH_SURFACE) {
    HOUSING_TYPE_WITH_SURFACE["house"] = "house";
    HOUSING_TYPE_WITH_SURFACE["appartment"] = "appartment";
})(HOUSING_TYPE_WITH_SURFACE || (HOUSING_TYPE_WITH_SURFACE = {}));
export const CONTRACT_HOUSING_TYPE_WITH_SURFACE = {
    [HousingTypeSlug.Apartment]: HOUSING_TYPE_WITH_SURFACE.appartment,
    [HousingTypeSlug.House]: HOUSING_TYPE_WITH_SURFACE.house,
    [HousingTypeSlug.Block]: HOUSING_TYPE_WITH_SURFACE.appartment,
    [HousingTypeSlug.IndustrialLocal]: HOUSING_TYPE_WITH_SURFACE.appartment,
    [HousingTypeSlug.ProfessionalLocal]: HOUSING_TYPE_WITH_SURFACE.house,
    [HousingTypeSlug.Garage]: HOUSING_TYPE_WITH_SURFACE.appartment,
    [HousingTypeSlug.CommercialLocal]: HOUSING_TYPE_WITH_SURFACE.appartment,
    [HousingTypeSlug.Parking]: HOUSING_TYPE_WITH_SURFACE.house,
    [HousingTypeSlug.Other]: HOUSING_TYPE_WITH_SURFACE.house,
    [HousingTypeSlug.School]: HOUSING_TYPE_WITH_SURFACE.house,
    [HousingTypeSlug.MunicipalPool]: HOUSING_TYPE_WITH_SURFACE.house,
    [HousingTypeSlug.MedicalOffice]: HOUSING_TYPE_WITH_SURFACE.appartment,
    [HousingTypeSlug.Hotel]: HOUSING_TYPE_WITH_SURFACE.house,
    [HousingTypeSlug.MobileResidence]: HOUSING_TYPE_WITH_SURFACE.appartment,
    [HousingTypeSlug.Farm]: HOUSING_TYPE_WITH_SURFACE.house,
};
export const ROOM_MEAN_SURFACE = {
    [QualificationRoomType.Kitchen]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 5,
        [HOUSING_TYPE_WITH_SURFACE.house]: 8,
    },
    [QualificationRoomType.Lounge]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 18,
        [HOUSING_TYPE_WITH_SURFACE.house]: 25,
    },
    [QualificationRoomType.Bathroom]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 3,
        [HOUSING_TYPE_WITH_SURFACE.house]: 7,
    },
    [QualificationRoomType.WC]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 1,
        [HOUSING_TYPE_WITH_SURFACE.house]: 2,
    },
    [QualificationRoomType.BedroomOffice]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 9,
        [HOUSING_TYPE_WITH_SURFACE.house]: 12,
    },
    [QualificationRoomType.Balcony]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 5,
        [HOUSING_TYPE_WITH_SURFACE.house]: 8,
    },
    [QualificationRoomType.Basement]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 4,
        [HOUSING_TYPE_WITH_SURFACE.house]: 10,
    },
    [QualificationRoomType.Garage]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 12,
        [HOUSING_TYPE_WITH_SURFACE.house]: 20,
    },
    [QualificationRoomType.Laundry]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 4,
        [HOUSING_TYPE_WITH_SURFACE.house]: 6,
    },
    [QualificationRoomType.CorridorEntrance]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 6,
        [HOUSING_TYPE_WITH_SURFACE.house]: 10,
    },
    [QualificationRoomType.Outbuilding]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 8,
        [HOUSING_TYPE_WITH_SURFACE.house]: 8,
    },
    [QualificationRoomType.Other]: {
        [HOUSING_TYPE_WITH_SURFACE.appartment]: 20,
        [HOUSING_TYPE_WITH_SURFACE.house]: 20,
    },
};
export var RoomSize;
(function (RoomSize) {
    RoomSize["Small"] = "small";
    RoomSize["Mean"] = "mean";
    RoomSize["Big"] = "big";
})(RoomSize || (RoomSize = {}));
export const SURFACE_DAMAGE_VETUSTY_TRAD_KEYS = {
    5: 'claim.damage.real_estate.room_damage.form.question.surface_damage.state.new',
    15: 'claim.damage.real_estate.room_damage.form.question.surface_damage.state.used',
    25: 'claim.damage.real_estate.room_damage.form.question.surface_damage.state.old',
};
export const SURFACE_DAMAGE_VETUSTY_CHOICES = objectToTranslatableChoices(SURFACE_DAMAGE_VETUSTY_TRAD_KEYS);
