import { ModelFieldInputPreset, ModelFieldInputType, } from '@shared/modelField/modelField';
import { translatableObjectToChoices } from '@shared/choice';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
import { DEFAULT_FENCE_TYPE_TRAD_KEYS } from '@shared/constants/externalDamages';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <Fence>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function fenceBreakInDamageEditionFields(model, translate) {
    return [
        {
            label: translate('model.break_in_damage.fence_type'),
            key: 'fenceType',
            value: model.fenceType,
            displayedValue: optionalTranslatableEnumTransform(model.fenceType, DEFAULT_FENCE_TYPE_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(DEFAULT_FENCE_TYPE_TRAD_KEYS, translate),
                },
                modelKey: 'fenceType',
            },
        },
        {
            label: translate('model.break_in_damage.fence_length'),
            key: 'fenceLength',
            value: model.fenceLength,
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                modelKey: 'fenceLength',
            },
        },
        {
            label: translate('model.break_in_damage.fence.is_half_height'),
            key: 'isFenceHalfHeight',
            value: model.isFenceHalfHeight,
            displayedValue: translate(model.isFenceHalfHeight ? 'yes' : 'no'),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isFenceHalfHeight',
            },
        },
    ];
}
