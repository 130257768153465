import { cacheFile } from '@shared/files/fetchFile';
import { ApiResourceName } from '@shared/types/api/api';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { uuidFromIri } from '@shared/utils/iri';
export async function uploadFile(file, claimIri, type, $axios) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('claim', uuidFromIri(claimIri));
    const uploaded = await $axios.$post(apiResourcePath(ApiResourceName.Files), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    cacheFile(uploaded);
    return uploaded;
}
export function uploadFiles(files, claimIri, type, $axios) {
    return Promise.all(files.map((file) => uploadFile(file, claimIri, type, $axios)));
}
