import { imageObjectToChoices } from '@shared/choice';
import { GARAGE_DOOR_TYPE_IMAGES_AND_TRAD_KEYS } from '@shared/constants/externalDamages';
import { ModelFieldInputPreset, ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalBooleanTransform, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
export const garageDoorFields = (model, { translate }) => {
    return [
        {
            label: translate('model.external_damage.door_type'),
            value: model.doorType,
            displayedValue: optionalTranslatableEnumTransform(model.doorType, GARAGE_DOOR_TYPE_IMAGES_AND_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: imageObjectToChoices(GARAGE_DOOR_TYPE_IMAGES_AND_TRAD_KEYS, translate),
                },
                modelKey: 'doorType',
            },
        },
        {
            label: translate('model.external_damage.width_greater_than_standard'),
            value: model.isWidthGreaterThanStandard,
            displayedValue: optionalBooleanTransform(model.isWidthGreaterThanStandard, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isWidthGreaterThanStandard',
            },
        },
        {
            label: translate('model.external_damage.is_opening_motorized'),
            value: model.isOpeningMotorized,
            displayedValue: optionalBooleanTransform(model.isOpeningMotorized, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isOpeningMotorized',
            },
        },
    ];
};
