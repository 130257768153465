import { ProofType } from '@shared/types/api/refundDetail';
export const PROOF_TYPES_LABELS = {
    [ProofType.ExistenceAndValue]: 'Valeur et existence',
    [ProofType.Existence]: 'Existence',
    [ProofType.Empty]: 'Non justifié',
    [ProofType.NotApplicable]: 'Non applicable',
};
export const PROOF_TYPES_LABELS_ABREVIATIONS = {
    [ProofType.ExistenceAndValue]: 'JEV',
    [ProofType.Existence]: 'JE',
    [ProofType.Empty]: 'NJ',
    [ProofType.NotApplicable]: 'NA',
};
