import { translatableObjectToChoices } from '@shared/choice';
import { TILE_DISPLACEMENT_COUNT_TRAD_KEYS } from '@shared/constants/externalDamages';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export const tileDisplacementFields = (model, { translate }) => {
    return [
        {
            label: translate('model.external_damage.tile_count'),
            value: model.tileCount,
            displayedValue: optionalTranslatableEnumTransform(model.tileCount, TILE_DISPLACEMENT_COUNT_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(TILE_DISPLACEMENT_COUNT_TRAD_KEYS, translate),
                    vertical: true,
                },
                modelKey: 'tileCount',
            },
        },
    ];
};
