import { objectToTranslatableChoices, } from '@shared/choice';
import { PIPE_TYPE_LABELS } from '@shared/roomDamage/goodDamage';
import { ExternalDamageOrigin } from '@shared/types/api/externalDamage';
export const DEFAULT_PIPE_TYPE_TRAD_KEYS = PIPE_TYPE_LABELS;
const STORM_PIPE_TYPE_TRAD_KEYS = {};
const SNOW_PIPE_TYPE_TRAD_KEYS = {};
const HAIL_PIPE_TYPE_TRAD_KEYS = {};
const FIRE_PIPE_TYPE_TRAD_KEYS = {};
export const PIPE_TYPE_TRAD_KEYS = {
    [ExternalDamageOrigin.Storm]: {
        ...DEFAULT_PIPE_TYPE_TRAD_KEYS,
        ...STORM_PIPE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Snow]: {
        ...DEFAULT_PIPE_TYPE_TRAD_KEYS,
        ...SNOW_PIPE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Hail]: {
        ...DEFAULT_PIPE_TYPE_TRAD_KEYS,
        ...HAIL_PIPE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Fire]: {
        ...DEFAULT_PIPE_TYPE_TRAD_KEYS,
        ...FIRE_PIPE_TYPE_TRAD_KEYS,
    },
};
export const PIPE_TYPE_CHOICES = {
    [ExternalDamageOrigin.Storm]: objectToTranslatableChoices(PIPE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Storm]),
    [ExternalDamageOrigin.Snow]: objectToTranslatableChoices(PIPE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Snow]),
    [ExternalDamageOrigin.Hail]: objectToTranslatableChoices(PIPE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Hail]),
    [ExternalDamageOrigin.Fire]: objectToTranslatableChoices(PIPE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Fire]),
};
