import { translateChoices } from '@shared/choice';
import { BASIN_MATERIAL_CHOICES, BASIN_MATERIAL_TRAD_KEYS, } from '@shared/constants/externalDamages/BasinConstants';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export const basinFields = (model, { translate, stormSnowHailDamageCauseType }) => {
    const origin = stormSnowHailDamageCauseType !== null && stormSnowHailDamageCauseType !== void 0 ? stormSnowHailDamageCauseType : ClaimTypeTrigram.Fire;
    return [
        {
            label: translate('model.external_damage.basin.material'),
            value: model.material,
            displayedValue: optionalTranslatableEnumTransform(model.material, BASIN_MATERIAL_TRAD_KEYS[origin], translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(BASIN_MATERIAL_CHOICES[origin], translate),
                },
                modelKey: 'material',
            },
        },
    ];
};
