import { objectToTranslatableChoices, objectToTranslatableImageChoices } from '@shared/choice';
import { GATE_HEIGHT_TRAD_KEYS, GATE_MATERIAL_TRAD_KEYS, GATE_WIDTH_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { GateFillStyle, GateType } from '@shared/types/api/externalDamage';
export const GATE_TYPE_TITLE = 'claim.declaration.external_damages.form.gate.type.title';
export const GATE_TYPE_SUBTITLE = 'claim.declaration.external_damages.form.gate.type.subtitle';
export const GATE_PANEL_FILL_STYLE_TITLE = 'claim.declaration.external_damages.form.gate.panel_fill_style.title';
export const GATE_PANEL_FILL_STYLE_SUBTITLE = 'claim.declaration.external_damages.form.gate.panel_fill_style.subtitle';
export const GATE_MATERIAL_TITLE = 'claim.declaration.external_damages.form.gate.material.title';
export const GATE_MATERIAL_PLACEHOLDER = 'claim.declaration.external_damages.form.gate.material.placeholder';
export const GATE_OPENING_TITLE = 'claim.declaration.external_damages.form.gate.opening.title';
export const GATE_HEIGHT_TITLE = 'claim.declaration.external_damages.form.gate.height.title';
export const GATE_HEIGHT_PLACEHOLDER = 'claim.declaration.external_damages.form.gate.height.placeholder';
export const GATE_WIDTH_TITLE = 'claim.declaration.external_damages.form.gate.width.title';
export const GATE_WIDTH_PLACEHOLDER = 'claim.declaration.external_damages.form.gate.width.placeholder';
export const GATE_TYPE_IMAGES_AND_TRAD_KEYS = {
    [GateType.Sliding]: {
        tradKey: 'claim.declaration.external_damages.form.gate.type.coulissante',
        image: require('@declaration/assets/images/externalDamage/gate/coulissante.svg'),
    },
    [GateType.Flappy]: {
        tradKey: 'claim.declaration.external_damages.form.gate.type.battante',
        image: require('@declaration/assets/images/externalDamage/gate/battante.svg'),
    },
};
export const GATE_FILL_STYLE_IMAGE_AND_TRAD_KEYS = {
    [GateFillStyle.Plein]: {
        tradKey: 'claim.declaration.external_damages.form.gate.panel_fill_style.plein',
        image: require('@declaration/assets/images/externalDamage/gate/plein.svg'),
    },
    [GateFillStyle.Ajoure]: {
        tradKey: 'claim.declaration.external_damages.form.gate.panel_fill_style.ajoure',
        image: require('@declaration/assets/images/externalDamage/gate/ajoure.svg'),
    },
    [GateFillStyle.SemiAjoure]: {
        tradKey: 'claim.declaration.external_damages.form.gate.panel_fill_style.semi_ajoure',
        image: require('@declaration/assets/images/externalDamage/gate/semi-ajoure.svg'),
    },
};
export const GATE_TYPE_CHOICES = objectToTranslatableImageChoices(GATE_TYPE_IMAGES_AND_TRAD_KEYS);
export const GATE_FILL_STYLE_CHOICES = objectToTranslatableImageChoices(GATE_FILL_STYLE_IMAGE_AND_TRAD_KEYS);
export const GATE_MATERIAL_CHOICES = objectToTranslatableChoices(GATE_MATERIAL_TRAD_KEYS);
export const GATE_HEIGHT_CHOICES = objectToTranslatableChoices(GATE_HEIGHT_TRAD_KEYS);
export const GATE_WIDTH_CHOICES = objectToTranslatableChoices(GATE_WIDTH_TRAD_KEYS);
