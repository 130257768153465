export function bodyHeight100Percent() {
    const setBodyHeightTo100 = () => {
        var _a;
        const innerHeight = (_a = document.documentElement.clientHeight) !== null && _a !== void 0 ? _a : window.innerHeight;
        document.body.style.height = `${innerHeight}px`;
    };
    window.addEventListener('resize', setBodyHeightTo100);
    setBodyHeightTo100();
    return () => {
        window.removeEventListener('resize', setBodyHeightTo100);
    };
}
