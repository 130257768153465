import { ImpactsNumber, Material, Measurement } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
import { GarageDoorType } from '@shared/types/api/externalDamage';
export const GARAGE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES = {
    [GarageDoorType.Tipper]: {
        tradKey: 'claim.declaration.break_in_damages.form.garage-door.type.tipper',
        sumupTradKey: 'claim.declaration.break_in_damages.form.garage-door.type.tipper.sumup',
        image: require('@declaration/assets/images/breakInDamage/garageDoor/type/tipper.svg'),
    },
    [GarageDoorType.Sectional]: {
        tradKey: 'claim.declaration.break_in_damages.form.garage-door.type.sectional',
        sumupTradKey: 'claim.declaration.break_in_damages.form.garage-door.type.sectional.sumup',
        image: require('@declaration/assets/images/breakInDamage/garageDoor/type/sectional.svg'),
    },
    [GarageDoorType.Sliding]: {
        tradKey: 'claim.declaration.break_in_damages.form.garage-door.type.sliding',
        sumupTradKey: 'claim.declaration.break_in_damages.form.garage-door.type.sliding.sumup',
        image: require('@declaration/assets/images/breakInDamage/garageDoor/type/sliding.svg'),
    },
    [GarageDoorType.Rolling]: {
        tradKey: 'claim.declaration.break_in_damages.form.garage-door.type.rolling',
        sumupTradKey: 'claim.declaration.break_in_damages.form.garage-door.type.rolling.sumup',
        image: require('@declaration/assets/images/breakInDamage/garageDoor/type/rolling.svg'),
    },
    [GarageDoorType.Flappy]: {
        tradKey: 'claim.declaration.break_in_damages.form.garage-door.type.flappy',
        sumupTradKey: 'claim.declaration.break_in_damages.form.garage-door.type.flappy.sumup',
        image: require('@declaration/assets/images/breakInDamage/garageDoor/type/flappy.svg'),
    },
    [GarageDoorType.Folding]: {
        tradKey: 'claim.declaration.break_in_damages.form.garage-door.type.folding',
        sumupTradKey: 'claim.declaration.break_in_damages.form.garage-door.type.folding.sumup',
        image: require('@declaration/assets/images/breakInDamage/garageDoor/type/folding.svg'),
    },
};
export const GARAGE_DOOR_MATERIAL_CHOICES = [
    {
        value: Material.Wood,
        tradKey: 'claim.declaration.break_in_damages.form.material.wood',
    },
    {
        value: Material.PVC,
        tradKey: 'claim.declaration.break_in_damages.form.material.pvc',
    },
    {
        value: Material.Metal,
        tradKey: 'claim.declaration.break_in_damages.form.material.metal',
    },
];
export const WOODEN_GARAGE_DOOR_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.AtLeastOne,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
    },
];
export const METAL_GARAGE_DOOR_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.MoreThanThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    },
];
export const PVC_GARAGE_DOOR_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.FourToSix,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.four-to-six',
    },
    {
        value: ImpactsNumber.MoreThanSeven,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-seven',
    },
];
export const GARAGE_DOOR_WIDTH_CHOICES = [
    {
        value: Measurement.LessThan300cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-300cm',
    },
    {
        value: Measurement.MoreThan300cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-300cm',
    },
];
export const REPAIR_STATUS_CHOICES_GARAGE_DOOR = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.garage-door',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.garage-door',
    },
];
