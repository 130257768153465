import { ClaimTypeTrigram, StormSnowHailDamageCauseType } from '@shared/types/api/claim';
import { ExternalDamageLocalizationSlug, ExternalDamageSlug, ExternalDamageOrigin, } from '@shared/types/api/externalDamage';
export const DEFAULT_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS = {
    [ExternalDamageSlug.GardenShelter]: {
        tradKey: 'claim.declaration.external_damages.form.slug.garden_shelter',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.garden_shelter.sumup',
    },
    [ExternalDamageSlug.SwimpoolCover]: {
        tradKey: 'claim.declaration.external_damages.form.slug.swimpool_cover',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.swimpool_cover.sumup',
    },
    [ExternalDamageSlug.Plants]: {
        tradKey: 'claim.declaration.external_damages.form.slug.plants',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.plants.sumup',
    },
    [ExternalDamageSlug.GarageDoor]: {
        tradKey: 'claim.declaration.external_damages.form.slug.garage_door',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.garage_door.sumup',
    },
    [ExternalDamageSlug.TerraceFloor]: {
        tradKey: 'claim.declaration.external_damages.form.slug.terrace_floor',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.terrace_floor.sumup',
    },
    [ExternalDamageSlug.Pergola]: {
        tradKey: 'claim.declaration.external_damages.form.slug.pergola',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.pergola.sumup',
    },
    [ExternalDamageSlug.Awning]: {
        tradKey: 'claim.declaration.external_damages.form.slug.awning',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.awning.sumup',
    },
    [ExternalDamageSlug.Veranda]: {
        tradKey: 'claim.declaration.external_damages.form.slug.veranda',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.veranda.sumup',
    },
    [ExternalDamageSlug.RoofHole]: {
        tradKey: 'claim.declaration.external_damages.form.slug.roof_hole',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.roof_hole.sumup',
    },
    [ExternalDamageSlug.TileDisplacement]: {
        tradKey: 'claim.declaration.external_damages.form.slug.tile_displacement',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.tile_displacement.sumup',
    },
    [ExternalDamageSlug.Framework]: {
        tradKey: 'claim.declaration.external_damages.form.slug.framework',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.framework.sumup',
    },
    [ExternalDamageSlug.Antenna]: {
        tradKey: 'claim.declaration.external_damages.form.slug.antenna',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.antenna.sumup',
    },
    [ExternalDamageSlug.Chimney]: {
        tradKey: 'claim.declaration.external_damages.form.slug.chimney',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.chimney.sumup',
    },
    [ExternalDamageSlug.Gutter]: {
        tradKey: 'claim.declaration.external_damages.form.slug.gutter',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.gutter.sumup',
    },
    [ExternalDamageSlug.Shutters]: {
        tradKey: 'claim.declaration.external_damages.form.slug.shutters',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.shutters.sumup',
    },
    [ExternalDamageSlug.Window]: {
        tradKey: 'claim.declaration.external_damages.form.slug.window',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.window.sumup',
    },
    [ExternalDamageSlug.FrontWall]: {
        tradKey: 'claim.declaration.external_damages.form.slug.front_wall',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.front_wall.sumup',
    },
    [ExternalDamageSlug.ExternalPersonalProperty]: {
        tradKey: 'claim.declaration.external_damages.form.slug.external_personal_property',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.external_personal_property.sumup',
    },
    [ExternalDamageSlug.Fence]: {
        tradKey: 'claim.declaration.external_damages.form.slug.fence',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.fence.sumup',
    },
    [ExternalDamageSlug.Gate]: {
        tradKey: 'claim.declaration.external_damages.form.slug.gate',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.gate.sumup',
    },
    [ExternalDamageSlug.LittleGate]: {
        tradKey: 'claim.declaration.external_damages.form.slug.little_gate',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.little_gate.sumup',
    },
    [ExternalDamageSlug.StoneWork]: {
        tradKey: 'claim.declaration.external_damages.form.slug.stone_work',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.stone_work.sumup',
    },
    [ExternalDamageSlug.Roof]: {
        tradKey: 'claim.declaration.external_damages.form.slug.roof',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.roof.sumup',
    },
    [ExternalDamageSlug.Door]: {
        tradKey: 'claim.declaration.external_damages.form.slug.door',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.door.sumup',
    },
    [ExternalDamageSlug.AerationGrid]: {
        tradKey: 'claim.declaration.external_damages.form.slug.aeration_grid',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.aeration_grid.sumup',
    },
    [ExternalDamageSlug.Basin]: {
        tradKey: 'claim.declaration.external_damages.form.slug.basin',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.basin.sumup',
    },
    [ExternalDamageSlug.Faucet]: {
        tradKey: 'claim.declaration.external_damages.form.slug.faucet',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.faucet.sumup',
    },
    [ExternalDamageSlug.VisiblePipe]: {
        tradKey: 'claim.declaration.external_damages.form.slug.visible_pipe',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.visible_pipe.sumup',
    },
    [ExternalDamageSlug.LightSource]: {
        tradKey: 'claim.declaration.external_damages.form.slug.light_source',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.light_source.sumup',
    },
    [ExternalDamageSlug.OutletOrSwitch]: {
        tradKey: 'claim.declaration.external_damages.form.slug.outlet_or_switch',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.outlet_or_switch.sumup',
    },
    [ExternalDamageSlug.ElectricalSheath]: {
        tradKey: 'claim.declaration.external_damages.form.slug.electrical_sheath',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.electrical_sheath.sumup',
    },
    [ExternalDamageSlug.Barbecue]: {
        tradKey: 'claim.declaration.external_damages.form.slug.barbecue',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.barbecue.sumup',
    },
    [ExternalDamageSlug.Playground]: {
        tradKey: 'claim.declaration.external_damages.form.slug.playground',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.playground.sumup',
    },
    [ExternalDamageSlug.GardenLounge]: {
        tradKey: 'claim.declaration.external_damages.form.slug.garden_lounge',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.garden_lounge.sumup',
    },
    [ExternalDamageSlug.PizzaOven]: {
        tradKey: 'claim.declaration.external_damages.form.slug.pizza_oven',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.pizza_oven.sumup',
    },
    [ExternalDamageSlug.PhotovoltaicPanel]: {
        tradKey: 'claim.declaration.external_damages.form.slug.photovoltaic_panel',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.photovoltaic_panel.sumup',
    },
    [ExternalDamageSlug.Other]: {
        tradKey: 'claim.declaration.external_damages.form.slug.other',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.other.sumup',
    },
};
const STORM_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS = {};
const SNOW_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS = {
    [ExternalDamageSlug.Framework]: {
        tradKey: 'claim.declaration.external_damages.form.slug.framework.snow',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.framework.sumup.snow',
    },
};
const HAIL_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS = {
    [ExternalDamageSlug.TileDisplacement]: {
        tradKey: 'claim.declaration.external_damages.form.slug.tile_displacement.hail',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.tile_displacement.sumup.hail',
    },
};
const FIRE_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS = {
    [ExternalDamageSlug.Plants]: {
        tradKey: 'claim.declaration.external_damages.form.slug.plants.fire',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.plants.sumup.fire',
    },
    [ExternalDamageSlug.Awning]: {
        tradKey: 'claim.declaration.external_damages.form.slug.awning.fire',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.awning.sumup.fire',
    },
    [ExternalDamageSlug.TileDisplacement]: {
        tradKey: 'claim.declaration.external_damages.form.slug.tile_displacement.fire',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.tile_displacement.sumup.fire',
    },
    [ExternalDamageSlug.Chimney]: {
        tradKey: 'claim.declaration.external_damages.form.slug.chimney.fire',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.chimney.sumup.fire',
    },
    [ExternalDamageSlug.FrontWall]: {
        tradKey: 'claim.declaration.external_damages.form.slug.front_wall.fire',
        sumupTradKey: 'claim.declaration.external_damages.form.slug.front_wall.sumup.fire',
    },
};
export const EXTERNAL_DAMAGE_SLUG_TRAD_KEYS = {
    [ExternalDamageOrigin.Storm]: {
        ...DEFAULT_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS,
        ...STORM_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Snow]: {
        ...DEFAULT_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS,
        ...SNOW_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Hail]: {
        ...DEFAULT_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS,
        ...HAIL_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Fire]: {
        ...DEFAULT_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS,
        ...FIRE_EXTERNAL_DAMAGE_SLUG_TRAD_KEYS,
    },
};
export const EXTERNAL_DAMAGE_SLUGS = {
    [StormSnowHailDamageCauseType.Storm]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: [
            ExternalDamageSlug.GardenShelter,
            ExternalDamageSlug.SwimpoolCover,
            ExternalDamageSlug.Plants,
            ExternalDamageSlug.TerraceFloor,
            ExternalDamageSlug.Pergola,
            ExternalDamageSlug.Awning,
            ExternalDamageSlug.ExternalPersonalProperty,
        ],
        [ExternalDamageLocalizationSlug.Roof]: [
            ExternalDamageSlug.RoofHole,
            ExternalDamageSlug.TileDisplacement,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Antenna,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Chimney,
        ],
        [ExternalDamageLocalizationSlug.Shutters]: [ExternalDamageSlug.Shutters],
        [ExternalDamageLocalizationSlug.GarageDoor]: [ExternalDamageSlug.GarageDoor],
        [ExternalDamageLocalizationSlug.Veranda]: [ExternalDamageSlug.Veranda],
        [ExternalDamageLocalizationSlug.Chimney]: [ExternalDamageSlug.Chimney],
        [ExternalDamageLocalizationSlug.FenceAndGate]: [
            ExternalDamageSlug.Fence,
            ExternalDamageSlug.Gate,
            ExternalDamageSlug.LittleGate,
            ExternalDamageSlug.StoneWork,
        ],
        [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: [
            ExternalDamageSlug.ExternalPersonalProperty,
        ],
        [ExternalDamageLocalizationSlug.Outbuilding]: [
            ExternalDamageSlug.Roof,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Door,
            ExternalDamageSlug.Window,
            ExternalDamageSlug.Shutters,
        ],
        [ExternalDamageLocalizationSlug.Window]: [],
        [ExternalDamageLocalizationSlug.FrontWall]: [],
        [ExternalDamageLocalizationSlug.Electricity]: [],
        [ExternalDamageLocalizationSlug.Plumbing]: [],
        [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: [],
        [ExternalDamageLocalizationSlug.Door]: [],
        [ExternalDamageLocalizationSlug.Other]: [ExternalDamageSlug.Other],
    },
    [StormSnowHailDamageCauseType.Hail]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: [
            ExternalDamageSlug.GardenShelter,
            ExternalDamageSlug.SwimpoolCover,
            ExternalDamageSlug.Plants,
            ExternalDamageSlug.TerraceFloor,
            ExternalDamageSlug.Pergola,
            ExternalDamageSlug.Awning,
            ExternalDamageSlug.ExternalPersonalProperty,
        ],
        [ExternalDamageLocalizationSlug.Roof]: [
            ExternalDamageSlug.RoofHole,
            ExternalDamageSlug.TileDisplacement,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Antenna,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Chimney,
        ],
        [ExternalDamageLocalizationSlug.Shutters]: [ExternalDamageSlug.Shutters],
        [ExternalDamageLocalizationSlug.GarageDoor]: [ExternalDamageSlug.GarageDoor],
        [ExternalDamageLocalizationSlug.Veranda]: [ExternalDamageSlug.Veranda],
        [ExternalDamageLocalizationSlug.Chimney]: [],
        [ExternalDamageLocalizationSlug.FenceAndGate]: [
            ExternalDamageSlug.Fence,
            ExternalDamageSlug.Gate,
            ExternalDamageSlug.LittleGate,
            ExternalDamageSlug.StoneWork,
        ],
        [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: [
            ExternalDamageSlug.ExternalPersonalProperty,
        ],
        [ExternalDamageLocalizationSlug.Outbuilding]: [
            ExternalDamageSlug.Roof,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Door,
            ExternalDamageSlug.Window,
            ExternalDamageSlug.Shutters,
        ],
        [ExternalDamageLocalizationSlug.Window]: [ExternalDamageSlug.Window],
        [ExternalDamageLocalizationSlug.FrontWall]: [ExternalDamageSlug.FrontWall],
        [ExternalDamageLocalizationSlug.Electricity]: [],
        [ExternalDamageLocalizationSlug.Plumbing]: [],
        [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: [ExternalDamageSlug.PhotovoltaicPanel],
        [ExternalDamageLocalizationSlug.Door]: [ExternalDamageSlug.Door],
        [ExternalDamageLocalizationSlug.Other]: [ExternalDamageSlug.Other],
    },
    [StormSnowHailDamageCauseType.Snow]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: [
            ExternalDamageSlug.GardenShelter,
            ExternalDamageSlug.SwimpoolCover,
            ExternalDamageSlug.Plants,
            ExternalDamageSlug.Pergola,
            ExternalDamageSlug.Awning,
        ],
        [ExternalDamageLocalizationSlug.Roof]: [
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Antenna,
            ExternalDamageSlug.Gutter,
        ],
        [ExternalDamageLocalizationSlug.Shutters]: [],
        [ExternalDamageLocalizationSlug.GarageDoor]: [],
        [ExternalDamageLocalizationSlug.Veranda]: [ExternalDamageSlug.Veranda],
        [ExternalDamageLocalizationSlug.Chimney]: [],
        [ExternalDamageLocalizationSlug.FenceAndGate]: [],
        [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: [],
        [ExternalDamageLocalizationSlug.Outbuilding]: [
            ExternalDamageSlug.Roof,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Door,
            ExternalDamageSlug.Window,
            ExternalDamageSlug.Shutters,
        ],
        [ExternalDamageLocalizationSlug.Window]: [],
        [ExternalDamageLocalizationSlug.FrontWall]: [],
        [ExternalDamageLocalizationSlug.Electricity]: [],
        [ExternalDamageLocalizationSlug.Plumbing]: [],
        [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: [],
        [ExternalDamageLocalizationSlug.Door]: [],
        [ExternalDamageLocalizationSlug.Other]: [ExternalDamageSlug.Other],
    },
    [ClaimTypeTrigram.Fire]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: [],
        [ExternalDamageLocalizationSlug.Roof]: [
            ExternalDamageSlug.Antenna,
            ExternalDamageSlug.TileDisplacement,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Chimney,
        ],
        [ExternalDamageLocalizationSlug.Shutters]: [],
        [ExternalDamageLocalizationSlug.GarageDoor]: [],
        [ExternalDamageLocalizationSlug.Veranda]: [],
        [ExternalDamageLocalizationSlug.Chimney]: [],
        [ExternalDamageLocalizationSlug.FenceAndGate]: [
            ExternalDamageSlug.Gate,
            ExternalDamageSlug.LittleGate,
            ExternalDamageSlug.Fence,
        ],
        [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: [
            ExternalDamageSlug.Barbecue,
            ExternalDamageSlug.Playground,
            ExternalDamageSlug.GardenLounge,
            ExternalDamageSlug.PizzaOven,
        ],
        [ExternalDamageLocalizationSlug.Outbuilding]: [],
        [ExternalDamageLocalizationSlug.Window]: [],
        [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: [],
        [ExternalDamageLocalizationSlug.Door]: [],
        [ExternalDamageLocalizationSlug.FrontWall]: [],
        [ExternalDamageLocalizationSlug.Electricity]: [
            ExternalDamageSlug.LightSource,
            ExternalDamageSlug.OutletOrSwitch,
            ExternalDamageSlug.ElectricalSheath,
        ],
        [ExternalDamageLocalizationSlug.Plumbing]: [
            ExternalDamageSlug.AerationGrid,
            ExternalDamageSlug.Basin,
            ExternalDamageSlug.Faucet,
            ExternalDamageSlug.VisiblePipe,
        ],
        [ExternalDamageLocalizationSlug.Other]: [
            ExternalDamageSlug.Plants,
            ExternalDamageSlug.FrontWall,
            ExternalDamageSlug.Awning,
            ExternalDamageSlug.Pergola,
            ExternalDamageSlug.GardenShelter,
            ExternalDamageSlug.SwimpoolCover,
        ],
    },
};
export function getExternalDamageSlugChoices(origin, localization) {
    const slugs = EXTERNAL_DAMAGE_SLUGS[origin][localization];
    return slugs.map((value) => {
        const data = EXTERNAL_DAMAGE_SLUG_TRAD_KEYS[origin][value];
        return typeof data === 'string' ? { value, tradKey: data } : { value, ...data };
    });
}
export const isExternalDamageEnabled = (externalDamageSlug, localizationSlug, qualification) => {
    const key = 'causeType' in qualification ? qualification.causeType : ClaimTypeTrigram.Fire;
    return (!!key &&
        EXTERNAL_DAMAGE_SLUGS[key][localizationSlug].includes(externalDamageSlug));
};
