import { EXTERNAL_DAMAGE_SLUGS, EXTERNAL_DAMAGE_SLUG_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { ClaimTypeTrigram, StormSnowHailDamageCauseType } from '@shared/types/api/claim';
import { ExternalDamageLocalizationSlug, ExternalDamageSlug, } from '@shared/types/api/externalDamage';
export const EXTERNAL_DAMAGE_APARTMENT_SLUGS = {
    [StormSnowHailDamageCauseType.Storm]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: [
            ExternalDamageSlug.Plants,
            ExternalDamageSlug.TerraceFloor,
            ExternalDamageSlug.Awning,
            ExternalDamageSlug.ExternalPersonalProperty,
        ],
        [ExternalDamageLocalizationSlug.Roof]: [
            ExternalDamageSlug.RoofHole,
            ExternalDamageSlug.TileDisplacement,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Antenna,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Chimney,
        ],
        [ExternalDamageLocalizationSlug.Shutters]: [ExternalDamageSlug.Shutters],
        [ExternalDamageLocalizationSlug.GarageDoor]: [ExternalDamageSlug.GarageDoor],
        [ExternalDamageLocalizationSlug.Veranda]: [ExternalDamageSlug.Veranda],
        [ExternalDamageLocalizationSlug.Chimney]: [ExternalDamageSlug.Chimney],
        [ExternalDamageLocalizationSlug.FenceAndGate]: [
            ExternalDamageSlug.Fence,
            ExternalDamageSlug.LittleGate,
            ExternalDamageSlug.StoneWork,
        ],
        [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: [
            ExternalDamageSlug.ExternalPersonalProperty,
        ],
        [ExternalDamageLocalizationSlug.Outbuilding]: [
            ExternalDamageSlug.Roof,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Door,
            ExternalDamageSlug.Window,
            ExternalDamageSlug.Shutters,
        ],
        [ExternalDamageLocalizationSlug.Window]: [],
        [ExternalDamageLocalizationSlug.FrontWall]: [],
        [ExternalDamageLocalizationSlug.Electricity]: [],
        [ExternalDamageLocalizationSlug.Plumbing]: [],
        [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: [],
        [ExternalDamageLocalizationSlug.Door]: [],
        [ExternalDamageLocalizationSlug.Other]: [ExternalDamageSlug.Other],
    },
    [StormSnowHailDamageCauseType.Hail]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: [
            ExternalDamageSlug.Plants,
            ExternalDamageSlug.TerraceFloor,
            ExternalDamageSlug.Awning,
            ExternalDamageSlug.ExternalPersonalProperty,
        ],
        [ExternalDamageLocalizationSlug.Roof]: [
            ExternalDamageSlug.RoofHole,
            ExternalDamageSlug.TileDisplacement,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Antenna,
            ExternalDamageSlug.Gutter,
        ],
        [ExternalDamageLocalizationSlug.Shutters]: [ExternalDamageSlug.Shutters],
        [ExternalDamageLocalizationSlug.GarageDoor]: [ExternalDamageSlug.GarageDoor],
        [ExternalDamageLocalizationSlug.Veranda]: [ExternalDamageSlug.Veranda],
        [ExternalDamageLocalizationSlug.Chimney]: [],
        [ExternalDamageLocalizationSlug.FenceAndGate]: [],
        [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: [
            ExternalDamageSlug.ExternalPersonalProperty,
        ],
        [ExternalDamageLocalizationSlug.Outbuilding]: [
            ExternalDamageSlug.Roof,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Door,
            ExternalDamageSlug.Window,
            ExternalDamageSlug.Shutters,
        ],
        [ExternalDamageLocalizationSlug.Window]: [ExternalDamageSlug.Window],
        [ExternalDamageLocalizationSlug.FrontWall]: [ExternalDamageSlug.FrontWall],
        [ExternalDamageLocalizationSlug.Electricity]: [],
        [ExternalDamageLocalizationSlug.Plumbing]: [],
        [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: [ExternalDamageSlug.PhotovoltaicPanel],
        [ExternalDamageLocalizationSlug.Door]: [],
        [ExternalDamageLocalizationSlug.Other]: [ExternalDamageSlug.Other],
    },
    [StormSnowHailDamageCauseType.Snow]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: [
            ExternalDamageSlug.Plants,
            ExternalDamageSlug.Awning,
        ],
        [ExternalDamageLocalizationSlug.Roof]: [
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Antenna,
            ExternalDamageSlug.Gutter,
        ],
        [ExternalDamageLocalizationSlug.Shutters]: [],
        [ExternalDamageLocalizationSlug.GarageDoor]: [],
        [ExternalDamageLocalizationSlug.Veranda]: [ExternalDamageSlug.Veranda],
        [ExternalDamageLocalizationSlug.Chimney]: [],
        [ExternalDamageLocalizationSlug.FenceAndGate]: [],
        [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: [],
        [ExternalDamageLocalizationSlug.Outbuilding]: [
            ExternalDamageSlug.Roof,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Door,
            ExternalDamageSlug.Window,
            ExternalDamageSlug.Shutters,
        ],
        [ExternalDamageLocalizationSlug.Window]: [],
        [ExternalDamageLocalizationSlug.FrontWall]: [],
        [ExternalDamageLocalizationSlug.Electricity]: [],
        [ExternalDamageLocalizationSlug.Plumbing]: [],
        [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: [],
        [ExternalDamageLocalizationSlug.Door]: [],
        [ExternalDamageLocalizationSlug.Other]: [ExternalDamageSlug.Other],
    },
    [ClaimTypeTrigram.Fire]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: [],
        [ExternalDamageLocalizationSlug.Roof]: [
            ExternalDamageSlug.Antenna,
            ExternalDamageSlug.TileDisplacement,
            ExternalDamageSlug.Gutter,
            ExternalDamageSlug.Framework,
            ExternalDamageSlug.Chimney,
        ],
        [ExternalDamageLocalizationSlug.Shutters]: [],
        [ExternalDamageLocalizationSlug.GarageDoor]: [],
        [ExternalDamageLocalizationSlug.Veranda]: [],
        [ExternalDamageLocalizationSlug.Chimney]: [],
        [ExternalDamageLocalizationSlug.FenceAndGate]: [
            ExternalDamageSlug.Gate,
            ExternalDamageSlug.LittleGate,
            ExternalDamageSlug.Fence,
        ],
        [ExternalDamageLocalizationSlug.ExternalPersonalProperty]: [
            ExternalDamageSlug.Barbecue,
            ExternalDamageSlug.Playground,
            ExternalDamageSlug.GardenLounge,
            ExternalDamageSlug.PizzaOven,
        ],
        [ExternalDamageLocalizationSlug.Outbuilding]: [],
        [ExternalDamageLocalizationSlug.Window]: [],
        [ExternalDamageLocalizationSlug.FrontWall]: [],
        [ExternalDamageLocalizationSlug.Electricity]: [
            ExternalDamageSlug.LightSource,
            ExternalDamageSlug.OutletOrSwitch,
            ExternalDamageSlug.ElectricalSheath,
        ],
        [ExternalDamageLocalizationSlug.Plumbing]: [
            ExternalDamageSlug.AerationGrid,
            ExternalDamageSlug.Basin,
            ExternalDamageSlug.Faucet,
            ExternalDamageSlug.VisiblePipe,
        ],
        [ExternalDamageLocalizationSlug.PhotovoltaicPanel]: [],
        [ExternalDamageLocalizationSlug.Door]: [],
        [ExternalDamageLocalizationSlug.Other]: [
            ExternalDamageSlug.Plants,
            ExternalDamageSlug.FrontWall,
            ExternalDamageSlug.Awning,
            ExternalDamageSlug.Pergola,
            ExternalDamageSlug.GardenShelter,
            ExternalDamageSlug.SwimpoolCover,
        ],
    },
};
export function getExternalDamageSlugChoices(origin, localization) {
    const slugs = EXTERNAL_DAMAGE_SLUGS[origin][localization];
    return slugs.map((value) => {
        const data = EXTERNAL_DAMAGE_SLUG_TRAD_KEYS[origin][value];
        return typeof data === 'string' ? { value, tradKey: data } : { value, ...data };
    });
}
export function getExternalDamageApartmentSlugChoices(origin, localization) {
    const slugs = EXTERNAL_DAMAGE_APARTMENT_SLUGS[origin][localization];
    return slugs.map((value) => {
        const data = EXTERNAL_DAMAGE_SLUG_TRAD_KEYS[origin][value];
        return typeof data === 'string' ? { value, tradKey: data } : { value, ...data };
    });
}
export const isExternalDamageEnabled = (externalDamageSlug, localizationSlug, qualification) => {
    const key = 'causeType' in qualification ? qualification.causeType : ClaimTypeTrigram.Fire;
    return (!!key &&
        EXTERNAL_DAMAGE_SLUGS[key][localizationSlug].includes(externalDamageSlug));
};
