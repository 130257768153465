export function removeArrayItem(array, index) {
    if (array.length === 0) {
        return [...array];
    }
    if (index === 0) {
        return array.slice(1);
    }
    if (index === array.length - 1) {
        return array.slice(0, index);
    }
    return [...array.slice(0, index), ...array.slice(index + 1)];
}
