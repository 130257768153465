import { optionalBooleanValueToString } from '@backoffice/utils/optionalBooleanValueToString';
import { parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { translateChoices } from '@shared/choice';
import { FENCE_TYPE_CHOICES, FENCE_TYPE_TRAD_KEYS } from '@shared/constants/externalDamages';
import { ModelFieldInputPreset, ModelFieldInputType } from '@shared/modelField/modelField';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export const fenceFields = (model, { translate, stormSnowHailDamageCauseType }) => {
    var _a;
    const origin = stormSnowHailDamageCauseType !== null && stormSnowHailDamageCauseType !== void 0 ? stormSnowHailDamageCauseType : ClaimTypeTrigram.Fire;
    return [
        {
            label: translate('model.external_damage.fence_type'),
            value: model.fenceType,
            displayedValue: optionalTranslatableEnumTransform(model.fenceType, FENCE_TYPE_TRAD_KEYS[origin], translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(FENCE_TYPE_CHOICES[origin], translate),
                },
                modelKey: 'fenceType',
            },
        },
        {
            label: translate('model.external_damage.half_height'),
            value: model.isFenceHalfHeight,
            // workaround because model.isFenceHalfHeight is undefined if checkbox is not clicked
            displayedValue: optionalBooleanValueToString(model.isFenceHalfHeight),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isFenceHalfHeight',
            },
        },
        {
            label: translate('model.external_damage.fence_length'),
            value: ((_a = model.fenceLength) !== null && _a !== void 0 ? _a : 0) / 1000,
            displayedValue: parseOptionalDimension(model.fenceLength),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'm',
                modelKey: 'fenceLength',
                toPayload: (fenceLength) => ({ fenceLength: fenceLength * 1000 }),
            },
        },
    ];
};
