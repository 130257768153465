import { EMPTY } from '@shared/utils/strings';
export function optionalTransform(value, transform, defaultValueString = EMPTY) {
    if (value === null || value === undefined) {
        return defaultValueString;
    }
    return transform(value);
}
export function createOptionalTransform(transform) {
    return (value, defaultValueString = EMPTY) => optionalTransform(value, transform, defaultValueString);
}
function booleanKeysTransform(value, yes = 'question.answer.yes', no = 'question.answer.no') {
    return value ? yes : no;
}
export function booleanTransform(value, translate, yesLabel, noLabel) {
    return translate(booleanKeysTransform(value, yesLabel, noLabel));
}
export const optionalBooleanTransform = (value, translate, defaultValueString, yesLabel, noLabel) => optionalTransform(value, (value) => booleanTransform(value, translate, yesLabel, noLabel), defaultValueString);
export function labelEnumTransform(value, labels) {
    return labels[value];
}
export function optionalLabelEnumTransform(value, labels, defaultValueString = EMPTY) {
    if (value === null || value === undefined) {
        return defaultValueString;
    }
    return labelEnumTransform(value, labels);
}
export function translatableEnumTransform(value, tradKeys, translate) {
    const translation = tradKeys[value];
    const tradKey = typeof translation === 'string' ? translation : translation.tradKey;
    return translate(tradKey);
}
export function optionalString(value, defaultValue = EMPTY) {
    if (typeof value !== 'string' && typeof value !== 'number') {
        return defaultValue;
    }
    const valueString = typeof value === 'string' ? value : value.toString(10);
    if (valueString.trim() === '') {
        return defaultValue;
    }
    return valueString;
}
export function optionalTranslatableEnumTransform(value, tradKeys, translate, defaultValueString = EMPTY) {
    if (value === null || value === undefined || !tradKeys[value]) {
        return defaultValueString;
    }
    return translatableEnumTransform(value, tradKeys, translate);
}
export function countryCodeToName(code, language = 'fr') {
    var _a;
    const tr = new Intl.DisplayNames([language], { type: 'region' });
    return (_a = tr.of(code)) !== null && _a !== void 0 ? _a : code;
}
export function optionalCountryCodeToName(code, language = 'fr', defaultValue = EMPTY) {
    if (!code)
        return defaultValue;
    return countryCodeToName(code, language);
}
