export const name = 'authentication';
export const namespaced = true;
export const state = () => ({
    accessToken: null,
    magicLink: null,
});
export const getters = {
    isAuthenticated(state) {
        return !!state.accessToken;
    },
    isAuthenticatedWithMagicLink(state) {
        return !!state.accessToken && !!state.magicLink;
    },
};
export const actions = {
    async login({ commit }, loginPayload) {
        const { access } = await this.$axios.$post('/auth/jwt/create', loginPayload, {
            withCredentials: true,
        });
        if (access) {
            commit('SET_MAGIC_LINK', null);
            commit('SET_ACCESS_TOKEN', access);
        }
    },
    async loginWithMagicLink({ commit }, magicLink) {
        commit('SET_ACCESS_TOKEN', null);
        if (magicLink.error) {
            commit('SET_MAGIC_LINK', magicLink);
            return;
        }
        try {
            const { token } = await this.$axios.$post(`/pwdless/auth/${magicLink.magicToken}`, {
                contractNumber: magicLink.contractNumber,
                claimId: magicLink.claimId,
            });
            if (token) {
                commit('SET_ACCESS_TOKEN', token);
                commit('SET_MAGIC_LINK', magicLink);
            }
        }
        catch (error) {
            commit('SET_ACCESS_TOKEN', null);
            commit('SET_MAGIC_LINK', { ...magicLink, error: 'error.magic_link.invalid' });
        }
    },
    async logout({ dispatch, commit }) {
        dispatch('resetRootStates', null, { root: true });
        try {
            await this.$axios.post(`/auth/jwt/logout`, {}, {
                withCredentials: true,
            });
        }
        finally {
            commit('SET_ACCESS_TOKEN', null);
        }
    },
};
export const mutations = {
    SET_ACCESS_TOKEN(state, token) {
        state.accessToken = token;
    },
    SET_MAGIC_LINK(state, magicLink) {
        state.magicLink = magicLink;
    },
};
