function equipmentConstraints() {
    return {
        name: {
            presence: {
                allowEmpty: false,
                message: 'claim.declaration.equipments.form.name.validator.presence',
            },
        },
    };
}
export default equipmentConstraints;
