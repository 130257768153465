import validate from 'validate.js';
const contractContactConstraints = {
    lastName: {
        presence: {
            allowEmpty: false,
            message: 'contract.modification.last_name.validator.presence',
        },
    },
    firstName: {
        presence: {
            allowEmpty: false,
            message: 'contract.modification.first_name.validator.presence',
        },
    },
    address: {
        presence: {
            allowEmpty: false,
            message: 'contract.modification.address.validator.presence',
        },
    },
    zipcode: {
        presence: {
            allowEmpty: false,
            message: 'contract.modification.zipcode.validator.presence',
        },
    },
    city: {
        presence: {
            allowEmpty: false,
            message: 'contract.modification.city.validator.presence',
        },
    },
    landlinePhone: {
        presenceIfEmpty: {
            attribute: 'mobilePhone',
            message: 'contract.modification.landline_phone.validator.presence',
        },
        length(value) {
            // Only validate length if phone number is set
            if (validate.isEmpty(value)) {
                return;
            }
            return {
                minimum: 10,
                message: 'contract.modification.phone.validator.format',
            };
        },
    },
    mobilePhone: {
        presenceIfEmpty: {
            attribute: 'landlinePhone',
            message: 'contract.modification.mobile_phone.validator.presence',
        },
        length(value) {
            // Only validate length if phone number is set
            if (validate.isEmpty(value)) {
                return;
            }
            return {
                minimum: 10,
                message: 'contract.modification.phone.validator.format',
            };
        },
    },
    email: {
        presence: {
            allowEmpty: false,
            message: 'contract.modification.email.validator.presence',
        },
    },
};
export default contractContactConstraints;
