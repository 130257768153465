import { objectToTranslatableChoices, } from '@shared/choice';
import { INSURER_CONFIG } from '@shared/insurer';
import { LostKeysLockType, PersonalRobberyConcernedPerson, PersonalRobberyEventType, RobberyBreakableElement, RobberyEventType, RobberyLocation, RobberyMethod, RobberySecurizationMethod, RobberyType, } from '@shared/types/api/claim';
export const ROBBERY_EVENT_TYPE_TRAD_KEYS = {
    [RobberyEventType.Robbery]: 'claim.qualification.robbery.event_type.robbery',
    [RobberyEventType.Vandalism]: 'claim.qualification.robbery.event_type.vandalism',
    [RobberyEventType.LostKeys]: 'claim.qualification.robbery.event_type.lost_keys',
};
export const ROBBERY_EVENT_TYPE_CHOICES = objectToTranslatableChoices(ROBBERY_EVENT_TYPE_TRAD_KEYS);
export const ROBBERY_LOCATION_TRAD_KEYS = {
    [RobberyLocation.Home]: 'claim.qualification.robbery.location.home',
    [RobberyLocation.Outside]: 'claim.qualification.robbery.location.outside',
};
export const ROBBERY_LOCATION_CHOICES = objectToTranslatableChoices(ROBBERY_LOCATION_TRAD_KEYS);
export const ROBBERY_TYPE_TRAD_KEYS = {
    [RobberyType.BreakInRobbery]: 'claim.qualification.robbery.type.break_in_robbery',
    [RobberyType.AnyRobbery]: 'claim.qualification.robbery.type.any_robbery',
    [RobberyType.Robbery]: 'claim.qualification.robbery.type.robbery',
    [RobberyType.BreakInAttemptedRobbery]: 'claim.qualification.robbery.type.break_in_attempted_robbery',
    [RobberyType.PersonalRobbery]: 'claim.qualification.robbery.type.personal_robbery',
};
export const ROBBERY_TYPE_ANSWER_TRAD_KEYS = {
    [RobberyType.Robbery]: 'claim.qualification.robbery.type.answer.robbery',
    [RobberyType.AnyRobbery]: 'claim.qualification.robbery.type.answer.any_robbery',
    [RobberyType.BreakInRobbery]: 'claim.qualification.robbery.type.answer.break_in_robbery',
    [RobberyType.BreakInAttemptedRobbery]: 'claim.qualification.robbery.type.answer.break_in_attempted_robbery',
    [RobberyType.PersonalRobbery]: 'claim.qualification.robbery.type.answer.personal_robbery',
};
export const ROBBERY_TYPE_CHOICES = objectToTranslatableChoices(ROBBERY_TYPE_TRAD_KEYS).filter((choice) => !(INSURER_CONFIG === null || INSURER_CONFIG === void 0 ? void 0 : INSURER_CONFIG.robberyType) || INSURER_CONFIG.robberyType.includes(choice.value));
export const PERSONAL_ROBBERY_EVENT_TYPE_TRAD_KEYS = {
    [PersonalRobberyEventType.Assault]: 'claim.qualification.robbery.personal_robbery.event_type.assault',
    [PersonalRobberyEventType.Accident]: 'claim.qualification.robbery.personal_robbery.event_type.accident',
    [PersonalRobberyEventType.Other]: 'claim.qualification.robbery.personal_robbery.event_type.other',
};
export const PERSONAL_ROBBERY_EVENT_TYPE_CHOICES = objectToTranslatableChoices(PERSONAL_ROBBERY_EVENT_TYPE_TRAD_KEYS);
export const PERSONAL_ROBBERY_CONCERNED_PERSON_TRAD_KEYS = {
    [PersonalRobberyConcernedPerson.Yourself]: 'claim.qualification.robbery.personal_robbery.concerned_person.yourself',
    [PersonalRobberyConcernedPerson.Children]: 'claim.qualification.robbery.personal_robbery.concerned_person.children',
    [PersonalRobberyConcernedPerson.Other]: 'claim.qualification.robbery.personal_robbery.concerned_person.other',
};
export const PERSONAL_ROBBERY_CONCERNED_PERSON_CHOICES = objectToTranslatableChoices(PERSONAL_ROBBERY_CONCERNED_PERSON_TRAD_KEYS);
export const ROBBERY_METHOD_TRAD_KEYS = {
    [RobberyMethod.Sneaking]: 'claim.qualification.robbery.method.without_force',
    [RobberyMethod.Climbing]: 'claim.qualification.robbery.method.climbing',
    [RobberyMethod.FalseKey]: 'claim.qualification.robbery.method.false_key',
    [RobberyMethod.FalseIdentity]: 'claim.qualification.robbery.method.false_identity',
    [RobberyMethod.Assault]: 'claim.qualification.robbery.method.assault',
    [RobberyMethod.ElectronicHacking]: 'claim.qualification.robbery.method.electronic_hacking',
    [RobberyMethod.MagneticPass]: 'claim.qualification.robbery.method.magnetic_pass',
    [RobberyMethod.Other]: 'claim.qualification.robbery.method.other',
};
export const ROBBERY_METHOD_CHOICES = objectToTranslatableChoices(ROBBERY_METHOD_TRAD_KEYS);
export const ROBBERY_SECURIZATION_METHOD_TRAD_KEYS = {
    [RobberySecurizationMethod.Unnecessary]: 'claim.qualification.robbery.house_secured.unneccessary',
    [RobberySecurizationMethod.SecuredByMyself]: 'claim.qualification.robbery.house_secured.secured_by_myself',
    [RobberySecurizationMethod.SecuredByAssistance]: 'claim.qualification.robbery.house_secured.secured_by_assistance',
    [RobberySecurizationMethod.AssistanceNeeded]: 'claim.qualification.robbery.house_secured.assistance_needed',
};
export const ROBBERY_SECURIZATION_METHOD_CHOICES = objectToTranslatableChoices(ROBBERY_SECURIZATION_METHOD_TRAD_KEYS);
export const YES_OR_NO_EMERGENCY_SERVICE_NEEDED = [
    {
        value: false,
        tradKey: 'claim.qualification.robbery.emergency.is_needed.no',
    },
    {
        value: true,
        tradKey: 'claim.qualification.robbery.emergency.is_needed.yes',
    },
];
export const POSSIBLE_ROBBERY_METHODS_DURING_ABSENCE = new Set([
    RobberyMethod.Sneaking,
    RobberyMethod.Climbing,
    RobberyMethod.FalseKey,
    RobberyMethod.ElectronicHacking,
    RobberyMethod.MagneticPass,
    RobberyMethod.Other,
]);
export const ROBBERY_METHOD_DURING_ABSENCE_TRAD_KEYS = Object.fromEntries(Object.entries(ROBBERY_METHOD_TRAD_KEYS).filter(([key]) => POSSIBLE_ROBBERY_METHODS_DURING_ABSENCE.has(key)));
export const ROBBERY_METHOD_DURING_ABSENCE_CHOICES = objectToTranslatableChoices(ROBBERY_METHOD_DURING_ABSENCE_TRAD_KEYS);
export const ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS = {
    [RobberyBreakableElement.Door]: 'claim.qualification.robbery.breakable.door',
    [RobberyBreakableElement.GarageDoor]: 'claim.qualification.robbery.breakable.garage_door',
    [RobberyBreakableElement.FrenchWindow]: 'claim.qualification.robbery.breakable.french_window',
    [RobberyBreakableElement.Window]: 'claim.qualification.robbery.breakable.window',
    [RobberyBreakableElement.Shutter]: 'claim.qualification.robbery.breakable.shutter',
    [RobberyBreakableElement.SlidingBayWindow]: 'claim.qualification.robbery.breakable.sliding_bay_window',
    [RobberyBreakableElement.Fence]: 'claim.qualification.robbery.breakable.fence',
    [RobberyBreakableElement.Gate]: 'claim.qualification.robbery.breakable.gate',
    [RobberyBreakableElement.LittleGate]: 'claim.qualification.robbery.breakable.little_gate',
    [RobberyBreakableElement.Other]: 'claim.qualification.robbery.breakable.other',
};
export const ROBBERY_BREAKABLE_ELEMENT_SLUG_AND_TRAD_KEYS = Object.entries(ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS).map(([slug, tradKey]) => ({
    slug,
    tradKey,
}));
export const ROBBERY_LOST_KEYS_LOCK_TYPE_TRAD_KEYS = {
    [LostKeysLockType.MainEntranceDoor]: 'claim.qualification.robbery.lost_keys.lock_type.main_entrance_door',
    [LostKeysLockType.SecondaryEntranceDoor]: 'claim.qualification.robbery.lost_keys.lock_type.secondary_entrance_door',
    [LostKeysLockType.WindowDoor]: 'claim.qualification.robbery.lost_keys.lock_type.window_door',
    [LostKeysLockType.GarageDoor]: 'claim.qualification.robbery.lost_keys.lock_type.garage_door',
    [LostKeysLockType.BasementDoor]: 'claim.qualification.robbery.lost_keys.lock_type.basement_door',
    [LostKeysLockType.Gate]: 'claim.qualification.robbery.lost_keys.lock_type.gate',
    [LostKeysLockType.EntranceDoorCommonArea]: 'claim.qualification.robbery.lost_keys.lock_type.entrance_door_common_area',
    [LostKeysLockType.InteriorDoor]: 'claim.qualification.roberry.lost_keys.lock_type.interior_door',
    [LostKeysLockType.Car]: 'claim.qualification.robbery.lost_keys.lock_type.car',
    [LostKeysLockType.GeneraliCarInsured]: 'claim.qualification.robbery.lost_keys.lock_type.car.generali_insured',
    [LostKeysLockType.GeneraliCarNotInsured]: 'claim.qualification.robbery.lost_keys.lock_type.car.generali_not_insured',
    [LostKeysLockType.MailboxDoor]: 'claim.qualification.robbery.lost_keys.lock_type.mailbox_door',
    [LostKeysLockType.Other]: 'claim.qualification.robbery.lost_keys.lock_type.other',
};
