import { EMPTY } from '@shared/utils/strings';
import { ClaimLanguage } from '@shared/types/api/user';
export function valueToDate(value) {
    return value instanceof Date ? value : new Date(value);
}
export function dateToISOLocaleDay(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}
export function dateToISOLocaleDayAndTime(date) {
    return `${dateToISOLocaleDay(date)}T${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
}
export function optionalValueToDate(value) {
    return value === null || value === undefined ? null : valueToDate(value);
}
export function formatDate(dateValue, lang = ClaimLanguage.French) {
    const date = valueToDate(dateValue);
    switch (lang) {
        case ClaimLanguage.English:
            return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
                .getDate()
                .toString()
                .padStart(2, '0')}/${date.getFullYear()}`;
        case ClaimLanguage.French:
        default:
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, '0')}/${date.getFullYear()}`;
    }
}
export function formatOptionalDate(date, defaultString = EMPTY) {
    return date === undefined || date === null ? defaultString : formatDate(date);
}
export function formatMinute(minute, display0Minutes = false) {
    if (minute === 0 && !display0Minutes) {
        return '';
    }
    if (minute < 10) {
        return `0${minute}`;
    }
    return minute.toString();
}
/**
 * Currently only supports durations up to 1 day
 */
export function formatMinutesDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours}h${formatMinute(minutes, true)}`;
}
export function formatTime(value, display0Minutes = false) {
    const date = value instanceof Date ? value : new Date(value);
    return `${date.getHours()}h${formatMinute(date.getMinutes(), display0Minutes)}`;
}
export function endOfDay(date) {
    const eod = new Date(date);
    eod.setHours(23, 59, 59, 999);
    return eod;
}
export function minimumDate(a, b) {
    if (a.getTime() < b.getTime()) {
        return a;
    }
    return b;
}
function getDateTimeFromDayHourMinute(baseDate, hourAndMinute) {
    const date = new Date(baseDate.getTime());
    const [hours, minutes] = hourAndMinute.split('h');
    date.setHours(hours ? parseInt(hours) : 0, minutes ? parseInt(minutes) : 0);
    return date;
}
export function startEndFromAvailableTimeSlot(day, { startHour, endHour }) {
    return {
        startDateTime: getDateTimeFromDayHourMinute(day, startHour),
        endDateTime: getDateTimeFromDayHourMinute(day, endHour),
    };
}
export function formatDateTime(dateValue) {
    return `${formatDate(dateValue)} à ${formatTime(dateValue)}`;
}
export function formatOptionalDateTime(dateValue, defaultString = EMPTY) {
    if (dateValue === null || dateValue === undefined) {
        return defaultString;
    }
    return formatDateTime(dateValue);
}
export function areDatesOnSameDay(first, second) {
    const firstDate = valueToDate(first);
    const secondDate = valueToDate(second);
    return (firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate());
}
