import { VuexPersistence } from 'vuex-persist';
import { state as artisanAppointmentState } from '@declaration/store/artisanAppointment';
import { state as authenticationState } from '@declaration/store/authentication';
import { state as breakInDamageState } from '@declaration/store/breakInDamage';
import { state as claimState } from '@declaration/store/claim';
import { state as contractState } from '@declaration/store/contract';
import { state as countingState } from '@declaration/store/counting';
import { state as equipmentState } from '@declaration/store/equipment';
import { state as errorState } from '@declaration/store/error';
import { state as expertAppointmentState } from '@declaration/store/expertAppointment';
import { state as externalDamageState } from '@declaration/store/externalDamage';
import { state as layoutState } from '@declaration/store/layout';
import { state as pagesState } from '@declaration/store/pages';
import { state as personalPropertyState } from '@declaration/store/personalProperty';
import { state as qualificationState } from '@declaration/store/qualification';
import { state as receiptState } from '@declaration/store/receipt';
import { state as roomDamageState } from '@declaration/store/roomDamage';
import { state as settingsState } from '@declaration/store/settings';
import { state as thirdPartyState } from '@declaration/store/thirdParty';
import { state as userState } from '@declaration/store/user';
import { state as helperState } from '@declaration/store/helpers';
const persistedMutations = new Set([
    'RESET_ROOT_STATE',
    'authentication/SET_AUTH',
    'authentication/SET_MAGIC_LINK',
    'user/SET_USER_INFO',
    'artisanAppointment/SET_AVAILABLE_TIME_SLOTS_SCHEDULE',
    'artisanAppointment/SET_SELECTED_TIME_SLOT_SLUG',
    'artisanAppointment/RESET_STATE',
    'expertAppointment/SET_AVAILABLE_TIME_SLOTS_SCHEDULE',
    'expertAppointment/SET_EXPERT_NAME',
    'expertAppointment/SET_SELECTED_TIME_SLOT_SLUG',
    'expertAppointment/RESET_STATE',
    'claim/UPDATE_CLAIM',
    'claim/SET_CLAIM_ID',
    'claim/RESET_STATE',
]);
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        authentication: { ...state.authentication },
        user: { ...state.user },
        artisanAppointment: { ...state.artisanAppointment },
        expertAppointment: { ...state.expertAppointment },
        claim: { claim: { id: state.claim.claim.id } },
    }),
    filter: (mutation) => persistedMutations.has(mutation.type),
});
export const plugins = [vuexLocal.plugin];
export const state = () => ({});
export const getters = {};
export const actions = {
    resetRootStates({ commit }) {
        commit('RESET_ROOT_STATE');
    },
};
export const mutations = {
    RESET_ROOT_STATE(stateObject) {
        const newState = {
            artisanAppointment: artisanAppointmentState(),
            authentication: authenticationState(),
            breakInDamage: breakInDamageState(),
            claim: claimState(),
            contract: contractState(),
            counting: countingState(),
            equipment: equipmentState(),
            error: errorState(),
            expertAppointment: expertAppointmentState(),
            externalDamages: externalDamageState(),
            layout: layoutState(),
            pages: pagesState(),
            personalProperty: personalPropertyState(),
            qualification: qualificationState(),
            receipt: receiptState(),
            roomDamage: roomDamageState(),
            settings: settingsState(),
            thirdParty: thirdPartyState(),
            user: userState(),
            helpers: helperState(),
        };
        Object.assign(stateObject, newState);
    },
};
