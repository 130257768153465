import { antennaFields } from './antennaFields';
import { garageDoorFields } from './garageDoorFields';
import { tileDisplacementFields } from './tileDisplacementFields';
import { chimneyFields } from './chimneyFields';
import { shuttersFields } from './shuttersFields';
import { gutterFields } from './gutterFields';
import { fenceFields } from './fenceFields';
import { littleGateFields } from './littleGateFields';
import { gateFields } from './gateFields';
import { awningFields } from './awningFields';
import { basinFields } from './basinFields';
import { aerationGridFields } from './aerationGridFields';
import { visiblePipeFields } from './visiblePipeFields';
import { electricalSheathFields } from './electricalSheathFields';
import { lightSourceFields } from './lightSourceFields';
import { verandaFields } from './verandaFields';
import { windowFields } from './windowFields';
import { doorFields } from './doorFields';
import { pergolaFields } from '@shared/externalDamage/pergolaFields';
import externalDamageConstraints from '@shared/validator/constraints/externalDamageConstraints';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
import { ClaimFileType } from '@shared/types/file';
import { ExternalDamageSlug, } from '@shared/types/api/externalDamage';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS, EXTERNAL_DAMAGE_SLUG_TRAD_KEYS, getExternalDamageLocalizationChoices, getExternalDamageSlugChoices, } from '@shared/constants/externalDamages';
import { translateChoices } from '@shared/choice';
export function externalDamageTitle(model, origin, translate) {
    return origin
        ? optionalTranslatableEnumTransform(model.slug, EXTERNAL_DAMAGE_SLUG_TRAD_KEYS[origin], translate, 'Dommage extérieur')
        : 'Dommage extérieur';
}
export const externalDamageFields = (model, ctx) => {
    const { translate, projection, stormSnowHailDamageCauseType } = ctx;
    const key = stormSnowHailDamageCauseType !== null && stormSnowHailDamageCauseType !== void 0 ? stormSnowHailDamageCauseType : ClaimTypeTrigram.Fire;
    const title = externalDamageTitle(model, key, translate);
    const fields = [];
    if (projection !== ModelFieldsProjection.Declaration) {
        fields.push({
            label: translate('model.external_damage.localization'),
            value: model.localizationSlug,
            displayedValue: optionalTranslatableEnumTransform(model.localizationSlug, EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS[key], translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(getExternalDamageLocalizationChoices(key), translate),
                },
                modelKey: 'localizationSlug',
                toPayload(localizationSlug) {
                    return {
                        localizationSlug,
                        slug: !model.id ? null : model.slug,
                    };
                },
            },
        });
    }
    if ((projection === ModelFieldsProjection.Backoffice || projection !== ModelFieldsProjection.Pdf) &&
        !model.id &&
        !!model.localizationSlug) {
        fields.push({
            label: translate('model.external_damage.slug'),
            value: model.slug,
            displayedValue: optionalTranslatableEnumTransform(model.slug, EXTERNAL_DAMAGE_SLUG_TRAD_KEYS[key], translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(getExternalDamageSlugChoices(key, model.localizationSlug), translate),
                },
                modelKey: 'slug',
            },
        });
    }
    switch (model.slug) {
        case ExternalDamageSlug.Antenna:
            fields.push(...antennaFields(model, ctx));
            break;
        case ExternalDamageSlug.Awning:
            fields.push(...awningFields(model, ctx));
            break;
        case ExternalDamageSlug.GarageDoor:
            fields.push(...garageDoorFields(model, ctx));
            break;
        case ExternalDamageSlug.TileDisplacement:
            fields.push(...tileDisplacementFields(model, ctx));
            break;
        case ExternalDamageSlug.Chimney:
            fields.push(...chimneyFields(model, ctx));
            break;
        case ExternalDamageSlug.Shutters:
            fields.push(...shuttersFields(model, ctx));
            break;
        case ExternalDamageSlug.Gutter:
            fields.push(...gutterFields(model, ctx));
            break;
        case ExternalDamageSlug.Fence:
            fields.push(...fenceFields(model, ctx));
            break;
        case ExternalDamageSlug.LittleGate:
            fields.push(...littleGateFields(model, ctx));
            break;
        case ExternalDamageSlug.Gate:
            fields.push(...gateFields(model, ctx));
            break;
        case ExternalDamageSlug.Basin:
            fields.push(...basinFields(model, ctx));
            break;
        case ExternalDamageSlug.AerationGrid:
            fields.push(...aerationGridFields(model, ctx));
            break;
        case ExternalDamageSlug.VisiblePipe:
            fields.push(...visiblePipeFields(model, ctx));
            break;
        case ExternalDamageSlug.ElectricalSheath:
            fields.push(...electricalSheathFields(model, ctx));
            break;
        case ExternalDamageSlug.LightSource:
            fields.push(...lightSourceFields(model, ctx));
            break;
        case ExternalDamageSlug.Veranda:
            fields.push(...verandaFields(model, ctx));
            break;
        case ExternalDamageSlug.Pergola:
            fields.push(...pergolaFields(model, ctx));
            break;
        case ExternalDamageSlug.Window:
            fields.push(...windowFields(model, ctx));
            break;
        case ExternalDamageSlug.Door:
            fields.push(...doorFields(model, ctx));
            break;
        default:
            break;
    }
    if (projection !== ModelFieldsProjection.Pdf) {
        fields.push({
            label: translate('model.pictures'),
            value: model.pictureCollection,
            displayedValue: {
                type: 'fileCollection',
                fileCollection: model.pictureCollection,
                uploadLabel: translate('model.picture.upload'),
                emptyLabel: translate('model.pictures.empty'),
                fileType: ClaimFileType.ExternalDamagePicture,
                displayAsCount: true,
            },
            editable: {
                input: {
                    type: ModelFieldInputType.FileCollection,
                },
                modelKey: 'pictureCollection',
            },
        });
    }
    return {
        title,
        fields,
        model,
        editable: { constraints: externalDamageConstraints },
        defaultPayload: { slug: model.slug },
    };
};
