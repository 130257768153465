import { UI } from '@declaration/utils/constants';
function viewWidth() {
    // Cross browser js equivalent of @media(width)
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}
export function isDesktop() {
    return viewWidth() > UI.MOBILE_MAX_SIZE;
}
export function isMobile() {
    return viewWidth() <= UI.MOBILE_MAX_SIZE;
}
