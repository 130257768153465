import { forcableAmount } from '@shared/utils/forcedAmount';
export var ExclusionReason;
(function (ExclusionReason) {
    ExclusionReason["ContractGuarantees"] = "contract-guarantees";
    ExclusionReason["NoReparabilityCertificate"] = "no-reparability-certificate";
    ExclusionReason["TooComplexForRefund"] = "too-complex-for-refund";
    ExclusionReason["NotIncludedInOutsideRobbery"] = "not-included-in-outside-robbery";
    ExclusionReason["RepairedByAssistance"] = "repaired-by-assistance";
    ExclusionReason["NoProofForAmountOnlyProperty"] = "no-proof-amount-only";
    ExclusionReason["NoProofForExpensiveProperty"] = "no-proof-expensive";
    ExclusionReason["ContractError"] = "contract-error";
})(ExclusionReason || (ExclusionReason = {}));
function createEmptyCeilingParameters() {
    return {
        value: null,
        type: null,
    };
}
export function createEmptyValuationResult() {
    return {
        replacement: null,
        obsolescenceRatio: null,
        obsolescence: null,
        obsolescenceDeducted: null,
    };
}
export var ProofType;
(function (ProofType) {
    ProofType["ExistenceAndValue"] = "proof_type_existence_and_value";
    ProofType["Existence"] = "proof_type_existence";
    ProofType["Empty"] = "proof_type_empty";
    ProofType["NotApplicable"] = "proof_not_applicable";
})(ProofType || (ProofType = {}));
export function createEmptyRefundDetailParameters() {
    return {
        exclusionReason: null,
        documentsAmount: null,
        deductibleAmount: {
            baseValue: null,
            computedValue: null,
            forcedValue: null,
        },
        ceiling: createEmptyCeilingParameters(),
        isVanApplicable: null,
        recoverableObsolescenceRatio: null,
        proofType: null,
    };
}
export function createEmptyCompensationResult() {
    return {
        obsolescenceDeducted: null,
        certifiedArtisanAmount: null,
        recoverableObsolescenceRatio: null,
        recoverableObsolescence: null,
        documentAmount: null,
        immediate: forcableAmount(),
        delayed: forcableAmount(),
        total: forcableAmount(),
    };
}
export function createEmptyRefundDetail() {
    return {
        valuation: createEmptyValuationResult(),
        parameters: createEmptyRefundDetailParameters(),
        compensation: createEmptyCompensationResult(),
        appliedAmounts: createEmptyCompensationResult(),
    };
}
