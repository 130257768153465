import { createDamageStore } from '@declaration/store/createDamageStore';
import { Step } from '@shared/claim/steps';
import { fileCollectionFilesToIri } from '@shared/files/uploadedFiles';
import { surfaceDamageCoveringCategoryToIri, groupCoveringCategoriesBySurfaceType, surfaceDamageOfType, } from '@shared/roomDamage/surfaceDamage';
import { ApiResourceName } from '@shared/types/api/api';
import { QualificationRoomType } from '@shared/types/api/claim';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { indexBy } from '@shared/utils/indexBy';
import { optionalResourceIri } from '@shared/utils/iri';
import { removeArrayItem } from '@shared/utils/removeArrayItem';
import roomDamageConstraints from '@shared/validator/constraints/roomDamageConstraints';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
export const name = 'roomDamage';
export const namespaced = true;
export const PICTURE_FILES_COUNT = 4;
export const roundSurfaceToHalfInteger = (number) => {
    return Math.round(number * 2) / 2;
};
function emptyRoomDamage() {
    return {
        id: null,
        clientIdentifier: newClientIdentifier(),
        roomNameSlug: null,
        surface: null,
        surfaceDamages: [],
        goodDamages: [],
        pictureCollection: {
            id: null,
            uploadedAppFiles: [],
        },
    };
}
const { createDamageState, damageGetters, damageActions, damageMutations } = createDamageStore(emptyRoomDamage, roomDamageConstraints, Step.RoomDamagesDetails, 'roomDamages', { step: Step.NumberOfRoomDamages, categoryKey: 'roomNameSlug' }, (roomNameSlug, _state, _getters, _rootState, rootGetters) => {
    const surface = rootGetters['contract/roomMeanSurface'](roomNameSlug);
    return { roomNameSlug, surface };
});
export const state = () => {
    return {
        ...createDamageState(),
        coveringCategoriesBySurfaceType: null,
        coveringCategories: null,
    };
};
function updateSurfaceDamage({ surfaceIndex, surfaceType, payload, roomDamage, }) {
    const surfaceDamages = roomDamage.surfaceDamages;
    const surfaceDamage = surfaceDamages.filter(surfaceDamageOfType(surfaceType))[surfaceIndex];
    if (!surfaceDamage) {
        return null;
    }
    const index = surfaceDamages.indexOf(surfaceDamage);
    if (index < 0) {
        return null;
    }
    const newSurfaceDamage = {
        ...surfaceDamage,
        ...payload,
    };
    const result = [...surfaceDamages];
    result[index] = newSurfaceDamage;
    return result;
}
function updateSurfaceDamages({ surfaceType, payload, roomDamage, }) {
    const surfaceDamages = roomDamage.surfaceDamages;
    const surfaceDamageIndexes = surfaceDamages
        .filter(surfaceDamageOfType(surfaceType))
        .map((surfaceDamage) => surfaceDamages.indexOf(surfaceDamage))
        .filter((index) => index !== -1);
    const result = [...surfaceDamages];
    for (const index of surfaceDamageIndexes) {
        result[index] = {
            ...surfaceDamages[index],
            ...payload,
        };
    }
    return result;
}
export const getters = {
    ...damageGetters,
    getRoomDamagePositionSuffix: (state) => (slug, index) => {
        if (!slug) {
            return null;
        }
        let positionWithSameCategory = 1;
        let isTheOnlyOneWithCategory = true;
        state.damages.forEach((damage, otherIndex) => {
            if (damage.roomNameSlug === slug && otherIndex !== index) {
                isTheOnlyOneWithCategory = false;
                if (otherIndex < index) {
                    positionWithSameCategory += 1;
                }
            }
        });
        return isTheOnlyOneWithCategory ? null : positionWithSameCategory.toString();
    },
    isEmpty(_state, getters) {
        return (changeKey) => {
            const roomDamage = getters.change(changeKey).data;
            return roomDamage.roomNameSlug === QualificationRoomType.Other
                ? !roomDamage.roomLabel && !roomDamage.roomDescription
                : roomDamage.surfaceDamages.length === 0 && roomDamage.goodDamages.length === 0;
        };
    },
};
export const actions = {
    ...damageActions,
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    updateStoreWithClaimResponse({ commit }, claimResponse) {
        commit('SET_DAMAGES', claimResponse.counting.roomDamages.map((damage) => ({
            ...damage,
            surfaceDamages: damage.surfaceDamages.map(surfaceDamageCoveringCategoryToIri),
            goodDamages: damage.goodDamages,
            pictureCollection: fileCollectionFilesToIri(damage.pictureCollection),
        })));
    },
    async fetchCoveringCategoriesIfNotSet({ state, commit }) {
        if (state.coveringCategoriesBySurfaceType) {
            return;
        }
        const { data } = await this.$axios.get(apiResourcePath(ApiResourceName.CoveringCategories));
        const coveringCategories = data['hydra:member'];
        commit('SET_COVERING_CATEGORIES', coveringCategories);
    },
    async validateRoomDamagesStep({ dispatch, rootState, commit }) {
        if (!rootState.counting.counting.id) {
            throw new Error('No counting');
        }
        // ensure Step.RoomDamagesDetails is in StepStack
        if (!rootState.claim.claim.stepStack.includes(Step.RoomDamagesDetails)) {
            try {
                await dispatch('claim/saveStepWithoutStoreUpdate', Step.RoomDamagesDetails, { root: true });
            }
            catch (_a) {
                dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
                return;
            }
        }
        await dispatch('claim/updateAndSaveClaimStep', Step.RoomDamages, { root: true });
        commit('RESET_CHANGES');
    },
    resetSurfaceDamage({ dispatch, getters }, { changeKey, surfaceType, }) {
        const roomDamage = getters.change(changeKey).data;
        const payload = {
            changeKey,
            data: {
                surfaceDamages: roomDamage.surfaceDamages.filter((surfaceDamage) => !surfaceDamageOfType(surfaceType)(surfaceDamage)),
            },
        };
        dispatch('updateDamage', payload);
    },
    addNewSurfaceDamage({ getters, dispatch }, { surfaceType, changeKey, }) {
        var _a, _b;
        const roomDamage = getters.change(changeKey).data;
        const lastSurfaceDamageOfSameType = (_a = roomDamage.surfaceDamages.find(surfaceDamageOfType(surfaceType))) !== null && _a !== void 0 ? _a : null;
        const lastCoveringCategoryIri = optionalResourceIri(lastSurfaceDamageOfSameType === null || lastSurfaceDamageOfSameType === void 0 ? void 0 : lastSurfaceDamageOfSameType.coveringCategory);
        const lastAge = (_b = lastSurfaceDamageOfSameType === null || lastSurfaceDamageOfSameType === void 0 ? void 0 : lastSurfaceDamageOfSameType.age) !== null && _b !== void 0 ? _b : null;
        const newSurfaceDamage = {
            id: null,
            age: lastAge,
            type: surfaceType,
            coveringCategory: lastCoveringCategoryIri,
        };
        const payload = {
            changeKey,
            data: {
                surfaceDamages: [...roomDamage.surfaceDamages, newSurfaceDamage],
            },
        };
        dispatch('updateDamage', payload);
    },
    popSurfaceDamage({ getters, dispatch }, { surfaceType, changeKey }) {
        const roomDamage = getters.change(changeKey).data;
        const surfaceDamages = roomDamage.surfaceDamages;
        // Find last index of roomDamage with same type
        let indexToRemove = null;
        for (let i = surfaceDamages.length - 1; i >= 0; i--) {
            if (surfaceDamages[i].type === surfaceType) {
                indexToRemove = i;
                break;
            }
        }
        if (indexToRemove === null) {
            return;
        }
        const newSurfaceDamages = removeArrayItem(surfaceDamages, indexToRemove);
        const payload = {
            changeKey,
            data: {
                surfaceDamages: newSurfaceDamages,
            },
        };
        dispatch('updateDamage', payload);
    },
    setSurfaceDamageCovering({ getters, dispatch }, { changeKey, surfaceType, surfaceIndex, coveringCategory, }) {
        const roomDamage = getters.change(changeKey).data;
        const surfaceDamages = updateSurfaceDamage({
            surfaceType,
            surfaceIndex,
            payload: { coveringCategory },
            roomDamage,
        });
        const payload = {
            changeKey,
            data: {
                surfaceDamages,
            },
        };
        dispatch('updateDamage', payload);
    },
    setSurfaceDamageAges({ getters, dispatch }, { changeKey, surfaceType, age, }) {
        const roomDamage = getters.change(changeKey).data;
        const surfaceDamages = updateSurfaceDamages({ roomDamage, payload: { age }, surfaceType });
        const payload = {
            changeKey,
            data: {
                surfaceDamages,
            },
        };
        dispatch('updateDamage', payload);
    },
    addNewGoodDamage({ getters, dispatch }, { room, goodCategory, goodType, changeKey, }) {
        const roomDamage = getters.change(changeKey).data;
        const newGoodDamage = {
            id: null,
            room,
            category: goodCategory,
            type: goodType,
        };
        const payload = {
            changeKey,
            data: {
                goodDamages: [...roomDamage.goodDamages, newGoodDamage],
            },
        };
        dispatch('updateDamage', payload);
    },
    popGoodDamage({ getters, dispatch }, { changeKey, room, goodCategory, goodType, }) {
        const roomDamage = getters.change(changeKey).data;
        const goodDamages = roomDamage.goodDamages;
        const indexToRemove = goodDamages.findIndex((goodDamage) => goodDamage.category === goodCategory &&
            goodDamage.type === goodType &&
            goodDamage.room === room);
        if (indexToRemove < 0)
            return;
        const newGoodDamages = removeArrayItem(goodDamages, indexToRemove);
        const payload = {
            changeKey,
            data: {
                goodDamages: newGoodDamages,
            },
        };
        dispatch('updateDamage', payload);
    },
    removeGoodDamage({ getters, dispatch }, { changeKey, index, }) {
        const roomDamage = getters.change(changeKey).data;
        const goodDamages = roomDamage.goodDamages;
        const newGoodDamages = removeArrayItem(goodDamages, index);
        const payload = {
            changeKey,
            data: {
                goodDamages: newGoodDamages,
            },
        };
        dispatch('updateDamage', payload);
    },
    resetGoodDamage({ dispatch, getters }, { changeKey, room, goodCategory, goodType, }) {
        const roomDamage = getters.change(changeKey).data;
        const payload = {
            changeKey,
            data: {
                goodDamages: roomDamage.goodDamages.filter((goodDamage) => {
                    return goodType === null
                        ? !(goodDamage.room === room && goodDamage.category === goodCategory)
                        : !(goodDamage.room === room &&
                            goodDamage.category === goodCategory &&
                            goodDamage.type === goodType);
                }),
            },
        };
        dispatch('updateDamage', payload);
    },
};
export const mutations = {
    ...damageMutations,
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    SET_COVERING_CATEGORIES(state, coveringCategories) {
        state.coveringCategories = indexBy(coveringCategories, 'id');
        state.coveringCategoriesBySurfaceType = groupCoveringCategoriesBySurfaceType(coveringCategories);
    },
};
