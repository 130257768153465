import { objectToTranslatableChoices } from '@shared/choice';
import { DEFAULT_FENCE_TYPE_TRAD_KEYS } from '@shared/constants/externalDamages';
import { FenceType } from '@shared/types/api/externalDamage';
export const FENCE_TITLE = 'claim.declaration.external_damages.form.fence.title';
export const FENCE_SUB_TITLE = 'claim.declaration.external_damages.form.fence.subtitle';
export const FENCE_LENGHT_TITLE = 'claim.declaration.external_damages.form.fence.length.title';
export const FENCE_LENGHT_LABEL = 'claim.declaration.external_damages.form.fence.length.question';
export const FENCE_TYPE_HALF_HEIGHT_IMAGES = {
    [FenceType.WireMesh]: require('@declaration/assets/images/externalDamage/fenceType/grillagee-half.svg'),
    [FenceType.WirePanel]: require('@declaration/assets/images/externalDamage/fenceType/panneau-grillage-half.svg'),
    [FenceType.WoodPanel]: require('@declaration/assets/images/externalDamage/fenceType/panneau-bois-half.svg'),
    [FenceType.PVC]: require('@declaration/assets/images/externalDamage/fenceType/pvc-half.svg'),
};
export const FENCE_TYPE_FULL_HEIGHT_IMAGES = {
    [FenceType.WireMesh]: require('@declaration/assets/images/externalDamage/fenceType/grillagee-full.svg'),
    [FenceType.WirePanel]: require('@declaration/assets/images/externalDamage/fenceType/panneau-grillage-full.svg'),
    [FenceType.WoodPanel]: require('@declaration/assets/images/externalDamage/fenceType/panneau-bois-full.svg'),
    [FenceType.PVC]: require('@declaration/assets/images/externalDamage/fenceType/pvc-full.svg'),
};
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const FENCE_TYPE_OTHER_IMAGE = require('@declaration/assets/images/externalDamage/fenceType/cloture-autre.svg');
export const DEFAULT_FENCE_TYPE_CHOICES = objectToTranslatableChoices(DEFAULT_FENCE_TYPE_TRAD_KEYS);
