import { objectToTranslatableChoices } from '@shared/choice';
import { SurfaceType } from '@shared/types/api/roomDamage';
import { optionalResourceIri } from '@shared/utils/iri';
export function surfaceDamagesByType(surfaceDamages) {
    const result = {
        [SurfaceType.Wall]: [],
        [SurfaceType.Floor]: [],
        [SurfaceType.Ceiling]: [],
    };
    for (const surfaceDamage of surfaceDamages) {
        result[surfaceDamage.type].push(surfaceDamage);
    }
    return result;
}
export const EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE = {
    [SurfaceType.Ceiling]: [],
    [SurfaceType.Floor]: [],
    [SurfaceType.Wall]: [],
};
export function groupCoveringCategoriesBySurfaceType(coveringCategories) {
    const result = {
        [SurfaceType.Wall]: [],
        [SurfaceType.Floor]: [],
        [SurfaceType.Ceiling]: [],
    };
    for (const coveringCategory of coveringCategories) {
        result[coveringCategory.surfaceType].push(coveringCategory);
    }
    return result;
}
// Filter function to help with surface damage entities
export function surfaceDamageOfTypeWall(surfaceDamage) {
    return surfaceDamage.type === SurfaceType.Wall;
}
export function surfaceDamageOfTypeCeiling(surfaceDamage) {
    return surfaceDamage.type === SurfaceType.Ceiling;
}
export function surfaceDamageOfTypeFloor(surfaceDamage) {
    return surfaceDamage.type === SurfaceType.Floor;
}
const surfaceDamageFilters = {
    [SurfaceType.Wall]: surfaceDamageOfTypeWall,
    [SurfaceType.Ceiling]: surfaceDamageOfTypeCeiling,
    [SurfaceType.Floor]: surfaceDamageOfTypeFloor,
};
export function surfaceDamageOfType(type) {
    return surfaceDamageFilters[type];
}
export function surfaceDamageCoveringCategoryToIri(surfaceDamage) {
    const coveringCategory = surfaceDamage === null || surfaceDamage === void 0 ? void 0 : surfaceDamage.coveringCategory;
    if (!coveringCategory || typeof coveringCategory === 'string') {
        return surfaceDamage;
    }
    return { ...surfaceDamage, coveringCategory: optionalResourceIri(coveringCategory) };
}
export const SURFACE_TYPE_LABELS = {
    [SurfaceType.Wall]: 'claim.damage.real_estate.room_damage.form.question.surface_damage.wall',
    [SurfaceType.Floor]: 'claim.damage.real_estate.room_damage.form.question.surface_damage.floor',
    [SurfaceType.Ceiling]: 'claim.damage.real_estate.room_damage.form.question.surface_damage.ceiling',
};
export const SURFACE_TYPE_CHOICES = objectToTranslatableChoices(SURFACE_TYPE_LABELS);
