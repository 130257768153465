export function receiptConstraints() {
    return {
        title: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.real_estate.receipt.form.question.title.validator.presence',
            },
        },
        amount: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.real_estate.receipt.form.question.amount.validator.presence',
            },
            numericality: {
                greaterThan: 0,
                lessThan: 1000000000,
                message: 'claim.damage.real_estate.receipt.form.question.amount.validator.numericality',
            },
        },
        receiptFile: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.real_estate.receipt.form.question.file.validator.presence',
            },
        },
    };
}
