/**
 * A Question is a component displayed to the User for one step of a declaration
 *
 * They appear in the Questions declaration page.
 *
 * Must be updated for each declaration of a new Question component.
 *
 * It serves as an enum proxy because components cannot be directly imported as it causes
 * circular imports that fail compilation
 */
export var Question;
(function (Question) {
    Question["ClaimDateQuestion"] = "ClaimDateQuestion";
    Question["LeakageCauseLocationQuestion"] = "LeakageCauseLocationQuestion";
    Question["LeakageCauseLocationHouseQuestion"] = "LeakageCauseLocationHouseQuestion";
    Question["WaterLeakageCauseFixedQuestion"] = "WaterLeakageCauseFixedQuestion";
    Question["WaterLeakageCauseLocalizedQuestion"] = "WaterLeakageCauseLocalizedQuestion";
    Question["WaterLeakageCauseLocalizedPlumberIntervenedQuestion"] = "WaterLeakageCauseLocalizedPlumberIntervenedQuestion";
    Question["WaterLeakageCauseLocalizedPlumberIntervenedInvoiceQuestion"] = "WaterLeakageCauseLocalizedPlumberIntervenedInvoiceQuestion";
    Question["HasDamagesQuestion"] = "HasDamagesQuestion";
    Question["IsRehousingNeededQuestion"] = "IsRehousingNeededQuestion";
    Question["NeedHelpToFindLeakageQuestion"] = "NeedHelpToFindLeakageQuestion";
    Question["BeCalledQualificationQuestion"] = "BeCalledQualificationQuestion";
    Question["NumberOfRoomDamagesQuestion"] = "NumberOfRoomDamagesQuestion";
    Question["IsPremiseFurnishedQuestion"] = "IsPremiseFurnishedQuestion";
    Question["IsPremiseVacantQuestion"] = "IsPremiseVacantQuestion";
    Question["IsLeaveBeforeSinisterQuestion"] = "IsLeaveBeforeSinisterQuestion";
    Question["OwnerHasDamagesOnTenantPropertiesQuestion"] = "OwnerHasDamagesOnTenantPropertiesQuestion";
    Question["NumberOfPersonalPropertyDamagesQuestion"] = "NumberOfPersonalPropertyDamagesQuestion";
    Question["HasPersonalPropertyDamagesQuestion"] = "HasPersonalPropertyDamagesQuestion";
    Question["HasRoomDamagesQuestion"] = "HasRoomDamagesQuestion";
    Question["LeakageCauseRoomQuestion"] = "LeakageCauseRoomQuestion";
    Question["LeakageCauseEquipmentQuestion"] = "LeakageCauseEquipmentQuestion";
    Question["LeakageCauseEquipmentDetailQuestion"] = "LeakageCauseEquipmentDetailQuestion";
    Question["LeakageCauseCommonAreaQuestion"] = "LeakageCauseCommonAreaQuestion";
    Question["LeakageCauseOutsideHouseQuestion"] = "LeakageCauseOutsideHouseQuestion";
    Question["EquipmentAgeQuestion"] = "EquipmentAgeQuestion";
    Question["OutsiderInterventionQuestion"] = "OutsiderInterventionQuestion";
    Question["RoomDamagesQuestion"] = "RoomDamageQuestion";
    Question["PersonalPropertiesQuestion"] = "PersonalPropertyQuestion";
    Question["ExternalDamagesQuestion"] = "ExternalDamagesQuestion";
    Question["ThirdPartyQuestion"] = "ThirdPartyQuestion";
    Question["EquipmentsQuestion"] = "EquipmentQuestion";
    Question["IsThirdPartyInvolvedQuestion"] = "IsThirdPartyInvolvedQuestion";
    Question["ElectricalCauseOriginQuestion"] = "ElectricalCauseOriginQuestion";
    Question["ElectricalTypeOfDamageQuestion"] = "ElectricalTypeOfDamageQuestion";
    Question["ElectricalFoodAmountQuestion"] = "ElectricalFoodAmountQuestion";
    Question["ElectricalNbrOfEquipmentsQuestion"] = "ElectricalNbrOfEquipmentsQuestion";
    Question["HasProfessionalIntervenedQuestion"] = "HasProfessionalIntervenedQuestion";
    Question["ProfessionalInterventionInfoQuestion"] = "ProfessionalInterventionInfoQuestion";
    Question["ProfessionalInterventionInvoiceQuestion"] = "ProfessionalInterventionInvoiceQuestion";
    Question["IsAwareOfPublicWorksQuestion"] = "IsAwareOfPublicWorksQuestion";
    Question["PublicWorksInfoQuestion"] = "PublicWorksInfoQuestion";
    Question["ElectricalCauseEquipmentQuestion"] = "ElectricalCauseEquipmentQuestion";
    Question["StormDateQuestion"] = "StormDateQuestion";
    Question["ElectricalDamagesQuestion"] = "ElectricalDamagesQuestion";
    Question["StormCauseTypeQuestion"] = "StormCauseTypeQuestion";
    Question["IsProtectionMeasureDoneQuestion"] = "IsProtectionMeasureDoneQuestion";
    Question["IsHelpForProtectionMeasureNeededQuestion"] = "IsHelpForProtectionMeasureNeededQuestion";
    Question["RobberyEventTypeQuestion"] = "RobberyEventTypeQuestion";
    Question["RobberyLostKeysCircumstancesQuestion"] = "RobberyLostKeysCircumstancesQuestion";
    Question["RobberyVandalismAuthorKnownQuestion"] = "RobberyVandalismAuthorKnownQuestion";
    Question["RobberyLostKeysLockSelectionQuestion"] = "RobberyLostKeysLockSelectionQuestion";
    Question["RobberyDuringAbsenceQuestion"] = "RobberyDuringAbsenceQuestion";
    Question["RobberyAbsenceDatesQuestion"] = "RobberyAbsenceDatesQuestion";
    Question["RobberyLeaveDateQuestion"] = "RobberyLeaveDateQuestion";
    Question["RobberyMethodQuestion"] = "RobberyMethodQuestion";
    Question["RobberyHouseSecuredQuestion"] = "RobberyHouseSecuredQuestion";
    Question["RobberyHouseSecuredYesNoQuestion"] = "RobberyHouseSecuredYesNoQuestion";
    Question["RobberyHouseIsEmergencyServiceNeededQuestion"] = "RobberyHouseIsEmergencyServiceNeededQuestion";
    Question["BreakInDamagesQuestion"] = "BreakInDamagesQuestion";
    Question["RobberyLocationQuestion"] = "RobberyLocationQuestion";
    Question["RobberyProtectionMeansQuestion"] = "RobberyProtectionMeansQuestion";
    Question["RobberyProtectionMeasuresQuestion"] = "RobberyProtectionMeasuresQuestion";
    Question["RobberyPersonalPropertyCountQuestion"] = "RobberyPersonalPropertyCountQuestion";
    Question["RobberyTypeQuestion"] = "RobberyTypeQuestion";
    Question["RobberyComplaintFiledIntroQuestion"] = "RobberyComplaintFiledIntroQuestion";
    Question["RobberyComplaintNotFiledQuestion"] = "RobberyComplaintNotFiledQuestion";
    Question["PersonalRobberyEventTypeQuestion"] = "PersonalRobberyEventTypeQuestion";
    Question["PersonalRobberyConcernedPersonQuestion"] = "PersonalRobberyConcernedPersonQuestion";
    Question["DamagesSynthesisQuestion"] = "DamagesSynthesisQuestion";
    Question["GlassBreakagePersonalPropertiesNonIncludedQuestion"] = "GlassBreakagePersonalPropertiesNonIncludedQuestion";
    Question["GlassBreakageDamageTypeQuestion"] = "GlassBreakageDamageTypeQuestion";
    Question["GlassBreakageSecurityQuestion"] = "GlassBreakageSecurityQuestion";
    Question["GlassBreakageSecurityAssistanceNeededQuestion"] = "GlassBreakageSecurityAssistanceNeededQuestion";
    Question["GlassBreakageRoomDamagesCauseQuestion"] = "GlassBreakageRoomDamagesCauseQuestion";
    Question["GlassBreakageRoomDamagesOriginQuestion"] = "GlassBreakageRoomDamagesOriginQuestion";
    Question["GlassBreakageRoomDamagesThirdPartyContactQuestion"] = "GlassBreakageRoomDamagesThirdPartyContactQuestion";
    Question["GlassBreakageRoomDamagesInstallationDateQuestion"] = "GlassBreakageRoomDamagesInstallationDateQuestion";
    Question["GlassBreakageRoomDamagesCountQuestion"] = "GlassBreakageRoomDamagesCountQuestion";
    Question["GlassBreakagePersonalPropertyDamagesOriginQuestion"] = "GlassBreakagePersonalPropertyDamagesOriginQuestion";
    Question["GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion"] = "GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion";
    Question["GlassBreakagePersonalPropertyDamagesCountQuestion"] = "GlassBreakagePersonalPropertyDamagesCountQuestion";
    Question["GlassDamagesQuestion"] = "GlassDamagesQuestion";
    Question["GlassDamagesReparationsQuestion"] = "GlassDamagesReparationsQuestion";
    Question["FireBeginQuestion"] = "FireBeginQuestion";
    Question["FireCauseLocationHouseQuestion"] = "FireCauseLocationHouseQuestion";
    Question["FireCauseLocalOrigin"] = "FireCauseLocalOrigin";
    Question["FireCauseExternOrigin"] = "FireCauseExternOrigin";
    Question["FireHasRealEstateDamagesQuestion"] = "FireHasRealEstateDamagesQuestion";
    Question["FireElectricalCause"] = "FireElectricalCause";
    Question["FireElectricalBoardAge"] = "FireElectricalBoardAge";
    Question["FireElectricalNetworkAge"] = "FireElectricalNetworkAge";
    Question["FireThirdPartyList"] = "FireThirdPartyList";
    Question["FireHasProIntervention"] = "FireHasProIntervention";
    Question["FireComplaintFiledQuestion"] = "FireComplaintFiledQuestion";
    Question["FireHasThirdPartyInfo"] = "FireHasThirdPartyInfo";
    Question["FireFireplaceCause"] = "FireFireplaceCause";
    Question["FireFireplaceAge"] = "FireFireplaceAge";
    Question["FireFireplaceWhoMakesWorks"] = "FireFireplaceWhoMakesWorks";
    Question["FireWhoMakesWorks"] = "FireWhoMakesWorks";
    Question["FireCauseCommonPartsOrigin"] = "FireCauseCommonPartsOrigin";
    Question["FireElectricalCauseCommonParts"] = "FireElectricalCauseCommonParts";
    Question["FireCauseNeighboorOrigin"] = "FireCauseNeighboorOrigin";
    Question["FireCauseVehicleThirdPartyContactQuestion"] = "FireCauseVehicleThirdPartyContactQuestion";
    Question["FirePersonalPropertyDescriptionQuestion"] = "FirePersonalPropertyDescriptionQuestion";
    Question["FireNumberOfExternalDamages"] = "FireNumberOfExternalDamages";
    Question["FireWhoIsResponsibleBarbecue"] = "FireWhoIsResponsibleBarbecue";
    Question["DocumentsSynthesisQuestion"] = "DocumentsSynthesisQuestion";
    Question["UnderConstructionQuestion"] = "UnderConstructionQuestion";
})(Question || (Question = {}));
