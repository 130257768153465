import { GARAGE_DOOR_TYPE_MODEL_LABELS, GARAGE_DOOR_TYPE_MODEL_CHOICES, GARAGE_DOOR_MATERIAL_MODEL_LABELS, GARAGE_DOOR_MATERIAL_MODEL_CHOICES, GARAGE_DOOR_DAMAGE_TYPE_MODEL_LABELS, GARAGE_DOOR_DAMAGE_TYPE_MODEL_CHOICES, GARAGE_DOOR_WIDTH_MODEL_LABELS, GARAGE_DOOR_WIDTH_MODEL_CHOICES, GARAGE_DOOR_OPENING_MODE_MODEL_LABELS, GARAGE_DOOR_OPENING_MODE_MODEL_CHOICES, GARAGE_DOOR_MATERIAL_LABELS, } from '../goodDamage';
import { translateChoices } from '@shared/choice';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { GarageDoorMaterial, GarageDoorDamageType, GarageDoorWidth, GarageDoorOpeningMode, } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform, optionalBooleanTransform, } from '@shared/utils/transform';
export function garageDoorTitle(model, translate) {
    if (!model.type || !model.doorType)
        return null;
    return translate('claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.title', undefined, {
        type: translate(GARAGE_DOOR_TYPE_MODEL_LABELS[model.doorType]).toLocaleLowerCase(),
    });
}
export function garageDoorDetails(model, translate) {
    if (!model.type || !model.doorType || !model.material)
        return null;
    return translate(GARAGE_DOOR_MATERIAL_LABELS[model.material]);
}
export function garageDoorFields(model, context) {
    const { translate, index } = context;
    const fields = [
        {
            label: translate('model.good_damage.garage_door.door_type'),
            value: model.doorType,
            displayedValue: optionalTranslatableEnumTransform(model.doorType, GARAGE_DOOR_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(GARAGE_DOOR_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.doorType`,
                toPayload: (doorType) => ({ doorType }),
            },
        },
        {
            label: translate('model.good_damage.garage_door.material'),
            value: model.material,
            displayedValue: optionalTranslatableEnumTransform(model.material, GARAGE_DOOR_MATERIAL_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GARAGE_DOOR_MATERIAL_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.material`,
                toPayload: (material) => ({
                    material,
                    damageType: material === GarageDoorMaterial.WOOD ? model.damageType : null,
                }),
            },
        },
    ];
    if (model.material === GarageDoorMaterial.WOOD) {
        fields.push({
            label: translate('model.good_damage.garage_door.damage_type'),
            value: model.damageType,
            displayedValue: optionalTranslatableEnumTransform(model.damageType, GARAGE_DOOR_DAMAGE_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GARAGE_DOOR_DAMAGE_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.damageType`,
                toPayload: (damageType) => ({
                    damageType,
                    doorWidth: damageType === GarageDoorDamageType.DAMAGED ? model.doorWidth : null,
                }),
            },
        });
    }
    if (model.material === GarageDoorMaterial.PVC ||
        model.material === GarageDoorMaterial.METAL ||
        model.damageType === GarageDoorDamageType.DAMAGED) {
        fields.push({
            label: translate('model.good_damage.garage_door.door_width'),
            value: model.doorWidth,
            displayedValue: optionalTranslatableEnumTransform(model.doorWidth, GARAGE_DOOR_WIDTH_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GARAGE_DOOR_WIDTH_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.doorWidth`,
                toPayload: (doorWidth) => ({
                    doorWidth,
                    openingMode: doorWidth === GarageDoorWidth.STANDARD ? model.openingMode : null,
                    isMotorWorking: doorWidth === GarageDoorWidth.STANDARD ? model.isMotorWorking : null,
                }),
            },
        });
    }
    if (model.damageType === GarageDoorDamageType.AESTHETIC ||
        model.doorWidth === GarageDoorWidth.STANDARD) {
        fields.push({
            label: translate('model.good_damage.garage_door.opening_mode'),
            value: model.openingMode,
            displayedValue: optionalTranslatableEnumTransform(model.openingMode, GARAGE_DOOR_OPENING_MODE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GARAGE_DOOR_OPENING_MODE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.openingMode`,
                toPayload: (openingMode) => ({
                    openingMode,
                    isMotorWorking: openingMode === GarageDoorOpeningMode.MOTORIZED ? model.isMotorWorking : null,
                }),
            },
        });
    }
    if (model.openingMode === GarageDoorOpeningMode.MOTORIZED) {
        fields.push({
            label: translate('model.good_damage.garage_door.is_motor_working'),
            value: model.isMotorWorking,
            displayedValue: optionalBooleanTransform(model.isMotorWorking, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(YES_OR_NO_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.isMotorWorking`,
                toPayload: (isMotorWorking) => ({ isMotorWorking }),
            },
        });
    }
    return fields;
}
