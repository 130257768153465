import { ModelFieldInputPreset, ModelFieldInputType } from '@shared/modelField/modelField';
import { formatOptionalLenght } from '@shared/utils/formatLength';
import { optionalBooleanTransform } from '@shared/utils/transform';
export const awningFields = (model, { translate }) => {
    return [
        {
            label: translate('model.external_damage.awning.is_opening_motorized'),
            value: model.isOpeningMotorized,
            displayedValue: optionalBooleanTransform(model.isOpeningMotorized, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isOpeningMotorized',
            },
        },
        {
            label: translate('model.external_damage.awning.width'),
            value: model.width,
            displayedValue: formatOptionalLenght(model.width),
            editable: {
                input: { type: ModelFieldInputType.Number },
                modelKey: 'width',
            },
        },
        {
            label: translate('model.external_damage.awning.overhang_length'),
            value: model.overhangLength,
            displayedValue: formatOptionalLenght(model.overhangLength),
            editable: {
                input: { type: ModelFieldInputType.Number },
                modelKey: 'overhangLength',
            },
        },
    ];
};
