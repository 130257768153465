import { objectToBooleanTranslatableChoices } from '@shared/choice';
import { ExternalDamageOrigin } from '@shared/types/api/externalDamage';
export const AWNING_MOTORIZATION_LABEL = 'claim.declaration.external_damages.form.awning.opening.title';
export const AWNING_DIMENSIONS_LABEL = 'claim.declaration.external_damages.form.awning.dimensions.title';
export const AWNING_WIDTH_LABEL = 'claim.declaration.external_damages.form.awning.dimensions.width.question';
export const AWNING_WIDTH_PLACEHOLDER = 'claim.declaration.external_damages.form.awning.dimensions.width.placeholder';
export const AWNING_OVERHANG_LENGTH_LABEL = 'claim.declaration.external_damages.form.awning.dimensions.length.question';
export const AWNING_OVERHANG_LENGTH_PLACEHOLDER = 'claim.declaration.external_damages.form.awning.dimensions.length.placeholder';
export const DEFAULT_AWNING_MOTORIZATION_TRAD_KEYS = {
    false: 'claim.declaration.external_damages.form.awning.opening.manual',
    true: 'claim.declaration.external_damages.form.awning.opening.motorized',
};
const STORM_AWNING_MOTORIZATION_TRAD_KEYS = {};
const SNOW_AWNING_MOTORIZATION_TRAD_KEYS = {};
const HAIL_AWNING_MOTORIZATION_TRAD_KEYS = {};
const FIRE_AWNING_MOTORIZATION_TRAD_KEYS = {};
export const AWNING_MOTORIZATION_TRAD_KEYS = {
    [ExternalDamageOrigin.Storm]: {
        ...DEFAULT_AWNING_MOTORIZATION_TRAD_KEYS,
        ...STORM_AWNING_MOTORIZATION_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Snow]: {
        ...DEFAULT_AWNING_MOTORIZATION_TRAD_KEYS,
        ...SNOW_AWNING_MOTORIZATION_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Hail]: {
        ...DEFAULT_AWNING_MOTORIZATION_TRAD_KEYS,
        ...HAIL_AWNING_MOTORIZATION_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Fire]: {
        ...DEFAULT_AWNING_MOTORIZATION_TRAD_KEYS,
        ...FIRE_AWNING_MOTORIZATION_TRAD_KEYS,
    },
};
export const AWNING_MOTORIZATION_CHOICES = {
    [ExternalDamageOrigin.Storm]: objectToBooleanTranslatableChoices(AWNING_MOTORIZATION_TRAD_KEYS[ExternalDamageOrigin.Storm]),
    [ExternalDamageOrigin.Snow]: objectToBooleanTranslatableChoices(AWNING_MOTORIZATION_TRAD_KEYS[ExternalDamageOrigin.Snow]),
    [ExternalDamageOrigin.Hail]: objectToBooleanTranslatableChoices(AWNING_MOTORIZATION_TRAD_KEYS[ExternalDamageOrigin.Hail]),
    [ExternalDamageOrigin.Fire]: objectToBooleanTranslatableChoices(AWNING_MOTORIZATION_TRAD_KEYS[ExternalDamageOrigin.Fire]),
};
