import { fileCollectionFilesToIri } from '@shared/files/uploadedFiles';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { optionalResourceIri } from '@shared/utils/iri';
const typeSerializers = {
    [ApiEntityType.UploadedAppFileCollection]: serializeUploadedAppFileCollection,
    [ApiEntityType.CoveringCategory]: optionalResourceIri,
    [ApiEntityType.PersonalPropertyCategory]: optionalResourceIri,
};
/**
 * These object keys will not be serialized as it is display information only
 */
const BANNED_KEYS = ['refund', 'appliedAllowances'];
export function serialize(resource) {
    if (resource === null || resource === undefined) {
        return resource;
    }
    if (resource instanceof Date) {
        return resource.toISOString();
    }
    if (Array.isArray(resource)) {
        return resource.map((obj) => serialize(obj));
    }
    if (typeof resource !== 'object') {
        // Scalar value
        return resource;
    }
    // Remove banned keys from object
    const clean = { ...resource };
    for (const bannedKey of BANNED_KEYS) {
        if (bannedKey in clean) {
            delete clean[bannedKey];
        }
    }
    // Use specific serializer for type if needed
    if ('@type' in resource && resource['@type'] in typeSerializers) {
        return typeSerializers[resource['@type']](clean);
    }
    const result = {};
    for (const key of Object.keys(clean)) {
        result[key] = serialize(clean[key]);
    }
    return result;
}
function serializeUploadedAppFileCollection(collection) {
    if (!('uploadedAppFiles' in collection)) {
        return collection;
    }
    return fileCollectionFilesToIri(collection);
}
