import { INSURER_CONFIG } from '@shared/insurer';
import { COLORS, DEFAULT_LAYOUT_COLOR as BASE_DEFAULT_LAYOUT_COLOR, WHITE_LAYOUT_COLOR as BASE_WHITE_LAYOUT_COLOR, CLAIM_TYPE_LAYOUT_COLORS as BASE_CLAIM_TYPE_LAYOUT_COLORS, } from '@shared/layout/layout';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
// Layout colors for declarations depends on insurer configuration
const insurerLayoutColors = INSURER_CONFIG.declaration.layout.colors;
/**
 * Layout colors can be overrided precisely.
 * For default use case, all colors can be derived from a few basics from "DeclarationLayoutColorConfig" type.
 */
function declarationLayoutColorFromConfig({ fullPageLayoutBackgroundColor, fullPageLayoutColor, entityCardHeaderBackgroundColor, entityCardHeaderColor, largeSplashScreenBackgroundColor, splashScreenBackgroundColor, splashScreenColor, questionsBackgroundColor, questionsCertificationBackgroundColor, questionCertificationMobileColor, questionsAsideColor, questionsAsideBackgroundColor, questionsAsideCertificationBackgroundColor, questionsStackBackgroundColor, progressBarBackgroundColor, progressBarIncompleteBackgroundColor, progressBarDotBackgroundColor, progressBarBackgroundColorMobile, progressBarIncompleteBackgroundColorMobile, progressBarDotBackgroundColorMobile, personalPropertyModalInitialCategorySelectionBackgroundColor, personalPropertyModalSearchBackgroundColor, personalPropertyModalFormBackgroundColor, compensationOptionsColor, modalHeaderBackgroundColor, modalHeaderColor, headerBackgroundColor, headerColor, mobileHeaderBackgroundColor, mobileHeaderColor, layoutBackgroundColor, calendarDropdownColor, breakInDamageModalFormBackgroundColor, checkoutColor, checkoutPlaceholder, background, backgroundLight, componentAndModalHeader, text, isBackgroundPale, modalContentBackgroundColor, modalContentColor, modalFooterBackgroundColor, modalFooterColor, }) {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        background,
        backgroundLight,
        componentAndModalHeader,
        headerBackgroundColor,
        text,
        fullPageLayoutBackgroundColor: fullPageLayoutBackgroundColor !== null && fullPageLayoutBackgroundColor !== void 0 ? fullPageLayoutBackgroundColor : background,
        fullPageLayoutColor: fullPageLayoutColor !== null && fullPageLayoutColor !== void 0 ? fullPageLayoutColor : text,
        entityCardHeaderBackgroundColor: entityCardHeaderBackgroundColor !== null && entityCardHeaderBackgroundColor !== void 0 ? entityCardHeaderBackgroundColor : background,
        entityCardHeaderColor: entityCardHeaderColor !== null && entityCardHeaderColor !== void 0 ? entityCardHeaderColor : text,
        largeSplashScreenBackgroundColor: largeSplashScreenBackgroundColor !== null && largeSplashScreenBackgroundColor !== void 0 ? largeSplashScreenBackgroundColor : background,
        splashScreenBackgroundColor: splashScreenBackgroundColor !== null && splashScreenBackgroundColor !== void 0 ? splashScreenBackgroundColor : background,
        splashScreenColor: splashScreenColor !== null && splashScreenColor !== void 0 ? splashScreenColor : text,
        questionsBackgroundColor: questionsBackgroundColor !== null && questionsBackgroundColor !== void 0 ? questionsBackgroundColor : backgroundLight,
        questionsCertificationBackgroundColor: questionsCertificationBackgroundColor !== null && questionsCertificationBackgroundColor !== void 0 ? questionsCertificationBackgroundColor : background,
        questionCertificationMobileColor: questionCertificationMobileColor !== null && questionCertificationMobileColor !== void 0 ? questionCertificationMobileColor : text,
        questionsAsideColor: questionsAsideColor !== null && questionsAsideColor !== void 0 ? questionsAsideColor : text,
        questionsAsideBackgroundColor: questionsAsideBackgroundColor !== null && questionsAsideBackgroundColor !== void 0 ? questionsAsideBackgroundColor : background,
        questionsAsideCertificationBackgroundColor: (_b = (_a = questionsAsideCertificationBackgroundColor !== null && questionsAsideCertificationBackgroundColor !== void 0 ? questionsAsideCertificationBackgroundColor : questionsCertificationBackgroundColor) !== null && _a !== void 0 ? _a : questionsAsideBackgroundColor) !== null && _b !== void 0 ? _b : background,
        questionsStackBackgroundColor: questionsStackBackgroundColor !== null && questionsStackBackgroundColor !== void 0 ? questionsStackBackgroundColor : backgroundLight,
        progressBarBackgroundColor: progressBarBackgroundColor !== null && progressBarBackgroundColor !== void 0 ? progressBarBackgroundColor : COLORS.white,
        progressBarIncompleteBackgroundColor: progressBarIncompleteBackgroundColor !== null && progressBarIncompleteBackgroundColor !== void 0 ? progressBarIncompleteBackgroundColor : COLORS.transparent.white['40'],
        progressBarDotBackgroundColor: progressBarDotBackgroundColor !== null && progressBarDotBackgroundColor !== void 0 ? progressBarDotBackgroundColor : background,
        progressBarBackgroundColorMobile: (_c = progressBarBackgroundColorMobile !== null && progressBarBackgroundColorMobile !== void 0 ? progressBarBackgroundColorMobile : progressBarBackgroundColor) !== null && _c !== void 0 ? _c : COLORS.white,
        progressBarIncompleteBackgroundColorMobile: (_d = progressBarIncompleteBackgroundColorMobile !== null && progressBarIncompleteBackgroundColorMobile !== void 0 ? progressBarIncompleteBackgroundColorMobile : progressBarIncompleteBackgroundColor) !== null && _d !== void 0 ? _d : COLORS.transparent.white['40'],
        progressBarDotBackgroundColorMobile: (_e = progressBarDotBackgroundColorMobile !== null && progressBarDotBackgroundColorMobile !== void 0 ? progressBarDotBackgroundColorMobile : progressBarDotBackgroundColor) !== null && _e !== void 0 ? _e : background,
        personalPropertyModalInitialCategorySelectionBackgroundColor: personalPropertyModalInitialCategorySelectionBackgroundColor !== null && personalPropertyModalInitialCategorySelectionBackgroundColor !== void 0 ? personalPropertyModalInitialCategorySelectionBackgroundColor : backgroundLight,
        personalPropertyModalSearchBackgroundColor: personalPropertyModalSearchBackgroundColor !== null && personalPropertyModalSearchBackgroundColor !== void 0 ? personalPropertyModalSearchBackgroundColor : COLORS.white,
        personalPropertyModalFormBackgroundColor: personalPropertyModalFormBackgroundColor !== null && personalPropertyModalFormBackgroundColor !== void 0 ? personalPropertyModalFormBackgroundColor : COLORS.grey['06'],
        compensationOptionsColor: compensationOptionsColor !== null && compensationOptionsColor !== void 0 ? compensationOptionsColor : text,
        modalHeaderBackgroundColor: modalHeaderBackgroundColor !== null && modalHeaderBackgroundColor !== void 0 ? modalHeaderBackgroundColor : headerBackgroundColor,
        modalHeaderColor: modalHeaderColor !== null && modalHeaderColor !== void 0 ? modalHeaderColor : text,
        modalContentBackgroundColor: modalContentBackgroundColor !== null && modalContentBackgroundColor !== void 0 ? modalContentBackgroundColor : backgroundLight,
        modalContentColor: modalContentColor !== null && modalContentColor !== void 0 ? modalContentColor : text,
        modalFooterBackgroundColor: (_f = modalFooterBackgroundColor !== null && modalFooterBackgroundColor !== void 0 ? modalFooterBackgroundColor : modalHeaderBackgroundColor) !== null && _f !== void 0 ? _f : headerBackgroundColor,
        modalFooterColor: (_g = modalFooterColor !== null && modalFooterColor !== void 0 ? modalFooterColor : modalHeaderColor) !== null && _g !== void 0 ? _g : text,
        headerColor: headerColor !== null && headerColor !== void 0 ? headerColor : text,
        mobileHeaderBackgroundColor: mobileHeaderBackgroundColor !== null && mobileHeaderBackgroundColor !== void 0 ? mobileHeaderBackgroundColor : headerBackgroundColor,
        mobileHeaderColor: mobileHeaderColor !== null && mobileHeaderColor !== void 0 ? mobileHeaderColor : text,
        layoutBackgroundColor: layoutBackgroundColor !== null && layoutBackgroundColor !== void 0 ? layoutBackgroundColor : background,
        calendarDropdownColor: calendarDropdownColor !== null && calendarDropdownColor !== void 0 ? calendarDropdownColor : text,
        breakInDamageModalFormBackgroundColor: breakInDamageModalFormBackgroundColor !== null && breakInDamageModalFormBackgroundColor !== void 0 ? breakInDamageModalFormBackgroundColor : backgroundLight,
        checkoutColor: checkoutColor !== null && checkoutColor !== void 0 ? checkoutColor : text,
        checkoutPlaceholder: checkoutPlaceholder !== null && checkoutPlaceholder !== void 0 ? checkoutPlaceholder : text,
        isBackgroundPale: isBackgroundPale !== null && isBackgroundPale !== void 0 ? isBackgroundPale : false,
    };
}
export const DEFAULT_LAYOUT_COLOR = declarationLayoutColorFromConfig({
    ...BASE_DEFAULT_LAYOUT_COLOR,
    ...insurerLayoutColors.default,
});
export const WHITE_LAYOUT_COLOR = declarationLayoutColorFromConfig({
    ...BASE_WHITE_LAYOUT_COLOR,
    ...insurerLayoutColors.white,
});
export const CLAIM_TYPE_LAYOUT_COLORS = Object.fromEntries(Object.values(ClaimTypeTrigram).map((trigram) => [
    trigram,
    declarationLayoutColorFromConfig({
        ...BASE_CLAIM_TYPE_LAYOUT_COLORS[trigram],
        ...insurerLayoutColors[trigram],
    }),
]));
export const CLAIM_TYPE_LIGHT_LAYOUT_COLORS = Object.fromEntries(Object.entries(CLAIM_TYPE_LAYOUT_COLORS).map(([trigram, layoutColor]) => [
    trigram,
    {
        ...layoutColor,
        background: layoutColor.backgroundLight,
        headerBackgroundColor: layoutColor.background,
    },
]));
// /!\ Keep in sync with $qualification-aside variable
export const QUESTIONS_ASIDE_WIDTH = 360;
