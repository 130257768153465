import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { optionalBooleanTransform, optionalTransform, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
import { ExternalDamageOrigin, VerandaPanelMaterial, VerandaPanelGlazingType, } from '@shared/types/api/externalDamage';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { VERANDA_GLASS_PANEL_GLAZING_CHOICES, VERANDA_GLASS_PANEL_GLAZING_TRAD_KEYS_AND_IMAGES, VERANDA_GUTTER_MATERIAL_CHOICES, VERANDA_GUTTER_MATERIAL_TRAD_KEYS, VERANDA_PANELS_MATERIAL_CHOICES, VERANDA_PANELS_MATERIAL_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/externalDamages/VerandaConstants';
import { translateChoices } from '@shared/choice';
import { formatOptionalLenght } from '@shared/utils/formatLength';
import { isApiResourceOfType } from '@shared/utils/isApiResource';
const panelsFromModel = (model) => {
    var _a, _b;
    return isApiResourceOfType(model, ApiEntityType.VerandaDamage)
        ? (_b = (_a = model.panels) === null || _a === void 0 ? void 0 : _a.slice()) !== null && _b !== void 0 ? _b : []
        : [];
};
export const verandaPanelFields = (model, index, panels, { translate }) => {
    const panel = model;
    const fields = [
        {
            label: 'Matériau',
            value: panel.material,
            displayedValue: optionalTranslatableEnumTransform(panel.material, VERANDA_PANELS_MATERIAL_TRAD_KEYS_AND_IMAGES, translate, '-'),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    vertical: true,
                    choices: translateChoices(VERANDA_PANELS_MATERIAL_CHOICES, translate),
                },
                modelKey: `panels.${index}.material`,
                toPayload: (material) => {
                    var _a, _b;
                    const payload = { material };
                    if (material === VerandaPanelMaterial.Glass) {
                        const glazingType = (_b = (_a = panels.find((panel) => panel.material === VerandaPanelMaterial.Glass && panel.glazingType)) === null || _a === void 0 ? void 0 : _a.glazingType) !== null && _b !== void 0 ? _b : null;
                        payload.glazingType = glazingType;
                    }
                    else
                        payload.glazingType = null;
                    if (material === VerandaPanelMaterial.Other) {
                        payload.width = null;
                        payload.height = null;
                    }
                    return payload;
                },
            },
        },
    ];
    if ([
        VerandaPanelMaterial.Glass,
        VerandaPanelMaterial.Polycarbonate,
        VerandaPanelMaterial.SandwichPanel,
    ].includes(panel.material)) {
        fields.push({
            label: 'Hauteur',
            value: panel.height ? panel.height / 10 : null,
            displayedValue: formatOptionalLenght(panel.height ? panel.height / 10 : null, 'cm', '-'),
            editable: {
                unit: 'cm',
                input: {
                    type: ModelFieldInputType.Number,
                },
                modelKey: `panels.${index}.height`,
                toPayload: (height) => {
                    return { height: height * 10 };
                },
            },
        }, {
            label: 'Largeur',
            value: panel.width ? panel.width / 10 : null,
            displayedValue: formatOptionalLenght(panel.width ? panel.width / 10 : null, 'cm', '-'),
            editable: {
                unit: 'cm',
                input: {
                    type: ModelFieldInputType.Number,
                },
                modelKey: `panels.${index}.width`,
                toPayload: (width) => {
                    return { width: width * 10 };
                },
            },
        });
    }
    return {
        title: `Panneau n°${index + 1}`,
        model,
        fields,
        editable: {
            toPayload: (value, model) => {
                const panels = panelsFromModel(model);
                const current = panels[index];
                panels.splice(index, 1, { ...current, ...value });
                return { panels };
            },
        },
    };
};
export const verandaFields = (model, context) => {
    var _a, _b, _c, _d;
    const { translate, stormSnowHailDamageCauseType, projection } = context;
    if (stormSnowHailDamageCauseType !== ExternalDamageOrigin.Hail)
        return [];
    const fields = [
        {
            label: 'Structure endommagée',
            value: model.structureDamaged,
            displayedValue: optionalBooleanTransform(model.structureDamaged, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'structureDamaged',
                toPayload: (structureDamaged) => {
                    const payload = { structureDamaged };
                    if (structureDamaged) {
                        payload.gutterDamaged = null;
                        payload.gutterMaterial = null;
                        payload.gutterLength = null;
                        payload.panels = [];
                    }
                    return payload;
                },
            },
        },
    ];
    if (model.structureDamaged !== false)
        return fields;
    const modelPanels = panelsFromModel(model);
    if (projection === ModelFieldsProjection.Backoffice) {
        const modelFields = modelPanels.map((panel, index, panels) => {
            return verandaPanelFields(panel, index, panels, context);
        });
        fields.push({
            label: 'Panneaux endommagés',
            emptyString: 'Auncun panneau endommagé',
            addLabel: 'Ajouter',
            modelFields,
            editable: {
                add: (model) => {
                    const panels = panelsFromModel(model);
                    panels.push({
                        '@type': ApiEntityType.VerandaDamagePanel,
                        material: null,
                        width: null,
                        height: null,
                        glazingType: null,
                    });
                    return { panels };
                },
                delete: (model, index) => {
                    const panels = panelsFromModel(model);
                    panels.splice(index, 1);
                    return { panels };
                },
                toPayload: (value, model, index, _context) => {
                    const panels = panelsFromModel(model);
                    const current = panels[index];
                    panels.splice(index, 1, { ...current, ...value });
                    return { panels };
                },
                modelKey: 'panels',
            },
        });
        const glassPanels = (_a = modelPanels === null || modelPanels === void 0 ? void 0 : modelPanels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.Glass)) !== null && _a !== void 0 ? _a : [];
        if (glassPanels.length) {
            const glassPanel = glassPanels[0];
            const index = (_b = modelPanels === null || modelPanels === void 0 ? void 0 : modelPanels.indexOf(glassPanel)) !== null && _b !== void 0 ? _b : -1;
            if (index > -1) {
                const glazingType = (_c = glassPanel === null || glassPanel === void 0 ? void 0 : glassPanel.glazingType) !== null && _c !== void 0 ? _c : null;
                fields.push({
                    label: 'Vitrage des panneaux en verre',
                    value: glazingType,
                    displayedValue: optionalTranslatableEnumTransform(glazingType, VERANDA_GLASS_PANEL_GLAZING_TRAD_KEYS_AND_IMAGES, translate),
                    editable: {
                        input: {
                            type: ModelFieldInputType.Radio,
                            vertical: true,
                            choices: translateChoices(VERANDA_GLASS_PANEL_GLAZING_CHOICES, translate),
                        },
                        modelKey: `panels.${index}.glazingType`,
                        toPayload: (glazingType) => {
                            return {
                                panels: modelPanels.map((panel) => {
                                    if (panel.material === VerandaPanelMaterial.Glass)
                                        return { ...panel, glazingType };
                                    return panel;
                                }),
                            };
                        },
                    },
                });
            }
        }
        fields.push({
            label: 'Goutière endommagée',
            value: model.gutterDamaged,
            displayedValue: optionalBooleanTransform(model.gutterDamaged, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'gutterDamaged',
                toPayload: (gutterDamaged) => {
                    const payload = { gutterDamaged };
                    if (gutterDamaged === false) {
                        payload.gutterMaterial = null;
                        payload.gutterLength = null;
                    }
                    return payload;
                },
            },
        });
        if (model.gutterDamaged === true) {
            fields.push({
                label: 'Matériau de la goutière',
                value: model.gutterMaterial,
                displayedValue: optionalTranslatableEnumTransform(model.gutterMaterial, VERANDA_GUTTER_MATERIAL_TRAD_KEYS, translate, '-'),
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        vertical: true,
                        choices: translateChoices(VERANDA_GUTTER_MATERIAL_CHOICES, translate),
                    },
                    modelKey: 'gutterMaterial',
                },
            }, {
                label: 'Longueur de la goutière',
                value: model.gutterLength ? model.gutterLength / 1000 : null,
                displayedValue: formatOptionalLenght(model.gutterLength ? model.gutterLength / 1000 : null, 'm', '-'),
                editable: {
                    unit: 'm',
                    input: {
                        type: ModelFieldInputType.Number,
                    },
                    modelKey: `gutterLength`,
                    toPayload: (gutterLength) => {
                        return { gutterLength: gutterLength * 1000 };
                    },
                },
            });
        }
        return fields;
    }
    const displayGutterMaterial = (material) => {
        return optionalTranslatableEnumTransform(material, VERANDA_GUTTER_MATERIAL_TRAD_KEYS, translate, '?');
    };
    const displayGutterLength = (length) => {
        return optionalTransform(length, (val) => parseFloat((val / 1000).toFixed(2)).toString() + 'm', '0m');
    };
    const displayGutter = ({ gutterMaterial, gutterLength }) => {
        const material = displayGutterMaterial(gutterMaterial);
        const length = displayGutterLength(gutterLength);
        return `${material} - ${length}`;
    };
    fields.push({
        label: 'Gouttière endommagée',
        displayedValue: model.gutterDamaged ? displayGutter(model) : translate('no'),
        key: 'gutterDamaged',
    });
    const glassPanels = modelPanels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.Glass);
    const polycarbonatePanels = modelPanels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.Polycarbonate);
    const sandwichPanels = modelPanels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.SandwichPanel);
    const otherPanels = modelPanels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.Other);
    const panelLabels = [];
    if (glassPanels === null || glassPanels === void 0 ? void 0 : glassPanels.length) {
        let label = translate(VERANDA_PANELS_MATERIAL_TRAD_KEYS_AND_IMAGES.glass.tradKey);
        const glazingType = (_d = glassPanels.find((panel) => panel === null || panel === void 0 ? void 0 : panel.glazingType)) === null || _d === void 0 ? void 0 : _d.glazingType;
        if (glazingType && glazingType !== VerandaPanelGlazingType.Unknown) {
            label += ` ${translate(`glazing.${glazingType}`).toLocaleLowerCase()}`;
        }
        panelLabels.push(`${label} ×${glassPanels.length}`);
    }
    if (polycarbonatePanels === null || polycarbonatePanels === void 0 ? void 0 : polycarbonatePanels.length) {
        const label = translate(VERANDA_PANELS_MATERIAL_TRAD_KEYS_AND_IMAGES.polycarbonate.tradKey);
        panelLabels.push(`${label} ×${polycarbonatePanels.length}`);
    }
    if (sandwichPanels === null || sandwichPanels === void 0 ? void 0 : sandwichPanels.length) {
        const label = translate(VERANDA_PANELS_MATERIAL_TRAD_KEYS_AND_IMAGES['sandwich-panel'].tradKey);
        panelLabels.push(`${label} ×${sandwichPanels.length}`);
    }
    if (otherPanels === null || otherPanels === void 0 ? void 0 : otherPanels.length) {
        const label = translate(VERANDA_PANELS_MATERIAL_TRAD_KEYS_AND_IMAGES.other.tradKey);
        panelLabels.push(`${label} ×${otherPanels.length}`);
    }
    fields.push({
        label: 'Panneaux endommagés',
        displayedValue: (panelLabels === null || panelLabels === void 0 ? void 0 : panelLabels.length) ? panelLabels.join(', ') : translate('no'),
        key: 'panels',
    });
    return fields;
};
