import Vue from 'vue';
import { uuidFromIri } from '@shared/utils/iri';
import appointmentConstraints from '@shared/validator/constraints/appointmentConstraints';
import { ValidationContext } from '@shared/validator/validationContext';
import { newValidator, revealViolations, touch, validate } from '@shared/validator/validator';
export const name = 'expertAppointment';
export const namespaced = true;
const newUserContact = () => ({
    email: null,
    phoneNumber: null,
    addressDetails: null,
});
export const state = () => {
    const userContact = newUserContact();
    const validator = newValidator(userContact);
    validate(validator, userContact, appointmentConstraints);
    return {
        isConfirmingAppointment: false,
        appointmentFindError: null,
        appointmentFindResult: null,
        selectedTimeSlot: null,
        userContact,
        validator: newValidator(userContact),
    };
};
export const getters = {
    validation(state) {
        return new ValidationContext(state.validator);
    },
    selectedAppointment(state) {
        var _a, _b;
        if (!state.selectedTimeSlot) {
            return null;
        }
        const appointments = (_a = state.appointmentFindResult) === null || _a === void 0 ? void 0 : _a.appointments;
        if (!appointments) {
            return null;
        }
        return (_b = appointments.timeslots.find(({ id }) => id === state.selectedTimeSlot)) !== null && _b !== void 0 ? _b : null;
    },
};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    updateContact({ commit }, payload) {
        commit('UPDATE_APPOINTMENT_CONTACT', payload);
    },
    validateContact({ commit }) {
        commit('VALIDATE_AND_REVEAL_CONTACT');
    },
    async fetchExpertAppointments({ commit, rootState }) {
        commit('RESET_STATE_WITHOUT_CONTACT');
        const claim = rootState.claim.claim;
        if (claim === null || !claim.id) {
            throw new Error('No claim for expert appointment fetch');
        }
        try {
            const appointments = await this.$axios.$post(`/expert-appointment/find-appointment/${uuidFromIri(claim.id)}`);
            commit('SET_APPOINTMENT_FIND_RESULT', appointments);
        }
        catch (err) {
            if (err instanceof Error) {
                commit('SET_APPOINTMENT_FIND_FAILED', err.message);
            }
        }
    },
    selectTimeSlot({ commit }, selectedTimeSlotId) {
        commit('SET_SELECTED_TIME_SLOT', selectedTimeSlotId);
    },
    async confirmAppointment({ rootState, getters, commit, state: { appointmentFindResult, userContact }, }) {
        if (!rootState.claim.claim.id || !appointmentFindResult) {
            return;
        }
        const timeSlot = getters.selectedAppointment;
        if (!timeSlot) {
            return;
        }
        const payload = {
            sessionID: appointmentFindResult.appointments.sessionID,
            userContact,
            timeslot: getters.selectedAppointment,
        };
        commit('SET_IS_CONFIRMING_APPOINTMENT', true);
        try {
            await this.$axios.$post(`/expert-appointment/book-timeslot/${uuidFromIri(rootState.claim.claim.id)}`, payload);
        }
        finally {
            commit('SET_IS_CONFIRMING_APPOINTMENT', false);
        }
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    RESET_STATE_WITHOUT_CONTACT(stateObject) {
        const userContact = stateObject.userContact;
        Object.assign(stateObject, state());
        Object.assign(stateObject.userContact, userContact);
    },
    SET_APPOINTMENT_FIND_RESULT(state, appointmentSearchResult) {
        state.appointmentFindResult = appointmentSearchResult;
    },
    SET_APPOINTMENT_FIND_FAILED(state, error) {
        state.appointmentFindError = error;
    },
    SET_SELECTED_TIME_SLOT(state, selectedTimeSlotId) {
        state.selectedTimeSlot = selectedTimeSlotId;
    },
    SET_IS_CONFIRMING_APPOINTMENT(state, isConfirmingAppointment) {
        state.isConfirmingAppointment = isConfirmingAppointment;
    },
    UPDATE_APPOINTMENT_CONTACT(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            Vue.set(state.userContact, key, value);
            touch(state.validator, key);
        });
        validate(state.validator, state.userContact, appointmentConstraints);
    },
    VALIDATE_AND_REVEAL_CONTACT(state) {
        validate(state.validator, state.userContact, appointmentConstraints);
        revealViolations(state.validator);
    },
};
