import { parseDimension, parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { ModelFieldInputType } from '@shared/modelField/modelField';
export function workplanDetails(model) {
    if (!model.type || !model.width || !model.height)
        return null;
    return `${parseDimension(model.width)} x ${parseDimension(model.height)}`;
}
export function workplanFields(model, context) {
    var _a, _b;
    const { translate, index } = context;
    return [
        {
            label: translate('model.good_damage.workplan.width'),
            value: ((_a = model.width) !== null && _a !== void 0 ? _a : 0) / 1000,
            displayedValue: parseOptionalDimension(model.width),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                label: translate('model.good_damage.workplan.width'),
                unit: 'm',
                modelKey: `goodDamages.${index}.width`,
                toPayload: (width) => ({ width: Math.round(width * 1000) }),
            },
        },
        {
            label: translate('model.good_damage.workplan.height'),
            value: ((_b = model.height) !== null && _b !== void 0 ? _b : 0) / 1000,
            displayedValue: parseOptionalDimension(model.height),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                label: translate('model.good_damage.workplan.height'),
                unit: 'm',
                modelKey: `goodDamages.${index}.height`,
                toPayload: (height) => ({ height: Math.round(height * 1000) }),
            },
        },
    ];
}
