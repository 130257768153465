import { EMPTY } from '@shared/utils/strings';
export const parseAmount = (price) => {
    if (!price)
        return 0;
    return parseFloat(price);
};
export const parseLength = (length) => {
    if (!length)
        return 0;
    return parseFloat(length);
};
export const parseAge = (age) => {
    if (!age)
        return 0;
    return parseInt(age) || 0;
};
export const parseDimension = (length) => {
    if (length % 10 !== 0 && length < 1000)
        return `${length} mm`;
    if (length % 1000 !== 0 && length < 10000)
        return `${parseFloat((length / 10).toFixed(2))} cm`;
    return `${parseFloat((length / 1000).toFixed(2))} m`;
};
export const parseOptionalDimension = (length, defaultStringValue = EMPTY) => {
    return typeof length === 'number' ? parseDimension(length) : defaultStringValue;
};
