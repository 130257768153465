import get from 'lodash/get';
import set from 'lodash/set';
import { damageChangesToEntityChanges, entityChangesToPayload, isCreateChangeKey, } from '@shared/claim/entities';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { enumToArray } from '@shared/utils/enum';
import { PersonalPropertyType } from '@shared/types/api/personalProperty';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
export var EntityChangeType;
(function (EntityChangeType) {
    EntityChangeType["ThirdParty"] = "thirdParties";
    EntityChangeType["Receipt"] = "receipts";
    EntityChangeType["PersonalProperty"] = "personalProperties";
    EntityChangeType["RoomDamage"] = "roomDamages";
    EntityChangeType["BreakInDamage"] = "breakInDamages";
    EntityChangeType["GlassDamage"] = "glassDamages";
    EntityChangeType["ElectricalDamage"] = "electricalDamages";
    EntityChangeType["ExternalDamage"] = "externalDamages";
    EntityChangeType["Equipment"] = "equipment";
    EntityChangeType["Charge"] = "charges";
})(EntityChangeType || (EntityChangeType = {}));
export const ENTITY_CHANGE_TYPES = enumToArray(EntityChangeType);
export const ENTITY_CHANGES_CLAIM_PROPERTY = {
    [EntityChangeType.ThirdParty]: 'thirdParties',
    [EntityChangeType.Receipt]: 'counting.receipts',
    [EntityChangeType.PersonalProperty]: 'counting.personalProperties',
    [EntityChangeType.RoomDamage]: 'counting.roomDamages',
    [EntityChangeType.BreakInDamage]: 'counting.breakInDamages',
    [EntityChangeType.GlassDamage]: 'counting.glassDamages',
    [EntityChangeType.ElectricalDamage]: 'counting.electricalDamages',
    [EntityChangeType.ExternalDamage]: 'counting.externalDamages',
    [EntityChangeType.Equipment]: 'counting.equipments',
    [EntityChangeType.Charge]: 'counting.charges',
};
export function newEntitiesChanges() {
    return {
        [EntityChangeType.ThirdParty]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.Receipt]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.PersonalProperty]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.RoomDamage]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.BreakInDamage]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.ExternalDamage]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.GlassDamage]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.ElectricalDamage]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.Equipment]: {
            create: [],
            update: {},
            delete: [],
        },
        [EntityChangeType.Charge]: {
            create: [],
            update: {},
            delete: [],
        },
    };
}
export function entityChangeByChangeKey(changes, changeKey) {
    var _a, _b;
    if (isCreateChangeKey(changeKey)) {
        return (_a = changes.create[changeKey !== null && changeKey !== void 0 ? changeKey : 0]) !== null && _a !== void 0 ? _a : null;
    }
    return (_b = changes.update[changeKey]) !== null && _b !== void 0 ? _b : null;
}
function entityHasChanges(type, changes) {
    const actions = changes[type];
    return (actions.create.length > 0 || actions.delete.length > 0 || Object.keys(actions.update).length > 0);
}
export function entitiesChangesAreEmpty(changes) {
    return ENTITY_CHANGE_TYPES.every((type) => !entityHasChanges(type, changes));
}
export function getEntitiesForType(type, claim) {
    return get(claim, ENTITY_CHANGES_CLAIM_PROPERTY[type], []);
}
export function entitiesChangesToPayloads(changes, info) {
    const payload = {};
    for (const type of ENTITY_CHANGE_TYPES) {
        if (!entityHasChanges(type, changes)) {
            continue;
        }
        // Need to keep counting IRI in payload to not recreate counting
        // on changes on entities
        if (!payload.counting || !payload.counting.id) {
            set(payload, 'counting.id', info.claim.counting.id);
        }
        const entityChanges = damageChangesToEntityChanges(changes[type]);
        const property = ENTITY_CHANGES_CLAIM_PROPERTY[type];
        const entityPayload = entityChangesToPayload(getEntitiesForType(type, info.claim), entityChanges);
        set(payload, property, entityPayload);
    }
    return payload;
}
export const ENTITY_TYPE_FOR_CHANGE_TYPE = {
    [EntityChangeType.RoomDamage]: ApiEntityType.RoomDamage,
    [EntityChangeType.ExternalDamage]: ApiEntityType.ExternalDamage,
    [EntityChangeType.BreakInDamage]: ApiEntityType.BreakInDamage,
    [EntityChangeType.GlassDamage]: ApiEntityType.GlassDamage,
    [EntityChangeType.ElectricalDamage]: ApiEntityType.ElectricalDamage,
    [EntityChangeType.Equipment]: ApiEntityType.Equipment,
    [EntityChangeType.PersonalProperty]: ApiEntityType.PersonalProperty,
    [EntityChangeType.ThirdParty]: ApiEntityType.ThirdParty,
    [EntityChangeType.Receipt]: ApiEntityType.Receipt,
    [EntityChangeType.Charge]: ApiEntityType.Charge,
};
export function newEntityForChangeType(type, claim) {
    var _a;
    const entity = {
        '@type': ENTITY_TYPE_FOR_CHANGE_TYPE[type],
        clientIdentifier: newClientIdentifier(),
    };
    const data = {};
    switch (type) {
        case EntityChangeType.PersonalProperty:
            switch ((_a = claim === null || claim === void 0 ? void 0 : claim.type) === null || _a === void 0 ? void 0 : _a.trigram) {
                case ClaimTypeTrigram.Glass:
                    data.type = PersonalPropertyType.GlassBreakage;
                    break;
                default:
                    data.type = PersonalPropertyType.Default;
                    break;
            }
            break;
        case EntityChangeType.Charge:
            data.label = '';
            break;
        case EntityChangeType.ElectricalDamage:
            data.repairable = false;
            data.purchaseInfo = false;
            data.purchaseCertification = false;
            break;
    }
    return { ...entity, ...data };
}
