import { SURFACE_TYPE_CHOICES, SURFACE_TYPE_LABELS } from '@shared/roomDamage/surfaceDamage';
import { translateChoices } from '@shared/choice';
import { SURFACE_DAMAGE_VETUSTY_CHOICES, SURFACE_DAMAGE_VETUSTY_TRAD_KEYS, } from '@shared/constants/roomDamageConstants';
import { ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { optionalResourceIri } from '@shared/utils/iri';
import { isNotNull } from '@shared/utils/isNotNull';
import { EMPTY } from '@shared/utils/strings';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
import { optionalAllowancesToModelFields } from '@shared/types/api/optionalAllowance';
import { validateRole } from '@backoffice/types/security';
import { UserRole } from '@shared/types/api/user';
export const surfaceDamageFields = (model, context) => {
    var _a, _b, _c;
    const { coveringCategoryLabels, coveringCategoriesBySurfaceType, translate, index, edition, availableSurfaceOptions, } = context;
    const title = optionalTranslatableEnumTransform(model.type, SURFACE_TYPE_LABELS, translate);
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.surface_damage.type'),
            value: model.type,
            displayedValue: optionalTranslatableEnumTransform(model.type, SURFACE_TYPE_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(SURFACE_TYPE_CHOICES, translate),
                },
                modelKey: `surfaceDamages.${index}.type`,
                toPayload: (type) => ({ type, coveringCategoryIri: null }),
            },
        });
    }
    const coveringCategoryIri = optionalResourceIri(model.coveringCategory);
    const coveringCategoryField = {
        label: translate('model.surface_damage.covering'),
        value: coveringCategoryIri,
        key: `surfaceDamages.${index}.coveringCategory`,
        displayedValue: coveringCategoryIri ? coveringCategoryLabels[coveringCategoryIri] : EMPTY,
    };
    // Only edit categories if model has a type
    if (edition) {
        if (model.type) {
            const coveringCategoriesChoices = model.type
                ? coveringCategoriesBySurfaceType[model.type].map(({ id, label }) => ({ value: id, label }))
                : [];
            coveringCategoryField.editable = {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: coveringCategoriesChoices,
                },
                modelKey: `surfaceDamages.${index}.coveringCategory`,
                toPayload: (coveringCategory) => ({ coveringCategory }),
            };
            fields.push(coveringCategoryField);
        }
    }
    else {
        fields.push(coveringCategoryField);
    }
    fields.push({
        label: translate('model.surface_damage.age'),
        value: model.age,
        displayedValue: optionalTranslatableEnumTransform(model.age, SURFACE_DAMAGE_VETUSTY_TRAD_KEYS, translate),
        editable: {
            input: {
                type: ModelFieldInputType.Radio,
                choices: translateChoices(SURFACE_DAMAGE_VETUSTY_CHOICES, translate),
                castTo: 'number',
            },
            modelKey: `surfaceDamages.${index}.age`,
            toPayload: (age) => ({ age }),
        },
    });
    const coveringCategoryId = optionalResourceIri(model.coveringCategory);
    const availableOptions = coveringCategoryId
        ? (_a = availableSurfaceOptions === null || availableSurfaceOptions === void 0 ? void 0 : availableSurfaceOptions[coveringCategoryId]) !== null && _a !== void 0 ? _a : []
        : [];
    if (context.projection === ModelFieldsProjection.Backoffice &&
        validateRole((_c = (_b = context.user) === null || _b === void 0 ? void 0 : _b.roles) !== null && _c !== void 0 ? _c : [], UserRole.VALUATION_OPTIONS)) {
        fields.push(...optionalAllowancesToModelFields(availableOptions, model, context, true));
    }
    const toPayload = (value, model) => {
        var _a;
        const surfaceDamages = [
            ...((_a = model.surfaceDamages) !== null && _a !== void 0 ? _a : []),
        ].filter(isNotNull);
        const current = surfaceDamages[index];
        if (typeof current === 'string') {
            surfaceDamages[index] = value;
        }
        else {
            surfaceDamages[index] = { ...current, ...value };
        }
        return {
            surfaceDamages,
        };
    };
    return { title, fields, model, editable: { toPayload } };
};
