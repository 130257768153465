import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { damageSeverityField, dimensionsFields, editionMaterialField, impactsNumberField, otherDamagesFields, } from '@shared/breakInDamage/breakInDamageFields';
import { translatableObjectToChoices, translateChoices } from '@shared/choice';
import { GARAGE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES, GARAGE_DOOR_WIDTH_CHOICES, } from '@shared/constants/breakInDamages/GarageDoorConstants';
import { DamageSeverity, ImpactsNumber, Material } from '@shared/types/api/breakInDamage';
import { GARAGE_DOOR_MOTORIZATION_CHOICES } from '@declaration/constants/externalDamages';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <GarageDoor>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function garageDoorBreakInDamageEditionFields(model, translate, projection, edition = false) {
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.break_in_damage.garage_door.type'),
            key: 'garageDoorType',
            value: model.garageDoorType,
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(GARAGE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'garageDoorType',
            },
        });
        fields.push(editionMaterialField(model, translate));
    }
    fields.push(damageSeverityField(model, translate));
    if (model.damageSeverity === DamageSeverity.Critical) {
        if (edition) {
            fields.push(...garageDoorBreakInDamageCriticalSeverityFields(model, translate, edition));
        }
    }
    else if (model.damageSeverity === DamageSeverity.Notable) {
        fields.push(impactsNumberField(model, translate));
        if (edition) {
            if (model.material === Material.PVC &&
                model.numberOfImpacts === ImpactsNumber.MoreThanSeven) {
                fields.push(...garageDoorBreakInDamageCriticalSeverityFields(model, translate, edition));
            }
            else if (model.damageSeverity === DamageSeverity.Notable) {
                fields.push({
                    label: translate('model.break_in_damage.garage_door.functional'),
                    key: 'garageDoorIsFunctional',
                    value: model.garageDoorIsFunctional,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'garageDoorIsFunctional',
                        toPayload(garageDoorIsFunctional) {
                            if (!garageDoorIsFunctional) {
                                return { garageDoorIsFunctional, mustBeReplaced: true };
                            }
                            return { garageDoorIsFunctional, mustBeReplaced: null };
                        },
                    },
                });
                if (!model.garageDoorIsFunctional) {
                    fields.push(...garageDoorBreakInDamageCriticalSeverityFields(model, translate, edition));
                }
                else {
                    fields.push({
                        label: translate('model.break_in_damage.garage_door.is_bent'),
                        key: 'garageDoorIsBent',
                        value: model.garageDoorIsBent,
                        editable: {
                            input: ModelFieldInputPreset.boolean(translate),
                            modelKey: 'garageDoorIsBent',
                            toPayload(garageDoorIsBent) {
                                if (garageDoorIsBent) {
                                    return { garageDoorIsBent, mustBeReplaced: true };
                                }
                                return { garageDoorIsBent, mustBeReplaced: null };
                            },
                        },
                    });
                    if (model.garageDoorIsBent) {
                        fields.push(...garageDoorBreakInDamageCriticalSeverityFields(model, translate, edition));
                    }
                }
            }
        }
        if (projection !== ModelFieldsProjection.Declaration &&
            model.damageSeverity === DamageSeverity.Notable &&
            !model.garageDoorIsBent &&
            model.garageDoorIsFunctional) {
            fields.push(...otherDamagesFields(model, translate));
        }
    }
    // We check the conditions for critical severity again but this time also checking if we're in
    // readonly mode because in this mode we want the dimensions fields to appear at the end
    if (!edition &&
        (model.damageSeverity === DamageSeverity.Critical ||
            (model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
            !model.garageDoorIsFunctional ||
            model.garageDoorIsBent)) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function garageDoorBreakInDamageCriticalSeverityFields(model, translate, edition = false) {
    return [
        {
            label: translate(edition
                ? 'model.break_in_damage.garage_door.opening'
                : 'claim.declaration.external_damages.form.garage_door.opening.title'),
            key: 'garageDoorIsMotorized',
            value: model.garageDoorIsMotorized,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GARAGE_DOOR_MOTORIZATION_CHOICES, translate),
                },
                modelKey: 'garageDoorIsMotorized',
            },
        },
        {
            label: translate('model.break_in_damage.garage_door.width'),
            key: 'garageDoorWidth',
            value: model.garageDoorWidth,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GARAGE_DOOR_WIDTH_CHOICES, translate),
                },
                modelKey: 'garageDoorWidth',
            },
        },
    ];
}
