import { BasePersonalPropertyInitialCategorySlug, BDGPersonalPropertyInitialCategorySlug, BDGPersonalPropertyReparationBy, IdentityDocumentType, INCPersonalPropertyInitialCategorySlug, PersonalPropertyReceiptType, } from '@shared/types/api/personalProperty';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
export const BASE_INITIAL_PERSONAL_PROPERTY_CATEGORIES = {
    [BasePersonalPropertyInitialCategorySlug.Multimedia]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/laptop.svg'),
        labelTradKey: 'claim.personal_property.initial_category.electronic',
        isProductSearchAvailable: true,
        isCategoryModifiable: insurerHasFeature(InsurerFeatureFlag.MultimediaModifiableCategory),
    },
    [BasePersonalPropertyInitialCategorySlug.BijouxObjetsDeValeur]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/jewels.svg'),
        labelTradKey: 'claim.personal_property.initial_category.jewels',
        isProductSearchAvailable: false,
        isCategoryModifiable: false,
    },
    [BasePersonalPropertyInitialCategorySlug.VetementsEtAccessoires]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/coat.svg'),
        labelTradKey: 'claim.personal_property.initial_category.clothes_and_accessories',
        isProductSearchAvailable: false,
        isCategoryModifiable: false,
    },
    [BasePersonalPropertyInitialCategorySlug.Autres]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/more.svg'),
        labelTradKey: 'claim.personal_property.initial_category.other',
        isProductSearchAvailable: false,
        isCategoryModifiable: true,
    },
};
export const BDG_INITIAL_PERSONAL_PROPERTY_CATEGORIES = {
    [BDGPersonalPropertyInitialCategorySlug.InsertDeCheminee]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/insert-cheminee.svg'),
        labelTradKey: 'claim.personal_property.initial_category.fireplace-insert',
        isProductSearchAvailable: false,
        isCategoryModifiable: false,
    },
    [BDGPersonalPropertyInitialCategorySlug.Miroir]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/miroir.svg'),
        labelTradKey: 'claim.personal_property.initial_category.mirror',
        isProductSearchAvailable: false,
        isCategoryModifiable: false,
    },
    [BDGPersonalPropertyInitialCategorySlug.Meuble]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/meuble.svg'),
        labelTradKey: 'claim.personal_property.initial_category.furniture',
        isProductSearchAvailable: false,
        isCategoryModifiable: false,
    },
    [BDGPersonalPropertyInitialCategorySlug.ElectromenagerOuMultimedia]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/electromenager-ou-multimedia.svg'),
        labelTradKey: 'claim.personal_property.initial_category.appliance-or-multimedia',
        isProductSearchAvailable: true,
        isCategoryModifiable: false,
    },
};
export const INC_INITIAL_PERSONAL_PROPERTY_CATEGORIES = {
    [INCPersonalPropertyInitialCategorySlug.ElectromenagerOuMultimedia]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/electromenager-ou-multimedia.svg'),
        labelTradKey: 'claim.personal_property.initial_category.appliance-or-multimedia',
        isProductSearchAvailable: true,
        isCategoryModifiable: false,
    },
    [INCPersonalPropertyInitialCategorySlug.VetementsEtAccessoires]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/coat.svg'),
        labelTradKey: 'claim.personal_property.initial_category.clothes_and_accessories',
        isProductSearchAvailable: false,
        isCategoryModifiable: false,
    },
    [INCPersonalPropertyInitialCategorySlug.Meuble]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/meuble.svg'),
        labelTradKey: 'claim.personal_property.initial_category.furniture',
        isProductSearchAvailable: false,
        isCategoryModifiable: false,
    },
    [INCPersonalPropertyInitialCategorySlug.Autres]: {
        imageUrl: require('@declaration/assets/images/personalProperty/categories/more.svg'),
        labelTradKey: 'claim.personal_property.initial_category.other',
        isProductSearchAvailable: false,
        isCategoryModifiable: true,
    },
};
export const INITIAL_PERSONAL_PROPERTY_CATEGORIES = {
    ...BASE_INITIAL_PERSONAL_PROPERTY_CATEGORIES,
    ...BDG_INITIAL_PERSONAL_PROPERTY_CATEGORIES,
    ...INC_INITIAL_PERSONAL_PROPERTY_CATEGORIES,
};
export const BDG_PERSONAL_PROPERTY_REPARATION_BY_TRAD_KEY = {
    [BDGPersonalPropertyReparationBy.Myself]: 'claim.damage.personal_property.BDG.form.reparation-by.myself',
    [BDGPersonalPropertyReparationBy.Professional]: 'claim.damage.personal_property.BDG.form.reparation-by.professional',
    [BDGPersonalPropertyReparationBy.Assistance]: 'claim.damage.personal_property.BDG.form.reparation-by.assistance',
};
export const PERSONAL_PROPERTY_RECEIPT_TYPES_TRAD_KEY = {
    [PersonalPropertyReceiptType.None]: 'claim.personal_property.receipt.type.none',
    [PersonalPropertyReceiptType.TypeInvoice]: 'claim.personal_property.receipt.type.invoice',
    [PersonalPropertyReceiptType.TypeCashierReceipt]: 'claim.personal_property.receipt.type.cashier_receipt',
    [PersonalPropertyReceiptType.CashWithdrawal]: 'claim.personal_property.receipt.type.cash_withdrawal',
    [PersonalPropertyReceiptType.Pictures]: 'claim.personal_property.receipt.type.pictures',
    [PersonalPropertyReceiptType.TypeRepairInvoice]: 'claim.personal_property.receipt.type.repair_invoice',
    [PersonalPropertyReceiptType.TypeValuation]: 'claim.personal_property.receipt.type.valuation',
    [PersonalPropertyReceiptType.TypeOther]: 'claim.personal_property.receipt.type.other',
};
export const PERSONAL_PROPERTY_RECEIPT_TYPES = Object.entries(PERSONAL_PROPERTY_RECEIPT_TYPES_TRAD_KEY).map(([type, tradKey]) => ({
    tradKey,
    value: type,
}));
export const PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY = {
    [IdentityDocumentType.NationalIdentityCard]: 'claim.personal_property.identity_document.national_identity_card',
    [IdentityDocumentType.DrivingLicence]: 'claim.personal_property.identity_document.driving_licence',
    [IdentityDocumentType.Passport]: 'claim.personal_property.identity_document.passport',
};
export const PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES = Object.entries(PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY).map(([type, tradKey]) => ({
    tradKey,
    value: type,
}));
