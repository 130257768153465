import { objectToTranslatableChoices, } from '@shared/choice';
import { ShutterMaterial, ShutterType } from '@shared/types/api/externalDamage';
export const SHUTTER_TYPE_IMAGES_AND_TRAD_KEYS = {
    [ShutterType.Flappy]: {
        image: require('@declaration/assets/images/externalDamage/shutters/battants.svg'),
        tradKey: 'claim.declaration.external_damages.form.shutters.type.flappy',
        sumupTradKey: 'claim.declaration.external_damages.form.shutters.type.flappy.sumup',
    },
    [ShutterType.Rolling]: {
        image: require('@declaration/assets/images/externalDamage/shutters/roulants.svg'),
        tradKey: 'claim.declaration.external_damages.form.shutters.type.rolling',
        sumupTradKey: 'claim.declaration.external_damages.form.shutters.type.rolling.sumup',
    },
    [ShutterType.Folding]: {
        image: require('@declaration/assets/images/externalDamage/shutters/pliants.svg'),
        tradKey: 'claim.declaration.external_damages.form.shutters.type.folding',
        sumupTradKey: 'claim.declaration.external_damages.form.shutters.type.folding.sumup',
    },
};
export const SHUTTER_MATERIAL_TRAD_KEYS = {
    [ShutterMaterial.Wood]: 'claim.declaration.external_damages.form.shutters.material.wood',
    [ShutterMaterial.Metal]: 'claim.declaration.external_damages.form.shutters.material.metal',
    [ShutterMaterial.PVC]: 'claim.declaration.external_damages.form.shutters.material.pvc',
    [ShutterMaterial.Alumninium]: 'claim.declaration.external_damages.form.shutters.material.alumninium',
};
export const SHUTTER_MATERIAL_CHOICES = objectToTranslatableChoices(SHUTTER_MATERIAL_TRAD_KEYS);
export const SHUTTERS_MOTORIZATION_CHOICES = [
    {
        value: false,
        tradKey: 'claim.declaration.external_damages.form.shutters.opening.manual',
    },
    {
        value: true,
        tradKey: 'claim.declaration.external_damages.form.shutters.opening.motorized',
    },
];
