import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { damageSeverityField, dimensionsFields, editionMaterialField, impactsNumberField, otherDamagesFields, } from '@shared/breakInDamage/breakInDamageFields';
import { objectToTranslatableChoices, translatableObjectToChoices, translateChoices, } from '@shared/choice';
import { DOOR_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, DOOR_LOCK_DAMAGES, DOOR_LOCK_TYPE_TRAD_KEYS_AND_IMAGES, DOOR_TYPE_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/breakInDamages/DoorConstants';
import { DamageSeverity, DoorType, ImpactsNumber, Material } from '@shared/types/api/breakInDamage';
import { GLASS_TYPE_TRAD_KEYS_AND_IMAGES, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/breakInDamageConstants';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <Door>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function doorBreakInDamageEditionFields(model, translate, projection, edition = false) {
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.break_in_damage.door_type'),
            key: 'doorType',
            value: model.doorType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(objectToTranslatableChoices(DOOR_TYPE_TRAD_KEYS_AND_IMAGES), translate),
                    vertical: true,
                },
                modelKey: 'doorType',
                toPayload(doorType) {
                    if (doorType === DoorType.Indoor) {
                        return { doorType, mustBeReplaced: true };
                    }
                    return { doorType, mustBeReplaced: null };
                },
            },
        });
        if (model.doorType === DoorType.Indoor) {
            return fields;
        }
        else {
            fields.push({
                label: translate('model.break_in_damage.leaves_type'),
                key: 'doorLeavesType',
                value: model.doorLeavesType,
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translatableObjectToChoices(DOOR_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                    },
                    modelKey: 'doorLeavesType',
                },
            });
            fields.push(editionMaterialField(model, translate));
        }
    }
    fields.push(damageSeverityField(model, translate));
    if (model.damageSeverity === DamageSeverity.Critical) {
        if (edition) {
            fields.push(...doorBreakInDamageCriticalSeverityFields(model, translate));
        }
    }
    else if (model.damageSeverity === DamageSeverity.Notable) {
        fields.push(impactsNumberField(model, translate));
        if (edition) {
            if ((model.numberOfImpacts === ImpactsNumber.MoreThanThree &&
                model.material === Material.Metal) ||
                (model.numberOfImpacts === ImpactsNumber.MoreThanSeven && model.material === Material.PVC)) {
                fields.push(...doorBreakInDamageCriticalSeverityFields(model, translate));
            }
            else {
                fields.push({
                    label: translate('model.break_in_damage.door.has_window'),
                    key: 'doorHasWindow',
                    value: model.doorHasWindow,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'doorHasWindow',
                    },
                });
                if (model.doorHasWindow) {
                    fields.push({
                        label: translate('model.break_in_damage.broken_window'),
                        key: 'doorHasBrokenWindowTiles',
                        value: model.doorHasBrokenWindowTiles,
                        editable: {
                            input: ModelFieldInputPreset.boolean(translate),
                            modelKey: 'doorHasBrokenWindowTiles',
                        },
                    });
                    if (model.doorHasBrokenWindowTiles) {
                        fields.push({
                            label: translate('model.break_in_damage.broken_tiles_number'),
                            key: 'doorNumberOfBrokenWindowTiles',
                            value: model.doorNumberOfBrokenWindowTiles,
                            editable: {
                                input: {
                                    type: ModelFieldInputType.Number,
                                },
                                modelKey: 'doorNumberOfBrokenWindowTiles',
                            },
                        }, {
                            label: translate('model.break_in_damage.broken_window.height'),
                            key: 'doorBiggestBrokenWindowTileHeight',
                            value: model.doorBiggestBrokenWindowTileHeight,
                            editable: {
                                input: {
                                    type: ModelFieldInputType.Number,
                                },
                                modelKey: 'doorBiggestBrokenWindowTileHeight',
                            },
                        }, {
                            label: translate('model.break_in_damage.broken_window.width'),
                            key: 'doorBiggestBrokenWindowTileWidth',
                            value: model.doorBiggestBrokenWindowTileWidth,
                            editable: {
                                input: {
                                    type: ModelFieldInputType.Number,
                                },
                                modelKey: 'doorBiggestBrokenWindowTileWidth',
                            },
                        }, {
                            label: translate('model.break_in_damage.glazing_type'),
                            key: 'doorWindowGlazingType',
                            value: model.doorWindowGlazingType,
                            editable: {
                                input: {
                                    type: ModelFieldInputType.Radio,
                                    choices: translatableObjectToChoices(GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                                    vertical: true,
                                },
                                modelKey: 'doorWindowGlazingType',
                            },
                        }, {
                            label: translate('model.break_in_damage.glass_type'),
                            key: 'doorWindowGlassType',
                            value: model.doorWindowGlassType,
                            editable: {
                                input: {
                                    type: ModelFieldInputType.Radio,
                                    choices: translatableObjectToChoices(GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                                    vertical: true,
                                },
                                modelKey: 'doorWindowGlassType',
                            },
                        });
                    }
                }
                fields.push({
                    label: translate('model.break_in_damage.lock_damage'),
                    key: 'doorHasLockDamage',
                    value: model.doorHasLockDamage,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'doorHasLockDamage',
                    },
                });
                if (model.doorHasLockDamage) {
                    fields.push({
                        label: translate('model.break_in_damage.door.lock_damages'),
                        key: 'doorLockDamage',
                        value: model.doorLockDamage,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Radio,
                                choices: translateChoices(DOOR_LOCK_DAMAGES, translate),
                                vertical: true,
                            },
                            modelKey: 'doorLockDamage',
                        },
                    }, {
                        label: translate('model.break_in_damage.door.lock_type'),
                        key: 'doorLockType',
                        value: model.doorLockType,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Select,
                                choices: translatableObjectToChoices(DOOR_LOCK_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                            },
                            modelKey: 'doorLockType',
                        },
                    });
                }
            }
        }
        else if ((projection === ModelFieldsProjection.Backoffice ||
            projection === ModelFieldsProjection.Pdf) &&
            model.doorHasBrokenWindowTiles) {
            fields.push({
                label: translate('model.break_in_damage.broken_tiles_number'),
                key: 'doorNumberOfBrokenWindowTiles',
                value: model.doorNumberOfBrokenWindowTiles,
                displayedValue: '' + model.doorNumberOfBrokenWindowTiles,
            }, {
                label: translate('model.break_in_damage.broken_window.height'),
                key: 'doorBiggestBrokenWindowTileHeight',
                value: model.doorBiggestBrokenWindowTileHeight,
                displayedValue: '' + model.doorBiggestBrokenWindowTileHeight,
            }, {
                label: translate('model.break_in_damage.broken_window.width'),
                key: 'doorBiggestBrokenWindowTileWidth',
                value: model.doorBiggestBrokenWindowTileWidth,
                displayedValue: '' + model.doorBiggestBrokenWindowTileWidth,
            }, {
                label: translate('model.break_in_damage.glazing_type'),
                key: 'doorWindowGlazingType',
                value: model.doorWindowGlazingType,
                displayedValue: optionalTranslatableEnumTransform(model.doorWindowGlazingType, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            }, {
                label: translate('model.break_in_damage.glass_type'),
                key: 'doorWindowGlassType',
                value: model.doorWindowGlassType,
                displayedValue: optionalTranslatableEnumTransform(model.doorWindowGlassType, GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            });
        }
        if (projection !== ModelFieldsProjection.Declaration &&
            !(model.numberOfImpacts === ImpactsNumber.MoreThanThree && model.material === Material.Metal) &&
            !(model.numberOfImpacts === ImpactsNumber.MoreThanSeven && model.material === Material.PVC)) {
            fields.push(...otherDamagesFields(model, translate));
        }
    }
    // We check the conditions for critical severity again but this time also checking if we're in
    // readonly mode because in this mode we want the dimensions fields to appear at the end
    if (!edition &&
        (model.damageSeverity === DamageSeverity.Critical ||
            (model.numberOfImpacts === ImpactsNumber.MoreThanThree &&
                model.material === Material.Metal) ||
            (model.numberOfImpacts === ImpactsNumber.MoreThanSeven && model.material === Material.PVC))) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function doorBreakInDamageCriticalSeverityFields(model, translate) {
    return [
        {
            label: translate('model.break_in_damage.door.lock_type'),
            key: 'doorLockType',
            value: model.doorLockType,
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(DOOR_LOCK_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'doorLockType',
            },
        },
        {
            label: translate('model.break_in_damage.door.has_window'),
            key: 'doorHasWindow',
            value: model.doorHasWindow,
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'doorHasWindow',
            },
        },
    ];
}
