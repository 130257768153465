export var ContractType;
(function (ContractType) {
    ContractType["Standard"] = "standard";
    ContractType["Standing"] = "standing";
    ContractType["Premium"] = "premium";
})(ContractType || (ContractType = {}));
export var ContractStatus;
(function (ContractStatus) {
    ContractStatus["InEffect"] = "enVigueur";
    ContractStatus["Suspended"] = "suspendu";
    ContractStatus["Cancelled"] = "resilie";
})(ContractStatus || (ContractStatus = {}));
export var ContractResidenceType;
(function (ContractResidenceType) {
    ContractResidenceType["MAIN"] = "P";
    ContractResidenceType["SECONDARY"] = "S";
    ContractResidenceType["UNOCCUPIED_OR_UNDETERMINED"] = "I";
})(ContractResidenceType || (ContractResidenceType = {}));
export var HousingTypeSlug;
(function (HousingTypeSlug) {
    HousingTypeSlug["Apartment"] = "apartment";
    HousingTypeSlug["House"] = "house";
    HousingTypeSlug["Block"] = "block";
    HousingTypeSlug["IndustrialLocal"] = "industrial-local";
    HousingTypeSlug["ProfessionalLocal"] = "professional-local";
    HousingTypeSlug["Garage"] = "garage";
    HousingTypeSlug["CommercialLocal"] = "commercial-local";
    HousingTypeSlug["Parking"] = "parking";
    HousingTypeSlug["Other"] = "other";
    HousingTypeSlug["School"] = "school";
    HousingTypeSlug["MunicipalPool"] = "municipal-pool";
    HousingTypeSlug["MedicalOffice"] = "medical-office";
    HousingTypeSlug["Hotel"] = "hotel";
    HousingTypeSlug["MobileResidence"] = "mobile-residence";
    HousingTypeSlug["Farm"] = "farm";
})(HousingTypeSlug || (HousingTypeSlug = {}));
export var ContractHousingCapacityCode;
(function (ContractHousingCapacityCode) {
    ContractHousingCapacityCode["CoOwnerOccupier"] = "COP";
    ContractHousingCapacityCode["CoOwnerNonOccupier"] = "CNO";
    ContractHousingCapacityCode["OwnerOccupier"] = "PRO";
    ContractHousingCapacityCode["Usufruct"] = "USU";
    ContractHousingCapacityCode["OwnerNonOccupier"] = "PNO";
    ContractHousingCapacityCode["WorkAccomodation"] = "LGF";
    ContractHousingCapacityCode["FreeOfChargeOccupier"] = "OTG";
    ContractHousingCapacityCode["BareOwnership"] = "NUP";
    ContractHousingCapacityCode["NeighbourOwner"] = "VOIS";
    ContractHousingCapacityCode["Other"] = "AUTRES";
    ContractHousingCapacityCode["CoOwnership"] = "COPRO";
    ContractHousingCapacityCode["Tenant"] = "LOC";
    ContractHousingCapacityCode["VehicleOwner"] = "PVEH";
    ContractHousingCapacityCode["RentalBuildingOwner"] = "IML";
    ContractHousingCapacityCode["CoOwnerEmptyFlat"] = "CNOV";
    ContractHousingCapacityCode["UniqueTenant"] = "LOCS";
    ContractHousingCapacityCode["OwnerManager"] = "PEXP";
    ContractHousingCapacityCode["TenantManager"] = "LEXP";
    ContractHousingCapacityCode["Manager"] = "GER";
    ContractHousingCapacityCode["Owner"] = "PROS";
    ContractHousingCapacityCode["FreeSyndicate"] = "ASL";
    ContractHousingCapacityCode["TenantWithInsuranceForOwnerNonOccupier"] = "LOCPO";
    ContractHousingCapacityCode["SubTenant"] = "SOULOC";
    ContractHousingCapacityCode["HousedInMilitaryCompound"] = "EMIL";
    ContractHousingCapacityCode["NecessityServiceOccupier"] = "NSER";
})(ContractHousingCapacityCode || (ContractHousingCapacityCode = {}));
// TODO: rename
export var InsuranceContractXmlLabel;
(function (InsuranceContractXmlLabel) {
    InsuranceContractXmlLabel["Outbuilding"] = "dependance";
})(InsuranceContractXmlLabel || (InsuranceContractXmlLabel = {}));
export var SubscribedCapitalXmlLabel;
(function (SubscribedCapitalXmlLabel) {
    SubscribedCapitalXmlLabel["PersonalProperties"] = "biensAssures";
    SubscribedCapitalXmlLabel["Valuables"] = "objetValeur";
    SubscribedCapitalXmlLabel["Jewelery"] = "bijoux";
})(SubscribedCapitalXmlLabel || (SubscribedCapitalXmlLabel = {}));
export var DamageXmlLabel;
(function (DamageXmlLabel) {
    DamageXmlLabel["RealEstate"] = "biensImmobiliersAssures";
    DamageXmlLabel["RoomDamage"] = "biensImmobiliersInterieurs";
    DamageXmlLabel["BreakInDamage"] = "biensImmobiliersAvecEffraction";
    DamageXmlLabel["ExternalDamage"] = "biensImmobiliersExterieurs";
    DamageXmlLabel["GlassDamage"] = "biensImmobiliersVitres";
    DamageXmlLabel["ElectricalDamage"] = "biensImmobiliersElectriques";
    DamageXmlLabel["PersonalProperty"] = "biensAssures";
    DamageXmlLabel["AppliedMinimumCharge"] = "applicationDesForfaitsMinimum";
})(DamageXmlLabel || (DamageXmlLabel = {}));
export var PersonalPropertyXmlLabel;
(function (PersonalPropertyXmlLabel) {
    PersonalPropertyXmlLabel["Electrical"] = "appareil_electrique";
    PersonalPropertyXmlLabel["Electronical"] = "appareil_electronique";
    PersonalPropertyXmlLabel["Fournitures"] = "mobilier";
    PersonalPropertyXmlLabel["Clothes"] = "vetement";
    PersonalPropertyXmlLabel["Laundry"] = "linge";
    PersonalPropertyXmlLabel["LeisureEquipments"] = "loisirs";
    PersonalPropertyXmlLabel["Music"] = "materiel_musique";
    PersonalPropertyXmlLabel["Sport"] = "materiel_sport";
    PersonalPropertyXmlLabel["Garden"] = "mobilier_jardin";
    PersonalPropertyXmlLabel["Wine"] = "alcool";
    PersonalPropertyXmlLabel["MotorizedVehicule"] = "vehicule_moteur";
    PersonalPropertyXmlLabel["VehiculeEquipment"] = "materiel_vehicule";
    PersonalPropertyXmlLabel["Cash"] = "especes";
    PersonalPropertyXmlLabel["Hvac"] = "hvac";
    PersonalPropertyXmlLabel["Jewels"] = "bijoux";
    PersonalPropertyXmlLabel["ValuableObject"] = "objet_valeur";
    PersonalPropertyXmlLabel["TransportBike"] = "moyen_transport_leger";
    PersonalPropertyXmlLabel["IdentityDocument"] = "document_valeur";
    PersonalPropertyXmlLabel["ObjetValeur"] = "objet_valeur";
    PersonalPropertyXmlLabel["Vetement"] = "vetement";
    PersonalPropertyXmlLabel["Outillage"] = "outillage";
    PersonalPropertyXmlLabel["DenreesAlimentaires"] = "denrees_alimentaires";
    PersonalPropertyXmlLabel["MoyenTransport"] = "moyen_transport";
    PersonalPropertyXmlLabel["Multimedia"] = "multimedia";
})(PersonalPropertyXmlLabel || (PersonalPropertyXmlLabel = {}));
export var RefundCeilingType;
(function (RefundCeilingType) {
    RefundCeilingType["Absolute"] = "absolute";
    RefundCeilingType["Percentage"] = "percentage";
})(RefundCeilingType || (RefundCeilingType = {}));
export var OptionLabel;
(function (OptionLabel) {
    OptionLabel["Garden"] = "jardin";
    OptionLabel["Renovation"] = "valeur-a-neuf";
    OptionLabel["Robbery"] = "vol-etendu";
})(OptionLabel || (OptionLabel = {}));
export var ContractCategoryConditionsType;
(function (ContractCategoryConditionsType) {
    ContractCategoryConditionsType["General"] = "general";
    ContractCategoryConditionsType["RealEstateGeneral"] = "real_estate_general";
    ContractCategoryConditionsType["PersonalPropertyGeneral"] = "personal_property_general";
    ContractCategoryConditionsType["RealEstate"] = "real_estate_specific";
    ContractCategoryConditionsType["PersonalProperty"] = "personal_property_specific";
})(ContractCategoryConditionsType || (ContractCategoryConditionsType = {}));
export var CoverCode;
(function (CoverCode) {
    CoverCode["NoDeductableAmount"] = "SANS_FRANCHISE";
    CoverCode["ValueAsNew"] = "VALEUR_A_NEUF";
    CoverCode["SwimmingPool"] = "PISCINE";
    CoverCode["Micro"] = "MICRO";
    CoverCode["Food"] = "DENREES_ALIMENTAIRES";
    CoverCode["RobberyRoomDamagesDomicile"] = "VIM";
    CoverCode["RobberyPersonalPropertyDamagesDomicile"] = "VMO";
    CoverCode["RobberyValuables"] = "VOV";
    CoverCode["PersonalRobbery"] = "VPE";
    CoverCode["RobberyRoomDamagesHabitationLogiplus"] = "VOLI";
    CoverCode["RobberyPersonalPropertyDamagesHabitationLogiplus"] = "VOLM";
})(CoverCode || (CoverCode = {}));
export var ContractProblem;
(function (ContractProblem) {
    ContractProblem["Unpaid"] = "IMPAYES";
    ContractProblem["ManagementIncident"] = "INCIDENT_DE_GESTION";
    ContractProblem["automaticCompensation24"] = "REGLEMENT_AUTO_24";
})(ContractProblem || (ContractProblem = {}));
export var HasContractProblems;
(function (HasContractProblems) {
    HasContractProblems["Yes"] = "Oui";
    HasContractProblems["No"] = "Non";
})(HasContractProblems || (HasContractProblems = {}));
