import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { areEntityChangesEmpty, changesToArray, createEntityChanges, damageChangesToEntityChanges, entityChangesToPayload, getEntityChange, getOrCreateEntityChange, isUpdateChangeKey, } from '@shared/claim/entities';
import { deleteFiles } from '@shared/files/deleteFiles';
import { removeArrayItem } from '@shared/utils/removeArrayItem';
import { ValidationContext } from '@shared/validator/validationContext';
import { newValidator, revealViolations, touch, untouch, validate, } from '@shared/validator/validator';
function validationContextFromRoot(rootGetters) {
    return rootGetters['claim/validationConstraintsContext'];
}
export function createDamageStore(newDamage, damageConstraints, damageDetailStep, countingDamageKey, initialDamage, damageFieldsForCategoryKey) {
    function createDamageChange(damage, validationContext) {
        const validator = newValidator(damage);
        validate(validator, damage, damageConstraints(damage, validationContext));
        return {
            data: damage,
            validator,
        };
    }
    function getOrCreateDamageChange(state, changeKey, validationContext) {
        return getOrCreateEntityChange(state.changes, changeKey, (changeKey) => {
            var _a;
            if (isUpdateChangeKey(changeKey)) {
                // Create change model with data from existing damage with same iri
                return createDamageChange(cloneDeep((_a = state.damages.find(({ id }) => id === changeKey)) !== null && _a !== void 0 ? _a : newDamage()), validationContext);
            }
            // Create new change model
            return createDamageChange(newDamage(), validationContext);
        });
    }
    /**
     * Get existing change from state or create one based on existing entity
     */
    function damageChangeFromState(state, changeKey, validationContext) {
        // Get from creation array if iri is a number or null (changeKey of created)
        const change = getEntityChange(state.changes, changeKey);
        if (change) {
            return change;
        }
        const existingDamage = state.damages.find(({ id }) => id === changeKey);
        if (existingDamage) {
            return createDamageChange(existingDamage, validationContext);
        }
        return createDamageChange(newDamage(), validationContext);
    }
    const createDamageState = () => ({
        damages: [],
        changes: createEntityChanges(),
        uploadedFilesToDelete: [],
    });
    const damageGetters = {
        havePendingChanges(state) {
            return !areEntityChangesEmpty(state.changes);
        },
        change(state, _getters, _rootState, rootGetters) {
            return (changeKey) => {
                const validationContext = validationContextFromRoot(rootGetters);
                if (!validationContext) {
                    throw new Error('No validation context');
                }
                return damageChangeFromState(state, changeKey, validationContext);
            };
        },
        validation(_state, getters) {
            return (changeKey) => {
                const change = getters.change(changeKey);
                if (change) {
                    return new ValidationContext(change.validator);
                }
                return new ValidationContext(null);
            };
        },
        isDamageValid: (_state, _getters, _rootState, rootGetters) => (damage) => {
            const validator = newValidator(damage);
            const validationContext = validationContextFromRoot(rootGetters);
            if (!validationContext) {
                return false;
            }
            validate(validator, damage, damageConstraints(damage, validationContext));
            const validation = new ValidationContext(validator);
            return validation.isValid;
        },
        changedDamages(state) {
            return state.damages
                .map((damage) => { var _a, _b; return (_b = (_a = getEntityChange(state.changes, damage.id)) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : damage; })
                .concat(state.changes.create.map((created) => created.data));
        },
        areDamagesValid(_, getters) {
            return getters.changedDamages.every((damage) => getters.isDamageValid(damage));
        },
        toDeleteDamagesIriSet(state) {
            const toDeleteDamagesIri = new Set();
            state.changes.delete.forEach((iri) => {
                toDeleteDamagesIri.add(iri);
            });
            return toDeleteDamagesIri;
        },
        damagesCount(state, getters) {
            let count = 0;
            const toDeleteDamagesIriSet = getters.toDeleteDamagesIriSet;
            state.changes.delete.forEach((iri) => {
                toDeleteDamagesIriSet.add(iri);
            });
            state.damages.forEach((damage) => {
                if (!toDeleteDamagesIriSet.has(damage.id)) {
                    count += 1;
                }
            });
            count += state.changes.create.length;
            return count;
        },
        damageCountByCategory(state, getters) {
            if (!initialDamage) {
                return {};
            }
            const damagesByCategory = {};
            const toDeleteDamagesIriSet = getters.toDeleteDamagesIriSet;
            state.damages.forEach((damage) => {
                const category = damage[initialDamage.categoryKey];
                if (!category || typeof category !== 'string' || toDeleteDamagesIriSet.has(damage.id)) {
                    return;
                }
                if (category in damagesByCategory) {
                    damagesByCategory[category] += 1;
                }
                else {
                    damagesByCategory[category] = 1;
                }
            });
            state.changes.create.forEach((created) => {
                const category = created.data[initialDamage.categoryKey];
                if (!category || typeof category !== 'string') {
                    return;
                }
                if (category in damagesByCategory) {
                    damagesByCategory[category] += 1;
                }
                else {
                    damagesByCategory[category] = 1;
                }
            });
            return damagesByCategory;
        },
        damagesCountInCategory(_state, getters) {
            return (category) => { var _a; return (_a = getters.damageCountByCategory[category]) !== null && _a !== void 0 ? _a : 0; };
        },
        areDamagesInCategory(_state, getters) {
            return (category) => { var _a; return ((_a = getters.damageCountByCategory[category]) !== null && _a !== void 0 ? _a : 0) > 0; };
        },
    };
    const damageActions = {
        validateAndRevealDamages({ commit, rootGetters }, changeKey) {
            const validationContext = validationContextFromRoot(rootGetters);
            if (!validationContext) {
                return;
            }
            commit('VALIDATE_AND_REVEAL_DAMAGES', {
                changeKey,
                validationContext,
            });
        },
        async saveChanges({ dispatch, commit, state, rootState }, { step, throwError, payload: inputPayload, } = {
            step: damageDetailStep,
        }) {
            if (!rootState.counting.counting.id) {
                throw new Error('No counting');
            }
            const entityChanges = damageChangesToEntityChanges(state.changes);
            const payload = entityChangesToPayload(state.damages, entityChanges, true);
            await dispatch('claim/saveClaimWithPayload', {
                throwError,
                ...inputPayload,
                counting: {
                    id: rootState.counting.counting.id,
                    [countingDamageKey]: payload,
                },
                step,
            }, {
                root: true,
            });
            commit('RESET_CHANGES');
            // No need to wait for file deletions
            deleteFiles(state.uploadedFilesToDelete, this.$axios);
        },
        deleteDamage({ commit }, changeKey) {
            commit('DELETE_DAMAGE', changeKey);
        },
        updateDamage({ commit, rootGetters }, payload) {
            const validationContext = validationContextFromRoot(rootGetters);
            if (!validationContext) {
                return;
            }
            const mutationPayload = {
                ...payload,
                validationContext,
            };
            commit('UPDATE_DAMAGE', mutationPayload);
        },
        resetChange({ commit }, changeKey) {
            commit('RESET_CHANGE', changeKey);
        },
        resetChanges({ commit }) {
            commit('RESET_CHANGES');
        },
        async updateCount({ state, dispatch, getters, commit, rootState, rootGetters }, { count, step }) {
            if (!initialDamage) {
                throw new Error('No initial damage parameters configured');
            }
            if (!rootState.counting.counting.id) {
                throw new Error('No counting');
            }
            const validationContext = validationContextFromRoot(rootGetters);
            if (!validationContext) {
                throw new Error('No validation context');
            }
            const currentDamagesCount = getters.damagesCount;
            if (count > currentDamagesCount) {
                commit('ADD_NEW_DAMAGES', {
                    count: count - currentDamagesCount,
                    validationContext,
                });
            }
            else {
                const toDelete = currentDamagesCount - count;
                const createdToDeleteCount = Math.min(toDelete, state.changes.create.length);
                const savedToDeleteCount = Math.max(toDelete - state.changes.create.length, 0);
                const savedToDeleteIndex = Array.from({ length: savedToDeleteCount }, (_, i) => state.damages.length - 1 - i);
                commit('REMOVE_CREATED', {
                    count: createdToDeleteCount,
                    startIndex: state.changes.create.length - createdToDeleteCount,
                });
                for (let i = 0; i < savedToDeleteIndex.length; i++) {
                    if (savedToDeleteIndex[i] >= 0 && savedToDeleteIndex[i] < state.damages.length) {
                        commit('DELETE_DAMAGE', state.damages[savedToDeleteIndex[i]].id);
                    }
                }
            }
            await dispatch('saveChanges', step !== null && step !== void 0 ? step : initialDamage.step);
        },
        deleteWithInitialCategory({ state, commit, getters }, category) {
            if (!initialDamage) {
                return;
            }
            for (let i = 0; i < state.changes.create.length; i++) {
                if (state.changes.create[state.changes.create.length - i - 1].data[initialDamage.categoryKey] === category) {
                    commit('REMOVE_CREATED', { startIndex: state.changes.create.length - i - 1, count: 1 });
                    return;
                }
            }
            const damageToDeleteReverseIndex = state.damages
                .slice()
                .reverse()
                .findIndex((damage) => damage[initialDamage.categoryKey] === category &&
                !getters.toDeleteDamagesIriSet.has(damage.id));
            if (damageToDeleteReverseIndex >= 0) {
                commit('DELETE_DAMAGE', state.damages[state.damages.length - damageToDeleteReverseIndex - 1].id);
            }
        },
        addWithInitialCategory({ commit, state, rootState, getters, rootGetters }, category) {
            const validationContext = validationContextFromRoot(rootGetters);
            if (!validationContext) {
                return;
            }
            const initialFields = damageFieldsForCategoryKey
                ? damageFieldsForCategoryKey(category, state, rootState, getters, rootGetters)
                : {};
            commit('ADD_NEW_DAMAGES', {
                count: 1,
                initialFields,
                validationContext,
            });
        },
        selectWithInitialCategory({ state, dispatch, getters, rootState, rootGetters }, { changeKey, category }) {
            const fields = damageFieldsForCategoryKey
                ? damageFieldsForCategoryKey(category, state, getters, rootState, rootGetters)
                : {};
            dispatch('updateDamage', {
                changeKey,
                data: fields,
            });
        },
        updateDamagesCountInCategory({ dispatch, getters }, { count, category, }) {
            const stateCount = getters.damagesCountInCategory(category);
            if (count < stateCount) {
                return dispatch('deleteWithInitialCategory', category);
            }
            if (count > stateCount) {
                return dispatch('addWithInitialCategory', category);
            }
        },
        updateStoreWithClaimResponse({ commit }, claimResponse) {
            commit('SET_DAMAGES', claimResponse.counting[countingDamageKey]);
        },
        resetState({ commit }) {
            commit('RESET_STATE');
        },
    };
    const damageMutations = {
        SET_DAMAGES(state, damages) {
            state.damages = damages;
            state.damages.sort((a, b) => a.id.localeCompare(b.id));
        },
        UPDATE_DAMAGE(state, { changeKey, data, validationContext }) {
            const change = getOrCreateDamageChange(state, changeKey, validationContext);
            for (const key in data) {
                const value = data[key];
                Vue.set(change.data, key, value);
                touch(change.validator, key);
            }
            validate(change.validator, change.data, damageConstraints(change.data, validationContext));
        },
        RESET_CHANGE(state, changeKey) {
            if (isUpdateChangeKey(changeKey)) {
                Vue.delete(state.changes.update, changeKey);
                // Remove change from deletion
                const deleteIndex = state.changes.delete.findIndex((iri) => iri === changeKey);
                if (deleteIndex !== -1) {
                    const newDelete = removeArrayItem(state.changes.delete, deleteIndex);
                    Vue.set(state.changes, 'delete', newDelete);
                }
                return;
            }
            const newCreate = removeArrayItem(state.changes.create, changeKey !== null && changeKey !== void 0 ? changeKey : 0);
            Vue.set(state.changes, 'create', newCreate);
        },
        VALIDATE_AND_REVEAL_DAMAGES(state, { changeKey, validationContext, }) {
            if (changeKey === undefined) {
                // Validate all changes
                for (const change of changesToArray(state.changes)) {
                    validate(change.validator, change.data, damageConstraints(change.data, validationContext));
                    revealViolations(change.validator);
                }
                return;
            }
            const change = getEntityChange(state.changes, changeKey);
            if (!change) {
                return;
            }
            validate(change.validator, change.data, damageConstraints(change.data, validationContext));
            revealViolations(change.validator);
        },
        TOUCH_FIELD(state, { changeKey, fieldName }) {
            const change = getEntityChange(state.changes, changeKey);
            if (!change) {
                return;
            }
            touch(change.validator, fieldName);
        },
        UNTOUCH_FIELD(state, { changeKey, fieldName }) {
            const change = getEntityChange(state.changes, changeKey);
            if (!change) {
                return;
            }
            untouch(change.validator, fieldName);
        },
        DELETE_DAMAGE(state, changeKey) {
            if (changeKey === null) {
                // Existing entities might be "placeholders" with null changeKeys
                const placeholderIndex = state.damages.findIndex(({ id }) => id === null);
                if (placeholderIndex !== -1) {
                    state.damages = removeArrayItem(state.damages, placeholderIndex);
                    return;
                }
            }
            // If changeKey is IRI, add to delete list
            if (isUpdateChangeKey(changeKey)) {
                delete state.changes.update[changeKey];
                Vue.set(state.changes.delete, state.changes.delete.length, changeKey);
                return;
            }
            // Remove from created but not saved changes
            state.changes.create = removeArrayItem(state.changes.create, changeKey !== null && changeKey !== void 0 ? changeKey : 0);
        },
        RESET_CHANGES(state) {
            state.changes = createEntityChanges();
            state.uploadedFilesToDelete = [];
        },
        ADD_NEW_DAMAGES(state, { count, initialFields, validationContext, }) {
            for (let i = 0; i < count; i++) {
                const damage = newDamage();
                if (initialFields) {
                    Object.keys(initialFields).forEach((key) => {
                        damage[key] = initialFields[key];
                    });
                }
                state.changes.create.push(createDamageChange(damage, validationContext));
            }
        },
        REMOVE_CREATED(state, { startIndex, count }) {
            state.changes.create.splice(startIndex, count);
        },
        RESET_STATE(state) {
            Object.assign(state, createDamageState());
        },
    };
    return { createDamageState, damageGetters, damageActions, damageMutations };
}
