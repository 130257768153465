export function contractFromResponse({ id, inEffectSince, number, formula, status, contact, housingType, housingCapacity, specialConditions: { address, addressAffix, city, country, livingArea, rooms, zipcode, personalPropertyCapital, valuablesCapital, isVacant, isRented, }, residence, covers, }) {
    return {
        id,
        number,
        formula,
        status,
        inEffectSince: inEffectSince ? new Date(inEffectSince) : null,
        contact,
        housingType,
        housingCapacity,
        specialConditions: {
            address,
            addressAffix,
            zipcode,
            city,
            country,
            rooms,
            livingArea,
            personalPropertyCapital,
            valuablesCapital,
            isVacant,
            isRented,
        },
        residence,
        covers,
    };
}
