const middleware = ({ store }) => {
    return Promise.all([
        store.dispatch('settings/fetchSettingsIfNotSet'),
        store.dispatch('helpers/fetch'),
        store.dispatch('externalDamage/fetchTypes'),
        store.dispatch('personalProperty/fetchCategories'),
        store.dispatch('roomDamage/fetchCoveringCategoriesIfNotSet'),
        store.dispatch('thirdParty/fetchInsuranceCompanyChoicesIfNotSet'),
    ]);
};
export default middleware;
