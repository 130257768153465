import { translateChoices } from '@shared/choice';
import { WINDOW_PANE_GLASS_TYPE_CHOICES, WINDOW_PANE_GLASS_TYPE_IMAGES_AND_TRAD_KEYS, WINDOW_PANE_GLAZING_TYPE_CHOICES, WINDOW_PANE_GLAZING_TYPE_IMAGES_AND_TRAD_KEYS, WINDOW_TYPE_CHOICES, WINDOW_TYPE_IMAGES_AND_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { optionalBooleanTransform, optionalString, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
import { ExternalDamageOrigin, WindowType } from '@shared/types/api/externalDamage';
import { formatOptionalLenght } from '@shared/utils/formatLength';
export const windowFields = (model, { translate, stormSnowHailDamageCauseType, projection }) => {
    const fields = [
        {
            label: translate('model.external_damage.window.type'),
            value: model.windowType,
            displayedValue: optionalTranslatableEnumTransform(model.windowType, WINDOW_TYPE_IMAGES_AND_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(WINDOW_TYPE_CHOICES, translate),
                    vertical: true,
                },
                modelKey: 'windowType',
                toPayload: (windowType) => {
                    const payload = { windowType };
                    if (stormSnowHailDamageCauseType === ExternalDamageOrigin.Hail) {
                        if (windowType === WindowType.RoofWindow)
                            payload.windowPaneCount = null;
                        else if (model.windowStructureDamaged === false)
                            payload.windowPaneCount = model.windowPaneCount || 1;
                    }
                    else
                        payload.windowCount = model.windowCount || 1;
                    return payload;
                },
            },
        },
    ];
    if (stormSnowHailDamageCauseType === ExternalDamageOrigin.Hail) {
        fields.push({
            label: translate('model.external_damage.window.structure_damaged'),
            value: model.windowStructureDamaged,
            displayedValue: optionalBooleanTransform(model.windowStructureDamaged, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'windowStructureDamaged',
                toPayload: (windowStructureDamaged) => {
                    const payload = { windowStructureDamaged };
                    if (windowStructureDamaged === true) {
                        payload.windowPaneCount = null;
                        payload.windowPaneWidth = null;
                        payload.windowPaneHeight = null;
                        payload.windowPaneGlassType = null;
                        payload.windowPaneGlazingType = null;
                    }
                    else if (windowStructureDamaged === false &&
                        model.windowType !== WindowType.RoofWindow) {
                        payload.windowPaneCount || (payload.windowPaneCount = 1);
                    }
                    return payload;
                },
            },
        });
        if (model.windowStructureDamaged === false) {
            if (model.windowType !== WindowType.RoofWindow) {
                fields.push({
                    label: translate('model.external_damage.window.pane_count'),
                    value: model.windowPaneCount,
                    displayedValue: optionalString(model.windowPaneCount),
                    editable: {
                        input: {
                            type: ModelFieldInputType.Number,
                        },
                        modelKey: 'windowPaneCount',
                    },
                });
            }
            const width = model.windowPaneWidth ? model.windowPaneWidth / 10 : null;
            const height = model.windowPaneHeight ? model.windowPaneHeight / 10 : null;
            if (projection === ModelFieldsProjection.Backoffice) {
                fields.push({
                    label: translate(model.windowType === WindowType.RoofWindow
                        ? 'model.external_damage.window.pane_height'
                        : 'model.external_damage.window.widest_pane_height'),
                    value: height,
                    displayedValue: formatOptionalLenght(height, 'cm'),
                    editable: {
                        unit: 'cm',
                        input: {
                            type: ModelFieldInputType.Number,
                        },
                        modelKey: 'windowPaneHeight',
                        toPayload: (windowPaneHeight) => {
                            return {
                                windowPaneHeight: windowPaneHeight ? windowPaneHeight * 10 : windowPaneHeight,
                            };
                        },
                    },
                });
                fields.push({
                    label: translate(model.windowType === WindowType.RoofWindow
                        ? 'model.external_damage.window.pane_width'
                        : 'model.external_damage.window.widest_pane_width'),
                    value: width,
                    displayedValue: formatOptionalLenght(width, 'cm'),
                    editable: {
                        unit: 'cm',
                        input: {
                            type: ModelFieldInputType.Number,
                        },
                        modelKey: 'windowPaneWidth',
                        toPayload: (windowPaneWidth) => {
                            return { windowPaneWidth: windowPaneWidth ? windowPaneWidth * 10 : windowPaneWidth };
                        },
                    },
                });
            }
            else {
                const width = model.windowPaneWidth ? model.windowPaneWidth / 10 : null;
                const height = model.windowPaneHeight ? model.windowPaneHeight / 10 : null;
                fields.push({
                    label: translate(model.windowType === WindowType.RoofWindow
                        ? 'model.external_damage.window.pane_dimensions'
                        : 'model.external_damage.window.widest_pane_dimensions'),
                    key: 'windowPaneDimensions',
                    displayedValue: `${formatOptionalLenght(width, 'cm')} × ${formatOptionalLenght(height, 'cm')}`,
                });
            }
            fields.push({
                label: translate('model.external_damage.window.pane_glazing_type'),
                value: model.windowPaneGlazingType,
                displayedValue: optionalTranslatableEnumTransform(model.windowPaneGlazingType, WINDOW_PANE_GLAZING_TYPE_IMAGES_AND_TRAD_KEYS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translateChoices(WINDOW_PANE_GLAZING_TYPE_CHOICES, translate),
                        vertical: true,
                    },
                    modelKey: 'windowPaneGlazingType',
                },
            }, {
                label: translate('model.external_damage.window.pane_glass_type'),
                value: model.windowPaneGlassType,
                displayedValue: optionalTranslatableEnumTransform(model.windowPaneGlassType, WINDOW_PANE_GLASS_TYPE_IMAGES_AND_TRAD_KEYS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translateChoices(WINDOW_PANE_GLASS_TYPE_CHOICES, translate),
                        vertical: true,
                    },
                    modelKey: 'windowPaneGlassType',
                },
            });
        }
    }
    else {
        fields.push({
            label: translate('model.external_damage.window.count'),
            value: model.windowCount,
            displayedValue: optionalString(model.windowCount),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                modelKey: 'windowCount',
            },
        });
    }
    return fields;
};
