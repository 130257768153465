import Vue from 'vue';
import Buefy from 'buefy';
Vue.use(Buefy, {
    defaultIconPack: 'claims-icon-buefy',
    customIconPacks: {
        'claims-icon-buefy': {
            sizes: {
                default: 'is-size-5',
                'is-small': 'is-size-5',
                'is-medium': 'is-size-3',
                'is-large': 'is-size-1',
            },
            iconPrefix: 'icon-',
        },
    },
});
