function dispatchIfDifferentCallback(callback, store) {
    if (store.state.layout.goBackCallback === callback) {
        return;
    }
    store.dispatch('layout/setCloseCallback', callback);
}
export function setCloseCallback(callback, { store }) {
    if (!callback) {
        return dispatchIfDifferentCallback(null, store);
    }
    return dispatchIfDifferentCallback(callback, store);
}
