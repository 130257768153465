import { RobberyBreakableElement, BreakInDamageRepairationType } from '@shared/types/api/claim';
/**
 * Fields that can be added to a break in damage form depending on breakin damage type
 */
export var BreakInDamageField;
(function (BreakInDamageField) {
    BreakInDamageField["Name"] = "name";
})(BreakInDamageField || (BreakInDamageField = {}));
/**
 * Which fields in addition to base ones should be added to form depending on damage type
 */
export const BREAK_IN_DAMAGE_FIELDS_FOR_TYPE = {
    [RobberyBreakableElement.Door]: [],
    [RobberyBreakableElement.GarageDoor]: [],
    [RobberyBreakableElement.Shutter]: [],
    [RobberyBreakableElement.Window]: [],
    [RobberyBreakableElement.SlidingBayWindow]: [],
    [RobberyBreakableElement.FrenchWindow]: [],
    [RobberyBreakableElement.Fence]: [],
    [RobberyBreakableElement.Gate]: [],
    [RobberyBreakableElement.LittleGate]: [],
    [RobberyBreakableElement.Other]: [BreakInDamageField.Name],
};
export const BREAK_IN_DAMAGE_REPARATION_TYPES_TRAD_KEYS = {
    [BreakInDamageRepairationType.Temporary]: 'claim.declaration.break_in_damages.question.repairation_type.temporary',
    [BreakInDamageRepairationType.Definitive]: 'claim.declaration.break_in_damages.question.repairation_type.definitive',
    [BreakInDamageRepairationType.AssistanceTemporary]: 'claim.declaration.break_in_damages.question.repairation_type.assistance_temporary',
    [BreakInDamageRepairationType.AssistanceDefinitive]: 'claim.declaration.break_in_damages.question.repairation_type.assistance_definitive',
};
export var BreakInDamageCompensationMode;
(function (BreakInDamageCompensationMode) {
    BreakInDamageCompensationMode["CompensationModeAssistance"] = "assistance";
    BreakInDamageCompensationMode["CompensationModeTemporary"] = "temporary";
    BreakInDamageCompensationMode["CompensationModeDefinitive"] = "definitive";
    BreakInDamageCompensationMode["CompensationModeReplacementNecessary"] = "replacement-necessary";
    BreakInDamageCompensationMode["CompensationModeFixed"] = "fixed";
})(BreakInDamageCompensationMode || (BreakInDamageCompensationMode = {}));
export var DamageSeverity;
(function (DamageSeverity) {
    DamageSeverity["Slight"] = "slight";
    DamageSeverity["Notable"] = "notable";
    DamageSeverity["Critical"] = "critical";
})(DamageSeverity || (DamageSeverity = {}));
export var GlazingType;
(function (GlazingType) {
    GlazingType["Simple"] = "simple";
    GlazingType["Double"] = "double";
    GlazingType["Triple"] = "triple";
    GlazingType["Unknown"] = "unknown";
})(GlazingType || (GlazingType = {}));
export var GlassType;
(function (GlassType) {
    GlassType["Laminated"] = "laminated";
    GlassType["NonLaminated"] = "non-laminated";
    GlassType["Unknown"] = "unknown";
})(GlassType || (GlassType = {}));
export var LeavesType;
(function (LeavesType) {
    LeavesType["OneLeaf"] = "one-leaf";
    LeavesType["TwoLeaves"] = "two-leaves";
    LeavesType["ThreeLeaves"] = "three-leaves";
})(LeavesType || (LeavesType = {}));
export var Material;
(function (Material) {
    Material["Wood"] = "wood";
    Material["PVC"] = "pvc";
    Material["Metal"] = "metal";
    Material["Aluminium"] = "aluminium";
    Material["Steel"] = "steel";
})(Material || (Material = {}));
export const MATERIALS_TRAD_KEYS = {
    [Material.Wood]: 'claim.declaration.break_in_damages.form.material.wood',
    [Material.Metal]: 'claim.declaration.break_in_damages.form.material.metal',
    [Material.Aluminium]: 'claim.declaration.break_in_damages.form.material.aluminium',
    [Material.Steel]: 'claim.declaration.break_in_damages.form.material.steel',
    [Material.PVC]: 'claim.declaration.break_in_damages.form.material.pvc',
};
export var Measurement;
(function (Measurement) {
    Measurement["LessThan60cm"] = "less-than-60cm";
    Measurement["LessThan120cm"] = "less-than-120cm";
    Measurement["LessThan95cm"] = "less-than-95cm";
    Measurement["LessThan100cm"] = "less-than-100cm";
    Measurement["LessThan145cm"] = "less-than-145cm";
    Measurement["LessThan150cm"] = "less-than-150cm";
    Measurement["LessThan220cm"] = "less-than-220cm";
    Measurement["LessThan300cm"] = "less-than-300cm";
    Measurement["Between60And120cm"] = "between-60-and-120cm";
    Measurement["Between95And145cm"] = "between-95-and-145cm";
    Measurement["Between100And180cm"] = "between-100-and-180cm";
    Measurement["Between120And140cm"] = "between-120-and-140cm";
    Measurement["Between140And180cm"] = "between-140-and-180cm";
    Measurement["Between150And170cm"] = "between-150-and-170cm";
    Measurement["Between170And180cm"] = "between-170-and-180cm";
    Measurement["Between220And260cm"] = "between-220-and-260cm";
    Measurement["Between300And400cm"] = "between-300-and-400cm";
    Measurement["MoreThan95cm"] = "more-than-95cm";
    Measurement["MoreThan120cm"] = "more-than-120cm";
    Measurement["MoreThan145cm"] = "more-than-145cm";
    Measurement["MoreThan180cm"] = "more-than-180cm";
    Measurement["MoreThan220cm"] = "more-than-220cm";
    Measurement["MoreThan260cm"] = "more-than-260cm";
    Measurement["MoreThan300cm"] = "more-than-300cm";
    Measurement["MoreThan400cm"] = "more-than-400cm";
})(Measurement || (Measurement = {}));
export const MEASUREMENTS_TRAD_KEYS = {
    [Measurement.LessThan60cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-60cm',
    [Measurement.LessThan120cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-120cm',
    [Measurement.LessThan95cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-95cm',
    [Measurement.LessThan100cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-100cm',
    [Measurement.LessThan145cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-145cm',
    [Measurement.LessThan150cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-150cm',
    [Measurement.LessThan220cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-220cm',
    [Measurement.LessThan300cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-300cm',
    [Measurement.Between60And120cm]: 'claim.declaration.break_in_damages.form.measurement.between-60-and-120cm',
    [Measurement.Between95And145cm]: 'claim.declaration.break_in_damages.form.measurement.between-95-and-145cm',
    [Measurement.Between100And180cm]: 'claim.declaration.break_in_damages.form.measurement.between-100-and-180cm',
    [Measurement.Between120And140cm]: 'claim.declaration.break_in_damages.form.measurement.between-120-and-140cm',
    [Measurement.Between140And180cm]: 'claim.declaration.break_in_damages.form.measurement.between-140-and-180cm',
    [Measurement.Between150And170cm]: 'claim.declaration.break_in_damages.form.measurement.between-150-and-170cm',
    [Measurement.Between170And180cm]: 'claim.declaration.break_in_damages.form.measurement.between-170-and-180cm',
    [Measurement.Between220And260cm]: 'claim.declaration.break_in_damages.form.measurement.between-220-and-260cm',
    [Measurement.Between300And400cm]: 'claim.declaration.break_in_damages.form.measurement.between-300-and-400cm',
    [Measurement.MoreThan95cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-95cm',
    [Measurement.MoreThan120cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-120cm',
    [Measurement.MoreThan145cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-145cm',
    [Measurement.MoreThan180cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-180cm',
    [Measurement.MoreThan220cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-220cm',
    [Measurement.MoreThan260cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-260cm',
    [Measurement.MoreThan300cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-300cm',
    [Measurement.MoreThan400cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-400cm',
};
export var DoorType;
(function (DoorType) {
    DoorType["MainEntrance"] = "main-entrance";
    DoorType["Service"] = "service";
    DoorType["Indoor"] = "indoor";
})(DoorType || (DoorType = {}));
export var DoorLockDamages;
(function (DoorLockDamages) {
    DoorLockDamages["Nonfunctional"] = "nonfunctional";
    DoorLockDamages["Destroyed"] = "destroyed";
})(DoorLockDamages || (DoorLockDamages = {}));
export var DoorLockType;
(function (DoorLockType) {
    DoorLockType["OneLock"] = "one-lock";
    DoorLockType["TwoLocks"] = "two-locks";
    DoorLockType["ThreeLocks"] = "three-locks";
    DoorLockType["FixatedMultipointLock"] = "fixated-multipoint-lock";
    DoorLockType["FairedMultipointLock"] = "faired-multipoint-lock";
    DoorLockType["IntegratedMultipointLock"] = "integrated-multipoint-lock";
})(DoorLockType || (DoorLockType = {}));
export var ImpactsNumber;
(function (ImpactsNumber) {
    ImpactsNumber["NoImpact"] = "no-impact";
    ImpactsNumber["AtLeastOne"] = "at-least-one";
    ImpactsNumber["OneToThree"] = "one-to-three";
    ImpactsNumber["MoreThanThree"] = "more-than-three";
    ImpactsNumber["FourToSix"] = "four-to-six";
    ImpactsNumber["MoreThanSeven"] = "more-than-seven";
})(ImpactsNumber || (ImpactsNumber = {}));
export var BreakInDamageShutterType;
(function (BreakInDamageShutterType) {
    BreakInDamageShutterType["Flappy"] = "battant";
    BreakInDamageShutterType["Rolling"] = "roulant";
    BreakInDamageShutterType["Folding"] = "pliant";
    BreakInDamageShutterType["Bar"] = "bar";
})(BreakInDamageShutterType || (BreakInDamageShutterType = {}));
export var RollingShutterMotorization;
(function (RollingShutterMotorization) {
    RollingShutterMotorization["Manual"] = "manuel";
    RollingShutterMotorization["Motorized"] = "motorized";
})(RollingShutterMotorization || (RollingShutterMotorization = {}));
export var ShutterBarTypes;
(function (ShutterBarTypes) {
    ShutterBarTypes["Grid"] = "grid";
    ShutterBarTypes["IndividualBars"] = "individual-bars";
})(ShutterBarTypes || (ShutterBarTypes = {}));
export var BreakInDamageGarageDoorType;
(function (BreakInDamageGarageDoorType) {
    BreakInDamageGarageDoorType["Tilting"] = "tilting";
    BreakInDamageGarageDoorType["Sectional"] = "sectional";
    BreakInDamageGarageDoorType["Sliding"] = "sliding";
    BreakInDamageGarageDoorType["RollUp"] = "roll-up";
    BreakInDamageGarageDoorType["Swinging"] = "swinging";
    BreakInDamageGarageDoorType["Folding"] = "folding";
})(BreakInDamageGarageDoorType || (BreakInDamageGarageDoorType = {}));
export var GarageDoorMotorization;
(function (GarageDoorMotorization) {
    GarageDoorMotorization["Manual"] = "manual";
    GarageDoorMotorization["Motorized"] = "motorized";
})(GarageDoorMotorization || (GarageDoorMotorization = {}));
export var OtherDamageType;
(function (OtherDamageType) {
    OtherDamageType["Skylight"] = "skylight";
    OtherDamageType["FixedBay"] = "fixed-bay";
    OtherDamageType["Transom"] = "transom";
    OtherDamageType["Other"] = "other";
})(OtherDamageType || (OtherDamageType = {}));
