export function insuranceCompanyGetDataToFormValues({ name, email, phone, }) {
    return {
        name,
        email,
        phone,
    };
}
export function insuranceCompanyFormValuesToPostData({ name, phone, email, handWrittenName, }) {
    return {
        phone,
        email,
        name: handWrittenName || name || '',
    };
}
export function thirdPartyGetDataToFormValues({ id, clientIdentifier, contact, insuranceCompany, vehicleRegistrationNumber, contractNumber, }) {
    return {
        iri: id,
        clientIdentifier,
        contact,
        insuranceCompany: insuranceCompany
            ? insuranceCompanyGetDataToFormValues(insuranceCompany)
            : { id: null, name: null, email: null, phone: null, handWrittenName: null },
        vehicleRegistrationNumber,
        contractNumber,
    };
}
export function thirdPartyFormValuesToPostData({ iri, clientIdentifier, contact, insuranceCompany, vehicleRegistrationNumber, contractNumber, }) {
    return {
        id: iri,
        clientIdentifier,
        contact,
        insuranceCompany: insuranceCompany.name || insuranceCompany.handWrittenName
            ? insuranceCompanyFormValuesToPostData(insuranceCompany)
            : null,
        vehicleRegistrationNumber,
        contractNumber,
    };
}
