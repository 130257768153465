import { ModelFieldInputType } from '@shared/modelField/modelField';
import { translatableObjectToChoices } from '@shared/choice';
import { OTHER_DAMAGE_TYPE_TRAD_KEYS_AND_IMAGES } from '@shared/constants/breakInDamages/OtherConstants';
import { OtherDamageType } from '@shared/types/api/breakInDamage';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <Other>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function otherBreakInDamageEditionFields(model, translate) {
    const fields = [];
    fields.push({
        label: translate('model.break_in_damage.other.type'),
        key: 'otherDamageType',
        value: model.otherDamageType,
        displayedValue: translate('claim.declaration.break_in_damages.form.other-damage.type.' + model.otherDamageType),
        editable: {
            input: {
                type: ModelFieldInputType.Radio,
                choices: translatableObjectToChoices(OTHER_DAMAGE_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                vertical: true,
            },
            modelKey: 'otherDamageType',
        },
    });
    if (model.otherDamageType === OtherDamageType.Other) {
        fields.push({
            label: translate('model.break_in_damage.other.name'),
            key: 'otherDamageName',
            value: model.otherDamageName,
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'otherDamageName',
            },
        });
    }
    fields.push({
        label: translate('model.break_in_damage.other.description'),
        key: 'otherDamageDescription',
        value: model.otherDamageDescription,
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'otherDamageDescription',
        },
    });
    return fields;
}
