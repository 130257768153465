export var ApiResourceName;
(function (ApiResourceName) {
    ApiResourceName["Users"] = "users";
    ApiResourceName["Claims"] = "claims";
    ApiResourceName["Materials"] = "materials";
    ApiResourceName["ClaimTypes"] = "claim_types";
    ApiResourceName["HousingTypes"] = "housing_types";
    ApiResourceName["MarketShares"] = "market_shares";
    ApiResourceName["ArtisanAppointments"] = "artisan_appointments";
    ApiResourceName["Settings"] = "settings";
    ApiResourceName["HelpersContent"] = "help_contents";
    ApiResourceName["StepHelpers"] = "step_helps";
    ApiResourceName["CoveringCategories"] = "covering_categories";
    ApiResourceName["ExternalContracts"] = "external/contracts";
    ApiResourceName["ExternalClaims"] = "external/claims";
    ApiResourceName["ExternalSearchEnabled"] = "external/search_enabled";
    ApiResourceName["ContractsPrime"] = "contracts/prime";
    // Uploaded app files entity without content data
    ApiResourceName["UploadedAppFiles"] = "uploaded_app_files";
    // File content data
    ApiResourceName["Files"] = "file";
    ApiResourceName["Countings"] = "countings";
    ApiResourceName["Allowances"] = "allowances";
    ApiResourceName["AllowanceCategoryProductLines"] = "allowance_category_product_lines";
    ApiResourceName["InsuranceContracts"] = "insurance_contracts";
    ApiResourceName["SurfaceDamages"] = "surface_damages";
    ApiResourceName["WaterDamageQualifications"] = "water_damage_qualifications";
    ApiResourceName["RobberyDamageQualifications"] = "robbery_damage_qualifications";
    ApiResourceName["DroughtDamageQualifications"] = "drought_damage_qualifications";
    ApiResourceName["InsuranceCompanies"] = "insurance_companies";
    ApiResourceName["ThirdParties"] = "third_parties";
    ApiResourceName["HourlyRates"] = "hourly_rates";
    ApiResourceName["MaterialObsolescences"] = "material_obsolescences";
    ApiResourceName["Receipts"] = "receipts";
    ApiResourceName["ConstructionJobCategories"] = "construction_job_categories";
    ApiResourceName["ProductLines"] = "product_lines";
    ApiResourceName["Experts"] = "experts";
    ApiResourceName["AllowanceCategories"] = "allowance_categories";
    ApiResourceName["HousingCapacities"] = "housing_capacities";
    ApiResourceName["Departments"] = "departments";
    ApiResourceName["Coverings"] = "coverings";
    ApiResourceName["RoomDamages"] = "room_damages";
    ApiResourceName["ExpertAppointments"] = "expert_appointments";
    ApiResourceName["PersonalProperties"] = "personal_properties";
    ApiResourceName["ExternalDamages"] = "external_damages";
    ApiResourceName["AwningAllowanceTypes"] = "awning_types";
    ApiResourceName["GateTypes"] = "gate_types";
    ApiResourceName["BreakInDamages"] = "break_in_damages";
    ApiResourceName["Contracts"] = "contracts";
    ApiResourceName["PersonalPropertyCategories"] = "personal_property_categories";
    ApiResourceName["UploadedAppFileCollection"] = "uploaded_app_file_collections";
    ApiResourceName["Equipments"] = "equipment";
    ApiResourceName["DamagesValuations"] = "damages_valuations";
    ApiResourceName["MessageTemplate"] = "message_templates";
})(ApiResourceName || (ApiResourceName = {}));
