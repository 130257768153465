import { GOOD_DAMAGE_TYPE_LABELS, PIPE_TYPE_MODEL_LABELS, PIPE_TYPE_MODEL_CHOICES, } from '../goodDamage';
import { parseDimension, parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { translateChoices } from '@shared/choice';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { PipeType } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export function visiblePipeTitle(model, translate) {
    if (!model.type)
        return null;
    let key = GOOD_DAMAGE_TYPE_LABELS[model.type];
    if (model.pipeType && model.pipeType !== PipeType.UNKNOWN) {
        key += `.title.${model.pipeType}`;
    }
    return translate(key);
}
export function visiblePipeDetails(model) {
    if (!model.type || !model.pipeType || !model.length)
        return null;
    return parseDimension(model.length);
}
export function visiblePipeFields(model, context) {
    var _a;
    const { translate, index } = context;
    const fields = [
        {
            label: translate('model.good_damage.visible_pipes.pipe_type'),
            value: model.pipeType,
            displayedValue: optionalTranslatableEnumTransform(model.pipeType, PIPE_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(PIPE_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.pipeType`,
                toPayload: (pipeType) => ({
                    pipeType,
                    length: [PipeType.FLEXIBLE, null].includes(pipeType) ? null : model.length,
                }),
            },
        },
    ];
    if (model.pipeType && model.pipeType !== PipeType.FLEXIBLE) {
        fields.push({
            label: translate('model.good_damage.visible_pipes.length'),
            value: ((_a = model.length) !== null && _a !== void 0 ? _a : 0) / 1000,
            displayedValue: parseOptionalDimension(model.length),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'm',
                modelKey: `goodDamages.${index}.length`,
                toPayload: (length) => ({ length: length * 1000 }),
            },
        });
    }
    return fields;
}
