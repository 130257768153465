import { parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { ModelFieldInputType } from '@shared/modelField/modelField';
export const electricalSheathFields = (model, { translate }) => {
    return [
        {
            label: translate('model.external_damage.electrical_sheath.length'),
            value: model.sheathLength,
            displayedValue: parseOptionalDimension(model.sheathLength),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'm',
                modelKey: 'sheathLength',
            },
        },
    ];
};
