export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":{"de":["en","fr"],"en":["fr"],"it":["en","fr"]},"silentFallbackWarn":true,"silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"name":"Deutsch","code":"de","iso":"de-DE","file":"de.ts"},{"name":"English","code":"en","iso":"en-US","file":"en.ts"},{"name":"Francais","code":"fr","iso":"fr-FR","file":"fr.ts"},{"name":"Italiano","code":"it","iso":"it-IT","file":"it.ts"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "i18n/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"Deutsch","code":"de","iso":"de-DE","file":"de.ts"},{"name":"English","code":"en","iso":"en-US","file":"en.ts"},{"name":"Francais","code":"fr","iso":"fr-FR","file":"fr.ts"},{"name":"Italiano","code":"it","iso":"it-IT","file":"it.ts"}],
  localeCodes: ["de","en","fr","it"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "d",
  3: "e",
  4: ".",
  5: "t",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: ".",
  14: ".",
  15: "/",
  16: ".",
  17: ".",
  18: "/",
  19: "s",
  20: "r",
  21: "c",
  22: "/",
  23: "d",
  24: "e",
  25: "c",
  26: "l",
  27: "a",
  28: "r",
  29: "a",
  30: "t",
  31: "i",
  32: "o",
  33: "n",
  34: "/",
  35: "i",
  36: "1",
  37: "8",
  38: "n",
  39: "/",
  40: "d",
  41: "e",
  42: ".",
  43: "t",
  44: "s",
  45: "\"",
  46: ",",
  47: "\"",
  48: "e",
  49: "n",
  50: ".",
  51: "t",
  52: "s",
  53: "\"",
  54: ":",
  55: "\"",
  56: ".",
  57: ".",
  58: "/",
  59: ".",
  60: ".",
  61: "/",
  62: ".",
  63: ".",
  64: "/",
  65: "s",
  66: "r",
  67: "c",
  68: "/",
  69: "d",
  70: "e",
  71: "c",
  72: "l",
  73: "a",
  74: "r",
  75: "a",
  76: "t",
  77: "i",
  78: "o",
  79: "n",
  80: "/",
  81: "i",
  82: "1",
  83: "8",
  84: "n",
  85: "/",
  86: "e",
  87: "n",
  88: ".",
  89: "t",
  90: "s",
  91: "\"",
  92: ",",
  93: "\"",
  94: "f",
  95: "r",
  96: ".",
  97: "t",
  98: "s",
  99: "\"",
  100: ":",
  101: "\"",
  102: ".",
  103: ".",
  104: "/",
  105: ".",
  106: ".",
  107: "/",
  108: ".",
  109: ".",
  110: "/",
  111: "s",
  112: "r",
  113: "c",
  114: "/",
  115: "d",
  116: "e",
  117: "c",
  118: "l",
  119: "a",
  120: "r",
  121: "a",
  122: "t",
  123: "i",
  124: "o",
  125: "n",
  126: "/",
  127: "i",
  128: "1",
  129: "8",
  130: "n",
  131: "/",
  132: "f",
  133: "r",
  134: ".",
  135: "t",
  136: "s",
  137: "\"",
  138: ",",
  139: "\"",
  140: "i",
  141: "t",
  142: ".",
  143: "t",
  144: "s",
  145: "\"",
  146: ":",
  147: "\"",
  148: ".",
  149: ".",
  150: "/",
  151: ".",
  152: ".",
  153: "/",
  154: ".",
  155: ".",
  156: "/",
  157: "s",
  158: "r",
  159: "c",
  160: "/",
  161: "d",
  162: "e",
  163: "c",
  164: "l",
  165: "a",
  166: "r",
  167: "a",
  168: "t",
  169: "i",
  170: "o",
  171: "n",
  172: "/",
  173: "i",
  174: "1",
  175: "8",
  176: "n",
  177: "/",
  178: "i",
  179: "t",
  180: ".",
  181: "t",
  182: "s",
  183: "\"",
  184: "}",
}

export const localeMessages = {
  'de.ts': () => import('../../../../src/declaration/i18n/de.ts' /* webpackChunkName: "lang-de.ts" */),
  'en.ts': () => import('../../../../src/declaration/i18n/en.ts' /* webpackChunkName: "lang-en.ts" */),
  'fr.ts': () => import('../../../../src/declaration/i18n/fr.ts' /* webpackChunkName: "lang-fr.ts" */),
  'it.ts': () => import('../../../../src/declaration/i18n/it.ts' /* webpackChunkName: "lang-it.ts" */),
}
