import { CLAIM_QUALIFICATION_KEY, ClaimTypeTrigram } from '@shared/types/api/claim';
export const name = 'qualification';
export const namespaced = true;
export const state = () => ({
    typeTrigram: ClaimTypeTrigram.Empty,
    qualification: null,
});
export const getters = {
    typeTrigram(state) {
        return state.typeTrigram;
    },
};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    async setClaimType({ state, rootState, commit, dispatch }, { typeIRI, typeTrigram, step }) {
        if (!rootState.claim.claim.id) {
            return;
        }
        // Do not send type in payload if not changed
        const payload = state.typeTrigram === typeTrigram ? { step } : { type: typeIRI, step };
        commit('SET_TYPE_TRIGRAM', typeTrigram);
        await dispatch('claim/saveClaimWithPayload', payload, { root: true });
        await dispatch('contract/fetchApplicableContractDetail', {}, { root: true });
        await dispatch('contract/fetchContractCoverageOptions', {}, { root: true });
    },
    updateStoreWithClaimResponse({ commit }, claimResponse) {
        var _a, _b;
        const trigram = (_b = (_a = claimResponse.type) === null || _a === void 0 ? void 0 : _a.trigram) !== null && _b !== void 0 ? _b : null;
        switch (trigram) {
            // Handled claim types qualifications
            case ClaimTypeTrigram.WaterDamage:
            case ClaimTypeTrigram.Drought:
            case ClaimTypeTrigram.StormSnowHail:
            case ClaimTypeTrigram.Electric:
            case ClaimTypeTrigram.Robbery:
            case ClaimTypeTrigram.Glass:
            case ClaimTypeTrigram.Fire:
                commit('SET_TYPE_TRIGRAM', trigram);
                commit('UPDATE_QUALIFICATION', claimResponse[CLAIM_QUALIFICATION_KEY[trigram]]);
                break;
            // Null, Empty or not yet handled claim type
            default:
                commit('RESET_STATE', trigram !== null && trigram !== void 0 ? trigram : ClaimTypeTrigram.Empty);
        }
    },
    updateQualification({ commit }, { qualificationPayload }) {
        commit('UPDATE_QUALIFICATION_WITH_PAYLOAD', qualificationPayload);
    },
    async updateAndSaveQualification({ state, dispatch, commit }, { qualificationPayload, step, claimPayload }) {
        var _a;
        commit('UPDATE_QUALIFICATION_WITH_PAYLOAD', qualificationPayload);
        const qualificationKey = CLAIM_QUALIFICATION_KEY[state.typeTrigram];
        if (!qualificationKey) {
            dispatch('error/add', 'Une erreur est survenue', { root: true });
            return;
        }
        const id = (_a = state.qualification) === null || _a === void 0 ? void 0 : _a.id;
        const payload = {
            ...claimPayload,
            [qualificationKey]: {
                ...qualificationPayload,
                id,
            },
            step,
        };
        await dispatch('claim/saveClaimWithPayload', payload, { root: true });
    },
};
export const mutations = {
    RESET_STATE(stateObject, typeTrigram) {
        Object.assign(stateObject, state());
        if (typeTrigram) {
            stateObject.typeTrigram = typeTrigram;
        }
    },
    SET_TYPE_TRIGRAM(state, typeTrigram) {
        state.typeTrigram = typeTrigram;
    },
    UPDATE_QUALIFICATION_WITH_PAYLOAD(state, qualificationPayload) {
        if (!state.qualification) {
            return;
        }
        Object.entries(qualificationPayload).forEach(([key, value]) => {
            state.qualification[key] = value;
        });
    },
    UPDATE_QUALIFICATION(state, qualification) {
        state.qualification = qualification;
    },
};
