// UploadedAppFiles are created/deleted but never updated so we can cache them
const cache = new Map();
export function fetchFile(fileIri, $axios) {
    const cached = cache.get(fileIri);
    if (cached) {
        return cached;
    }
    const promise = $axios.$get(fileIri);
    cache.set(fileIri, promise);
    return promise;
}
export function cacheFile(file) {
    cache.set(file.id, Promise.resolve(file));
}
