import { ExternalDamageLocalizationSlug, ExternalDamageOrigin, ExternalDamageSlug, } from '@shared/types/api/externalDamage';
export * from './ExternalDamageLocalization';
export * from './ExternalDamageSlug';
export * from './AerationGridConstants';
export * from './AntennaConstants';
export * from './AwningConstants';
export * from './BasinConstants';
export * from './ChimneyConstants';
export * from './ElectricalSheathConstants';
export * from './FenceConstants';
export * from './FrontWallConstants';
export * from './GarageDoorConstants';
export * from './GateConstants';
export * from './GutterConstants';
export * from './LightSourceConstants';
export * from './LittleGateConstants';
export * from './ShutterConstants';
export * from './TileDisplacementConstants';
export * from './VisiblePipeConstants';
export * from './WindowConstants';
export var ExternalDamageImageComponentName;
(function (ExternalDamageImageComponentName) {
    ExternalDamageImageComponentName["ExternalView"] = "ExternalView";
    ExternalDamageImageComponentName["RoofView"] = "RoofView";
    ExternalDamageImageComponentName["GardenView"] = "GardenView";
    ExternalDamageImageComponentName["OutbuildingView"] = "OutbuildingView";
})(ExternalDamageImageComponentName || (ExternalDamageImageComponentName = {}));
export var ExternalDamageDescriptionComponent;
(function (ExternalDamageDescriptionComponent) {
    ExternalDamageDescriptionComponent["GarageDoor"] = "GarageDoor";
    ExternalDamageDescriptionComponent["TileDisplacement"] = "TileDisplacement";
    ExternalDamageDescriptionComponent["Chimney"] = "Chimney";
    ExternalDamageDescriptionComponent["Antenna"] = "Antenna";
    ExternalDamageDescriptionComponent["Basin"] = "Basin";
    ExternalDamageDescriptionComponent["AerationGrid"] = "AerationGrid";
    ExternalDamageDescriptionComponent["VisiblePipe"] = "VisiblePipe";
    ExternalDamageDescriptionComponent["Gutter"] = "Gutter";
    ExternalDamageDescriptionComponent["Fence"] = "Fence";
    ExternalDamageDescriptionComponent["Shutters"] = "Shutters";
    ExternalDamageDescriptionComponent["Window"] = "Window";
    ExternalDamageDescriptionComponent["Awning"] = "Awning";
    ExternalDamageDescriptionComponent["Gate"] = "Gate";
    ExternalDamageDescriptionComponent["LittleGate"] = "LittleGate";
    ExternalDamageDescriptionComponent["FrontWall"] = "FrontWall";
    ExternalDamageDescriptionComponent["ElectricalSheath"] = "ElectricalSheath";
    ExternalDamageDescriptionComponent["LightSource"] = "LightSource";
    ExternalDamageDescriptionComponent["Veranda"] = "Veranda";
    ExternalDamageDescriptionComponent["Pergola"] = "Pergola";
    ExternalDamageDescriptionComponent["Door"] = "Door";
})(ExternalDamageDescriptionComponent || (ExternalDamageDescriptionComponent = {}));
export const EXTERNAL_DAMAGE_SLUG_TO_IMAGE_COMPONENT = {
    [ExternalDamageOrigin.Storm]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: {
            component: ExternalDamageImageComponentName.GardenView,
            zoomAndPan: null,
        },
        [ExternalDamageLocalizationSlug.Roof]: {
            component: ExternalDamageImageComponentName.RoofView,
            zoomAndPan: null,
        },
        [ExternalDamageLocalizationSlug.FenceAndGate]: {
            component: ExternalDamageImageComponentName.ExternalView,
            zoomAndPan: { zoomX: 2, zoomY: 2.59, panX: 300, panY: 300 },
        },
        [ExternalDamageLocalizationSlug.Outbuilding]: {
            component: ExternalDamageImageComponentName.OutbuildingView,
            zoomAndPan: null,
        },
    },
    [ExternalDamageOrigin.Snow]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: {
            component: ExternalDamageImageComponentName.GardenView,
            zoomAndPan: null,
        },
        [ExternalDamageLocalizationSlug.Roof]: {
            component: ExternalDamageImageComponentName.RoofView,
            zoomAndPan: null,
        },
        [ExternalDamageLocalizationSlug.Outbuilding]: {
            component: ExternalDamageImageComponentName.OutbuildingView,
            zoomAndPan: null,
        },
    },
    [ExternalDamageOrigin.Hail]: {
        [ExternalDamageLocalizationSlug.GardenTerrace]: {
            component: ExternalDamageImageComponentName.GardenView,
            zoomAndPan: null,
        },
        [ExternalDamageLocalizationSlug.Roof]: {
            component: ExternalDamageImageComponentName.RoofView,
            zoomAndPan: null,
        },
        [ExternalDamageLocalizationSlug.FenceAndGate]: {
            component: ExternalDamageImageComponentName.ExternalView,
            zoomAndPan: { zoomX: 2, zoomY: 2.59, panX: 300, panY: 300 },
        },
        [ExternalDamageLocalizationSlug.Outbuilding]: {
            component: ExternalDamageImageComponentName.OutbuildingView,
            zoomAndPan: null,
        },
    },
    [ExternalDamageOrigin.Fire]: {
        [ExternalDamageLocalizationSlug.Roof]: {
            component: ExternalDamageImageComponentName.RoofView,
            zoomAndPan: null,
        },
        [ExternalDamageLocalizationSlug.FenceAndGate]: {
            component: ExternalDamageImageComponentName.ExternalView,
            zoomAndPan: { zoomX: 2, zoomY: 2.59, panX: 300, panY: 300 },
        },
        [ExternalDamageLocalizationSlug.Other]: {
            component: ExternalDamageImageComponentName.GardenView,
            zoomAndPan: null,
        },
    },
};
export const EXTERNAL_DAMAGE_SLUG_TO_DESCRIPTION_COMPONENT = {
    [ExternalDamageSlug.GardenShelter]: null,
    [ExternalDamageSlug.SwimpoolCover]: null,
    [ExternalDamageSlug.Plants]: null,
    [ExternalDamageSlug.GarageDoor]: ExternalDamageDescriptionComponent.GarageDoor,
    [ExternalDamageSlug.TerraceFloor]: null,
    [ExternalDamageSlug.Pergola]: ExternalDamageDescriptionComponent.Pergola,
    [ExternalDamageSlug.Awning]: ExternalDamageDescriptionComponent.Awning,
    [ExternalDamageSlug.Veranda]: ExternalDamageDescriptionComponent.Veranda,
    [ExternalDamageSlug.RoofHole]: null,
    [ExternalDamageSlug.TileDisplacement]: ExternalDamageDescriptionComponent.TileDisplacement,
    [ExternalDamageSlug.Framework]: null,
    [ExternalDamageSlug.Antenna]: ExternalDamageDescriptionComponent.Antenna,
    [ExternalDamageSlug.Chimney]: ExternalDamageDescriptionComponent.Chimney,
    [ExternalDamageSlug.Gutter]: ExternalDamageDescriptionComponent.Gutter,
    [ExternalDamageSlug.Shutters]: ExternalDamageDescriptionComponent.Shutters,
    [ExternalDamageSlug.Window]: ExternalDamageDescriptionComponent.Window,
    [ExternalDamageSlug.FrontWall]: ExternalDamageDescriptionComponent.FrontWall,
    [ExternalDamageSlug.ExternalPersonalProperty]: null,
    [ExternalDamageSlug.Fence]: ExternalDamageDescriptionComponent.Fence,
    [ExternalDamageSlug.Gate]: ExternalDamageDescriptionComponent.Gate,
    [ExternalDamageSlug.LittleGate]: ExternalDamageDescriptionComponent.LittleGate,
    [ExternalDamageSlug.StoneWork]: null,
    [ExternalDamageSlug.Roof]: null,
    [ExternalDamageSlug.Door]: ExternalDamageDescriptionComponent.Door,
    [ExternalDamageSlug.Other]: null,
    [ExternalDamageSlug.AerationGrid]: ExternalDamageDescriptionComponent.AerationGrid,
    [ExternalDamageSlug.Basin]: ExternalDamageDescriptionComponent.Basin,
    [ExternalDamageSlug.Faucet]: null,
    [ExternalDamageSlug.VisiblePipe]: ExternalDamageDescriptionComponent.VisiblePipe,
    [ExternalDamageSlug.LightSource]: ExternalDamageDescriptionComponent.LightSource,
    [ExternalDamageSlug.OutletOrSwitch]: null,
    [ExternalDamageSlug.ElectricalSheath]: ExternalDamageDescriptionComponent.ElectricalSheath,
    [ExternalDamageSlug.Barbecue]: null,
    [ExternalDamageSlug.Playground]: null,
    [ExternalDamageSlug.GardenLounge]: null,
    [ExternalDamageSlug.PizzaOven]: null,
    [ExternalDamageSlug.PhotovoltaicPanel]: null,
};
export function getExternalDamageChoices(indexedObject, origin, defaultValue = []) {
    var _a;
    if (!origin)
        return defaultValue;
    return (_a = indexedObject[origin]) !== null && _a !== void 0 ? _a : defaultValue;
}
