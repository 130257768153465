import { optionalBooleanTransform, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
import { ModelFieldInputPreset, ModelFieldInputType } from '@shared/modelField/modelField';
import { PERGOLA_DAMAGE_TYPES_CHOICES, PERGOLA_DAMAGE_TYPES_KEYS, PERGOLA_SPECIFICATIONS_MATERIAL_CHOICES, PERGOLA_SPECIFICATIONS_MATERIAL_KEYS, } from '@shared/constants/externalDamages/PergolaConstants';
import { translateChoices } from '@shared/choice';
import { ExternalDamageOrigin } from '@shared/types/api/externalDamage';
export const pergolaFields = (model, { stormSnowHailDamageCauseType, translate }) => {
    if (stormSnowHailDamageCauseType !== ExternalDamageOrigin.Hail)
        return [];
    return [
        {
            label: translate('model.external_damage.pergola.bioclimatic'),
            value: model.isBioclimatic,
            displayedValue: optionalBooleanTransform(model.isBioclimatic, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isBioclimatic',
            },
        },
        {
            label: translate('model.external_damage.pergola.material'),
            value: model.material,
            displayedValue: optionalTranslatableEnumTransform(model.material, PERGOLA_SPECIFICATIONS_MATERIAL_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    vertical: true,
                    choices: translateChoices(PERGOLA_SPECIFICATIONS_MATERIAL_CHOICES, translate),
                },
                modelKey: 'material',
            },
        },
        {
            label: translate('model.external_damage.pergola.damage_type'),
            value: model.damageType,
            displayedValue: optionalTranslatableEnumTransform(model.damageType, PERGOLA_DAMAGE_TYPES_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(PERGOLA_DAMAGE_TYPES_CHOICES, translate),
                },
                modelKey: 'damageType',
            },
        },
    ];
};
