import { Measurement } from '../breakInDamage';
export var GoodDamageCategory;
(function (GoodDamageCategory) {
    GoodDamageCategory["Plumbing"] = "plumbing";
    GoodDamageCategory["Heating"] = "heating";
    GoodDamageCategory["Opening"] = "opening";
    GoodDamageCategory["Electricity"] = "electricity";
    GoodDamageCategory["Furniture"] = "furniture";
    GoodDamageCategory["Other"] = "other";
})(GoodDamageCategory || (GoodDamageCategory = {}));
export var GoodDamagePlumbing;
(function (GoodDamagePlumbing) {
    GoodDamagePlumbing["AerationGrid"] = "aeration-grid";
    GoodDamagePlumbing["VisiblePipes"] = "visible-pipes";
    GoodDamagePlumbing["WC"] = "wc";
    GoodDamagePlumbing["Faucet"] = "faucet";
    GoodDamagePlumbing["Basin"] = "basin";
    GoodDamagePlumbing["Bathtub"] = "bathtub";
    GoodDamagePlumbing["Shower"] = "shower";
})(GoodDamagePlumbing || (GoodDamagePlumbing = {}));
export var GoodDamageHeating;
(function (GoodDamageHeating) {
    GoodDamageHeating["ElectricHeater"] = "electrical-heater";
    GoodDamageHeating["WaterRadiator"] = "water-radiator";
    GoodDamageHeating["Chimney"] = "chimney";
    GoodDamageHeating["Stove"] = "stove";
    GoodDamageHeating["TowelDryer"] = "towel-dryer";
})(GoodDamageHeating || (GoodDamageHeating = {}));
export var GoodDamageOpening;
(function (GoodDamageOpening) {
    GoodDamageOpening["Door"] = "door";
    GoodDamageOpening["Window"] = "window";
    GoodDamageOpening["FrenchWindow"] = "french-window";
    GoodDamageOpening["SlidingBay"] = "sliding-bay";
    GoodDamageOpening["GarageDoor"] = "garage-door";
})(GoodDamageOpening || (GoodDamageOpening = {}));
export var GoodDamageElectricity;
(function (GoodDamageElectricity) {
    GoodDamageElectricity["LightSource"] = "light-source";
    GoodDamageElectricity["OutletOrSwitch"] = "outlet-or-switch";
    GoodDamageElectricity["ExposedElectricalSheath"] = "exposed-electrical-sheath";
    GoodDamageElectricity["Switchboard"] = "switchboard";
    GoodDamageElectricity["ElectricMeter"] = "electric-meter";
})(GoodDamageElectricity || (GoodDamageElectricity = {}));
export var GoodDamageFurniture;
(function (GoodDamageFurniture) {
    GoodDamageFurniture["HighFurniture"] = "high-furniture";
    GoodDamageFurniture["LowFurniture"] = "low-furniture";
    GoodDamageFurniture["ColumnFurniture"] = "column-furniture";
    GoodDamageFurniture["FixedLayout"] = "fixed-layout";
    GoodDamageFurniture["Locker"] = "locker";
    GoodDamageFurniture["Credenza"] = "credenza";
    GoodDamageFurniture["Workplan"] = "workplan";
})(GoodDamageFurniture || (GoodDamageFurniture = {}));
export var GoodDamageOther;
(function (GoodDamageOther) {
    GoodDamageOther["Other"] = "other";
    GoodDamageOther["OtherPlumbing"] = "other-plumbing";
    GoodDamageOther["OtherHeating"] = "other-heating";
    GoodDamageOther["OtherOpening"] = "other-opening";
    GoodDamageOther["OtherFurniture"] = "other-furniture";
    GoodDamageOther["OtherElectricity"] = "other-electricity";
})(GoodDamageOther || (GoodDamageOther = {}));
export const GoodDamageType = {
    ...GoodDamagePlumbing,
    ...GoodDamageHeating,
    ...GoodDamageOpening,
    ...GoodDamageFurniture,
    ...GoodDamageElectricity,
    ...GoodDamageOther,
};
export const GoodDamageOtherTypeMap = {
    [GoodDamageCategory.Electricity]: GoodDamageOther.OtherElectricity,
    [GoodDamageCategory.Furniture]: GoodDamageOther.OtherFurniture,
    [GoodDamageCategory.Heating]: GoodDamageOther.OtherHeating,
    [GoodDamageCategory.Opening]: GoodDamageOther.OtherOpening,
    [GoodDamageCategory.Other]: GoodDamageOther.Other,
    [GoodDamageCategory.Plumbing]: GoodDamageOther.OtherPlumbing,
};
export var LockerDoorType;
(function (LockerDoorType) {
    LockerDoorType["SLIDING"] = "sliding-door";
    LockerDoorType["SWING"] = "swing-door";
})(LockerDoorType || (LockerDoorType = {}));
export var ElectricMeterType;
(function (ElectricMeterType) {
    ElectricMeterType["REGULAR"] = "regular";
    ElectricMeterType["LINKY"] = "linky";
})(ElectricMeterType || (ElectricMeterType = {}));
export var LightSourceType;
(function (LightSourceType) {
    LightSourceType["DCL"] = "device-connection-luminaires";
    LightSourceType["SPOT_LIGHT"] = "spot-light";
    LightSourceType["WALL_LIGHT"] = "wall-light";
})(LightSourceType || (LightSourceType = {}));
export var PipeType;
(function (PipeType) {
    PipeType["COPPER"] = "copper";
    PipeType["PEX"] = "pex";
    PipeType["FLEXIBLE"] = "flexible";
    PipeType["UNKNOWN"] = "unknown";
})(PipeType || (PipeType = {}));
export var WcType;
(function (WcType) {
    WcType["BLOCK"] = "block";
    WcType["HUNG"] = "hung";
    WcType["MACERATOR"] = "macerator";
})(WcType || (WcType = {}));
export var ElectricalHeaterType;
(function (ElectricalHeaterType) {
    ElectricalHeaterType["RADIANT_RADIATOR"] = "radiant-radiator";
    ElectricalHeaterType["CONVECTION_HEATER"] = "convection-heater";
    ElectricalHeaterType["INERTIA_RADIATOR"] = "inertia-radiator";
    ElectricalHeaterType["UNKNOWN"] = "unknown";
})(ElectricalHeaterType || (ElectricalHeaterType = {}));
export var TowelDryerType;
(function (TowelDryerType) {
    TowelDryerType["WATER_DRYER"] = "water-dryer";
    TowelDryerType["ELECTRICAL_DRYER"] = "electrical-dryer";
    TowelDryerType["UNKNOWN"] = "unknown";
})(TowelDryerType || (TowelDryerType = {}));
export var WaterRadiatorDamageType;
(function (WaterRadiatorDamageType) {
    WaterRadiatorDamageType["AESTHETIC"] = "aesthetic";
    WaterRadiatorDamageType["DAMAGED"] = "damaged";
})(WaterRadiatorDamageType || (WaterRadiatorDamageType = {}));
export var WaterRadiatorMaterial;
(function (WaterRadiatorMaterial) {
    WaterRadiatorMaterial["CAST_IRON"] = "cast-iron";
    WaterRadiatorMaterial["ALUMINUM_OR_STEEL"] = "aluminum-or-steel";
    WaterRadiatorMaterial["UNKNOWN"] = "unknown";
})(WaterRadiatorMaterial || (WaterRadiatorMaterial = {}));
export var SlidingBayGlazingType;
(function (SlidingBayGlazingType) {
    SlidingBayGlazingType["SIMPLE"] = "simple";
    SlidingBayGlazingType["DOUBLE"] = "double";
    SlidingBayGlazingType["TRIPLE"] = "triple";
    SlidingBayGlazingType["UNKNOWN"] = "unknown";
})(SlidingBayGlazingType || (SlidingBayGlazingType = {}));
export var SlidingBayGlassType;
(function (SlidingBayGlassType) {
    SlidingBayGlassType["LAMINATED"] = "laminated";
    SlidingBayGlassType["NON_LAMINATED"] = "non-laminated";
    SlidingBayGlassType["UNKNOWN"] = "unknown";
})(SlidingBayGlassType || (SlidingBayGlassType = {}));
export var SlidingBayLeafWidth;
(function (SlidingBayLeafWidth) {
    SlidingBayLeafWidth["SMALL"] = "less-than-220cm";
    SlidingBayLeafWidth["MEDIUM"] = "between-220-and-260cm";
    SlidingBayLeafWidth["LARGE"] = "more-than-260cm";
})(SlidingBayLeafWidth || (SlidingBayLeafWidth = {}));
export var GarageDoorType;
(function (GarageDoorType) {
    GarageDoorType["TIPPER"] = "tipper-door";
    GarageDoorType["SECTIONAL"] = "sectional-door";
    GarageDoorType["SLIDING"] = "sliding-door";
    GarageDoorType["ROLLING"] = "rolling-door";
    GarageDoorType["FLAPPY"] = "flappy-door";
    GarageDoorType["FOLDING"] = "folding-door";
})(GarageDoorType || (GarageDoorType = {}));
export var GarageDoorMaterial;
(function (GarageDoorMaterial) {
    GarageDoorMaterial["WOOD"] = "wood";
    GarageDoorMaterial["PVC"] = "pvc";
    GarageDoorMaterial["METAL"] = "metal";
})(GarageDoorMaterial || (GarageDoorMaterial = {}));
export var GarageDoorDamageType;
(function (GarageDoorDamageType) {
    GarageDoorDamageType["AESTHETIC"] = "aesthetic";
    GarageDoorDamageType["DAMAGED"] = "damaged";
})(GarageDoorDamageType || (GarageDoorDamageType = {}));
export var GarageDoorWidth;
(function (GarageDoorWidth) {
    GarageDoorWidth["STANDARD"] = "standard";
    GarageDoorWidth["LARGE"] = "large";
})(GarageDoorWidth || (GarageDoorWidth = {}));
export var GarageDoorOpeningMode;
(function (GarageDoorOpeningMode) {
    GarageDoorOpeningMode["MANUAL"] = "manual";
    GarageDoorOpeningMode["MOTORIZED"] = "motorized";
})(GarageDoorOpeningMode || (GarageDoorOpeningMode = {}));
export var DoorType;
(function (DoorType) {
    DoorType["MAIN_ENTRANCE"] = "main-entrance";
    DoorType["SERVICE"] = "service";
    DoorType["INDOOR"] = "indoor";
})(DoorType || (DoorType = {}));
export var DoorMaterial;
(function (DoorMaterial) {
    DoorMaterial["WOOD"] = "wood";
    DoorMaterial["PVC"] = "pvc";
    DoorMaterial["METAL"] = "metal";
})(DoorMaterial || (DoorMaterial = {}));
export var DoorDamageType;
(function (DoorDamageType) {
    DoorDamageType["AESTHETIC"] = "aesthetic";
    DoorDamageType["DAMAGED"] = "damaged";
})(DoorDamageType || (DoorDamageType = {}));
export var DoorGlazingType;
(function (DoorGlazingType) {
    DoorGlazingType["SIMPLE"] = "simple";
    DoorGlazingType["DOUBLE"] = "double";
    DoorGlazingType["TRIPLE"] = "triple";
    DoorGlazingType["UNKNOWN"] = "unknown";
})(DoorGlazingType || (DoorGlazingType = {}));
export var DoorGlassType;
(function (DoorGlassType) {
    DoorGlassType["LAMINATED"] = "laminated";
    DoorGlassType["NON_LAMINATED"] = "non-laminated";
    DoorGlassType["UNKNOWN"] = "unknown";
})(DoorGlassType || (DoorGlassType = {}));
export var DoorLockType;
(function (DoorLockType) {
    DoorLockType["NO_LOCK"] = "no-lock";
    DoorLockType["ONE_LOCK"] = "one-lock";
    DoorLockType["TWO_LOCKS"] = "two-locks";
    DoorLockType["THREE_LOCKS"] = "three-locks";
    DoorLockType["FIXATED_MULTIPOINT_LOCK"] = "fixated-multipoint-lock";
    DoorLockType["FAIRED_MULTIPOINT_LOCK"] = "faired-multipoint-lock";
    DoorLockType["INTEGRATED_MULTIPOINT_LOCK"] = "integrated-multipoint-lock";
})(DoorLockType || (DoorLockType = {}));
export var FrenchWindowMaterial;
(function (FrenchWindowMaterial) {
    FrenchWindowMaterial["WOOD"] = "wood";
    FrenchWindowMaterial["PVC"] = "pvc";
    FrenchWindowMaterial["METAL"] = "metal";
})(FrenchWindowMaterial || (FrenchWindowMaterial = {}));
export var FrenchWindowDamageType;
(function (FrenchWindowDamageType) {
    FrenchWindowDamageType["AESTHETIC"] = "aesthetic";
    FrenchWindowDamageType["DAMAGED"] = "damaged";
})(FrenchWindowDamageType || (FrenchWindowDamageType = {}));
export var FrenchWindowLeafWidth;
(function (FrenchWindowLeafWidth) {
    FrenchWindowLeafWidth["STANDARD"] = "standard";
    FrenchWindowLeafWidth["LARGE"] = "large";
})(FrenchWindowLeafWidth || (FrenchWindowLeafWidth = {}));
export var FrenchWindowLeafHeight;
(function (FrenchWindowLeafHeight) {
    FrenchWindowLeafHeight["STANDARD"] = "standard";
    FrenchWindowLeafHeight["LARGE"] = "large";
})(FrenchWindowLeafHeight || (FrenchWindowLeafHeight = {}));
export var FrenchWindowGlazingType;
(function (FrenchWindowGlazingType) {
    FrenchWindowGlazingType["SIMPLE"] = "simple";
    FrenchWindowGlazingType["DOUBLE"] = "double";
    FrenchWindowGlazingType["TRIPLE"] = "triple";
    FrenchWindowGlazingType["UNKNOWN"] = "unknown";
})(FrenchWindowGlazingType || (FrenchWindowGlazingType = {}));
export var FrenchWindowGlassType;
(function (FrenchWindowGlassType) {
    FrenchWindowGlassType["LAMINATED"] = "laminated";
    FrenchWindowGlassType["NON_LAMINATED"] = "non-laminated";
    FrenchWindowGlassType["UNKNOWN"] = "unknown";
})(FrenchWindowGlassType || (FrenchWindowGlassType = {}));
export var WindowMaterial;
(function (WindowMaterial) {
    WindowMaterial["WOOD"] = "wood";
    WindowMaterial["PVC"] = "pvc";
    WindowMaterial["METAL"] = "metal";
})(WindowMaterial || (WindowMaterial = {}));
export var WindowDamageType;
(function (WindowDamageType) {
    WindowDamageType["AESTHETIC"] = "aesthetic";
    WindowDamageType["DAMAGED"] = "damaged";
})(WindowDamageType || (WindowDamageType = {}));
export var WindowOneLeafWidth;
(function (WindowOneLeafWidth) {
    WindowOneLeafWidth[WindowOneLeafWidth["LessThan60cm"] = Measurement.LessThan60cm] = "LessThan60cm";
    WindowOneLeafWidth[WindowOneLeafWidth["Between60And120cm"] = Measurement.Between60And120cm] = "Between60And120cm";
    WindowOneLeafWidth[WindowOneLeafWidth["MoreThan120cm"] = Measurement.MoreThan120cm] = "MoreThan120cm";
})(WindowOneLeafWidth || (WindowOneLeafWidth = {}));
export var WindowTwoLeavesWidth;
(function (WindowTwoLeavesWidth) {
    WindowTwoLeavesWidth[WindowTwoLeavesWidth["LessThan95cm"] = Measurement.LessThan95cm] = "LessThan95cm";
    WindowTwoLeavesWidth[WindowTwoLeavesWidth["Between95And145cm"] = Measurement.Between95And145cm] = "Between95And145cm";
    WindowTwoLeavesWidth[WindowTwoLeavesWidth["MoreThan145cm"] = Measurement.MoreThan145cm] = "MoreThan145cm";
})(WindowTwoLeavesWidth || (WindowTwoLeavesWidth = {}));
export var WindowOneLeafHeight;
(function (WindowOneLeafHeight) {
    WindowOneLeafHeight[WindowOneLeafHeight["LessThan60cm"] = Measurement.LessThan60cm] = "LessThan60cm";
    WindowOneLeafHeight[WindowOneLeafHeight["Between60And120cm"] = Measurement.Between60And120cm] = "Between60And120cm";
    WindowOneLeafHeight[WindowOneLeafHeight["MoreThan120cm"] = Measurement.MoreThan120cm] = "MoreThan120cm";
})(WindowOneLeafHeight || (WindowOneLeafHeight = {}));
export var WindowTwoLeavesHeight;
(function (WindowTwoLeavesHeight) {
    WindowTwoLeavesHeight[WindowTwoLeavesHeight["LessThan100cm"] = Measurement.LessThan100cm] = "LessThan100cm";
    WindowTwoLeavesHeight[WindowTwoLeavesHeight["Between100And180cm"] = Measurement.Between100And180cm] = "Between100And180cm";
    WindowTwoLeavesHeight[WindowTwoLeavesHeight["MoreThan180cm"] = Measurement.MoreThan180cm] = "MoreThan180cm";
})(WindowTwoLeavesHeight || (WindowTwoLeavesHeight = {}));
export const WindowLeafWidth = { ...WindowOneLeafWidth, ...WindowTwoLeavesWidth };
export const WindowLeafHeight = { ...WindowOneLeafHeight, ...WindowTwoLeavesHeight };
export var WindowGlazingType;
(function (WindowGlazingType) {
    WindowGlazingType["SIMPLE"] = "simple";
    WindowGlazingType["DOUBLE"] = "double";
    WindowGlazingType["TRIPLE"] = "triple";
    WindowGlazingType["UNKNOWN"] = "unknown";
})(WindowGlazingType || (WindowGlazingType = {}));
export var WindowGlassType;
(function (WindowGlassType) {
    WindowGlassType["LAMINATED"] = "laminated";
    WindowGlassType["NON_LAMINATED"] = "non-laminated";
    WindowGlassType["UNKNOWN"] = "unknown";
})(WindowGlassType || (WindowGlassType = {}));
