import { optionalBooleanTransform, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
import { DOOR_MATERIALS_KEYS, DOOR_MATERIALS_CHOICES, DOOR_IMPACT_TRAD_KEYS, DOOR_IMPACT_CHOICES, DOOR_LEAVES_CHOICES, DOOR_GLASS_TYPE_TYPE_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/externalDamages/DoorConstants';
import { ModelFieldInputPreset, ModelFieldInputType, } from '@shared/modelField/modelField';
import { translateChoices } from '@shared/choice';
import { DoorMaterial } from '@shared/types/api/externalDamage';
import { formatOptionalLenght } from '@shared/utils/formatLength';
import { DOOR_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES } from '@shared/constants/breakInDamages/DoorConstants';
import { DOOR_GLASS_TYPE_CHOICES, DOOR_GLAZING_TYPE_CHOICES, DOOR_GLAZING_TYPE_LABELS, } from '@shared/roomDamage/goodDamage';
export const doorFields = (model, context) => {
    const { translate } = context;
    const fields = [
        {
            label: translate('model.external_damage.door_material'),
            value: model.doorMaterial,
            displayedValue: optionalTranslatableEnumTransform(model.doorMaterial, DOOR_MATERIALS_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(DOOR_MATERIALS_CHOICES, translate),
                },
                modelKey: 'doorMaterial',
            },
        },
        {
            label: translate('model.external_damage.door_leaves_type'),
            value: model.doorMaterial,
            displayedValue: optionalTranslatableEnumTransform(model.doorLeavesType, DOOR_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(DOOR_LEAVES_CHOICES, translate),
                },
                modelKey: 'doorLeavesType',
            },
        },
        {
            label: translate('model.external_damage.door_has_windows'),
            value: model.doorHasWindows,
            displayedValue: optionalBooleanTransform(model.doorHasWindows, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'doorHasWindows',
            },
        },
    ];
    if (model.doorMaterial === DoorMaterial.Wood) {
        fields.push({
            label: translate('model.external_damage.door_impacts'),
            value: model.doorImpactsNumber,
            displayedValue: optionalTranslatableEnumTransform(model.doorImpactsNumber, DOOR_IMPACT_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(DOOR_IMPACT_CHOICES, translate),
                },
                modelKey: 'doorImpactsNumber',
            },
        });
    }
    if (model.doorHasWindows) {
        fields.push({
            label: translate('model.external_damage.door_has_broken_windows'),
            value: model.doorHasBrokenTiles,
            displayedValue: optionalBooleanTransform(model.doorHasBrokenTiles, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'doorHasBrokenTiles',
            },
        });
        if (model.doorHasBrokenTiles) {
            fields.push({
                label: translate('model.external_damage.door_number_of_broken_tiles'),
                value: model.doorNumberOfBrokenTiles,
                displayedValue: '' + model.doorNumberOfBrokenTiles,
                editable: {
                    input: {
                        type: ModelFieldInputType.Number,
                    },
                    modelKey: `doorNumberOfBrokenTiles`,
                },
            }, {
                label: translate('model.external_damage.door_biggest_broken_window_tile_height'),
                value: model.doorBiggestBrokenWindowTileHeight,
                displayedValue: formatOptionalLenght(model.doorBiggestBrokenWindowTileHeight
                    ? +model.doorBiggestBrokenWindowTileHeight
                    : null, 'cm', '-'),
                editable: {
                    unit: 'cm',
                    input: {
                        type: ModelFieldInputType.Number,
                    },
                    modelKey: `doorBiggestBrokenWindowTileHeight`,
                },
            }, {
                label: translate('model.external_damage.door_biggest_broken_window_tile_width'),
                value: model.doorBiggestBrokenWindowTileWidth,
                displayedValue: formatOptionalLenght(model.doorBiggestBrokenWindowTileWidth ? +model.doorBiggestBrokenWindowTileWidth : null, 'cm', '-'),
                editable: {
                    unit: 'cm',
                    input: {
                        type: ModelFieldInputType.Number,
                    },
                    modelKey: `doorBiggestBrokenWindowTileWidth`,
                },
            }, {
                label: translate('model.external_damage.door_glazzing_type'),
                value: model.doorGlazingType,
                displayedValue: optionalTranslatableEnumTransform(model.doorGlazingType, DOOR_GLAZING_TYPE_LABELS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translateChoices(DOOR_GLAZING_TYPE_CHOICES, translate),
                    },
                    modelKey: 'doorGlazingType',
                },
            }, {
                label: translate('model.external_damage.door_glass_type'),
                value: model.doorGlassType,
                displayedValue: optionalTranslatableEnumTransform(model.doorGlassType, DOOR_GLASS_TYPE_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translateChoices(DOOR_GLASS_TYPE_CHOICES, translate),
                    },
                    modelKey: 'doorGlassType',
                },
            });
        }
    }
    return fields;
};
