import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { damageSeverityField, dimensionsFields, editionMaterialField, impactsNumberField, otherDamagesFields, } from '@shared/breakInDamage/breakInDamageFields';
import { translatableObjectToChoices, translateChoices } from '@shared/choice';
import { FRENCH_WINDOW_HEIGHT_CHOICES, FRENCH_WINDOW_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, FRENCH_WINDOW_ONE_LEAF_WIDTH_CHOICES, FRENCH_WINDOW_TWO_LEAVES_WIDTH_CHOICES, } from '@shared/constants/breakInDamages/FrenchWindowConstants';
import { DamageSeverity, ImpactsNumber, LeavesType, Material, } from '@shared/types/api/breakInDamage';
import { GLASS_TYPE_TRAD_KEYS_AND_IMAGES, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/breakInDamageConstants';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <FrenchWindow>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function frenchWindowBreakInDamageEditionFields(model, translate, projection, edition = false) {
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.break_in_damage.leaves_type'),
            key: 'frenchWindowType',
            value: model.frenchWindowType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(FRENCH_WINDOW_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'frenchWindowType',
            },
        });
        fields.push(editionMaterialField(model, translate));
    }
    fields.push(damageSeverityField(model, translate));
    if (model.damageSeverity === DamageSeverity.Critical) {
        if (edition) {
            fields.push(...frenchWindowBreakInDamageCriticalSeverityFields(model, translate));
        }
    }
    else if (model.damageSeverity === DamageSeverity.Notable) {
        fields.push(impactsNumberField(model, translate));
        if (edition) {
            if ((model.numberOfImpacts === ImpactsNumber.MoreThanThree &&
                model.material === Material.Metal) ||
                (model.numberOfImpacts === ImpactsNumber.MoreThanSeven && model.material === Material.PVC)) {
                fields.push(...frenchWindowBreakInDamageCriticalSeverityFields(model, translate));
            }
            else {
                fields.push({
                    label: translate('model.break_in_damage.broken_window'),
                    key: 'frenchWindowHasBrokenTiles',
                    value: model.frenchWindowHasBrokenTiles,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'frenchWindowHasBrokenTiles',
                    },
                });
                if (model.frenchWindowHasBrokenTiles) {
                    fields.push({
                        label: translate('model.break_in_damage.broken_tiles_number'),
                        key: 'frenchWindowNumberOfBrokenTiles',
                        value: model.frenchWindowNumberOfBrokenTiles,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'frenchWindowNumberOfBrokenTiles',
                        },
                    }, {
                        label: translate('model.break_in_damage.broken_window.height'),
                        key: 'frenchWindowBiggestBrokenTileHeight',
                        value: model.frenchWindowBiggestBrokenTileHeight,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'frenchWindowBiggestBrokenTileHeight',
                        },
                    }, {
                        label: translate('model.break_in_damage.broken_window.width'),
                        key: 'frenchWindowBiggestBrokenTileWidth',
                        value: model.frenchWindowBiggestBrokenTileWidth,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: 'frenchWindowBiggestBrokenTileWidth',
                        },
                    }, {
                        label: translate('model.break_in_damage.glazing_type'),
                        key: 'frenchWindowGlazingType',
                        value: model.frenchWindowGlazingType,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Radio,
                                choices: translatableObjectToChoices(GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                                vertical: true,
                            },
                            modelKey: 'frenchWindowGlazingType',
                        },
                    }, {
                        label: translate('model.break_in_damage.glass_type'),
                        key: 'frenchWindowLaminatedGlazingType',
                        value: model.frenchWindowLaminatedGlazingType,
                        editable: {
                            input: {
                                type: ModelFieldInputType.Radio,
                                choices: translatableObjectToChoices(GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                            },
                            modelKey: 'frenchWindowLaminatedGlazingType',
                        },
                    });
                }
                fields.push({
                    label: translate('model.break_in_damage.lock_damage'),
                    key: 'frenchWindowLockIsDamaged',
                    value: model.frenchWindowLockIsDamaged,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'frenchWindowLockIsDamaged',
                    },
                });
            }
        }
        else if ((projection === ModelFieldsProjection.Backoffice ||
            projection === ModelFieldsProjection.Pdf) &&
            model.frenchWindowHasBrokenTiles) {
            fields.push({
                label: translate('model.break_in_damage.broken_tiles_number'),
                key: 'frenchWindowNumberOfBrokenTiles',
                value: model.frenchWindowNumberOfBrokenTiles,
                displayedValue: '' + model.frenchWindowNumberOfBrokenTiles,
            }, {
                label: translate('model.break_in_damage.broken_window.height'),
                key: 'frenchWindowBiggestBrokenTileHeight',
                value: model.frenchWindowBiggestBrokenTileHeight,
                displayedValue: '' + model.frenchWindowBiggestBrokenTileHeight,
            }, {
                label: translate('model.break_in_damage.broken_window.width'),
                key: 'frenchWindowBiggestBrokenTileWidth',
                value: model.frenchWindowBiggestBrokenTileWidth,
                displayedValue: '' + model.frenchWindowBiggestBrokenTileWidth,
            }, {
                label: translate('model.break_in_damage.glazing_type'),
                key: 'frenchWindowGlazingType',
                value: model.frenchWindowGlazingType,
                displayedValue: optionalTranslatableEnumTransform(model.frenchWindowGlazingType, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            }, {
                label: translate('model.break_in_damage.glass_type'),
                key: 'frenchWindowLaminatedGlazingType',
                value: model.frenchWindowLaminatedGlazingType,
                displayedValue: optionalTranslatableEnumTransform(model.frenchWindowLaminatedGlazingType, GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
            });
        }
        if (projection !== ModelFieldsProjection.Declaration &&
            !(model.numberOfImpacts === ImpactsNumber.MoreThanThree && model.material === Material.Metal) &&
            !(model.numberOfImpacts === ImpactsNumber.MoreThanSeven && model.material === Material.PVC)) {
            fields.push(...otherDamagesFields(model, translate));
        }
    }
    // We check the conditions for critical severity again but this time also checking if we're in
    // readonly mode because in this mode we want the dimensions fields to appear at the end
    if (!edition &&
        (model.damageSeverity === DamageSeverity.Critical ||
            (model.numberOfImpacts === ImpactsNumber.MoreThanThree &&
                model.material === Material.Metal) ||
            (model.numberOfImpacts === ImpactsNumber.MoreThanSeven && model.material === Material.PVC))) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function frenchWindowBreakInDamageCriticalSeverityFields(model, translate) {
    return [
        {
            label: translate('claim.declaration.break_in_damages.form.french-window.height'),
            key: 'frenchWindowHeight',
            value: model.frenchWindowHeight,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(FRENCH_WINDOW_HEIGHT_CHOICES, translate),
                },
                modelKey: 'frenchWindowHeight',
            },
        },
        {
            label: translate('claim.declaration.break_in_damages.form.french-window.width'),
            key: 'frenchWindowWidth',
            value: model.frenchWindowHeight,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(model.frenchWindowType === LeavesType.OneLeaf
                        ? FRENCH_WINDOW_ONE_LEAF_WIDTH_CHOICES
                        : FRENCH_WINDOW_TWO_LEAVES_WIDTH_CHOICES, translate),
                },
                modelKey: 'frenchWindowWidth',
            },
        },
        {
            label: translate('model.break_in_damage.glazing_type'),
            key: 'frenchWindowGlazingType',
            value: model.frenchWindowGlazingType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                    vertical: true,
                },
                modelKey: 'frenchWindowGlazingType',
            },
        },
        {
            label: translate('model.break_in_damage.glass_type'),
            key: 'frenchWindowLaminatedGlazingType',
            value: model.frenchWindowLaminatedGlazingType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GLASS_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'frenchWindowLaminatedGlazingType',
            },
        },
    ];
}
