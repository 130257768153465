const middleware = {}

middleware['authenticated'] = require('../../../src/declaration/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['claimFetched'] = require('../../../src/declaration/middleware/claimFetched.ts')
middleware['claimFetched'] = middleware['claimFetched'].default || middleware['claimFetched']

middleware['contextEntitiesFetched'] = require('../../../src/declaration/middleware/contextEntitiesFetched.ts')
middleware['contextEntitiesFetched'] = middleware['contextEntitiesFetched'].default || middleware['contextEntitiesFetched']

middleware['forbidMagicLinkAuth'] = require('../../../src/declaration/middleware/forbidMagicLinkAuth.ts')
middleware['forbidMagicLinkAuth'] = middleware['forbidMagicLinkAuth'].default || middleware['forbidMagicLinkAuth']

middleware['layout'] = require('../../../src/declaration/middleware/layout.ts')
middleware['layout'] = middleware['layout'].default || middleware['layout']

middleware['magicLinkLogin'] = require('../../../src/declaration/middleware/magicLinkLogin.ts')
middleware['magicLinkLogin'] = middleware['magicLinkLogin'].default || middleware['magicLinkLogin']

middleware['middlewares'] = require('../../../src/declaration/middleware/middlewares.ts')
middleware['middlewares'] = middleware['middlewares'].default || middleware['middlewares']

middleware['notAuthenticated'] = require('../../../src/declaration/middleware/notAuthenticated.ts')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['router'] = require('../../../src/declaration/middleware/router.ts')
middleware['router'] = middleware['router'].default || middleware['router']

export default middleware
