import { objectToTranslatableChoices, objectToTranslatableImageChoices, } from '@shared/choice';
import { DoorImpactsNumber, DoorMaterial, DoorLeavesType, DoorGlazingType, DoorGlassType, } from '@shared/types/api/externalDamage';
// Door material
// Material list
export const DOOR_MATERIALS_KEYS = {
    [DoorMaterial.Wood]: 'claim.declaration.external_damages.form.door.material.wood',
    [DoorMaterial.PVC]: 'claim.declaration.external_damages.form.door.material.pvc',
    [DoorMaterial.Metal]: 'claim.declaration.external_damages.form.door.material.metal',
};
export const DOOR_MATERIALS_CHOICES = objectToTranslatableChoices(DOOR_MATERIALS_KEYS);
//  Door impacts
// Impacts list
export const DOOR_IMPACT_TRAD_KEYS = {
    [DoorImpactsNumber.NoImpact]: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    [DoorImpactsNumber.AtLeastOne]: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
};
export const DOOR_IMPACT_CHOICES = objectToTranslatableChoices(DOOR_IMPACT_TRAD_KEYS);
// Door leaves
// Material list
export const DOOR_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES = {
    [DoorLeavesType.OneLeaf]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.one-leaf',
        image: require('@declaration/assets/images/externalDamage/door/leaves/un-battant.svg'),
    },
    [DoorLeavesType.TwoLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.two-leaves',
        image: require('@declaration/assets/images/externalDamage/door/leaves/deux-battants.svg'),
    },
};
export const DOOR_LEAVES_CHOICES = objectToTranslatableImageChoices(DOOR_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES);
// Door Glazing Type
// Glazing Type list
export const DOOR_GLAZING_TYPE_TYPE_TRAD_KEYS_AND_IMAGES = {
    [DoorGlazingType.Simple]: {
        tradKey: 'claim.declaration.external_damages.form.door.panels.glass.glazing.simple',
        image: require('@declaration/assets/images/externalDamage/door/glazing/glazing-simple.svg'),
    },
    [DoorGlazingType.Double]: {
        tradKey: 'claim.declaration.external_damages.form.door.panels.glass.glazing.double',
        image: require('@declaration/assets/images/externalDamage/door/glazing/glazing-double.svg'),
    },
    [DoorGlazingType.Triple]: {
        tradKey: 'claim.declaration.external_damages.form.door.panels.glass.glazing.triple',
        image: require('@declaration/assets/images/externalDamage/door/glazing/glazing-triple.svg'),
    },
    [DoorGlazingType.Unknown]: {
        tradKey: 'claim.declaration.external_damages.form.door.panels.glass.glazing.unknown',
        image: require('@declaration/assets/images/externalDamage/door/glazing/glazing-unknown.svg'),
    },
};
export const DOOR_GLAZING_TYPE_CHOICES = objectToTranslatableImageChoices(DOOR_GLAZING_TYPE_TYPE_TRAD_KEYS_AND_IMAGES);
// Glazing Type list
export const DOOR_GLASS_TYPE_TYPE_TRAD_KEYS_AND_IMAGES = {
    [DoorGlassType.Laminated]: {
        tradKey: 'claim.declaration.external_damages.form.door.glass.laminated',
        image: require('@declaration/assets/images/externalDamage/door/glass/glass-laminated.svg'),
    },
    [DoorGlassType.NoLaminated]: {
        tradKey: 'claim.declaration.external_damages.form.door.glass.no-laminated',
        image: require('@declaration/assets/images/externalDamage/door/glass/glass-no-laminated.svg'),
    },
    [DoorGlassType.Unknown]: {
        tradKey: 'claim.declaration.external_damages.form.door.glass.unknown',
        image: require('@declaration/assets/images/externalDamage/door/glass/glass-unknown.svg'),
    },
};
export const DOOR_GLASS_TYPE_CHOICES = objectToTranslatableImageChoices(DOOR_GLASS_TYPE_TYPE_TRAD_KEYS_AND_IMAGES);
