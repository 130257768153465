import { ELECTRICAL_DAMAGE_TYPES_OTHER } from '@shared/constants/electricalDamageConstants';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
const typeConstraints = () => ({
    presence: {
        allowEmpty: false,
        message: 'model.electrical_damage.type.validator.presence',
    },
});
const designationConstraints = () => ({
    presenceIfValue: {
        attribute: 'type',
        triggeringValue: [...ELECTRICAL_DAMAGE_TYPES_OTHER, null],
        message: 'model.electrical_damage.designation.validator.presence',
    },
});
const repairAmountConstraints = () => ({
    presence: {
        allowEmpty: false,
        message: 'model.electrical_damage.repair_amount.validator.presence',
    },
    numericality: {
        greaterThan: 0,
        message: 'model.electrical_damage.repair_amount.validator.numericality',
    },
});
const repairQuoteConstraints = () => ({
    requiredFileCollection: {
        message: 'model.electrical_damage.repair_quote.validator.required_file_collection',
        trigram: ClaimTypeTrigram.Electric,
    },
});
const irreparabilityCertificateConstraints = () => ({
    requiredFileCollection: {
        message: 'model.electrical_damage.irreparability_certificate.validator.required_file_collection',
        trigram: ClaimTypeTrigram.Electric,
    },
});
const purchaseAmountConstraints = () => ({
    presence: {
        allowEmpty: false,
        message: 'model.electrical_damage.purchase_amount.validator.presence',
    },
    numericality: {
        greaterThan: 0,
        message: 'model.electrical_damage.purchase_amount.validator.numericality',
    },
});
const purchaseDateConstraints = () => ({
    presence: {
        allowEmpty: false,
        message: 'model.electrical_damage.purchase_date.validator.presence',
    },
});
const purchaseCertificationConstraints = () => ({
    checked: {
        message: 'model.electrical_damage.purchase_certification.validator.checked',
    },
});
export function electricalDamageConstraints(electricalDamage) {
    const constraints = {
        type: typeConstraints(),
        designation: designationConstraints(),
    };
    if (electricalDamage.repairable) {
        constraints.repairAmount = repairAmountConstraints();
        constraints.repairQuote = repairQuoteConstraints();
        if (electricalDamage.purchaseInfo) {
            constraints.purchaseAmount = purchaseAmountConstraints();
            constraints.purchaseDate = purchaseDateConstraints();
        }
        else {
            constraints.purchaseCertification = purchaseCertificationConstraints();
        }
    }
    else {
        constraints.irreparabilityCertificate = irreparabilityCertificateConstraints();
    }
    return constraints;
}
