import { LIGHT_SOURCE_TYPE_LABELS, LIGHT_SOURCE_TYPE_MODEL_CHOICES, LIGHT_SOURCE_TYPE_MODEL_LABELS, } from '../goodDamage';
import { translateChoices } from '@shared/choice';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export function lightSourceTitle(model, translate) {
    if (!model.type || !model.lightType)
        return null;
    return translate(LIGHT_SOURCE_TYPE_LABELS[model.lightType]);
}
export function lightSourceFields(model, context) {
    const { translate, index } = context;
    return [
        {
            label: translate('model.good_damage.light_source.light_type'),
            value: model.lightType,
            displayedValue: optionalTranslatableEnumTransform(model.lightType, LIGHT_SOURCE_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    nullable: true,
                    choices: translateChoices(LIGHT_SOURCE_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.lightType`,
                toPayload: (lightType) => ({ lightType }),
            },
        },
    ];
}
