import { objectToTranslatableChoices, } from '@shared/choice';
import { BasinMaterial, ExternalDamageOrigin } from '@shared/types/api/externalDamage';
export const DEFAULT_BASIN_MATERIAL_TRAD_KEYS = {
    [BasinMaterial.Metal]: 'claim.declaration.external_damages.form.basin.material.metal',
    [BasinMaterial.Resin]: 'claim.declaration.external_damages.form.basin.material.resin',
    [BasinMaterial.Ceramic]: 'claim.declaration.external_damages.form.basin.material.ceramic',
    [BasinMaterial.Unknown]: 'claim.declaration.external_damages.form.basin.material.unknown',
};
const STORM_BASIN_MATERIAL_TRAD_KEYS = {};
const SNOW_BASIN_MATERIAL_TRAD_KEYS = {};
const HAIL_BASIN_MATERIAL_TRAD_KEYS = {};
const FIRE_BASIN_MATERIAL_TRAD_KEYS = {};
export const BASIN_MATERIAL_TRAD_KEYS = {
    [ExternalDamageOrigin.Storm]: {
        ...DEFAULT_BASIN_MATERIAL_TRAD_KEYS,
        ...STORM_BASIN_MATERIAL_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Snow]: {
        ...DEFAULT_BASIN_MATERIAL_TRAD_KEYS,
        ...SNOW_BASIN_MATERIAL_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Hail]: {
        ...DEFAULT_BASIN_MATERIAL_TRAD_KEYS,
        ...HAIL_BASIN_MATERIAL_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Fire]: {
        ...DEFAULT_BASIN_MATERIAL_TRAD_KEYS,
        ...FIRE_BASIN_MATERIAL_TRAD_KEYS,
    },
};
export const BASIN_MATERIAL_CHOICES = {
    [ExternalDamageOrigin.Storm]: objectToTranslatableChoices(BASIN_MATERIAL_TRAD_KEYS[ExternalDamageOrigin.Storm]),
    [ExternalDamageOrigin.Snow]: objectToTranslatableChoices(BASIN_MATERIAL_TRAD_KEYS[ExternalDamageOrigin.Snow]),
    [ExternalDamageOrigin.Hail]: objectToTranslatableChoices(BASIN_MATERIAL_TRAD_KEYS[ExternalDamageOrigin.Hail]),
    [ExternalDamageOrigin.Fire]: objectToTranslatableChoices(BASIN_MATERIAL_TRAD_KEYS[ExternalDamageOrigin.Fire]),
};
