import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalString } from '@shared/utils/transform';
export const aerationGridFields = (model, { translate }) => {
    return [
        {
            label: translate('model.external_damage.aeration_grid.count'),
            value: model.gridCount,
            displayedValue: optionalString(model.gridCount),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                modelKey: 'gridCount',
            },
        },
    ];
};
