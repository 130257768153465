import { parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { translateChoices } from '@shared/choice';
import { GLASS_DAMAGE_GLASS_TYPE_CHOICES, GLASS_DAMAGE_GLASS_TYPE_TRAD_KEYS, GLASS_DAMAGE_GLAZING_TYPE_CHOICES, GLASS_DAMAGE_GLAZING_TYPE_TRAD_KEYS, GLASS_DAMAGE_TYPE_CHOICES, GLASS_DAMAGE_TYPE_TRAD_KEYS, } from '@shared/constants/glassDamageConstants';
import { ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { GlassType, GlazingType, } from '@shared/types/api/glassDamage';
import { ClaimFileType } from '@shared/types/file';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
import { glassDamageConstraints } from '@shared/validator/constraints/glassDamageConstraints';
export function glassDamageTitle(model, translate) {
    return optionalTranslatableEnumTransform(model.type, GLASS_DAMAGE_TYPE_TRAD_KEYS, translate);
}
export const glassDamageFields = (model, { translate, projection }) => {
    var _a, _b;
    const fields = [];
    const title = optionalTranslatableEnumTransform(model.type, GLASS_DAMAGE_TYPE_TRAD_KEYS, translate);
    if (projection === ModelFieldsProjection.Backoffice || projection !== ModelFieldsProjection.Pdf) {
        fields.push({
            label: translate('model.glass_damage.type'),
            value: model.type,
            displayedValue: title,
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(GLASS_DAMAGE_TYPE_CHOICES, translate),
                },
                modelKey: 'type',
            },
        });
    }
    fields.push({
        label: translate('model.glass_damage.number_of_panes'),
        value: model.numberOfPanes,
        displayedValue: (_b = (_a = model.numberOfPanes) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '-',
        editable: {
            input: {
                type: ModelFieldInputType.Number,
            },
            modelKey: 'numberOfPanes',
        },
    });
    if (projection === ModelFieldsProjection.Backoffice || projection !== ModelFieldsProjection.Pdf) {
        fields.push({
            label: translate('model.glass_damage.widest_pane_width'),
            value: model.widestPaneWidth,
            displayedValue: parseOptionalDimension(model.widestPaneWidth, '-'),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'mm',
                modelKey: 'widestPaneWidth',
            },
        });
        fields.push({
            label: translate('model.glass_damage.widest_pane_height'),
            value: model.widestPaneHeight,
            displayedValue: parseOptionalDimension(model.widestPaneHeight, '-'),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'mm',
                modelKey: 'widestPaneHeight',
            },
        });
    }
    else {
        const { widestPaneWidth: w, widestPaneHeight: h } = model;
        fields.push({
            label: translate('model.glass_damage.widest_pane_dimensions'),
            key: 'widestPaneDimensions',
            displayedValue: `${parseOptionalDimension(w, 'n/a')} x ${parseOptionalDimension(h, 'n/a')}`,
        });
    }
    if (projection === ModelFieldsProjection.Backoffice || projection !== ModelFieldsProjection.Pdf) {
        fields.push({
            label: translate('model.glass_damage.glazing_type'),
            value: model.glazingType,
            displayedValue: model.glazingType
                ? translate(GLASS_DAMAGE_GLAZING_TYPE_TRAD_KEYS[model.glazingType])
                : '-',
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GLASS_DAMAGE_GLAZING_TYPE_CHOICES, translate),
                    vertical: true,
                },
                modelKey: 'glazingType',
            },
        });
        fields.push({
            label: translate('model.glass_damage.glass_type'),
            value: model.glassType,
            displayedValue: model.glassType
                ? translate(GLASS_DAMAGE_GLASS_TYPE_TRAD_KEYS[model.glassType])
                : '-',
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GLASS_DAMAGE_GLASS_TYPE_CHOICES, translate),
                    vertical: true,
                },
                modelKey: 'glassType',
            },
        });
    }
    else {
        const { glazingType, glassType } = model;
        let displayedValue;
        if ((!!glazingType && glazingType !== GlazingType.Unknown) ||
            (!!glassType && glassType !== GlassType.Unknown)) {
            const displayedGlazingType = !!glazingType && glazingType !== GlazingType.Unknown
                ? translate(GLASS_DAMAGE_GLAZING_TYPE_TRAD_KEYS[glazingType])
                : translate('model.glass_damage.glass');
            const displayedGlassType = !!glassType && glassType !== GlassType.Unknown
                ? ` ${translate(GLASS_DAMAGE_GLASS_TYPE_TRAD_KEYS[glassType]).toLocaleLowerCase()}`
                : '';
            displayedValue = displayedGlazingType + displayedGlassType;
        }
        else
            displayedValue = '-';
        fields.push({
            label: translate('model.glass_damage.glass'),
            key: 'glass',
            displayedValue,
        });
    }
    if (projection === ModelFieldsProjection.Backoffice) {
        fields.push({
            label: 'Photo(s)',
            value: model.pictureCollection,
            displayedValue: {
                type: 'fileCollection',
                fileCollection: model.pictureCollection,
                emptyLabel: 'Aucun document',
                fileType: ClaimFileType.GlassDamagePicture,
                uploadLabel: translate('model.file.upload'),
            },
            editable: {
                input: {
                    type: ModelFieldInputType.FileCollection,
                },
                modelKey: 'pictureCollection',
            },
            layout: {
                column: 1,
            },
        });
    }
    return {
        title,
        fields,
        model,
        editable: {
            constraints: glassDamageConstraints,
        },
        defaultPayload: { type: model.type },
    };
};
