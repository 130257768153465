import { EMPTY } from '@shared/utils/strings';
/**
 * Percentage argument should be a float between 0 and 1
 */
export function formatPercentage(percentage, showTwoDecimals = false) {
    let value = percentage * 100;
    if (showTwoDecimals) {
        value = Math.round(value * 100) / 100;
    }
    else {
        value = Math.round(value);
    }
    return `${value} %`;
}
/**
 * Percentage argument should be a float between 0 and 1
 */
export function formatOptionalPercentage(percentage, showTwoDecimals = false, defaultString = EMPTY) {
    return percentage === undefined || percentage === null
        ? defaultString
        : formatPercentage(percentage, showTwoDecimals);
}
