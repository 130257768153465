export var GlassDamageType;
(function (GlassDamageType) {
    GlassDamageType["Awning"] = "auvent";
    GlassDamageType["FixedBayWindow"] = "baie-fixe";
    GlassDamageType["FrenchWindow"] = "porte-fenetre";
    GlassDamageType["GlassWall"] = "verriere";
    GlassDamageType["Greenhouse"] = "serre";
    GlassDamageType["Other"] = "autre";
    GlassDamageType["Pergola"] = "pergola";
    GlassDamageType["SlidingBayWindow"] = "baie-coulissante";
    GlassDamageType["SwimpoolCover"] = "abris-de-piscine";
    GlassDamageType["Transom"] = "imposte";
    GlassDamageType["Velux"] = "velux";
    GlassDamageType["Veranda"] = "veranda";
    GlassDamageType["Window"] = "fenetre";
})(GlassDamageType || (GlassDamageType = {}));
export var GlazingType;
(function (GlazingType) {
    GlazingType["Simple"] = "simple";
    GlazingType["Double"] = "double";
    GlazingType["Triple"] = "triple";
    GlazingType["Unknown"] = "unknown";
})(GlazingType || (GlazingType = {}));
export var GlassType;
(function (GlassType) {
    GlassType["Laminated"] = "laminated";
    GlassType["NonLaminated"] = "non-laminated";
    GlassType["Unknown"] = "unknown";
})(GlassType || (GlassType = {}));
export const GLASS_DAMAGE_TYPE_LABELS = {
    [GlassDamageType.Awning]: 'Auvent',
    [GlassDamageType.FixedBayWindow]: 'Baie fixe',
    [GlassDamageType.FrenchWindow]: 'Porte-fenêtre',
    [GlassDamageType.GlassWall]: 'Verrière',
    [GlassDamageType.Greenhouse]: 'Serre',
    [GlassDamageType.Other]: 'Autre',
    [GlassDamageType.Pergola]: 'Pergola',
    [GlassDamageType.SlidingBayWindow]: 'Baie coulissante',
    [GlassDamageType.SwimpoolCover]: 'Abri de piscine',
    [GlassDamageType.Transom]: 'Imposte',
    [GlassDamageType.Velux]: 'Velux',
    [GlassDamageType.Veranda]: 'Véranda',
    [GlassDamageType.Window]: 'Fenêtre',
};
