import { objectToTranslatableImageChoices, } from '@shared/choice';
import { GARAGE_DOOR_TYPE_IMAGES_AND_TRAD_KEYS } from '@shared/constants/externalDamages';
export const GARAGE_DOOR_TITLE = 'claim.declaration.external_damages.form.garage_door.title';
export const GARAGE_DOOR_TYPE_LABEL = 'claim.declaration.external_damages.form.garage_door.subtitle';
export const GARAGE_DOOR_WIDTH_LABEL = 'claim.declaration.external_damages.form.garage_door.width.title';
export const GARAGE_DOOR_MOTORIZATION_LABEL = 'claim.declaration.external_damages.form.garage_door.opening.title';
export const GARAGE_DOOR_TYPE_CHOICES = objectToTranslatableImageChoices(GARAGE_DOOR_TYPE_IMAGES_AND_TRAD_KEYS);
export const GARAGE_DOOR_WIDTH_CHOICES = [
    {
        value: false,
        tradKey: 'claim.declaration.external_damages.form.garage_door.width.lower',
    },
    {
        value: true,
        tradKey: 'claim.declaration.external_damages.form.garage_door.width.greater',
    },
];
export const GARAGE_DOOR_MOTORIZATION_CHOICES = [
    {
        value: false,
        tradKey: 'claim.declaration.external_damages.form.garage_door.opening.manual',
    },
    {
        value: true,
        tradKey: 'claim.declaration.external_damages.form.garage_door.opening.motorized',
    },
];
