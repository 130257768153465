export const YES_OR_NO_CHOICES = [
    {
        value: true,
        tradKey: 'question.answer.yes',
    },
    {
        value: false,
        tradKey: 'question.answer.no',
    },
];
export const UNKNOWN_CHOICE = [
    {
        value: null,
        tradKey: 'claim.declaration.personal_property.unknown',
    },
];
export const NUMERIC_CHOICES = (number) => {
    const choices = [];
    for (let i = 0; i < number; i++) {
        choices[i] = {
            value: i + 1,
            label: String(i + 1),
        };
    }
    return choices;
};
