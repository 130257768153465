export function changePageTitle(title, { store, translate }) {
    if (title) {
        const displayedTitle = translate(title);
        if (store.state.layout.title === displayedTitle) {
            return;
        }
        store.dispatch('layout/setTitle', displayedTitle);
        return;
    }
    store.dispatch('layout/setTitle', null);
}
