import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { QualificationRoomType } from '@shared/types/api/claim';
import { createGoodDamagesConstraints } from '@shared/validator/constraints/goodDamageConstraints';
import { createSurfaceDamageConstraints } from '@shared/validator/constraints/surfaceDamageConstraints';
const roomNameSlugConstraint = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.room_name.validator.presence',
    },
};
const roomLabelConstraint = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.room_label.validator.presence',
    },
};
const roomDescriptionConstraint = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.room_description.validator.presence',
    },
};
const surfaceConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.surface.validator.presence',
    },
    numericality: {
        greaterThan: 0,
        lessThan: 1000000000,
        message: 'claim.damage.real_estate.room_damage.form.question.surface.validator.numericality',
    },
};
const surfaceDamagesConstraints = {
    presence: {
        allowEmpty: false,
        message: 'claim.damage.real_estate.room_damage.form.question.surface_damage.validator.presence',
    },
    length: {
        minimum: 1,
        message: 'claim.damage.real_estate.room_damage.form.question.surface_damage.validator.presence',
    },
};
const goodDamagesConstraints = {
    presenceIfEmpty: {
        allowEmpty: false,
        attribute: 'surfaceDamages',
        message: 'claim.damage.real_estate.room_damage.form.question.good_damage.validator.presence',
    },
};
function createBaseConstraints(trigram, roomNameSlug) {
    const baseConstraints = {
        roomNameSlug: roomNameSlugConstraint,
    };
    if (roomNameSlug !== QualificationRoomType.Other) {
        baseConstraints.surface = surfaceConstraints;
        if (claimTypeHasFeature(trigram, ClaimTypeFeatureFlag.MandatoryRoomDamagedSurfaces)) {
            baseConstraints.surfaceDamages = surfaceDamagesConstraints;
        }
        if (claimTypeHasFeature(trigram, ClaimTypeFeatureFlag.DescribeRoomDamagedGoods)) {
            baseConstraints.goodDamages = goodDamagesConstraints;
        }
    }
    else {
        baseConstraints.roomLabel = roomLabelConstraint;
        baseConstraints.roomDescription = roomDescriptionConstraint;
    }
    return baseConstraints;
}
function roomDamageConstraints({ surfaceDamages, goodDamages, roomNameSlug = null }, { typeTrigram }) {
    const constrains = createBaseConstraints(typeTrigram, roomNameSlug);
    if (roomNameSlug !== QualificationRoomType.Other) {
        Object.assign(constrains, createSurfaceDamageConstraints(surfaceDamages), createGoodDamagesConstraints(goodDamages));
    }
    return constrains;
}
export default roomDamageConstraints;
