import merge from 'lodash/merge';
import { ExternalDamageOrigin, ExternalDamageSlug, ShutterType, VerandaGutterMaterial, VerandaPanelGlazingType, VerandaPanelMaterial, WindowType, DoorMaterial, } from '@shared/types/api/externalDamage';
import { PipeType } from '@shared/types/api/roomDamage';
const externalDamagesBaseConstraints = {
    localizationSlug: {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.localization.presence',
        },
    },
    slug: {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.localization.presence',
        },
    },
};
function gateExternalDamagesConstraints() {
    const constraints = {};
    constraints.type = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.gate.type.presence',
        },
    };
    constraints.material = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.gate.material.presence',
        },
    };
    constraints.panelFillStyle = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.gate.panel_fill_style.presence',
        },
    };
    constraints.widthSlug = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.gate.width.presence',
        },
    };
    constraints.heightSlug = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.gate.height.presence',
        },
    };
    constraints.isOpeningMotorized = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.gate.opening.presence',
        },
    };
    return constraints;
}
function shutterExternalDamagesConstraints(shutterDamage) {
    const constraints = {};
    constraints.shutterType = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.shutters.type.presence',
        },
    };
    constraints.material = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.shutters.material.presence',
        },
    };
    constraints.isWidthGreaterThanStandard = {
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.shutters.width.presence',
        },
    };
    if (shutterDamage.shutterType === ShutterType.Rolling) {
        constraints.isOpeningMotorized = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.shutters.opening.presence',
            },
        };
    }
    constraints.shutterCount = {
        numericality: {
            greaterThan: 0,
            message: 'claim.damage.external_damage.form.validator.shutters.count.minimum',
        },
        presence: {
            allowEmpty: false,
            message: 'claim.damage.external_damage.form.validator.shutters.count.minimum',
        },
    };
    return constraints;
}
function externalDamagesConstraints(externalDamage, context) {
    var _a;
    const slug = externalDamage.slug;
    let constraints = { ...externalDamagesBaseConstraints };
    if (slug === ExternalDamageSlug.GarageDoor) {
        constraints.doorType = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.garage_door.door_type.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.TileDisplacement) {
        constraints.tileCount = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.tile_displacement.count.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.Gutter) {
        constraints.horizontalPartMaterial = {
            presenceIfEmpty: {
                attribute: 'verticalPartMaterial',
                message: 'claim.damage.external_damage.form.validator.gutter.material.presence',
            },
            presenceIfPresent: {
                attribute: 'horizontalPartLength',
                message: 'claim.damage.external_damage.form.validator.gutter.material.presence',
            },
        };
        constraints.horizontalPartLength = {
            presenceIfEmpty: {
                attribute: 'verticalPartLength',
                message: 'claim.damage.external_damage.form.validator.gutter.length.presence',
            },
            presenceIfPresent: {
                attribute: 'horizontalPartMaterial',
                message: 'claim.damage.external_damage.form.validator.gutter.length.presence',
            },
        };
        constraints.verticalPartMaterial = {
            presenceIfEmpty: {
                attribute: 'horizontalPartMaterial',
                message: 'claim.damage.external_damage.form.validator.gutter.material.presence',
            },
            presenceIfPresent: {
                attribute: 'verticalPartLength',
                message: 'claim.damage.external_damage.form.validator.gutter.material.presence',
            },
        };
        constraints.verticalPartLength = {
            presenceIfEmpty: {
                attribute: 'horizontalPartLength',
                message: 'claim.damage.external_damage.form.validator.gutter.length.presence',
            },
            presenceIfPresent: {
                attribute: 'verticalPartMaterial',
                message: 'claim.damage.external_damage.form.validator.gutter.length.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.Fence) {
        constraints.fenceType = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.fence.type.presence',
            },
        };
        constraints.fenceLength = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.fence.length.presence',
            },
        };
        constraints.isFenceHalfHeight = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.fence.length.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.Shutters) {
        constraints = merge(constraints, shutterExternalDamagesConstraints(externalDamage));
    }
    else if (slug === ExternalDamageSlug.Window) {
        constraints.windowType = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.window.type.presence',
            },
        };
        if (context.externalDamageOrigin === ExternalDamageOrigin.Hail) {
            constraints.windowStructureDamaged = {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.external_damage.form.validator.window.structure_damaged.presence',
                },
            };
            if (externalDamage.windowStructureDamaged === false) {
                constraints.windowPaneCount = {
                    presenceIfValue: {
                        attribute: 'windowType',
                        triggeringValue: [WindowType.Window, WindowType.SlidingBay, WindowType.FrenchWindow],
                        message: 'claim.damage.external_damage.form.validator.window.pane_count.presence',
                    },
                    numericality: {
                        greaterThan: 0,
                        message: 'claim.damage.external_damage.form.validator.window.pane_count.numericality',
                    },
                };
                constraints.windowPaneHeight = {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.window.pane_height.presence',
                    },
                    numericality: {
                        greaterThan: 0,
                        message: 'claim.damage.external_damage.form.validator.window.pane_height.numericality',
                    },
                };
                constraints.windowPaneWidth = {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.window.pane_width.presence',
                    },
                    numericality: {
                        greaterThan: 0,
                        message: 'claim.damage.external_damage.form.validator.window.pane_width.numericality',
                    },
                };
                constraints.windowPaneGlazingType = {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.window.pane_glazing_type.presence',
                    },
                };
                constraints.windowPaneGlassType = {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.window.pane_glass_type.presence',
                    },
                };
            }
        }
        else {
            constraints.windowCount = {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.external_damage.form.validator.window.count.minimum',
                },
                numericality: {
                    greaterThan: 0,
                    message: 'claim.damage.external_damage.form.validator.window.count.minimum',
                },
            };
        }
    }
    else if (slug === ExternalDamageSlug.FrontWall) {
        constraints.frontWallFloorCount = {
            numericality: {
                greaterThan: 0,
                message: 'claim.damage.external_damage.form.validator.front_wall.floor_count.minimum',
            },
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.front_wall.floor_count.minimum',
            },
        };
        constraints.frontWallWideness = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.front_wall.wideness.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.Awning) {
        constraints.isOpeningMotorized = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.awning.opening.presence',
            },
        };
        constraints.width = {
            numericality: {
                greaterThan: 0,
                message: 'claim.damage.external_damage.form.validator.awning.dimensions.width.minimum',
            },
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.awning.dimensions.width.presence',
            },
        };
        constraints.overhangLength = {
            numericality: {
                greaterThan: 0,
                message: 'claim.damage.external_damage.form.validator.awning.dimensions.length.minimum',
            },
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.awning.dimensions.length.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.LittleGate) {
        constraints.material = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.gate.material.presence',
            },
        };
        constraints.size = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.gate.height.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.Gate) {
        constraints = merge(constraints, gateExternalDamagesConstraints());
    }
    else if (slug === ExternalDamageSlug.Antenna) {
        constraints.antennaCount = {
            numericality: {
                greaterThan: 0,
                message: 'claim.damage.external_damage.form.validator.antenna.count.minimum',
            },
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.antenna.count.minimum',
            },
        };
    }
    else if (slug === ExternalDamageSlug.Basin) {
        constraints.material = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.basin.material.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.AerationGrid) {
        constraints.gridCount = {
            numericality: {
                greaterThan: 0,
                message: 'claim.damage.external_damage.form.validator.aeration_grid.count.numericality',
            },
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.aeration_grid.count.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.VisiblePipe) {
        constraints.pipeType = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.visible_pipe.type.presence',
            },
        };
        constraints.pipeLength = {
            numericality: {
                greaterThan: 0,
                message: 'claim.damage.external_damage.form.validator.visible_pipe.length.numericality',
            },
            presenceIfValue: {
                attribute: 'pipeType',
                triggeringValue: [PipeType.COPPER, PipeType.PEX, PipeType.UNKNOWN],
                message: 'claim.damage.external_damage.form.validator.visible_pipe.length.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.ElectricalSheath) {
        constraints.sheathLength = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.electrical_sheath.length.presence',
            },
            numericality: {
                greaterThan: 0,
                message: 'claim.damage.external_damage.form.validator.electrical_sheath.length.numericality',
            },
        };
    }
    else if (slug === ExternalDamageSlug.LightSource) {
        constraints.lightType = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.light_source.type.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.Veranda &&
        context.externalDamageOrigin === ExternalDamageOrigin.Hail) {
        constraints.structureDamaged = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.veranda.structure_damaged.presence',
            },
        };
        constraints.gutterDamaged = {
            presenceIfValue: {
                attribute: 'structureDamaged',
                triggeringValue: false,
                message: 'claim.damage.external_damage.form.validator.veranda.gutter_damaged.presence',
            },
        };
        constraints.gutterMaterial = {
            presenceIfValue: {
                attribute: 'gutterDamaged',
                triggeringValue: true,
                message: 'claim.damage.external_damage.form.validator.veranda.gutter_material.presence',
            },
            inclusion: {
                within: Object.values(VerandaGutterMaterial),
                message: 'claim.damage.external_damage.form.validator.veranda.gutter_material.inclusion',
            },
        };
        constraints.gutterLength = {
            presenceIfValue: {
                attribute: 'gutterDamaged',
                triggeringValue: true,
                message: 'claim.damage.external_damage.form.validator.veranda.gutter_length.presence',
            },
            numericality: {
                greaterThan: 0,
                message: 'claim.damage.external_damage.form.validator.veranda.gutter_length.numericality',
            },
        };
        (_a = externalDamage.panels) === null || _a === void 0 ? void 0 : _a.forEach((_, index) => {
            constraints[`panels.${index}.material`] = {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.external_damage.form.validator.veranda.panel.material.presence',
                },
            };
            constraints[`panels.${index}.width`] = {
                presenceIfValue: {
                    attribute: `panels.${index}.material`,
                    triggeringValue: [
                        VerandaPanelMaterial.Glass,
                        VerandaPanelMaterial.Polycarbonate,
                        VerandaPanelMaterial.SandwichPanel,
                    ],
                    message: 'claim.damage.external_damage.form.validator.veranda.panel.width.presence',
                },
                numericality: {
                    greaterThan: 0,
                    message: 'claim.damage.external_damage.form.validator.veranda.panel.width.numericality',
                },
            };
            constraints[`panels.${index}.height`] = {
                presenceIfValue: {
                    attribute: `panels.${index}.material`,
                    triggeringValue: [
                        VerandaPanelMaterial.Glass,
                        VerandaPanelMaterial.Polycarbonate,
                        VerandaPanelMaterial.SandwichPanel,
                    ],
                    message: 'claim.damage.external_damage.form.validator.veranda.panel.height.presence',
                },
                numericality: {
                    greaterThan: 0,
                    message: 'claim.damage.external_damage.form.validator.veranda.panel.height.numericality',
                },
            };
            constraints[`panels.${index}.glazingType`] = {
                presenceIfValue: {
                    attribute: `panels.${index}.material`,
                    triggeringValue: VerandaPanelMaterial.Glass,
                    message: 'claim.damage.external_damage.form.validator.veranda.panel.glazing_type.presence',
                },
                inclusion: {
                    within: Object.values(VerandaPanelGlazingType),
                    message: 'claim.damage.external_damage.form.validator.veranda.panel.glazing_type.inclusion',
                },
            };
        });
    }
    else if (slug === ExternalDamageSlug.Pergola &&
        context.externalDamageOrigin === ExternalDamageOrigin.Hail) {
        constraints.material = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.pergola.material.presence',
            },
        };
        constraints.damageType = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.pergola.damage_type.presence',
            },
        };
    }
    else if (slug === ExternalDamageSlug.Door &&
        context.externalDamageOrigin === ExternalDamageOrigin.Hail) {
        constraints.doorMaterial = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.door.door_material.presence',
            },
        };
        constraints.doorImpactsNumber = {
            presenceIfValue: {
                attribute: 'doorMaterial',
                triggeringValue: DoorMaterial.Wood,
                message: 'claim.damage.external_damage.form.validator.door.door_impacts.presence',
            },
        };
        constraints.doorLeavesType = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.door.door_leaves.presence',
            },
        };
        constraints.doorHasWindows = {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.external_damage.form.validator.door.door_has_windows.presence',
            },
        };
        if (externalDamage.doorHasWindows) {
            constraints.doorHasBrokenTiles = {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.external_damage.form.validator.door.door_windows_broken.presence',
                },
            };
            if (externalDamage.doorHasBrokenTiles) {
                constraints.doorNumberOfBrokenTiles = {
                    numericality: {
                        greaterThan: 0,
                        message: 'claim.damage.external_damage.form.validator.door.door_number_windows_broken.presence',
                    },
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.door.door_number_windows_broken.presence',
                    },
                };
                constraints.doorBiggestBrokenWindowTileHeight = {
                    numericality: {
                        greaterThan: 0,
                        message: 'claim.damage.external_damage.form.validator.door.door_windows_broken_sizes.zero',
                    },
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.door.door_windows_height.presence',
                    },
                };
                constraints.doorBiggestBrokenWindowTileWidth = {
                    numericality: {
                        greaterThan: 0,
                        message: 'claim.damage.external_damage.form.validator.door.door_windows_broken_sizes.zero',
                    },
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.door.door_windows_width.presence',
                    },
                };
                constraints.doorGlazingType = {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.door.door_windows_glazing.presence',
                    },
                };
                constraints.doorGlassType = {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.external_damage.form.validator.door.door_windows_glass.presence',
                    },
                };
            }
        }
    }
    return constraints;
}
export default externalDamagesConstraints;
