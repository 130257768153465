export const name = 'error';
export const namespaced = true;
export const state = () => ({
    errors: [],
});
export const getters = {};
export const actions = {
    add({ commit }, error) {
        if (typeof error === 'string') {
            error = { message: error };
        }
        commit('ADD_ERROR', error);
    },
    remove({ commit }) {
        commit('REMOVE_ERRORS');
    },
};
export const mutations = {
    ADD_ERROR(state, error) {
        state.errors = [...state.errors, error];
    },
    REMOVE_ERRORS(state) {
        if (state.errors.length === 0) {
            return;
        }
        state.errors = [];
    },
};
