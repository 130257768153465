import TCWrapper from 'vue-tag-commander';
import { INSURER_CONFIG } from '@shared/insurer';
export function getTagCommander() {
    return typeof INSURER_CONFIG.declaration.tagCommanderURL === 'string' && process.client === true
        ? TCWrapper.getInstance()
        : null;
}
export function tagCommanderSetVariable(key, value, wrapper = getTagCommander()) {
    if (wrapper)
        wrapper.setTcVar(key, value);
}
export function tagCommanderSetVariables(vars, wrapper = getTagCommander()) {
    if (wrapper)
        wrapper.setTcVars(vars);
}
export function tagCommanderReload(wrapper = getTagCommander()) {
    if (wrapper)
        wrapper.reloadAllContainers();
}
