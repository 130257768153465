import { stringAfterLastSlash } from '@shared/utils/strings';
/**
 * Returns resource id as a number from IRI
 * e.g. "/claim/54" => 54
 *
 */
export function idFromIri(iri) {
    return parseInt(stringIdFromIri(iri));
}
export function stringIdFromIri(iri) {
    return stringAfterLastSlash(iri);
}
export function uuidFromIri(iri) {
    return iri.split('/').pop();
}
export function resourceIri(resource) {
    if (typeof resource === 'string') {
        return resource;
    }
    return resource.id;
}
export function optionalResourceIri(resource) {
    var _a;
    if (resource === null || resource === undefined) {
        return null;
    }
    if (typeof resource === 'string') {
        return resource;
    }
    return (_a = resource.id) !== null && _a !== void 0 ? _a : null;
}
export function optionalCategoryIri(slug, categories) {
    var _a, _b;
    return (_b = (_a = Object.values(categories).find((c) => [slug].flat().includes(c.slug))) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
}
export function compareOptionalResource(a, b) {
    const idA = typeof a === 'string' ? a : a === null || a === void 0 ? void 0 : a.id;
    const idB = typeof b === 'string' ? b : b === null || b === void 0 ? void 0 : b.id;
    if (!idA || !idB)
        return false;
    return uuidFromIri(idA) === uuidFromIri(idB);
}
