import { objectToTranslatableChoices } from '@shared/choice';
import { MEASUREMENTS_TRAD_KEYS } from '@shared/types/api/breakInDamage';
import { QualificationRoomType } from '@shared/types/api/claim';
import { GoodDamageCategory, GoodDamageElectricity, GoodDamageFurniture, GoodDamageHeating, GoodDamageOpening, GoodDamagePlumbing, LockerDoorType, ElectricMeterType, LightSourceType, PipeType, WcType, ElectricalHeaterType, TowelDryerType, WaterRadiatorDamageType, WaterRadiatorMaterial, SlidingBayGlazingType, SlidingBayGlassType, SlidingBayLeafWidth, GarageDoorType, GarageDoorMaterial, GarageDoorDamageType, GarageDoorWidth, GarageDoorOpeningMode, DoorType, DoorMaterial, DoorDamageType, DoorGlazingType, DoorGlassType, DoorLockType, WindowMaterial, WindowDamageType, WindowGlazingType, WindowGlassType, WindowOneLeafWidth, WindowTwoLeavesWidth, WindowOneLeafHeight, WindowTwoLeavesHeight, FrenchWindowMaterial, FrenchWindowDamageType, FrenchWindowGlazingType, FrenchWindowGlassType, FrenchWindowLeafWidth, FrenchWindowLeafHeight, GoodDamageOther, GoodDamageOtherTypeMap, } from '@shared/types/api/roomDamage';
export const GOOD_DAMAGE_CATEGORY_LABELS = {
    [GoodDamageCategory.Plumbing]: 'claim.damage.real_estate.room_damage.form.question.good_damage.category.plumbing',
    [GoodDamageCategory.Heating]: 'claim.damage.real_estate.room_damage.form.question.good_damage.category.heating',
    [GoodDamageCategory.Opening]: 'claim.damage.real_estate.room_damage.form.question.good_damage.category.opening',
    [GoodDamageCategory.Electricity]: 'claim.damage.real_estate.room_damage.form.question.good_damage.category.electricity',
    [GoodDamageCategory.Furniture]: 'claim.damage.real_estate.room_damage.form.question.good_damage.category.furniture',
    [GoodDamageCategory.Other]: 'claim.damage.real_estate.room_damage.form.question.good_damage.category.other',
};
export const GOOD_DAMAGE_TYPE_LABELS = {
    [GoodDamageElectricity.LightSource]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.light_source',
    [GoodDamageElectricity.OutletOrSwitch]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.outlet_or_switch',
    [GoodDamageElectricity.ExposedElectricalSheath]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.exposed_electrical_sheath',
    [GoodDamageElectricity.ElectricMeter]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.electric_meter',
    [GoodDamageElectricity.Switchboard]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.switchboard',
    [GoodDamageFurniture.HighFurniture]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.high_furniture',
    [GoodDamageFurniture.LowFurniture]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.low_furniture',
    [GoodDamageFurniture.ColumnFurniture]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.column_furniture',
    [GoodDamageFurniture.FixedLayout]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.fixed_layout',
    [GoodDamageFurniture.Locker]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker',
    [GoodDamageFurniture.Credenza]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.credenza',
    [GoodDamageFurniture.Workplan]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.workplan',
    [GoodDamagePlumbing.AerationGrid]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.aeration_grid',
    [GoodDamagePlumbing.VisiblePipes]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes',
    [GoodDamagePlumbing.WC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.wc',
    [GoodDamagePlumbing.Faucet]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.faucet',
    [GoodDamagePlumbing.Basin]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.basin',
    [GoodDamagePlumbing.Bathtub]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.bathtub',
    [GoodDamagePlumbing.Shower]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.shower',
    [GoodDamageHeating.ElectricHeater]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.electrical_heater',
    [GoodDamageHeating.WaterRadiator]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator',
    [GoodDamageHeating.Chimney]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.chimney',
    [GoodDamageHeating.Stove]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.stove',
    [GoodDamageHeating.TowelDryer]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.towel_dryer',
    [GoodDamageOpening.Door]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door',
    [GoodDamageOpening.SlidingBay]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay',
    [GoodDamageOpening.Window]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window',
    [GoodDamageOpening.FrenchWindow]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window',
    [GoodDamageOpening.GarageDoor]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door',
    [GoodDamageOther.Other]: 'claim.damage.real_estate.room_damage.form.question.good_damage.other.other',
    [GoodDamageOther.OtherElectricity]: 'claim.damage.real_estate.room_damage.form.question.good_damage.other.electricity',
    [GoodDamageOther.OtherFurniture]: 'claim.damage.real_estate.room_damage.form.question.good_damage.other.furniture',
    [GoodDamageOther.OtherHeating]: 'claim.damage.real_estate.room_damage.form.question.good_damage.other.heating',
    [GoodDamageOther.OtherOpening]: 'claim.damage.real_estate.room_damage.form.question.good_damage.other.opening',
    [GoodDamageOther.OtherPlumbing]: 'claim.damage.real_estate.room_damage.form.question.good_damage.other.plumbing',
};
export const GOOD_DAMAGE_ROOM_ILLUSTRATIONS = {
    [QualificationRoomType.Basement]: require('@declaration/assets/images/roomDamage/room/basement.svg'),
    [QualificationRoomType.Bathroom]: require('@declaration/assets/images/roomDamage/room/bathroom.svg'),
    [QualificationRoomType.BedroomOffice]: require('@declaration/assets/images/roomDamage/room/bedroom-office.svg'),
    [QualificationRoomType.CorridorEntrance]: require('@declaration/assets/images/roomDamage/room/corridor-entrance.svg'),
    [QualificationRoomType.Garage]: require('@declaration/assets/images/roomDamage/room/garage.svg'),
    [QualificationRoomType.Kitchen]: require('@declaration/assets/images/roomDamage/room/kitchen.svg'),
    [QualificationRoomType.Laundry]: require('@declaration/assets/images/roomDamage/room/laundry.svg'),
    [QualificationRoomType.Lounge]: require('@declaration/assets/images/roomDamage/room/lounge.svg'),
    [QualificationRoomType.WC]: require('@declaration/assets/images/roomDamage/room/wc.svg'),
};
export const GOOD_DAMAGE_TYPE_ILLUSTRATIONS = {
    [GoodDamageElectricity.LightSource]: require('@declaration/assets/images/roomDamage/good/light-source.svg'),
    [GoodDamageElectricity.OutletOrSwitch]: require('@declaration/assets/images/roomDamage/good/outlet.svg'),
    [GoodDamageElectricity.ExposedElectricalSheath]: require('@declaration/assets/images/roomDamage/good/electric-sheath.svg'),
    [GoodDamageElectricity.ElectricMeter]: require('@declaration/assets/images/roomDamage/good/electric-meter.svg'),
    [GoodDamageElectricity.Switchboard]: require('@declaration/assets/images/roomDamage/good/switchboard.svg'),
    [GoodDamageFurniture.HighFurniture]: require('@declaration/assets/images/roomDamage/good/high-furniture.svg'),
    [GoodDamageFurniture.LowFurniture]: require('@declaration/assets/images/roomDamage/good/low-furniture.svg'),
    [GoodDamageFurniture.ColumnFurniture]: require('@declaration/assets/images/roomDamage/good/column-furniture.svg'),
    [GoodDamageFurniture.FixedLayout]: require('@declaration/assets/images/roomDamage/good/fixed-layout.svg'),
    [GoodDamageFurniture.Locker]: require('@declaration/assets/images/roomDamage/good/locker.svg'),
    [GoodDamageFurniture.Credenza]: require('@declaration/assets/images/roomDamage/good/credenza.svg'),
    [GoodDamageFurniture.Workplan]: require('@declaration/assets/images/roomDamage/good/workplan.svg'),
    [GoodDamagePlumbing.AerationGrid]: require('@declaration/assets/images/roomDamage/good/grille_daeration.svg'),
    [GoodDamagePlumbing.Basin]: require('@declaration/assets/images/roomDamage/good/evier.svg'),
    [GoodDamagePlumbing.Bathtub]: require('@declaration/assets/images/roomDamage/good/baignoire.svg'),
    [GoodDamagePlumbing.Faucet]: require('@declaration/assets/images/roomDamage/good/robinet.svg'),
    [GoodDamagePlumbing.Shower]: require('@declaration/assets/images/roomDamage/good/colonne_de_douche.svg'),
    [GoodDamagePlumbing.VisiblePipes]: require('@declaration/assets/images/roomDamage/good/tuyau_apparent.svg'),
    [GoodDamagePlumbing.WC]: require('@declaration/assets/images/roomDamage/good/WC.svg'),
    [GoodDamageHeating.Chimney]: require('@declaration/assets/images/roomDamage/good/cheminee.svg'),
    [GoodDamageHeating.Stove]: require('@declaration/assets/images/roomDamage/good/poele.svg'),
    [GoodDamageHeating.TowelDryer]: require('@declaration/assets/images/roomDamage/good/seche_serviette.svg'),
    [GoodDamageHeating.ElectricHeater]: require('@declaration/assets/images/roomDamage/good/radiateur_electrique.svg'),
    [GoodDamageHeating.WaterRadiator]: require('@declaration/assets/images/roomDamage/good/radiateur_a_eau.svg'),
    [GoodDamageOpening.SlidingBay]: require('@declaration/assets/images/roomDamage/good/baie_coulissante.svg'),
    [GoodDamageOpening.SlidingBay]: require('@declaration/assets/images/roomDamage/good/baie_coulissante.svg'),
    [GoodDamageOpening.Window]: require('@declaration/assets/images/roomDamage/good/fenetre.svg'),
    [GoodDamageOpening.FrenchWindow]: require('@declaration/assets/images/roomDamage/good/porte_fenetre.svg'),
    [GoodDamageOpening.Door]: require('@declaration/assets/images/roomDamage/good/porte.svg'),
    [GoodDamageOpening.GarageDoor]: require('@declaration/assets/images/roomDamage/good/garage_door.svg'),
    [GoodDamageOther.OtherElectricity]: require('@declaration/assets/images/roomDamage/good/other.svg'),
    [GoodDamageOther.OtherFurniture]: require('@declaration/assets/images/roomDamage/good/other.svg'),
    [GoodDamageOther.OtherHeating]: require('@declaration/assets/images/roomDamage/good/other.svg'),
    [GoodDamageOther.OtherOpening]: require('@declaration/assets/images/roomDamage/good/other.svg'),
    [GoodDamageOther.OtherPlumbing]: require('@declaration/assets/images/roomDamage/good/other.svg'),
};
const GOOD_DAMAGES_PRESETS = {
    ELECTRICITY_ALL: Object.values(GoodDamageElectricity),
    PLUMBING_ALL: Object.values(GoodDamagePlumbing),
    HEATING_ALL: Object.values(GoodDamageHeating),
    OPENING_DEFAULT: [
        GoodDamageOpening.Door,
        GoodDamageOpening.Window,
        GoodDamageOpening.FrenchWindow,
        GoodDamageOpening.SlidingBay,
    ],
    OPENING_GARAGE: [
        GoodDamageOpening.Door,
        GoodDamageOpening.Window,
        GoodDamageOpening.FrenchWindow,
        GoodDamageOpening.GarageDoor,
    ],
    FURNITURE_DEFAULT: [
        GoodDamageFurniture.HighFurniture,
        GoodDamageFurniture.LowFurniture,
        GoodDamageFurniture.ColumnFurniture,
    ],
    FURNITURE_INNER: [GoodDamageFurniture.FixedLayout, GoodDamageFurniture.Locker],
};
export const GOOD_DAMAGES_MAP = {
    [QualificationRoomType.Lounge]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Furniture]: GOOD_DAMAGES_PRESETS.FURNITURE_INNER,
        [GoodDamageCategory.Plumbing]: [
            GoodDamagePlumbing.VisiblePipes,
            GoodDamagePlumbing.AerationGrid,
        ],
        [GoodDamageCategory.Heating]: [
            GoodDamageHeating.ElectricHeater,
            GoodDamageHeating.Chimney,
            GoodDamageHeating.Stove,
        ],
        [GoodDamageCategory.Opening]: GOOD_DAMAGES_PRESETS.OPENING_DEFAULT,
    },
    [QualificationRoomType.Kitchen]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Plumbing]: [
            GoodDamagePlumbing.AerationGrid,
            GoodDamagePlumbing.Basin,
            GoodDamagePlumbing.VisiblePipes,
            GoodDamagePlumbing.Faucet,
        ],
        [GoodDamageCategory.Heating]: [
            GoodDamageHeating.ElectricHeater,
            GoodDamageHeating.WaterRadiator,
            GoodDamageHeating.Chimney,
            GoodDamageHeating.Stove,
        ],
        [GoodDamageCategory.Furniture]: [
            ...GOOD_DAMAGES_PRESETS.FURNITURE_DEFAULT,
            GoodDamageFurniture.Credenza,
            GoodDamageFurniture.Workplan,
        ],
        [GoodDamageCategory.Opening]: GOOD_DAMAGES_PRESETS.OPENING_DEFAULT,
    },
    [QualificationRoomType.BedroomOffice]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Furniture]: GOOD_DAMAGES_PRESETS.FURNITURE_INNER,
        [GoodDamageCategory.Plumbing]: [
            GoodDamagePlumbing.AerationGrid,
            GoodDamagePlumbing.VisiblePipes,
        ],
        [GoodDamageCategory.Heating]: [
            GoodDamageHeating.ElectricHeater,
            GoodDamageHeating.WaterRadiator,
            GoodDamageHeating.Chimney,
            GoodDamageHeating.Stove,
        ],
        [GoodDamageCategory.Opening]: GOOD_DAMAGES_PRESETS.OPENING_DEFAULT,
    },
    [QualificationRoomType.Bathroom]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Furniture]: GOOD_DAMAGES_PRESETS.FURNITURE_DEFAULT,
        [GoodDamageCategory.Plumbing]: GOOD_DAMAGES_PRESETS.PLUMBING_ALL,
        [GoodDamageCategory.Heating]: [
            GoodDamageHeating.ElectricHeater,
            GoodDamageHeating.WaterRadiator,
            GoodDamageHeating.TowelDryer,
        ],
        [GoodDamageCategory.Opening]: GOOD_DAMAGES_PRESETS.OPENING_DEFAULT,
    },
    [QualificationRoomType.WC]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Furniture]: GOOD_DAMAGES_PRESETS.FURNITURE_INNER,
        [GoodDamageCategory.Plumbing]: [
            GoodDamagePlumbing.AerationGrid,
            GoodDamagePlumbing.VisiblePipes,
            GoodDamagePlumbing.WC,
            GoodDamagePlumbing.Faucet,
            GoodDamagePlumbing.Basin,
        ],
        [GoodDamageCategory.Heating]: [
            GoodDamageHeating.ElectricHeater,
            GoodDamageHeating.WaterRadiator,
        ],
        [GoodDamageCategory.Opening]: GOOD_DAMAGES_PRESETS.OPENING_DEFAULT,
    },
    [QualificationRoomType.CorridorEntrance]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Furniture]: GOOD_DAMAGES_PRESETS.FURNITURE_INNER,
        [GoodDamageCategory.Plumbing]: [
            GoodDamagePlumbing.VisiblePipes,
            GoodDamagePlumbing.AerationGrid,
        ],
        [GoodDamageCategory.Heating]: [
            GoodDamageHeating.ElectricHeater,
            GoodDamageHeating.WaterRadiator,
            GoodDamageHeating.Chimney,
            GoodDamageHeating.Stove,
        ],
        [GoodDamageCategory.Opening]: GOOD_DAMAGES_PRESETS.OPENING_DEFAULT,
    },
    [QualificationRoomType.Garage]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Furniture]: GOOD_DAMAGES_PRESETS.FURNITURE_INNER,
        [GoodDamageCategory.Plumbing]: [
            GoodDamagePlumbing.VisiblePipes,
            GoodDamagePlumbing.AerationGrid,
            GoodDamagePlumbing.Basin,
            GoodDamagePlumbing.Faucet,
        ],
        [GoodDamageCategory.Opening]: GOOD_DAMAGES_PRESETS.OPENING_GARAGE,
    },
    [QualificationRoomType.Basement]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Furniture]: GOOD_DAMAGES_PRESETS.FURNITURE_INNER,
        [GoodDamageCategory.Plumbing]: [
            GoodDamagePlumbing.AerationGrid,
            GoodDamagePlumbing.VisiblePipes,
        ],
        [GoodDamageCategory.Heating]: [
            GoodDamageHeating.ElectricHeater,
            GoodDamageHeating.WaterRadiator,
        ],
        [GoodDamageCategory.Opening]: [GoodDamageOpening.Door, GoodDamageOpening.Window],
    },
    [QualificationRoomType.Laundry]: {
        [GoodDamageCategory.Electricity]: GOOD_DAMAGES_PRESETS.ELECTRICITY_ALL,
        [GoodDamageCategory.Furniture]: GOOD_DAMAGES_PRESETS.FURNITURE_INNER,
        [GoodDamageCategory.Plumbing]: [
            GoodDamagePlumbing.AerationGrid,
            GoodDamagePlumbing.VisiblePipes,
            GoodDamagePlumbing.Basin,
            GoodDamagePlumbing.Faucet,
        ],
        [GoodDamageCategory.Heating]: [
            GoodDamageHeating.ElectricHeater,
            GoodDamageHeating.WaterRadiator,
        ],
        [GoodDamageCategory.Opening]: GOOD_DAMAGES_PRESETS.OPENING_DEFAULT,
    },
};
export function goodDamageCategoryFilter(category) {
    return (goodDamage) => goodDamage.category === category;
}
export function goodDamageTypeFilter(type) {
    return (goodDamage) => goodDamage.type === type;
}
export function getGoodDamageCategoryChoices(room) {
    const categories = GOOD_DAMAGES_MAP[room];
    if (!categories)
        return null;
    return Object.keys(categories)
        .map((category) => ({
        value: category,
        tradKey: GOOD_DAMAGE_CATEGORY_LABELS[category],
    }))
        .concat([
        {
            value: GoodDamageCategory.Other,
            tradKey: GOOD_DAMAGE_CATEGORY_LABELS[GoodDamageCategory.Other],
        },
    ]);
}
export function getGoodDamageTypeChoices(room, category) {
    var _a;
    const types = (_a = GOOD_DAMAGES_MAP[room]) === null || _a === void 0 ? void 0 : _a[category];
    if (!types)
        return null;
    return types
        .map((type) => ({
        value: type,
        tradKey: GOOD_DAMAGE_TYPE_LABELS[type],
    }))
        .concat([
        {
            value: GoodDamageOtherTypeMap[category],
            tradKey: GOOD_DAMAGE_TYPE_LABELS[GoodDamageOtherTypeMap[category]],
        },
    ]);
}
export function getGoodDamageCategoryDefinition(room, category) {
    var _a;
    const types = (_a = GOOD_DAMAGES_MAP[room]) === null || _a === void 0 ? void 0 : _a[category];
    if (!types)
        return null;
    return {
        category,
        tradKey: `claim.damage.real_estate.room_damage.form.question.good_damage.${category}.title`,
        types: types.concat([GoodDamageOtherTypeMap[category]]),
    };
}
export function getGoodDamageTypeDefinition(room, category, type) {
    var _a, _b;
    const types = (_b = (_a = getGoodDamageCategoryDefinition(room, category)) === null || _a === void 0 ? void 0 : _a.types) !== null && _b !== void 0 ? _b : null;
    return types && types.includes(type)
        ? {
            type,
            tradKey: GOOD_DAMAGE_TYPE_LABELS[type],
            illustration: GOOD_DAMAGE_TYPE_ILLUSTRATIONS[type],
        }
        : null;
}
export const DESCRIBIBLE_GOOD_DAMAGE_TYPES = [
    GoodDamageFurniture.Locker,
    GoodDamageFurniture.Workplan,
    GoodDamageElectricity.LightSource,
    GoodDamageElectricity.ExposedElectricalSheath,
    GoodDamageElectricity.ElectricMeter,
    GoodDamagePlumbing.VisiblePipes,
    GoodDamagePlumbing.WC,
    GoodDamageHeating.ElectricHeater,
    GoodDamageHeating.TowelDryer,
    GoodDamageHeating.WaterRadiator,
    GoodDamageOpening.SlidingBay,
    GoodDamageOpening.GarageDoor,
    GoodDamageOpening.Door,
    GoodDamageOpening.Window,
    GoodDamageOpening.FrenchWindow,
];
export const LOCKER_DOOR_TYPE_LABELS = {
    [LockerDoorType.SLIDING]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.door_type.sliding_door',
    [LockerDoorType.SWING]: 'claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.door_type.swing_door',
};
export const LOCKER_DOOR_TYPE_CHOICES = objectToTranslatableChoices(LOCKER_DOOR_TYPE_LABELS);
export const LOCKER_DOOR_TYPE_MODEL_LABELS = {
    [LockerDoorType.SLIDING]: 'model.good_damage.locker.door_type.sliding_door',
    [LockerDoorType.SWING]: 'model.good_damage.locker.door_type.swing_door',
};
export const LOCKER_DOOR_TYPE_MODEL_CHOICES = objectToTranslatableChoices(LOCKER_DOOR_TYPE_MODEL_LABELS);
export const ELECTRIC_METER_TYPE_LABELS = {
    [ElectricMeterType.LINKY]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.electric_meter.meter_type.linky',
    [ElectricMeterType.REGULAR]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.electric_meter.meter_type.regular',
};
export const ELECTRIC_METER_TYPE_CHOICES = objectToTranslatableChoices(ELECTRIC_METER_TYPE_LABELS);
export const ELECTRIC_METER_TYPE_MODEL_LABELS = {
    [ElectricMeterType.LINKY]: 'model.good_damage.electric_meter.meter_type.linky',
    [ElectricMeterType.REGULAR]: 'model.good_damage.electric_meter.meter_type.regular',
};
export const ELECTRIC_METER_TYPE_MODEL_CHOICES = objectToTranslatableChoices(ELECTRIC_METER_TYPE_MODEL_LABELS);
export const LIGHT_SOURCE_TYPE_LABELS = {
    [LightSourceType.DCL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.light_source.light_type.device_connection_luminaires',
    [LightSourceType.SPOT_LIGHT]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.light_source.light_type.spot_light',
    [LightSourceType.WALL_LIGHT]: 'claim.damage.real_estate.room_damage.form.question.good_damage.electricity.light_source.light_type.wall_light',
};
export const LIGHT_SOURCE_TYPE_CHOICES = objectToTranslatableChoices(LIGHT_SOURCE_TYPE_LABELS);
export const LIGHT_SOURCE_TYPE_MODEL_LABELS = {
    [LightSourceType.DCL]: 'model.good_damage.light_source.light_type.device_connection_luminaires',
    [LightSourceType.SPOT_LIGHT]: 'model.good_damage.light_source.light_type.spot_light',
    [LightSourceType.WALL_LIGHT]: 'model.good_damage.light_source.light_type.wall_light',
};
export const LIGHT_SOURCE_TYPE_MODEL_CHOICES = objectToTranslatableChoices(LIGHT_SOURCE_TYPE_MODEL_LABELS);
export const PIPE_TYPE_LABELS = {
    [PipeType.COPPER]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes.pipe_type.copper',
    [PipeType.PEX]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes.pipe_type.pex',
    [PipeType.FLEXIBLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes.pipe_type.flexible',
    [PipeType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.visible_pipes.pipe_type.unknown',
};
export const PIPE_TYPE_CHOICES = objectToTranslatableChoices(PIPE_TYPE_LABELS);
export const PIPE_TYPE_MODEL_LABELS = {
    [PipeType.COPPER]: 'model.good_damage.visible_pipes.pipe_type.copper',
    [PipeType.PEX]: 'model.good_damage.visible_pipes.pipe_type.pex',
    [PipeType.FLEXIBLE]: 'model.good_damage.visible_pipes.pipe_type.flexible',
    [PipeType.UNKNOWN]: 'model.good_damage.visible_pipes.pipe_type.unknown',
};
export const PIPE_TYPE_MODEL_CHOICES = objectToTranslatableChoices(PIPE_TYPE_MODEL_LABELS);
export const WC_TYPE_LABELS = {
    [WcType.BLOCK]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.wc.wc_type.block',
    [WcType.HUNG]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.wc.wc_type.hung',
    [WcType.MACERATOR]: 'claim.damage.real_estate.room_damage.form.question.good_damage.plumbing.wc.wc_type.macerator',
};
export const WC_TYPE_CHOICES = objectToTranslatableChoices(WC_TYPE_LABELS);
export const WC_TYPE_MODEL_LABELS = {
    [WcType.BLOCK]: 'model.good_damage.wc.wc_type.block',
    [WcType.HUNG]: 'model.good_damage.wc.wc_type.hung',
    [WcType.MACERATOR]: 'model.good_damage.wc.wc_type.macerator',
};
export const WC_TYPE_MODEL_CHOICES = objectToTranslatableChoices(WC_TYPE_MODEL_LABELS);
export const ELECTRICAL_HEATER_TYPE_LABELS = {
    [ElectricalHeaterType.RADIANT_RADIATOR]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.electrical_heater.heater_type.radiant_radiator',
    [ElectricalHeaterType.CONVECTION_HEATER]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.electrical_heater.heater_type.convection_heater',
    [ElectricalHeaterType.INERTIA_RADIATOR]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.electrical_heater.heater_type.inertia_radiator',
    [ElectricalHeaterType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.electrical_heater.heater_type.unknown',
};
export const ELECTRICAL_HEATER_TYPE_CHOICES = objectToTranslatableChoices(ELECTRICAL_HEATER_TYPE_LABELS);
export const ELECTRICAL_HEATER_TYPE_MODEL_LABELS = {
    [ElectricalHeaterType.RADIANT_RADIATOR]: 'model.good_damage.electrical_heater.heater_type.radiant_radiator',
    [ElectricalHeaterType.CONVECTION_HEATER]: 'model.good_damage.electrical_heater.heater_type.convection_heater',
    [ElectricalHeaterType.INERTIA_RADIATOR]: 'model.good_damage.electrical_heater.heater_type.inertia_radiator',
    [ElectricalHeaterType.UNKNOWN]: 'model.good_damage.electrical_heater.heater_type.unknown',
};
export const ELECTRICAL_HEATER_TYPE_MODEL_CHOICES = objectToTranslatableChoices(ELECTRICAL_HEATER_TYPE_MODEL_LABELS);
export const TOWEL_DRYER_TYPE_LABELS = {
    [TowelDryerType.WATER_DRYER]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.towel_dryer.dryer_type.water_dryer',
    [TowelDryerType.ELECTRICAL_DRYER]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.towel_dryer.dryer_type.electrical_dryer',
    [TowelDryerType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.towel_dryer.dryer_type.unknown',
};
export const TOWEL_DRYER_TYPE_CHOICES = objectToTranslatableChoices(TOWEL_DRYER_TYPE_LABELS);
export const TOWEL_DRYER_TYPE_MODEL_LABELS = {
    [TowelDryerType.WATER_DRYER]: 'model.good_damage.towel_dryer.dryer_type.water_dryer',
    [TowelDryerType.ELECTRICAL_DRYER]: 'model.good_damage.towel_dryer.dryer_type.electrical_dryer',
    [TowelDryerType.UNKNOWN]: 'model.good_damage.towel_dryer.dryer_type.unknown',
};
export const TOWEL_DRYER_TYPE_MODEL_CHOICES = objectToTranslatableChoices(TOWEL_DRYER_TYPE_MODEL_LABELS);
export const WATER_RADIATOR_DAMAGE_TYPE_LABELS = {
    [WaterRadiatorDamageType.AESTHETIC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.damage_type.aesthetic',
    [WaterRadiatorDamageType.DAMAGED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.damage_type.damaged',
};
export const WATER_RADIATOR_DAMAGE_TYPE_CHOICES = objectToTranslatableChoices(WATER_RADIATOR_DAMAGE_TYPE_LABELS);
export const WATER_RADIATOR_DAMAGE_TYPE_MODEL_LABELS = {
    [WaterRadiatorDamageType.AESTHETIC]: 'model.good_damage.water_radiator.damage_type.aesthetic',
    [WaterRadiatorDamageType.DAMAGED]: 'model.good_damage.water_radiator.damage_type.damaged',
};
export const WATER_RADIATOR_DAMAGE_TYPE_MODEL_CHOICES = objectToTranslatableChoices(WATER_RADIATOR_DAMAGE_TYPE_MODEL_LABELS);
export const WATER_RADIATOR_MATERIAL_LABELS = {
    [WaterRadiatorMaterial.CAST_IRON]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.material.cast_iron',
    [WaterRadiatorMaterial.ALUMINUM_OR_STEEL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.material.aluminum_or_steel',
    [WaterRadiatorMaterial.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.material.unknown',
};
export const WATER_RADIATOR_MATERIAL_CHOICES = objectToTranslatableChoices(WATER_RADIATOR_MATERIAL_LABELS);
export const WATER_RADIATOR_MATERIAL_MODEL_LABELS = {
    [WaterRadiatorMaterial.CAST_IRON]: 'model.good_damage.water_radiator.material.cast_iron',
    [WaterRadiatorMaterial.ALUMINUM_OR_STEEL]: 'model.good_damage.water_radiator.material.aluminum_or_steel',
    [WaterRadiatorMaterial.UNKNOWN]: 'model.good_damage.water_radiator.material.unknown',
};
export const WATER_RADIATOR_MATERIAL_MODEL_CHOICES = objectToTranslatableChoices(WATER_RADIATOR_MATERIAL_MODEL_LABELS);
export const SLIDING_BAY_GLAZING_TYPE_LABELS = {
    [SlidingBayGlazingType.SIMPLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glazing_type.simple',
    [SlidingBayGlazingType.DOUBLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glazing_type.double',
    [SlidingBayGlazingType.TRIPLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glazing_type.triple',
    [SlidingBayGlazingType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glazing_type.unknown',
};
export const SLIDING_BAY_GLAZING_TYPE_CHOICES = objectToTranslatableChoices(SLIDING_BAY_GLAZING_TYPE_LABELS);
export const SLIDING_BAY_GLAZING_TYPE_MODEL_LABELS = {
    [SlidingBayGlazingType.SIMPLE]: 'model.good_damage.sliding_bay.glazing_type.simple',
    [SlidingBayGlazingType.DOUBLE]: 'model.good_damage.sliding_bay.glazing_type.double',
    [SlidingBayGlazingType.TRIPLE]: 'model.good_damage.sliding_bay.glazing_type.triple',
    [SlidingBayGlazingType.UNKNOWN]: 'model.good_damage.sliding_bay.glazing_type.unknown',
};
export const SLIDING_BAY_GLAZING_TYPE_MODEL_CHOICES = objectToTranslatableChoices(SLIDING_BAY_GLAZING_TYPE_MODEL_LABELS);
export const SLIDING_BAY_GLASS_TYPE_LABELS = {
    [SlidingBayGlassType.LAMINATED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glass_type.laminated',
    [SlidingBayGlassType.NON_LAMINATED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glass_type.non_laminated',
    [SlidingBayGlassType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glass_type.unknown',
};
export const SLIDING_BAY_GLASS_TYPE_CHOICES = objectToTranslatableChoices(SLIDING_BAY_GLASS_TYPE_LABELS);
export const SLIDING_BAY_GLASS_TYPE_MODEL_LABELS = {
    [SlidingBayGlassType.LAMINATED]: 'model.good_damage.sliding_bay.glass_type.laminated',
    [SlidingBayGlassType.NON_LAMINATED]: 'model.good_damage.sliding_bay.glass_type.non_laminated',
    [SlidingBayGlassType.UNKNOWN]: 'model.good_damage.sliding_bay.glass_type.unknown',
};
export const SLIDING_BAY_GLASS_TYPE_MODEL_CHOICES = objectToTranslatableChoices(SLIDING_BAY_GLASS_TYPE_MODEL_LABELS);
export const SLIDING_BAY_LEAF_WIDTH_LABELS = {
    [SlidingBayLeafWidth.SMALL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_width.small',
    [SlidingBayLeafWidth.MEDIUM]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_width.medium',
    [SlidingBayLeafWidth.LARGE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_width.large',
};
export const SLIDING_BAY_LEAF_WIDTH_CHOICES = objectToTranslatableChoices(SLIDING_BAY_LEAF_WIDTH_LABELS);
export const SLIDING_BAY_LEAF_WIDTH_MODEL_LABELS = {
    [SlidingBayLeafWidth.SMALL]: 'model.good_damage.sliding_bay.leaf_width.small',
    [SlidingBayLeafWidth.MEDIUM]: 'model.good_damage.sliding_bay.leaf_width.medium',
    [SlidingBayLeafWidth.LARGE]: 'model.good_damage.sliding_bay.leaf_width.large',
};
export const SLIDING_BAY_LEAF_WIDTH_MODEL_CHOICES = objectToTranslatableChoices(SLIDING_BAY_LEAF_WIDTH_MODEL_LABELS);
export const GARAGE_DOOR_TYPE_LABELS = {
    [GarageDoorType.FLAPPY]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type.flappy_door',
    [GarageDoorType.FOLDING]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type.folding_door',
    [GarageDoorType.ROLLING]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type.rolling_door',
    [GarageDoorType.SECTIONAL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type.sectional_door',
    [GarageDoorType.SLIDING]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type.sliding_door',
    [GarageDoorType.TIPPER]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type.tipper_door',
};
export const GARAGE_DOOR_TYPE_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_TYPE_LABELS);
export const GARAGE_DOOR_TYPE_MODEL_LABELS = {
    [GarageDoorType.FLAPPY]: 'model.good_damage.garage_door.door_type.flappy_door',
    [GarageDoorType.FOLDING]: 'model.good_damage.garage_door.door_type.folding_door',
    [GarageDoorType.ROLLING]: 'model.good_damage.garage_door.door_type.rolling_door',
    [GarageDoorType.SECTIONAL]: 'model.good_damage.garage_door.door_type.sectional_door',
    [GarageDoorType.SLIDING]: 'model.good_damage.garage_door.door_type.sliding_door',
    [GarageDoorType.TIPPER]: 'model.good_damage.garage_door.door_type.tipper_door',
};
export const GARAGE_DOOR_TYPE_MODEL_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_TYPE_MODEL_LABELS);
export const GARAGE_DOOR_MATERIAL_LABELS = {
    [GarageDoorMaterial.WOOD]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.material.wood',
    [GarageDoorMaterial.PVC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.material.pvc',
    [GarageDoorMaterial.METAL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.material.metal',
};
export const GARAGE_DOOR_MATERIAL_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_MATERIAL_LABELS);
export const GARAGE_DOOR_MATERIAL_MODEL_LABELS = {
    [GarageDoorMaterial.WOOD]: 'model.good_damage.garage_door.material.wood',
    [GarageDoorMaterial.PVC]: 'model.good_damage.garage_door.material.pvc',
    [GarageDoorMaterial.METAL]: 'model.good_damage.garage_door.material.metal',
};
export const GARAGE_DOOR_MATERIAL_MODEL_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_MATERIAL_MODEL_LABELS);
export const GARAGE_DOOR_DAMAGE_TYPE_LABELS = {
    [GarageDoorDamageType.AESTHETIC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.damage_type.aesthetic',
    [GarageDoorDamageType.DAMAGED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.damage_type.damaged',
};
export const GARAGE_DOOR_DAMAGE_TYPE_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_DAMAGE_TYPE_LABELS);
export const GARAGE_DOOR_DAMAGE_TYPE_MODEL_LABELS = {
    [GarageDoorDamageType.AESTHETIC]: 'model.good_damage.garage_door.damage_type.aesthetic',
    [GarageDoorDamageType.DAMAGED]: 'model.good_damage.garage_door.damage_type.damaged',
};
export const GARAGE_DOOR_DAMAGE_TYPE_MODEL_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_DAMAGE_TYPE_MODEL_LABELS);
export const GARAGE_DOOR_WIDTH_LABELS = {
    [GarageDoorWidth.STANDARD]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_width.standard',
    [GarageDoorWidth.LARGE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_width.large',
};
export const GARAGE_DOOR_WIDTH_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_WIDTH_LABELS);
export const GARAGE_DOOR_WIDTH_MODEL_LABELS = {
    [GarageDoorWidth.STANDARD]: 'model.good_damage.garage_door.door_width.standard',
    [GarageDoorWidth.LARGE]: 'model.good_damage.garage_door.door_width.large',
};
export const GARAGE_DOOR_WIDTH_MODEL_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_WIDTH_MODEL_LABELS);
export const GARAGE_DOOR_OPENING_MODE_LABELS = {
    [GarageDoorOpeningMode.MANUAL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.opening_mode.manual',
    [GarageDoorOpeningMode.MOTORIZED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.opening_mode.motorized',
};
export const GARAGE_DOOR_OPENING_MODE_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_OPENING_MODE_LABELS);
export const GARAGE_DOOR_OPENING_MODE_MODEL_LABELS = {
    [GarageDoorOpeningMode.MANUAL]: 'model.good_damage.garage_door.opening_mode.manual',
    [GarageDoorOpeningMode.MOTORIZED]: 'model.good_damage.garage_door.opening_mode.motorized',
};
export const GARAGE_DOOR_OPENING_MODE_MODEL_CHOICES = objectToTranslatableChoices(GARAGE_DOOR_OPENING_MODE_MODEL_LABELS);
export const DOOR_TYPE_LABELS = {
    [DoorType.INDOOR]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.door_type.indoor',
    [DoorType.MAIN_ENTRANCE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.door_type.main_entrance',
    [DoorType.SERVICE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.door_type.service',
};
export const DOOR_TYPE_CHOICES = objectToTranslatableChoices(DOOR_TYPE_LABELS);
export const DOOR_TYPE_MODEL_LABELS = {
    [DoorType.INDOOR]: 'model.good_damage.door.door_type.indoor',
    [DoorType.MAIN_ENTRANCE]: 'model.good_damage.door.door_type.main_entrance',
    [DoorType.SERVICE]: 'model.good_damage.door.door_type.service',
};
export const DOOR_TYPE_MODEL_CHOICES = objectToTranslatableChoices(DOOR_TYPE_MODEL_LABELS);
export const DOOR_MATERIAL_LABELS = {
    [DoorMaterial.WOOD]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.material.wood',
    [DoorMaterial.PVC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.material.pvc',
    [DoorMaterial.METAL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.material.metal',
};
export const DOOR_MATERIAL_CHOICES = objectToTranslatableChoices(DOOR_MATERIAL_LABELS);
export const DOOR_MATERIAL_MODEL_LABELS = {
    [DoorMaterial.WOOD]: 'model.good_damage.door.material.wood',
    [DoorMaterial.PVC]: 'model.good_damage.door.material.pvc',
    [DoorMaterial.METAL]: 'model.good_damage.door.material.metal',
};
export const DOOR_MATERIAL_MODEL_CHOICES = objectToTranslatableChoices(DOOR_MATERIAL_MODEL_LABELS);
export const DOOR_DAMAGE_TYPE_LABELS = {
    [DoorDamageType.AESTHETIC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.damage_type.aesthetic',
    [DoorDamageType.DAMAGED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.damage_type.damaged',
};
export const DOOR_DAMAGE_TYPE_CHOICES = objectToTranslatableChoices(DOOR_DAMAGE_TYPE_LABELS);
export const DOOR_DAMAGE_TYPE_MODEL_LABELS = {
    [DoorDamageType.AESTHETIC]: 'model.good_damage.door.damage_type.aesthetic',
    [DoorDamageType.DAMAGED]: 'model.good_damage.door.damage_type.damaged',
};
export const DOOR_DAMAGE_TYPE_MODEL_CHOICES = objectToTranslatableChoices(DOOR_DAMAGE_TYPE_MODEL_LABELS);
export const DOOR_GLAZING_TYPE_LABELS = {
    [DoorGlazingType.SIMPLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glazing_type.simple',
    [DoorGlazingType.DOUBLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glazing_type.double',
    [DoorGlazingType.TRIPLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glazing_type.triple',
    [DoorGlazingType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glazing_type.unknown',
};
export const DOOR_GLAZING_TYPE_CHOICES = objectToTranslatableChoices(DOOR_GLAZING_TYPE_LABELS);
export const DOOR_GLAZING_TYPE_MODEL_LABELS = {
    [DoorGlazingType.SIMPLE]: 'model.good_damage.door.glazing_type.simple',
    [DoorGlazingType.DOUBLE]: 'model.good_damage.door.glazing_type.double',
    [DoorGlazingType.TRIPLE]: 'model.good_damage.door.glazing_type.triple',
    [DoorGlazingType.UNKNOWN]: 'model.good_damage.door.glazing_type.unknown',
};
export const DOOR_GLAZING_TYPE_MODEL_CHOICES = objectToTranslatableChoices(DOOR_GLAZING_TYPE_MODEL_LABELS);
export const DOOR_GLASS_TYPE_LABELS = {
    [DoorGlassType.LAMINATED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glass_type.laminated',
    [DoorGlassType.NON_LAMINATED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glass_type.non_laminated',
    [DoorGlassType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glass_type.unknown',
};
export const DOOR_GLASS_TYPE_CHOICES = objectToTranslatableChoices(DOOR_GLASS_TYPE_LABELS);
export const DOOR_GLASS_TYPE_MODEL_LABELS = {
    [DoorGlassType.LAMINATED]: 'model.good_damage.door.glass_type.laminated',
    [DoorGlassType.NON_LAMINATED]: 'model.good_damage.door.glass_type.non_laminated',
    [DoorGlassType.UNKNOWN]: 'model.good_damage.door.glass_type.unknown',
};
export const DOOR_GLASS_TYPE_MODEL_CHOICES = objectToTranslatableChoices(DOOR_GLASS_TYPE_MODEL_LABELS);
export const DOOR_LOCK_TYPE_LABELS = {
    [DoorLockType.NO_LOCK]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.no_lock',
    [DoorLockType.ONE_LOCK]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.one_lock',
    [DoorLockType.TWO_LOCKS]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.two_locks',
    [DoorLockType.THREE_LOCKS]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.three_locks',
    [DoorLockType.FIXATED_MULTIPOINT_LOCK]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.fixated_multipoint_lock',
    [DoorLockType.FAIRED_MULTIPOINT_LOCK]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.faired_multipoint_lock',
    [DoorLockType.INTEGRATED_MULTIPOINT_LOCK]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type.integrated_multipoint_lock',
};
export const DOOR_LOCK_TYPE_CHOICES = objectToTranslatableChoices(DOOR_LOCK_TYPE_LABELS);
export const DOOR_LOCK_TYPE_MODEL_LABELS = {
    [DoorLockType.NO_LOCK]: 'model.good_damage.door.lock_type.no_lock',
    [DoorLockType.ONE_LOCK]: 'model.good_damage.door.lock_type.one_lock',
    [DoorLockType.TWO_LOCKS]: 'model.good_damage.door.lock_type.two_locks',
    [DoorLockType.THREE_LOCKS]: 'model.good_damage.door.lock_type.three_locks',
    [DoorLockType.FIXATED_MULTIPOINT_LOCK]: 'model.good_damage.door.lock_type.fixated_multipoint_lock',
    [DoorLockType.FAIRED_MULTIPOINT_LOCK]: 'model.good_damage.door.lock_type.faired_multipoint_lock',
    [DoorLockType.INTEGRATED_MULTIPOINT_LOCK]: 'model.good_damage.door.lock_type.integrated_multipoint_lock',
};
export const DOOR_LOCK_TYPE_MODEL_CHOICES = objectToTranslatableChoices(DOOR_LOCK_TYPE_MODEL_LABELS);
export const WINDOW_MATERIAL_LABELS = {
    [WindowMaterial.WOOD]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.material.wood',
    [WindowMaterial.PVC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.material.pvc',
    [WindowMaterial.METAL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.material.metal',
};
export const WINDOW_MATERIAL_CHOICES = objectToTranslatableChoices(WINDOW_MATERIAL_LABELS);
export const WINDOW_MATERIAL_MODEL_LABELS = {
    [WindowMaterial.WOOD]: 'model.good_damage.window.material.wood',
    [WindowMaterial.PVC]: 'model.good_damage.window.material.pvc',
    [WindowMaterial.METAL]: 'model.good_damage.window.material.metal',
};
export const WINDOW_MATERIAL_MODEL_CHOICES = objectToTranslatableChoices(WINDOW_MATERIAL_MODEL_LABELS);
export const WINDOW_DAMAGE_TYPE_LABELS = {
    [WindowDamageType.AESTHETIC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.damage_type.aesthetic',
    [WindowDamageType.DAMAGED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.damage_type.damaged',
};
export const WINDOW_DAMAGE_TYPE_CHOICES = objectToTranslatableChoices(WINDOW_DAMAGE_TYPE_LABELS);
export const WINDOW_DAMAGE_TYPE_MODEL_LABELS = {
    [WindowDamageType.AESTHETIC]: 'model.good_damage.window.damage_type.aesthetic',
    [WindowDamageType.DAMAGED]: 'model.good_damage.window.damage_type.damaged',
};
export const WINDOW_DAMAGE_TYPE_MODEL_CHOICES = objectToTranslatableChoices(WINDOW_DAMAGE_TYPE_MODEL_LABELS);
export const WINDOW_GLAZING_TYPE_LABELS = {
    [WindowGlazingType.SIMPLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glazing_type.simple',
    [WindowGlazingType.DOUBLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glazing_type.double',
    [WindowGlazingType.TRIPLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glazing_type.triple',
    [WindowGlazingType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glazing_type.unknown',
};
export const WINDOW_GLAZING_TYPE_CHOICES = objectToTranslatableChoices(WINDOW_GLAZING_TYPE_LABELS);
export const WINDOW_GLAZING_TYPE_MODEL_LABELS = {
    [WindowGlazingType.SIMPLE]: 'model.good_damage.window.glazing_type.simple',
    [WindowGlazingType.DOUBLE]: 'model.good_damage.window.glazing_type.double',
    [WindowGlazingType.TRIPLE]: 'model.good_damage.window.glazing_type.triple',
    [WindowGlazingType.UNKNOWN]: 'model.good_damage.window.glazing_type.unknown',
};
export const WINDOW_GLAZING_TYPE_MODEL_CHOICES = objectToTranslatableChoices(WINDOW_GLAZING_TYPE_MODEL_LABELS);
export const WINDOW_GLASS_TYPE_LABELS = {
    [WindowGlassType.LAMINATED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glass_type.laminated',
    [WindowGlassType.NON_LAMINATED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glass_type.non_laminated',
    [WindowGlassType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.window.glass_type.unknown',
};
export const WINDOW_GLASS_TYPE_CHOICES = objectToTranslatableChoices(WINDOW_GLASS_TYPE_LABELS);
export const WINDOW_GLASS_TYPE_MODEL_LABELS = {
    [WindowGlassType.LAMINATED]: 'model.good_damage.window.glass_type.laminated',
    [WindowGlassType.NON_LAMINATED]: 'model.good_damage.window.glass_type.non_laminated',
    [WindowGlassType.UNKNOWN]: 'model.good_damage.window.glass_type.unknown',
};
export const WINDOW_GLASS_TYPE_MODEL_CHOICES = objectToTranslatableChoices(WINDOW_GLASS_TYPE_MODEL_LABELS);
export const WINDOW_ONE_LEAF_WIDTH_LABELS = {
    [WindowOneLeafWidth.LessThan60cm]: MEASUREMENTS_TRAD_KEYS[WindowOneLeafWidth.LessThan60cm],
    [WindowOneLeafWidth.Between60And120cm]: MEASUREMENTS_TRAD_KEYS[WindowOneLeafWidth.Between60And120cm],
    [WindowOneLeafWidth.MoreThan120cm]: MEASUREMENTS_TRAD_KEYS[WindowOneLeafWidth.MoreThan120cm],
};
export const WINDOW_ONE_LEAF_WIDTH_CHOICES = objectToTranslatableChoices(WINDOW_ONE_LEAF_WIDTH_LABELS);
export const WINDOW_ONE_LEAF_WIDTH_MODEL_LABELS = WINDOW_ONE_LEAF_WIDTH_LABELS;
export const WINDOW_ONE_LEAF_WIDTH_MODEL_CHOICES = objectToTranslatableChoices(WINDOW_ONE_LEAF_WIDTH_MODEL_LABELS);
export const WINDOW_TWO_LEAVES_WIDTH_LABELS = {
    [WindowTwoLeavesWidth.LessThan95cm]: MEASUREMENTS_TRAD_KEYS[WindowTwoLeavesWidth.LessThan95cm],
    [WindowTwoLeavesWidth.Between95And145cm]: MEASUREMENTS_TRAD_KEYS[WindowTwoLeavesWidth.Between95And145cm],
    [WindowTwoLeavesWidth.MoreThan145cm]: MEASUREMENTS_TRAD_KEYS[WindowTwoLeavesWidth.MoreThan145cm],
};
export const WINDOW_TWO_LEAVES_WIDTH_CHOICES = objectToTranslatableChoices(WINDOW_TWO_LEAVES_WIDTH_LABELS);
export const WINDOW_TWO_LEAVES_WIDTH_MODEL_LABELS = WINDOW_TWO_LEAVES_WIDTH_LABELS;
export const WINDOW_TWO_LEAVES_WIDTH_MODEL_CHOICES = objectToTranslatableChoices(WINDOW_TWO_LEAVES_WIDTH_MODEL_LABELS);
export const WINDOW_ONE_LEAF_HEIGHT_LABELS = {
    [WindowOneLeafHeight.LessThan60cm]: MEASUREMENTS_TRAD_KEYS[WindowOneLeafHeight.LessThan60cm],
    [WindowOneLeafHeight.Between60And120cm]: MEASUREMENTS_TRAD_KEYS[WindowOneLeafHeight.Between60And120cm],
    [WindowOneLeafHeight.MoreThan120cm]: MEASUREMENTS_TRAD_KEYS[WindowOneLeafHeight.MoreThan120cm],
};
export const WINDOW_ONE_LEAF_HEIGHT_CHOICES = objectToTranslatableChoices(WINDOW_ONE_LEAF_HEIGHT_LABELS);
export const WINDOW_ONE_LEAF_HEIGHT_MODEL_LABELS = WINDOW_ONE_LEAF_HEIGHT_LABELS;
export const WINDOW_ONE_LEAF_HEIGHT_MODEL_CHOICES = objectToTranslatableChoices(WINDOW_ONE_LEAF_HEIGHT_MODEL_LABELS);
export const WINDOW_TWO_LEAVES_HEIGHT_LABELS = {
    [WindowTwoLeavesHeight.LessThan100cm]: MEASUREMENTS_TRAD_KEYS[WindowTwoLeavesHeight.LessThan100cm],
    [WindowTwoLeavesHeight.Between100And180cm]: MEASUREMENTS_TRAD_KEYS[WindowTwoLeavesHeight.Between100And180cm],
    [WindowTwoLeavesHeight.MoreThan180cm]: MEASUREMENTS_TRAD_KEYS[WindowTwoLeavesHeight.MoreThan180cm],
};
export const WINDOW_TWO_LEAVES_HEIGHT_CHOICES = objectToTranslatableChoices(WINDOW_TWO_LEAVES_HEIGHT_LABELS);
export const WINDOW_TWO_LEAVES_HEIGHT_MODEL_LABELS = WINDOW_TWO_LEAVES_HEIGHT_LABELS;
export const WINDOW_TWO_LEAVES_HEIGHT_MODEL_CHOICES = objectToTranslatableChoices(WINDOW_TWO_LEAVES_HEIGHT_MODEL_LABELS);
export const FRENCH_WINDOW_MATERIAL_LABELS = {
    [FrenchWindowMaterial.WOOD]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.material.wood',
    [FrenchWindowMaterial.PVC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.material.pvc',
    [FrenchWindowMaterial.METAL]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.material.metal',
};
export const FRENCH_WINDOW_MATERIAL_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_MATERIAL_LABELS);
export const FRENCH_WINDOW_MATERIAL_MODEL_LABELS = {
    [FrenchWindowMaterial.WOOD]: 'model.good_damage.french_window.material.wood',
    [FrenchWindowMaterial.PVC]: 'model.good_damage.french_window.material.pvc',
    [FrenchWindowMaterial.METAL]: 'model.good_damage.french_window.material.metal',
};
export const FRENCH_WINDOW_MATERIAL_MODEL_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_MATERIAL_MODEL_LABELS);
export const FRENCH_WINDOW_DAMAGE_TYPE_LABELS = {
    [FrenchWindowDamageType.AESTHETIC]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.damage_type.aesthetic',
    [FrenchWindowDamageType.DAMAGED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.damage_type.damaged',
};
export const FRENCH_WINDOW_DAMAGE_TYPE_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_DAMAGE_TYPE_LABELS);
export const FRENCH_WINDOW_DAMAGE_TYPE_MODEL_LABELS = {
    [FrenchWindowDamageType.AESTHETIC]: 'model.good_damage.french_window.damage_type.aesthetic',
    [FrenchWindowDamageType.DAMAGED]: 'model.good_damage.french_window.damage_type.damaged',
};
export const FRENCH_WINDOW_DAMAGE_TYPE_MODEL_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_DAMAGE_TYPE_MODEL_LABELS);
export const FRENCH_WINDOW_GLAZING_TYPE_LABELS = {
    [FrenchWindowGlazingType.SIMPLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glazing_type.simple',
    [FrenchWindowGlazingType.DOUBLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glazing_type.double',
    [FrenchWindowGlazingType.TRIPLE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glazing_type.triple',
    [FrenchWindowGlazingType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glazing_type.unknown',
};
export const FRENCH_WINDOW_GLAZING_TYPE_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_GLAZING_TYPE_LABELS);
export const FRENCH_WINDOW_GLAZING_TYPE_MODEL_LABELS = {
    [FrenchWindowGlazingType.SIMPLE]: 'model.good_damage.french_window.glazing_type.simple',
    [FrenchWindowGlazingType.DOUBLE]: 'model.good_damage.french_window.glazing_type.double',
    [FrenchWindowGlazingType.TRIPLE]: 'model.good_damage.french_window.glazing_type.triple',
    [FrenchWindowGlazingType.UNKNOWN]: 'model.good_damage.french_window.glazing_type.unknown',
};
export const FRENCH_WINDOW_GLAZING_TYPE_MODEL_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_GLAZING_TYPE_MODEL_LABELS);
export const FRENCH_WINDOW_GLASS_TYPE_LABELS = {
    [FrenchWindowGlassType.LAMINATED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glass_type.laminated',
    [FrenchWindowGlassType.NON_LAMINATED]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glass_type.non_laminated',
    [FrenchWindowGlassType.UNKNOWN]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.glass_type.unknown',
};
export const FRENCH_WINDOW_GLASS_TYPE_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_GLASS_TYPE_LABELS);
export const FRENCH_WINDOW_GLASS_TYPE_MODEL_LABELS = {
    [FrenchWindowGlassType.LAMINATED]: 'model.good_damage.french_window.glass_type.laminated',
    [FrenchWindowGlassType.NON_LAMINATED]: 'model.good_damage.french_window.glass_type.non_laminated',
    [FrenchWindowGlassType.UNKNOWN]: 'model.good_damage.french_window.glass_type.unknown',
};
export const FRENCH_WINDOW_GLASS_TYPE_MODEL_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_GLASS_TYPE_MODEL_LABELS);
export const FRENCH_WINDOW_ONE_LEAF_WIDTH_LABELS = {
    [FrenchWindowLeafWidth.STANDARD]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_width.standard.one_leaf',
    [FrenchWindowLeafWidth.LARGE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_width.large.one_leaf',
};
export const FRENCH_WINDOW_ONE_LEAF_WIDTH_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_ONE_LEAF_WIDTH_LABELS);
export const FRENCH_WINDOW_ONE_LEAF_WIDTH_MODEL_LABELS = {
    [FrenchWindowLeafWidth.STANDARD]: 'model.good_damage.french_window.leaf_width.standard.one_leaf',
    [FrenchWindowLeafWidth.LARGE]: 'model.good_damage.french_window.leaf_width.large.one_leaf',
};
export const FRENCH_WINDOW_ONE_LEAF_WIDTH_MODEL_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_ONE_LEAF_WIDTH_MODEL_LABELS);
export const FRENCH_WINDOW_TWO_LEAVES_WIDTH_LABELS = {
    [FrenchWindowLeafWidth.STANDARD]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_width.standard.two_leaves',
    [FrenchWindowLeafWidth.LARGE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_width.large.two_leaves',
};
export const FRENCH_WINDOW_TWO_LEAVES_WIDTH_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_TWO_LEAVES_WIDTH_LABELS);
export const FRENCH_WINDOW_TWO_LEAVES_WIDTH_MODEL_LABELS = {
    [FrenchWindowLeafWidth.STANDARD]: 'model.good_damage.french_window.leaf_width.standard.two_leaves',
    [FrenchWindowLeafWidth.LARGE]: 'model.good_damage.french_window.leaf_width.large.two_leaves',
};
export const FRENCH_WINDOW_TWO_LEAVES_WIDTH_MODEL_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_TWO_LEAVES_WIDTH_MODEL_LABELS);
export const FRENCH_WINDOW_LEAF_HEIGHT_LABELS = {
    [FrenchWindowLeafHeight.STANDARD]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_height.standard',
    [FrenchWindowLeafHeight.LARGE]: 'claim.damage.real_estate.room_damage.form.question.good_damage.opening.french_window.leaf_height.large',
};
export const FRENCH_WINDOW_LEAF_HEIGHT_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_LEAF_HEIGHT_LABELS);
export const FRENCH_WINDOW_LEAF_HEIGHT_MODEL_LABELS = {
    [FrenchWindowLeafHeight.STANDARD]: 'model.good_damage.french_window.leaf_height.standard',
    [FrenchWindowLeafHeight.LARGE]: 'model.good_damage.french_window.leaf_height.large',
};
export const FRENCH_WINDOW_LEAF_HEIGHT_MODEL_CHOICES = objectToTranslatableChoices(FRENCH_WINDOW_LEAF_HEIGHT_MODEL_LABELS);
