import { NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST } from '@shared/constants/thirdParty';
const thirdPartyConstraints = {
    'contact.companyName': {
        presenceIfValue: {
            attribute: 'contact.isCompany',
            triggeringValue: [true],
            allowEmpty: false,
            message: 'claim.third_party.form.validator.contact.companyName',
        },
    },
    'contact.lastName': {
        presenceIfValue: {
            attribute: 'contact.isCompany',
            triggeringValue: [false, undefined],
            allowEmpty: false,
            message: 'claim.third_party.form.validator.contact.lastName',
        },
    },
    'contact.firstName': {
        presenceIfValue: {
            attribute: 'contact.isCompany',
            triggeringValue: [false, undefined],
            allowEmpty: false,
            message: 'claim.third_party.form.validator.contact.firstName',
        },
    },
    'insuranceCompany.name': {
        presence: {
            allowEmpty: false,
            message: 'claim.third_party.form.validator.insuranceCompany.name',
        },
    },
    'insuranceCompany.handWrittenName': {
        presenceIfValue: {
            attribute: 'insuranceCompany.name',
            triggeringValue: [NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST.notInList],
            allowEmpty: false,
            message: 'claim.third_party.form.validator.insuranceCompany.handWrittenName',
        },
    },
};
export default thirdPartyConstraints;
