import { createDamageStore } from '@declaration/store/createDamageStore';
import { Step } from '@shared/claim/steps';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { BreakInDamageRepairQuotePendingStatus, } from '@shared/types/api/claim';
import { breakInDamageConstraints } from '@shared/validator/constraints/breakInDamageConstraints';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
export const breakInDamagesToRepair = (breakInDamages, translate) => {
    return breakInDamages
        .filter((breakInDamage) => breakInDamage.needsRepair)
        .map((breakInDamage) => {
        return {
            key: breakInDamage.id,
            label: breakInDamageTitle(breakInDamage, translate),
        };
    });
};
export const name = 'breakInDamage';
export const namespaced = true;
function newBreakInDamage() {
    return {
        id: null,
        clientIdentifier: newClientIdentifier(),
        pictureCollection: emptyUploadedFileCollection(),
        type: null,
        mustBeReplaced: null,
        needsRepair: null,
        isRepaired: null,
        repairationType: null,
        repairableStatus: null,
        repairQuoteInfo: null,
        repairFileCollection: emptyUploadedFileCollection(),
        pendingRepairQuoteInfo: null,
        pendingRepairFileCollection: emptyUploadedFileCollection(),
        refund: null,
        compensationMode: null,
        initialCompensationMode: null,
        pendingCompensationMode: null,
        ignoreRepairFile: null,
        ignorePendingRepairFile: null,
        repairQuotePendingStatus: BreakInDamageRepairQuotePendingStatus.None,
        hasBeenRepaired: null,
        repairedBy: null,
        doorType: null,
        doorLeavesType: null,
        material: null,
        damageSeverity: null,
        doorHasLockDamage: null,
        doorLockDamage: null,
        doorLockType: null,
        doorHasWindow: null,
        numberOfImpacts: null,
        hasOtherDamages: null,
        otherDamages: null,
        thresholdExceeded: null,
        doorHasBrokenWindowTiles: null,
        doorNumberOfBrokenWindowTiles: 1,
        doorBiggestBrokenWindowTileHeight: null,
        doorBiggestBrokenWindowTileWidth: null,
        doorWindowGlassType: null,
        doorWindowGlazingType: null,
        frenchWindowType: null,
        frenchWindowHasBrokenTiles: null,
        frenchWindowNumberOfBrokenTiles: 1,
        frenchWindowBiggestBrokenTileHeight: null,
        frenchWindowBiggestBrokenTileWidth: null,
        frenchWindowLockIsDamaged: null,
        frenchWindowGlazingType: null,
        frenchWindowLaminatedGlazingType: null,
        frenchWindowHeight: null,
        frenchWindowWidth: null,
        windowType: null,
        windowHasBrokenTiles: null,
        windowNumberOfBrokenTiles: 1,
        windowBiggestBrokenTileHeight: null,
        windowBiggestBrokenTileWidth: null,
        windowGlazingType: null,
        windowLaminatedGlazingType: null,
        windowHeight: null,
        windowWidth: null,
        fenceType: null,
        isFenceHalfHeight: null,
        fenceLength: null,
        slidingBayLeavesType: null,
        slidingBayHasBrokenTiles: null,
        slidingBayNumberOfBrokenTiles: 1,
        slidingBayBiggestBrokenTileHeight: null,
        slidingBayBiggestBrokenTileWidth: null,
        slidingBayGlazingType: null,
        slidingBayGlassType: null,
        slidingBayLockIsDamaged: null,
        slidingBayWidth: null,
        shutterType: null,
        flappyShutterLeaves: null,
        rollingShutterIsMotorized: null,
        shutterIndividualBarsNumber: 1,
        shutterIsOpening: null,
        shutterBarTypes: null,
        shutterWindowWidth: null,
        garageDoorType: null,
        garageDoorIsFunctional: null,
        garageDoorIsBent: null,
        garageDoorWidth: null,
        garageDoorIsMotorized: null,
        otherDamageType: null,
        otherDamageDescription: null,
        otherDamageName: null,
        littleGateLockIsDamaged: null,
        littleGateHeight: null,
    };
}
const { createDamageState, damageGetters, damageActions, damageMutations } = createDamageStore(newBreakInDamage, breakInDamageConstraints, Step.BreakInDamagesDetails, 'breakInDamages');
export const state = createDamageState;
export const getters = {
    ...damageGetters,
    getBreakInDamagePositionSuffix: (state) => (damageSlug, index) => {
        if (!damageSlug) {
            return null;
        }
        let positionWithSameCategory = 1;
        let isTheOnlyOneWithCategory = true;
        state.damages.forEach((damage, otherIndex) => {
            if (damage.type === damageSlug && otherIndex !== index) {
                isTheOnlyOneWithCategory = false;
                if (otherIndex < index) {
                    positionWithSameCategory += 1;
                }
            }
        });
        return isTheOnlyOneWithCategory ? null : positionWithSameCategory.toString();
    },
};
export const actions = {
    ...damageActions,
    async validateBreakInDamagesStep({ dispatch, rootState, commit }) {
        // ensure Step.BreakInDamagesDetails is in StepStack
        if (!rootState.claim.claim.stepStack.includes(Step.BreakInDamagesDetails)) {
            try {
                await dispatch('claim/saveStepWithoutStoreUpdate', Step.BreakInDamagesDetails, {
                    root: true,
                });
            }
            catch (_a) {
                dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
                return;
            }
        }
        await dispatch('claim/updateAndSaveClaimStep', Step.BreakInDamages, { root: true });
        commit('RESET_CHANGES');
    },
};
export const mutations = {
    ...damageMutations,
};
