export var DocumentAnalyseStatus;
(function (DocumentAnalyseStatus) {
    DocumentAnalyseStatus["Uploaded"] = "uploaded";
    DocumentAnalyseStatus["ToRetryUpload"] = "to_retry_upload";
    DocumentAnalyseStatus["FailedUpload"] = "failed_upload";
    DocumentAnalyseStatus["Ingested"] = "ingested";
    DocumentAnalyseStatus["ToVerify"] = "to_verify";
    DocumentAnalyseStatus["ToRetryCheck"] = "to_retry_check";
    DocumentAnalyseStatus["Verified"] = "verified";
    DocumentAnalyseStatus["Rejected"] = "rejected";
    DocumentAnalyseStatus["Error"] = "error";
})(DocumentAnalyseStatus || (DocumentAnalyseStatus = {}));
export var DocumentCheckType;
(function (DocumentCheckType) {
    DocumentCheckType["Check"] = "check_type_check";
    DocumentCheckType["Extract"] = "check_type_extract";
})(DocumentCheckType || (DocumentCheckType = {}));
