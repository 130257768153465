import { ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { ClaimFileType } from '@shared/types/file';
import { formatOptionalDate } from '@shared/utils/date';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { optionalString } from '@shared/utils/transform';
import { receiptConstraints } from '@shared/validator/constraints/receiptConstraints';
export const receiptFields = (model, { translate, projection, edition }) => {
    var _a;
    const title = projection === ModelFieldsProjection.Declaration || edition ? (_a = model.title) !== null && _a !== void 0 ? _a : 'Document' : '';
    const fields = [
        {
            label: translate('model.receipt.title'),
            value: model.title,
            displayedValue: optionalString(model.title),
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'title',
            },
        },
        {
            label: translate('model.receipt.date'),
            value: model.receiptDate,
            displayedValue: formatOptionalDate(model.receiptDate),
            editable: {
                input: {
                    type: ModelFieldInputType.Date,
                },
                modelKey: 'receiptDate',
            },
        },
        {
            label: translate('model.receipt.amount'),
            value: model.amount,
            displayedValue: formatOptionalCentsAmount(model.amount),
            editable: {
                input: {
                    type: ModelFieldInputType.Amount,
                },
                modelKey: 'amount',
            },
        },
    ];
    fields.push({
        label: translate('model.receipt.document'),
        value: model.receiptFile,
        displayedValue: {
            type: 'fileCollection',
            fileCollection: model.receiptFile,
            emptyLabel: translate('model.equipment.receipt_file.empty'),
            fileType: ClaimFileType.Receipt,
            uploadLabel: translate('model.file.upload'),
        },
        editable: {
            input: {
                type: ModelFieldInputType.FileCollection,
            },
            modelKey: 'receiptFile',
        },
    });
    return { title, fields, model, editable: { constraints: receiptConstraints } };
};
