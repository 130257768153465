/**
 * A sections represents a grouping of Questions for the Questions page
 */
export var DeclarationSection;
(function (DeclarationSection) {
    DeclarationSection["QualificationSection"] = "QualificationSection";
    DeclarationSection["AssistanceSection"] = "AssistanceSection";
    DeclarationSection["RoomDamagesSection"] = "RoomDamagesSection";
    DeclarationSection["PersonalPropertyDamagesSection"] = "PersonalPropertyDamagesSection";
    DeclarationSection["PersonalPropertyDamagesVOLSection"] = "PersonalPropertyDamagesVOLSection";
    DeclarationSection["RobberyQualificationContinuationVOLSection"] = "RobberyQualificationContinuationVOLSection";
    DeclarationSection["ExternalDamagesSection"] = "ExternalDamagesSection";
    DeclarationSection["ThirdPartySection"] = "ThirdPartySection";
    DeclarationSection["InternalDamagesSection"] = "InternalDamagesSection";
    DeclarationSection["BreakInDamagesSection"] = "BreakInDamagesSection";
    DeclarationSection["RobberyComplaintSection"] = "RobberyComplaintSection";
    DeclarationSection["GlassDamagesSection"] = "GlassDamagesSection";
    DeclarationSection["ElectricalDamagesSection"] = "ElectricalDamagesSection";
    DeclarationSection["DamagesSynthesisSection"] = "DamagesSynthesisSection";
    DeclarationSection["DocumentsSynthesisSection"] = "DocumentsSynthesisSection";
})(DeclarationSection || (DeclarationSection = {}));
