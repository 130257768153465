export function getSetting(name, appSettings) {
    const result = appSettings.settings.find((setting) => {
        return setting.name === name;
    });
    if (!result) {
        throw new Error('No setting with name "' + name + '" could be found in AppSettings object');
    }
    return result;
}
export function getSettingValue(name, appSettings) {
    return getSetting(name, appSettings).value;
}
