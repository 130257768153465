import { objectToTranslatableChoices, } from '@shared/choice';
import { ExternalDamageOrigin, LightSourceType } from '@shared/types/api/externalDamage';
export const DEFAULT_LIGHT_SOURCE_TYPE_TRAD_KEYS = {
    [LightSourceType.BollardLight]: 'claim.declaration.external_damages.form.light_source.bollard_light',
    [LightSourceType.WallLamp]: 'claim.declaration.external_damages.form.light_source.wall_lamp',
    [LightSourceType.OutdoorSpotlight]: 'claim.declaration.external_damages.form.light_source.outdoor_spotlight',
    [LightSourceType.StreetLamp]: 'claim.declaration.external_damages.form.light_source.street_lamp',
};
const STORM_LIGHT_SOURCE_TYPE_TRAD_KEYS = {};
const SNOW_LIGHT_SOURCE_TYPE_TRAD_KEYS = {};
const HAIL_LIGHT_SOURCE_TYPE_TRAD_KEYS = {};
const FIRE_LIGHT_SOURCE_TYPE_TRAD_KEYS = {};
export const LIGHT_SOURCE_TYPE_TRAD_KEYS = {
    [ExternalDamageOrigin.Storm]: {
        ...DEFAULT_LIGHT_SOURCE_TYPE_TRAD_KEYS,
        ...STORM_LIGHT_SOURCE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Snow]: {
        ...DEFAULT_LIGHT_SOURCE_TYPE_TRAD_KEYS,
        ...SNOW_LIGHT_SOURCE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Hail]: {
        ...DEFAULT_LIGHT_SOURCE_TYPE_TRAD_KEYS,
        ...HAIL_LIGHT_SOURCE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Fire]: {
        ...DEFAULT_LIGHT_SOURCE_TYPE_TRAD_KEYS,
        ...FIRE_LIGHT_SOURCE_TYPE_TRAD_KEYS,
    },
};
export const LIGHT_SOURCE_TYPE_CHOICES = {
    [ExternalDamageOrigin.Storm]: objectToTranslatableChoices(LIGHT_SOURCE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Storm]),
    [ExternalDamageOrigin.Snow]: objectToTranslatableChoices(LIGHT_SOURCE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Snow]),
    [ExternalDamageOrigin.Hail]: objectToTranslatableChoices(LIGHT_SOURCE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Hail]),
    [ExternalDamageOrigin.Fire]: objectToTranslatableChoices(LIGHT_SOURCE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Fire]),
};
