import get from 'lodash/get';
import validateJs from 'validate.js';
export function presenceIfEmpty(value, { attribute, message }, _key, attributes) {
    if (!validateJs.isEmpty(value)) {
        return;
    }
    const testAttributes = Array.isArray(attribute) ? attribute : [attribute];
    if (attributes) {
        for (const attribute of testAttributes) {
            if (!validateJs.isEmpty(get(attributes, attribute))) {
                return;
            }
        }
    }
    return message || 'this value is required';
}
export function presenceIfPresent(value, { attribute, message }, _key, attributes) {
    if (!validateJs.isEmpty(value)) {
        return;
    }
    const testAttributes = Array.isArray(attribute) ? attribute : [attribute];
    if (attributes) {
        for (const attribute of testAttributes) {
            if (!validateJs.isEmpty(get(attributes, attribute))) {
                return message || 'this value is required';
            }
        }
    }
}
