import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { BreakInDamageRepairPerson, BreakInDamageRepairStatus, ClaimTypeTrigram, RobberyBreakableElement, } from '@shared/types/api/claim';
import { BreakInDamageShutterType, DamageSeverity, DoorLockType, DoorType, GlassType, GlazingType, ImpactsNumber, LeavesType, Material, Measurement, OtherDamageType, ShutterBarTypes, } from '@shared/types/api/breakInDamage';
import { FenceType, GarageDoorType, GateFillStyle, GateType, } from '@shared/types/api/externalDamage';
function baseConstraints(breakInDamage, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
context) {
    const mustHasAnInvoice = breakInDamage.hasBeenRepaired !== null &&
        breakInDamage.hasBeenRepaired !== BreakInDamageRepairStatus.NotRepaired &&
        breakInDamage.repairedBy !== null &&
        breakInDamage.repairedBy === BreakInDamageRepairPerson.Artisan;
    const picturesNotRequired = breakInDamage.type &&
        breakInDamage.damageSeverity === DamageSeverity.Slight &&
        [
            RobberyBreakableElement.FrenchWindow,
            RobberyBreakableElement.Gate,
            RobberyBreakableElement.LittleGate,
            RobberyBreakableElement.Shutter,
        ].includes(breakInDamage.type);
    return {
        type: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.break_in_damage.form.validator.presence',
            },
        },
        ...(!picturesNotRequired
            ? {
                pictureCollection: {
                    requiredFileCollection: {
                        message: 'validator.requiredFileCollection',
                        trigram: ClaimTypeTrigram.Robbery,
                    },
                },
            }
            : null),
        ...(mustHasAnInvoice
            ? {
                repairFileCollection: {
                    requiredFileCollection: {
                        message: 'validator.requiredFileCollection',
                        trigram: ClaimTypeTrigram.Robbery,
                    },
                },
            }
            : null),
        ...(fileCollectionFilesCount(breakInDamage === null || breakInDamage === void 0 ? void 0 : breakInDamage.repairFileCollection) > 0 &&
            !breakInDamage.ignoreRepairFile
            ? {
                'repairQuoteInfo.amount': {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.presence',
                    },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThan: 500000000,
                        message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.numericality',
                    },
                },
            }
            : null),
        ...(fileCollectionFilesCount(breakInDamage === null || breakInDamage === void 0 ? void 0 : breakInDamage.pendingRepairFileCollection) > 0 &&
            !breakInDamage.ignorePendingRepairFile
            ? {
                'pendingRepairQuoteInfo.amount': {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.presence',
                    },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThan: 500000000,
                        message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.numericality',
                    },
                },
            }
            : null),
    };
}
function defaultConstraints(breakInDamage, context) {
    var _a;
    return {
        type: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.break_in_damage.form.validator.presence',
            },
        },
        ...(((_a = context.settings) === null || _a === void 0 ? void 0 : _a.filter((setting) => setting.name === 'volBreakInDamagePicturesRequired')[0].value)
            ? {
                pictureCollection: {
                    requiredFileCollection: {
                        message: 'validator.requiredFileCollection',
                        trigram: ClaimTypeTrigram.Robbery,
                    },
                },
            }
            : null),
        ...(fileCollectionFilesCount(breakInDamage === null || breakInDamage === void 0 ? void 0 : breakInDamage.repairFileCollection) > 0 &&
            !breakInDamage.ignoreRepairFile
            ? {
                'repairQuoteInfo.companyName': {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.personal_property.form.question.repair_quote_company_name.validator.presence',
                    },
                },
                'repairQuoteInfo.amount': {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.presence',
                    },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThan: 500000000,
                        message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.numericality',
                    },
                },
            }
            : null),
        ...(fileCollectionFilesCount(breakInDamage === null || breakInDamage === void 0 ? void 0 : breakInDamage.pendingRepairFileCollection) > 0 &&
            !breakInDamage.ignorePendingRepairFile
            ? {
                'pendingRepairQuoteInfo.companyName': {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.personal_property.form.question.repair_quote_company_name.validator.presence',
                    },
                },
                'pendingRepairQuoteInfo.amount': {
                    presence: {
                        allowEmpty: false,
                        message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.presence',
                    },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThan: 500000000,
                        message: 'claim.damage.personal_property.form.question.repair_quote_amount.validator.numericality',
                    },
                },
            }
            : null),
    };
}
export function breakInDamageConstraints(breakInDamage, context) {
    if (breakInDamage.type === RobberyBreakableElement.Door) {
        return {
            ...baseConstraints(breakInDamage, context),
            doorType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [DoorType.Indoor, DoorType.Service, DoorType.MainEntrance],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.doorType !== DoorType.Indoor
                ? {
                    doorLeavesType: {
                        presenceIfValue: {
                            attribute: 'doorType',
                            triggeringValue: [DoorType.MainEntrance, DoorType.Service],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                        inclusion: {
                            within: [LeavesType.OneLeaf, LeavesType.TwoLeaves],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    damageSeverity: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                        inclusion: {
                            within: [DamageSeverity.Slight, DamageSeverity.Notable, DamageSeverity.Critical],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    material: {
                        presenceIfValue: {
                            attribute: 'doorType',
                            triggeringValue: [DoorType.MainEntrance, DoorType.Service],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                        inclusion: {
                            within: [Material.Wood, Material.Metal, Material.PVC],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                        ? {
                            ...(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                                (breakInDamage.damageSeverity === DamageSeverity.Notable &&
                                    ((breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree &&
                                        breakInDamage.material === Material.Metal) ||
                                        (breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven &&
                                            breakInDamage.material === Material.PVC)))
                                ? {
                                    doorHasWindow: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    doorLockType: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                        inclusion: {
                                            within: [
                                                DoorLockType.OneLock,
                                                DoorLockType.TwoLocks,
                                                DoorLockType.ThreeLocks,
                                                DoorLockType.FixatedMultipointLock,
                                                DoorLockType.FairedMultipointLock,
                                                DoorLockType.IntegratedMultipointLock,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                }
                                : null),
                            ...(breakInDamage.damageSeverity !== DamageSeverity.Critical
                                ? {
                                    numberOfImpacts: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                        ...(breakInDamage.material === Material.PVC
                                            ? {
                                                inclusion: {
                                                    within: [
                                                        ImpactsNumber.NoImpact,
                                                        ImpactsNumber.OneToThree,
                                                        ImpactsNumber.FourToSix,
                                                        ImpactsNumber.MoreThanSeven,
                                                    ],
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            }
                                            : null),
                                        ...(breakInDamage.material === Material.Metal
                                            ? {
                                                inclusion: {
                                                    within: [
                                                        ImpactsNumber.NoImpact,
                                                        ImpactsNumber.OneToThree,
                                                        ImpactsNumber.MoreThanThree,
                                                    ],
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            }
                                            : null),
                                        ...(breakInDamage.material === Material.Wood
                                            ? {
                                                inclusion: {
                                                    within: [ImpactsNumber.NoImpact, ImpactsNumber.AtLeastOne],
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            }
                                            : null),
                                    },
                                    ...(!((breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree &&
                                        breakInDamage.material === Material.Metal) ||
                                        (breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven &&
                                            breakInDamage.material === Material.PVC))
                                        ? {
                                            doorHasWindow: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            },
                                            doorHasLockDamage: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            },
                                            ...(breakInDamage.doorHasLockDamage
                                                ? {
                                                    doorLockType: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                        inclusion: {
                                                            within: [
                                                                DoorLockType.OneLock,
                                                                DoorLockType.TwoLocks,
                                                                DoorLockType.ThreeLocks,
                                                                DoorLockType.FixatedMultipointLock,
                                                                DoorLockType.FairedMultipointLock,
                                                                DoorLockType.IntegratedMultipointLock,
                                                            ],
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                    },
                                                }
                                                : null),
                                            ...(breakInDamage.doorHasWindow
                                                ? {
                                                    doorHasBrokenWindowTiles: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                    },
                                                }
                                                : null),
                                            hasOtherDamages: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            },
                                            ...(breakInDamage.doorHasBrokenWindowTiles
                                                ? {
                                                    doorBiggestBrokenWindowTileHeight: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                        numericality: {
                                                            onlyInteger: true,
                                                            lessThanOrEqualTo: 200,
                                                        },
                                                    },
                                                    doorBiggestBrokenWindowTileWidth: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                        numericality: {
                                                            onlyInteger: true,
                                                            lessThanOrEqualTo: 200,
                                                        },
                                                    },
                                                    doorNumberOfBrokenWindowTiles: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                        numericality: {
                                                            onlyInteger: true,
                                                            lessThanOrEqualTo: 20,
                                                        },
                                                    },
                                                    doorWindowGlassType: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                        inclusion: {
                                                            within: [
                                                                GlassType.Laminated,
                                                                GlassType.NonLaminated,
                                                                GlassType.Unknown,
                                                            ],
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                    },
                                                    doorWindowGlazingType: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                        inclusion: {
                                                            within: [
                                                                GlazingType.Simple,
                                                                GlazingType.Double,
                                                                GlazingType.Triple,
                                                                GlazingType.Unknown,
                                                            ],
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                    },
                                                }
                                                : null),
                                            ...(breakInDamage.hasOtherDamages
                                                ? {
                                                    otherDamages: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                    },
                                                }
                                                : null),
                                            ...(breakInDamage.doorHasLockDamage
                                                ? {
                                                    doorLockDamage: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                    },
                                                }
                                                : null),
                                        }
                                        : null),
                                }
                                : null),
                            hasBeenRepaired: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            repairedBy: {
                                presenceIfValue: {
                                    attribute: 'hasBeenRepaired',
                                    triggeringValue: [
                                        BreakInDamageRepairStatus.Repaired,
                                        BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                }
                : null),
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.Window) {
        return {
            ...baseConstraints(breakInDamage, context),
            windowType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [LeavesType.OneLeaf, LeavesType.TwoLeaves, LeavesType.ThreeLeaves],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            material: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [Material.Wood, Material.Metal, Material.PVC],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                ? {
                    damageSeverity: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                        inclusion: {
                            within: [DamageSeverity.Slight, DamageSeverity.Notable, DamageSeverity.Critical],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    ...(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.damageSeverity === DamageSeverity.Notable &&
                            breakInDamage.material === Material.PVC &&
                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven)
                        ? {
                            windowHeight: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                ...(breakInDamage.windowType === LeavesType.OneLeaf
                                    ? {
                                        inclusion: {
                                            within: [
                                                Measurement.LessThan60cm,
                                                Measurement.Between60And120cm,
                                                Measurement.MoreThan120cm,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.windowType === LeavesType.TwoLeaves
                                    ? {
                                        inclusion: {
                                            within: [
                                                Measurement.LessThan100cm,
                                                Measurement.Between100And180cm,
                                                Measurement.MoreThan180cm,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                            },
                            windowWidth: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                ...(breakInDamage.windowType === LeavesType.OneLeaf
                                    ? {
                                        inclusion: {
                                            within: [
                                                Measurement.LessThan60cm,
                                                Measurement.Between60And120cm,
                                                Measurement.MoreThan120cm,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.windowType === LeavesType.TwoLeaves
                                    ? {
                                        inclusion: {
                                            within: [
                                                Measurement.LessThan95cm,
                                                Measurement.Between95And145cm,
                                                Measurement.MoreThan145cm,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                            },
                            windowGlazingType: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        GlazingType.Simple,
                                        GlazingType.Double,
                                        GlazingType.Triple,
                                        GlazingType.Unknown,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            windowLaminatedGlazingType: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [GlassType.Laminated, GlassType.NonLaminated, GlassType.Unknown],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                    ...(breakInDamage.damageSeverity !== DamageSeverity.Critical
                        ? {
                            numberOfImpacts: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                ...(breakInDamage.material === Material.PVC
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.FourToSix,
                                                ImpactsNumber.MoreThanSeven,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Metal
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.MoreThanThree,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Wood
                                    ? {
                                        inclusion: {
                                            within: [ImpactsNumber.NoImpact, ImpactsNumber.AtLeastOne],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                            },
                            ...(!(breakInDamage.damageSeverity === DamageSeverity.Notable &&
                                breakInDamage.material === Material.PVC &&
                                breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven)
                                ? {
                                    windowHasBrokenTiles: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    hasOtherDamages: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    ...(breakInDamage.windowHasBrokenTiles
                                        ? {
                                            windowBiggestBrokenTileHeight: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 200,
                                                },
                                            },
                                            windowBiggestBrokenTileWidth: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 200,
                                                },
                                            },
                                            windowNumberOfBrokenTiles: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 20,
                                                },
                                            },
                                        }
                                        : null),
                                    ...(breakInDamage.hasOtherDamages
                                        ? {
                                            otherDamages: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            },
                                        }
                                        : null),
                                }
                                : null),
                        }
                        : null),
                    hasBeenRepaired: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    repairedBy: {
                        presenceIfValue: {
                            attribute: 'hasBeenRepaired',
                            triggeringValue: [
                                BreakInDamageRepairStatus.Repaired,
                                BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                            ],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                }
                : null),
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.FrenchWindow) {
        return {
            ...baseConstraints(breakInDamage, context),
            frenchWindowType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [LeavesType.OneLeaf, LeavesType.TwoLeaves, LeavesType.ThreeLeaves],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            material: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [Material.Wood, Material.Metal, Material.PVC],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            damageSeverity: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [DamageSeverity.Slight, DamageSeverity.Notable, DamageSeverity.Critical],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                ? {
                    ...(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.damageSeverity === DamageSeverity.Notable &&
                            ((breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree &&
                                breakInDamage.material === Material.Metal) ||
                                (breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven &&
                                    breakInDamage.material === Material.PVC)))
                        ? {
                            frenchWindowHeight: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [Measurement.LessThan220cm, Measurement.MoreThan220cm],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            frenchWindowWidth: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                ...(breakInDamage.frenchWindowType === LeavesType.OneLeaf
                                    ? {
                                        inclusion: {
                                            within: [Measurement.LessThan95cm, Measurement.MoreThan95cm],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.frenchWindowType === LeavesType.TwoLeaves
                                    ? {
                                        inclusion: {
                                            within: [Measurement.LessThan145cm, Measurement.MoreThan145cm],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                            },
                            frenchWindowGlazingType: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        GlazingType.Simple,
                                        GlazingType.Double,
                                        GlazingType.Triple,
                                        GlazingType.Unknown,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            frenchWindowLaminatedGlazingType: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [GlassType.Laminated, GlassType.NonLaminated, GlassType.Unknown],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                    ...(breakInDamage.damageSeverity !== DamageSeverity.Critical
                        ? {
                            numberOfImpacts: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                ...(breakInDamage.material === Material.PVC
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.FourToSix,
                                                ImpactsNumber.MoreThanSeven,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Metal
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.MoreThanThree,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Wood
                                    ? {
                                        inclusion: {
                                            within: [ImpactsNumber.NoImpact, ImpactsNumber.AtLeastOne],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                            },
                            ...(!((breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree &&
                                breakInDamage.material === Material.Metal) ||
                                (breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven &&
                                    breakInDamage.material === Material.PVC))
                                ? {
                                    frenchWindowHasBrokenTiles: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    frenchWindowLockIsDamaged: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    hasOtherDamages: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    ...(breakInDamage.frenchWindowHasBrokenTiles
                                        ? {
                                            frenchWindowBiggestBrokenTileHeight: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 200,
                                                },
                                            },
                                            frenchWindowBiggestBrokenTileWidth: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 200,
                                                },
                                            },
                                            frenchWindowNumberOfBrokenTiles: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 20,
                                                },
                                            },
                                        }
                                        : null),
                                    ...(breakInDamage.hasOtherDamages
                                        ? {
                                            otherDamages: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            },
                                        }
                                        : null),
                                }
                                : null),
                        }
                        : null),
                    hasBeenRepaired: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    repairedBy: {
                        presenceIfValue: {
                            attribute: 'hasBeenRepaired',
                            triggeringValue: [
                                BreakInDamageRepairStatus.Repaired,
                                BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                            ],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                }
                : null),
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.SlidingBayWindow) {
        return {
            ...baseConstraints(breakInDamage, context),
            slidingBayLeavesType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [LeavesType.TwoLeaves, LeavesType.ThreeLeaves],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            damageSeverity: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [DamageSeverity.Slight, DamageSeverity.Notable, DamageSeverity.Critical],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                ? {
                    ...(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.damageSeverity === DamageSeverity.Notable &&
                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree)
                        ? {
                            slidingBayWidth: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        Measurement.LessThan220cm,
                                        Measurement.Between220And260cm,
                                        Measurement.MoreThan260cm,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            slidingBayGlazingType: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        GlazingType.Simple,
                                        GlazingType.Double,
                                        GlazingType.Triple,
                                        GlazingType.Unknown,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            slidingBayGlassType: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [GlassType.Laminated, GlassType.NonLaminated, GlassType.Unknown],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                    ...(breakInDamage.damageSeverity !== DamageSeverity.Critical
                        ? {
                            numberOfImpacts: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        ImpactsNumber.NoImpact,
                                        ImpactsNumber.OneToThree,
                                        ImpactsNumber.MoreThanThree,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            ...(breakInDamage.numberOfImpacts !== ImpactsNumber.MoreThanThree
                                ? {
                                    slidingBayHasBrokenTiles: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    slidingBayLockIsDamaged: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    hasOtherDamages: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    ...(breakInDamage.slidingBayHasBrokenTiles
                                        ? {
                                            slidingBayBiggestBrokenTileHeight: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 200,
                                                },
                                            },
                                            slidingBayBiggestBrokenTileWidth: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 200,
                                                },
                                            },
                                            slidingBayNumberOfBrokenTiles: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                                numericality: {
                                                    onlyInteger: true,
                                                    lessThanOrEqualTo: 20,
                                                },
                                            },
                                        }
                                        : null),
                                    ...(breakInDamage.hasOtherDamages
                                        ? {
                                            otherDamages: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            },
                                        }
                                        : null),
                                }
                                : null),
                        }
                        : null),
                    hasBeenRepaired: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    repairedBy: {
                        presenceIfValue: {
                            attribute: 'hasBeenRepaired',
                            triggeringValue: [
                                BreakInDamageRepairStatus.Repaired,
                                BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                            ],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                }
                : null),
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.Shutter) {
        return {
            ...baseConstraints(breakInDamage, context),
            shutterType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [
                        BreakInDamageShutterType.Flappy,
                        BreakInDamageShutterType.Rolling,
                        BreakInDamageShutterType.Folding,
                        BreakInDamageShutterType.Bar,
                    ],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.shutterType === BreakInDamageShutterType.Bar
                ? {
                    shutterBarTypes: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                        inclusion: {
                            within: [ShutterBarTypes.Grid, ShutterBarTypes.IndividualBars],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    ...(breakInDamage.shutterBarTypes === ShutterBarTypes.IndividualBars
                        ? {
                            shutterIndividualBarsNumber: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                numericality: {
                                    onlyInteger: true,
                                    lessThanOrEqualTo: 50,
                                },
                            },
                        }
                        : null),
                }
                : null),
            ...(breakInDamage.shutterType !== BreakInDamageShutterType.Bar
                ? {
                    material: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                        inclusion: {
                            within: [Material.Wood, Material.Metal, Material.PVC],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    ...(breakInDamage.shutterType === BreakInDamageShutterType.Rolling
                        ? {
                            rollingShutterIsMotorized: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                    ...(breakInDamage.shutterType === BreakInDamageShutterType.Flappy
                        ? {
                            flappyShutterLeaves: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [LeavesType.OneLeaf, LeavesType.TwoLeaves],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                    damageSeverity: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                        inclusion: {
                            within: [DamageSeverity.Slight, DamageSeverity.Notable, DamageSeverity.Critical],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                        ? {
                            ...(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                                (breakInDamage.damageSeverity === DamageSeverity.Notable &&
                                    ((breakInDamage.material === Material.Metal &&
                                        breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree) ||
                                        (breakInDamage.material === Material.PVC &&
                                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven)))
                                ? {
                                    shutterWindowWidth: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                        inclusion: {
                                            within: [Measurement.LessThan120cm, Measurement.MoreThan120cm],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                }
                                : null),
                            ...(breakInDamage.damageSeverity !== DamageSeverity.Critical
                                ? {
                                    numberOfImpacts: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                        ...(breakInDamage.material === Material.PVC
                                            ? {
                                                inclusion: {
                                                    within: [
                                                        ImpactsNumber.NoImpact,
                                                        ImpactsNumber.OneToThree,
                                                        ImpactsNumber.FourToSix,
                                                        ImpactsNumber.MoreThanSeven,
                                                    ],
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            }
                                            : null),
                                        ...(breakInDamage.material === Material.Aluminium ||
                                            breakInDamage.material === Material.Steel
                                            ? {
                                                inclusion: {
                                                    within: [
                                                        ImpactsNumber.NoImpact,
                                                        ImpactsNumber.OneToThree,
                                                        ImpactsNumber.MoreThanThree,
                                                    ],
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            }
                                            : null),
                                        ...(breakInDamage.material === Material.Wood
                                            ? {
                                                inclusion: {
                                                    within: [ImpactsNumber.NoImpact, ImpactsNumber.AtLeastOne],
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            }
                                            : null),
                                    },
                                    ...(!((breakInDamage.material === Material.Metal &&
                                        breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree) ||
                                        (breakInDamage.material === Material.PVC &&
                                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven))
                                        ? {
                                            hasOtherDamages: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            },
                                            ...(breakInDamage.hasOtherDamages
                                                ? {
                                                    otherDamages: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                    },
                                                }
                                                : null),
                                        }
                                        : null),
                                }
                                : null),
                        }
                        : null),
                }
                : null),
            ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                ? {
                    hasBeenRepaired: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    repairedBy: {
                        presenceIfValue: {
                            attribute: 'hasBeenRepaired',
                            triggeringValue: [
                                BreakInDamageRepairStatus.Repaired,
                                BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                            ],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                }
                : null),
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.GarageDoor) {
        return {
            ...baseConstraints(breakInDamage, context),
            garageDoorType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [
                        GarageDoorType.Tipper,
                        GarageDoorType.Sectional,
                        GarageDoorType.Sliding,
                        GarageDoorType.Rolling,
                        GarageDoorType.Flappy,
                        GarageDoorType.Folding,
                    ],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            material: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [Material.Wood, Material.Metal, Material.PVC],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            damageSeverity: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [DamageSeverity.Slight, DamageSeverity.Notable, DamageSeverity.Critical],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                ? {
                    ...(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.material === Material.PVC &&
                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
                        !breakInDamage.garageDoorIsFunctional ||
                        breakInDamage.garageDoorIsBent
                        ? {
                            garageDoorWidth: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [Measurement.MoreThan300cm, Measurement.LessThan300cm],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            garageDoorIsMotorized: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                    ...(breakInDamage.damageSeverity !== DamageSeverity.Critical
                        ? {
                            numberOfImpacts: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                ...(breakInDamage.material === Material.PVC
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.FourToSix,
                                                ImpactsNumber.MoreThanSeven,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Metal
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.MoreThanThree,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Wood
                                    ? {
                                        inclusion: {
                                            within: [ImpactsNumber.NoImpact, ImpactsNumber.AtLeastOne],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                            },
                        }
                        : null),
                    ...(!(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.material === Material.PVC &&
                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven))
                        ? {
                            garageDoorIsFunctional: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            ...(breakInDamage.garageDoorIsFunctional
                                ? {
                                    garageDoorIsBent: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                    ...(!breakInDamage.garageDoorIsBent
                                        ? {
                                            hasOtherDamages: {
                                                presence: {
                                                    allowEmpty: false,
                                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                                },
                                            },
                                            ...(breakInDamage.hasOtherDamages
                                                ? {
                                                    otherDamages: {
                                                        presence: {
                                                            allowEmpty: false,
                                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                                        },
                                                    },
                                                }
                                                : null),
                                        }
                                        : null),
                                }
                                : null),
                        }
                        : null),
                    hasBeenRepaired: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    repairedBy: {
                        presenceIfValue: {
                            attribute: 'hasBeenRepaired',
                            triggeringValue: [
                                BreakInDamageRepairStatus.Repaired,
                                BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                            ],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                }
                : null),
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.Other) {
        return {
            ...baseConstraints(breakInDamage, context),
            otherDamageType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [
                        OtherDamageType.Skylight,
                        OtherDamageType.FixedBay,
                        OtherDamageType.Transom,
                        OtherDamageType.Other,
                    ],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.otherDamageType === OtherDamageType.Other
                ? {
                    otherDamageName: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                }
                : null),
            otherDamageDescription: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            hasBeenRepaired: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            repairedBy: {
                presenceIfValue: {
                    attribute: 'hasBeenRepaired',
                    triggeringValue: [
                        BreakInDamageRepairStatus.Repaired,
                        BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                    ],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.Fence) {
        return {
            ...baseConstraints(breakInDamage, context),
            fenceType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [
                        FenceType.WireMesh,
                        FenceType.WirePanel,
                        FenceType.WoodPanel,
                        FenceType.PVC,
                        FenceType.Other,
                    ],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            fenceLength: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            hasBeenRepaired: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            repairedBy: {
                presenceIfValue: {
                    attribute: 'hasBeenRepaired',
                    triggeringValue: [
                        BreakInDamageRepairStatus.Repaired,
                        BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                    ],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.LittleGate) {
        return {
            ...baseConstraints(breakInDamage, context),
            material: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [Material.Wood, Material.Metal, Material.PVC],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            damageSeverity: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [DamageSeverity.Slight, DamageSeverity.Notable, DamageSeverity.Critical],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                ? {
                    ...(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.damageSeverity === DamageSeverity.Notable &&
                            ((breakInDamage.material === Material.PVC &&
                                breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
                                (breakInDamage.material === Material.Metal &&
                                    breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree)))
                        ? {
                            littleGateHeight: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        Measurement.LessThan120cm,
                                        Measurement.Between120And140cm,
                                        Measurement.Between140And180cm,
                                        Measurement.MoreThan180cm,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                    ...(breakInDamage.damageSeverity !== DamageSeverity.Critical
                        ? {
                            numberOfImpacts: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                ...(breakInDamage.material === Material.PVC
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.FourToSix,
                                                ImpactsNumber.MoreThanSeven,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Metal
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.MoreThanThree,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Wood
                                    ? {
                                        inclusion: {
                                            within: [ImpactsNumber.NoImpact, ImpactsNumber.AtLeastOne],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                            },
                        }
                        : null),
                    ...(!(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.material === Material.PVC &&
                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
                        (breakInDamage.material === Material.Metal &&
                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree))
                        ? {
                            littleGateLockIsDamaged: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            hasOtherDamages: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            ...(breakInDamage.hasOtherDamages
                                ? {
                                    otherDamages: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                }
                                : null),
                        }
                        : null),
                    hasBeenRepaired: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    repairedBy: {
                        presenceIfValue: {
                            attribute: 'hasBeenRepaired',
                            triggeringValue: [
                                BreakInDamageRepairStatus.Repaired,
                                BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                            ],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                }
                : null),
        };
    }
    else if (breakInDamage.type === RobberyBreakableElement.Gate) {
        return {
            ...baseConstraints(breakInDamage, context),
            gateType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [GateType.Sliding, GateType.Flappy],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            gateFillType: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [GateFillStyle.Plein, GateFillStyle.Ajoure, GateFillStyle.SemiAjoure],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            material: {
                presence: {
                    allowEmpty: false,
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
                inclusion: {
                    within: [Material.Wood, Material.Aluminium, Material.Steel, Material.PVC],
                    message: 'claim.damage.break_in_damage.form.validator.presence',
                },
            },
            ...(breakInDamage.damageSeverity !== DamageSeverity.Slight
                ? {
                    ...(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.damageSeverity === DamageSeverity.Notable &&
                            ((breakInDamage.material === Material.PVC &&
                                breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
                                ((breakInDamage.material === Material.Metal ||
                                    breakInDamage.material === Material.Aluminium) &&
                                    breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree) ||
                                breakInDamage.gateIsBent ||
                                breakInDamage.gateIsFunctional === false))
                        ? {
                            gateWidth: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        Measurement.LessThan300cm,
                                        Measurement.Between300And400cm,
                                        Measurement.MoreThan400cm,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            gateHeight: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        Measurement.LessThan150cm,
                                        Measurement.Between150And170cm,
                                        Measurement.Between170And180cm,
                                        Measurement.MoreThan180cm,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            gateIsMotorized: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                        }
                        : null),
                    ...(breakInDamage.damageSeverity !== DamageSeverity.Critical
                        ? {
                            numberOfImpacts: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                ...(breakInDamage.material === Material.PVC
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.FourToSix,
                                                ImpactsNumber.MoreThanSeven,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Aluminium ||
                                    breakInDamage.material === Material.Steel
                                    ? {
                                        inclusion: {
                                            within: [
                                                ImpactsNumber.NoImpact,
                                                ImpactsNumber.OneToThree,
                                                ImpactsNumber.MoreThanThree,
                                            ],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                                ...(breakInDamage.material === Material.Wood
                                    ? {
                                        inclusion: {
                                            within: [ImpactsNumber.NoImpact, ImpactsNumber.AtLeastOne],
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    }
                                    : null),
                            },
                        }
                        : null),
                    ...(!(breakInDamage.damageSeverity === DamageSeverity.Critical ||
                        (breakInDamage.material === Material.PVC &&
                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
                        ((breakInDamage.material === Material.Metal ||
                            breakInDamage.material === Material.Aluminium) &&
                            breakInDamage.numberOfImpacts === ImpactsNumber.MoreThanThree) ||
                        breakInDamage.gateIsBent ||
                        breakInDamage.gateIsFunctional === false)
                        ? {
                            damageSeverity: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                                inclusion: {
                                    within: [
                                        DamageSeverity.Slight,
                                        DamageSeverity.Notable,
                                        DamageSeverity.Critical,
                                    ],
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            gateLockIsDamaged: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            gateIsFunctional: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            gateIsBent: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            hasOtherDamages: {
                                presence: {
                                    allowEmpty: false,
                                    message: 'claim.damage.break_in_damage.form.validator.presence',
                                },
                            },
                            ...(breakInDamage.hasOtherDamages
                                ? {
                                    otherDamages: {
                                        presence: {
                                            allowEmpty: false,
                                            message: 'claim.damage.break_in_damage.form.validator.presence',
                                        },
                                    },
                                }
                                : null),
                        }
                        : null),
                    hasBeenRepaired: {
                        presence: {
                            allowEmpty: false,
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                    repairedBy: {
                        presenceIfValue: {
                            attribute: 'hasBeenRepaired',
                            triggeringValue: [
                                BreakInDamageRepairStatus.Repaired,
                                BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
                            ],
                            message: 'claim.damage.break_in_damage.form.validator.presence',
                        },
                    },
                }
                : null),
        };
    }
    return defaultConstraints(breakInDamage, context);
}
