import { ImpactsNumber, LeavesType, Measurement } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
export const SLIDING_BAY_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES = {
    [LeavesType.TwoLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.two-leaves',
        image: require('@declaration/assets/images/breakInDamage/slidingBay/leavesType/deux-battants.svg'),
    },
    [LeavesType.ThreeLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.three-leaves',
        image: require('@declaration/assets/images/breakInDamage/slidingBay/leavesType/trois-battants.svg'),
    },
};
export const SLIDING_BAY_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.MoreThanThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    },
];
export const SLIDING_BAY_WIDTH_CHOICES = [
    {
        value: Measurement.LessThan220cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-220cm',
    },
    {
        value: Measurement.Between220And260cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.between-220-and-260cm',
    },
    {
        value: Measurement.MoreThan260cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-260cm',
    },
];
export const REPAIR_STATUS_CHOICES_SLIDING_BAY = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.sliding-bay',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.sliding-bay',
    },
];
