import { ImpactsNumber, LeavesType, Material, Measurement } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
export const WINDOW_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES = {
    [LeavesType.OneLeaf]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.one-leaf',
        image: require('@declaration/assets/images/breakInDamage/window/leavesType/un-battant.svg'),
    },
    [LeavesType.TwoLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.two-leaves',
        image: require('@declaration/assets/images/breakInDamage/window/leavesType/deux-battants.svg'),
    },
    [LeavesType.ThreeLeaves]: {
        tradKey: 'claim.declaration.break_in_damages.form.leaves-type.three-leaves',
        image: require('@declaration/assets/images/breakInDamage/window/leavesType/trois-battants.svg'),
    },
};
export const WINDOW_MATERIAL_CHOICES = [
    {
        value: Material.Wood,
        tradKey: 'claim.declaration.break_in_damages.form.material.wood',
    },
    {
        value: Material.PVC,
        tradKey: 'claim.declaration.break_in_damages.form.material.pvc',
    },
    {
        value: Material.Metal,
        tradKey: 'claim.declaration.break_in_damages.form.material.metal',
    },
];
export const WOODEN_WINDOW_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.AtLeastOne,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
    },
];
export const METAL_WINDOW_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.MoreThanThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    },
];
export const PVC_WINDOW_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.FourToSix,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.four-to-six',
    },
    {
        value: ImpactsNumber.MoreThanSeven,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-seven',
    },
];
export const ONE_LEAF_WINDOW_HEIGHT_CHOICES = [
    {
        value: Measurement.LessThan60cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-60cm',
    },
    {
        value: Measurement.Between60And120cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.between-60-and-120cm',
    },
    {
        value: Measurement.MoreThan120cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-120cm',
    },
];
export const TWO_LEAVES_WINDOW_HEIGHT_CHOICES = [
    {
        value: Measurement.LessThan100cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-100cm',
    },
    {
        value: Measurement.Between100And180cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.between-100-and-180cm',
    },
    {
        value: Measurement.MoreThan180cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-180cm',
    },
];
export const ONE_LEAF_WINDOW_WIDTH_CHOICES = [
    {
        value: Measurement.LessThan60cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-60cm',
    },
    {
        value: Measurement.Between60And120cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.between-60-and-120cm',
    },
    {
        value: Measurement.MoreThan120cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-120cm',
    },
];
export const TWO_LEAVES_WINDOW_WIDTH_CHOICES = [
    {
        value: Measurement.LessThan95cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-95cm',
    },
    {
        value: Measurement.Between95And145cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.between-95-and-145cm',
    },
    {
        value: Measurement.MoreThan145cm,
        tradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-145cm',
    },
];
export const REPAIR_STATUS_CHOICES_WINDOW = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.window',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.window',
    },
];
