import { imageObjectToChoices, translatableObjectToChoices } from '@shared/choice';
import { GATE_TYPE_TRAD_KEYS_AND_IMAGES } from '@shared/constants/breakInDamages/GateConstants';
import { GATE_HEIGHT_TRAD_KEYS, GATE_MATERIAL_TRAD_KEYS, GATE_WIDTH_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { ModelFieldInputPreset, ModelFieldInputType } from '@shared/modelField/modelField';
import { MATERIALS_TRAD_KEYS, MEASUREMENTS_TRAD_KEYS } from '@shared/types/api/breakInDamage';
import { optionalBooleanTransform, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
export const gateFields = (model, { translate }) => {
    return [
        {
            label: translate('model.external_damage.gate_type'),
            value: model.type,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: imageObjectToChoices(GATE_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'type',
            },
        },
        {
            label: translate('model.external_damage.gate_fill_type'),
            value: model.panelFillStyle,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: imageObjectToChoices(GATE_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'panelFillStyle',
            },
        },
        {
            label: translate('model.external_damage.gate_is_opening_motorized'),
            value: model.isOpeningMotorized,
            displayedValue: optionalBooleanTransform(model.isOpeningMotorized, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isOpeningMotorized',
            },
        },
        {
            label: translate('model.external_damage.material'),
            value: model.material,
            displayedValue: optionalTranslatableEnumTransform(model.material, MATERIALS_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(GATE_MATERIAL_TRAD_KEYS, translate),
                },
                modelKey: 'material',
            },
        },
        {
            label: translate('model.external_damage.gate_width'),
            value: model.widthSlug,
            displayedValue: optionalTranslatableEnumTransform(model.widthSlug, MEASUREMENTS_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(GATE_WIDTH_TRAD_KEYS, translate),
                },
                modelKey: 'widthSlug',
            },
        },
        {
            label: translate('model.external_damage.gate_height'),
            value: model.heightSlug,
            displayedValue: optionalTranslatableEnumTransform(model.heightSlug, MEASUREMENTS_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(GATE_HEIGHT_TRAD_KEYS, translate),
                },
                modelKey: 'heightSlug',
            },
        },
    ];
};
