function defaultConstraints(_glassDamage, _context) {
    return {
        type: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.glass_damage.form.type.validator.presence',
            },
        },
        numberOfPanes: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.glass_damage.form.number_of_panes.validator.presence',
            },
            numericality: {
                onlyInteger: true,
                greaterThanOrEqualTo: 1,
                message: 'claim.damage.glass_damage.form.number_of_panes.validator.numericality',
            },
        },
        widestPaneWidth: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.glass_damage.form.widest_pane_width.validator.presence',
            },
            numericality: {
                onlyInteger: true,
                greaterThanOrEqualTo: 1,
                message: 'claim.damage.glass_damage.form.widest_pane_width.validator.numericality',
            },
        },
        widestPaneHeight: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.glass_damage.form.widest_pane_height.validator.presence',
            },
            numericality: {
                onlyInteger: true,
                greaterThanOrEqualTo: 1,
                message: 'claim.damage.glass_damage.form.widest_pane_height.validator.numericality',
            },
        },
        glazingType: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.glass_damage.form.glazing_type.validator.presence',
            },
        },
        glassType: {
            presence: {
                allowEmpty: false,
                message: 'claim.damage.glass_damage.form.glass_type.validator.presence',
            },
        },
    };
}
export function glassDamageConstraints(glassDamage, context) {
    switch (glassDamage.type) {
        default:
            return defaultConstraints(glassDamage, context);
    }
}
