import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalString } from '@shared/utils/transform';
export const antennaFields = (model, { translate }) => {
    return [
        {
            label: translate('model.external_damage.antenna_count'),
            value: model.antennaCount,
            displayedValue: optionalString(model.antennaCount),
            editable: {
                input: { type: ModelFieldInputType.Number },
                modelKey: 'antennaCount',
            },
        },
    ];
};
