import { createDamageStore } from '@declaration/store/createDamageStore';
import { Step } from '@shared/claim/steps';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { receiptConstraints } from '@shared/validator/constraints/receiptConstraints';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
export const name = 'receipt';
export const namespaced = true;
const newReceipt = () => {
    return {
        id: null,
        clientIdentifier: newClientIdentifier(),
        title: null,
        receiptDate: null,
        amount: null,
        receiptFile: emptyUploadedFileCollection(),
    };
};
const { createDamageState, damageGetters, damageActions, damageMutations } = createDamageStore(newReceipt, receiptConstraints, Step.RoomDamagesDetails, 'receipts');
export const state = () => createDamageState();
export const getters = {
    ...damageGetters,
};
export const actions = {
    ...damageActions,
};
export const mutations = {
    ...damageMutations,
};
