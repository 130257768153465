import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { damageSeverityField, dimensionsFields, editionMaterialField, impactsNumberField, otherDamagesFields, } from '@shared/breakInDamage/breakInDamageFields';
import { translatableObjectToChoices, translateChoices } from '@shared/choice';
import { GATE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES, GATE_HEIGHT_TRAD_KEYS, GATE_IS_MOTORIZED_CHOICES, GATE_TYPE_TRAD_KEYS_AND_IMAGES, GATE_WIDTH_TRAD_KEYS, } from '@shared/constants/breakInDamages/GateConstants';
import { DamageSeverity, ImpactsNumber, Material } from '@shared/types/api/breakInDamage';
// This method is the direct implementation of the logic followed for the required fields related to break-in damages of type <Gate>
// The diagram expressing this logic can be found here : https://whimsical.com/claims-v2-LPTF48okXotSDh3m7uGkUH
// It is VERY STRONGLY RECOMMENDED to have a view of this diagram when reading or updating this code.
export function gateBreakInDamageEditionFields(model, translate, projection, edition = false) {
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.break_in_damage.gate_type'),
            key: 'gateType',
            value: model.gateType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GATE_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                },
                modelKey: 'gateType',
            },
        }, {
            label: translate('model.break_in_damage.gate_fill_type'),
            key: 'gateFillType',
            value: model.gateFillType,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GATE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES, translate),
                    vertical: true,
                },
                modelKey: 'gateFillType',
            },
        });
        fields.push(editionMaterialField(model, translate));
    }
    fields.push(damageSeverityField(model, translate));
    if (model.damageSeverity === DamageSeverity.Critical) {
        if (edition) {
            fields.push(...gateBreakInDamageCriticalSeverityFields(model, translate));
        }
    }
    else if (model.damageSeverity === DamageSeverity.Notable) {
        fields.push(impactsNumberField(model, translate));
        if (edition) {
            if ((model.material === Material.PVC &&
                model.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
                ((model.material === Material.Metal || model.material === Material.Aluminium) &&
                    model.numberOfImpacts === ImpactsNumber.MoreThanThree)) {
                fields.push(...gateBreakInDamageCriticalSeverityFields(model, translate));
            }
            else {
                fields.push({
                    label: translate('model.break_in_damage.lock_damage'),
                    key: 'gateLockIsDamaged',
                    value: model.gateLockIsDamaged,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'gateLockIsDamaged',
                    },
                });
                fields.push({
                    label: translate('model.break_in_damage.gate.functional'),
                    key: 'gateIsFunctional',
                    value: model.gateIsFunctional,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'gateIsFunctional',
                    },
                });
                fields.push({
                    label: translate('model.break_in_damage.gate.is_bent'),
                    key: 'gateIsBent',
                    value: model.gateIsBent,
                    editable: {
                        input: ModelFieldInputPreset.boolean(translate),
                        modelKey: 'gateIsBent',
                    },
                });
                if (model.gateIsBent || model.gateIsFunctional === false) {
                    fields.push(...gateBreakInDamageCriticalSeverityFields(model, translate));
                }
            }
        }
        if (projection !== ModelFieldsProjection.Declaration &&
            !(model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven) &&
            !((model.material === Material.Metal || model.material === Material.Aluminium) &&
                model.numberOfImpacts === ImpactsNumber.MoreThanThree) &&
            model.gateIsFunctional &&
            !model.gateIsBent) {
            fields.push(...otherDamagesFields(model, translate));
        }
    }
    // We check the conditions for critical severity again but this time also checking if we're in
    // readonly mode because in this mode we want the dimensions fields to appear at the end
    if (!edition &&
        (model.damageSeverity === DamageSeverity.Critical ||
            (model.material === Material.PVC && model.numberOfImpacts === ImpactsNumber.MoreThanSeven) ||
            ((model.material === Material.Metal || model.material === Material.Aluminium) &&
                model.numberOfImpacts === ImpactsNumber.MoreThanThree) ||
            model.gateIsFunctional === false ||
            model.gateIsBent)) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function gateBreakInDamageCriticalSeverityFields(model, translate, edition = false) {
    return [
        {
            label: translate(edition ? '' : 'claim.declaration.external_damages.form.gate.opening.title'),
            key: 'gateIsMotorized',
            value: model.gateIsMotorized,
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(GATE_IS_MOTORIZED_CHOICES, translate),
                },
                modelKey: 'gateIsMotorized',
            },
        },
        {
            label: translate('claim.declaration.external_damages.form.gate.height.title'),
            key: 'gateHeight',
            value: model.gateHeight,
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(GATE_HEIGHT_TRAD_KEYS, translate),
                },
                modelKey: 'gateHeight',
            },
        },
        {
            label: translate('claim.declaration.external_damages.form.gate.width.title'),
            key: 'gateWidth',
            value: model.gateWidth,
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(GATE_WIDTH_TRAD_KEYS, translate),
                },
                modelKey: 'gateWidth',
            },
        },
    ];
}
