/**
 * A DeclarationPage is a page displayed to the User for one or multiple steps of a declaration
 *
 * Must be updated for each declaration page component.
 * It serves as an enum proxy because components cannot be directly imported as it causes
 * circular imports that fail compilation
 */
export var DeclarationPage;
(function (DeclarationPage) {
    DeclarationPage["Index"] = "Index";
    DeclarationPage["Contracts"] = "Contracts";
    DeclarationPage["ContractInformation"] = "ContractInformation";
    DeclarationPage["ContractModification"] = "ContractModification";
    DeclarationPage["ClaimType"] = "ClaimType";
    DeclarationPage["Questions"] = "Questions";
    DeclarationPage["DroughtDeclaration"] = "DroughtDeclaration";
    DeclarationPage["CallbackConfirmation"] = "CallbackConfirmation";
    DeclarationPage["CallbackConfirmationTNG"] = "CallbackConfirmationTNG";
    DeclarationPage["NeedRehousing"] = "NeedRehousing";
    DeclarationPage["ShouldRepairLeakage"] = "ShouldRepairLeakage";
    DeclarationPage["ShouldLocalizeLeakage"] = "ShouldLocalizeLeakage";
    DeclarationPage["Smuggling"] = "Smuggling";
    DeclarationPage["WaterLeakageCauseNotLocalized"] = "WaterLeakageCauseNotLocalized";
    DeclarationPage["WaterLeakageCauseAdvisorContact"] = "WaterLeakageCauseAdvisorContact";
    DeclarationPage["ShouldContactOwnerLeakage"] = "ShouldContactOwnerLeakage";
    DeclarationPage["CompensationOptions"] = "CompensationOptions";
    DeclarationPage["CompensationRemainingWorks"] = "CompensationRemainingWorks";
    DeclarationPage["CompensationRemainingWorksConfirmation"] = "CompensationRemainingWorksConfirmation";
    DeclarationPage["CompensationProposition"] = "CompensationProposition";
    DeclarationPage["CompensationLostLeys"] = "CompensationLostLeys";
    DeclarationPage["CompensationConfirmation"] = "CompensationConfirmation";
    DeclarationPage["CompensationPartnerOption"] = "CompensationPartnerOption";
    DeclarationPage["CompensationOtherArtisanSendInvoice"] = "CompensationOtherArtisanSendInvoice";
    DeclarationPage["CompensationLowDeductible"] = "CompensationLowDeductible";
    DeclarationPage["CompensationNoDamages"] = "CompensationNoDamages";
    DeclarationPage["CompensationNotCoveredGuarantee"] = "CompensationNotCoveredGuarantee";
    DeclarationPage["CompensationLostSharedKeys"] = "CompensationLostSharedKeys";
    DeclarationPage["CompensationDamagesSumUp"] = "CompensationDamagesSumUp";
    DeclarationPage["CompensationSsDiNonOccConvDi"] = "CompensationSsDiNonOccConvDi";
    DeclarationPage["DeclarationDeadlineOver"] = "DeclarationDeadlineOver";
    DeclarationPage["NoAdministrativeFollowupIndex"] = "NoAdministrativeFollowupIndex";
    DeclarationPage["NoFollowUpIndex"] = "NoFollowUpIndex";
    DeclarationPage["ClaimCompleted"] = "ClaimCompleted";
    DeclarationPage["ClaimCompletedArtisan"] = "ClaimCompletedArtisan";
    DeclarationPage["NoSuitableExpertiseSchedule"] = "NoSuitableExpertiseSchedule";
    DeclarationPage["TransferToPae"] = "TransferToPae";
    DeclarationPage["DeclarationDocumentPending"] = "DeclarationDocumentPending";
    DeclarationPage["ExpertAppointmentByPhoneIndex"] = "ExpertAppointmentByPhoneIndex";
    DeclarationPage["ExpertAppointmentByPhoneDateSelection"] = "ExpertAppointmentByPhoneDateSelection";
    DeclarationPage["ExpertAppointmentByPhoneConfirmation"] = "ExpertAppointmentByPhoneConfirmation";
    DeclarationPage["ExpertAppointmentByVisioIndex"] = "ExpertAppointmentByVisioIndex";
    DeclarationPage["ExpertAppointmentByVisioVerification"] = "ExpertAppointmentByVisioVerification";
    DeclarationPage["ExpertAppointmentByVisioDateSelection"] = "ExpertAppointmentByVisioDateSelection";
    DeclarationPage["ExpertAppointmentByVisioConfirmation"] = "ExpertAppointmentByVisioConfirmation";
    DeclarationPage["ExpertAppointmentOnSiteIndex"] = "ExpertAppointmentOnSiteIndex";
    DeclarationPage["ExpertAppointmentOnSiteDateSelection"] = "ExpertAppointmentOnSiteDateSelection";
    DeclarationPage["ExpertAppointmentOnSiteConfirmation"] = "ExpertAppointmentOnSiteConfirmation";
    DeclarationPage["ExpertRecontactForAppointment"] = "ExpertRecontactForAppointment";
    DeclarationPage["ArtisanRepairAppointmentDateSelection"] = "ArtisanRepairAppointmentDateSelection";
    DeclarationPage["ArtisanLeakageSearchAppointmentIndex"] = "ArtisanLeakageSearchAppointmentIndex";
    DeclarationPage["ArtisanLeakageSearchAppointmentDateSelection"] = "ArtisanLeakageSearchAppointmentDateSelection";
    DeclarationPage["ArtisanLeakageSearchAppointmentConfirmation"] = "ArtisanLeakageSearchAppointmentConfirmation";
    DeclarationPage["AssistanceIndex"] = "AssistanceIndex";
    DeclarationPage["ArtisanRobberySecuringAppointmentDateSelection"] = "ArtisanRobberySecuringAppointmentDateSelection";
    DeclarationPage["ArtisanRobberySecuringAppointmentConfirmation"] = "ArtisanRobberySecuringAppointmentConfirmation";
    DeclarationPage["ImmediatePayoutImpossible"] = "ImmediatePayoutImpossible";
    DeclarationPage["PayoutIndex"] = "PayoutIndex";
    DeclarationPage["PayoutPending"] = "PayoutPending";
    DeclarationPage["PayoutSuccess"] = "PayoutSuccess";
    DeclarationPage["PayoutFailure"] = "PayoutFailure";
    DeclarationPage["Summary"] = "Summary";
    DeclarationPage["BreakInDamagesMissingFile"] = "BreakInDamagesMissingFile";
    DeclarationPage["InManagement"] = "InManagement";
    DeclarationPage["WaintingForManagement"] = "WaintingForManagement";
    DeclarationPage["Maintenance"] = "Maintenance";
    DeclarationPage["NoDamage"] = "NoDamage";
    DeclarationPage["TenantInsurer"] = "TenantInsurer";
    DeclarationPage["OwnerInsurer"] = "OwnerInsurer";
    DeclarationPage["ValuationOwnerInsurer"] = "ValuationOwnerInsurer";
    DeclarationPage["RoomDamagesOwnerInsurer"] = "RoomDamagesOwnerInsurer";
    DeclarationPage["TenantNotCovered"] = "TenantNotCovered";
    DeclarationPage["OnlyYourDamages"] = "OnlyYourDamages";
    DeclarationPage["RobberyPNOExit"] = "RobberyPNOExit";
    DeclarationPage["DamagesDeclarationInfos"] = "DamagesDeclarationInfos";
    DeclarationPage["NeedManagement"] = "NeedManagement";
    DeclarationPage["ClaimRefused"] = "ClaimRefused";
    DeclarationPage["UserComment"] = "UserComment";
    DeclarationPage["NoThirdPartyDeductibleApplicationInfo"] = "NoThirdPartyDeductibleApplicationInfo";
})(DeclarationPage || (DeclarationPage = {}));
