const appointmentConstraints = {
    phoneNumber: {
        presence: {
            allowEmpty: false,
            message: 'appointment.user_contact.form.phone_number.error_message',
        },
    },
};
export const backofficeAppointmentConstraints = {
    selectedTimeSlot: {
        presence: {
            allowEmpty: false,
            message: 'appointment.expert.date_selection.validation',
        },
    },
    'userContact.phoneNumber': {
        presence: {
            allowEmpty: false,
            message: 'appointment.user_contact.form.phone_number.error_message',
        },
    },
};
export default appointmentConstraints;
