import { WC_TYPE_MODEL_LABELS, WC_TYPE_MODEL_CHOICES } from '../goodDamage';
import { translateChoices } from '@shared/choice';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export function wcFields(model, context) {
    const { translate, index } = context;
    return [
        {
            label: translate('model.good_damage.wc.wc_type'),
            value: model.wcType,
            displayedValue: optionalTranslatableEnumTransform(model.wcType, WC_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(WC_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.wcType`,
                toPayload: (wcType) => ({ wcType }),
            },
        },
    ];
}
