var _a, _b;
/**
 * Validates that variable is present in __env__
 */
function getEnv(variable) {
    const value = window.__env__ && window.__env__[variable];
    if (value === undefined) {
        throw new TypeError('Missing environment variable ' + variable);
    }
    return value;
}
function getEnvOrDefault(variable, defaultValue) {
    try {
        return getEnv(variable);
    }
    catch (e) {
        return defaultValue;
    }
}
export const CONFIG = {
    apiUrl: getEnv('API_URL'),
    checkoutPublicKey: getEnv('CHECKOUT_PUBLIC_KEY'),
    crispWebsiteId: getEnvOrDefault('CRISP_WEBSITE_ID', ''),
    keycloakUrl: getEnvOrDefault('KEYCLOAK_URL', null),
    keycloakClientId: getEnvOrDefault('KEYCLOAK_CLIENT_ID', null),
    keycloakConfigPath: getEnvOrDefault('KEYCLOAK_CONFIG_PATH', null),
    // Environment variables injected at build time :
    release: ((_a = process.env.NUXT_ENV_SENTRY_RELEASE) !== null && _a !== void 0 ? _a : null),
    nodeEnv: (_b = process.env.NODE_ENV) !== null && _b !== void 0 ? _b : 'development',
};
