import { Material, Measurement } from '@shared/types/api/breakInDamage';
export const GATE_MATERIAL_TRAD_KEYS = {
    [Material.Steel]: 'claim.declaration.external_damages.form.gate.material.acier',
    [Material.Aluminium]: 'claim.declaration.external_damages.form.gate.material.aluminium',
    [Material.PVC]: 'claim.declaration.external_damages.form.gate.material.pvc',
    [Material.Wood]: 'claim.declaration.external_damages.form.gate.material.bois',
};
export const GATE_HEIGHT_TRAD_KEYS = {
    [Measurement.LessThan150cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-150cm',
    [Measurement.Between150And170cm]: 'claim.declaration.break_in_damages.form.measurement.between-150-and-170cm',
    [Measurement.Between170And180cm]: 'claim.declaration.break_in_damages.form.measurement.between-170-and-180cm',
    [Measurement.MoreThan180cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-180cm',
};
export const GATE_WIDTH_TRAD_KEYS = {
    [Measurement.LessThan300cm]: 'claim.declaration.break_in_damages.form.measurement.less-than-300cm',
    [Measurement.Between300And400cm]: 'claim.declaration.break_in_damages.form.measurement.between-300-and-400cm',
    [Measurement.MoreThan400cm]: 'claim.declaration.break_in_damages.form.measurement.more-than-400cm',
};
export const GATE_IS_MOTORIZED_CHOICES = [
    {
        value: false,
        tradKey: 'claim.declaration.break_in_damages.form.gate.motorization.manual',
    },
    {
        value: true,
        tradKey: 'claim.declaration.break_in_damages.form.gate.motorization.motorized',
    },
];
