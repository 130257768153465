// Memoized fetchSelectedClaim result
let claimId;
let claimFetchPromise;
const SHOULD_FETCH_CLAIM_GETTER = 'claim/shouldFetchClaim';
const FETCH_CLAIM_ACTION = 'claim/fetchClaim';
export function fetchSelectedClaim(store) {
    if (!store.getters[SHOULD_FETCH_CLAIM_GETTER]) {
        return Promise.resolve(store.state);
    }
    const storeClaimId = store.state.claim.claim.id;
    // Return already memoized promise for performance and no double requests
    if (claimFetchPromise && claimId === storeClaimId) {
        return claimFetchPromise;
    }
    claimFetchPromise = store
        .dispatch(FETCH_CLAIM_ACTION)
        .then(() => store.state)
        .catch(() => null);
    claimId = storeClaimId;
    return claimFetchPromise;
}
