import { ModelFieldInputType, } from '@shared/modelField/modelField';
import { ClaimFileType } from '@shared/types/file';
import { formatOptionalAge } from '@shared/utils/formatAge';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { optionalString, optionalTranslatableEnumTransform } from '@shared/utils/transform';
import equipmentConstraints from '@shared/validator/constraints/equipmentConstraints';
import { QUALIFICATION_EQUIPMENT } from '@shared/constants/equipmentConstants';
import { capitalize } from '@shared/utils/strings';
export function equipmentTitle(model, translate) {
    var _a;
    return optionalTranslatableEnumTransform(model.name, QUALIFICATION_EQUIPMENT, translate, (_a = capitalize(model.name)) !== null && _a !== void 0 ? _a : 'Equipement');
}
export const equipmentFields = (model, context) => {
    const { translate, edition } = context;
    const title = equipmentTitle(model, translate);
    const fields = [];
    if (edition) {
        fields.push({
            label: translate('model.equipment.name'),
            value: model.name,
            displayedValue: optionalString(model.name),
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'name',
            },
        });
    }
    fields.push({
        label: translate('model.equipment.brand_or_model'),
        value: model.brandOrModel,
        displayedValue: optionalString(model.brandOrModel),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'brandOrModel',
        },
    });
    fields.push({
        label: translate('model.equipment.age'),
        value: model.age,
        displayedValue: formatOptionalAge(model.age, translate, true),
        editable: {
            input: {
                type: ModelFieldInputType.Age,
                yearsOnly: true,
            },
            modelKey: 'age',
        },
    });
    fields.push({
        label: translate('model.equipment.purchase_price'),
        value: model.purchasePrice,
        displayedValue: formatOptionalCentsAmount(model.purchasePrice),
        editable: {
            input: {
                type: ModelFieldInputType.Amount,
            },
            modelKey: 'purchasePrice',
        },
    });
    fields.push({
        label: translate('model.equipment.receipt_file'),
        value: model.receipt,
        displayedValue: {
            type: 'fileCollection',
            fileCollection: model.receipt,
            emptyLabel: translate('model.equipment.receipt_file.empty'),
            fileType: ClaimFileType.EquipmentReceipt,
            uploadLabel: translate('model.file.upload'),
        },
        editable: {
            input: {
                type: ModelFieldInputType.FileCollection,
            },
            modelKey: 'receipt',
        },
    });
    fields.push({
        label: translate('model.pictures'),
        value: model.pictureCollection,
        displayedValue: {
            type: 'fileCollection',
            fileType: ClaimFileType.EquipmentPicture,
            uploadLabel: translate('model.picture.upload'),
            emptyLabel: translate('model.pictures.empty'),
            fileCollection: model.pictureCollection,
            displayAsCount: true,
        },
        editable: {
            input: {
                type: ModelFieldInputType.FileCollection,
            },
            modelKey: 'pictures',
        },
    });
    return { title, fields, model, editable: { constraints: equipmentConstraints } };
};
