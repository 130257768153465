import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { idFromIri } from '@shared/utils/iri';
import { INSURER } from '@shared/insurer';
import { CONFIG } from '@shared/environment/environment';
export default ({ store }) => {
    if (!CONFIG.release) {
        return;
    }
    const sentryOptions = {
        environment: CONFIG.nodeEnv,
        release: CONFIG.release,
        dsn: 'https://21eac4f3aa91403892fba691db363d25@sentry.io/3049004',
        integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
    };
    Sentry.init(sentryOptions);
    Sentry.setTag('insurer', INSURER);
    // Set user context
    store.watch(({ user: { email, lastname, roles, firstname } }) => {
        if (email === null) {
            return null;
        }
        return {
            email,
            username: `${firstname !== null && firstname !== void 0 ? firstname : ''} ${lastname !== null && lastname !== void 0 ? lastname : ''}`.trim() || undefined,
            roles: roles !== null && roles !== void 0 ? roles : undefined,
        };
    }, (userInfo) => {
        Sentry.configureScope((scope) => {
            scope.setUser(userInfo);
        });
    }, { deep: true, immediate: true });
    // Set claim context
    store.watch(({ claim: { claim, isClaimFetched } }) => {
        return {
            claim: claim.id,
            claimFetched: isClaimFetched,
            stepStack: claim.stepStack,
            nextStep: claim.nextStep,
            claimStatus: claim.status,
            contract: claim.contractIri ? idFromIri(claim.contractIri) : null,
        };
    }, (claimInfo) => {
        Sentry.configureScope((scope) => {
            scope.setExtras(claimInfo);
        });
    }, { deep: true, immediate: true });
};
