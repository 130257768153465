import { CHARGE_TYPOLOGY_CHOICES, CHARGE_TYPOLOGY_TRAD_KEYS } from './chargeConstants';
import { translateChoices } from '@shared/choice';
import { ModelFieldInputType, } from '@shared/modelField/modelField';
import formatOptionalAmount from '@shared/utils/formatOptionalAmount';
import { optionalString, optionalTranslatableEnumTransform } from '@shared/utils/transform';
import { chargeConstraintsFactory } from '@shared/validator/constraints/chargeConstraints';
export const chargeFields = (model, { translate }) => {
    var _a;
    const title = '';
    const fields = [
        {
            label: translate('claim.charge.typology'),
            value: model.typology,
            displayedValue: optionalTranslatableEnumTransform(model.typology, CHARGE_TYPOLOGY_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(CHARGE_TYPOLOGY_CHOICES, translate),
                },
                modelKey: 'typology',
            },
        },
        {
            label: translate('claim.charge.description'),
            value: model.description,
            displayedValue: optionalString(model.description),
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'description',
            },
        },
        {
            label: translate('claim.charge.amount'),
            value: (_a = model.amount) !== null && _a !== void 0 ? _a : 0,
            displayedValue: formatOptionalAmount(model.amount),
            editable: {
                input: {
                    type: ModelFieldInputType.Amount,
                },
                unit: '€',
                modelKey: 'amount',
                toPayload: (amount) => ({ amount: amount !== null && amount !== void 0 ? amount : 0 }),
            },
        },
    ];
    return { title, fields, model, editable: { constraints: chargeConstraintsFactory } };
};
