import { WATER_RADIATOR_DAMAGE_TYPE_MODEL_LABELS, WATER_RADIATOR_DAMAGE_TYPE_MODEL_CHOICES, WATER_RADIATOR_MATERIAL_MODEL_LABELS, WATER_RADIATOR_MATERIAL_MODEL_CHOICES, WATER_RADIATOR_MATERIAL_LABELS, } from '../goodDamage';
import { translateChoices } from '@shared/choice';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { WaterRadiatorDamageType, WaterRadiatorMaterial, } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export function waterRadiatorDetails(model, translate) {
    if (!model.type ||
        !model.damageType ||
        !model.material ||
        model.damageType === WaterRadiatorDamageType.AESTHETIC ||
        model.material === WaterRadiatorMaterial.UNKNOWN)
        return null;
    return translate(WATER_RADIATOR_MATERIAL_LABELS[model.material]);
}
export function waterRadiatorFields(model, context) {
    const { translate, index } = context;
    const fields = [
        {
            label: translate('model.good_damage.water_radiator.damage_type'),
            value: model.damageType,
            displayedValue: optionalTranslatableEnumTransform(model.damageType, WATER_RADIATOR_DAMAGE_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    nullable: true,
                    choices: translateChoices(WATER_RADIATOR_DAMAGE_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.damageType`,
                toPayload: (damageType) => ({
                    damageType,
                    material: damageType === WaterRadiatorDamageType.DAMAGED ? model.material : null,
                }),
            },
        },
    ];
    if (model.damageType === WaterRadiatorDamageType.DAMAGED) {
        fields.push({
            label: translate('model.good_damage.water_radiator.material'),
            value: model.material,
            displayedValue: optionalTranslatableEnumTransform(model.material, WATER_RADIATOR_MATERIAL_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(WATER_RADIATOR_MATERIAL_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.material`,
                toPayload: (material) => ({ material }),
            },
        });
    }
    return fields;
}
