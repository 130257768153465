import { translatableObjectToChoices, translateChoices } from '@shared/choice';
import { ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS } from '@shared/constants/robberyConstants';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { BREAK_IN_DAMAGE_REPARATION_TYPES_TRAD_KEYS, BreakInDamageShutterType, DamageSeverity, DoorType, ImpactsNumber, Material, } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairationType, BreakInDamageRepairPerson, BreakInDamageRepairStatus, RobberyBreakableElement, } from '@shared/types/api/claim';
import { ClaimFileType } from '@shared/types/file';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { optionalBooleanTransform, optionalString, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
import { breakInDamageConstraints } from '@shared/validator/constraints/breakInDamageConstraints';
import { getBreakInDamageTitle } from '@shared/breakInDamage/breakInDamageHelpers';
import { BREAK_IN_DAMAGES_DAMAGE_SEVERITY_TRAD_KEYS, BREAK_IN_DAMAGES_NUMBER_OF_IMPACTS_TRAD_KEYS, REPAIRED_BY_CHOICES, MATERIAL_SUMUP_TRAD_KEYS, LEAVES_TYPE_SUMUP_TRAD_KEYS, } from '@shared/constants/breakInDamageConstants';
import { BAR_TYPE_SUMUP_TRAD_KEYS, METAL_SHUTTER_IMPACTS_NUMBER, PVC_SHUTTER_IMPACTS_NUMBER, REPAIR_STATUS_CHOICES_SHUTTER, SHUTTER_MATERIAL_CHOICES, SHUTTER_TYPE_SUMUP_TRAD_KEYS, WOODEN_SHUTTER_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/ShutterConstants';
import { DOOR_MATERIAL_CHOICES, DOOR_TYPE_TRAD_KEYS_AND_IMAGES, METAL_DOOR_IMPACTS_NUMBER, PVC_DOOR_IMPACTS_NUMBER, REPAIR_STATUS_CHOICES_DOOR, WOODEN_DOOR_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/DoorConstants';
import { GARAGE_DOOR_MATERIAL_CHOICES, GARAGE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES, METAL_GARAGE_DOOR_IMPACTS_NUMBER, PVC_GARAGE_DOOR_IMPACTS_NUMBER, REPAIR_STATUS_CHOICES_GARAGE_DOOR, WOODEN_GARAGE_DOOR_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/GarageDoorConstants';
import { GATE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES, GATE_MATERIAL_CHOICES, GATE_TYPE_TRAD_KEYS_AND_IMAGES, METAL_GATE_IMPACTS_NUMBER, PVC_GATE_IMPACTS_NUMBER, REPAIR_STATUS_CHOICES_GATE, WOODEN_GATE_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/GateConstants';
import { ExclusionReason } from '@shared/types/api/refundDetail';
import { doorBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/doorBreakInDamageFields';
import { fenceBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/fenceBreakInDamageFields';
import { frenchWindowBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/frenchWindowBreakInDamageFields';
import { garageDoorBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/garageDoorBreakInDamageFields';
import { gateBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/gateBreakInDamageFields';
import { littleGateBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/littleGateBreakInDamageFields';
import { otherBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/otherBreakInDamageFields';
import { shutterBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/shutterBreakInDamageFields';
import { slidingBayWindowBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/slidingBayWindowBreakInDamageFields';
import { windowBreakInDamageEditionFields } from '@shared/breakInDamage/fieldsByType/windowBreakInDamageFields';
import { FRENCH_WINDOW_MATERIAL_CHOICES, METAL_FRENCH_WINDOW_IMPACTS_NUMBER, PVC_FRENCH_WINDOW_IMPACTS_NUMBER, REPAIR_STATUS_CHOICES_FRENCH_WINDOW, WOOD_FRENCH_WINDOW_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/FrenchWindowConstants';
import { LITTLE_GATE_MATERIAL_CHOICES, METAL_LITTLE_GATE_IMPACTS_NUMBER, PVC_LITTLE_GATE_IMPACTS_NUMBER, REPAIR_STATUS_CHOICES_LITTLE_GATE, WOODEN_LITTLE_GATE_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/LittleGateConstants';
import { METAL_WINDOW_IMPACTS_NUMBER, PVC_WINDOW_IMPACTS_NUMBER, REPAIR_STATUS_CHOICES_WINDOW, WINDOW_MATERIAL_CHOICES, WOODEN_WINDOW_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/WindowConstants';
import { REPAIR_STATUS_CHOICES_SLIDING_BAY, SLIDING_BAY_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/SlidingBayConstants';
import { REPAIR_STATUS_CHOICES_FENCE } from '@shared/constants/breakInDamages/FenceConstants';
import { REPAIR_STATUS_CHOICES_OTHER } from '@shared/constants/breakInDamages/OtherConstants';
import { DEFAULT_FENCE_TYPE_TRAD_KEYS } from '@shared/constants/externalDamages';
function pendingRepairFileCollectionField(model, { translate }, label) {
    return {
        label: translate(label),
        value: model.pendingRepairFileCollection,
        displayedValue: {
            type: 'fileCollection',
            fileCollection: model.pendingRepairFileCollection,
            uploadLabel: translate('model.document.upload'),
            addFileLabel: translate('model.document.upload.add_page'),
            emptyLabel: translate('model.document.empty'),
            fileType: ClaimFileType.RealEstateQuote,
        },
        editable: {
            input: {
                type: ModelFieldInputType.FileCollection,
            },
            modelKey: 'pendingRepairFileCollection',
        },
    };
}
function pendingRepairAmountField(model, { translate }, label = 'model.break_in_damage.repair_amount') {
    var _a, _b, _c;
    return {
        label: translate(label),
        value: (_b = (_a = model.pendingRepairQuoteInfo) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : null,
        displayedValue: formatOptionalCentsAmount((_c = model.pendingRepairQuoteInfo) === null || _c === void 0 ? void 0 : _c.amount),
        editable: {
            input: {
                type: ModelFieldInputType.Amount,
            },
            modelKey: 'pendingRepairQuoteInfo.amount',
        },
        layout: {
            column: 1,
        },
    };
}
function pendingRepairCompanyField(model, { translate }, label = 'model.break_in_damage.repair_company') {
    var _a, _b, _c;
    return {
        label: translate(label),
        value: (_b = (_a = model.pendingRepairQuoteInfo) === null || _a === void 0 ? void 0 : _a.companyName) !== null && _b !== void 0 ? _b : null,
        displayedValue: optionalString((_c = model.pendingRepairQuoteInfo) === null || _c === void 0 ? void 0 : _c.companyName),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'pendingRepairQuoteInfo.companyName',
        },
        layout: {
            column: 1,
        },
    };
}
function repairFileCollectionField(model, { translate }, label) {
    return {
        label: translate(label),
        value: model.repairFileCollection,
        displayedValue: {
            type: 'fileCollection',
            fileCollection: model.repairFileCollection,
            uploadLabel: translate('model.document.upload'),
            addFileLabel: translate('model.document.upload.add_page'),
            emptyLabel: translate('model.document.empty'),
            fileType: ClaimFileType.BreakInDamageRepairReceipt,
        },
        editable: {
            input: {
                type: ModelFieldInputType.FileCollection,
            },
            modelKey: 'repairFileCollection',
        },
    };
}
function repairAmountField(model, { translate }, label = 'model.break_in_damage.repair_amount') {
    var _a, _b, _c;
    return {
        label: translate(label),
        value: (_b = (_a = model.repairQuoteInfo) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : null,
        displayedValue: formatOptionalCentsAmount((_c = model.repairQuoteInfo) === null || _c === void 0 ? void 0 : _c.amount),
        editable: {
            input: {
                type: ModelFieldInputType.Amount,
            },
            modelKey: 'repairQuoteInfo.amount',
        },
        layout: {
            column: 1,
        },
    };
}
function repairCompanyField(model, { translate }, label = 'model.break_in_damage.repair_company') {
    var _a, _b, _c;
    return {
        label: translate(label),
        value: (_b = (_a = model.repairQuoteInfo) === null || _a === void 0 ? void 0 : _a.companyName) !== null && _b !== void 0 ? _b : null,
        displayedValue: optionalString((_c = model.repairQuoteInfo) === null || _c === void 0 ? void 0 : _c.companyName),
        editable: {
            input: {
                type: ModelFieldInputType.Text,
            },
            modelKey: 'repairQuoteInfo.companyName',
        },
        layout: {
            column: 1,
        },
    };
}
export function breakInDamageTitle(model, translate) {
    return getBreakInDamageTitle(model, translate, 'Bien endommagé');
}
export function mustBeReplacedPayloadByTypeAndImpactsNumber(numberOfImpacts, type, material) {
    switch (type) {
        case RobberyBreakableElement.Door:
        case RobberyBreakableElement.FrenchWindow:
        case RobberyBreakableElement.GarageDoor:
        case RobberyBreakableElement.LittleGate:
        case RobberyBreakableElement.Shutter:
            if ((material === Material.Metal && numberOfImpacts === ImpactsNumber.MoreThanThree) ||
                (material === Material.PVC && numberOfImpacts === ImpactsNumber.MoreThanSeven)) {
                return { numberOfImpacts, mustBeReplaced: true };
            }
            return { numberOfImpacts, mustBeReplaced: null };
        case RobberyBreakableElement.Gate:
            if (((material === Material.Aluminium || material === Material.Metal) &&
                numberOfImpacts === ImpactsNumber.MoreThanThree) ||
                (material === Material.PVC && numberOfImpacts === ImpactsNumber.MoreThanSeven)) {
                return { numberOfImpacts, mustBeReplaced: true };
            }
            return { numberOfImpacts, mustBeReplaced: null };
        case RobberyBreakableElement.SlidingBayWindow:
            if (numberOfImpacts === ImpactsNumber.MoreThanThree) {
                return { numberOfImpacts, mustBeReplaced: true };
            }
            return { numberOfImpacts, mustBeReplaced: null };
        case RobberyBreakableElement.Window:
            if (material === Material.PVC && numberOfImpacts === ImpactsNumber.MoreThanSeven) {
                return { numberOfImpacts, mustBeReplaced: true };
            }
            return { numberOfImpacts, mustBeReplaced: null };
        default:
            return { numberOfImpacts, mustBeReplaced: null };
    }
}
export function impactsNumberEnumByType(model) {
    switch (model.type) {
        case RobberyBreakableElement.Door:
            return model.material === Material.Wood
                ? WOODEN_DOOR_IMPACTS_NUMBER
                : model.material === Material.Metal
                    ? METAL_DOOR_IMPACTS_NUMBER
                    : PVC_DOOR_IMPACTS_NUMBER;
        case RobberyBreakableElement.FrenchWindow:
            return model.material === Material.Wood
                ? WOOD_FRENCH_WINDOW_IMPACTS_NUMBER
                : model.material === Material.Metal
                    ? METAL_FRENCH_WINDOW_IMPACTS_NUMBER
                    : PVC_FRENCH_WINDOW_IMPACTS_NUMBER;
        case RobberyBreakableElement.GarageDoor:
            return model.material === Material.Wood
                ? WOODEN_GARAGE_DOOR_IMPACTS_NUMBER
                : model.material === Material.Metal
                    ? METAL_GARAGE_DOOR_IMPACTS_NUMBER
                    : PVC_GARAGE_DOOR_IMPACTS_NUMBER;
        case RobberyBreakableElement.Gate:
            return model.material === Material.Wood
                ? WOODEN_GATE_IMPACTS_NUMBER
                : model.material === Material.Metal || model.material === Material.Aluminium
                    ? METAL_GATE_IMPACTS_NUMBER
                    : PVC_GATE_IMPACTS_NUMBER;
        case RobberyBreakableElement.LittleGate:
            return model.material === Material.Wood
                ? WOODEN_LITTLE_GATE_IMPACTS_NUMBER
                : model.material === Material.Metal
                    ? METAL_LITTLE_GATE_IMPACTS_NUMBER
                    : PVC_LITTLE_GATE_IMPACTS_NUMBER;
        case RobberyBreakableElement.Shutter:
            return model.material === Material.Wood
                ? WOODEN_SHUTTER_IMPACTS_NUMBER
                : model.material === Material.Metal
                    ? METAL_SHUTTER_IMPACTS_NUMBER
                    : PVC_SHUTTER_IMPACTS_NUMBER;
        case RobberyBreakableElement.SlidingBayWindow:
            return SLIDING_BAY_IMPACTS_NUMBER;
        case RobberyBreakableElement.Window:
            return model.material === Material.Wood
                ? WOODEN_WINDOW_IMPACTS_NUMBER
                : model.material === Material.Metal
                    ? METAL_WINDOW_IMPACTS_NUMBER
                    : PVC_WINDOW_IMPACTS_NUMBER;
        default:
            return [];
    }
}
export function impactsNumberField(model, translate) {
    return {
        label: translate('claim.declaration.break_in_damages.form.impacts'),
        key: 'numberOfImpacts',
        value: model.numberOfImpacts,
        displayedValue: optionalTranslatableEnumTransform(model.numberOfImpacts, BREAK_IN_DAMAGES_NUMBER_OF_IMPACTS_TRAD_KEYS, translate),
        editable: {
            input: ModelFieldInputPreset.autoSelect(translateChoices(impactsNumberEnumByType(model), translate)),
            modelKey: 'numberOfImpacts',
            toPayload(numberOfImpacts) {
                return mustBeReplacedPayloadByTypeAndImpactsNumber(numberOfImpacts, model.type, model.material);
            },
        },
    };
}
export function editionMaterialChoicesByDamageType(model, translate) {
    switch (model.type) {
        case RobberyBreakableElement.Door:
            return translateChoices(DOOR_MATERIAL_CHOICES, translate);
        case RobberyBreakableElement.FrenchWindow:
            return translateChoices(FRENCH_WINDOW_MATERIAL_CHOICES, translate);
        case RobberyBreakableElement.GarageDoor:
            return translateChoices(GARAGE_DOOR_MATERIAL_CHOICES, translate);
        case RobberyBreakableElement.Gate:
            return translateChoices(GATE_MATERIAL_CHOICES, translate);
        case RobberyBreakableElement.LittleGate:
            return translateChoices(LITTLE_GATE_MATERIAL_CHOICES, translate);
        case RobberyBreakableElement.Shutter:
            return translateChoices(SHUTTER_MATERIAL_CHOICES, translate);
        case RobberyBreakableElement.Window:
            return translateChoices(WINDOW_MATERIAL_CHOICES, translate);
        default:
            return null;
    }
}
export function editionMaterialField(model, translate) {
    var _a;
    return {
        label: translate('model.break_in_damage.material'),
        key: 'material',
        value: model.material,
        editable: {
            input: ModelFieldInputPreset.autoSelect((_a = editionMaterialChoicesByDamageType(model, translate)) !== null && _a !== void 0 ? _a : []),
            modelKey: 'material',
        },
    };
}
export function damageSeverityField(model, translate) {
    return {
        label: translate('model.break_in_damage.severity'),
        key: 'damageSeverity',
        value: model.damageSeverity,
        displayedValue: optionalTranslatableEnumTransform(model.damageSeverity, BREAK_IN_DAMAGES_DAMAGE_SEVERITY_TRAD_KEYS, translate),
        editable: {
            input: ModelFieldInputPreset.autoSelect(translatableObjectToChoices(BREAK_IN_DAMAGES_DAMAGE_SEVERITY_TRAD_KEYS, translate)),
            modelKey: 'damageSeverity',
            toPayload(damageSeverity) {
                if (damageSeverity === DamageSeverity.Critical) {
                    return { damageSeverity, mustBeReplaced: true };
                }
                return { damageSeverity, mustBeReplaced: null };
            },
        },
    };
}
export function otherDamagesFields(model, translate) {
    var _a;
    const fields = [];
    fields.push({
        label: translate('model.break_in_damage.other_damages'),
        key: 'hasOtherDamages',
        value: model.hasOtherDamages,
        displayedValue: optionalBooleanTransform(model.hasOtherDamages, translate),
        editable: {
            input: ModelFieldInputPreset.boolean(translate),
            modelKey: 'hasOtherDamages',
        },
    });
    if (model.hasOtherDamages) {
        fields.push({
            label: translate('model.break_in_damage.other_damages.description'),
            key: 'otherDamages',
            value: model.otherDamages,
            displayedValue: (_a = model.otherDamages) !== null && _a !== void 0 ? _a : '-',
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'otherDamages',
            },
        });
    }
    return fields;
}
export function dimensionsFields(model, translate) {
    const fields = [];
    let width;
    let height;
    switch (model.type) {
        case RobberyBreakableElement.Door:
            width = model.doorBiggestBrokenWindowTileWidth;
            height = model.doorBiggestBrokenWindowTileHeight;
            break;
        case RobberyBreakableElement.GarageDoor:
            width = model.garageDoorWidth;
            break;
        case RobberyBreakableElement.Shutter:
            width = model.shutterWindowWidth;
            break;
        case RobberyBreakableElement.Window:
            width = model.windowWidth;
            height = model.windowHeight;
            break;
        case RobberyBreakableElement.FrenchWindow:
            width = model.frenchWindowWidth;
            height = model.frenchWindowHeight;
            break;
        case RobberyBreakableElement.SlidingBayWindow:
            width = model.slidingBayWidth;
            break;
        case RobberyBreakableElement.Fence:
            width = model.fenceLength;
            break;
        case RobberyBreakableElement.Gate:
            width = model.gateWidth;
            height = model.gateHeight;
            break;
        case RobberyBreakableElement.LittleGate:
            height = model.littleGateHeight;
            break;
        default:
            break;
    }
    if (width || height) {
        let widthLabel = '-';
        let heightLabel = '-';
        if (typeof width === 'string') {
            widthLabel = translate(`claim.declaration.break_in_damages.form.measurement.${width}`);
        }
        else if (typeof width === 'number') {
            widthLabel = `${width}m`;
        }
        if (typeof height === 'string') {
            heightLabel = translate(`claim.declaration.break_in_damages.form.measurement.${height}`);
        }
        else if (typeof height === 'number') {
            heightLabel = `${height}m`;
        }
        if (width) {
            fields.push({
                label: translate(typeof width === 'string'
                    ? 'model.external_damage.width'
                    : 'model.external_damage.length'),
                key: 'width',
                value: width,
                displayedValue: widthLabel,
            });
        }
        if (height) {
            fields.push({
                label: translate('model.external_damage.height'),
                key: 'height',
                value: height,
                displayedValue: heightLabel,
            });
        }
    }
    return fields;
}
export function breakInDamageEditionSeverityFields(model, translate, edition) {
    const fields = [];
    const severityField = edition &&
        model.type !== RobberyBreakableElement.Fence &&
        model.type !== RobberyBreakableElement.Other
        ? {
            label: translate('claim.declaration.break_in_damages.form.damage-severity'),
            key: 'damageSeverity',
            value: model.damageSeverity,
            editable: {
                input: ModelFieldInputPreset.autoSelect(translatableObjectToChoices(BREAK_IN_DAMAGES_DAMAGE_SEVERITY_TRAD_KEYS, translate)),
                modelKey: 'damageSeverity',
            },
        }
        : model.damageSeverity
            ? {
                label: translate('claim.declaration.break_in_damages.form.damage-severity'),
                key: 'damageSeverity',
                value: model.damageSeverity,
                displayedValue: translate(`claim.declaration.break_in_damages.form.damage-severity.${model.damageSeverity}`),
            }
            : null;
    if (severityField) {
        fields.push(severityField);
    }
    if (model.damageSeverity === DamageSeverity.Critical) {
        fields.push(...dimensionsFields(model, translate));
    }
    return fields;
}
export function repairStatusChoiceByDamageType(model) {
    switch (model.type) {
        case RobberyBreakableElement.Door:
            return REPAIR_STATUS_CHOICES_DOOR;
        case RobberyBreakableElement.Fence:
            return REPAIR_STATUS_CHOICES_FENCE;
        case RobberyBreakableElement.FrenchWindow:
            return REPAIR_STATUS_CHOICES_FRENCH_WINDOW;
        case RobberyBreakableElement.GarageDoor:
            return REPAIR_STATUS_CHOICES_GARAGE_DOOR;
        case RobberyBreakableElement.Gate:
            return REPAIR_STATUS_CHOICES_GATE;
        case RobberyBreakableElement.LittleGate:
            return REPAIR_STATUS_CHOICES_LITTLE_GATE;
        case RobberyBreakableElement.Other:
            return REPAIR_STATUS_CHOICES_OTHER;
        case RobberyBreakableElement.Shutter:
            return REPAIR_STATUS_CHOICES_SHUTTER;
        case RobberyBreakableElement.SlidingBayWindow:
            return REPAIR_STATUS_CHOICES_SLIDING_BAY;
        case RobberyBreakableElement.Window:
            return REPAIR_STATUS_CHOICES_WINDOW;
        default:
            return [];
    }
}
export function repairStatusTradKeyByDamageType(model, translate, EMPTY = '-') {
    if (model.hasBeenRepaired) {
        if (model.hasBeenRepaired === BreakInDamageRepairStatus.RepairedButNeedsMoreWork) {
            return translate('claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work');
        }
        switch (model.type) {
            case RobberyBreakableElement.Door:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.door');
            case RobberyBreakableElement.Fence:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.fence');
            case RobberyBreakableElement.FrenchWindow:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.french-window');
            case RobberyBreakableElement.GarageDoor:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.garage-door');
            case RobberyBreakableElement.Gate:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.gate');
            case RobberyBreakableElement.LittleGate:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.little-gate');
            case RobberyBreakableElement.Other:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.other');
            case RobberyBreakableElement.Shutter:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.shutter');
            case RobberyBreakableElement.SlidingBayWindow:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.sliding-bay');
            case RobberyBreakableElement.Window:
                return translate('claim.declaration.break_in_damages.form.repairs.repair-status.' +
                    model.hasBeenRepaired +
                    '.window');
            default:
                return EMPTY;
        }
    }
    return EMPTY;
}
export function fieldsByBreakInDamageType(model, translate, projection, edition = false) {
    switch (model.type) {
        case RobberyBreakableElement.Door:
            return doorBreakInDamageEditionFields(model, translate, projection, edition);
        case RobberyBreakableElement.Fence:
            return fenceBreakInDamageEditionFields(model, translate);
        case RobberyBreakableElement.FrenchWindow:
            return frenchWindowBreakInDamageEditionFields(model, translate, projection, edition);
        case RobberyBreakableElement.GarageDoor:
            return garageDoorBreakInDamageEditionFields(model, translate, projection, edition);
        case RobberyBreakableElement.Gate:
            return gateBreakInDamageEditionFields(model, translate, projection, edition);
        case RobberyBreakableElement.LittleGate:
            return littleGateBreakInDamageEditionFields(model, translate, projection, edition);
        case RobberyBreakableElement.Other:
            return otherBreakInDamageEditionFields(model, translate);
        case RobberyBreakableElement.Shutter:
            return shutterBreakInDamageEditionFields(model, translate, projection, edition);
        case RobberyBreakableElement.SlidingBayWindow:
            return slidingBayWindowBreakInDamageEditionFields(model, translate, projection, edition);
        case RobberyBreakableElement.Window:
            return windowBreakInDamageEditionFields(model, translate, projection, edition);
        default:
            return [];
    }
}
export const breakInDamageFields = (model, context) => {
    const { translate, projection, edition } = context;
    const title = breakInDamageTitle(model, translate);
    const fields = [];
    if ((projection === ModelFieldsProjection.Backoffice || projection === ModelFieldsProjection.Pdf) &&
        !model.id) {
        fields.push({
            label: translate('model.break_in_damage.type'),
            key: 'type',
            value: model.type,
            displayedValue: optionalTranslatableEnumTransform(model.type, ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS, translate),
                },
                modelKey: 'type',
            },
        });
    }
    const isNewBreakInDamage = model.type === RobberyBreakableElement.Door ||
        model.type === RobberyBreakableElement.Window ||
        model.type === RobberyBreakableElement.Fence ||
        model.type === RobberyBreakableElement.FrenchWindow ||
        model.type === RobberyBreakableElement.SlidingBayWindow ||
        model.type === RobberyBreakableElement.Shutter ||
        model.type === RobberyBreakableElement.GarageDoor ||
        model.type === RobberyBreakableElement.Other ||
        model.type === RobberyBreakableElement.Gate ||
        model.type === RobberyBreakableElement.LittleGate;
    if (isNewBreakInDamage) {
        if (!edition) {
            const damagedPartData = [];
            if (model.type === RobberyBreakableElement.Door) {
                if (model.doorType) {
                    damagedPartData.push(translate(DOOR_TYPE_TRAD_KEYS_AND_IMAGES[model.doorType].sumupTradKey));
                }
                if (model.doorType !== DoorType.Indoor) {
                    if (model.doorLeavesType) {
                        damagedPartData.push(translate(LEAVES_TYPE_SUMUP_TRAD_KEYS[model.doorLeavesType]));
                    }
                    if (model.material) {
                        damagedPartData.push(translate(MATERIAL_SUMUP_TRAD_KEYS[model.material]));
                    }
                }
            }
            else if (model.type === RobberyBreakableElement.FrenchWindow) {
                if (model.frenchWindowType) {
                    damagedPartData.push(translate(LEAVES_TYPE_SUMUP_TRAD_KEYS[model.frenchWindowType]));
                }
                if (model.material) {
                    damagedPartData.push(translate(MATERIAL_SUMUP_TRAD_KEYS[model.material]));
                }
            }
            else if (model.type === RobberyBreakableElement.Window) {
                if (model.windowType) {
                    damagedPartData.push(translate(LEAVES_TYPE_SUMUP_TRAD_KEYS[model.windowType]));
                }
                if (model.material) {
                    damagedPartData.push(translate(MATERIAL_SUMUP_TRAD_KEYS[model.material]));
                }
            }
            else if (model.type === RobberyBreakableElement.SlidingBayWindow) {
                if (model.slidingBayLeavesType) {
                    damagedPartData.push(translate(LEAVES_TYPE_SUMUP_TRAD_KEYS[model.slidingBayLeavesType]));
                }
            }
            else if (model.type === RobberyBreakableElement.Shutter) {
                if (model.shutterType) {
                    damagedPartData.push(translate(SHUTTER_TYPE_SUMUP_TRAD_KEYS[model.shutterType]));
                }
                if (model.flappyShutterLeaves) {
                    damagedPartData.push(translate(LEAVES_TYPE_SUMUP_TRAD_KEYS[model.flappyShutterLeaves]));
                }
                if (model.shutterBarTypes && model.shutterType === BreakInDamageShutterType.Bar) {
                    damagedPartData.push(translate(BAR_TYPE_SUMUP_TRAD_KEYS[model.shutterBarTypes]));
                }
                if (model.material && model.shutterType !== BreakInDamageShutterType.Bar) {
                    damagedPartData.push(translate(MATERIAL_SUMUP_TRAD_KEYS[model.material]));
                }
            }
            else if (model.type === RobberyBreakableElement.GarageDoor) {
                if (model.garageDoorType) {
                    damagedPartData.push(translate(GARAGE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES[model.garageDoorType].sumupTradKey));
                }
                if (model.material) {
                    damagedPartData.push(translate(MATERIAL_SUMUP_TRAD_KEYS[model.material]));
                }
            }
            else if (model.type === RobberyBreakableElement.Gate) {
                if (model.gateType) {
                    damagedPartData.push(translate(GATE_TYPE_TRAD_KEYS_AND_IMAGES[model.gateType].sumupTradKey));
                }
                if (model.gateFillType) {
                    damagedPartData.push(translate(GATE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES[model.gateFillType].sumupTradKey));
                }
                if (model.material) {
                    damagedPartData.push(translate(MATERIAL_SUMUP_TRAD_KEYS[model.material]));
                }
            }
            else if (model.type === RobberyBreakableElement.LittleGate) {
                if (model.material) {
                    damagedPartData.push(translate(MATERIAL_SUMUP_TRAD_KEYS[model.material]));
                }
            }
            else if (model.type === RobberyBreakableElement.Fence) {
                if (model.fenceType) {
                    damagedPartData.push(translate(DEFAULT_FENCE_TYPE_TRAD_KEYS[model.fenceType]));
                }
            }
            if (damagedPartData.length > 0) {
                fields.push({
                    label: translate('model.break_in_damage.damaged-part.description'),
                    key: 'damagedPartDescription',
                    value: true,
                    displayedValue: damagedPartData.join(', '),
                });
            }
        }
        fields.push(...fieldsByBreakInDamageType(model, translate, projection, edition));
        const shouldDisplayRepairsInfo = !((model.damageSeverity === DamageSeverity.Slight &&
            model.type !== RobberyBreakableElement.Fence &&
            model.type !== RobberyBreakableElement.Other) ||
            (model.type === RobberyBreakableElement.Door && model.doorType === DoorType.Indoor));
        if (shouldDisplayRepairsInfo) {
            fields.push({
                label: translate('model.break_in_damage.had-repairs'),
                key: 'hasBeenRepaired',
                value: model.hasBeenRepaired,
                displayedValue: repairStatusTradKeyByDamageType(model, translate),
                editable: {
                    input: ModelFieldInputPreset.autoSelect(translateChoices(repairStatusChoiceByDamageType(model), translate)),
                    modelKey: 'hasBeenRepaired',
                },
            });
            const hadRepairs = model.hasBeenRepaired &&
                (model.hasBeenRepaired === BreakInDamageRepairStatus.Repaired ||
                    model.hasBeenRepaired === BreakInDamageRepairStatus.RepairedButNeedsMoreWork);
            if (hadRepairs) {
                fields.push({
                    label: translate('model.break_in_damage.had-repairs.author'),
                    key: 'repairedBy',
                    value: model.repairedBy,
                    displayedValue: model.repairedBy
                        ? translate('model.break_in_damage.repaired-by.' + model.repairedBy)
                        : '-',
                    editable: {
                        input: ModelFieldInputPreset.autoSelect(translateChoices(REPAIRED_BY_CHOICES, translate)),
                        modelKey: 'repairedBy',
                        toPayload(repairedBy) {
                            if (model.hasBeenRepaired === BreakInDamageRepairStatus.Repaired) {
                                return {
                                    repairedBy,
                                    repairationType: repairedBy === BreakInDamageRepairPerson.Assistance
                                        ? BreakInDamageRepairationType.AssistanceDefinitive
                                        : BreakInDamageRepairationType.Definitive,
                                };
                            }
                            else if (model.hasBeenRepaired === BreakInDamageRepairStatus.RepairedButNeedsMoreWork) {
                                return {
                                    repairedBy,
                                    repairationType: repairedBy === BreakInDamageRepairPerson.Assistance
                                        ? BreakInDamageRepairationType.AssistanceTemporary
                                        : BreakInDamageRepairationType.Temporary,
                                };
                            }
                            return { repairedBy, repairationType: null };
                        },
                    },
                });
                if (model.repairedBy && model.repairedBy !== BreakInDamageRepairPerson.Assistance) {
                    if (projection !== ModelFieldsProjection.Pdf) {
                        fields.push(repairFileCollectionField(model, context, 'model.break_in_damage.definitive_repairs_invoice'));
                    }
                    if (edition &&
                        typeof model.repairFileCollection !== 'string' &&
                        model.repairFileCollection &&
                        model.repairFileCollection.uploadedAppFiles &&
                        model.repairFileCollection.uploadedAppFiles.length > 0) {
                        fields.push(repairAmountField(model, context));
                    }
                }
            }
            const hasQuote = fileCollectionFilesCount(model === null || model === void 0 ? void 0 : model.pendingRepairFileCollection) > 0;
            fields.push({
                label: translate('model.break_in_damage.still-needs-repairs'),
                key: 'needsRepair',
                value: model.needsRepair,
                displayedValue: model.needsRepair
                    ? hasQuote
                        ? translate('model.break_in_damage.still-needs-repairs.with-quote')
                        : translate('yes')
                    : translate('no'),
                editable: {
                    input: ModelFieldInputPreset.boolean(translate),
                    modelKey: 'needsRepair',
                },
            });
            if (model.needsRepair) {
                if (projection !== ModelFieldsProjection.Pdf) {
                    fields.push(pendingRepairFileCollectionField(model, context, 'model.break_in_damage.definitive_repairs_quote'));
                }
                if (fileCollectionFilesCount(model.pendingRepairFileCollection) > 0) {
                    fields.push(pendingRepairAmountField(model, context));
                }
            }
            const expertise = model.thresholdExceeded ||
                (model.refund &&
                    model.refund.parameters &&
                    model.refund.parameters.exclusionReason === ExclusionReason.TooComplexForRefund) ||
                (model.damageSeverity === DamageSeverity.Notable && model.hasOtherDamages === true);
            if (expertise && projection !== ModelFieldsProjection.Declaration) {
                fields.push({
                    label: translate('model.break_in_damage.expertise'),
                    key: 'expertise',
                    value: expertise,
                    displayedValue: translate('model.break_in_damage.expertise.explanation'),
                    layout: {
                        column: 1,
                    },
                });
            }
        }
        const shouldDisplayPicturesFields = !((model.type === RobberyBreakableElement.FrenchWindow &&
            model.damageSeverity === DamageSeverity.Slight) ||
            (model.type === RobberyBreakableElement.Gate &&
                model.damageSeverity === DamageSeverity.Slight) ||
            (model.type === RobberyBreakableElement.LittleGate &&
                model.damageSeverity === DamageSeverity.Slight) ||
            (model.type === RobberyBreakableElement.Shutter &&
                model.damageSeverity === DamageSeverity.Slight)) && projection !== ModelFieldsProjection.Pdf;
        if (shouldDisplayPicturesFields) {
            fields.push({
                label: translate('model.break_in_damage.documents.picture', fileCollectionFilesCount(model.pictureCollection)),
                value: model.pictureCollection,
                displayedValue: {
                    type: 'fileCollection',
                    fileCollection: model.pictureCollection,
                    uploadLabel: translate('model.picture.upload'),
                    emptyLabel: translate('model.pictures.empty'),
                    fileType: ClaimFileType.BreakInDamagePicture,
                    displayAsCount: true,
                },
                editable: {
                    input: {
                        type: ModelFieldInputType.FileCollection,
                    },
                    modelKey: 'pictureCollection',
                },
            });
        }
    }
    else {
        fields.push({
            label: translate('model.break_in_damage.repaired'),
            value: model.isRepaired,
            displayedValue: translate(model.isRepaired ? 'yes' : 'no'),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isRepaired',
                toPayload(isRepaired) {
                    if (isRepaired) {
                        return { isRepaired, mustBeReplaced: null, repairationType: null };
                    }
                    return { isRepaired, mustBeReplaced: false };
                },
            },
        });
        if (model.hasBeenRepaired === BreakInDamageRepairStatus.NotRepaired) {
            fields.push({
                label: translate('model.break_in_damage.must_be_replaced'),
                value: model.mustBeReplaced,
                displayedValue: optionalBooleanTransform(model.mustBeReplaced, translate),
                editable: {
                    input: ModelFieldInputPreset.boolean(translate),
                    modelKey: 'mustBeReplaced',
                },
            });
        }
        else if (model.hasBeenRepaired === BreakInDamageRepairStatus.Repaired ||
            model.hasBeenRepaired === BreakInDamageRepairStatus.RepairedButNeedsMoreWork) {
            fields.push({
                label: translate('model.break_in_damage.repair_type'),
                value: model.repairationType,
                displayedValue: optionalTranslatableEnumTransform(model.repairationType, BREAK_IN_DAMAGE_REPARATION_TYPES_TRAD_KEYS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Select,
                        choices: [],
                    },
                    modelKey: 'repairationType',
                },
            });
        }
        const repairationType = model.repairationType;
        const hasPendingRepairFile = fileCollectionFilesCount(model.pendingRepairFileCollection) > 0;
        const hasRepairFile = fileCollectionFilesCount(model.repairFileCollection) > 0;
        if (model.isRepaired) {
            fields.push({
                label: translate('model.break_in_damage.repair_type'),
                value: model.repairationType,
                displayedValue: optionalTranslatableEnumTransform(model.repairationType, BREAK_IN_DAMAGE_REPARATION_TYPES_TRAD_KEYS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Select,
                        choices: translatableObjectToChoices(BREAK_IN_DAMAGE_REPARATION_TYPES_TRAD_KEYS, translate),
                    },
                    modelKey: 'repairationType',
                },
            });
        }
        // Break in damage is not repaired and must be replaced
        // It has a replacement quote in *pending* repair file collection
        if ((!model.isRepaired && model.mustBeReplaced) || model.needsRepair) {
            if (projection !== ModelFieldsProjection.Pdf) {
                fields.push(pendingRepairFileCollectionField(model, context, 'model.break_in_damage.replacement_quote'));
            }
            if (hasPendingRepairFile) {
                fields.push(pendingRepairCompanyField(model, context));
                fields.push(pendingRepairAmountField(model, context));
            }
        }
        // Break in damage is repaired definitively
        // It has definitive repair invoice in repair file collection
        if (repairationType === BreakInDamageRepairationType.Definitive) {
            if (projection !== ModelFieldsProjection.Pdf) {
                fields.push(repairFileCollectionField(model, context, 'model.break_in_damage.definitive_repairs_invoice'));
            }
            if (hasRepairFile) {
                fields.push(repairCompanyField(model, context));
                fields.push(repairAmountField(model, context));
            }
        }
        // Break in damage is repaired temporarily
        // It has temporary repair invoice in repair file
        // and definitive repairs quote in *pending* repair file
        if (repairationType === BreakInDamageRepairationType.Temporary) {
            if (projection !== ModelFieldsProjection.Pdf) {
                fields.push(repairFileCollectionField(model, context, 'model.break_in_damage.temporary_repairs_invoice'));
            }
            if (hasRepairFile) {
                fields.push(repairCompanyField(model, context, 'model.break_in_damage.repair_company.temporary'));
                fields.push(repairAmountField(model, context, 'model.break_in_damage.repair_amount.temporary'));
            }
            if (projection !== ModelFieldsProjection.Pdf) {
                fields.push(pendingRepairFileCollectionField(model, context, 'model.break_in_damage.definitive_repairs_quote'));
            }
            if (hasPendingRepairFile) {
                fields.push(pendingRepairCompanyField(model, context, 'model.break_in_damage.repair_company.definitive'));
                fields.push(pendingRepairAmountField(model, context, 'model.break_in_damage.repair_amount.definitive'));
            }
        }
        // Break in damage is repaired temporarily by assistance
        // It has definitive repairs quote in *pending* repair file
        if (repairationType === BreakInDamageRepairationType.AssistanceTemporary) {
            if (projection !== ModelFieldsProjection.Pdf) {
                fields.push(pendingRepairFileCollectionField(model, context, 'model.break_in_damage.definitive_repairs_quote'));
            }
            if (hasPendingRepairFile) {
                fields.push(pendingRepairCompanyField(model, context, 'model.break_in_damage.repair_company.definitive'));
                fields.push(pendingRepairAmountField(model, context, 'model.break_in_damage.repair_amount.definitive'));
            }
        }
        if (projection !== ModelFieldsProjection.Pdf) {
            fields.push({
                label: translate('model.pictures'),
                value: model.pictureCollection,
                displayedValue: {
                    type: 'fileCollection',
                    fileCollection: model.pictureCollection,
                    uploadLabel: translate('model.picture.upload'),
                    emptyLabel: translate('model.pictures.empty'),
                    fileType: ClaimFileType.BreakInDamagePicture,
                    displayAsCount: true,
                },
                editable: {
                    input: {
                        type: ModelFieldInputType.FileCollection,
                    },
                    modelKey: 'pictureCollection',
                },
            });
        }
    }
    return {
        title,
        fields,
        model,
        editable: { constraints: breakInDamageConstraints },
        defaultPayload: { type: model.type },
    };
};
