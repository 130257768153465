import { ClaimLanguage } from '@shared/types/api/user';
import { crispSetNickname } from '@declaration/helpers/crisp';
export const name = 'user';
export const namespaced = true;
export const state = () => ({
    firstname: null,
    lastname: null,
    email: null,
    roles: [],
    language: ClaimLanguage.French,
});
export const getters = {};
export const actions = {
    async getUserInfo({ commit }) {
        const userInfo = await this.$axios.$get('/auth/me');
        commit('SET_USER_INFO', userInfo);
    },
    async setLanguage({ dispatch }, payload) {
        await this.$axios.$put('/auth/me/language', { lang: payload });
        dispatch('getUserInfo');
    },
};
export const mutations = {
    SET_USER_INFO(state, userInfo) {
        state.firstname = userInfo.firstname;
        state.lastname = userInfo.lastname;
        state.email = userInfo.email;
        state.roles = userInfo.roles;
        state.language = userInfo.selectedLanguage;
        crispSetNickname(`${userInfo.firstname} ${userInfo.lastname}`);
    },
};
