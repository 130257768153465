import { CONFIG } from '@shared/environment/environment';
import { getValidAccessToken } from '@declaration/services/accessTokenValidation';
import { handleMaintenance } from '@declaration/services/handleMaintenance';
const URLS_WITHOUT_AUTH = /(^\/auth\/jwt\/)|(^\/pwdless\/auth)/;
function isHttpQueryWithoutAuth(url) {
    return !!url && (url === null || url === void 0 ? void 0 : url.match(URLS_WITHOUT_AUTH)) !== null;
}
export default function ({ $axios, route, store, app: { localePath }, redirect }) {
    const { insurerConfig } = store.app.$config;
    $axios.setBaseURL(CONFIG.apiUrl);
    $axios.onRequest((config) => {
        config.headers.common['Claims-Frontend'] = 'declaration';
        // todo remove this when insurer and customer naming is correct
        config.headers.common['Claims-Insurer'] = insurerConfig.id.includes('_')
            ? insurerConfig.id.split('_')[1]
            : insurerConfig.id;
        if (isHttpQueryWithoutAuth(config.url)) {
            return config;
        }
        return getValidAccessToken($axios, store)
            .then((access) => {
            if (access) {
                config.headers.common['API-Authorization'] = `Bearer ${access}`;
            }
            return Promise.resolve(config);
        })
            .catch((error) => {
            /* eslint-disable-next-line */
            console.error(error);
            throw error;
        });
    });
    $axios.onError((error) => {
        var _a, _b;
        if (typeof error.response === 'undefined') {
            // Network error or CORS preflight request failed
            handleMaintenance(store);
            return;
        }
        if (isHttpQueryWithoutAuth(error.config.url)) {
            return;
        }
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            if (localePath(route.path) !== localePath({ name: 'ml' })) {
                redirect(localePath('login'));
            }
        }
        else if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 503) {
            handleMaintenance(store);
        }
    });
}
