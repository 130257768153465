import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalBooleanTransform, optionalString } from '@shared/utils/transform';
export const chimneyFields = (model, { translate }) => {
    var _a;
    return [
        {
            label: translate('model.external_damage.damaged_caps_count'),
            value: model.damagedCapsCount,
            displayedValue: optionalString((_a = model.damagedCapsCount) === null || _a === void 0 ? void 0 : _a.toString()),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                modelKey: 'damagedCapsCount',
            },
        },
        {
            label: 'Souche endommagée',
            value: model.isStackDamaged,
            displayedValue: optionalBooleanTransform(model.isStackDamaged, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Checkbox,
                },
                modelKey: 'isStackDamaged',
            },
        },
    ];
};
