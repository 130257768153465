import { ClaimTypeTrigram, StormSnowHailDamageCauseType, } from './claim';
import { Material, Measurement } from '@shared/types/api/breakInDamage';
export const ExternalDamageOrigin = {
    Storm: StormSnowHailDamageCauseType.Storm,
    Snow: StormSnowHailDamageCauseType.Snow,
    Hail: StormSnowHailDamageCauseType.Hail,
    Fire: ClaimTypeTrigram.Fire,
};
export var GarageDoorType;
(function (GarageDoorType) {
    GarageDoorType["Tipper"] = "basculante";
    GarageDoorType["Sectional"] = "sectionnelle";
    GarageDoorType["Sliding"] = "coulissante";
    GarageDoorType["Rolling"] = "enroulement";
    GarageDoorType["Flappy"] = "battante";
    GarageDoorType["Folding"] = "pliante";
})(GarageDoorType || (GarageDoorType = {}));
export var TileDisplacementCount;
(function (TileDisplacementCount) {
    TileDisplacementCount["Ten"] = "dizaine";
    TileDisplacementCount["FiftyInRoofPart"] = "cinquantaine-sur-partie";
    TileDisplacementCount["FiftyInWholeRoof"] = "cinquantaine-sur-ensemble";
})(TileDisplacementCount || (TileDisplacementCount = {}));
export var GutterMaterial;
(function (GutterMaterial) {
    GutterMaterial["Zinc"] = "zinc";
    GutterMaterial["PVC"] = "pvc";
    GutterMaterial["Alumninium"] = "aluminium";
})(GutterMaterial || (GutterMaterial = {}));
export var FenceType;
(function (FenceType) {
    FenceType["WireMesh"] = "grillage";
    FenceType["WoodPanel"] = "panneaux-de-bois";
    FenceType["PVC"] = "pvc";
    FenceType["WirePanel"] = "panneaux-grillages";
    FenceType["Other"] = "autre";
})(FenceType || (FenceType = {}));
export var GateType;
(function (GateType) {
    GateType["Sliding"] = "coulissant";
    GateType["Flappy"] = "battant";
})(GateType || (GateType = {}));
export var GateMaterial;
(function (GateMaterial) {
    GateMaterial[GateMaterial["Acier"] = Material.Steel] = "Acier";
    GateMaterial[GateMaterial["PVC"] = Material.PVC] = "PVC";
    GateMaterial[GateMaterial["Bois"] = Material.Wood] = "Bois";
    GateMaterial[GateMaterial["Alumninium"] = Material.Aluminium] = "Alumninium";
})(GateMaterial || (GateMaterial = {}));
export var GateFillStyle;
(function (GateFillStyle) {
    GateFillStyle["Plein"] = "plein";
    GateFillStyle["Ajoure"] = "ajoure";
    GateFillStyle["SemiAjoure"] = "semi-ajoure";
})(GateFillStyle || (GateFillStyle = {}));
export var BasinMaterial;
(function (BasinMaterial) {
    BasinMaterial["Metal"] = "metal";
    BasinMaterial["Resin"] = "resin";
    BasinMaterial["Ceramic"] = "ceramic";
    BasinMaterial["Unknown"] = "unknown";
})(BasinMaterial || (BasinMaterial = {}));
export const LittleGateSize = {
    Small: Measurement.LessThan120cm,
    Medium: Measurement.Between120And140cm,
    High: Measurement.Between140And180cm,
    VeryHigh: Measurement.MoreThan180cm,
};
export var WindowType;
(function (WindowType) {
    WindowType["Window"] = "fenetre";
    WindowType["RoofWindow"] = "fenetre-de-toit";
    WindowType["SlidingBay"] = "baie-coulissante";
    WindowType["FrenchWindow"] = "porte-fenetre";
})(WindowType || (WindowType = {}));
export var ShutterType;
(function (ShutterType) {
    ShutterType["Flappy"] = "battant";
    ShutterType["Rolling"] = "roulant";
    ShutterType["Folding"] = "pliant";
})(ShutterType || (ShutterType = {}));
export var ShutterMaterial;
(function (ShutterMaterial) {
    ShutterMaterial["Wood"] = "wood";
    ShutterMaterial["PVC"] = "pvc";
    ShutterMaterial["Alumninium"] = "aluminium";
    ShutterMaterial["Metal"] = "metal";
})(ShutterMaterial || (ShutterMaterial = {}));
export var PergolaMaterial;
(function (PergolaMaterial) {
    PergolaMaterial[PergolaMaterial["Wood"] = Material.Wood] = "Wood";
    PergolaMaterial[PergolaMaterial["PVC"] = Material.PVC] = "PVC";
    PergolaMaterial[PergolaMaterial["Aluminium"] = Material.Aluminium] = "Aluminium";
    PergolaMaterial[PergolaMaterial["Steel"] = Material.Steel] = "Steel";
})(PergolaMaterial || (PergolaMaterial = {}));
export var PergolaDamageType;
(function (PergolaDamageType) {
    PergolaDamageType["Minor"] = "minor";
    PergolaDamageType["Destroyed"] = "destroyed";
})(PergolaDamageType || (PergolaDamageType = {}));
export var LightSourceType;
(function (LightSourceType) {
    LightSourceType["BollardLight"] = "bollard-light";
    LightSourceType["WallLamp"] = "wall-lamp";
    LightSourceType["OutdoorSpotlight"] = "outdoor-spotlight";
    LightSourceType["StreetLamp"] = "street-lamp";
})(LightSourceType || (LightSourceType = {}));
export var VerandaPanelMaterial;
(function (VerandaPanelMaterial) {
    VerandaPanelMaterial["Glass"] = "glass";
    VerandaPanelMaterial["Polycarbonate"] = "polycarbonate";
    VerandaPanelMaterial["SandwichPanel"] = "sandwich-panel";
    VerandaPanelMaterial["Other"] = "other";
})(VerandaPanelMaterial || (VerandaPanelMaterial = {}));
export var VerandaPanelGlazingType;
(function (VerandaPanelGlazingType) {
    VerandaPanelGlazingType["Simple"] = "simple";
    VerandaPanelGlazingType["Double"] = "double";
    VerandaPanelGlazingType["Unknown"] = "unknown";
})(VerandaPanelGlazingType || (VerandaPanelGlazingType = {}));
export var VerandaGutterMaterial;
(function (VerandaGutterMaterial) {
    VerandaGutterMaterial["Zinc"] = "zinc";
    VerandaGutterMaterial["Pvc"] = "pvc";
    VerandaGutterMaterial["Aluminum"] = "aluminum";
})(VerandaGutterMaterial || (VerandaGutterMaterial = {}));
export var DoorMaterial;
(function (DoorMaterial) {
    DoorMaterial["Wood"] = "wood";
    DoorMaterial["PVC"] = "pvc";
    DoorMaterial["Metal"] = "metal";
})(DoorMaterial || (DoorMaterial = {}));
export var DoorImpactsNumber;
(function (DoorImpactsNumber) {
    DoorImpactsNumber["NoImpact"] = "no-impact";
    DoorImpactsNumber["AtLeastOne"] = "at-least-one";
})(DoorImpactsNumber || (DoorImpactsNumber = {}));
export var DoorLeavesType;
(function (DoorLeavesType) {
    DoorLeavesType["OneLeaf"] = "one-leaf";
    DoorLeavesType["TwoLeaves"] = "two-leaves";
})(DoorLeavesType || (DoorLeavesType = {}));
export var DoorGlazingType;
(function (DoorGlazingType) {
    DoorGlazingType["Simple"] = "simple";
    DoorGlazingType["Double"] = "double";
    DoorGlazingType["Triple"] = "triple";
    DoorGlazingType["Unknown"] = "unknown";
})(DoorGlazingType || (DoorGlazingType = {}));
export var DoorGlassType;
(function (DoorGlassType) {
    DoorGlassType["Laminated"] = "laminated";
    DoorGlassType["NoLaminated"] = "non-laminated";
    DoorGlassType["Unknown"] = "unknown";
})(DoorGlassType || (DoorGlassType = {}));
export var ExternalDamageLocalizationSlug;
(function (ExternalDamageLocalizationSlug) {
    ExternalDamageLocalizationSlug["GardenTerrace"] = "jardin-terrasse";
    ExternalDamageLocalizationSlug["Roof"] = "toit";
    ExternalDamageLocalizationSlug["Shutters"] = "volets";
    ExternalDamageLocalizationSlug["ExternalPersonalProperty"] = "mobilier-exterieur";
    ExternalDamageLocalizationSlug["FenceAndGate"] = "cloture-portail";
    ExternalDamageLocalizationSlug["Chimney"] = "cheminee";
    ExternalDamageLocalizationSlug["Veranda"] = "veranda";
    ExternalDamageLocalizationSlug["GarageDoor"] = "porte-de-garage";
    ExternalDamageLocalizationSlug["Outbuilding"] = "dependance";
    ExternalDamageLocalizationSlug["Window"] = "fenetre";
    ExternalDamageLocalizationSlug["FrontWall"] = "facade";
    ExternalDamageLocalizationSlug["Plumbing"] = "plomberie";
    ExternalDamageLocalizationSlug["Electricity"] = "electricite";
    ExternalDamageLocalizationSlug["PhotovoltaicPanel"] = "panneaux-solaires";
    ExternalDamageLocalizationSlug["Door"] = "door";
    ExternalDamageLocalizationSlug["Other"] = "autre";
})(ExternalDamageLocalizationSlug || (ExternalDamageLocalizationSlug = {}));
export var ExternalDamageSlug;
(function (ExternalDamageSlug) {
    ExternalDamageSlug["Fence"] = "cloture";
    ExternalDamageSlug["Gate"] = "portail";
    ExternalDamageSlug["LittleGate"] = "portillon";
    ExternalDamageSlug["StoneWork"] = "maconnerie";
    ExternalDamageSlug["GardenShelter"] = "abri-de-jardin";
    ExternalDamageSlug["SwimpoolCover"] = "couverture-de-piscine";
    ExternalDamageSlug["Plants"] = "vegetaux";
    ExternalDamageSlug["GarageDoor"] = "porte-de-garage";
    ExternalDamageSlug["TerraceFloor"] = "sol-de-terrasse";
    ExternalDamageSlug["Pergola"] = "pergola";
    ExternalDamageSlug["Awning"] = "stores-exterieurs";
    ExternalDamageSlug["Veranda"] = "veranda";
    ExternalDamageSlug["RoofHole"] = "trou-toiture";
    ExternalDamageSlug["Roof"] = "toiture";
    ExternalDamageSlug["TileDisplacement"] = "deplacement-tuile";
    ExternalDamageSlug["Framework"] = "charpente";
    ExternalDamageSlug["Antenna"] = "antennes";
    ExternalDamageSlug["Chimney"] = "cheminee";
    ExternalDamageSlug["Gutter"] = "gouttiere";
    ExternalDamageSlug["Shutters"] = "volets";
    ExternalDamageSlug["Window"] = "fenetre";
    ExternalDamageSlug["FrontWall"] = "facade";
    ExternalDamageSlug["ExternalPersonalProperty"] = "mobilier-exterieur";
    ExternalDamageSlug["Door"] = "porte";
    ExternalDamageSlug["VisiblePipe"] = "tuyau-apparent";
    ExternalDamageSlug["Basin"] = "evier-vasque";
    ExternalDamageSlug["Faucet"] = "robinet-migiteur";
    ExternalDamageSlug["AerationGrid"] = "grille-aeration";
    ExternalDamageSlug["LightSource"] = "point-lumineux";
    ExternalDamageSlug["OutletOrSwitch"] = "prise-interrupteur";
    ExternalDamageSlug["ElectricalSheath"] = "gaine-electrique";
    ExternalDamageSlug["Barbecue"] = "barbecue";
    ExternalDamageSlug["Playground"] = "jeux-exterieur";
    ExternalDamageSlug["GardenLounge"] = "salon-jardin";
    ExternalDamageSlug["PizzaOven"] = "four-pizza";
    ExternalDamageSlug["PhotovoltaicPanel"] = "panneaux-solaires";
    ExternalDamageSlug["Other"] = "autre";
})(ExternalDamageSlug || (ExternalDamageSlug = {}));
export const ExternalDamageSlugWithProtectionNeeded = [
    ExternalDamageSlug.GarageDoor,
    ExternalDamageSlug.Pergola,
    ExternalDamageSlug.RoofHole,
    ExternalDamageSlug.TileDisplacement,
    ExternalDamageSlug.Framework,
];
export function shutterCanBeMotorized(type) {
    return type === ShutterType.Rolling;
}
