import { GarageDoorType } from '@shared/types/api/externalDamage';
export const GARAGE_DOOR_TYPE_IMAGES_AND_TRAD_KEYS = {
    [GarageDoorType.Tipper]: {
        image: require('@declaration/assets/images/externalDamage/garageDoor/basculante.svg'),
        tradKey: 'claim.declaration.external_damages.form.garage_door.tipper',
        sumupTradKey: 'claim.declaration.external_damages.form.garage_door.tipper.sumup',
    },
    [GarageDoorType.Sectional]: {
        image: require('@declaration/assets/images/externalDamage/garageDoor/sectionnelle.svg'),
        tradKey: 'claim.declaration.external_damages.form.garage_door.sectional',
        sumupTradKey: 'claim.declaration.external_damages.form.garage_door.sectional.sumup',
    },
    [GarageDoorType.Sliding]: {
        image: require('@declaration/assets/images/externalDamage/garageDoor/coulissante.svg'),
        tradKey: 'claim.declaration.external_damages.form.garage_door.sliding',
        sumupTradKey: 'claim.declaration.external_damages.form.garage_door.sliding.sumup',
    },
    [GarageDoorType.Rolling]: {
        image: require('@declaration/assets/images/externalDamage/garageDoor/enroulement.svg'),
        tradKey: 'claim.declaration.external_damages.form.garage_door.rolling',
        sumupTradKey: 'claim.declaration.external_damages.form.garage_door.rolling.sumup',
    },
    [GarageDoorType.Flappy]: {
        image: require('@declaration/assets/images/externalDamage/garageDoor/battante.svg'),
        tradKey: 'claim.declaration.external_damages.form.garage_door.flappy',
        sumupTradKey: 'claim.declaration.external_damages.form.garage_door.flappy.sumup',
    },
    [GarageDoorType.Folding]: {
        image: require('@declaration/assets/images/externalDamage/garageDoor/pliante.svg'),
        tradKey: 'claim.declaration.external_damages.form.garage_door.folding',
        sumupTradKey: 'claim.declaration.external_damages.form.garage_door.folding.sumup',
    },
};
