import { ApiEntityType } from '@shared/types/api/apiEntityType';
function isExpertAppointment(appointment) {
    return appointment['@type'] === ApiEntityType.ExpertAppointment;
}
function isArtisanAppointment(appointment) {
    return appointment['@type'] === ApiEntityType.ArtisanAppointment;
}
function getArtisanAppointmentFromResponse(appointmentResponse) {
    if (!appointmentResponse || !isArtisanAppointment(appointmentResponse)) {
        return null;
    }
    const { startDateTime, endDateTime, artisanCompanyName, artisanAppointmentLabel, artisanExtranetLink, } = appointmentResponse;
    return {
        type: ApiEntityType.ArtisanAppointment,
        startDateTime,
        endDateTime,
        artisanCompanyName,
        artisanAppointmentLabel,
        artisanExtranetLink,
    };
}
function getExpertAppointmentFromResponse(appointmentResponse) {
    if (!appointmentResponse || !isExpertAppointment(appointmentResponse)) {
        return null;
    }
    const { startDateTime, endDateTime, expertSociety, expertAppointmentMode } = appointmentResponse;
    return {
        type: ApiEntityType.ExpertAppointment,
        startDateTime,
        endDateTime,
        expertSociety,
        expertAppointmentMode,
    };
}
export function getClaimInfoFromClaimResponse({ id, caseNumber, status, exit, forcedExit, nextStep, stepStack, damageDate, expert, isAdvisorCallWanted, isVisioConferenceAccepted, qualificationCertifiedAt, damagesValidatedAt, chosenCompensationOption, isCompensationOptionAccepted, termsAndConditionsAccepted, invoiceFile, irsi, contract, artisanAppointment, expertAppointment, immediatePayout, userContact, thirdPartyIncidentDescription, isPendingRepairQuotePending, immediateCompensationAmount, delayedCompensationAmount, delayedPayout, isMissingInitialFileStepValidated, noSuitableExpertTimeSlot, noSuitableExpertTimeSlotReason, renEmailSent, renContactInformation, provision, claimWarnings, expertRecontactForAppointment, userComment, }) {
    return {
        id,
        caseNumber,
        status,
        exit,
        forcedExit,
        nextStep,
        stepStack,
        damageDate,
        expert,
        isAdvisorCallWanted,
        isVisioConferenceAccepted,
        qualificationCertifiedAt,
        damagesValidatedAt,
        chosenCompensationOption,
        isCompensationOptionAccepted,
        termsAndConditionsAccepted,
        invoiceFile,
        irsi,
        contractIri: contract,
        artisanAppointment: getArtisanAppointmentFromResponse(artisanAppointment),
        expertAppointment: getExpertAppointmentFromResponse(expertAppointment),
        immediatePayout,
        immediateCompensationAmount,
        delayedCompensationAmount,
        delayedPayout,
        userContact,
        thirdPartyIncidentDescription,
        isPendingRepairQuotePending,
        isMissingInitialFileStepValidated,
        noSuitableExpertTimeSlot,
        noSuitableExpertTimeSlotReason,
        renEmailSent,
        renContactInformation,
        provision,
        warnings: claimWarnings !== null && claimWarnings !== void 0 ? claimWarnings : [],
        expertRecontactForAppointment,
        userComment,
    };
}
export function getClaimPostDataFromInfo({ status, stepStack, damageDate, isAdvisorCallWanted, isVisioConferenceAccepted, damagesValidatedAt, qualificationCertifiedAt, chosenCompensationOption, isCompensationOptionAccepted, termsAndConditionsAccepted, invoiceFile, userContact, thirdPartyIncidentDescription, isMissingInitialFileStepValidated, noSuitableExpertTimeSlot, noSuitableExpertTimeSlotReason, renContactInformation, expertRecontactForAppointment, userComment, }) {
    var _a;
    return {
        status,
        stepStack,
        damageDate,
        isAdvisorCallWanted,
        damagesValidatedAt,
        qualificationCertifiedAt,
        chosenCompensationOption,
        isCompensationOptionAccepted,
        isVisioConferenceAccepted,
        termsAndConditionsAccepted,
        invoiceFile: (_a = invoiceFile === null || invoiceFile === void 0 ? void 0 : invoiceFile.id) !== null && _a !== void 0 ? _a : null,
        noSuitableExpertTimeSlot,
        noSuitableExpertTimeSlotReason,
        userContact,
        thirdPartyIncidentDescription,
        isMissingInitialFileStepValidated,
        renContactInformation,
        expertRecontactForAppointment,
        userComment,
    };
}
