import { objectToTranslatableChoices } from '@shared/choice';
export var CauseEquipmentType;
(function (CauseEquipmentType) {
    CauseEquipmentType["CircuitBreaker"] = "disjoncteur";
    CauseEquipmentType["ElectricalPanel"] = "tableau-electrique";
    CauseEquipmentType["ElectricalGrid"] = "reseau-electrique";
    CauseEquipmentType["Unknown"] = "je-ne-sais-pas";
})(CauseEquipmentType || (CauseEquipmentType = {}));
export var TypeOfElectricalDamage;
(function (TypeOfElectricalDamage) {
    TypeOfElectricalDamage["RealsEstate"] = "real_estate";
    TypeOfElectricalDamage["PersonalProperties"] = "personal_properties";
    TypeOfElectricalDamage["Food"] = "food";
})(TypeOfElectricalDamage || (TypeOfElectricalDamage = {}));
export var ElectricalDamageNumberOfEquipments;
(function (ElectricalDamageNumberOfEquipments) {
    ElectricalDamageNumberOfEquipments[ElectricalDamageNumberOfEquipments["Zero"] = 0] = "Zero";
    ElectricalDamageNumberOfEquipments[ElectricalDamageNumberOfEquipments["One"] = 1] = "One";
    ElectricalDamageNumberOfEquipments[ElectricalDamageNumberOfEquipments["Two"] = 2] = "Two";
    ElectricalDamageNumberOfEquipments[ElectricalDamageNumberOfEquipments["Three"] = 3] = "Three";
    ElectricalDamageNumberOfEquipments[ElectricalDamageNumberOfEquipments["Four"] = 4] = "Four";
    ElectricalDamageNumberOfEquipments[ElectricalDamageNumberOfEquipments["FiveAndMore"] = 5] = "FiveAndMore";
})(ElectricalDamageNumberOfEquipments || (ElectricalDamageNumberOfEquipments = {}));
export const CAUSE_EQUIPMENT_TRAD_KEYS = {
    [CauseEquipmentType.CircuitBreaker]: 'claim.qualification.electrical.cause_equipment.circuit_breaker',
    [CauseEquipmentType.ElectricalPanel]: 'claim.qualification.electrical.cause_equipment.electrical_panel',
    [CauseEquipmentType.ElectricalGrid]: 'claim.qualification.electrical.cause_equipment.electrical_grid',
    [CauseEquipmentType.Unknown]: 'claim.qualification.electrical.cause_equipment.unknown',
};
export const CAUSE_EQUIPMENT_CHOICES = objectToTranslatableChoices(CAUSE_EQUIPMENT_TRAD_KEYS);
export var ElectricalDamageType;
(function (ElectricalDamageType) {
    ElectricalDamageType["Alarm"] = "alarme";
    ElectricalDamageType["GarageDoorMotorization"] = "motorisation-porte-garage";
    ElectricalDamageType["GateMotorization"] = "motorisation-portail";
    ElectricalDamageType["HeatPump"] = "pompe-a-chaleur";
    ElectricalDamageType["Intercom"] = "interphone";
    ElectricalDamageType["Other"] = "autre";
    ElectricalDamageType["PhotovoltaicPanels"] = "panneaux-photovoltaiques";
    ElectricalDamageType["RollingShuttersMotorization"] = "motorisation-volets-roulants";
    ElectricalDamageType["Swimpool"] = "piscine";
})(ElectricalDamageType || (ElectricalDamageType = {}));
export var ElectricalDamageRepairableStatus;
(function (ElectricalDamageRepairableStatus) {
    ElectricalDamageRepairableStatus["Repairable"] = "repairable";
    ElectricalDamageRepairableStatus["Irreparable"] = "irreparable";
})(ElectricalDamageRepairableStatus || (ElectricalDamageRepairableStatus = {}));
