export const GUTTER_TITLE = {
    horizontal: 'claim.declaration.external_damages.form.gutter.horizontal.title',
    vertical: 'claim.declaration.external_damages.form.gutter.vertical.title',
};
export const GUTTER_LABEL = {
    horizontal: 'claim.declaration.external_damages.form.gutter.horizontal.is_damaged',
    vertical: 'claim.declaration.external_damages.form.gutter.vertical.is_damaged',
};
export const GUTTER_MATERIAL_LABEL = 'claim.declaration.external_damages.form.gutter.material';
export const GUTTER_LENGTH_LABEL = 'claim.declaration.external_damages.form.gutter.length';
