import { DeclarationPage } from '@declaration/pages';
import { DeclarationSection } from '@declaration/sections';
import { pageFromStepConfiguration, sectionFromStepConfiguration, StepConfigurations, } from '@declaration/stepConfigurations';
import { Step } from '@shared/claim/steps';
import { stepDisplayName } from '@shared/helpers/steps';
import { crispPushData } from '@declaration/helpers/crisp';
import { tagCommanderReload, tagCommanderSetVariable } from '@declaration/helpers/tagCommander';
import { INSURER } from '@shared/insurer';
export const name = 'pages';
export const namespaced = true;
export const state = () => ({
    activeStep: null,
    currentPageNeedValidation: false,
    pageOverride: null,
    declarationComponentStack: [],
});
export var Certification;
(function (Certification) {
    Certification["Sumup"] = "sumup";
    Certification["Qualification"] = "qualification";
})(Certification || (Certification = {}));
export const getters = {
    currentPage(state, _getters, rootState, rootGetters) {
        var _a;
        if (state.pageOverride) {
            return state.pageOverride;
        }
        const currentStep = (_a = state.activeStep) !== null && _a !== void 0 ? _a : rootGetters['claim/currentStep'];
        return pageFromStepConfiguration(StepConfigurations[currentStep], rootState.qualification.typeTrigram, INSURER);
    },
    activeQuestion(state) {
        if (!state.activeStep) {
            return null;
        }
        const configuration = StepConfigurations[state.activeStep];
        if (configuration.page !== DeclarationPage.Questions) {
            return null;
        }
        return configuration.question;
    },
    activeSection(state, _getters, rootState) {
        if (!state.activeStep) {
            return null;
        }
        const configuration = StepConfigurations[state.activeStep];
        if (configuration.page !== DeclarationPage.Questions) {
            return null;
        }
        return sectionFromStepConfiguration(configuration, rootState.qualification.typeTrigram, INSURER);
    },
    shouldCertifySumup(state, _getters, rootState) {
        return (state.activeStep === Step.Sumup ||
            // We want to display sumup info even if user is looking at (activeStep) history steps
            rootState.claim.claim.nextStep === Step.Sumup);
    },
    shouldCertifyQualification(state, _getters, rootState) {
        return (state.activeStep === Step.QualificationCertification ||
            // We want to display sumup info even if user is looking at (activeStep) history steps
            rootState.claim.claim.nextStep === Step.QualificationCertification);
    },
    shouldCertify(_state, getters) {
        if (getters.shouldCertifySumup) {
            return Certification.Sumup;
        }
        if (getters.shouldCertifyQualification) {
            return Certification.Qualification;
        }
        return null;
    },
    isUnavailable(state) {
        return state.activeStep === Step.Unavailable;
    },
    previousStep(state, _getters, rootState) {
        const stack = rootState.claim.claim.nextStep
            ? [...rootState.claim.claim.stepStack, rootState.claim.claim.nextStep]
            : rootState.claim.claim.stepStack;
        const activeStep = state.activeStep;
        const activeStepIndex = stack.findIndex((step) => activeStep === step);
        if (activeStepIndex < 1) {
            return null;
        }
        let previousStep = null;
        for (let i = activeStepIndex - 1; i >= 0; i--) {
            // If qualification page, we ignore step that have no question associated (e.g. details steps)
            const previousStackStep = stack[i];
            if (!previousStackStep) {
                break;
            }
            const configuration = StepConfigurations[previousStackStep];
            if (
            // Certification can be a previous step even if no question is associated
            previousStackStep !== Step.QualificationCertification &&
                configuration.page === DeclarationPage.Questions &&
                configuration.question === null) {
                continue;
            }
            previousStep = previousStackStep;
            break;
        }
        return previousStep;
    },
    isPreviousStepLocked(_state, getters, rootState) {
        const previousStep = getters.previousStep;
        if (!previousStep) {
            return false;
        }
        const qualificationCertified = !!rootState.claim.claim.qualificationCertifiedAt;
        if (!qualificationCertified) {
            return false;
        }
        // Certification is locked if already certified
        if (previousStep === Step.QualificationCertification) {
            return true;
        }
        const configuration = StepConfigurations[previousStep];
        if (configuration.page !== DeclarationPage.Questions) {
            return false;
        }
        return configuration.section === DeclarationSection.QualificationSection;
    },
};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    selectActiveStep({ commit }, activeStep) {
        commit('SET_ACTIVE_STEP', activeStep);
    },
    goBackOneStep({ commit, getters }) {
        const previousStep = getters.previousStep;
        if (!previousStep) {
            return this.$router.back();
        }
        commit('SET_ACTIVE_STEP', previousStep);
    },
    updateStoreWithClaimResponse({ commit, rootState, state }, { claimResponse, shouldUpdatePage }) {
        var _a, _b, _c;
        const stepStack = claimResponse.stepStack;
        const nextStep = claimResponse.nextStep;
        // Redirect to homepage at declaration "exit" (index step sent from BRMS)
        if (nextStep === Step.Index) {
            (_a = this.app.router) === null || _a === void 0 ? void 0 : _a.push(this.app.localePath('index'));
            return;
        }
        // recreate the component stack from steps
        const newDeclarationComponentStack = [];
        let previousComponentStackStep = null;
        let previousQuestion = null;
        const steps = nextStep ? [...stepStack, nextStep] : stepStack;
        for (const step of steps) {
            const configuration = StepConfigurations[step];
            if (configuration.page !== DeclarationPage.Questions) {
                continue;
            }
            const question = configuration.question;
            const section = sectionFromStepConfiguration(configuration, rootState.qualification.typeTrigram, INSURER);
            if (step === previousComponentStackStep ||
                question === previousQuestion ||
                !question ||
                !section) {
                continue;
            }
            newDeclarationComponentStack.push({
                question,
                step,
                section,
            });
            previousComponentStackStep = step;
            previousQuestion = configuration.question;
        }
        commit('SET_DECLARATION_COMPONENT_STACK', newDeclarationComponentStack);
        const nextStepConfiguration = nextStep ? StepConfigurations[nextStep] : null;
        const nextStepHasQuestion = nextStepConfiguration &&
            nextStepConfiguration.page === DeclarationPage.Questions &&
            !!nextStepConfiguration.question;
        if (nextStepHasQuestion) {
            commit('SET_ACTIVE_STEP', (_c = (_b = newDeclarationComponentStack[newDeclarationComponentStack.length - 1]) === null || _b === void 0 ? void 0 : _b.step) !== null && _c !== void 0 ? _c : null);
        }
        else if (shouldUpdatePage) {
            commit('SET_ACTIVE_STEP', nextStep);
        }
        if (state.pageOverride) {
            commit('OVERRIDE_PAGE', null);
        }
    },
    overridePage({ commit }, page) {
        commit('OVERRIDE_PAGE', page);
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    SET_ACTIVE_STEP(state, activeStep) {
        var _a;
        state.activeStep = activeStep;
        crispPushData('etape_actuelle', (_a = stepDisplayName(activeStep)) !== null && _a !== void 0 ? _a : 'Aucune');
        tagCommanderSetVariable('claims_step', activeStep);
        tagCommanderReload();
    },
    OVERRIDE_PAGE(state, page) {
        state.pageOverride = page;
    },
    SET_DECLARATION_COMPONENT_STACK(state, declarationComponentStack) {
        state.declarationComponentStack = declarationComponentStack;
    },
};
