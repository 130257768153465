export function forcedAmount(amount) {
    var _a, _b;
    return (_b = (_a = amount.forcedValue) !== null && _a !== void 0 ? _a : amount.computedValue) !== null && _b !== void 0 ? _b : amount.baseValue;
}
export function forcedAmountWithDefault(amount, defaultValue = 0) {
    var _a;
    return (_a = (amount && forcedAmount(amount))) !== null && _a !== void 0 ? _a : defaultValue;
}
export function forcableAmount(amount, forced) {
    return {
        baseValue: amount !== null && amount !== void 0 ? amount : null,
        computedValue: null,
        forcedValue: forced !== null && forced !== void 0 ? forced : null,
    };
}
