import { OtherDamageType } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
export const OTHER_DAMAGE_TYPE_TRAD_KEYS_AND_IMAGES = {
    [OtherDamageType.Skylight]: {
        tradKey: 'claim.declaration.break_in_damages.form.other-damage.type.skylight',
        sumupTradKey: 'claim.declaration.break_in_damages.form.other-damage.type.skylight.sumup',
        image: require('@declaration/assets/images/breakInDamage/other/type/skylight.svg'),
    },
    [OtherDamageType.FixedBay]: {
        tradKey: 'claim.declaration.break_in_damages.form.other-damage.type.fixed-bay',
        sumupTradKey: 'claim.declaration.break_in_damages.form.other-damage.type.fixed-bay.sumup',
        image: require('@declaration/assets/images/breakInDamage/other/type/fixed-bay.svg'),
    },
    [OtherDamageType.Transom]: {
        tradKey: 'claim.declaration.break_in_damages.form.other-damage.type.transom',
        sumupTradKey: 'claim.declaration.break_in_damages.form.other-damage.type.transom.sumup',
        image: require('@declaration/assets/images/breakInDamage/other/type/transom.svg'),
    },
    [OtherDamageType.Other]: {
        tradKey: 'claim.declaration.break_in_damages.form.other-damage.type.other',
        sumupTradKey: 'claim.declaration.break_in_damages.form.other-damage.type.other.sumup',
        image: require('@declaration/assets/images/breakInDamage/other/type/other.svg'),
    },
};
export const REPAIR_STATUS_CHOICES_OTHER = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.other',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.other',
    },
];
