import { objectToTranslatableImageChoices, } from '@shared/choice';
import { GlassType, GlazingType } from '@shared/types/api/breakInDamage';
import { WindowType } from '@shared/types/api/externalDamage';
export const WINDOW_TYPE_IMAGES_AND_TRAD_KEYS = {
    [WindowType.Window]: {
        image: require('@declaration/assets/images/externalDamage/window/window.svg'),
        tradKey: 'claim.declaration.external_damages.form.window.type.window',
        sumupTradKey: 'claim.declaration.external_damages.form.window.type.window.sumup',
    },
    [WindowType.RoofWindow]: {
        image: require('@declaration/assets/images/externalDamage/window/roof_window.svg'),
        tradKey: 'claim.declaration.external_damages.form.window.type.roof_window',
        sumupTradKey: 'claim.declaration.external_damages.form.window.type.roof_window.sumup',
    },
    [WindowType.SlidingBay]: {
        image: require('@declaration/assets/images/externalDamage/window/sliding_bay.svg'),
        tradKey: 'claim.declaration.external_damages.form.window.type.sliding_bay',
        sumupTradKey: 'claim.declaration.external_damages.form.window.type.sliding_bay.sumup',
    },
    [WindowType.FrenchWindow]: {
        image: require('@declaration/assets/images/externalDamage/window/french_window.svg'),
        tradKey: 'claim.declaration.external_damages.form.window.type.french_window',
        sumupTradKey: 'claim.declaration.external_damages.form.window.type.french_window.sumup',
    },
};
export const WINDOW_TYPE_CHOICES = objectToTranslatableImageChoices(WINDOW_TYPE_IMAGES_AND_TRAD_KEYS);
export const WINDOW_PANE_DIMENSIONS_TITLE = {
    [WindowType.Window]: 'claim.declaration.external_damages.form.window.pane_dimensions.title.window',
    [WindowType.RoofWindow]: 'claim.declaration.external_damages.form.window.pane_dimensions.title.roof_window',
    [WindowType.SlidingBay]: 'claim.declaration.external_damages.form.window.pane_dimensions.title.sliding_bay',
    [WindowType.FrenchWindow]: 'claim.declaration.external_damages.form.window.pane_dimensions.title.french_window',
};
export const WINDOW_PANE_GLASS_TYPE_IMAGES_AND_TRAD_KEYS = {
    [GlassType.Laminated]: {
        tradKey: 'claim.declaration.external_damages.form.window.pane_glass_type.laminated',
        image: require('@declaration/assets/images/externalDamage/window/glass_laminated.svg'),
    },
    [GlassType.NonLaminated]: {
        tradKey: 'claim.declaration.external_damages.form.window.pane_glass_type.non_laminated',
        image: require('@declaration/assets/images/externalDamage/window/glass_non_laminated.svg'),
    },
    [GlassType.Unknown]: {
        tradKey: 'claim.declaration.external_damages.form.window.pane_glass_type.unknown',
        image: require('@declaration/assets/images/externalDamage/window/glass_unknown.svg'),
    },
};
export const WINDOW_PANE_GLASS_TYPE_CHOICES = objectToTranslatableImageChoices(WINDOW_PANE_GLASS_TYPE_IMAGES_AND_TRAD_KEYS);
export const WINDOW_PANE_GLAZING_TYPE_TITLE = 'claim.declaration.external_damages.form.window.pane_glazing_type.title';
export const WINDOW_PANE_GLAZING_TYPE_IMAGES_AND_TRAD_KEYS = {
    [GlazingType.Simple]: {
        tradKey: 'claim.declaration.external_damages.form.window.pane_glazing_type.simple',
        image: require('@declaration/assets/images/externalDamage/window/glazing_simple.svg'),
    },
    [GlazingType.Double]: {
        tradKey: 'claim.declaration.external_damages.form.window.pane_glazing_type.double',
        image: require('@declaration/assets/images/externalDamage/window/glazing_double.svg'),
    },
    [GlazingType.Triple]: {
        tradKey: 'claim.declaration.external_damages.form.window.pane_glazing_type.triple',
        image: require('@declaration/assets/images/externalDamage/window/glazing_triple.svg'),
    },
    [GlazingType.Unknown]: {
        tradKey: 'claim.declaration.external_damages.form.window.pane_glazing_type.unknown',
        image: require('@declaration/assets/images/externalDamage/window/glazing_unknown.svg'),
    },
};
export const WINDOW_PANE_GLAZING_TYPE_CHOICES = objectToTranslatableImageChoices(WINDOW_PANE_GLAZING_TYPE_IMAGES_AND_TRAD_KEYS);
