import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
export function requiredFileCollection(value, { message, trigram }) {
    const nonBlocking = insurerHasFeature(InsurerFeatureFlag.NonBlockingRequiredFiles, trigram || ClaimTypeTrigram.Empty);
    if (nonBlocking)
        return;
    const fileCount = fileCollectionFilesCount(value);
    if (fileCount === 0) {
        return message || 'this value is required';
    }
}
