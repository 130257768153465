import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _497d739a = () => interopDefault(import('../../../src/declaration/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5684ef7c = () => interopDefault(import('../../../src/declaration/pages/declaration.vue' /* webpackChunkName: "pages/declaration" */))
const _abc806d2 = () => interopDefault(import('../../../src/declaration/pages/exception.vue' /* webpackChunkName: "pages/exception" */))
const _132516b1 = () => interopDefault(import('../../../src/declaration/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4b5baad3 = () => interopDefault(import('../../../src/declaration/pages/mes-declarations.vue' /* webpackChunkName: "pages/mes-declarations" */))
const _eca4d4f2 = () => interopDefault(import('../../../src/declaration/pages/ml.vue' /* webpackChunkName: "pages/ml" */))
const _5d0feeb6 = () => interopDefault(import('../../../src/declaration/pages/stylesheet.vue' /* webpackChunkName: "pages/stylesheet" */))
const _97a1c2b8 = () => interopDefault(import('../../../src/declaration/pages/design/styleguide.vue' /* webpackChunkName: "pages/design/styleguide" */))
const _6f8329f5 = () => interopDefault(import('../../../src/declaration/pages/monitoring/regles.vue' /* webpackChunkName: "pages/monitoring/regles" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _497d739a,
    name: "index___de"
  }, {
    path: "/declaration",
    component: _5684ef7c,
    name: "declaration___fr"
  }, {
    path: "/en",
    component: _497d739a,
    name: "index___en"
  }, {
    path: "/exception",
    component: _abc806d2,
    name: "exception___fr"
  }, {
    path: "/it",
    component: _497d739a,
    name: "index___it"
  }, {
    path: "/login",
    component: _132516b1,
    name: "login___fr"
  }, {
    path: "/mes-declarations",
    component: _4b5baad3,
    name: "mes-declarations___fr"
  }, {
    path: "/ml",
    component: _eca4d4f2,
    name: "ml___fr"
  }, {
    path: "/stylesheet",
    component: _5d0feeb6,
    name: "stylesheet___fr"
  }, {
    path: "/de/declaration",
    component: _5684ef7c,
    name: "declaration___de"
  }, {
    path: "/de/exception",
    component: _abc806d2,
    name: "exception___de"
  }, {
    path: "/de/login",
    component: _132516b1,
    name: "login___de"
  }, {
    path: "/de/mes-declarations",
    component: _4b5baad3,
    name: "mes-declarations___de"
  }, {
    path: "/de/ml",
    component: _eca4d4f2,
    name: "ml___de"
  }, {
    path: "/de/stylesheet",
    component: _5d0feeb6,
    name: "stylesheet___de"
  }, {
    path: "/design/styleguide",
    component: _97a1c2b8,
    name: "design/styleguide___fr"
  }, {
    path: "/en/declaration",
    component: _5684ef7c,
    name: "declaration___en"
  }, {
    path: "/en/exception",
    component: _abc806d2,
    name: "exception___en"
  }, {
    path: "/en/login",
    component: _132516b1,
    name: "login___en"
  }, {
    path: "/en/mes-declarations",
    component: _4b5baad3,
    name: "mes-declarations___en"
  }, {
    path: "/en/ml",
    component: _eca4d4f2,
    name: "ml___en"
  }, {
    path: "/en/stylesheet",
    component: _5d0feeb6,
    name: "stylesheet___en"
  }, {
    path: "/it/declaration",
    component: _5684ef7c,
    name: "declaration___it"
  }, {
    path: "/it/exception",
    component: _abc806d2,
    name: "exception___it"
  }, {
    path: "/it/login",
    component: _132516b1,
    name: "login___it"
  }, {
    path: "/it/mes-declarations",
    component: _4b5baad3,
    name: "mes-declarations___it"
  }, {
    path: "/it/ml",
    component: _eca4d4f2,
    name: "ml___it"
  }, {
    path: "/it/stylesheet",
    component: _5d0feeb6,
    name: "stylesheet___it"
  }, {
    path: "/monitoring/regles",
    component: _6f8329f5,
    name: "monitoring/regles___fr"
  }, {
    path: "/de/design/styleguide",
    component: _97a1c2b8,
    name: "design/styleguide___de"
  }, {
    path: "/de/monitoring/regles",
    component: _6f8329f5,
    name: "monitoring/regles___de"
  }, {
    path: "/en/design/styleguide",
    component: _97a1c2b8,
    name: "design/styleguide___en"
  }, {
    path: "/en/monitoring/regles",
    component: _6f8329f5,
    name: "monitoring/regles___en"
  }, {
    path: "/it/design/styleguide",
    component: _97a1c2b8,
    name: "design/styleguide___it"
  }, {
    path: "/it/monitoring/regles",
    component: _6f8329f5,
    name: "monitoring/regles___it"
  }, {
    path: "/",
    component: _497d739a,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
