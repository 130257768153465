import { ImpactsNumber, Material, Measurement } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairStatus } from '@shared/types/api/claim';
export const LITTLE_GATE_MATERIAL_CHOICES = [
    {
        value: Material.Wood,
        tradKey: 'claim.declaration.break_in_damages.form.material.wood',
    },
    {
        value: Material.PVC,
        tradKey: 'claim.declaration.break_in_damages.form.material.pvc',
    },
    {
        value: Material.Metal,
        tradKey: 'claim.declaration.break_in_damages.form.material.metal',
    },
];
export const LITTLE_GATE_HEIGHT_TRAD_KEYS_AND_IMAGES = {
    [Measurement.LessThan120cm]: {
        tradKey: 'claim.declaration.break_in_damages.form.little-gate.height.low',
        descriptionTradKey: 'claim.declaration.break_in_damages.form.measurement.less-than-120cm',
        image: require('@declaration/assets/images/breakInDamage/littleGate/height/low.svg'),
    },
    [Measurement.Between120And140cm]: {
        tradKey: 'claim.declaration.break_in_damages.form.little-gate.height.medium',
        descriptionTradKey: 'claim.declaration.break_in_damages.form.measurement.between-120-and-140cm',
        image: require('@declaration/assets/images/breakInDamage/littleGate/height/medium.svg'),
    },
    [Measurement.Between140And180cm]: {
        tradKey: 'claim.declaration.break_in_damages.form.little-gate.height.high',
        descriptionTradKey: 'claim.declaration.break_in_damages.form.measurement.between-140-and-180cm',
        image: require('@declaration/assets/images/breakInDamage/littleGate/height/high.svg'),
    },
    [Measurement.MoreThan180cm]: {
        tradKey: 'claim.declaration.break_in_damages.form.little-gate.height.very-high',
        descriptionTradKey: 'claim.declaration.break_in_damages.form.measurement.more-than-180cm',
        image: require('@declaration/assets/images/breakInDamage/littleGate/height/very-high.svg'),
    },
};
export const WOODEN_LITTLE_GATE_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.AtLeastOne,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
    },
];
export const METAL_LITTLE_GATE_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.MoreThanThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    },
];
export const PVC_LITTLE_GATE_IMPACTS_NUMBER = [
    {
        value: ImpactsNumber.NoImpact,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    },
    {
        value: ImpactsNumber.OneToThree,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    },
    {
        value: ImpactsNumber.FourToSix,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.four-to-six',
    },
    {
        value: ImpactsNumber.MoreThanSeven,
        tradKey: 'claim.declaration.break_in_damages.form.impacts.more-than-seven',
    },
];
export const REPAIR_STATUS_CHOICES_LITTLE_GATE = [
    {
        value: BreakInDamageRepairStatus.Repaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired.little-gate',
    },
    {
        value: BreakInDamageRepairStatus.RepairedButNeedsMoreWork,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.repaired-but-needs-more-work',
    },
    {
        value: BreakInDamageRepairStatus.NotRepaired,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repair-status.not-repaired.little-gate',
    },
];
