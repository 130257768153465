import { FRENCH_WINDOW_MATERIAL_MODEL_LABELS, FRENCH_WINDOW_MATERIAL_MODEL_CHOICES, FRENCH_WINDOW_DAMAGE_TYPE_MODEL_LABELS, FRENCH_WINDOW_DAMAGE_TYPE_MODEL_CHOICES, FRENCH_WINDOW_GLAZING_TYPE_MODEL_LABELS, FRENCH_WINDOW_GLAZING_TYPE_MODEL_CHOICES, FRENCH_WINDOW_GLASS_TYPE_MODEL_LABELS, FRENCH_WINDOW_GLASS_TYPE_MODEL_CHOICES, FRENCH_WINDOW_TWO_LEAVES_WIDTH_MODEL_LABELS, FRENCH_WINDOW_ONE_LEAF_WIDTH_MODEL_LABELS, FRENCH_WINDOW_TWO_LEAVES_WIDTH_MODEL_CHOICES, FRENCH_WINDOW_ONE_LEAF_WIDTH_MODEL_CHOICES, FRENCH_WINDOW_LEAF_HEIGHT_MODEL_LABELS, FRENCH_WINDOW_LEAF_HEIGHT_MODEL_CHOICES, GOOD_DAMAGE_TYPE_LABELS, FRENCH_WINDOW_MATERIAL_LABELS, } from '../goodDamage';
import { parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { numbersToChoices, translateChoices } from '@shared/choice';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { FrenchWindowMaterial, FrenchWindowDamageType, } from '@shared/types/api/roomDamage';
import { optionalString, optionalTranslatableEnumTransform, optionalBooleanTransform, } from '@shared/utils/transform';
export function frenchWindowDetails(model, translate) {
    var _a;
    if (!model.type || !model.leafCount || !model.material)
        return null;
    const key = `${GOOD_DAMAGE_TYPE_LABELS[model.type]}.description`;
    return translate(key, (_a = model.leafCount) !== null && _a !== void 0 ? _a : 1, {
        material: translate(FRENCH_WINDOW_MATERIAL_LABELS[model.material]),
        count: model.leafCount,
    });
}
export function frenchWindowFields(model, context) {
    var _a, _b;
    const { translate, index } = context;
    const fields = [
        {
            label: translate('model.good_damage.french_window.leaf_count'),
            value: model.leafCount,
            displayedValue: optionalString(model.leafCount),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: numbersToChoices(1, 2, 3),
                },
                modelKey: `goodDamages.${index}.leafCount`,
                toPayload: (leafCount) => ({ leafCount }),
            },
        },
        {
            label: translate('model.good_damage.french_window.material'),
            value: model.material,
            displayedValue: optionalTranslatableEnumTransform(model.material, FRENCH_WINDOW_MATERIAL_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(FRENCH_WINDOW_MATERIAL_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.material`,
                toPayload: (material) => ({
                    material,
                    damageType: material === FrenchWindowMaterial.WOOD ? model.damageType : null,
                    isGlazingBroken: material === FrenchWindowMaterial.WOOD ? model.isGlazingBroken : null,
                    tileCount: material === FrenchWindowMaterial.WOOD ? model.tileCount : null,
                    biggestTileWidth: material === FrenchWindowMaterial.WOOD ? model.biggestTileWidth : null,
                    biggestTileHeight: material === FrenchWindowMaterial.WOOD ? model.biggestTileHeight : null,
                    leafWidth: material === FrenchWindowMaterial.PVC ||
                        material === FrenchWindowMaterial.METAL ||
                        model.damageType === FrenchWindowDamageType.DAMAGED
                        ? model.leafWidth
                        : null,
                    leafHeight: material === FrenchWindowMaterial.PVC ||
                        material === FrenchWindowMaterial.METAL ||
                        model.damageType === FrenchWindowDamageType.DAMAGED
                        ? model.leafHeight
                        : null,
                }),
            },
        },
    ];
    if (model.material === FrenchWindowMaterial.WOOD) {
        fields.push({
            label: translate('model.good_damage.french_window.damage_type'),
            value: model.damageType,
            displayedValue: optionalTranslatableEnumTransform(model.damageType, FRENCH_WINDOW_DAMAGE_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(FRENCH_WINDOW_DAMAGE_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.damageType`,
                toPayload: (damageType) => {
                    const aesthetic = damageType === FrenchWindowDamageType.AESTHETIC;
                    return {
                        damageType,
                        isGlazingBroken: aesthetic ? model.isGlazingBroken : null,
                        tileCount: aesthetic ? model.tileCount : null,
                        biggestTileWidth: aesthetic ? model.biggestTileWidth : null,
                        biggestTileHeight: aesthetic ? model.biggestTileHeight : null,
                        leafWidth: aesthetic ? null : model.leafWidth,
                        leafHeight: aesthetic ? null : model.leafHeight,
                    };
                },
            },
        });
    }
    if (model.damageType === FrenchWindowDamageType.AESTHETIC) {
        fields.push({
            label: translate('model.good_damage.french_window.is_glazing_broken'),
            value: model.isGlazingBroken,
            displayedValue: optionalBooleanTransform(model.isGlazingBroken, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(YES_OR_NO_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.isGlazingBroken`,
                toPayload: (isGlazingBroken) => ({
                    isGlazingBroken,
                    tileCount: isGlazingBroken ? model.tileCount : null,
                    biggestTileWidth: isGlazingBroken ? model.biggestTileWidth : null,
                    biggestTileHeight: isGlazingBroken ? model.biggestTileHeight : null,
                    glazingType: isGlazingBroken ? model.glazingType : null,
                    glassType: isGlazingBroken ? model.glassType : null,
                }),
            },
        });
        if (model.isGlazingBroken) {
            fields.push({
                label: translate('model.good_damage.french_window.tile_count'),
                value: model.tileCount,
                displayedValue: optionalString(model.tileCount),
                editable: {
                    input: {
                        type: ModelFieldInputType.Number,
                    },
                    modelKey: `goodDamages.${index}.tileCount`,
                    toPayload: (tileCount) => ({ tileCount }),
                },
            }, {
                label: translate('model.good_damage.french_window.biggest_tile_width'),
                value: ((_a = model.biggestTileWidth) !== null && _a !== void 0 ? _a : 0) / 10,
                displayedValue: parseOptionalDimension(model.biggestTileWidth),
                editable: {
                    input: {
                        type: ModelFieldInputType.Number,
                    },
                    unit: 'cm',
                    modelKey: `goodDamages.${index}.biggestTileWidth`,
                    toPayload: (biggestTileWidth) => ({
                        biggestTileWidth: biggestTileWidth * 10,
                    }),
                },
            }, {
                label: translate('model.good_damage.french_window.biggest_tile_height'),
                value: ((_b = model.biggestTileHeight) !== null && _b !== void 0 ? _b : 0) / 10,
                displayedValue: parseOptionalDimension(model.biggestTileHeight),
                editable: {
                    input: {
                        type: ModelFieldInputType.Number,
                    },
                    unit: 'cm',
                    modelKey: `goodDamages.${index}.biggestTileHeight`,
                    toPayload: (biggestTileHeight) => ({
                        biggestTileHeight: biggestTileHeight * 10,
                    }),
                },
            });
        }
    }
    if (model.isGlazingBroken ||
        model.damageType === FrenchWindowDamageType.DAMAGED ||
        model.material === FrenchWindowMaterial.PVC ||
        model.material === FrenchWindowMaterial.METAL) {
        fields.push({
            label: translate('model.good_damage.french_window.glazing_type'),
            value: model.glazingType,
            displayedValue: optionalTranslatableEnumTransform(model.glazingType, FRENCH_WINDOW_GLAZING_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(FRENCH_WINDOW_GLAZING_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.glazingType`,
                toPayload: (glazingType) => ({ glazingType }),
            },
        }, {
            label: translate('model.good_damage.french_window.glass_type'),
            value: model.glassType,
            displayedValue: optionalTranslatableEnumTransform(model.glassType, FRENCH_WINDOW_GLASS_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(FRENCH_WINDOW_GLASS_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.glassType`,
                toPayload: (glassType) => ({ glassType }),
            },
        });
    }
    if ((model.material === FrenchWindowMaterial.PVC ||
        model.material === FrenchWindowMaterial.METAL ||
        model.damageType === FrenchWindowDamageType.DAMAGED) &&
        typeof model.leafCount === 'number') {
        fields.push({
            label: translate('model.good_damage.french_window.leaf_width'),
            value: model.leafWidth,
            displayedValue: optionalTranslatableEnumTransform(model.leafWidth, model.leafCount >= 2
                ? FRENCH_WINDOW_TWO_LEAVES_WIDTH_MODEL_LABELS
                : FRENCH_WINDOW_ONE_LEAF_WIDTH_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(model.leafCount >= 2
                        ? FRENCH_WINDOW_TWO_LEAVES_WIDTH_MODEL_CHOICES
                        : FRENCH_WINDOW_ONE_LEAF_WIDTH_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.leafWidth`,
                toPayload: (leafWidth) => ({ leafWidth }),
            },
        }, {
            label: translate('model.good_damage.french_window.leaf_height'),
            value: model.leafHeight,
            displayedValue: optionalTranslatableEnumTransform(model.leafHeight, FRENCH_WINDOW_LEAF_HEIGHT_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(FRENCH_WINDOW_LEAF_HEIGHT_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.leafHeight`,
                toPayload: (leafHeight) => ({ leafHeight }),
            },
        });
    }
    return fields;
}
