import { getSettingValue } from '@shared/helpers/settings';
export default (function (context, inject) {
    async function goBackHome(saveStep, customerAreaUrlParam) {
        const store = context.store;
        if (saveStep) {
            const activeStep = typeof saveStep === 'string' ? saveStep : store.state.pages.activeStep;
            if (activeStep)
                await store.dispatch('claim/updateAndSaveClaimStep', activeStep);
        }
        if (store.getters['authentication/isAuthenticatedWithMagicLink']) {
            store.commit('authentication/SET_MAGIC_LINK', null);
        }
        if (store.state.settings.appSettings &&
            store.state.settings.appSettings.some((appSetting) => appSetting.name === 'customerAreaUrl') &&
            getSettingValue('customerAreaUrl', {
                settings: store.state.settings.appSettings,
            }) !== null) {
            window.location.href =
                getSettingValue('customerAreaUrl', {
                    settings: store.state.settings.appSettings,
                }) + (customerAreaUrlParam ? '/' + customerAreaUrlParam : '');
        }
        else {
            context.$goToLocalePath('index');
        }
    }
    inject('goBackHome', goBackHome);
});
