import { objectToTranslatableChoices, } from '@shared/choice';
import { ExternalDamageOrigin, FenceType } from '@shared/types/api/externalDamage';
export const DEFAULT_FENCE_TYPE_TRAD_KEYS = {
    [FenceType.WireMesh]: 'claim.declaration.external_damages.form.fence.type.wire_mesh',
    [FenceType.WirePanel]: 'claim.declaration.external_damages.form.fence.type.wire_panel',
    [FenceType.WoodPanel]: 'claim.declaration.external_damages.form.fence.type.wood_panel',
    [FenceType.PVC]: 'claim.declaration.external_damages.form.fence.type.pvc',
    [FenceType.Other]: 'claim.declaration.external_damages.form.fence.type.other',
};
const STORM_FENCE_TYPE_TRAD_KEYS = {};
const SNOW_FENCE_TYPE_TRAD_KEYS = {};
const HAIL_FENCE_TYPE_TRAD_KEYS = {};
const FIRE_FENCE_TYPE_TRAD_KEYS = {};
export const FENCE_TYPE_TRAD_KEYS = {
    [ExternalDamageOrigin.Storm]: {
        ...DEFAULT_FENCE_TYPE_TRAD_KEYS,
        ...STORM_FENCE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Snow]: {
        ...DEFAULT_FENCE_TYPE_TRAD_KEYS,
        ...SNOW_FENCE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Hail]: {
        ...DEFAULT_FENCE_TYPE_TRAD_KEYS,
        ...HAIL_FENCE_TYPE_TRAD_KEYS,
    },
    [ExternalDamageOrigin.Fire]: {
        ...DEFAULT_FENCE_TYPE_TRAD_KEYS,
        ...FIRE_FENCE_TYPE_TRAD_KEYS,
    },
};
export const FENCE_TYPE_CHOICES = {
    [ExternalDamageOrigin.Storm]: objectToTranslatableChoices(FENCE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Storm]),
    [ExternalDamageOrigin.Snow]: objectToTranslatableChoices(FENCE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Snow]),
    [ExternalDamageOrigin.Hail]: objectToTranslatableChoices(FENCE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Hail]),
    [ExternalDamageOrigin.Fire]: objectToTranslatableChoices(FENCE_TYPE_TRAD_KEYS[ExternalDamageOrigin.Fire]),
};
