import { parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { translatableObjectToChoices } from '@shared/choice';
import { GUTTER_MATERIAL_TRAD_KEYS } from '@shared/constants/externalDamages';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
export const gutterFields = (model, { translate }) => {
    var _a, _b;
    return [
        {
            label: translate('model.external_damage.horizontal_part_length'),
            value: ((_a = model.horizontalPartLength) !== null && _a !== void 0 ? _a : 0) / 1000,
            displayedValue: parseOptionalDimension(model.horizontalPartLength),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'm',
                modelKey: 'horizontalPartLength',
                toPayload: (horizontalPartLength) => ({
                    horizontalPartLength: horizontalPartLength * 1000,
                }),
            },
        },
        {
            label: translate('model.external_damage.horizontal_part_material'),
            value: model.horizontalPartMaterial,
            displayedValue: optionalTranslatableEnumTransform(model.horizontalPartMaterial, GUTTER_MATERIAL_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GUTTER_MATERIAL_TRAD_KEYS, translate),
                },
                modelKey: 'horizontalPartMaterial',
            },
        },
        {
            label: translate('model.external_damage.vertical_part_length'),
            value: ((_b = model.verticalPartLength) !== null && _b !== void 0 ? _b : 0) / 1000,
            displayedValue: parseOptionalDimension(model.verticalPartLength),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'm',
                modelKey: 'verticalPartLength',
                toPayload: (verticalPartLength) => ({
                    verticalPartLength: verticalPartLength * 1000,
                }),
            },
        },
        {
            label: translate('model.external_damage.vertical_part_material'),
            value: model.verticalPartMaterial,
            displayedValue: optionalTranslatableEnumTransform(model.verticalPartMaterial, GUTTER_MATERIAL_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translatableObjectToChoices(GUTTER_MATERIAL_TRAD_KEYS, translate),
                },
                modelKey: 'verticalPartMaterial',
            },
        },
    ];
};
