import { BreakInDamageCompensationMode, DamageSeverity, GlassType, GlazingType, ImpactsNumber, LeavesType, Material, } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairPerson } from '@shared/types/api/claim';
export const BREAK_IN_DAMAGE_COMPENSATION_MODE_TRAD_KEYS = {
    [BreakInDamageCompensationMode.CompensationModeAssistance]: 'backoffice.break_in_damage.compensation_mode.assistance',
    [BreakInDamageCompensationMode.CompensationModeTemporary]: 'backoffice.break_in_damage.compensation_mode.temporary',
    [BreakInDamageCompensationMode.CompensationModeDefinitive]: 'backoffice.break_in_damage.compensation_mode.definitive',
    [BreakInDamageCompensationMode.CompensationModeReplacementNecessary]: 'backoffice.break_in_damage.compensation_mode.replacement_necessary',
    [BreakInDamageCompensationMode.CompensationModeFixed]: 'backoffice.break_in_damage.compensation_mode.fixed',
};
export const REPAIRED_BY_CHOICES = [
    {
        value: BreakInDamageRepairPerson.Myself,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repaired-by.myself',
    },
    {
        value: BreakInDamageRepairPerson.Artisan,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repaired-by.artisan',
    },
    {
        value: BreakInDamageRepairPerson.Assistance,
        tradKey: 'claim.declaration.break_in_damages.form.repairs.repaired-by.assistance',
    },
];
export const YES_OR_NO_CHOICES = [
    {
        value: true,
        tradKey: 'claim.declaration.break_in_damages.form.yes',
    },
    {
        value: false,
        tradKey: 'claim.declaration.break_in_damages.form.no',
    },
];
export const LEAVES_TYPE_SUMUP_TRAD_KEYS = {
    [LeavesType.OneLeaf]: 'claim.declaration.break_in_damages.form.leaves-type.one-leaf.sumup',
    [LeavesType.TwoLeaves]: 'claim.declaration.break_in_damages.form.leaves-type.two-leaves.sumup',
    [LeavesType.ThreeLeaves]: 'claim.declaration.break_in_damages.form.leaves-type.three-leaves.sumup',
};
export const MATERIAL_SUMUP_TRAD_KEYS = {
    [Material.Wood]: 'claim.declaration.break_in_damages.form.material.wood.sumup',
    [Material.PVC]: 'claim.declaration.break_in_damages.form.material.pvc.sumup',
    [Material.Metal]: 'claim.declaration.break_in_damages.form.material.metal.sumup',
    [Material.Aluminium]: 'claim.declaration.break_in_damages.form.material.aluminium.sumup',
    [Material.Steel]: 'claim.declaration.break_in_damages.form.material.steel.sumup',
};
export const GLAZING_TYPE_TRAD_KEYS_AND_IMAGES = {
    [GlazingType.Simple]: {
        tradKey: 'claim.declaration.break_in_damages.form.glazing.simple',
        image: require('@shared/assets/images/windows/glazing-type/simple.svg'),
    },
    [GlazingType.Double]: {
        tradKey: 'claim.declaration.break_in_damages.form.glazing.double',
        image: require('@shared/assets/images/windows/glazing-type/double.svg'),
    },
    [GlazingType.Triple]: {
        tradKey: 'claim.declaration.break_in_damages.form.glazing.triple',
        image: require('@shared/assets/images/windows/glazing-type/triple.svg'),
    },
    [GlazingType.Unknown]: {
        tradKey: 'claim.declaration.break_in_damages.unknown',
        image: require('@shared/assets/images/windows/glazing-type/unknown.svg'),
    },
};
export const GLASS_TYPE_TRAD_KEYS_AND_IMAGES = {
    [GlassType.Laminated]: {
        tradKey: 'claim.declaration.break_in_damages.form.glass.laminated',
        image: require('@declaration/assets/images/breakInDamage/common/glassType/laminated.svg'),
    },
    [GlassType.NonLaminated]: {
        tradKey: 'claim.declaration.break_in_damages.form.glass.non-laminated',
        image: require('@declaration/assets/images/breakInDamage/common/glassType/non-laminated.svg'),
    },
    [GlassType.Unknown]: {
        tradKey: 'claim.declaration.break_in_damages.unknown',
        image: require('@declaration/assets/images/breakInDamage/common/glassType/unknown.svg'),
    },
};
export var BreakInDamageDocumentsType;
(function (BreakInDamageDocumentsType) {
    BreakInDamageDocumentsType["InvoiceAndQuote"] = "invoice-and-quote";
    BreakInDamageDocumentsType["InvoiceOnly"] = "invoice-only";
    BreakInDamageDocumentsType["QuoteOnly"] = "quote-only";
    BreakInDamageDocumentsType["Photo"] = "photo";
    BreakInDamageDocumentsType["None"] = "none";
})(BreakInDamageDocumentsType || (BreakInDamageDocumentsType = {}));
export const BREAK_IN_DAMAGES_DAMAGE_SEVERITY_TRAD_KEYS = {
    [DamageSeverity.Slight]: 'model.break_in_damage.severity.light',
    [DamageSeverity.Notable]: 'model.break_in_damage.severity.notable',
    [DamageSeverity.Critical]: 'model.break_in_damage.severity.critical',
};
export const BREAK_IN_DAMAGES_NUMBER_OF_IMPACTS_TRAD_KEYS = {
    [ImpactsNumber.NoImpact]: 'claim.declaration.break_in_damages.form.impacts.no-impact',
    [ImpactsNumber.AtLeastOne]: 'claim.declaration.break_in_damages.form.impacts.at-least-one',
    [ImpactsNumber.OneToThree]: 'claim.declaration.break_in_damages.form.impacts.one-to-three',
    [ImpactsNumber.MoreThanThree]: 'claim.declaration.break_in_damages.form.impacts.more-than-three',
    [ImpactsNumber.FourToSix]: 'claim.declaration.break_in_damages.form.impacts.four-to-six',
    [ImpactsNumber.MoreThanSeven]: 'claim.declaration.break_in_damages.form.impacts.more-than-seven',
};
