import moment from 'moment';
import Vue from 'vue';
import { formatOptionalAge } from '@shared/utils/formatAge';
import formatForcableAmount from '@shared/utils/formatForcableAmount';
import formatOptionalAmount from '@shared/utils/formatOptionalAmount';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import formatOptionalCentsAmountWith2Precision from '@shared/utils/formatOptionalCentsAmountWith2Precision';
import { formatOptionalPercentage } from '@shared/utils/formatPercentage';
import { formatOptionalSurface } from '@shared/utils/formatSurface';
import { capitalize } from '@shared/utils/strings';
Vue.filter('amount', formatOptionalAmount);
Vue.filter('centsAmount', formatOptionalCentsAmount);
Vue.filter('centsAmount2Precision', formatOptionalCentsAmountWith2Precision);
Vue.filter('percentage', formatOptionalPercentage);
Vue.filter('age', formatOptionalAge);
Vue.filter('surface', formatOptionalSurface);
Vue.filter('capitalize', capitalize);
Vue.filter('forcableAmount', formatForcableAmount);
Vue.filter('round', (value) => {
    const res = Math.round(value);
    return isNaN(res) ? null : res;
});
Vue.filter('date', (value, format = 'L') => {
    return moment(value).format(format);
});
