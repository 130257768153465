import { ClaimTypeTrigram } from '@shared/types/api/claim';
// /!\ Keep in sync with _colors.scss
export const COLORS = {
    grey: {
        1: '#5B447C',
        2: '#6B628D',
        3: '#8793B9',
        4: '#BAC1F5',
        5: '#D7E0FF',
        6: '#F7F4FF',
        '03': '#F8F8F8',
        '06': '#F1F1F1',
        30: '#B3B3B3',
        50: '#808080',
    },
    blue: {
        1: '#5E62CD',
        2: '#6979E0',
        3: '#6C8EFA',
        4: '#86B4FF',
        5: '#B6DFFF',
        6: '#DFF1FF',
    },
    green: {
        1: '#367E7A',
        2: '#3D8E7D',
        3: '#4CA784',
        4: '#83E39C',
        5: '#D6EBDC',
    },
    red: {
        1: '#CA4155',
        2: '#FA5D64',
        3: '#FDBEC1',
    },
    yellow: {
        1: '#9D4C52',
        2: '#C46957',
        3: '#F7A56B',
        4: '#FFDD83',
        5: '#FFF3A1',
    },
    black: '#000000',
    dark: '#22256C',
    white: '#FFFFFF',
    transparent: {
        white: {
            15: 'rgba(255, 255, 255, 0.15)',
            40: 'rgba(255, 255, 255, 0.40)',
            100: 'rgba(255, 255, 255, 1)',
        },
    },
};
// We cannot use dynamic requires for assets so we cache them in a hashmap
export const CLAIM_TYPE_IMAGES = {
    [ClaimTypeTrigram.WaterDamage]: require(`@declaration/assets/images/claim-type/DDE.svg`),
    [ClaimTypeTrigram.Robbery]: require(`@declaration/assets/images/claim-type/VOL.svg`),
    [ClaimTypeTrigram.Drought]: require(`@declaration/assets/images/claim-type/SEC.svg`),
    [ClaimTypeTrigram.Disaster]: require(`@declaration/assets/images/claim-type/CAT.svg`),
    [ClaimTypeTrigram.StormSnowHail]: require(`@declaration/assets/images/claim-type/TNG.svg`),
    [ClaimTypeTrigram.Glass]: require(`@declaration/assets/images/claim-type/BDG.svg`),
    [ClaimTypeTrigram.Fire]: require(`@declaration/assets/images/claim-type/INC.svg`),
    [ClaimTypeTrigram.Electric]: require(`@declaration/assets/images/claim-type/ELE.svg`),
    [ClaimTypeTrigram.Vehicle]: require(`@declaration/assets/images/claim-type/VEH.svg`),
    [ClaimTypeTrigram.Other]: require(`@declaration/assets/images/claim-type/AUT.svg`),
    [ClaimTypeTrigram.Empty]: '',
};
export const DEFAULT_SPLASHSCREEN_IMAGES = {
    DDE: ['leakDetection', 'bathtub', 'remoteExpert'],
    TNG: ['blownTiles', 'brokenWindow', 'remoteExpert'],
};
export const SPLASHSCREEN_IMAGES = [
    {
        name: 'leakDetection',
        svg: require(`@declaration/assets/images/water-damage/leakDetection.svg`),
    },
    {
        name: 'bathtub',
        svg: require(`@declaration/assets/images/water-damage/bathtub.svg`),
    },
    {
        name: 'remoteExpert',
        svg: require(`@declaration/assets/images/water-damage/remoteExpert.svg`),
    },
    {
        name: 'creditCard',
        svg: require(`@declaration/assets/images/payout/creditCard.svg`),
    },
    {
        name: 'blownTiles',
        svg: require(`@declaration/assets/images/claim-type/TNG.svg`),
    },
    {
        name: 'brokenWindow',
        svg: require(`@declaration/assets/images/storm-snow-hail/TNG-argument1.svg`),
    },
];
export const DEFAULT_LAYOUT_COLOR = {
    background: COLORS.grey['03'],
    backgroundLight: COLORS.white,
    headerBackgroundColor: COLORS.white,
    componentAndModalHeader: COLORS.grey['03'],
    text: COLORS.dark,
};
/**
 * ⚠️ keep in sync with colors.scss colors
 */
export const CLAIM_TYPE_LAYOUT_COLORS = {
    [ClaimTypeTrigram.Empty]: {
        background: COLORS.blue['2'],
        backgroundLight: COLORS.grey['5'],
        headerColor: COLORS.dark,
        headerBackgroundColor: COLORS.blue['2'],
        componentAndModalHeader: COLORS.blue['4'],
        modalContentColor: COLORS.dark,
        text: COLORS.white,
    },
    [ClaimTypeTrigram.WaterDamage]: {
        background: COLORS.blue['2'],
        backgroundLight: COLORS.grey['5'],
        headerColor: COLORS.dark,
        headerBackgroundColor: COLORS.blue['2'],
        componentAndModalHeader: COLORS.blue['4'],
        modalContentColor: COLORS.dark,
        text: COLORS.white,
    },
    [ClaimTypeTrigram.Robbery]: {
        background: COLORS.green['4'],
        backgroundLight: COLORS.green['5'],
        headerBackgroundColor: COLORS.green['4'],
        componentAndModalHeader: COLORS.green['4'],
        text: COLORS.dark,
        isBackgroundPale: true,
    },
    [ClaimTypeTrigram.Drought]: {
        background: COLORS.yellow['4'],
        backgroundLight: COLORS.grey['5'],
        headerBackgroundColor: COLORS.yellow['4'],
        componentAndModalHeader: COLORS.yellow['4'],
        text: COLORS.dark,
    },
    [ClaimTypeTrigram.Disaster]: {
        background: COLORS.blue['5'],
        backgroundLight: COLORS.blue['6'],
        headerBackgroundColor: COLORS.blue['5'],
        componentAndModalHeader: COLORS.blue['5'],
        text: COLORS.dark,
    },
    [ClaimTypeTrigram.StormSnowHail]: {
        background: COLORS.blue['5'],
        backgroundLight: COLORS.blue['6'],
        headerBackgroundColor: COLORS.blue['5'],
        componentAndModalHeader: COLORS.blue['5'],
        text: COLORS.dark,
    },
    [ClaimTypeTrigram.Glass]: {
        background: COLORS.yellow['4'],
        backgroundLight: COLORS.grey['5'],
        headerBackgroundColor: COLORS.yellow['4'],
        componentAndModalHeader: COLORS.yellow['4'],
        text: COLORS.dark,
    },
    [ClaimTypeTrigram.Fire]: {
        background: COLORS.red['2'],
        backgroundLight: COLORS.red['3'],
        headerColor: COLORS.dark,
        headerBackgroundColor: COLORS.red['2'],
        componentAndModalHeader: COLORS.red['2'],
        modalContentBackgroundColor: COLORS.white,
        modalContentColor: COLORS.dark,
        text: COLORS.white,
    },
    [ClaimTypeTrigram.Electric]: {
        background: COLORS.yellow['3'],
        backgroundLight: COLORS.yellow['4'],
        headerBackgroundColor: COLORS.yellow['3'],
        componentAndModalHeader: COLORS.yellow['3'],
        text: COLORS.dark,
    },
    [ClaimTypeTrigram.Vehicle]: {
        background: COLORS.grey['5'],
        backgroundLight: COLORS.grey['5'],
        headerBackgroundColor: COLORS.grey['5'],
        componentAndModalHeader: COLORS.grey['5'],
        text: COLORS.dark,
    },
    [ClaimTypeTrigram.Other]: {
        background: COLORS.grey['2'],
        backgroundLight: COLORS.grey['5'],
        headerColor: COLORS.dark,
        headerBackgroundColor: COLORS.grey['3'],
        componentAndModalHeader: COLORS.grey['3'],
        modalContentColor: COLORS.dark,
        text: COLORS.white,
    },
};
export const KEEP_LAYOUT_COLOR = Symbol('KeepLayoutColor');
export const CURRENT_CLAIM_TYPE_LAYOUT_COLOR = Symbol('CurrentClaimTypeLayoutColor');
export const CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR = Symbol('CurrentClaimTypeLightLayoutColor');
export const WHITE_LAYOUT_COLOR = {
    text: COLORS.dark,
    headerBackgroundColor: COLORS.white,
    background: COLORS.white,
    backgroundLight: COLORS.white,
    componentAndModalHeader: COLORS.grey['03'],
};
