import { imageObjectToChoices, translatableObjectToChoices } from '@shared/choice';
import { SHUTTER_MATERIAL_TRAD_KEYS, SHUTTER_TYPE_IMAGES_AND_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { ModelFieldInputPreset, ModelFieldInputType, } from '@shared/modelField/modelField';
import { ShutterType } from '@shared/types/api/externalDamage';
import { optionalBooleanTransform, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
export const shuttersFields = (model, { translate }) => {
    const fields = [
        {
            label: translate('model.external_damage.shutter_type'),
            value: model.shutterType,
            displayedValue: optionalTranslatableEnumTransform(model.shutterType, SHUTTER_TYPE_IMAGES_AND_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: imageObjectToChoices(SHUTTER_TYPE_IMAGES_AND_TRAD_KEYS, translate),
                    vertical: true,
                },
                modelKey: 'shutterType',
            },
        },
    ];
    if (model.shutterType === ShutterType.Rolling) {
        fields.push({
            label: translate('model.external_damage.shutter_is_motorized'),
            value: model.isOpeningMotorized,
            displayedValue: optionalBooleanTransform(model.isOpeningMotorized, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'isOpeningMotorized',
            },
        });
    }
    fields.push({
        label: translate('model.external_damage.shutter_count'),
        value: model.shutterCount,
        editable: {
            input: {
                type: ModelFieldInputType.Number,
            },
            modelKey: 'shutterCount',
        },
    }, {
        label: translate('model.external_damage.shutter_material'),
        value: model.material,
        displayedValue: optionalTranslatableEnumTransform(model.material, SHUTTER_MATERIAL_TRAD_KEYS, translate),
        editable: {
            input: {
                type: ModelFieldInputType.Select,
                choices: translatableObjectToChoices(SHUTTER_MATERIAL_TRAD_KEYS, translate),
            },
            modelKey: 'material',
        },
    }, {
        label: translate('model.external_damage.shutter_width'),
        value: model.isWidthGreaterThanStandard,
        displayedValue: optionalBooleanTransform(model.isWidthGreaterThanStandard, translate, '-', 'model.external_damage.shutter_width.greater_than_standard', 'model.external_damage.shutter_width.standard'),
        editable: {
            input: ModelFieldInputPreset.boolean(translate),
            modelKey: 'isWidthGreaterThanStandard',
        },
    });
    return fields;
};
