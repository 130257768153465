const plugin = (ctx, inject) => {
    ctx.redirectLocale = redirectLocale;
    inject('goToLocalePath', goToLocalePath);
    function redirectLocale(path, locale) {
        const { redirect, localePath } = ctx;
        return redirect(localePath(path, locale));
    }
    async function goToLocalePath(path, locale) {
        const { app } = ctx;
        if (app.router)
            return await app.router.push(app.localePath(path, locale));
    }
};
export default plugin;
