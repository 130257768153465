import { getSettingValue } from '@shared/helpers/settings';
export const name = 'settings';
export const namespaced = true;
export const state = () => ({
    appSettings: null,
});
export const getters = {
    areSettingsFetched(state) {
        return state.appSettings !== null;
    },
    setting: (state) => (settingName) => {
        if (state.appSettings === null) {
            throw new Error('Settings not loaded');
        }
        return getSettingValue(settingName, { settings: state.appSettings });
    },
};
export const actions = {
    async fetchSettingsIfNotSet({ commit, getters, dispatch }) {
        if (getters.areSettingsFetched) {
            return;
        }
        try {
            const data = await this.$axios.$get('/settings-frontend');
            commit('SET_SETTINGS', data.settings);
        }
        catch (_a) {
            dispatch('error/add', 'Une erreur est survenue.', { root: true });
            commit('SET_SETTINGS', null);
        }
    },
};
export const mutations = {
    SET_SETTINGS(state, settings) {
        state.appSettings = settings !== null && settings !== void 0 ? settings : null;
    },
};
