import { translateChoices } from '@shared/choice';
import { ELECTRICAL_DAMAGE_TYPES_CHOICES, ELECTRICAL_DAMAGE_TYPES_OTHER, ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS, } from '@shared/constants/electricalDamageConstants';
import { ModelFieldInputPreset, ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { ClaimFileType } from '@shared/types/file';
import { formatOptionalYearRanges } from '@shared/utils/formatAge';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { optionalBooleanTransform, optionalString, optionalTranslatableEnumTransform, } from '@shared/utils/transform';
import { electricalDamageConstraints } from '@shared/validator/constraints/electricalDamageConstraints';
export function electricalDamageTitle(model, translate) {
    return optionalTranslatableEnumTransform(model.type, ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS, translate);
}
export const electricalDamageFields = (model, { translate, projection, damageDate, damageType }) => {
    const fields = [];
    const title = optionalTranslatableEnumTransform(model.type, ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS, translate);
    if (projection === ModelFieldsProjection.Backoffice) {
        fields.push({
            label: translate('model.electrical_damage.type'),
            value: model.type,
            displayedValue: optionalTranslatableEnumTransform(model.type, ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translateChoices(ELECTRICAL_DAMAGE_TYPES_CHOICES, translate),
                },
                modelKey: 'type',
            },
        });
    }
    if (projection === ModelFieldsProjection.Declaration) {
        fields.push({
            label: translate('model.electrical_damage.designation'),
            displayedValue: optionalString(model.designation, optionalTranslatableEnumTransform(model.type, ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS, translate)),
            key: 'designation',
        });
    }
    else if (!model.type || ELECTRICAL_DAMAGE_TYPES_OTHER.includes(model.type)) {
        fields.push({
            label: translate('model.electrical_damage.designation'),
            value: model.designation,
            displayedValue: optionalString(model.designation),
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'designation',
            },
        });
    }
    fields.push({
        label: translate('model.electrical_damage.repairable'),
        value: model.repairable,
        displayedValue: optionalBooleanTransform(model.repairable, translate),
        editable: {
            input: ModelFieldInputPreset.boolean(translate),
            modelKey: 'repairable',
        },
    });
    if (model.repairable) {
        if (projection === ModelFieldsProjection.Backoffice) {
            fields.push({
                label: translate('model.electrical_damage.repair_amount'),
                value: model.repairAmount,
                displayedValue: formatOptionalCentsAmount(model.repairAmount),
                editable: {
                    input: {
                        type: ModelFieldInputType.Amount,
                    },
                    modelKey: 'repairAmount',
                },
            });
        }
        fields.push({
            label: translate('model.electrical_damage.repair_quote'),
            value: model.repairQuote,
            displayedValue: {
                type: 'fileCollection',
                fileCollection: model.repairQuote,
                uploadLabel: translate('model.document.upload'),
                addFileLabel: translate('model.document.upload.add_page'),
                emptyLabel: translate('model.document.empty'),
                fileType: ClaimFileType.RealEstateQuote,
            },
            editable: {
                input: {
                    type: ModelFieldInputType.FileCollection,
                },
                modelKey: 'repairQuote',
            },
        });
    }
    else {
        fields.push({
            label: translate('model.electrical_damage.irreparability_certificate'),
            value: model.irreparabilityCertificate,
            displayedValue: {
                type: 'fileCollection',
                fileCollection: model.irreparabilityCertificate,
                uploadLabel: translate('model.document.upload'),
                addFileLabel: translate('model.document.upload.add_page'),
                emptyLabel: translate('model.document.empty'),
                fileType: ClaimFileType.RealEstateIrreparabilityCertificate,
            },
            editable: {
                input: {
                    type: ModelFieldInputType.FileCollection,
                },
                modelKey: 'irreparabilityCertificate',
            },
        });
    }
    if (projection === ModelFieldsProjection.Backoffice && model.repairable) {
        fields.push({
            label: translate('model.electrical_damage.purchase_info'),
            value: model.purchaseInfo,
            displayedValue: optionalBooleanTransform(model.purchaseInfo, translate),
            editable: {
                input: ModelFieldInputPreset.boolean(translate),
                modelKey: 'purchaseInfo',
            },
        });
        if (!model.purchaseInfo) {
            fields.push({
                label: translate('model.electrical_damage.purchase_certification'),
                value: model.purchaseCertification,
                displayedValue: optionalBooleanTransform(model.purchaseCertification, translate),
                editable: {
                    input: ModelFieldInputPreset.boolean(translate),
                    modelKey: 'purchaseCertification',
                },
            });
        }
    }
    if (projection === ModelFieldsProjection.Declaration || model.purchaseInfo) {
        const purchaseDate = model.purchaseDate ? new Date(model.purchaseDate) : null;
        const currentDamageDate = damageDate ? new Date(damageDate) : null;
        let displayedLabel = translate('model.electrical_damage.age');
        let displayedDate = formatOptionalYearRanges(purchaseDate, currentDamageDate, translate);
        if (damageType === 'immovableProperties') {
            displayedLabel = translate('model.electrical_damage.purchase_date');
            displayedDate = purchaseDate ? new Intl.DateTimeFormat('fr-FR').format(purchaseDate) : '';
        }
        fields.push({
            label: displayedLabel,
            value: model.purchaseDate,
            displayedValue: displayedDate,
            editable: {
                input: {
                    type: ModelFieldInputType.Date,
                },
                modelKey: 'purchaseDate',
            },
        }, {
            label: translate('model.electrical_damage.purchase_amount'),
            value: model.purchaseAmount,
            displayedValue: formatOptionalCentsAmount(model.purchaseAmount),
            editable: {
                input: {
                    type: ModelFieldInputType.Amount,
                },
                modelKey: 'purchaseAmount',
            },
        });
    }
    if (projection === ModelFieldsProjection.Declaration || !model.repairable || model.purchaseInfo)
        fields.push({
            label: translate('model.electrical_damage.purchase_invoice'),
            value: model.purchaseInvoice,
            displayedValue: {
                type: 'fileCollection',
                fileCollection: model.purchaseInvoice,
                uploadLabel: translate('model.document.upload'),
                addFileLabel: translate('model.document.upload.add_page'),
                emptyLabel: translate('model.document.empty'),
                fileType: ClaimFileType.Receipt,
            },
            editable: {
                input: {
                    type: ModelFieldInputType.FileCollection,
                },
                modelKey: 'purchaseInvoice',
            },
        });
    return {
        title,
        fields,
        model,
        editable: { constraints: electricalDamageConstraints },
        defaultPayload: { type: model.type },
    };
};
