export var PersonalPropertyType;
(function (PersonalPropertyType) {
    PersonalPropertyType["Default"] = "defaut";
    PersonalPropertyType["GlassBreakage"] = "bris-de-glace";
})(PersonalPropertyType || (PersonalPropertyType = {}));
export var BasePersonalPropertyInitialCategorySlug;
(function (BasePersonalPropertyInitialCategorySlug) {
    BasePersonalPropertyInitialCategorySlug["Multimedia"] = "multimedia";
    BasePersonalPropertyInitialCategorySlug["BijouxObjetsDeValeur"] = "bijoux-objets-de-valeur";
    BasePersonalPropertyInitialCategorySlug["VetementsEtAccessoires"] = "vetements-et-accessoires";
    BasePersonalPropertyInitialCategorySlug["Autres"] = "autres";
})(BasePersonalPropertyInitialCategorySlug || (BasePersonalPropertyInitialCategorySlug = {}));
export var BDGPersonalPropertyInitialCategorySlug;
(function (BDGPersonalPropertyInitialCategorySlug) {
    BDGPersonalPropertyInitialCategorySlug["InsertDeCheminee"] = "insert-de-cheminee";
    BDGPersonalPropertyInitialCategorySlug["Miroir"] = "miroir";
    BDGPersonalPropertyInitialCategorySlug["Meuble"] = "meuble";
    BDGPersonalPropertyInitialCategorySlug["ElectromenagerOuMultimedia"] = "electromenager-ou-multimedia";
    BDGPersonalPropertyInitialCategorySlug["Autres"] = "autres";
})(BDGPersonalPropertyInitialCategorySlug || (BDGPersonalPropertyInitialCategorySlug = {}));
export var INCPersonalPropertyInitialCategorySlug;
(function (INCPersonalPropertyInitialCategorySlug) {
    INCPersonalPropertyInitialCategorySlug["ElectromenagerOuMultimedia"] = "electromenager-ou-multimedia";
    INCPersonalPropertyInitialCategorySlug["VetementsEtAccessoires"] = "vetements-et-accessoires";
    INCPersonalPropertyInitialCategorySlug["Meuble"] = "meuble";
    INCPersonalPropertyInitialCategorySlug["BijouxObjetsDeValeur"] = "bijoux-objets-de-valeur";
    INCPersonalPropertyInitialCategorySlug["Autres"] = "autres";
})(INCPersonalPropertyInitialCategorySlug || (INCPersonalPropertyInitialCategorySlug = {}));
export const PersonalPropertyInitialCategorySlug = {
    ...BasePersonalPropertyInitialCategorySlug,
    ...BDGPersonalPropertyInitialCategorySlug,
    ...INCPersonalPropertyInitialCategorySlug,
};
export var BDGPersonalPropertyReparationBy;
(function (BDGPersonalPropertyReparationBy) {
    BDGPersonalPropertyReparationBy["Myself"] = "moi-meme";
    BDGPersonalPropertyReparationBy["Professional"] = "professionnel";
    BDGPersonalPropertyReparationBy["Assistance"] = "assistance";
})(BDGPersonalPropertyReparationBy || (BDGPersonalPropertyReparationBy = {}));
export var PersonalPropertyReceiptType;
(function (PersonalPropertyReceiptType) {
    PersonalPropertyReceiptType["TypeInvoice"] = "type_invoice";
    PersonalPropertyReceiptType["TypeCashierReceipt"] = "type_cashier_receipt";
    PersonalPropertyReceiptType["TypeRepairInvoice"] = "type_repair_invoice";
    // Special case to not upload a receipt but pictures instead
    PersonalPropertyReceiptType["Pictures"] = "pictures";
    PersonalPropertyReceiptType["CashWithdrawal"] = "cash_withdrawal";
    PersonalPropertyReceiptType["TypeValuation"] = "type_valuation";
    PersonalPropertyReceiptType["TypeOther"] = "type_other";
    PersonalPropertyReceiptType["None"] = "none";
})(PersonalPropertyReceiptType || (PersonalPropertyReceiptType = {}));
export var RepairableStatus;
(function (RepairableStatus) {
    RepairableStatus["Repairable"] = "repairable";
    RepairableStatus["Irreparable"] = "irreparable";
    RepairableStatus["Excluded"] = "excluded";
})(RepairableStatus || (RepairableStatus = {}));
export var IdentityDocumentType;
(function (IdentityDocumentType) {
    IdentityDocumentType["Passport"] = "passport";
    IdentityDocumentType["DrivingLicence"] = "driving-licence";
    IdentityDocumentType["NationalIdentityCard"] = "national-identity-card";
})(IdentityDocumentType || (IdentityDocumentType = {}));
export const REPAIRABLE_STATUS_LABELS = {
    [RepairableStatus.Repairable]: 'model.personal_property.repairable_status.repairable',
    [RepairableStatus.Irreparable]: 'model.personal_property.repairable_status.irrepairable',
    [RepairableStatus.Excluded]: 'model.personal_property.repairable_status.excluded',
};
