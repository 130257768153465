import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const middleware = async ({ store, route, redirect, app: { localePath } }) => {
    if (insurerHasFeature(InsurerFeatureFlag.MagicLinkLoginCheckContractNumber))
        return;
    const magicToken = route.query.m;
    const claimId = route.query.c;
    if (!magicToken || !claimId) {
        await store.dispatch('authentication/loginWithMagicLink', {
            magicToken: '',
            claimId: '',
            error: 'error.magic_link.invalid',
        });
        return;
    }
    // Always attempt passwordless authentication to refresh token / re-check permissions
    await store.dispatch('authentication/loginWithMagicLink', {
        magicToken,
        claimId,
        error: null,
    });
    if (!store.getters['authentication/isAuthenticated'])
        return;
    // Fetch Claim and let BRMS and store redirect to the right current page
    try {
        await store.dispatch('claim/fetchClaim', { id: claimId });
        return redirect(localePath({ name: 'declaration' }));
    }
    catch (error) {
        await store.dispatch('authentication/loginWithMagicLink', {
            magicToken: '',
            claimId: '',
            error: 'error.magic_link.no_claim',
        });
    }
};
export default middleware;
