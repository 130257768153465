import { ELECTRIC_METER_TYPE_MODEL_CHOICES, ELECTRIC_METER_TYPE_MODEL_LABELS, GOOD_DAMAGE_TYPE_LABELS, } from '../goodDamage';
import { translateChoices } from '@shared/choice';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { ElectricMeterType, } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
const electicMeterBrands = [ElectricMeterType.LINKY];
export function electricMeterTitle(model, translate) {
    if (!model.type || !model.meterType)
        return null;
    const label = translate(GOOD_DAMAGE_TYPE_LABELS[model.type]);
    let type = translate(ELECTRIC_METER_TYPE_MODEL_LABELS[model.meterType]);
    if (!electicMeterBrands.includes(model.meterType))
        type = type.toLowerCase();
    return `${label} ${type}`;
}
export function electricMeterFields(model, context) {
    const { translate, index } = context;
    return [
        {
            label: translate('model.good_damage.electric_meter.meter_type'),
            value: model.meterType,
            displayedValue: optionalTranslatableEnumTransform(model.meterType, ELECTRIC_METER_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    nullable: true,
                    choices: translateChoices(ELECTRIC_METER_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.meterType`,
                toPayload: (meterType) => ({ meterType }),
            },
        },
    ];
}
