import Vue from 'vue';
import { createEmptyRefund } from '@shared/transformer/refundAmountTransformer';
import { ApiResourceName } from '@shared/types/api/api';
import { DamageXmlLabel } from '@shared/types/api/contract';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
export const name = 'counting';
export const namespaced = true;
export const state = () => ({
    counting: {
        _placeholder: true,
        id: null,
        initialRoomDamages: {},
        hasDamages: null,
        hasPersonalPropertyDamages: null,
        hasRoomDamages: null,
        roomDamages: [],
        personalProperties: [],
        externalDamages: [],
        breakInDamages: [],
        glassDamages: [],
        electricalDamages: [],
        equipments: [],
        receipts: [],
        isCertifiedArtisanOptionAvailable: null,
        isPecuniaryOptionAvailable: null,
        isSelfRepairOptionAvailable: null,
        damagesGroup: null,
    },
});
function refundFromDamageGroup(damageGroup) {
    var _a;
    return (_a = damageGroup === null || damageGroup === void 0 ? void 0 : damageGroup.refund) !== null && _a !== void 0 ? _a : createEmptyRefund();
}
export const getters = {
    initialRoomDamagesCount(state) {
        if (!state.counting.initialRoomDamages) {
            return null;
        }
        let initialRoomDamagesCount = 0;
        for (const roomSlug in state.counting.initialRoomDamages) {
            initialRoomDamagesCount += state.counting.initialRoomDamages[roomSlug];
        }
        return initialRoomDamagesCount;
    },
    realEstateRefund(state, _getters) {
        var _a, _b;
        const damageGroup = (_b = (_a = state.counting.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups) === null || _b === void 0 ? void 0 : _b.find((group) => group.xmlLabel === DamageXmlLabel.RealEstate);
        return refundFromDamageGroup(damageGroup);
    },
    personalPropertiesRefund(state, _getters) {
        var _a, _b;
        const damageGroup = (_b = (_a = state.counting.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups) === null || _b === void 0 ? void 0 : _b.find((group) => group.xmlLabel === DamageXmlLabel.PersonalProperty);
        return refundFromDamageGroup(damageGroup);
    },
    breakInDamagesRefund(state, _getters) {
        var _a, _b, _c, _d;
        const damageGroup = (_d = (_c = (_b = (_a = state.counting.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups) === null || _b === void 0 ? void 0 : _b.find((group) => group && group.xmlLabel === DamageXmlLabel.RealEstate)) === null || _c === void 0 ? void 0 : _c.childrenDamagesGroups) === null || _d === void 0 ? void 0 : _d.find((group) => group.xmlLabel === DamageXmlLabel.BreakInDamage);
        return refundFromDamageGroup(damageGroup);
    },
    damagesRefund(state, _getters) {
        return refundFromDamageGroup(state.counting.damagesGroup);
    },
};
export const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    async createNewCounting({ state, commit }, claimIri) {
        const { id } = await this.$axios.$post(apiResourcePath(ApiResourceName.Countings), {
            title: 'Chiffrage',
            claim: claimIri,
        });
        commit('UPDATE_COUNTING', { ...state.counting, id });
    },
    updateStoreWithClaimResponse({ commit }, claimResponse) {
        commit('UPDATE_COUNTING', claimResponse.counting);
    },
    updateCounting({ commit }, countingPayload) {
        commit('UPDATE_COUNTING_WITH_PAYLOAD', countingPayload);
    },
    removeRealEstateQuoteFile({ commit }, fileId) {
        commit('REMOVE_REAL_ESTATE_QUOTE_FILE', fileId);
    },
    async updateAndSaveCounting({ state, commit, dispatch }, { countingPayload, step }) {
        commit('UPDATE_COUNTING_WITH_PAYLOAD', countingPayload);
        const id = state.counting.id;
        const payload = {
            counting: { ...countingPayload, id },
            step,
        };
        await dispatch('claim/saveClaimWithPayload', payload, { root: true });
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    UPDATE_COUNTING_WITH_PAYLOAD(state, countingPayload) {
        if (!state.counting) {
            return;
        }
        Object.entries(countingPayload).forEach(([key, value]) => {
            state.counting[key] = value;
        });
    },
    UPDATE_COUNTING(state, counting) {
        Vue.set(state, 'counting', counting);
    },
};
