import { DOOR_TYPE_MODEL_LABELS, DOOR_TYPE_MODEL_CHOICES, DOOR_MATERIAL_MODEL_LABELS, DOOR_MATERIAL_MODEL_CHOICES, DOOR_DAMAGE_TYPE_MODEL_LABELS, DOOR_DAMAGE_TYPE_MODEL_CHOICES, DOOR_GLAZING_TYPE_MODEL_LABELS, DOOR_GLAZING_TYPE_MODEL_CHOICES, DOOR_GLASS_TYPE_MODEL_LABELS, DOOR_GLASS_TYPE_MODEL_CHOICES, DOOR_LOCK_TYPE_MODEL_LABELS, DOOR_LOCK_TYPE_MODEL_CHOICES, DOOR_TYPE_LABELS, DOOR_MATERIAL_LABELS, GOOD_DAMAGE_TYPE_LABELS, } from '../goodDamage';
import { parseOptionalDimension } from '@declaration/helpers/typeFormatters';
import { translateChoices, numbersToChoices } from '@shared/choice';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { ModelFieldInputType } from '@shared/modelField/modelField';
import { DoorType, DoorMaterial, DoorDamageType, } from '@shared/types/api/roomDamage';
import { optionalTranslatableEnumTransform, optionalString, optionalBooleanTransform, } from '@shared/utils/transform';
export function doorTitle(model, translate) {
    if (!model.type || !model.doorType)
        return null;
    return translate(DOOR_TYPE_LABELS[model.doorType]);
}
export function doorDetails(model, translate) {
    var _a;
    if (!model.type || !model.doorType || !model.material)
        return null;
    if (model.doorType !== DoorType.INDOOR && !model.leafCount)
        return null;
    const key = `${GOOD_DAMAGE_TYPE_LABELS[model.type]}.description`;
    return translate(key, (_a = model.leafCount) !== null && _a !== void 0 ? _a : 1, {
        material: translate(DOOR_MATERIAL_LABELS[model.material]),
        count: model.leafCount,
    });
}
export function doorFields(model, context) {
    var _a, _b;
    const { translate, index } = context;
    const fields = [
        {
            label: translate('model.good_damage.door.door_type'),
            value: model.doorType,
            displayedValue: optionalTranslatableEnumTransform(model.doorType, DOOR_TYPE_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(DOOR_TYPE_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.doorType`,
                toPayload: (doorType) => doorType === DoorType.INDOOR
                    ? {
                        doorType,
                        leafCount: null,
                        material: null,
                        damageType: null,
                        hasGlazing: null,
                        isGlazingBroken: null,
                        tileCount: null,
                        biggestTileWidth: null,
                        biggestTileHeight: null,
                        glazingType: null,
                        glassType: null,
                    }
                    : { doorType },
            },
        },
    ];
    if (model.doorType === DoorType.MAIN_ENTRANCE || model.doorType === DoorType.SERVICE) {
        fields.push({
            label: translate('model.good_damage.door.leaf_count'),
            value: model.leafCount,
            displayedValue: optionalString(model.leafCount),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: numbersToChoices(1, 2),
                },
                modelKey: `goodDamages.${index}.leafCount`,
                toPayload: (leafCount) => ({ leafCount }),
            },
        }, {
            label: translate('model.good_damage.door.material'),
            value: model.material,
            displayedValue: optionalTranslatableEnumTransform(model.material, DOOR_MATERIAL_MODEL_LABELS, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(DOOR_MATERIAL_MODEL_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.material`,
                toPayload: (material) => ({
                    material,
                    damageType: material === DoorMaterial.WOOD ? model.damageType : null,
                    isGlazingBroken: material === DoorMaterial.WOOD ? model.isGlazingBroken : null,
                    tileCount: material === DoorMaterial.WOOD ? model.tileCount : null,
                    biggestTileWidth: material === DoorMaterial.WOOD ? model.biggestTileWidth : null,
                    biggestTileHeight: material === DoorMaterial.WOOD ? model.biggestTileHeight : null,
                    lockType: material === DoorMaterial.PVC ||
                        material === DoorMaterial.METAL ||
                        model.damageType === DoorDamageType.DAMAGED
                        ? model.lockType
                        : null,
                }),
            },
        });
        if (model.material === DoorMaterial.WOOD) {
            fields.push({
                label: translate('model.good_damage.door.damage_type'),
                value: model.damageType,
                displayedValue: optionalTranslatableEnumTransform(model.damageType, DOOR_DAMAGE_TYPE_MODEL_LABELS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Radio,
                        choices: translateChoices(DOOR_DAMAGE_TYPE_MODEL_CHOICES, translate),
                    },
                    modelKey: `goodDamages.${index}.damageType`,
                    toPayload: (damageType) => {
                        const aesthetic = damageType === DoorDamageType.AESTHETIC;
                        return {
                            damageType,
                            isGlazingBroken: aesthetic ? model.isGlazingBroken : null,
                            tileCount: aesthetic ? model.tileCount : null,
                            biggestTileWidth: aesthetic ? model.biggestTileWidth : null,
                            biggestTileHeight: aesthetic ? model.biggestTileHeight : null,
                            lockType: aesthetic ? null : model.lockType,
                        };
                    },
                },
            });
        }
        fields.push({
            label: translate('model.good_damage.door.has_glazing'),
            value: model.hasGlazing,
            displayedValue: optionalBooleanTransform(model.hasGlazing, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Radio,
                    choices: translateChoices(YES_OR_NO_CHOICES, translate),
                },
                modelKey: `goodDamages.${index}.hasGlazing`,
                toPayload: (hasGlazing) => ({
                    hasGlazing,
                    isGlazingBroken: hasGlazing ? model.isGlazingBroken : null,
                    tileCount: hasGlazing ? model.tileCount : null,
                    biggestTileWidth: hasGlazing ? model.biggestTileWidth : null,
                    biggestTileHeight: hasGlazing ? model.biggestTileHeight : null,
                    glazingType: hasGlazing ? model.glazingType : null,
                    glassType: hasGlazing ? model.glassType : null,
                }),
            },
        });
        if (model.hasGlazing) {
            if (model.damageType === DoorDamageType.AESTHETIC) {
                fields.push({
                    label: translate('model.good_damage.door.is_glazing_broken'),
                    value: model.isGlazingBroken,
                    displayedValue: optionalBooleanTransform(model.isGlazingBroken, translate),
                    editable: {
                        input: {
                            type: ModelFieldInputType.Radio,
                            choices: translateChoices(YES_OR_NO_CHOICES, translate),
                        },
                        modelKey: `goodDamages.${index}.isGlazingBroken`,
                        toPayload: (isGlazingBroken) => ({
                            isGlazingBroken,
                            tileCount: isGlazingBroken ? model.tileCount : null,
                            biggestTileWidth: isGlazingBroken ? model.biggestTileWidth : null,
                            biggestTileHeight: isGlazingBroken ? model.biggestTileHeight : null,
                            glazingType: isGlazingBroken ? model.glazingType : null,
                            glassType: isGlazingBroken ? model.glassType : null,
                        }),
                    },
                });
                if (model.isGlazingBroken) {
                    fields.push({
                        label: translate('model.good_damage.door.tile_count'),
                        value: model.tileCount,
                        displayedValue: optionalString(model.tileCount),
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            modelKey: `goodDamages.${index}.tileCount`,
                            toPayload: (tileCount) => ({ tileCount }),
                        },
                    }, {
                        label: translate('model.good_damage.door.biggest_tile_width'),
                        value: ((_a = model.biggestTileWidth) !== null && _a !== void 0 ? _a : 0) / 10,
                        displayedValue: parseOptionalDimension(model.biggestTileWidth),
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            unit: 'cm',
                            modelKey: `goodDamages.${index}.biggestTileWidth`,
                            toPayload: (biggestTileWidth) => ({
                                biggestTileWidth: biggestTileWidth * 10,
                            }),
                        },
                    }, {
                        label: translate('model.good_damage.door.biggest_tile_height'),
                        value: ((_b = model.biggestTileHeight) !== null && _b !== void 0 ? _b : 0) / 10,
                        displayedValue: parseOptionalDimension(model.biggestTileHeight),
                        editable: {
                            input: {
                                type: ModelFieldInputType.Number,
                            },
                            unit: 'cm',
                            modelKey: `goodDamages.${index}.biggestTileHeight`,
                            toPayload: (biggestTileHeight) => ({
                                biggestTileHeight: biggestTileHeight * 10,
                            }),
                        },
                    });
                }
            }
            if (model.isGlazingBroken ||
                model.damageType === DoorDamageType.DAMAGED ||
                model.material === DoorMaterial.PVC ||
                model.material === DoorMaterial.METAL) {
                fields.push({
                    label: translate('model.good_damage.door.glazing_type'),
                    value: model.glazingType,
                    displayedValue: optionalTranslatableEnumTransform(model.glazingType, DOOR_GLAZING_TYPE_MODEL_LABELS, translate),
                    editable: {
                        input: {
                            type: ModelFieldInputType.Radio,
                            choices: translateChoices(DOOR_GLAZING_TYPE_MODEL_CHOICES, translate),
                        },
                        modelKey: `goodDamages.${index}.glazingType`,
                        toPayload: (glazingType) => ({ glazingType }),
                    },
                }, {
                    label: translate('model.good_damage.door.glass_type'),
                    value: model.glassType,
                    displayedValue: optionalTranslatableEnumTransform(model.glassType, DOOR_GLASS_TYPE_MODEL_LABELS, translate),
                    editable: {
                        input: {
                            type: ModelFieldInputType.Radio,
                            choices: translateChoices(DOOR_GLASS_TYPE_MODEL_CHOICES, translate),
                        },
                        modelKey: `goodDamages.${index}.glassType`,
                        toPayload: (glassType) => ({ glassType }),
                    },
                });
            }
        }
        if (model.material === DoorMaterial.PVC ||
            model.material === DoorMaterial.METAL ||
            model.damageType === DoorDamageType.DAMAGED) {
            fields.push({
                label: translate('model.good_damage.door.lock_type'),
                value: model.lockType,
                displayedValue: optionalTranslatableEnumTransform(model.lockType, DOOR_LOCK_TYPE_MODEL_LABELS, translate),
                editable: {
                    input: {
                        type: ModelFieldInputType.Select,
                        choices: translateChoices(DOOR_LOCK_TYPE_MODEL_CHOICES, translate),
                    },
                    modelKey: `goodDamages.${index}.lockType`,
                    toPayload: (lockType) => ({ lockType }),
                },
            });
        }
    }
    return fields;
}
